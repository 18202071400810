import { useCallback } from 'react';

export enum Env {
    Development = 'development',
    Production = 'production',
    Staging = 'staging'
}

let currentEnv: Nullable<Env> = null;

export function setEnv(env: Env) {
    currentEnv = env;
}

export function unsetEnv() {
    currentEnv = null;
}

const useEnv = () => {
    if (!currentEnv) {
        // eslint-disable-next-line no-console
        console.warn('Environment has not been set.');
    }

    return {
        isDevelopment: useCallback(() => currentEnv === Env.Development, []),
        isProduction: useCallback(() => currentEnv === Env.Production, []),
        isStaging: useCallback(() => currentEnv === Env.Staging, [])
    };
};

export default useEnv;
