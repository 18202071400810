/* eslint-disable func-names */

var directiveName = 'spSlideshow';
var default_options = {
    numberOfPhotosInScope: 4,
    photoSize: '3x'
};

if (window.isSmallDevice()) {
    angular.extend(default_options, {
        numberOfPhotosInScope: 6,
        photoSize: 'l'
    });
} else if (window.isMediumDevice()) {
    angular.extend(default_options, {
        numberOfPhotosInScope: 6,
        photoSize: '2x'
    });
}

export default [
    '$timeout',
    function spSlideshowDirective($timeout) {
        var bodyElement = angular.element(document.querySelector('body'));

        return {
            restrict: 'A',
            scope: {
                photos: '=' + directiveName
            },
            link: function spSlideshowController($scope) {
                var index = false;

                bodyElement.css('overflow', 'hidden');

                $scope.$parent.photoSize = default_options.photoSize;

                /**
                 * Handle Controls visibility based on mouse movement
                 */
                $scope.$parent.handleSlideshowClick = function handleSlideshowClick() {
                    if (window.Modernizr.touchevents && !$scope.$parent.preventHideControls) {
                        $scope.$parent.slideshow.active = !$scope.$parent.slideshow.active;
                    }
                };

                $scope.slideshowDelay = 10;
                $scope.$parent.slideshow = {};
                $scope.$parent.slideshow.mouseLast = { x: 0, y: 0 };
                $scope.$parent.slideshow.mousePos = { x: 0, y: 0 };
                $scope.$parent.slideshow.active = true;
                $scope.$parent.slideshow.counter = $scope.slideshowDelay;

                if (!window.Modernizr.touchevents) {
                    $scope.$parent.updateWakeControls = function updateWakeControls(event) {
                        $scope.$parent.slideshow.mousePos = {
                            x: event.clientX,
                            y: event.clientY
                        };
                    };

                    $scope.$parent.slideshow.playPauseHover = false;

                    $scope.$parent.playButtonHoverStart = function playButtonHoverStart() {
                        $scope.$parent.slideshow.playPauseHover = true;
                    };

                    $scope.$parent.playButtonHoverEnd = function playButtonHoverEnd() {
                        $scope.$parent.slideshow.playPauseHover = false;
                    };

                    $scope.$parent.slideshow.closeButtonHover = false;

                    $scope.$parent.closeButtonHoverStart = function closeButtonHoverStart() {
                        $scope.$parent.slideshow.closeButtonHover = true;
                    };

                    $scope.$parent.closeButtonHoverEnd = function closeButtonHoverEnd() {
                        $scope.$parent.slideshow.closeButtonHover = false;
                    };

                    $scope.mouseTracker = setInterval(function() {
                        //  slow &  fast: 0.2s,
                        //  slow & !fast: 2.0s,
                        // !slow &  fast: 0.2s,
                        // !slow & !fast: 0.0s,
                        // !hide: opacity = 1,
                        //  hide: opacity = 0

                        $scope.$parent.slideshow.deltaMouse =
                            $scope.$parent.slideshow.mouseLast.x !==
                                $scope.$parent.slideshow.mousePos.x ||
                            $scope.$parent.slideshow.mouseLast.y !==
                                $scope.$parent.slideshow.mousePos.y;

                        $scope.$parent.slideshow.mouseLast = $scope.$parent.slideshow.mousePos;

                        if (
                            !$scope.$parent.slideshow.active &&
                            !$scope.$parent.slideshow.deltaMouse
                        ) {
                            // controls not shown and mouse has not moved -- do nothing
                            return;
                        } else if (
                            $scope.$parent.slideshow.active &&
                            ($scope.$parent.slideshow.deltaMouse ||
                                $scope.$parent.slideshow.playPauseHover ||
                                $scope.$parent.slideshow.closeButtonHover)
                        ) {
                            // controls visible and mouse has moved or is above one of the controls -- reset visibility delay
                            $scope.$parent.slideshow.counter = $scope.slideshowDelay;

                            return;
                        } else if (!$scope.$parent.slideshow.active) {
                            // controls not shown and mouse has moved -- quickly show controls
                            $scope.$parent.slideshow.counter = $scope.slideshowDelay;
                            $scope.$parent.slideshow.active = true;
                            $scope.$parent.slideshow.longTransition = false;

                            return;
                        } else if ($scope.$parent.slideshow.counter <= 0) {
                            // controls visible and counter has elapsed and mouse has not moved -- fade away controls
                            $scope.$parent.slideshow.active = false;
                            $scope.$parent.slideshow.longTransition = true;

                            return;
                        }

                        // controls visible and counter is in progress, mouse has not moved -- decrement counter
                        $scope.$parent.slideshow.counter--;
                    }, 100);
                }

                $scope.$parent.$on('advanceSlideshow', function onAdvanceSlideshow() {
                    $timeout(advancePhotoWindow);
                });

                advancePhotoWindow();

                $scope.$on('$destroy', function() {
                    bodyElement.css('overflow', '');

                    clearInterval($scope.mouseTracker);
                });

                function advancePhotoWindow() {
                    if (index === false) {
                        index = $scope.photos.length - 1;
                    } else {
                        index++;
                    }

                    index = index % $scope.photos.length;

                    var newSlideshowPhotos = $scope.photos.slice(
                        index,
                        index + default_options.numberOfPhotosInScope
                    );

                    if (index > $scope.photos.length - default_options.numberOfPhotosInScope) {
                        var numberToFrontLoad =
                            default_options.numberOfPhotosInScope - ($scope.photos.length - index);

                        newSlideshowPhotos = newSlideshowPhotos.concat(
                            $scope.photos.slice(0, numberToFrontLoad)
                        );
                    }

                    var cleanList = [];
                    var cleanListIds = [];
                    var newSlideshowPhoto;

                    for (var i = 0; i < newSlideshowPhotos.length; i++) {
                        newSlideshowPhoto = newSlideshowPhotos[i];

                        if (cleanListIds.indexOf(newSlideshowPhoto.id) === -1) {
                            cleanList.push(newSlideshowPhoto);
                            cleanListIds.push(newSlideshowPhoto.id);
                        }
                    }

                    newSlideshowPhotos = cleanList;

                    $scope.$parent.slideshowPhotos = newSlideshowPhotos;
                }
            }
        };
    }
];
