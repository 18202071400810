import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { styled } from 'sp-ui';
import { useEvent } from 'ts/client/common';
import { Button } from 'ts/common/components/gallery';
import { useTranslate } from 'ts/common/hooks';
import { useCurrency } from '../hooks';

const FooterContainer = styled(Flex, {
    shouldForwardProp: (prop) => prop !== 'visible'
})<{ visible: boolean }>(
    ({ theme, visible }) => `
        background: ${theme.colors.modalBackground};
        border-top: 1px solid ${theme.isLightColorScheme ? theme.colors.gray[200] : 'black'};
        bottom: ${visible ? '0' : '-80px'};
        height: 80px;
        left: 0;
        padding: 0 30px;
        position: fixed;
        transition: all .3s ease-out;
        width: 100%;
        z-index: 1;
    `
);

interface ICartInfoProps {
    numInCart?: number;
    preDiscountSubtotal?: number;
    visible: boolean;
}

const CartInfo: React.FC<ICartInfoProps> = ({ numInCart, preDiscountSubtotal, visible }) => {
    const { id: eventId } = useEvent();
    const $ = useCurrency();
    const t = useTranslate('client.gallery.addToCartSidePanel.selectProductGroupSlide');

    return (
        <FooterContainer alignItems="center" justifyContent="space-between" visible={visible}>
            {numInCart === 1 && (
                <Text
                    color="currentColor"
                    fontFamily="open-sans, sans-serif"
                    fontSize="md"
                    data-testid="cart-info-text"
                >{`${numInCart} ${t('item')}, ${
                    preDiscountSubtotal ? $(preDiscountSubtotal) : `$${preDiscountSubtotal}`
                } ${t('forThisPhoto')}`}</Text>
            )}
            {numInCart !== 1 && (
                <Text
                    color="currentColor"
                    fontFamily="open-sans, sans-serif"
                    fontSize="md"
                    data-testid="cart-info-text"
                >
                    {`${numInCart} ${t('items')},  ${
                        preDiscountSubtotal ? $(preDiscountSubtotal) : `$${preDiscountSubtotal}`
                    } ${t('forThisPhoto')}`}
                </Text>
            )}
            <Button
                isDisabled={false}
                isLoading={false}
                text={t('viewCart')}
                variant="primary"
                onClick={() => {
                    window.location.replace(
                        new URL(`/gallery/${eventId}/cart`, window.location.origin).toString()
                    );
                }}
            />
        </FooterContainer>
    );
};

export default CartInfo;
