export default [
    '$scope',
    'SPPhoto',
    function cartPhotoItemCropController($scope, SPPhoto) {
        $scope.crop = $scope.boundItem.crop;

        $scope.resetPhotoCrop = function resetPhotoCrop() {
            $scope.boundItem.resetCrop(SPPhoto);

            $scope.crop = $scope.boundItem.crop;
        };

        if (typeof $scope.crop !== 'object') {
            $scope.resetPhotoCrop();
        }

        $scope.selectCrop = function selectCrop() {
            $scope.save($scope.crop);
        };
    }
];
