import PRICESHEET_GROUP_TYPES from 'Sp/PriceSheet/Group';
import {
    generateCanvasClipMaskSVG,
    generateOrnamentClipMaskSVG,
    getMaxCrop,
    getSize
} from 'ts/common';
import {
    ornamentsCircleOverImageUrl,
    ornamentsHavenOverImageUrl,
    ornamentsMontereyOverImageUrl
} from 'ts/common/assets';
import type { ICrop, ISize } from 'ts/common/types';
import { isRoundProduct } from 'ts/common/utils';
import { IProductItem, IProductPhoto } from './types';

function normalizeBounds(photo, bounds) {
    if (photo.width > photo.height !== bounds.width > bounds.height) {
        // If the bounding mode does not match up with the photo mode,
        // then invert the bounding mode
        return {
            ...bounds,
            width: bounds.height,
            height: bounds.width
        };
    }

    return bounds;
}

export const buildDigitalProps = (photo: IProductPhoto, photoUrl: string) => {
    const project = {
        width: photo.width,
        height: photo.height,
        rotation: 0,
        image: {
            url: photoUrl,
            width: photo.width,
            height: photo.height,
            clippingMask: {
                left: 0,
                top: 0,
                right: 0,
                bottom: 0
            }
        }
    };
    const pageSpecification = {
        width: photo.width,
        height: photo.height,
        product: {
            x: 0,
            y: 0,
            width: photo.width,
            height: photo.height
        },
        productMasks: {
            clipImageUrl: '',
            frameImageUrl: '',
            multiplyImageUrl: '',
            overImageUrl: '',
            rotation: 0
        }
    };
    const isRoundCrop = false;

    return { isRoundCrop, pageSpecification, project };
};

export const buildNonDigitalProps = (
    item: IProductItem,
    photo: IProductPhoto,
    photoUrl: string
) => {
    const {
        crop: { bounds: rawBounds, end: cropEnd, start: cropStart },
        productMasks = {
            clipImageUrl: '',
            frameImageUrl: '',
            multiplyImageUrl: '',
            overImageUrl: '',
            rotation: 0
        }
    } = item;
    const bounds = normalizeBounds(photo, rawBounds);
    const cropMaskWidth = Math.floor((photo.width * (cropEnd.x - cropStart.x)) / 100);
    const cropMaskHeight = Math.floor((photo.height * (cropEnd.y - cropStart.y)) / 100);
    const cropDataAsClippingMask = {
        left: cropStart.x / 100,
        top: cropStart.y / 100,
        right: 1 - cropEnd.x / 100,
        bottom: 1 - cropEnd.y / 100
    };
    const rotation =
        bounds.width === bounds.height ||
        cropMaskWidth >= cropMaskHeight === bounds.width >= bounds.height
            ? 0
            : 1;
    const project = {
        width: bounds.width,
        height: bounds.height,
        rotation: item.rotation ?? rotation,
        image: {
            url: photoUrl,
            width: photo.width,
            height: photo.height,
            clippingMask: cropDataAsClippingMask
        }
    };
    const pageSpecification = {
        width: bounds.width,
        height: bounds.height,
        product: {
            x: 0,
            y: 0,
            width: bounds.width,
            height: bounds.height
        },
        productMasks
    };
    const isRoundCrop = isRoundProduct(item.boundsName);

    return { isRoundCrop, pageSpecification, project };
};

const getClipImageUrl = ({
    boundsName,
    priceSheetGroupType,
    productMasks,
    size
}: {
    boundsName?: string;
    priceSheetGroupType: SpApi.PriceSheetGroupType;
    productMasks?: IProductItem['productMasks'];
    size: Nullable<ISize>;
}) => {
    if (isRoundProduct(boundsName)) {
        return productMasks?.clipImageUrl;
    }

    if (priceSheetGroupType === PRICESHEET_GROUP_TYPES.TYPE.ORNAMENTS && boundsName) {
        return generateOrnamentClipMaskSVG(boundsName);
    }

    if (priceSheetGroupType === PRICESHEET_GROUP_TYPES.TYPE.CANVAS && size) {
        return generateCanvasClipMaskSVG(size);
    }

    return '';
};

export const getImageAspectRatio = (photoBounds: IProductPhoto) => {
    return photoBounds.width / photoBounds.height;
};

export const getProductItem = ({
    boundsName,
    crop,
    photoBounds,
    priceSheetGroupType,
    priceSheetItemSize,
    productMasks
}: {
    boundsName?: string;
    crop?: Nullable<ICrop>;
    photoBounds: { height: number; width: number };
    priceSheetGroupType: SpApi.PriceSheetGroupType;
    priceSheetItemSize?: Nullable<SpApi.IPriceSheetItemSize>;
    productMasks?: IProductItem['productMasks'];
}): IProductItem => {
    const isCanvas = priceSheetGroupType === PRICESHEET_GROUP_TYPES.TYPE.CANVAS;
    const isOrnaments = priceSheetGroupType === PRICESHEET_GROUP_TYPES.TYPE.ORNAMENTS;

    const overImageUrl = isOrnaments
        ? boundsName === 'Circle'
            ? ornamentsCircleOverImageUrl
            : boundsName === 'Haven'
            ? ornamentsHavenOverImageUrl
            : ornamentsMontereyOverImageUrl
        : '';

    const size = getSize(priceSheetItemSize ?? undefined);
    const isPortrait = photoBounds.height > photoBounds.width;

    const productPreviewItem: IProductItem = {
        boundsName: boundsName,
        crop: crop ?? getMaxCrop(photoBounds, size, isCanvas, isOrnaments),
        productMasks: {
            clipImageUrl:
                getClipImageUrl({
                    boundsName,
                    priceSheetGroupType,
                    productMasks,
                    size
                }) ?? '',
            frameImageUrl: productMasks?.frameImageUrl ?? '',
            multiplyImageUrl: productMasks?.multiplyImageUrl ?? '',
            overImageUrl: overImageUrl
        },
        // (do not) Rotate for ornaments when the photo is portrait
        rotation: isOrnaments && isPortrait ? 1 : undefined
    };

    return productPreviewItem;
};
