import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Box, Button, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import { ChevronDown, Heart, Image, Lock, Tag } from 'react-feather';
import { styled } from 'sp-ui';
import { useEvent, useFetchGalleryPhotosByContext } from 'ts/client/common';
import { IPathContext } from 'ts/client/gallery/components/types';
import { useTranslate } from 'ts/common';
import type { IContextOptionButton } from 'ts/common/types';
import { AlbumIcon } from 'client_react/common/components/icons';
import {
    ContextSelectDrawer,
    ContextSelectDropdown
} from 'client_react/gallery/components/Store/select-photos-modal';
import { useStore } from 'client_react/gallery/hooks/StoreContext';
import { useStoreModal } from 'client_react/gallery/hooks/StoreModalContext';

const AnimatedChevron = styled(ChevronDown, {
    shouldForwardProp: (prop) => prop !== 'isOpen'
})<{ isOpen: boolean }>(
    ({ isOpen }) => `
        transition: all 0.25s;
        transform: ${isOpen ? 'rotate(-180deg)' : 'rotate(0)'};
    `
);

interface IContextSelect {
    onPasswordedAlbumSelected: (albumId: string) => void;
}

const ContextSelect: React.FC<IContextSelect> = ({ onPasswordedAlbumSelected }) => {
    const isSmallScreen = useBreakpointValue({ base: true, lg: false });
    const t = useTranslate('client.store.selectPhotosModal');
    const {
        albumsData,
        authenticatedAlbums,
        isAdminModeEnabled,
        isLabeledPhotosVisible,
        pathContext,
        setPhotos,
        setPathContext,
        tags
    } = useStore();

    const { isOpen: isDrawerOpen, onClose: onDrawerClose, onOpen: openDrawer } = useDisclosure();

    const {
        isOpen: isDropdownOpen,
        onClose: onDropdownClose,
        onOpen: openDropdown
    } = useDisclosure();

    const toggleDropdownOpen = () => {
        return isDropdownOpen ? onDropdownClose() : openDropdown();
    };

    const { setArePhotosLoading } = useStoreModal();

    const {
        settings: { exceedsBigEventThreshold }
    } = useEvent();

    const loaded = useRef(false);

    const isPasswordedAlbum = useCallback(
        (context: IPathContext) => {
            if (context.context !== 'album') {
                return false;
            }

            const albumId = Number(context.contextId);

            const album = albumsData.find((album) => album.id === albumId);

            return album && album.requiresAccessCode && !authenticatedAlbums.includes(albumId);
        },
        [albumsData, authenticatedAlbums]
    );

    const handleContextChange = useCallback(
        (context: IPathContext) => {
            if (isPasswordedAlbum(context)) {
                onPasswordedAlbumSelected(context.contextId);
            } else {
                setPathContext(context);
            }
        },
        [isPasswordedAlbum, onPasswordedAlbumSelected, setPathContext]
    );

    const { drawerContextActions } = useMemo(() => {
        const drawerContextActions: IContextOptionButton[] = [];

        if (!exceedsBigEventThreshold) {
            drawerContextActions.push({
                key: 'allPhotos',
                context: '',
                contextId: '',
                leftIcon: <Image width="40px" height="40px" viewBox="-6 -6 36 36" />,
                text: t('allAvailablePhotos'),
                onClick: () => handleContextChange({ context: '', contextId: '' })
            });
        }

        const drawerAlbumsContextActions = albumsData.reduce((options, album) => {
            options.push({
                key: String(album.id),
                context: 'album',
                contextId: String(album.id),
                leftIcon: <AlbumIcon />,
                rightIcon:
                    album.requiresAccessCode && !authenticatedAlbums.includes(album.id) ? (
                        <Lock width="35px" height="35px" viewBox="-6 -6 36 36" />
                    ) : undefined,
                text: album.albumPath,
                onClick: () =>
                    handleContextChange({ context: 'album', contextId: String(album.id) })
            });
            return options;
        }, [] as IContextOptionButton[]);
        drawerContextActions.push(...drawerAlbumsContextActions);

        drawerContextActions.push({
            key: 'favorites',
            context: 'favorites',
            contextId: '',
            leftIcon: <Heart width="40px" height="40px" viewBox="-6 -6 36 36" />,
            text: t('favorites'),
            onClick: () => handleContextChange({ context: 'favorites', contextId: '' })
        });

        if (isAdminModeEnabled && isLabeledPhotosVisible) {
            const drawerTagsContextActions = tags.reduce((options, tag) => {
                options.push({
                    key: tag.name,
                    context: 'label',
                    contextId: tag.name,
                    leftIcon: <Tag width="40px" height="40px" viewBox="-6 -6 36 36" />,
                    text: tag.name,
                    onClick: () => handleContextChange({ context: 'label', contextId: tag.name })
                });
                return options;
            }, [] as IContextOptionButton[]);
            drawerContextActions.push(...drawerTagsContextActions);
        }

        return { drawerContextActions };
    }, [
        albumsData,
        authenticatedAlbums,
        exceedsBigEventThreshold,
        handleContextChange,
        isAdminModeEnabled,
        isLabeledPhotosVisible,
        t,
        tags
    ]);

    const getContextName = (pathContext: IPathContext) => {
        switch (pathContext.context) {
            case 'favorites':
                return t('favorites');
                break;
            case 'label':
                const labelOptions = drawerContextActions.filter(
                    (option) => option.context === 'label'
                );
                const labelOption = labelOptions.find(
                    (labelOption) => labelOption.contextId === pathContext.contextId
                );

                return labelOption?.text ?? '';
                break;
            case 'album':
                const albumOptions = drawerContextActions.filter(
                    (option) => option.context === 'album'
                );
                const albumOption = albumOptions.find(
                    (albumOption) => String(albumOption.contextId) === pathContext.contextId
                );

                return albumOption?.text ?? '';
                break;
            default:
                return t('allAvailablePhotos');
        }
    };

    const getIsSelectedContext = (action: IContextOptionButton) => {
        switch (pathContext.context) {
            case 'favorites':
                return action.context === 'favorites';
                break;
            case 'label':
                return (
                    action.context === pathContext.context &&
                    action.contextId === pathContext.contextId
                );
                break;
            case 'album':
                return (
                    action.context === pathContext.context &&
                    action.contextId === pathContext.contextId
                );
                break;
            default:
                return action.context === pathContext.context;
        }
    };

    const {
        fetchPhotos,
        loading: arePhotosLoading,
        fetchPhotosResponse
    } = useFetchGalleryPhotosByContext();

    useEffect(() => {
        if (loaded.current) {
            fetchPhotos(pathContext.context, pathContext.contextId);
        } else {
            loaded.current = true;
        }
    }, [fetchPhotos, pathContext]);

    useEffect(() => {
        if (fetchPhotosResponse) {
            setPhotos(fetchPhotosResponse.items);
        }
    }, [fetchPhotosResponse, setPhotos]);

    useEffect(() => {
        setArePhotosLoading(arePhotosLoading);
    }, [arePhotosLoading, setArePhotosLoading]);

    return (
        <Box>
            <Button
                aria-label={'context-select-dropdown'}
                data-testid="header-context-select-button"
                color="currentcolor"
                fontSize={isSmallScreen ? 'xs' : 'sm'}
                minWidth="260px"
                onClick={isSmallScreen ? openDrawer : toggleDropdownOpen}
                rightIcon={<AnimatedChevron isOpen={isDropdownOpen} />}
                variant="ghost"
                whiteSpace="inherit"
            >
                {getContextName(pathContext)}
            </Button>
            <ContextSelectDropdown
                actions={drawerContextActions}
                getIsSelectedContext={getIsSelectedContext}
                isOpen={isDropdownOpen}
                onClose={onDropdownClose}
            />
            <ContextSelectDrawer
                actions={drawerContextActions}
                getIsSelectedContext={getIsSelectedContext}
                isOpen={isDrawerOpen}
                onClose={onDrawerClose}
            />
        </Box>
    );
};

export default ContextSelect;
