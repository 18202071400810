import React, { FC, useEffect, useMemo } from 'react';
import { Box, Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import { styled } from 'sp-ui';
import { ProductGroup } from 'ts/client/gallery/components';
import {
    convertIEventPhotosToIImages,
    getItemDisplayName,
    sortByDisplayOrder
} from 'ts/client/gallery/components/common';
import { ExpandableHtml } from 'ts/common/components';
import { GalleryHeading } from 'ts/common/components/gallery';
import { useStore } from 'client_react/gallery/hooks/StoreContext';
import { PageIndex } from '../StorePage';

const GroupDescription = styled(Text)`
    p {
        color: inherit;
        font-size: inherit;
        line-height: inherit;
    }
`;

const SelectProductPage: FC = () => {
    const {
        goToPage,
        photos,
        priceSheetGroups,
        selectedPriceSheetGroup,
        setImageOnSelectedGroup,
        setSelectedPriceSheetGroup,
        setSelectedPriceSheetItem
    } = useStore();

    const gap = 16;
    const responsiveColumnGap = useBreakpointValue({ base: gap / 2, lg: gap }) ?? gap;
    const productGroupMaxWidth = useBreakpointValue({
        base: `calc(50% - ${responsiveColumnGap / 2}px)`,
        md: `calc(33% - ${gap / 2 + 1}px)`
    });

    useEffect(() => {
        setSelectedPriceSheetGroup(
            (selectedPriceSheetGroup) =>
                priceSheetGroups.find(
                    (priceSheetGroup) => priceSheetGroup.id === selectedPriceSheetGroup?.id
                ) ?? selectedPriceSheetGroup
        );
    }, [priceSheetGroups, setSelectedPriceSheetGroup]);

    const { description, groupType, items = [], name = '' } = selectedPriceSheetGroup || {};

    const sortedItems = sortByDisplayOrder(Object.values(items));
    const isDigitalsGroupType = groupType === 'digitals';
    const isOrnamentsGroupType = groupType === 'ornaments';
    const { boundedItems, customSubgroups, unboundedItems } = sortedItems.reduce(
        ({ boundedItems, customSubgroups, unboundedItems }, item) => {
            const subgroupName = item.subgroup?.name;

            if (
                ['packages', 'products'].includes(groupType ?? '') ||
                subgroupName === null ||
                subgroupName === 'No Size Specified'
            ) {
                unboundedItems.push(item);
            } else if (isOrnamentsGroupType && subgroupName) {
                if (!customSubgroups['ornaments']) {
                    customSubgroups['ornaments'] = [];
                }

                if (!customSubgroups['ornaments'].find((x) => x.subgroup?.name === subgroupName)) {
                    customSubgroups['ornaments'].push(item);
                }
            } else if (isDigitalsGroupType) {
                unboundedItems.push(item);
            } else if (item.bounds) {
                boundedItems.push(item);
            } else if (subgroupName) {
                if (!customSubgroups[subgroupName]) {
                    customSubgroups[subgroupName] = [];
                }

                customSubgroups[subgroupName].push(item);
            }

            return { boundedItems, customSubgroups, unboundedItems };
        },
        {
            boundedItems: [] as SpApi.Client.IPriceSheetItem[],
            customSubgroups: {} as { [subgroupName: string]: SpApi.Client.IPriceSheetItem[] },
            unboundedItems: [] as SpApi.Client.IPriceSheetItem[]
        }
    );

    const numberOfProducts =
        boundedItems.length + unboundedItems.length + Object.keys(customSubgroups).length;

    const imagesForProducts = useMemo(() => {
        return convertIEventPhotosToIImages(photos, true, numberOfProducts);
    }, [numberOfProducts, photos]);

    if (!selectedPriceSheetGroup || !photos) {
        return null;
    }

    const boundedItemsPriceSheetGroup = { ...selectedPriceSheetGroup, items: boundedItems };

    const goToSelectProductOptionsSlideWithItem = (
        priceSheetItem: SpApi.Client.IPriceSheetItem
    ) => {
        setSelectedPriceSheetItem(priceSheetItem);
        goToPage(PageIndex.SelectProductOptions, priceSheetItem.name);
    };

    const goToSelectProductOptionsSlideWithGroup = (
        priceSheetGroup: SpApi.Client.IPriceSheetGroup
    ) => {
        const { items } = priceSheetGroup;

        if (items.length > 1) {
            setSelectedPriceSheetItem(null);
            setSelectedPriceSheetGroup(priceSheetGroup);
        } else {
            setSelectedPriceSheetItem(items[0]);
        }

        // TODO: correct?
        goToPage(PageIndex.SelectProductOptions, priceSheetGroup.name);
    };

    return (
        <Box maxWidth="1440px" padding="48px 20px">
            <GalleryHeading
                marginBottom="16px"
                size="md"
                text={name}
                variant="primary"
                data-testid="select-product-heading"
            />
            {description && (
                <GroupDescription
                    as="span"
                    fontSize="md"
                    marginBottom="24px"
                    data-testid="select-product-description"
                >
                    <ExpandableHtml html={description} maxLineCount={4} />
                </GroupDescription>
            )}
            {imagesForProducts.length && imagesForProducts.length > 0 && (
                <Flex
                    flexWrap="wrap"
                    columnGap={`${responsiveColumnGap}px`}
                    rowGap="20px"
                    marginBottom="24px"
                >
                    {boundedItems.length > 0 && (
                        <ProductGroup
                            image={imagesForProducts[0]}
                            maxWidth={productGroupMaxWidth}
                            onClick={() => {
                                setImageOnSelectedGroup(imagesForProducts[0]);
                                goToSelectProductOptionsSlideWithGroup(boundedItemsPriceSheetGroup);
                            }}
                            priceSheetGroup={boundedItemsPriceSheetGroup}
                        />
                    )}
                    {unboundedItems.map((item, index) => (
                        <ProductGroup
                            image={imagesForProducts[index]}
                            key={index}
                            maxWidth={productGroupMaxWidth}
                            onClick={() => {
                                setImageOnSelectedGroup(imagesForProducts[index]);
                                goToSelectProductOptionsSlideWithItem(item);
                            }}
                            priceSheetGroup={{
                                ...selectedPriceSheetGroup,
                                items: [item],
                                name: getItemDisplayName(item)
                            }}
                        />
                    ))}
                </Flex>
            )}
            {imagesForProducts.length &&
                imagesForProducts.length > 0 &&
                Object.entries(customSubgroups).map(([subgroupName, items], subgroupIndex) => (
                    <React.Fragment key={subgroupIndex}>
                        {!isOrnamentsGroupType && (
                            <GalleryHeading
                                marginBottom="28px"
                                size="sm"
                                text={subgroupName}
                                variant="primary"
                            />
                        )}
                        <Flex
                            flexWrap="wrap"
                            columnGap={`${responsiveColumnGap}px`}
                            rowGap="20px"
                            marginBottom="24px"
                        >
                            {items.map((item, itemIndex) => (
                                <ProductGroup
                                    image={imagesForProducts[subgroupIndex]}
                                    key={itemIndex}
                                    maxWidth={productGroupMaxWidth}
                                    onClick={() => {
                                        setImageOnSelectedGroup(imagesForProducts[subgroupIndex]);
                                        goToSelectProductOptionsSlideWithItem(item);
                                    }}
                                    priceSheetGroup={{
                                        ...selectedPriceSheetGroup,
                                        items: [item],
                                        name:
                                            isOrnamentsGroupType &&
                                            item.subgroup &&
                                            item.subgroup.name
                                                ? item.subgroup.name
                                                : item.name
                                    }}
                                />
                            ))}
                        </Flex>
                    </React.Fragment>
                ))}
        </Box>
    );
};

export default SelectProductPage;
