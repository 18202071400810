import angular from 'angular';
import {
    DIGITAL_RULE_EVENTS,
    EVENT_ALBUM_EVENTS,
    EVENT_EVENTS,
    GOOGLE_PHOTOS_EXPORT_EVENTS,
    PHOTO_DOWNLOAD_EVENTS,
    ZIP_BUNDLE_EVENTS
} from 'Sp/Angular/Events/Client/Gallery';
import { findBy, pluck } from 'Sp/Array';
import { DOWNLOAD_DESTINATION, DOWNLOAD_TYPE } from 'Sp/Gallery';

/**
 * @ngdoc service
 * @name sp.client.gallery.service:spFreeDigitals
 * @requires $rootScope
 * @requires $routeParams
 * @requires $window
 * @requires SPModal
 * @requires spClientCredentials
 * @requires translateFilter
 *
 * @description
 * Handles permissions around, information about, and ability to download free digitals
 */
export default [
    '$rootScope',
    '$routeParams',
    '$window',
    'SPModal',
    'spClientCredentials',
    'spHiddenAlbum',
    'spAppData',
    'spToast',
    'translateFilter',
    'spDevice',
    'spAuthorizationToken',
    function spFreeDigitalsService(
        $rootScope,
        $routeParams,
        $window,
        SPModal,
        spClientCredentials,
        spHiddenAlbum,
        spAppData,
        spToast,
        translateFilter,
        spDevice,
        spAuthorizationToken
    ) {
        const PIN_LENGTH = {
            MAXIMUM: 8,
            MINIMUM: 4
        };
        const { noop } = angular;
        const state = {
            authorizationToken: null,
            eventAuthorizationToken: null,
            pendingDownload: {},
            credentials: {
                email: null,
                pins: [],
                doesAcceptTerms: false
            },
            event: {
                hasDigitalRules: false,
                hasDigitalRulesForDownloadAll: false,
                hasPinForDigitalRules: false,
                maximumDownloadAllPhotoCount: null,
                hasAccessToZipBundleDigitalRules: false,
                hasAccessToPhotoDownloadDigitalRules: false
            },
            eventAlbum: {
                hasDigitalRules: false,
                hasDigitalRulesForDownloadAll: false,
                hasPinForDigitalRules: false,
                maximumDownloadAllPhotoCount: null,
                hasAccessToZipBundleDigitalRules: false,
                hasAccessToPhotoDownloadDigitalRules: false
            },
            digitalRules: null,
            digitalRuleCount: 0,
            selectedDigitalRule: null,
            isEventContact: false,
            downloadForRender: spDevice.deviceIsIOs()
        };

        let afterPinCapturedCallback = noop;
        let isCapturingPin = false;

        function canDownloadPhoto() {
            if (!state.eventAlbum.hasDigitalRules && spHiddenAlbum.isInFlow()) {
                return false;
            }

            return (
                (state.event.hasDigitalRules || state.eventAlbum.hasDigitalRules) &&
                (state.event.hasAccessToPhotoDownloadDigitalRules ||
                    state.eventAlbum.hasAccessToPhotoDownloadDigitalRules)
            );
        }

        $rootScope.$watch('userState.isEventContact', function withIsEventContact(isEventContact) {
            if (isEventContact) {
                state.isEventContact = isEventContact;

                enableEventLevelDigitalRuleAccess();
            }
        });

        $rootScope.$on(EVENT_EVENTS.GOT, function withEvent(_, event) {
            const digitalRules = event.digitalRules.items;
            const hasOnlyEventContactDigitalRules = digitalRules.every(
                isDigitalRuleForEventContact
            );
            const hasOnlyEventContactZipBundleDigitalRules = digitalRules
                .filter(function isZipBundleDigitalRule(digitalRule) {
                    return digitalRule.downloadAll;
                })
                .every(isDigitalRuleForEventContact);

            state.digitalRuleCount += digitalRules.length;
            state.event.hasDigitalRules = digitalRules.length > 0;
            state.event.hasDigitalRulesForDownloadAll = hasDownloadAllDigitalRules(digitalRules);
            state.event.hasPinForDigitalRules = checkForDigitalRulePinPresence(digitalRules);
            state.event.maximumDownloadAllPhotoCount =
                event.digitalRules.meta.downloadAllMaximumPhotoCount;

            if (
                !hasOnlyEventContactDigitalRules ||
                (hasOnlyEventContactDigitalRules && state.isEventContact)
            ) {
                enableEventLevelDigitalRuleAccess();
            }

            if (hasOnlyEventContactZipBundleDigitalRules && !state.isEventContact) {
                state.event.hasAccessToZipBundleDigitalRules = false;
            }

            function isDigitalRuleForEventContact(digitalRule) {
                return digitalRule.isForEventContact;
            }

            $rootScope.isDownloadPhotoPossible = canDownloadPhoto();
            $rootScope.isDownloadAllPossible = $rootScope.canDownload();
        });

        $rootScope.$on(EVENT_ALBUM_EVENTS.GOT, function withEventAlbum(_, eventAlbum) {
            const digitalRules = eventAlbum.digitalRules.items;

            state.digitalRuleCount += digitalRules.length;
            state.eventAlbum.hasDigitalRules = digitalRules.length > 0;
            state.eventAlbum.hasDigitalRulesForDownloadAll =
                hasDownloadAllDigitalRules(digitalRules);
            state.eventAlbum.hasPinForDigitalRules = checkForDigitalRulePinPresence(digitalRules);
            state.eventAlbum.maximumDownloadAllPhotoCount =
                eventAlbum.digitalRules.meta.downloadAllMaximumPhotoCount;

            if (
                state.eventAlbum.hasDigitalRulesForDownloadAll ||
                state.eventAlbum.hasPinForDigitalRules
            ) {
                state.eventAlbum.hasAccessToZipBundleDigitalRules = true;
                state.eventAlbum.hasAccessToPhotoDownloadDigitalRules = true;
            }

            if (state.eventAlbum.hasDigitalRules) {
                state.eventAlbum.hasAccessToPhotoDownloadDigitalRules = true;
            }

            $rootScope.isDownloadPhotoPossible = canDownloadPhoto();
            $rootScope.isDownloadAllPossible = $rootScope.canDownload();
        });

        $rootScope.$on(DIGITAL_RULE_EVENTS.LISTED, function withDigitalRules(_, digitalRules) {
            let shouldTryToDownload = true;

            state.digitalRules = digitalRules;

            if (!requiresPins()) {
                if (!hasDownloadAllDigitalRules(state.digitalRules)) {
                    state.event.hasAccessToZipBundleDigitalRules = false;
                    state.eventAlbum.hasAccessToZipBundleDigitalRules = false;

                    if (
                        state.pendingDownload.type === DOWNLOAD_TYPE.ALL ||
                        state.pendingDownload.type === DOWNLOAD_TYPE.ALBUM
                    ) {
                        shouldTryToDownload = false;
                    }
                }

                if (digitalRules.length === 0) {
                    state.event.hasAccessToPhotoDownloadDigitalRules = false;
                    state.eventAlbum.hasAccessToPhotoDownloadDigitalRules = false;

                    if (state.pendingDownload.type === DOWNLOAD_TYPE.PHOTO) {
                        shouldTryToDownload = false;
                    }
                }
            }

            if (
                state.digitalRuleCount === 1 &&
                state.digitalRules.length === 1 &&
                state.digitalRules[0].downloadLimit === null
            ) {
                state.selectedDigitalRule = state.digitalRules[0];
            }

            afterPinCapturedCallback(getDigitalRules());

            afterPinCapturedCallback = noop;

            if (isCapturingPin && shouldTryToDownload) {
                tryToDownload();
            } else {
                SPModal.close().then(shouldTryToDownload ? tryToDownload : null);
            }
        });

        $rootScope.$on(DIGITAL_RULE_EVENTS.LIST_ERROR, function clearEmailOnGdprDenial() {
            state.credentials.email = null;
            spClientCredentials.clear();
        });

        $rootScope.$on(ZIP_BUNDLE_EVENTS.CREATED, function withResponse(_, response) {
            state.credentials.email = response.email;
            spClientCredentials.set({ zipBundleKey: response.zipBundleKey });

            SPModal.close().then(function consumeSelectedDigitalRuleAndOpenZipBundleCreatedModal() {
                $window.open(response.url, '_blank');
            });
        });

        $rootScope.$on(ZIP_BUNDLE_EVENTS.ERRORS.ALBUM, clearDigitalRulesAndTryToDownload);
        $rootScope.$on(ZIP_BUNDLE_EVENTS.ERRORS.PIN, undoDigitalRuleSelectionAndCaptureCredentials);

        $rootScope.$on(GOOGLE_PHOTOS_EXPORT_EVENTS.ERRORS.UNAUTHORIZED, () => {
            $rootScope.isSignedIntoGoogle = false;
            signIntoGoogle();
        });

        $rootScope.$on(GOOGLE_PHOTOS_EXPORT_EVENTS.ERRORS.UNKNOWN, () => {
            spToast.toast({
                title: translateFilter('downloadUnknownError'),
                status: 'error',
                isClosable: true
            });
        });

        $rootScope.$on(GOOGLE_PHOTOS_EXPORT_EVENTS.CREATED, (_, response) => {
            if (!response.googlePhotosAlbumName && response.photoIds.length === 1) {
                if (response.digitalRule.downloadLimit > 0) {
                    const digitalRule = state.digitalRules.find(
                        ({ id }) => id === response.digitalRule.id
                    );

                    digitalRule.downloadLimitUsed = response.digitalRule.downloadLimitUsed;

                    const photoId = response.photoIds[0];

                    if (!digitalRule.downloadedPhotoIds.includes(photoId)) {
                        digitalRule.downloadedPhotoIds.push(photoId);
                    }
                }

                setTimeout(() => {
                    $rootScope.$emit(GOOGLE_PHOTOS_EXPORT_EVENTS.GET, {
                        id: response.exportId,
                        authorizationToken: state.eventAuthorizationToken
                    });
                }, 1000);
            } else {
                const statusPageUrl = `google-photos-export-view/${response.exportId}`;
                $rootScope.navigate(statusPageUrl);
            }
        });

        $rootScope.$on(GOOGLE_PHOTOS_EXPORT_EVENTS.GOT, (_, response) => {
            // TODO Handle failed exports. Right now this only handles successful and pending exports.
            if (response.googlePhotosUrl) {
                if (!response.googlePhotosAlbumName && response.photoIds.length === 1) {
                    const toastOptions = {
                        title: translateFilter('downloadSuccessful'),
                        status: 'success',
                        isClosable: true
                    };

                    if (getNumberOfDownloadsRemaining(response.digitalRule) > 0) {
                        toastOptions.description = translateFilter(
                            'freeDigitalsRemainingDownloadLimit',
                            {
                                count: getNumberOfDownloadsRemaining(response.digitalRule),
                                name: response.digitalRule.name
                            }
                        );
                    }

                    spToast.toast(toastOptions);
                }
            } else {
                setTimeout(() => {
                    $rootScope.$emit(GOOGLE_PHOTOS_EXPORT_EVENTS.GET, {
                        id: response.exportId,
                        authorizationToken: state.eventAuthorizationToken
                    });
                }, 1000);
            }
        });

        $rootScope.$on(PHOTO_DOWNLOAD_EVENTS.CREATED, function withPhotoDownload(_, photoDownload) {
            if (
                state.selectedDigitalRule?.downloadLimit > 0 &&
                state.selectedDigitalRule?.downloadLimitUsed !==
                    photoDownload.digitalRule?.downloadLimitUsed
            ) {
                const digitalRule = findBy('id', state.selectedDigitalRule.id, state.digitalRules);

                digitalRule.downloadLimitUsed = photoDownload.digitalRule?.downloadLimitUsed;
                digitalRule.downloadedPhotoIds.push(state.pendingDownload.data.photoId);
            }

            SPModal.close().then(() => {
                state.pendingDownload = {};
                state.selectedDigitalRule = null;
            });

            const toastOptions = {
                title: translateFilter('downloadSuccessful'),
                status: 'success',
                isClosable: true
            };

            if (getNumberOfDownloadsRemaining(photoDownload.digitalRule) > 0) {
                toastOptions['description'] = translateFilter(
                    'freeDigitalsRemainingDownloadLimit',
                    {
                        count: getNumberOfDownloadsRemaining(photoDownload.digitalRule),
                        name: photoDownload.digitalRule.name
                    }
                );
            }

            if (state.downloadForRender) {
                SPModal.close().then(() => {
                    SPModal.open('photo-download', {
                        partialDirectory: 'free-digitals',
                        modalData: {
                            renderPhoto: photoDownload,
                            closeModal: function closeModal() {
                                SPModal.close();
                                cancelDownload();
                            }
                        }
                    });
                });
            } else {
                spToast.toast(toastOptions);
                $window.location.assign(photoDownload.downloadUrl);
            }
        });

        $rootScope.$on(PHOTO_DOWNLOAD_EVENTS.ERRORS.ALBUM, clearDigitalRulesAndTryToDownload);
        $rootScope.$on(
            PHOTO_DOWNLOAD_EVENTS.ERRORS.PIN,
            undoDigitalRuleSelectionAndCaptureCredentials
        );
        $rootScope.$emit(EVENT_EVENTS.GET);
        getEventAlbumDigitalRulePresence();

        $rootScope.$on('$routeChangeSuccess', function updateEventAlbumDigitalRulePresence() {
            state.digitalRules = null;
            state.digitalRuleCount = 0;
            state.eventAlbum.hasDigitalRules = false;
            state.eventAlbum.hasDigitalRulesForDownloadAll = false;
            state.eventAlbum.hasPinForDigitalRules = false;
            state.eventAlbum.hasAccessToZipBundleDigitalRules = false;
            state.eventAlbum.hasAccessToPhotoDownloadDigitalRules = false;

            getEventAlbumDigitalRulePresence();
        });

        return {
            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spFreeDigitals#canDownloadAll
             * @methodOf sp.client.gallery.service:spFreeDigitals
             *
             * @param {integer} photoCount Number of photos to download
             * @returns {boolean} True if user is allowed to download all photos
             */
            canDownloadAll: function canDownloadAll(photoCount) {
                if (
                    state.event.maximumDownloadAllPhotoCount &&
                    photoCount > state.event.maximumDownloadAllPhotoCount
                ) {
                    return false;
                }

                return (
                    state.event.hasDigitalRulesForDownloadAll &&
                    state.event.hasAccessToZipBundleDigitalRules
                );
            },
            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spFreeDigitals#canDownloadAlbum
             * @methodOf sp.client.gallery.service:spFreeDigitals
             *
             * @param {integer} photoCount Number of photos to download
             * @returns {boolean} True if user is allowed to download all photos in an album
             */
            canDownloadAlbum: function canDownloadAlbum(photoCount) {
                if (!state.eventAlbum.hasDigitalRulesForDownloadAll && spHiddenAlbum.isInFlow()) {
                    return false;
                }

                const hasEventAlbumZipBundleDigitalRules =
                    state.eventAlbum.hasDigitalRulesForDownloadAll &&
                    state.eventAlbum.hasAccessToZipBundleDigitalRules;
                let isWithinEventAlbumPhotoCountThreshold = true;

                if (state.eventAlbum.maximumDownloadAllPhotoCount) {
                    isWithinEventAlbumPhotoCountThreshold =
                        photoCount <= state.eventAlbum.maximumDownloadAllPhotoCount;
                }

                return (
                    this.canDownloadAll(photoCount) ||
                    (hasEventAlbumZipBundleDigitalRules && isWithinEventAlbumPhotoCountThreshold)
                );
            },
            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spFreeDigitals#canDownloadPhoto
             * @methodOf sp.client.gallery.service:spFreeDigitals
             *
             * @returns {boolean} True if user is allowed to download a photo
             */
            canDownloadPhoto,
            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spFreeDigitals#downloadAll
             * @methodOf sp.client.gallery.service:spFreeDigitals
             *
             * @description
             * Will attempt to download all photos in the gallery
             *
             * @param {Array<Object>=} photos A subset of photos to download
             */
            downloadAll: function downloadAll(photos) {
                state.pendingDownload = {
                    type: DOWNLOAD_TYPE.ALL,
                    data: {
                        // TODO: Use 'Favorites' for the album name if the user is downloading their favorites?
                        googlePhotosAlbumName: $rootScope.eventData.name,
                        photos: photos
                    }
                };

                tryToDownload();
            },
            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spFreeDigitals#downloadAlbum
             * @methodOf sp.client.gallery.service:spFreeDigitals
             *
             * @description
             * Will attempt to download all photos in the current album
             *
             * @param {Object} album An album
             * @param {string} album.id The album id
             * @param {string} album.name The album name
             */
            downloadAlbum: function downloadAlbum(album) {
                state.pendingDownload = {
                    type: DOWNLOAD_TYPE.ALBUM,
                    data: {
                        googlePhotosAlbumId: album.id,
                        googlePhotosAlbumName: album.name,
                        albumName: album.name
                    }
                };

                tryToDownload();
            },
            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spFreeDigitals#downloadPhoto
             * @methodOf sp.client.gallery.service:spFreeDigitals
             *
             * @description
             * Will attempt to download the provided photo or current photo in view
             *
             * @param {integer=} photoId The id of the photo
             */
            downloadPhoto: function downloadPhoto(photoId) {
                state.pendingDownload = {
                    type: DOWNLOAD_TYPE.PHOTO,
                    data: {
                        photoId: photoId || Number($routeParams.photoId)
                    }
                };

                tryToDownload();
            },
            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spFreeDigitals#isTryingToDownloadAll
             * @methodOf sp.client.gallery.service:spFreeDigitals
             *
             * @returns {boolean} True if currently trying to download all photos in the gallery
             */
            isTryingToDownloadAll: function isTryingToDownloadAll() {
                return state.pendingDownload.type === DOWNLOAD_TYPE.ALL;
            },
            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spFreeDigitals#isTryingToDownloadAlbum
             * @methodOf sp.client.gallery.service:spFreeDigitals
             *
             * @returns {boolean} True if currently trying to download all photos in the current album
             */
            isTryingToDownloadAlbum: function isTryingToDownloadAlbum() {
                return state.pendingDownload.type === DOWNLOAD_TYPE.ALBUM;
            },
            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spFreeDigitals#isTryingToDownloadPhoto
             * @methodOf sp.client.gallery.service:spFreeDigitals
             *
             * @returns {boolean} True if currently trying to download the current photo in view
             */
            isTryingToDownloadPhoto: function isTryingToDownloadPhoto() {
                return state.pendingDownload.type === DOWNLOAD_TYPE.PHOTO;
            },
            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spFreeDigitals#getPendingDownloadData
             * @methodOf sp.client.gallery.service:spFreeDigitals
             *
             * @returns {Object|null} Pending download data
             */
            getPendingDownloadData: function getPendingDownloadData() {
                return state.pendingDownload.data ? angular.copy(state.pendingDownload.data) : null;
            },
            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spFreeDigitals#getEmail
             * @methodOf sp.client.gallery.service:spFreeDigitals
             *
             * @returns {string|null} Current email in credentials
             */
            getEmail: function getEmail() {
                return state.credentials.email;
            },
            canExportToGoogle,
            disconnectFromGoogle,
            getGoogleEmail,
            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spFreeDigitals#hasPins
             * @methodOf sp.client.gallery.service:spFreeDigitals
             *
             * @returns {boolean} True if any of the current digital rules has a PIN
             */
            hasPins: requiresPins,
            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spFreeDigitals#getDigitalRules
             * @methodOf sp.client.gallery.service:spFreeDigitals
             *
             * @returns {Array<Object>} Array of currently applicable free digital rules
             */
            getDigitalRules,
            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spFreeDigitals#captureAnotherPin
             * @methodOf sp.client.gallery.service:spFreeDigitals
             *
             * @description
             * Prepares the service for capturing another PIN to fetch digital rules with
             * #setCredentials
             *
             * @param {function=} afterPinCaptured Invoked with the new digital rules after the PIN
             *     has been captured
             */
            captureAnotherPin: function captureAnotherPin(afterPinCaptured = noop) {
                afterPinCapturedCallback = afterPinCaptured;
                isCapturingPin = true;

                delete state.digitalRules;
            },
            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spFreeDigitals#selectDigitalRule
             * @methodOf sp.client.gallery.service:spFreeDigitals
             *
             * @description
             * Sets the digital rule and continues to generate the request for the current download
             *
             * @param {Object} digitalRule A digital rule
             */
            selectDigitalRule: function selectDigitalRule(digitalRule, downloadDestination) {
                state.selectedDigitalRule = digitalRule;
                state.pendingDownload.destination = downloadDestination;
                $rootScope.requiredDestinationSelectionType = null;
                $rootScope.requiredDestinationSelectionPhotoId = null;

                tryToDownload();
            },
            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spFreeDigitals#setAuthorizationToken
             * @methodOf sp.client.gallery.service:spFreeDigitals
             *
             * @description
             * Sets the authorization token value for zip bundle requests
             *
             * @param {string} authorizationToken An authorization token
             */
            setAuthorizationToken: function setAuthorizationToken(authorizationToken) {
                state.authorizationToken = authorizationToken;
            },
            setEventAuthorizationToken,
            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spFreeDigitals#setCredentials
             * @methodOf sp.client.gallery.service:spFreeDigitals
             *
             * @description
             * Sets the credentials and continues to generate the request for the current download
             *
             * @param {Object} credentials Credentials
             * @param {string} credentials.email An email address
             * @param {boolean} credentials.doesAcceptTerms a checkbox
             * @param {string=} credentials.pin A PIN
             */
            setCredentials: function setCredentials(credentials) {
                state.credentials.email = credentials.email;
                state.credentials.doesAcceptTerms = credentials.doesAcceptTerms;

                if (
                    credentials.pin &&
                    credentials.pin.length >= PIN_LENGTH.MINIMUM &&
                    credentials.pin.length <= PIN_LENGTH.MAXIMUM
                ) {
                    state.credentials.pins.push(credentials.pin);
                }

                tryToDownload();
            },
            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spFreeDigitals#hasSelectedADigitalRule
             * @methodOf sp.client.gallery.service:spFreeDigitals
             *
             * @return {boolean} True if user has selected a digital rule
             */
            hasSelectedADigitalRule: function hasSelectedADigitalRule() {
                return state.selectedDigitalRule !== null;
            },
            /**
             * Sets the download destination for the current pending download
             * The valid strings are in DOWNLOAD_DESTINATION from Sp/Gallery
             * @param {string} destination
             */
            setDestination: function setDestination(destination) {
                state.pendingDownload.destination = destination;
                $rootScope.requiredDestinationSelectionType = null;
                $rootScope.requiredDestinationSelectionPhotoId = null;

                tryToDownload();
            },
            /**
             * Get the number of remaining download for the currently selected digital rule.
             * If no rule is currently selected, return null
             * @returns {number|null}
             */
            getRemainingDownloadsForCurrentRule: function getRemainingDownloadsForCurrentRule() {
                return getNumberOfDownloadsRemaining(state.selectedDigitalRule);
            },
            /**
             * Clear all information regarding the current pending download
             */
            cancelDownload
        };

        function cancelDownload() {
            state.pendingDownload = {};
            state.selectedDigitalRule = null;
            $rootScope.requiredDestinationSelectionType = null;
            $rootScope.requiredDestinationSelectionPhotoId = null;
        }

        function getNumberOfDownloadsRemaining(digitalRule) {
            if (!digitalRule || digitalRule.downloadLimit === null) {
                return null;
            }

            const numRemaining = digitalRule.downloadLimit - digitalRule.downloadLimitUsed;

            if (numRemaining < 0) {
                return 0;
            }

            return numRemaining;
        }

        function getEventAlbumDigitalRulePresence() {
            const currentAlbumId = getAlbumId();

            if (currentAlbumId) {
                $rootScope.$emit(EVENT_ALBUM_EVENTS.GET, currentAlbumId);
            }
        }

        function getAlbumId() {
            const { albumId } = $routeParams;

            if (albumId && albumId !== 'all') {
                return albumId;
            }
        }

        function getGooglePhotosPhotoIds() {
            if (state.pendingDownload.data.photoId !== undefined) {
                return [state.pendingDownload.data.photoId];
            }

            if (state.pendingDownload.data.photos instanceof Array) {
                return state.pendingDownload.data.photos.map((photo) => photo.id);
            }

            return undefined;
        }

        function hasDownloadAllDigitalRules(digitalRules) {
            return digitalRules.some(function withDigitalRule(digitalRule) {
                return digitalRule.downloadAll;
            });
        }

        function checkForDigitalRulePinPresence(digitalRules) {
            return digitalRules.some(function withDigitalRule(digitalRule) {
                return digitalRule.hasDownloadPin;
            });
        }

        function canExportToGoogle() {
            return state.pendingDownload.type !== DOWNLOAD_TYPE.PHOTO;
        }

        function requiresPins() {
            return state.event.hasPinForDigitalRules || state.eventAlbum.hasPinForDigitalRules;
        }

        function signIntoGoogle() {
            // Set a new item in local storage to a random UUID:
            const authKey = crypto.randomUUID();
            localStorage.setItem(authKey, '');

            // Construct a URL to redirect to the Google Photos OAuth sign-in page:
            const redirectUrl = `${$rootScope.eventData.publicUrl}/google-oauth-redirect`;
            const stateQueryParam = encodeURIComponent(JSON.stringify({ authKey, redirectUrl }));
            const { apiRoot } = spAppData.get('urls');
            const isSmallScreen = innerWidth < 992;
            const authWindowUrl = `${apiRoot}/oauth/google?email=${state.credentials.email}&state=${stateQueryParam}`;

            // Open a new tab (small screens) or popup window (large screens) to the Google Photos OAuth URL:
            const newWindow = open(
                authWindowUrl,
                '/',
                isSmallScreen ? undefined : 'popup,width=992,height=620'
            );

            if (newWindow) {
                // Listen for local storage to change. Once authKey has been removed, Google OAuth succeeded, so continue...
                const storageListener = async (event) => {
                    if (event.key === authKey && event.newValue) {
                        removeEventListener('storage', storageListener);
                        localStorage.removeItem(authKey);

                        const cacheKey = event.newValue;

                        const authorizationToken =
                            await spAuthorizationToken.getAuthorizationTokenForEvent(true, {
                                email: state.credentials.email,
                                google_oauth_refresh_token_cache_key: cacheKey
                            });

                        // Try again to send a POST request to GooglePhotosExportController to create a new export:
                        $rootScope.$emit(GOOGLE_PHOTOS_EXPORT_EVENTS.CREATE, {
                            authorizationToken,
                            clientGalleryUrl: $rootScope.eventData.publicUrl,
                            digitalRuleId: state.selectedDigitalRule.id,
                            googlePhotosAlbumName: state.pendingDownload.data.googlePhotosAlbumName,
                            albumId: state.pendingDownload.data.googlePhotosAlbumId,
                            photoIds: getGooglePhotosPhotoIds()
                        });

                        state.pendingDownload = {};
                        state.selectedDigitalRule = null;
                    }
                };

                addEventListener('storage', storageListener);
            } else {
                // If newWindow is falsy, then the popup was blocked, because this function was called asynchronously
                // in response to the GOOGLE_PHOTOS_EXPORT_EVENTS.ERRORS.UNAUTHORIZED event being broadcast.

                // This happens if the front-end thought the user was signed into Google, but the client auth token thought otherwise.
                // Ask the user to try again, since this time their click will synchronously open the Google sign-in popup, which won't be blocked.

                spToast.toast({
                    title: translateFilter('downloadGoogleAuthWindowError'),
                    status: 'error',
                    isClosable: true
                });
            }
        }

        async function disconnectFromGoogle() {
            // Request a new client access token that doesn't include a google_oauth_refresh_token_cache_key:

            await spAuthorizationToken.getAuthorizationTokenForEvent(true, {
                email: state.credentials.email
            });
        }

        function getGoogleEmail() {
            if ($rootScope.isSignedIntoGoogle) {
                return $rootScope.googleEmail ?? null;
            }

            return null;
        }

        /**
         * This function can be called several times for a single download as there can be several times where the user needs to interact
         * to continue with the download.
         * @returns void
         */
        function tryToDownload() {
            // Check if the user needs to enter an email address
            if (requiresCredentials()) {
                captureCredentials();
                return;
            }

            spClientCredentials.set({ email: state.credentials.email });

            // Check if the digital rules need to be fetched
            if (!state.digitalRules) {
                const doesAcceptTerms =
                    state.credentials.doesAcceptTerms || spAppData.get('userData').doesAcceptTerms;

                $rootScope.$emit(DIGITAL_RULE_EVENTS.LIST, {
                    albumId: getAlbumId(),
                    email: state.credentials.email,
                    pins: state.credentials.pins,
                    doesAcceptTerms
                });

                return;
            }

            if (state.digitalRules.length === 1 && !requiresPins()) {
                // Handle single-rule, no-pin galleries
                const [digitalRule] = state.digitalRules;
                const canDownloadPhoto =
                    digitalRule.downloadLimit === null ||
                    getNumberOfDownloadsRemaining(digitalRule) > 0;
                const hasAlreadyDownloadedPhoto = digitalRule.downloadedPhotoIds.includes(
                    state.pendingDownload.data.photoId
                );

                if (canDownloadPhoto || hasAlreadyDownloadedPhoto) {
                    state.selectedDigitalRule = digitalRule;

                    // Check if the user needs to pick a download destination
                    if (requiresDestinationSelection()) {
                        $rootScope.$evalAsync(() => {
                            $rootScope.requiredDestinationSelectionType =
                                state.pendingDownload.type;
                            $rootScope.requiredDestinationSelectionPhotoId =
                                state.pendingDownload.data.photoId;
                        });

                        return;
                    }
                } else {
                    spToast.toast({
                        title: translateFilter('downloadLimitReached'),
                        status: 'error',
                        isClosable: true
                    });

                    return;
                }
            } else if (requiresDigitalRuleSelection()) {
                if (isCapturingPin) {
                    isCapturingPin = false;
                } else {
                    // Allow the user to pick the digital rule to use
                    SPModal.open('select-digital-rule', { partialDirectory: 'free-digitals' });
                }

                return;
            }

            // Handle Google Photos downloads
            if (state.pendingDownload.destination === DOWNLOAD_DESTINATION.GOOGLE_PHOTOS) {
                const googlePhotosExportProperties = {
                    clientGalleryUrl: $rootScope.eventData.publicUrl,
                    digitalRuleId: state.selectedDigitalRule.id,
                    googlePhotosAlbumName: state.pendingDownload.data.googlePhotosAlbumName,
                    albumId: state.pendingDownload.data.googlePhotosAlbumId,
                    photoIds: getGooglePhotosPhotoIds()
                };

                if (state.pendingDownload.data.googlePhotosAlbumName) {
                    // Store the export properties in local storage to be retrieved by GooglePhotosExportPage in a new tab.
                    const authKey = crypto.randomUUID();
                    localStorage.setItem(authKey, JSON.stringify(googlePhotosExportProperties));

                    // Since an album name is given, open GooglePhotosExportPage in a new tab to start this download with multiple photos.
                    const statusPageUrl = `${$rootScope.eventData.publicUrl}/google-photos-export-view?authKey=${authKey}`;

                    open(statusPageUrl, '_blank');

                    // Listen for local storage to change. Once the UUID has been removed, Google OAuth succeeded, so set isSignedIntoGoogle = true.
                    // This allows other downloads on this page to proceed without needing to sign in again.
                    const storageListener = (event) => {
                        if (event.key === authKey && event.newValue) {
                            removeEventListener('storage', storageListener);
                            localStorage.removeItem(authKey);

                            const eventAuthorizationToken = event.newValue;

                            if (!$rootScope.isSignedIntoGoogle) {
                                sessionStorage.setItem(
                                    `clientGalleryAuthorizationTokenForEvent-${$rootScope.eventData.id}`,
                                    eventAuthorizationToken
                                );

                                setEventAuthorizationToken(eventAuthorizationToken);
                            }
                        }
                    };

                    addEventListener('storage', storageListener);
                } else if ($rootScope.isSignedIntoGoogle) {
                    // Since we're already signed in, send a POST request to create a new export and start this single-photo download.
                    $rootScope.$emit(GOOGLE_PHOTOS_EXPORT_EVENTS.CREATE, {
                        ...googlePhotosExportProperties,
                        authorizationToken: state.eventAuthorizationToken
                    });
                } else {
                    // Since we're not signed in, begin the Google OAuth sign-in flow in a new window.
                    signIntoGoogle();
                }

                state.pendingDownload = {};
                state.selectedDigitalRule = null;

                return;
            }

            // This modal is called SelectDigitalRule, but it is used for all downloads, even if a rule does not need to be selected
            SPModal.open('select-digital-rule', { partialDirectory: 'free-digitals' });

            // Perform the download action for device downloads
            if (state.pendingDownload.type === DOWNLOAD_TYPE.PHOTO) {
                $rootScope.$emit(PHOTO_DOWNLOAD_EVENTS.CREATE, {
                    authorizationToken: state.authorizationToken,
                    albumId: getAlbumId(),
                    photoId: state.pendingDownload.data.photoId,
                    digitalRuleId: state.selectedDigitalRule.id,
                    email: state.credentials.email,
                    pins: state.credentials.pins
                });
            } else {
                $rootScope.$emit(ZIP_BUNDLE_EVENTS.CREATE, {
                    authorizationToken: state.authorizationToken,
                    albumId: getAlbumId(),
                    digitalRuleId: state.selectedDigitalRule.id,
                    email: state.credentials.email,
                    pins: state.credentials.pins,
                    photoIds: pluck('id', state.pendingDownload.data.photos || [])
                });

                state.pendingDownload = {};
                state.selectedDigitalRule = null;
            }

            function requiresCredentials() {
                state.credentials.email =
                    state.credentials.email || spClientCredentials.get().readableEmail;

                return !state.credentials.email;
            }

            function requiresDestinationSelection() {
                return canExportToGoogle() && !state.pendingDownload.destination;
            }

            function requiresDigitalRuleSelection() {
                return !state.selectedDigitalRule;
            }
        }

        async function setEventAuthorizationToken(eventAuthorizationToken) {
            // Store the event-level authorization token, for use with requests to the Google Photos Export API.
            state.eventAuthorizationToken = eventAuthorizationToken;

            // Check if the user is signed into Google Photos, and set the isSignedIntoGoogle state as a result.
            const { apiRoot } = spAppData.get('urls');
            const url = `${apiRoot}/oauth/google/status`;

            const options = {
                headers: {
                    Authorization: `Bearer ${eventAuthorizationToken}`
                }
            };

            let data;

            try {
                const response = await fetch(url, options);
                data = await response.json();
            } catch (error) {
                // We can safely ignore this API request error.
                // If the request failed, and the user is not signed in already,
                // then clicking "Save to Google Photos" will prompt them again.
                return;
            }

            $rootScope.isSignedIntoGoogle = data.isSignedIntoGoogle;
            $rootScope.googleEmail = data.googleEmail;
        }

        function captureCredentials() {
            SPModal.open('capture-credentials', { partialDirectory: 'free-digitals' });
        }

        function clearDigitalRulesAndTryToDownload() {
            delete state.selectedDigitalRule;
            delete state.digitalRules;

            tryToDownload();
        }

        function getDigitalRules() {
            return angular.copy(state.digitalRules) || [];
        }

        function undoDigitalRuleSelectionAndCaptureCredentials() {
            delete state.selectedDigitalRule;
            delete state.digitalRules;

            SPModal.close().then(captureCredentials);
        }

        function enableEventLevelDigitalRuleAccess() {
            if (state.event.hasDigitalRules) {
                state.event.hasAccessToPhotoDownloadDigitalRules = true;
            }

            if (state.event.hasDigitalRulesForDownloadAll) {
                state.event.hasAccessToZipBundleDigitalRules = true;
            }
        }
    }
];
