export default [
    '$rootScope',
    function toggleBodyScrollingFacade($rootScope) {
        $rootScope.enableBodyScrolling = function enableBodyScrolling() {
            $rootScope.bodyScrollLocked = false;
        };

        $rootScope.disableBodyScrolling = function disableBodyScrolling() {
            $rootScope.bodyScrollLocked = true;
        };
    }
];
