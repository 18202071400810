import React, { FC } from 'react';
import PageTitle from 'ts/common/components/gallery/PageTitle';
import { getAlbumCaption } from 'ts/common/components/gallery/helpers';
import type { ICoverPhotoBase } from 'ts/common/types';

interface Props {
    /** An optional number of albums to include in the caption */
    albumCount?: number;
    /** An optional cover photo with a URL, to render in the background of the title and caption */
    coverPhoto?: ICoverPhotoBase & { url: string };
    /** Text for a larger heading to show just above the small caption text */
    headingText: string;
    /** True only if the large-screen version should be shown. If not specified, then automatically detect based on viewport width. */
    isLargeScreen?: boolean;
    /** The number of photos to include in the caption */
    photoCount: number;
    /** The Angular translation service */
    translateFilter: SpAngularJs.ITranslateFilter;
}

/** A component that renders a PageTitle, for pages which have a photo count and maybe an album count */
const AlbumPageTitle: FC<Props> = ({
    albumCount,
    coverPhoto,
    headingText,
    isLargeScreen,
    photoCount,
    translateFilter: translate
}) => (
    <PageTitle
        caption={getAlbumCaption({ albumCount, photoCount, translate })}
        coverPhoto={coverPhoto}
        headingText={headingText}
        isLargeScreen={isLargeScreen}
    />
);

export default AlbumPageTitle;
