import cloneDeep from 'lodash.clonedeep';
import spPackageAlbumItemTileTemplate from '../../templates/components/sp-package-album-item-tile.nghtml';

/**
 * @ngdoc component
 * @name sp.client.gallery.component:spPackageAlbumItemTile
 *
 * @requires sp.client.gallery.service:spPackages
 *
 * @description
 * Renders a Package All Photos in Album Item in a tile.
 *
 * @param {object=} album current album
 * @param {array<object>=} albums current array of all albums
 * @param {object} item current item to be displayed
 * @param {number=} itemIndex Index in the Selection State of the Price Sheet Item in a Package
 * @param {array<object>} photos current photo photos array
 */
export default {
    bindings: {
        album: '<?',
        albums: '<?',
        item: '<',
        itemIndex: '<?',
        photos: '<'
    },
    controller: [
        'SPClientData',
        'spPackages',
        function spPackageAlbumItemTileController(SPClientData, spPackages) {
            const $ctrl = this;

            $ctrl.$onChanges = $onChanges;
            $ctrl.currentAlbumSelected = {};
            $ctrl.getSelectionText = getSelectionText;
            $ctrl.getSvgDisplay = getSvgDisplay;
            $ctrl.isAlbumAssigned = isAlbumAssigned;
            $ctrl.isValidAlbum = isValidAlbum;
            $ctrl.onDeleteItemHandler = onDeleteItemHandler;
            $ctrl.onSelectItemHandler = onSelectItemHandler;
            $ctrl.shouldUpdateAlbumAssigment = shouldUpdateAlbumAssigment;

            function $onChanges({ albums }) {
                const { id } = $ctrl.currentAlbumSelected;

                if ($ctrl.currentAlbumSelected && !id && albums && albums.currentValue) {
                    const item = spPackages.getPackageItemsSelectionState();

                    if (item && item[$ctrl.item.id].length) {
                        const albumId = item[$ctrl.item.id][0].albumId;
                        const album = $ctrl.albums.filter(
                            (currentAlbum) => albumId === currentAlbum.id
                        );

                        if (album.length) {
                            $ctrl.currentAlbumSelected = cloneDeep(album[0]);
                        } else if (!album.length && albumId) {
                            getAlbum(albumId);
                        }
                    }
                } else if (!$ctrl.currentAlbumSelected) {
                    $ctrl.currentAlbumSelected = {};
                }
            }

            function dataSuccessCallback(response) {
                let album = typeof response.album === 'object' ? response.album : undefined;

                if (album) {
                    $ctrl.currentAlbumSelected = cloneDeep(album);
                }
            }

            function getAlbum(id) {
                SPClientData.getEventData(
                    {
                        album_id: id
                    },
                    dataSuccessCallback
                );
            }

            function getSelectionText() {
                return isValidAlbum() ? 'chooseAlbumName' : 'viewAlbumToSelect';
            }

            function getSvgDisplay() {
                return isValidAlbum() ? 'iconPlus' : 'iconExclaim';
            }

            function isAlbumAssigned() {
                return Boolean($ctrl.currentAlbumSelected && $ctrl.currentAlbumSelected.id);
            }

            function isValidAlbum() {
                return Boolean(
                    $ctrl.album && $ctrl.album.id !== 'all' && $ctrl.photos && $ctrl.photos[0]
                );
            }

            function onDeleteItemHandler($event) {
                $event.stopPropagation();

                spPackages.selectPackageAllAlbumItem($ctrl.item.id, 0, null, null);

                $ctrl.currentAlbumSelected = {};
            }

            function onSelectItemHandler() {
                if (isValidAlbum()) {
                    spPackages.selectPackageAllAlbumItem(
                        $ctrl.item.id,
                        0,
                        $ctrl.album.id,
                        $ctrl.photos[0]
                    );

                    $ctrl.currentAlbumSelected = cloneDeep($ctrl.album);
                }
            }

            function shouldUpdateAlbumAssigment() {
                if (
                    $ctrl.album &&
                    $ctrl.currentAlbumSelected &&
                    !$ctrl.currentAlbumSelected.hasOwnProperty('id')
                ) {
                    $ctrl.onSelectItemHandler();
                }
            }
        }
    ],
    template: spPackageAlbumItemTileTemplate
};
