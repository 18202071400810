import { PACKAGE_EVENTS } from 'Sp/Angular/Events/Client/Gallery';
import { PACKAGE_SMALL_SCREEN_STATES } from 'Sp/Gallery';
import spPackageItemTileTemplate from '../../templates/components/sp-package-item-tile.nghtml';

/**
 * @ngdoc component
 * @name sp.client.gallery.component:spPackageItemTile
 *
 * @requires sp.client.common.service:SPPhoto
 * @requires sp.common.service:spEventBus
 * @requires sp.client.gallery.service:spPackages
 *
 * @description
 * Renders a Package Item in a tile.
 *
 * @param {object=} album current album
 * @param {object} item current item to be displayed
 * @param {number} itemId ID of a Price Sheet Item in a Package
 * @param {number} itemIndex Index in the Selection State of the Price Sheet Item in a Package
 */
export default {
    bindings: {
        album: '<?',
        item: '<',
        itemId: '<',
        itemIndex: '<'
    },
    controller: [
        'SPPhoto',
        'spEventBus',
        'spPackages',
        function spPackageItemTileController(SPPhoto, spEventBus, spPackages) {
            const PHOTO_SIZE =
                window.getDevicePixelRatio() === 1 || window.isSmallDevice() ? 'm' : 'l';
            const $ctrl = this;
            const isSmallDevice = window.isSmallDevice();
            let shouldUpdatePhotoUrl = true;

            $ctrl.photoUrl = null;

            $ctrl.getPhotoName = getPhotoName;
            $ctrl.getPhotoUrl = getPhotoUrl;
            $ctrl.isAPackagePhotoSelected = isAPackagePhotoSelected;
            $ctrl.isCurrentPackageItemSelected = isCurrentPackageItemSelected;
            $ctrl.onDeleteItemHandler = onDeleteItemHandler;
            $ctrl.onSelectItemHandler = onSelectItemHandler;
            $ctrl.replaceImage = replaceImage;
            $ctrl.shouldUpdatePhotoAssigment = shouldUpdatePhotoAssigment;
            $ctrl.showDeleteOnSmallScreen = showDeleteOnSmallScreen;
            $ctrl.showReplace = showReplace;
            $ctrl.showReplaceOnSmallScreen = showReplaceOnSmallScreen;

            function getPhotoUrl() {
                $ctrl.photo = spPackages.getAssignedPhoto($ctrl.itemId, $ctrl.itemIndex);

                if ($ctrl.photo) {
                    shouldUpdatePhotoUrl = false;
                    return SPPhoto.getUrl($ctrl.photo, PHOTO_SIZE);
                }
            }

            function getPhotoName() {
                const photo = spPackages.getAssignedPhoto($ctrl.itemId, $ctrl.itemIndex);

                if (photo) {
                    return photo.filename;
                }
            }

            function isAPackagePhotoSelected() {
                return spPackages.isAPackagePhotoSelected();
            }

            function isCurrentPackageItemSelected() {
                const { itemId, itemIndex } = spPackages.getSelectedPackageItem();

                return $ctrl.itemId === itemId && $ctrl.itemIndex === itemIndex;
            }

            function onDeleteItemHandler($event) {
                $event.stopPropagation();

                spPackages.selectPackageItemWithPhoto($ctrl.itemId, $ctrl.itemIndex, true);

                if (isCurrentPackageItemSelected()) {
                    spPackages.selectPackageItem($ctrl.itemId, $ctrl.itemIndex);
                }

                $ctrl.photoUrl = null;
                shouldUpdatePhotoUrl = true;
            }

            function onSelectItemHandler() {
                if (!spPackages.isAPackagePhotoSelected()) {
                    spPackages.setSmallScreenState(PACKAGE_SMALL_SCREEN_STATES.PHOTO_VIEW);
                    resetGalleryPhotoSizes();
                }

                if (isAPackagePhotoSelected()) {
                    return spPackages.selectIndividualPackagePhotoItem(
                        $ctrl.itemId,
                        $ctrl.itemIndex,
                        $ctrl.album
                    );
                }

                spPackages.selectPackageItem($ctrl.itemId, $ctrl.itemIndex);
            }

            function replaceImage() {
                shouldUpdatePhotoUrl = true;
                $ctrl.onSelectItemHandler();
            }

            function resetGalleryPhotoSizes() {
                spEventBus.emit(PACKAGE_EVENTS.SELECT);
            }

            function shouldUpdatePhotoAssigment() {
                if (isSmallDevice) {
                    spPackages.selectPackageItem($ctrl.itemId, $ctrl.itemIndex);
                    spPackages.setSmallScreenState(PACKAGE_SMALL_SCREEN_STATES.PHOTO_VIEW);
                    resetGalleryPhotoSizes();

                    return;
                }

                $ctrl.onSelectItemHandler();
            }

            function showDeleteOnSmallScreen() {
                return !shouldUpdatePhotoUrl && Boolean($ctrl.getPhotoUrl());
            }

            function showReplace() {
                return Boolean($ctrl.getPhotoUrl()) && !isSmallDevice;
            }

            function showReplaceOnSmallScreen() {
                const photoId = spPackages.getAssignedPhoto($ctrl.itemId, $ctrl.itemIndex);
                const selectedPhotoId = spPackages.getSelectedPackagePhoto();

                if (photoId && selectedPhotoId) {
                    return !shouldUpdatePhotoUrl && photoId !== selectedPhotoId;
                }
            }
        }
    ],
    template: spPackageItemTileTemplate
};
