import React, { FC, memo, useLayoutEffect, useMemo, useRef } from 'react';
import { digitalsProductOverImageUrl } from 'ts/common/assets';
import type { IProductPhoto } from '../types';

/** Coordinates for inner screen rectangle in Monitor frame */
const SCREEN_BOUND_START_X = 198;
const SCREEN_BOUND_START_Y = 243;
const SCREEN_BOUND_END_X = 966;
const SCREEN_BOUND_END_Y = 678;

const SCREEN_BOUNDS_WIDTH = SCREEN_BOUND_END_X - SCREEN_BOUND_START_X;
const SCREEN_BOUNDS_HEIGHT = SCREEN_BOUND_END_Y - SCREEN_BOUND_START_Y;
const SCREEN_BOUNDS_ASPECT_RATIO = SCREEN_BOUNDS_HEIGHT / SCREEN_BOUNDS_WIDTH;

interface IDigitalRender {
    photoBounds: IProductPhoto;
    photoUrl: string;
}

const DigitalRender: FC<IDigitalRender> = ({ photoBounds, photoUrl }) => {
    const digitalCanvasRef = useRef<HTMLCanvasElement>(null);
    const windowScale = window.devicePixelRatio;

    const monitorImage = useMemo(() => {
        const image = new Image();
        image.src = digitalsProductOverImageUrl;
        return image;
    }, []);

    const digitalImage = useMemo(() => {
        const image = new Image();
        image.src = photoUrl;
        return image;
    }, [photoUrl]);

    useLayoutEffect(() => {
        const canvas = digitalCanvasRef.current;

        if (!canvas) {
            return;
        }

        const context = canvas.getContext('2d');

        if (!context) {
            return;
        }

        let animationFrameHandle = 0;

        const render = () => {
            context.save();

            context.clearRect(0, 0, canvas.width, canvas.height);

            if (monitorImage.complete) {
                context.drawImage(monitorImage, 0, 0, canvas.width, canvas.height);

                const scale = canvas.width / monitorImage.width;
                context.scale(scale, scale);

                // Draw black monitor screen
                context.fillStyle = 'black';
                context.fillRect(
                    SCREEN_BOUND_START_X,
                    SCREEN_BOUND_START_Y,
                    SCREEN_BOUNDS_WIDTH,
                    SCREEN_BOUNDS_HEIGHT
                );

                if (digitalImage.complete) {
                    const imageAspectRatio = photoBounds.height / photoBounds.width;

                    // Calculate height and widths of image in screen while preserving aspect ratio
                    const imageInScreenHeight =
                        imageAspectRatio > SCREEN_BOUNDS_ASPECT_RATIO
                            ? SCREEN_BOUNDS_HEIGHT
                            : SCREEN_BOUNDS_WIDTH * imageAspectRatio;
                    const imageInScreenWidth =
                        imageAspectRatio > SCREEN_BOUNDS_ASPECT_RATIO
                            ? SCREEN_BOUNDS_HEIGHT / imageAspectRatio
                            : SCREEN_BOUNDS_WIDTH;

                    // Center the image in the screen
                    const offsetX = (SCREEN_BOUNDS_WIDTH - imageInScreenWidth) * 0.5;
                    const offsetY = (SCREEN_BOUNDS_HEIGHT - imageInScreenHeight) * 0.5;
                    context.drawImage(
                        digitalImage,
                        SCREEN_BOUND_START_X + offsetX,
                        SCREEN_BOUND_START_Y + offsetY,
                        imageInScreenWidth,
                        imageInScreenHeight
                    );
                }
            }

            context.restore();

            if (!digitalImage.complete || !monitorImage.complete) {
                animationFrameHandle = requestAnimationFrame(render);
            }
        };

        render();

        return () => {
            cancelAnimationFrame(animationFrameHandle);
        };
    }, [digitalCanvasRef, digitalImage, monitorImage, photoBounds.height, photoBounds.width]);

    return (
        <canvas
            ref={digitalCanvasRef}
            height={588 * windowScale}
            width={588 * windowScale}
            style={{
                maxHeight: '100%',
                maxWidth: '100%'
            }}
        />
    );
};

export default memo(DigitalRender);
