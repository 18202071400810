import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import ProductSwatch from 'ts/common/components/ProductSwatch';
import { useBreakpoint } from 'ts/common/hooks';
import type { ProductOption } from '../types';

interface Props {
    onClickOption: (option: ProductOption) => void;
    options: Array<ProductOption>;
    selectedPriceSheetItemId?: number;
}

/**
 * Renders the UI for selecting a frame option from a set of swatches.
 */
const FrameSelect = ({ onClickOption, options, selectedPriceSheetItemId }: Props) => {
    const isLargeScreen = useBreakpoint();

    return (
        <Flex
            role="listbox"
            alignItems="flex-start"
            flexFlow="row wrap"
            columnGap={options.length < 6 || !isLargeScreen ? '55px' : '32px'}
            rowGap="16px"
        >
            {options.map((option) => {
                const isSelected = option.priceSheetItemId === selectedPriceSheetItemId;

                return (
                    option.frame && (
                        <Flex
                            key={option.priceSheetItemId}
                            role="option"
                            aria-label={option.name}
                            aria-selected={isSelected}
                            alignItems="center"
                            cursor="pointer"
                            flexFlow="column nowrap"
                            gap="4px"
                            maxWidth="60px"
                            onClick={() => {
                                onClickOption(option);
                            }}
                        >
                            <ProductSwatch
                                key={option.priceSheetItemId}
                                background={option.frame}
                                selection={
                                    isSelected
                                        ? { borderColor: 'var(--chakra-colors-brand-primary)' }
                                        : undefined
                                }
                                size={40}
                            />
                            <Text fontSize="10px" lineHeight="normal" textAlign="center">
                                {option.name}
                            </Text>
                        </Flex>
                    )
                );
            })}
        </Flex>
    );
};

export default FrameSelect;
