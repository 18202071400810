export const getAlbumCaption = ({
    albumCount,
    photoCount,
    translate
}: {
    albumCount?: number;
    photoCount: number;
    translate: SpAngularJs.ITranslateFilter;
}) => {
    if (albumCount !== undefined && albumCount > 0) {
        return translate(albumCount === 1 ? 'oneAlbum' : 'multipleAlbums', {
            albumCount: String(albumCount)
        });
    }

    if (!photoCount) {
        return translate('zeroPhotos');
    }

    return translate(photoCount === 1 ? 'onePhoto' : 'multiplePhotos', {
        photoCount: String(photoCount)
    });
};
