import { DIGITAL_RULE_EVENTS } from 'Sp/Angular/Events/Client/Gallery';
import Route from 'Sp/Route';

export default [
    '$rootScope',
    'spApiHttp',
    function DigitalRuleDataStore($rootScope, spApiHttp) {
        $rootScope.$on(DIGITAL_RULE_EVENTS.LIST, (_, request) => {
            const apiRouteParams = {
                brandId: $rootScope.brandData.id,
                eventId: $rootScope.eventData.id,
                email: request.email,
                pins: request.pins
            };

            if (request.doesAcceptTerms) {
                apiRouteParams.doesAcceptTerms = request.doesAcceptTerms ? 1 : 0;
            }

            let apiRoute;

            if (request.albumId) {
                apiRouteParams.albumId = request.albumId;
                apiRoute = '/brand/:brandId/event/:eventId/album/:albumId/digital-rule';
            } else {
                apiRoute = '/brand/:brandId/event/:eventId/digital-rule';
            }

            spApiHttp
                .get(Route.get(apiRoute, apiRouteParams))
                .then(function withResponse({ data }) {
                    const { items: digitalRules } = data;

                    $rootScope.$broadcast(DIGITAL_RULE_EVENTS.LISTED, digitalRules);
                })
                .catch(function onError(response) {
                    const errors = response.data.info.errors;

                    $rootScope.$broadcast(DIGITAL_RULE_EVENTS.LIST_ERROR, errors);
                });
        });
    }
];
