const scrollPositionMap = {};

export default [
    '$timeout',
    function spPreserveScrollPositionDirective($timeout) {
        return {
            link: function spPreserveScrollPositionController(
                { id, scrollOnInitialRender = true },
                $element
            ) {
                const { [id]: initialScrollPosition } = scrollPositionMap;

                $element.css('visibility', 'hidden');

                if (initialScrollPosition && scrollOnInitialRender) {
                    $timeout(() => {
                        $element[0].scrollTo(0, initialScrollPosition);
                        $element.css('visibility', 'visible');
                    });
                } else {
                    $element.css('visibility', 'visible');
                }

                $element.on('scroll', ({ target: { scrollTop } }) => {
                    scrollPositionMap[id] = scrollTop;
                });
            },
            restrict: 'A',
            scope: {
                id: '@',
                scrollOnInitialRender: '<?'
            }
        };
    }
];
