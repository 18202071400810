import React, { ComponentProps, FC } from 'react';
import { Flex, Heading, Text } from '@chakra-ui/react';
import CircleIcon from 'ts/common/components/gallery/CircleIcon';

interface IModalHeaderProps {
    headerSubText?: string;
    modalIcon: ComponentProps<typeof CircleIcon>['icon'];
    headerText: string;
}

const ModalHeader: FC<IModalHeaderProps> = ({ headerSubText, modalIcon, headerText }) => {
    return (
        <Flex alignItems="flex-start" flexDirection="column">
            <CircleIcon icon={modalIcon} />
            <Heading as="h2" size="lg" variant="primary" paddingTop="16px">
                {headerText}
            </Heading>
            {headerSubText && <Text paddingTop="8px">{headerSubText}</Text>}
        </Flex>
    );
};

export default ModalHeader;
