import { GOOGLE_PHOTOS_EXPORT_EVENTS } from 'Sp/Angular/Events/Client/Gallery';

export default [
    'spAppData',
    '$rootScope',
    function GooglePhotosExportDataStore(spAppData, $rootScope) {
        $rootScope.$on(GOOGLE_PHOTOS_EXPORT_EVENTS.CREATE, async (_, request) => {
            const { apiRoot } = spAppData.get('urls');
            const url = `${apiRoot}/brand/${$rootScope.brandData.id}/event/${$rootScope.eventData.id}/google-photos-export`;

            const options = {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${request.authorizationToken}`,
                    'Content-Type': 'application/vnd.shootproof+json'
                },
                body: JSON.stringify({
                    clientGalleryUrl: request.clientGalleryUrl,
                    digitalRuleId: request.digitalRuleId,
                    googlePhotosAlbumName: request.googlePhotosAlbumName,
                    albumId: request.albumId,
                    photoIds: request.photoIds
                })
            };

            const response = await fetch(url, options);
            const data = await response.json();

            if (response.ok) {
                $rootScope.$broadcast(GOOGLE_PHOTOS_EXPORT_EVENTS.CREATED, data);
            } else if (response.status === 401) {
                $rootScope.$broadcast(GOOGLE_PHOTOS_EXPORT_EVENTS.ERRORS.UNAUTHORIZED, data);
            } else {
                $rootScope.$broadcast(GOOGLE_PHOTOS_EXPORT_EVENTS.ERRORS.UNKNOWN, data);
            }
        });

        $rootScope.$on(GOOGLE_PHOTOS_EXPORT_EVENTS.GET, async (_, request) => {
            const { apiRoot } = spAppData.get('urls');
            const url = `${apiRoot}/brand/${$rootScope.brandData.id}/event/${$rootScope.eventData.id}/google-photos-export/${request.id}`;

            const options = {
                headers: {
                    Authorization: `Bearer ${request.authorizationToken}`
                }
            };

            const response = await fetch(url, options);
            const data = await response.json();

            if (response.ok) {
                $rootScope.$broadcast(GOOGLE_PHOTOS_EXPORT_EVENTS.GOT, data);
            } else if (response.status === 401) {
                $rootScope.$broadcast(GOOGLE_PHOTOS_EXPORT_EVENTS.ERRORS.UNAUTHORIZED, data);
            } else {
                $rootScope.$broadcast(GOOGLE_PHOTOS_EXPORT_EVENTS.ERRORS.UNKNOWN, data);
            }
        });
    }
];
