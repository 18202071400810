import React, { FC, useCallback, useState } from 'react';
import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import debounce from 'lodash.debounce';
import { Image } from 'react-feather';
import ModalHeader from 'ts/common/components/gallery/ModalHeader';
import ModalButtonFooter from './ModalButtonFooter';

interface IAdminModalProps {
    onClose: () => void;
    onSubmit: () => void;
    isInvalid: boolean;
    errorMessage: string;
    onChangePin: (pin: string) => void;
    translateFilter: SpAngularJs.ITranslateFilter;
}

const AdminModal: FC<IAdminModalProps> = ({
    onClose,
    onSubmit,
    isInvalid,
    errorMessage,
    onChangePin,
    translateFilter: t
}) => {
    const [pin, setPin] = useState('');
    const isPinEmpty = pin.length < 1;

    const debouncedOnChangePin = debounce(onChangePin, 250);

    const handleChangePin = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setPin(event.target.value);
            debouncedOnChangePin(event.target.value);
        },
        [debouncedOnChangePin]
    );

    const handleSubmit = useCallback(
        (event: React.FormEvent<HTMLElement>) => {
            event.preventDefault();
            onSubmit();
            setPin('');
        },
        [onSubmit]
    );

    return (
        <Box>
            <ModalHeader
                modalIcon={Image}
                headerText={t('adminAuthHeading')}
                headerSubText={t('adminModeEntryDescription')}
            />
            <Flex as="form" flexDirection="column" paddingTop="32px" onSubmit={handleSubmit}>
                <Box paddingBottom="128px">
                    <FormControl isRequired isInvalid={isInvalid}>
                        <FormLabel>{t('accessCodeLabel')}</FormLabel>
                        <Input type="pin" value={pin} onChange={handleChangePin} />
                        <FormErrorMessage>{errorMessage}</FormErrorMessage>
                    </FormControl>
                </Box>

                <ModalButtonFooter
                    isPrimaryDisabled={isPinEmpty}
                    secondaryButtonAction={onClose}
                    primaryButtonText={t('submit')}
                    primaryLoadingText={t('submit')}
                    secondaryButtonText={t('adminModeDeclineToEnter')}
                />
            </Flex>
        </Box>
    );
};

export default AdminModal;
