export default [
    '$rootScope',
    function SPAddToCartService($rootScope) {
        var service = new (function SPAddToCart() {
            this.presentDialog = function presentDialog(photo) {
                $rootScope.openAddToCartSidePanel({
                    ...photo,
                    url: $rootScope.SPPhoto.getUrl(photo, 'l')
                });
            };
        })();

        $rootScope.SPAddToCart = service;

        return service;
    }
];
