import React, { ComponentType, FC, SVGProps, useCallback } from 'react';
import {
    Flex,
    Heading,
    IconButton,
    IconButtonProps,
    Link,
    LinkProps,
    useTheme
} from '@chakra-ui/react';
import { Link as LinkIcon } from 'react-feather';
import {
    SocialNetworkIconFacebook,
    SocialNetworkIconPinterest,
    SocialNetworkIconTwitter
} from 'ts/common/components/icons';
import useToast from 'ts/common/hooks/useToast';

const ShareButton: FC<
    IconButtonProps &
        LinkProps & { Icon: ComponentType<SVGProps<SVGSVGElement>>; iconFill?: string }
> = ({ Icon, iconFill, ...rest }) => (
    <IconButton
        border="none"
        height="55px"
        icon={<Icon fill={iconFill ?? 'none'} height="24px" width="24px" />}
        isRound
        width="55px"
        _active={{
            backgroundColor: rest.backgroundColor
        }}
        _focus={{
            boxShadow: 'none',
            color: rest.color
        }}
        _focusVisible={{
            boxShadow: 'outline'
        }}
        _hover={{
            backgroundColor: rest.backgroundColor,
            color: rest.color
        }}
        {...rest}
    />
);

interface Props {
    /** An href to be copied to the clipboard when the Copy Link button is clicked */
    copyLinkHref?: string;
    /** Text to be shown within the heading at the top */
    headingText: string;
    /** A record of hrefs to be used for rendering links */
    linkHrefs?: Record<string, string>;
    /** Called when any link or button is clicked */
    onClickLink?: () => unknown;
    /** The Angular translation service */
    translateFilter: SpAngularJs.ITranslateFilter;
}

const ShareLinks: FC<Props> = ({
    copyLinkHref,
    headingText,
    linkHrefs,
    onClickLink,
    translateFilter
}) => {
    const theme = useTheme();

    const toast = useToast({ type: 'copyLink', translateFilter });

    const handleClickCopyLink = useCallback(async () => {
        if (copyLinkHref) {
            await navigator.clipboard.writeText(copyLinkHref);
            onClickLink?.();
            toast();
        }
    }, [copyLinkHref, onClickLink, toast]);

    return (
        <Flex alignItems="center" flexDirection="column" gap="24px">
            <Heading as="h3" color="text" size="md">
                {headingText}
            </Heading>
            <Flex flexFlow="row wrap" justifyContent="center" gap="24px">
                {linkHrefs?.facebook && (
                    <ShareButton
                        as={Link}
                        aria-label="Facebook"
                        backgroundColor="#ecf0f8"
                        href={linkHrefs.facebook}
                        Icon={SocialNetworkIconFacebook}
                        iconFill="#4267b2"
                        onClick={onClickLink}
                        rel="noopener noreferrer"
                        target="_blank"
                    />
                )}
                {linkHrefs?.twitter && (
                    <ShareButton
                        as={Link}
                        aria-label="Twitter"
                        backgroundColor="#e7f5fd"
                        href={linkHrefs.twitter}
                        Icon={SocialNetworkIconTwitter}
                        iconFill="#1ca0f0"
                        onClick={onClickLink}
                        rel="noopener noreferrer"
                        target="_blank"
                    />
                )}
                {linkHrefs?.pinterest && (
                    <ShareButton
                        as={Link}
                        aria-label="Pinterest"
                        backgroundColor="#fee6e9"
                        href={linkHrefs.pinterest}
                        Icon={SocialNetworkIconPinterest}
                        iconFill="#bd061b"
                        onClick={onClickLink}
                        rel="noopener noreferrer"
                        target="_blank"
                    />
                )}
                {copyLinkHref && (
                    <ShareButton
                        aria-label={translateFilter('copyLink')}
                        backgroundColor={theme.isLightColorScheme ? 'gray.100' : 'gray.200'}
                        color="gray.500"
                        Icon={LinkIcon}
                        onClick={handleClickCopyLink}
                    />
                )}
            </Flex>
        </Flex>
    );
};

export default ShareLinks;
