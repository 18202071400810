import React, { FC } from 'react';
import { Tooltip, useTheme } from '@chakra-ui/react';
import { Info } from 'react-feather';

interface IInfoTooltipProps {
    infoIconLabel: string;
    tooltipLabel: string;
}

const InfoTooltip: FC<IInfoTooltipProps> = ({ infoIconLabel, tooltipLabel }) => {
    const theme = useTheme();

    return (
        <Tooltip
            gutter={16} // px
            hasArrow
            label={tooltipLabel}
            placement="top"
            // This is the only way I could find to apply a custom drop-shadow to the Tooltip's arrow:
            sx={{
                '.chakra-tooltip__arrow': {
                    filter: theme.filters?.dropShadow
                }
            }}
        >
            <Info aria-label={infoIconLabel} color="var(--chakra-colors-text)" size="16px" />
        </Tooltip>
    );
};

export default InfoTooltip;
