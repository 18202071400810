import React, { FC } from 'react';
import { Box, Flex, Image, useBreakpointValue } from '@chakra-ui/react';
import Button from 'ts/common/components/gallery/Button';
import GalleryHeading from 'ts/common/components/gallery/GalleryHeading';
import StudioHeading from 'ts/common/components/gallery/StudioHeading';
import StudioLogo from '../components/StudioLogo';
import { ILayoutProps, contentAnimation, coverAnimation } from './BaseLayout';

const AccentLayout: FC<ILayoutProps> = ({
    brandName,
    buttonText,
    buttonTextLarge,
    coverPhotoUrl,
    eventBrandLogo,
    galleryTitle,
    logoAltText,
    onClick
}) => {
    const coverPhotoMaxSize = useBreakpointValue({ base: 'xs', lg: 'sm' });
    const galleryHeadingContainerFlex = useBreakpointValue({ base: 1, lg: 0 });
    const galleryHeadingContainerPaddingY = useBreakpointValue({ base: 6, lg: 7 });
    const galleryHeadingSize =
        useBreakpointValue({ base: 'sm' as const, lg: 'lg' as const }) ?? 'sm';
    const headerAlign = useBreakpointValue({ base: 'start', lg: 'center' });
    const isFullWidthButton = useBreakpointValue({ base: true, lg: false });
    const studioLogoSize = useBreakpointValue({ base: 'md' as const, lg: 'lg' as const });

    return (
        <Flex
            alignItems="center"
            flexDirection="column"
            height="full"
            onClick={onClick}
            overflowY="auto"
            paddingX={4}
            paddingY={6}
            textAlign="center"
            width="full"
        >
            <Flex
                alignItems={headerAlign}
                minHeight="25%"
                paddingBottom={6}
                animation={contentAnimation}
                opacity={0}
            >
                {eventBrandLogo ? (
                    <StudioLogo
                        logo={eventBrandLogo}
                        logoAltText={logoAltText}
                        logoSize={studioLogoSize}
                    />
                ) : (
                    <StudioHeading text={brandName} variant="primary" />
                )}
            </Flex>
            <Image
                data-testid="gallery-cover-photo"
                maxHeight={coverPhotoMaxSize}
                maxWidth={coverPhotoMaxSize}
                src={coverPhotoUrl ?? undefined}
                animation={coverAnimation}
            />
            <Flex
                alignItems="center"
                alignSelf="stretch"
                flexDirection="column"
                flex={1}
                animation={contentAnimation}
                opacity={0}
            >
                <Box flex={galleryHeadingContainerFlex} paddingY={galleryHeadingContainerPaddingY}>
                    <GalleryHeading
                        size={galleryHeadingSize}
                        text={galleryTitle}
                        variant="primary"
                    />
                </Box>
                <Button
                    fullWidth={isFullWidthButton}
                    text={buttonText}
                    textLarge={buttonTextLarge}
                    variant="primary"
                />
            </Flex>
        </Flex>
    );
};

export default AccentLayout;
