const CANVAS_EXHIBITION_TYPES = {
    SOLO: 'solo',
    BEDROOM: 'bedroom',
    LIVING_ROOM: 'living-room',
    OFFICE: 'office'
};
const CANVAS_EXHIBITION_PHYSICAL_DIMENSIONS_MAP = {
    [CANVAS_EXHIBITION_TYPES.BEDROOM]: { width: 178.9, height: 146.6 },
    [CANVAS_EXHIBITION_TYPES.LIVING_ROOM]: { width: 159.4, height: 130.6 },
    [CANVAS_EXHIBITION_TYPES.OFFICE]: { width: 110.3, height: 90.3 }
};
const CANVAS_EXHIBITION_FOCAL_POINT_MAP = {
    [CANVAS_EXHIBITION_TYPES.BEDROOM]: { translateX: -1.786, translateY: -21.32 },
    [CANVAS_EXHIBITION_TYPES.LIVING_ROOM]: { translateX: -1.59, translateY: -6.04 },
    [CANVAS_EXHIBITION_TYPES.OFFICE]: { translateX: 19.92, translateY: -4 }
};
const CANVAS_RENDERING_TYPES = {
    PERSPECTIVE: 'perspective',
    SHADOW: 'shadow'
};
const ARCYLIC_THUMBNAILS = {
    PERSPECTIVE: 'perspective',
    SHADOW: 'shadow'
};
const ARCYLIC_THUMBNAIL_EXHIBITION_RENDERING_MAP = {
    [ARCYLIC_THUMBNAILS.PERSPECTIVE]: {
        exhibition: CANVAS_EXHIBITION_TYPES.SOLO,
        rendering: CANVAS_RENDERING_TYPES.PERSPECTIVE
    },
    [ARCYLIC_THUMBNAILS.SHADOW]: {
        exhibition: CANVAS_EXHIBITION_TYPES.SOLO,
        rendering: CANVAS_RENDERING_TYPES.SHADOW
    }
};
const CANVAS_THUMBNAILS = {
    PERSPECTIVE: 'perspective',
    SHADOW: 'shadow',
    BEDROOM: 'bedroom',
    LIVING_ROOM: 'living-room',
    OFFICE: 'office'
};
const CANVAS_THUMBNAIL_EXHIBITION_RENDERING_MAP = {
    [CANVAS_THUMBNAILS.PERSPECTIVE]: {
        exhibition: CANVAS_EXHIBITION_TYPES.SOLO,
        rendering: CANVAS_RENDERING_TYPES.PERSPECTIVE
    },
    [CANVAS_THUMBNAILS.SHADOW]: {
        exhibition: CANVAS_EXHIBITION_TYPES.SOLO,
        rendering: CANVAS_RENDERING_TYPES.SHADOW
    },
    [CANVAS_THUMBNAILS.BEDROOM]: {
        exhibition: CANVAS_EXHIBITION_TYPES.BEDROOM,
        rendering: CANVAS_RENDERING_TYPES.SHADOW
    },
    [CANVAS_THUMBNAILS.LIVING_ROOM]: {
        exhibition: CANVAS_EXHIBITION_TYPES.LIVING_ROOM,
        rendering: CANVAS_RENDERING_TYPES.SHADOW
    },
    [CANVAS_THUMBNAILS.OFFICE]: {
        exhibition: CANVAS_EXHIBITION_TYPES.OFFICE,
        rendering: CANVAS_RENDERING_TYPES.SHADOW
    }
};

export {
    CANVAS_EXHIBITION_FOCAL_POINT_MAP,
    CANVAS_EXHIBITION_TYPES,
    CANVAS_EXHIBITION_PHYSICAL_DIMENSIONS_MAP,
    CANVAS_RENDERING_TYPES,
    CANVAS_THUMBNAILS,
    CANVAS_THUMBNAIL_EXHIBITION_RENDERING_MAP,
    ARCYLIC_THUMBNAILS,
    ARCYLIC_THUMBNAIL_EXHIBITION_RENDERING_MAP
};

export default {
    CANVAS_EXHIBITION_FOCAL_POINT_MAP,
    CANVAS_EXHIBITION_TYPES,
    CANVAS_EXHIBITION_PHYSICAL_DIMENSIONS_MAP,
    CANVAS_RENDERING_TYPES,
    CANVAS_THUMBNAILS,
    CANVAS_THUMBNAIL_EXHIBITION_RENDERING_MAP,
    ARCYLIC_THUMBNAILS,
    ARCYLIC_THUMBNAIL_EXHIBITION_RENDERING_MAP
};
