import React, { FC } from 'react';
import { Box, Button, DrawerProps, Flex } from '@chakra-ui/react';
import DownloadDestinations, {
    Props as DownloadDestinationsProps
} from 'ts/client/gallery/components/DownloadDestinations';
import LoadingIndicator from 'ts/common/components/LoadingIndicator';
import Drawer from 'ts/common/components/gallery/Drawer';
import ShareLinks from 'ts/common/components/gallery/ShareLinks';
import type { IActionButton } from 'ts/common/types';

interface Props extends Omit<DrawerProps, 'children' | 'onClose'> {
    /** Actions to show in this drawer */
    actions: IActionButton[];
    /** True only if share links should be shown within this drawer */
    isShareLinksVisible?: boolean;
    /** A url to be copied to the clipboard when the Share Links Copy Link button is clicked */
    shareCopyLinkUrl?: string;
    /** Heading text to be shown above share links when they are visible */
    shareHeadingText: string;
    /** A record of urls to be used for share links */
    shareUrls?: Record<string, string>;
    /** True only if the download destinations should be shown within this drawer */
    isDownloadDestinationsVisible?: boolean;
    /** True only if this is a single-photo download */
    isDownloadPhoto?: boolean;
    /** Props to be passed to the DownloadDestination component */
    downloadDestinationsProps: Omit<
        DownloadDestinationsProps,
        'spFreeDigitals' | 'translateFilter'
    >;
    /** Called when the drawer is closed via the close button or clicking the backdrop*/
    onCancel: () => unknown;
    /** Called when the drawer is closed via clicking an action */
    onClose: () => unknown;
    /** Angular free digitals service */
    spFreeDigitals: SpAngularJs.ISpFreeDigitals;
    /** The Angular translation service */
    translateFilter: SpAngularJs.ITranslateFilter;
}

const DrawerWithActions: FC<Props> = ({
    actions,
    isShareLinksVisible,
    shareCopyLinkUrl,
    shareHeadingText,
    shareUrls,
    isDownloadDestinationsVisible,
    isDownloadPhoto,
    downloadDestinationsProps,
    onCancel,
    onClose,
    spFreeDigitals,
    translateFilter: translate,
    ...rest
}) => {
    return (
        <Drawer {...rest} onClose={onCancel}>
            {isShareLinksVisible ? (
                <Box paddingBottom="24px" paddingTop="4px">
                    {shareUrls && shareCopyLinkUrl ? (
                        <ShareLinks
                            copyLinkHref={shareCopyLinkUrl}
                            headingText={shareHeadingText}
                            linkHrefs={shareUrls}
                            onClickLink={onClose}
                            translateFilter={translate}
                        />
                    ) : (
                        <LoadingIndicator />
                    )}
                </Box>
            ) : isDownloadDestinationsVisible ? (
                <DownloadDestinations
                    {...downloadDestinationsProps}
                    isDownloadPhoto={isDownloadPhoto}
                    spFreeDigitals={spFreeDigitals}
                    translateFilter={translate}
                />
            ) : (
                <Flex flexFlow="column nowrap">
                    {actions.map((action) => (
                        <Button
                            key={action.key}
                            role={action.isToggledOn === undefined ? undefined : 'switch'}
                            aria-checked={action.isToggledOn}
                            variant="ghost"
                            fontSize="md"
                            height="48px"
                            justifyContent="left"
                            letterSpacing={0}
                            isDisabled={action.isDisabled}
                            leftIcon={action.icon}
                            paddingX="8px"
                            onClick={() => {
                                action.onClick();

                                if (!action.keepOpen) {
                                    onClose();
                                }
                            }}
                        >
                            {action.text}
                        </Button>
                    ))}
                </Flex>
            )}
        </Drawer>
    );
};

export default DrawerWithActions;
