export enum ComponentState {
    Active = 'active',
    Checked = 'checked',
    Default = 'default',
    Disabled = 'disabled',
    Focus = 'focus',
    Hover = 'hover',
    Invalid = 'invalid',
    Loaded = 'loaded',
    Loading = 'loading',
    Selected = 'selected',
    Valid = 'valid'
}

export const modalButtonWidth = 232;
