import React from 'react';
import {
    Box,
    Container,
    Flex,
    Heading,
    Select,
    Text,
    useBreakpointValue,
    useTheme
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { styled } from 'sp-ui';
import { useEvent } from 'ts/client/common';
import { sortByDisplayOrder } from 'ts/client/gallery/components/common';
import { isPackageItem } from 'ts/client/gallery/components/common';
import { GalleryHeading } from 'ts/common/components/gallery';
import { useTranslate } from 'ts/common/hooks';
import { useCurrency } from '../../hooks';
import { IBoundItem, IPriceSheetDataResponse } from '../types';
import { GroupType } from '../types';

const BoundInfoContainer = styled(Box)`
    &:not(:last-child) {
        border-bottom: 1px solid;
    }
`;

const DiscountDescriptionContainer = styled(Container)`
    .discount-detail {
        list-style: none;

        li:not(.item) {
            list-style: none;
        }
    }
`;

const DiscountDescriptionText = styled(Text)`
    &:not(:last-child) {
        border-bottom: 1px solid;
    }
`;

const getLowestCardCategoryPrice = (cardItems: IBoundItem[]) => {
    const cardItemWithLowestPrice = cardItems.reduce((cardItem, nextCardItem) =>
        parseFloat(cardItem.price) < parseFloat(nextCardItem.price) ? cardItem : nextCardItem
    );

    if (cardItems[0].whcc_id) {
        return parseFloat(cardItemWithLowestPrice.price) * 25;
    }

    return parseFloat(cardItemWithLowestPrice.price);
};

interface IItemInfoProps {
    name: string;
    price: string;
}

const ItemInfo: React.FC<IItemInfoProps> = ({ name, price }) => {
    return (
        <Flex justifyContent="space-between" marginBottom="12px">
            <Text
                color="text"
                fontFamily="open-sans, sans-serif"
                fontSize="14px"
                textTransform="capitalize"
            >
                {name}
            </Text>
            <Text color="text" fontFamily="open-sans, sans-serif" fontSize="14px">
                {price}
            </Text>
        </Flex>
    );
};

interface IBoundInfoProps {
    boundName: string;
    boundItems: IBoundItem[];
    groupType: number;
}

const BoundInfo: React.FC<IBoundInfoProps> = ({ boundName, boundItems, groupType }) => {
    const $ = useCurrency();
    const t = useTranslate('client.gallery.addToCartSidePanel.pricingAndDiscountsSlide.boundInfo');
    const isMediumScreenOrLarger = useBreakpointValue({ base: false, md: true });

    return (
        <BoundInfoContainer padding="16px 0 4px">
            <Text
                color="text"
                fontFamily="open-sans, sans-serif"
                fontSize="14px"
                margin="0 0 8px"
                textTransform="capitalize"
            >
                {boundName}
            </Text>
            {groupType === GroupType.CARDS && boundItems[0].whcc_id && (
                <Container
                    margin="0 16px"
                    padding="0"
                    width={isMediumScreenOrLarger ? '100%' : 'unset'}
                >
                    <ItemInfo
                        name={t('baseCards')}
                        price={t('from', {
                            lowestCardItemPrice: `${$(getLowestCardCategoryPrice(boundItems))}`
                        })}
                    />
                </Container>
            )}
            {!boundItems[0].whcc_id && (
                <Container
                    margin="0 16px"
                    padding="0"
                    width={isMediumScreenOrLarger ? '100%' : 'unset'}
                >
                    {boundItems.map((item, index) => (
                        <ItemInfo
                            key={`item-${index}`}
                            name={`${item.name} ${
                                item.is_album
                                    ? t('allAlbumPhotos')
                                    : item.is_event
                                    ? t('allGalleryPhotos')
                                    : item.is_individual
                                    ? t('individualPhotos')
                                    : ''
                            }`}
                            price={$(parseFloat(item.price))}
                        />
                    ))}
                </Container>
            )}
        </BoundInfoContainer>
    );
};

interface IPricingAndDiscountsSlideProps {
    packagesData?: SpApi.Client.IPriceSheetGroup;
    pricingAndDiscountsData?: Nullable<IPriceSheetDataResponse>;
}

interface IFormValues {
    viewOption: number;
}

const PricingAndDiscountsSlide: React.FC<IPricingAndDiscountsSlideProps> = ({
    packagesData,
    pricingAndDiscountsData
}) => {
    const t = useTranslate('client.gallery.addToCartSidePanel.pricingAndDiscountsSlide');
    const $ = useCurrency();
    const {
        settings: { priceSheetShowPackagesOnly }
    } = useEvent();
    const theme = useTheme();

    const { register, watch } = useForm<IFormValues>({
        defaultValues: {
            viewOption: 0
        }
    });

    const { viewOption } = watch();

    const options: { viewOptionId: number; option: string }[] = [];
    const pricesheetHasDiscounts = pricingAndDiscountsData?.discountDescriptions !== undefined;
    let packageViewOptionIndex;

    if (priceSheetShowPackagesOnly && pricingAndDiscountsData) {
        if (pricesheetHasDiscounts) {
            options.push({ viewOptionId: 0, option: `${t('discounts')}` });
        }

        if (packagesData) {
            packageViewOptionIndex = pricesheetHasDiscounts ? 1 : 0;
            options.push({
                viewOptionId: packageViewOptionIndex,
                option: `${t('packages')}`
            });
        }
    } else if (pricingAndDiscountsData) {
        if (pricesheetHasDiscounts) {
            options.push({ viewOptionId: 0, option: `${t('discounts')}` });
        }

        pricingAndDiscountsData.groups.forEach((group, index) => {
            options.push({
                viewOptionId: pricingAndDiscountsData.discountDescriptions ? index + 1 : index,
                option: group.name
            });
        });

        if (packagesData) {
            packageViewOptionIndex = pricesheetHasDiscounts
                ? pricingAndDiscountsData?.groups.length + 1
                : pricingAndDiscountsData?.groups.length;
            options.push({
                viewOptionId: packageViewOptionIndex,
                option: `${t('packages')}`
            });
        }
    } else {
        return null;
    }

    if (options.length === 0) {
        return null;
    }

    const viewOptions = options;

    const isDiscountsDefaultViewOption = viewOptions[0].option === `${t('discounts')}`;
    const isDiscountsDefaultViewOptionSelected = isDiscountsDefaultViewOption && viewOption === 0;
    const groupIndex = pricesheetHasDiscounts ? viewOption - 1 : viewOption;
    const isPackageViewOptionSelected = packagesData && viewOption === packageViewOptionIndex;

    const sortedPackagesInfo = packagesData
        ? sortByDisplayOrder(packagesData.items.filter(isPackageItem))
        : [];

    return (
        <Box as="form" padding="48px 20px">
            <GalleryHeading
                size="md"
                text={`${t('pricingInfo')}${pricesheetHasDiscounts ? ` ${t('andDiscounts')}` : ''}`}
                variant="primary"
            />
            <Select
                {...register('viewOption', {
                    valueAsNumber: true
                })}
                data-testid="view-option-select"
                id="view-option-select"
                margin="35px 0"
                aria-label="view-option"
                background={theme.isLightColorScheme ? 'white' : 'background'}
            >
                {viewOptions.map(({ viewOptionId, option }, index) => (
                    <option key={index} value={viewOptionId} style={{ background: 'inherit' }}>
                        {option}
                    </option>
                ))}
            </Select>
            {pricingAndDiscountsData?.discountDescriptions && isDiscountsDefaultViewOptionSelected && (
                <>
                    <Heading
                        color="brand.primary"
                        fontFamily="open-sans, sans-serif"
                        margin="0 0 28px"
                        size="lg"
                    >
                        {t('discounts')}
                    </Heading>
                    <DiscountDescriptionContainer margin="0" padding="0" maxWidth="100%">
                        {pricingAndDiscountsData.discountDescriptions.map((discount, index) => (
                            <DiscountDescriptionText
                                dangerouslySetInnerHTML={{ __html: discount }}
                                data-testid="discount-description"
                                fontFamily="open-sans, sans-serif"
                                fontSize="md"
                                key={index}
                                margin="0 0 22px"
                                paddingBottom="16px"
                            />
                        ))}
                    </DiscountDescriptionContainer>
                </>
            )}
            {pricingAndDiscountsData &&
                !isDiscountsDefaultViewOptionSelected &&
                !isPackageViewOptionSelected && (
                    <>
                        <Heading
                            color="brand.primary"
                            fontFamily="open-sans, sans-serif"
                            margin="0 0 12px"
                            size="lg"
                        >
                            {pricingAndDiscountsData.groups[groupIndex].name}
                        </Heading>
                        {pricingAndDiscountsData.groups[groupIndex].bounds.map((bound, index) =>
                            bound.items.length ? (
                                <BoundInfo
                                    key={`bound-${index}`}
                                    boundName={bound.bounds_name}
                                    boundItems={bound.items}
                                    groupType={pricingAndDiscountsData.groups[groupIndex].type}
                                />
                            ) : null
                        )}
                    </>
                )}
            {isPackageViewOptionSelected && (
                <>
                    <Heading
                        color="brand.primary"
                        fontFamily="open-sans, sans-serif"
                        margin="0 0 28px"
                        size="lg"
                    >
                        {t('packages')}
                    </Heading>
                    {sortedPackagesInfo.map((pkg, index) => (
                        <Container key={`package-${index}`}>
                            <Flex justifyContent="space-between" marginBottom="16px">
                                <Text>{pkg.name}</Text>
                                <Text>{pkg.price !== undefined ? $(pkg.price) : ''}</Text>
                            </Flex>
                            {pkg.items?.map((pkgItem, index) => (
                                <Container key={`package-item-${index}`}>
                                    {pkgItem.type === 'price-sheet-item-digital' && (
                                        <Flex margin="10px 0" justifyContent="flex-start">
                                            <Text fontWeight="900" marginRight="20px">
                                                {pkgItem.allGalleryPhotosDownload?.quantity
                                                    ? '-'
                                                    : pkgItem.allAlbumPhotosDownload?.quantity
                                                    ? '-'
                                                    : pkgItem.individualPhotoDownload?.quantity
                                                    ? pkgItem.individualPhotoDownload?.quantity
                                                    : pkgItem.quantity}
                                            </Text>
                                            <Box>
                                                <Text>
                                                    {pkgItem.allGalleryPhotosDownload?.quantity
                                                        ? `${t('allGalleryPhotos')} | ${t(
                                                              'digitals'
                                                          )}`
                                                        : pkgItem.allAlbumPhotosDownload?.quantity
                                                        ? `${t('allAlbumPhotos')} | ${t(
                                                              'digitals'
                                                          )}`
                                                        : pkgItem.individualPhotoDownload?.quantity
                                                        ? `${t('individualPhotos')} | ${t(
                                                              'digitals'
                                                          )}`
                                                        : `${t('digitals')}`}
                                                </Text>
                                                <Text fontSize="12px" fontStyle="italic">
                                                    {pkgItem.name}
                                                </Text>
                                            </Box>
                                        </Flex>
                                    )}
                                    {pkgItem.type === 'price-sheet-item' && (
                                        <Flex margin="10px 0" justifyContent="flex-start">
                                            <Text fontWeight="900" marginRight="20px">
                                                {pkgItem.quantity}
                                            </Text>
                                            <Box>
                                                <Text>
                                                    {pkgItem.subgroup?.name
                                                        ? `${pkgItem.subgroup?.name} | ${pkgItem.group.name}`
                                                        : `${pkgItem.group.name}`}
                                                </Text>
                                                <Text fontSize="12px" fontStyle="italic">
                                                    {pkgItem.name}
                                                </Text>
                                            </Box>
                                        </Flex>
                                    )}
                                </Container>
                            ))}
                        </Container>
                    ))}
                </>
            )}
        </Box>
    );
};

export default PricingAndDiscountsSlide;
