import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useGalleryApiFetch } from 'ts/client/common';
import { EmailCaptureForm } from 'ts/client/gallery/components';
import { FetchMethod, useTranslate } from 'ts/common/hooks';
import { IBoundItem, IImage, IPathContext } from '../types';

interface ILaunchEditorResponse {
    data?: {
        url?: string;
    };
    needEmail?: boolean;
    statusText?: string;
}

interface IEmailCaptureCardDesignerSlide {
    cardProduct?: IBoundItem;
    getPathContext: () => IPathContext;
    image: IImage;
    isSubjectToGdpr: boolean;
}

const EmailCaptureCardDesignerSlide: React.FC<IEmailCaptureCardDesignerSlide> = ({
    cardProduct,
    getPathContext,
    image,
    isSubjectToGdpr
}) => {
    const t = useTranslate();
    const { context, contextId } = getPathContext();
    const [emailSetSuccess, setEmailSetSuccess] = useState<boolean>(false);

    const {
        response: launchEditorResponse,
        performFetch: launchEditorRequest,
        loading: isLoading
    } = useGalleryApiFetch<ILaunchEditorResponse>('cart/launcheditor', {
        defer: true,
        method: FetchMethod.POST
    });

    useEffect(() => {
        if (emailSetSuccess && cardProduct) {
            launchEditorRequest({
                body: new URLSearchParams({
                    context: String(context),
                    context_id: String(contextId),
                    photo_id: String(image.id),
                    template_id: String(cardProduct.whcc_id),
                    whcc_product_id: String(cardProduct.whcc_product_id)
                })
            });
        }
    }, [cardProduct, context, contextId, emailSetSuccess, image, launchEditorRequest]);

    useEffect(() => {
        if (launchEditorResponse && launchEditorResponse.data?.url) {
            window.location.href = launchEditorResponse.data.url;
        }
    }, [launchEditorResponse]);

    return (
        <Box padding="48px 20px">
            <EmailCaptureForm
                buttonText={t(
                    'client.gallery.addToCartSidePanel.emailCaptureCardDesignerSlide.startDesigning'
                )}
                headerText={t(
                    'client.gallery.addToCartSidePanel.emailCaptureCardDesignerSlide.enterEmail'
                )}
                headerSubText={t(
                    'client.gallery.addToCartSidePanel.emailCaptureCardDesignerSlide.weWillSaveYourCart'
                )}
                isLoading={isLoading}
                isSubjectToGdpr={isSubjectToGdpr}
                setEmailSetSuccess={setEmailSetSuccess}
            />
        </Box>
    );
};

export default EmailCaptureCardDesignerSlide;
