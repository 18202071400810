import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { ReactComponent as CheckSuccessIcon } from 'svg/check-success.svg';
import { useTranslate } from 'ts/common/hooks';

interface IAddedToCartBannerProps {
    quantity: number;
}

const AddedToCartBanner: React.FC<IAddedToCartBannerProps> = ({ quantity }) => {
    const t = useTranslate('client.gallery.addedToCartBanner');
    return (
        <Flex
            backgroundColor="brand.primaryLight"
            borderRadius="4px"
            data-testid="added-to-cart-banner"
            padding="12px 14px"
            justifyContent="flex-start"
            width="100%"
        >
            <Box color="brand.primary">
                <CheckSuccessIcon color="inherit" height="24px" width="24px" />
            </Box>
            <Text fontSize="md" fontFamily="open-sans, sans-serif" padding="0 10px">
                {t('addedToCart', { items: quantity === 1 ? t('item') : t('items'), quantity })}
            </Text>
        </Flex>
    );
};

export default AddedToCartBanner;
