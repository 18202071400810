import React, { FC } from 'react';
import { Box, Flex, Heading, IconButton, Slide, Text, useBreakpointValue } from '@chakra-ui/react';
import { X } from 'react-feather';
import BackToTopButton from 'ts/common/components/gallery/BackToTopButton';
import Button from 'ts/common/components/gallery/Button';

interface Props {
    /** True only if the large-screen version should be shown. If not specified, then automatically detect based on viewport width. */
    isLargeScreen?: boolean;
    /** True if the user selected enough photos for comparison */
    isReadyToCompare?: boolean;
    /** True only if the page is scrolled down at least one page length */
    isScrolledDown?: boolean;
    /** True only if the last scroll interaction was downward */
    isScrollingDown?: boolean;
    /** Callback for the Compare Photos button */
    onComparePhotosClick?: () => unknown;
    /** Callback for the Exit Compare Mode button */
    onExitClick?: () => unknown;
    /** The Angular translation service */
    translateFilter: SpAngularJs.ITranslateFilter;
}

const CompareHeader: FC<Props> = ({
    isLargeScreen: isLargeScreenControlled,
    isReadyToCompare,
    isScrolledDown,
    isScrollingDown,
    onComparePhotosClick,
    onExitClick,
    translateFilter: translate
}) => {
    const isLargeScreenViewport = useBreakpointValue({ base: false, lg: true }) ?? false;
    const isLargeScreen = isLargeScreenControlled ?? isLargeScreenViewport;

    return (
        <>
            <Flex
                position="sticky"
                top="0px"
                zIndex={3}
                backgroundColor="background"
                alignItems="center"
                justifyContent="space-between"
                borderBottomColor="gray.100"
                borderBottomStyle="solid"
                borderBottomWidth="1px"
                flexDirection="row"
                height="60px"
                padding="16px"
            >
                <Flex alignItems="center" gap="12px">
                    <IconButton
                        icon={<X />}
                        width="40px"
                        height="40px"
                        color="text"
                        backgroundColor="transparent"
                        border="none"
                        _hover={{ color: 'brand.primary' }}
                        _focus={{ color: 'text' }}
                        aria-label={translate('exitCompareMode')}
                        onClick={onExitClick}
                    />
                    <Heading as="h2" size="lg" variant="primary">
                        {translate('comparePhotos')}
                    </Heading>
                </Flex>
                {isLargeScreen && (
                    <Button
                        text={translate('comparePhotosButton')}
                        isDisabled={!isReadyToCompare}
                        onClick={onComparePhotosClick}
                    />
                )}
                <BackToTopButton
                    isHidden={!isScrolledDown || isScrollingDown}
                    translateFilter={translate}
                />
            </Flex>
            <Text paddingX={useBreakpointValue({ lg: '32px' }) ?? '16px'} paddingY="24px">
                {translate('selectTwoPhotosCompare')}
            </Text>
            <Slide direction="bottom" in={!isLargeScreen && isReadyToCompare} style={{ zIndex: 3 }}>
                <Box paddingBottom="24px" paddingX="16px">
                    <Button
                        text={translate('comparePhotosButton')}
                        width="full"
                        isDisabled={!isReadyToCompare}
                        onClick={onComparePhotosClick}
                    />
                </Box>
            </Slide>
        </>
    );
};

export default CompareHeader;
