import React, {
    ComponentType,
    EventHandler,
    PointerEventHandler,
    SyntheticEvent,
    TouchEventHandler,
    useCallback,
    useRef,
    useState
} from 'react';
import { Flex } from '@chakra-ui/react';
import type { IPoint } from 'ts/common/types';
import { isHorizontalSwipeMovement, isVerticalMovement } from 'client_react/gallery/helpers';

/** Represents the state of the current pointer gesture, which may be a swipe */
interface Gesture {
    /** The point at which the pointer was put down */
    firstPoint: IPoint;
    /** True only if the pointer is actively swiping */
    isSwiping: boolean;
}

/** Represents an object with a locally unique id */
interface Identifiable {
    /** A locally unique number or string for this object */
    id: number | string;
}

interface Props<Item extends Identifiable, ItemProps> {
    /** A React component to render for each item in the carousel */
    Component: ComponentType<ItemProps>;
    /** An array of data to use to render to carousel */
    items: Item[];
    /** A function which maps each item in the dataset to props for its React element */
    mapItemToProps: (item: Item) => ItemProps;
}

const handleContextMenu: EventHandler<SyntheticEvent> = (event) => event.preventDefault();
const modulo = (a: number, b: number) => ((a % b) + b) % b;
const transitionMs = 500;

const SwipeCarousel = <Item extends Identifiable, ItemProps>({
    Component,
    items,
    mapItemToProps
}: Props<Item, ItemProps>) => {
    const gestureRef = useRef<Gesture>();

    const [index, setIndex] = useState(0);
    const [isTransitionRunning, setIsTransitionRunning] = useState(false);
    const [offset, setOffset] = useState(0);

    const handlePointerDown: PointerEventHandler = useCallback((event) => {
        gestureRef.current = {
            firstPoint: { x: event.clientX, y: event.clientY },
            isSwiping: false
        };
    }, []);

    const handlePointerMove: PointerEventHandler = useCallback((event) => {
        if (gestureRef.current === undefined) {
            return;
        }

        const eventPoint = { x: event.clientX, y: event.clientY };
        const isStartingToSwipe =
            !gestureRef.current.isSwiping &&
            isHorizontalSwipeMovement(gestureRef.current.firstPoint, eventPoint);

        if (isStartingToSwipe && isVerticalMovement(gestureRef.current.firstPoint, eventPoint)) {
            gestureRef.current = undefined;
            return;
        }

        if (isStartingToSwipe) {
            (event.target as HTMLDivElement).setPointerCapture?.(event.pointerId);
            gestureRef.current.isSwiping = true;
        }

        if (gestureRef.current.isSwiping) {
            setOffset(event.clientX - gestureRef.current.firstPoint.x);
        }
    }, []);

    const handlePointerUp: PointerEventHandler = useCallback(
        (event) => {
            if (gestureRef.current === undefined) {
                return;
            }

            if (gestureRef.current.isSwiping) {
                (event.target as HTMLDivElement).releasePointerCapture?.(event.pointerId);

                const indexDiff = Math.sign(offset);
                const nextIndex = modulo(index - indexDiff, items.length);

                setIsTransitionRunning(true);
                setOffset(window.innerWidth * indexDiff);
                setTimeout(() => {
                    setIndex(nextIndex);
                    setIsTransitionRunning(false);
                    setOffset(0);
                }, transitionMs);
            }

            gestureRef.current = undefined;
        },
        [index, items, offset]
    );

    const handleTouchStart: TouchEventHandler = useCallback((event) => {
        if (event.touches.length > 1) {
            gestureRef.current = undefined;
        }
    }, []);

    const carouselItems = [
        items[modulo(index - 1, items.length)],
        items[index],
        items[modulo(index + 1, items.length)]
    ];

    return (
        <Flex
            alignItems="stretch"
            flexFlow="row nowrap"
            flex="1 1 auto"
            onContextMenu={handleContextMenu}
            onPointerDown={handlePointerDown}
            onPointerMove={handlePointerMove}
            onPointerUp={handlePointerUp}
            onPointerCancel={handlePointerUp}
            onTouchStart={handleTouchStart}
            pointerEvents={isTransitionRunning ? 'none' : 'auto'}
            sx={{ touchAction: 'pan-x pinch-zoom' }} // Needed for WebKit on touch devices to not fire a pointercancel event
            transform={`translateX(${offset}px)`}
            transition={isTransitionRunning ? `transform ${transitionMs}ms` : undefined}
            userSelect="none"
            width="300vw"
        >
            {carouselItems.map((item, index) => (
                <Component key={`${item.id}.${index}`} {...mapItemToProps(item)} />
            ))}
        </Flex>
    );
};

export default SwipeCarousel;
