import { PACKAGE_EVENTS } from 'Sp/Angular/Events/Client/Gallery';
import { PRICE_SHEET_ITEM_EVENTS } from 'Sp/Angular/Events/Client/Gallery';
import { PACKAGE_SMALL_SCREEN_STATES } from 'Sp/Gallery';

/**
 * @ngdoc service
 * @name sp.client.gallery.service:spPackages
 * @requires $rootScope
 * @requires $window
 * @requires sp.client.common.service:SPCartData
 * @requires sp.client.common.service:SPModal
 * @requires sp.newcommon.service:spEventBus
 * @requires sp.newcommon.service:spPackage
 * @requires sp.client.gallery.filter:spPackageItemDetailFilter
 * @requires translateFilter
 *
 * @description
 * Helper functions for working with client package views
 */
export default [
    '$rootScope',
    '$q',
    '$window',
    'SPCartData',
    'SPModal',
    'spEventBus',
    'spPackage',
    'spPackageItemDetailFilter',
    'translateFilter',
    function spPackagesService(
        $rootScope,
        $q,
        $window,
        SPCartData,
        SPModal,
        spEventBus,
        spPackage,
        spPackageItemDetailFilter,
        translateFilter
    ) {
        const PACKAGE_SELECTED_ITEM = 'packageSelectedItem';
        const PACKAGE_SELECTION_STORAGE_KEY = 'packageSelectionState';
        const PACKAGE_SMALL_SCREEN_STATE_KEY = 'packageSmallScreenState';
        const PACKAGE_STORAGE_KEY = 'selectedPackage';
        const service = this;
        let isSinglePackageItemSelectedIndicator = false;
        let packages = [];
        let packagePhotos = [];
        let packageItemSelection = {};
        let selectedPackage;
        let selectedPackageItem;
        let smallScreenState;
        let stopListeningForRouteChangeStart = null;
        let targetAnchorItem = {};

        restoreSelectedPackage();

        $rootScope.$on('$routeChangeSuccess', () => {
            packagePhotos = [];
        });

        Object.assign(service, {
            addToCart,
            assignPhotoToAllPackageItems,
            canAddPackagesToCart,
            clearBackToCartListener,
            editPackageInCart,
            getAssignedPhoto,
            getAssignedSinglePhoto,
            getPackages,
            getPackageItemById,
            getPackageItemsSelectionState,
            getSelectedPackage,
            getSelectedPackageItem,
            getSelectedPackageItemName,
            getSelectedPackagePhoto,
            getSmallScreenState,
            getPackageSelectedItemCount,
            getPackageTotalSelectableQuantity,
            getTargetPackageItem,
            isAPackagePhotoSelected,
            isPackagePhotoSelected,
            isSinglePackageItemSelected,
            openPackageSelectModal,
            mapPackageItemsToSelectionState,
            resetSelectedPackagePhotos,
            retrievePackages,
            selectIndividualPackagePhotoItem,
            selectPackageAllAlbumItem,
            selectPackageItem,
            selectPackageItemWithPhoto,
            selectPackagePhoto,
            selectPackageSingleItem,
            setSelectedPackage,
            setSmallScreenState,
            sumItemQuantities,
            togglePackagePhoto
        });

        return service;

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#addToCart
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Adds the currently selected (or the provided) package to the cart.
         *
         * @param {object} cartData Data required to add an item into the cart
         * @param {boolean} cartData.doesAcceptTerms If the user has accepted GDPR or not
         * @param {string} cartData.email The user's email
         * @param {function} cartData.onError Invoked with an object containing `needsEmail`
         *     or `errors` when adding data to the cart results in form errors from the API
         * @param {function} cartData.onSuccess Invoked when the package has been
         *     successfully added to the cart
         * @param {object=} cartData.packageToAdd Package to be added to the cart
         * @param {object=} cartData.photoForPackage Will be assigned to all items in the
         *     package
         */
        function addToCart({
            doesAcceptTerms,
            email,
            onError,
            onSuccess,
            packageToAdd = selectedPackage,
            photoForPackage = null
        }) {
            let selectionState = packageItemSelection;

            if (photoForPackage) {
                selectionState = packageToAdd.items.reduce((selectionState, item) => {
                    const itemQuantity = spPackage.getPackageItemQuantity(item);

                    selectionState[item.id] = [];

                    for (let i = 0; i < itemQuantity; i++) {
                        selectionState[item.id].push({ photo: photoForPackage });
                    }

                    return selectionState;
                }, {});
            }

            SPCartData.addToCart(
                { ...packageToAdd, selectionState, quantity: 1 },
                null,
                null,
                false,
                email,
                doesAcceptTerms,
                onSuccess,
                ({ errors, status, need_email }) => {
                    if (status === 'form_errors') {
                        if (typeof need_email === 'boolean' && need_email) {
                            onError({ needsEmail: true });

                            return;
                        }

                        onError({ errors });
                    }
                }
            );
        }

        function assignPhotosFromPackageInCart(cartItemId, packageInCart = null) {
            if (packageInCart) {
                assignPhotosToPackageItemSelectionFromPackageInCart(packageInCart);
            } else {
                SPCartData.getCartItems(({ cart }) => {
                    const packageInCart = cart.packageList.find(
                        ({ cartItemId: packageCartItemId }) => packageCartItemId === cartItemId
                    );

                    assignPhotosToPackageItemSelectionFromPackageInCart(packageInCart);
                }, angular.noop);
            }
        }

        function assignPhotosToPackageItemSelectionFromPackageInCart(packageInCart) {
            const priceSheetItemIndexCounts = {};

            packageInCart.items.forEach(({ boundItems, photo, priceSheetItemId }) => {
                const [boundItem] = boundItems;
                const index = priceSheetItemIndexCounts[priceSheetItemId] || 0;

                assignPhotoToPackageItemSelection(
                    photo,
                    priceSheetItemId,
                    index,
                    boundItem.albumId ? { albumId: boundItem.albumId } : undefined
                );

                priceSheetItemIndexCounts[priceSheetItemId] = index + 1;
            });
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#assignPhotoToAllPackageItems
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Assigns an event photo to all items in a package (except All Digitals in an Album)
         * and clears selected photos
         *
         * @param {object} photo Photo data
         * @param {object} metadata Optional metadata
         */
        function assignPhotoToAllPackageItems(photo, metadata = {}) {
            const itemIds = Object.keys(packageItemSelection);
            const nonDigitalAlbumItemIds = itemIds.filter((id) => {
                return (
                    packageItemSelection[id][0] && !packageItemSelection[id][0].isAllAlbumDigital
                );
            });

            if (nonDigitalAlbumItemIds.length) {
                for (const itemId of nonDigitalAlbumItemIds) {
                    const itemEntries = packageItemSelection[itemId] || [];

                    if (itemEntries.length) {
                        itemEntries.forEach((_, index) => {
                            packageItemSelection[itemId][index] = {
                                photo,
                                ...metadata
                            };
                        });
                    }
                }
            }
        }

        function assignPhotoToPackageItemSelection(photo, itemId, itemIndex, metadata = {}) {
            const { [itemId]: itemEntries = [] } = packageItemSelection;

            itemEntries[itemIndex] = {
                photo,
                ...metadata
            };

            packageItemSelection = {
                ...packageItemSelection,
                [itemId]: [...itemEntries]
            };
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#canAddPackagesToCart
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Determines if a package can be added to the cart.
         *
         * @returns {boolean} Returns true if the package setting is on
         *  and a package exists
         */
        function canAddPackagesToCart() {
            const { hasPackages } = $rootScope.eventData.settings;

            return hasPackages && Boolean(packages) && packages.length > 0;
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#clearBackToCartListener
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Clear route change listener created when editing package from cart
         */
        function clearBackToCartListener() {
            if (stopListeningForRouteChangeStart) {
                stopListeningForRouteChangeStart();
                stopListeningForRouteChangeStart = null;
            }
        }

        function deselectPackageItem() {
            selectedPackageItem = { itemId: null, itemIndex: null };
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#editPackageInCart
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Sets up a Package that exists in a Cart for editing.
         *
         * @param {object} packageInCart A Package that exists in the Cart
         */
        function editPackageInCart(packageInCart) {
            const { cartItemId } = packageInCart;
            const selectedPackageId = Number(packageInCart.priceSheetItemId);
            const priceSheetPackage = packages.find(({ id }) => id === selectedPackageId);

            stopListeningForRouteChangeStart = $rootScope.$on(
                '$routeChangeStart',
                handleEditInPackageRouteChange
            );

            setSelectedPackage({ ...priceSheetPackage, cartItemId });
            assignPhotosFromPackageInCart(cartItemId, packageInCart);
            setSelectedDigitalState(packageInCart, priceSheetPackage);
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#getAssignedSinglePhoto
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Retrieves the photo for the package when single pose is enabled. Excludes photos
         * associated with an "All Photos in Album" digital.
         *
         * @returns {object} Photo data
         */
        function getAssignedSinglePhoto() {
            const itemEntries = Object.values(packageItemSelection).find(
                ([firstItem]) => firstItem && !firstItem.isAllAlbumDigital
            );
            const { photo } = itemEntries[0];

            return photo;
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#getAssignedPhoto
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Returns the assigned photo for the provided package item and index.
         *
         * @param {number} itemId Item identifier
         * @param {number} itemIndex Index of of the item entry (tied to item quantity)
         * @returns {object|null} The assigned Photo
         */
        function getAssignedPhoto(itemId, itemIndex) {
            const packageMetadata = packageItemSelection[itemId][itemIndex] || {};
            const { photo = null } = packageMetadata;

            return photo;
        }

        function getPackageItemById(itemId) {
            return selectedPackage.items.find(({ id }) => id === Number(itemId));
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#getPackageItemsSelectionState
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Getter for the package item selected state.
         */
        function getPackageItemsSelectionState() {
            return packageItemSelection;
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#getPackages
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Getter for the packages.
         */
        function getPackages() {
            return packages;
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#getSelectedPackage
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Getter for the package mode state.
         */
        function getSelectedPackage() {
            return selectedPackage;
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#getSelectedPackagePhoto
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Retrieve the current selected package items photo
         *
         * @returns {object} The currently selected package photo
         */
        function getSelectedPackagePhoto() {
            const [selectedPhoto] = packagePhotos;

            return selectedPhoto;
        }

        function getSmallScreenState() {
            return smallScreenState;
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#getTargetPackageItem
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Getter for the selected package item anchor.
         *
         * @returns {object} The currently selected package item anchor
         */
        function getTargetPackageItem() {
            return targetAnchorItem;
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#getSelectedPackageItem
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Getter for the selected package item.
         *
         * @returns {object} The currently selected package item
         */
        function getSelectedPackageItem() {
            return selectedPackageItem;
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#getSelectedPackageItemName
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Getter for the selected package item's name.
         *
         * @returns {string} The currently selected package item's name
         */
        function getSelectedPackageItemName() {
            const { itemId } = getSelectedPackageItem();

            if (!itemId) {
                return '';
            }

            return spPackageItemDetailFilter(
                getSelectedPackage().items.find(({ id }) => id === itemId),
                false,
                false
            );
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#isAPackagePhotoSelected
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Indicates if a Photo is selected in Package Mode.
         *
         * @returns {boolean} If a Photo is selected in Package Mode
         */
        function isAPackagePhotoSelected() {
            return packagePhotos.length > 0;
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#isAPackagePhotoSelected
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Returns whether a single pose package item is selected
         *
         * @returns {boolean} Represents whether a single pose package item is
         *    selected
         */
        function isSinglePackageItemSelected() {
            return isSinglePackageItemSelectedIndicator;
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#isPackagePhotoSelected
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Determines if a package photo has been selected.
         *
         * @param {number} photoId Photo identifier
         */
        function isPackagePhotoSelected(photoId) {
            return Boolean(packagePhotos.find(({ id }) => id === photoId));
        }

        function getAlbumMetadata(album) {
            return album ? { albumId: Number(album.id) || null } : {};
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#getPackageSelectedItemCount
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Returns the count of package items which have a selected image.
         */
        function getPackageSelectedItemCount() {
            return Object.keys(packageItemSelection).reduce((selectedCount, packageItemId) => {
                const item = getPackageItemById(packageItemId);

                if (spPackage.isShootProofFulfilledAllGalleryDownloadsDigitalItem(item)) {
                    return selectedCount;
                }

                const itemEntries = packageItemSelection[packageItemId];

                for (const entry of itemEntries) {
                    if (entry.photo) {
                        selectedCount += 1;
                    }
                }

                return selectedCount;
            }, 0);
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#getPackageTotalSelectableQuantity
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Returns the total quantity of selectable items on the selected package. For example,
         * an "All Items" digital download is not a selectable item, while any individual bounds
         * size or "Individual Items" digital download are selectable items.
         *
         * @returns {number} The number of selectable items on the selected package
         */
        function getPackageTotalSelectableQuantity() {
            return selectedPackage.items.reduce((totalQuantity, item) => {
                if (item.quantity) {
                    totalQuantity += item.quantity;
                } else if (
                    item.individualPhotoDownload &&
                    item.individualPhotoDownload.quantity > 0
                ) {
                    totalQuantity += item.individualPhotoDownload.quantity;
                } else if (
                    item.allAlbumPhotosDownload &&
                    item.allAlbumPhotosDownload.quantity > 0
                ) {
                    totalQuantity += 1;
                }

                return totalQuantity;
            }, 0);
        }

        function handleEditInPackageRouteChange($event, next) {
            if (next.controller !== 'Cart') {
                return;
            }

            if ($window.confirm(translateFilter('backToCartConfirmation'))) {
                service.clearBackToCartListener();
                service.setSmallScreenState(PACKAGE_SMALL_SCREEN_STATES.PHOTO_VIEW);
                service.setSelectedPackage();
            } else {
                $event.preventDefault();
            }
        }

        function mapPackageItemsToSelectionState() {
            if (!selectedPackage) {
                return;
            }

            const { items = [] } = selectedPackage;

            packageItemSelection = items.reduce(
                (packageItemMap, item) => ({
                    ...packageItemMap,
                    [item.id]: spPackage.getInitialPackageItemSelectionState(item)
                }),
                {}
            );
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#openPackageSelectModal
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Opens the package select modal.
         */
        function openPackageSelectModal() {
            SPModal.open('main', {
                partialDirectory: 'package-select',
                type: 'dialog package-select'
            });
        }

        function restoreSelectedPackage() {
            const storedPackage = $window.sessionStorage.getItem(PACKAGE_STORAGE_KEY);
            const storedPackageSelectionState = $window.sessionStorage.getItem(
                PACKAGE_SELECTION_STORAGE_KEY
            );
            const storedSmallScreenState = $window.sessionStorage.getItem(
                PACKAGE_SMALL_SCREEN_STATE_KEY
            );
            const storedSelectedPackageItem = $window.sessionStorage.getItem(PACKAGE_SELECTED_ITEM);

            selectedPackage = storedPackage ? JSON.parse(storedPackage) : undefined;
            packageItemSelection = storedPackageSelectionState
                ? JSON.parse(storedPackageSelectionState)
                : {};
            smallScreenState = storedSmallScreenState
                ? JSON.parse(storedSmallScreenState)
                : PACKAGE_SMALL_SCREEN_STATES.PHOTO_VIEW;
            selectedPackageItem = storedSelectedPackageItem
                ? JSON.parse(storedSelectedPackageItem)
                : { itemId: null, itemIndex: null };

            if (selectedPackage && !packageItemSelection) {
                mapPackageItemsToSelectionState();
            }

            const { cartItemId } = selectedPackage || {};

            if (cartItemId) {
                assignPhotosFromPackageInCart(cartItemId);
            }
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#resetSelectedPackagePhotos
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Resets the selected package photos
         */
        function resetSelectedPackagePhotos() {
            packagePhotos = [];
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#retrievePackages
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Retrieves the packages if the package setting is on.
         */
        function retrievePackages() {
            const { hasPackages, hasPriceSheet } = $rootScope.eventData.settings;

            if (hasPackages && hasPriceSheet) {
                $rootScope.$once(PRICE_SHEET_ITEM_EVENTS.LISTED, (_, listedPackages) => {
                    packages = listedPackages;
                });
                $rootScope.$emit(PRICE_SHEET_ITEM_EVENTS.LIST, { filterIsPackage: true });
            }
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#isSinglePackageItemSelected
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Toggles single pose package item selection
         */
        function selectPackageSingleItem() {
            isSinglePackageItemSelectedIndicator = !isSinglePackageItemSelectedIndicator;
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#selectPackageItem
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Sets the selected package item.
         *
         * @param {number} itemId Item identifier
         * @param {number} itemIndex Index of of the item entry (tied to item quantity)
         */
        function selectPackageItem(itemId, itemIndex) {
            targetAnchorItem = {
                itemId,
                itemIndex
            };
            selectedPackageItem = {
                itemId,
                itemIndex
            };
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#selectPackageAllAlbumItem
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Selects an individual package all photod in album item, leaving the album selected
         *
         * @param {number} albumId Album identifier
         * @param {number} itemId Item identifier
         * @param {number} itemIndex Index of the item entry (tied to item quantity)
         * @param {object} photo Current photo to assign to the album as a stand in
         */
        function selectPackageAllAlbumItem(itemId, itemIndex, albumId, photo) {
            const { [itemId]: itemEntries = [] } = packageItemSelection;

            itemEntries[itemIndex] = {
                id: itemId,
                itemIndex,
                albumId,
                photo,
                isAllAlbumDigital: true
            };

            packageItemSelection = {
                ...packageItemSelection,
                [itemId]: [...itemEntries]
            };
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#selectIndividualPackagePhotoItem
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Selects an individual package photo item, leaving the photo selected.
         *
         * @param {number} itemId Item identifier
         * @param {number} itemIndex Index of of the item entry (tied to item quantity)
         * @param {object|null} album The album of the view
         */
        function selectIndividualPackagePhotoItem(itemId, itemIndex, album) {
            const metadata = getAlbumMetadata(album);
            const [photo] = packagePhotos;

            if (photo) {
                assignPhotoToPackageItemSelection(photo, itemId, itemIndex, metadata);
            }
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#selectPackageItemWithPhoto
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Correlates a price sheet item entry (where there is an entry for each
         * value represented in quantity) with a photo within the gallery.
         *
         * @param {number} itemId Item identifier
         * @param {number} itemIndex Index of of the item entry (tied to item quantity)
         * @param {boolean=} isDelete Represents whether this is a delete operation
         */
        function selectPackageItemWithPhoto(itemId, itemIndex, isDelete = false) {
            if (isDelete) {
                assignPhotoToPackageItemSelection(null, itemId, itemIndex);

                return;
            }

            const [photo] = packagePhotos;

            if (!photo) {
                return;
            }

            assignPhotoToPackageItemSelection(photo, itemId, itemIndex);

            packagePhotos = [];
        }

        /**
         * Forces selection of a package photo
         * @param {object} photo Photo data
         */
        function selectPackagePhoto(photo) {
            packagePhotos = [photo];
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#setSelectedDigitalState
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Sets the current selected digitals package items.
         *
         * @param {object} packageInCart A Package that exists in the Cart
         * @param {object} priceSheetPackage The current Price Sheet Package
         */
        function setSelectedDigitalState(packageInCart, priceSheetPackage) {
            const { items } = packageInCart;
            const digitalPackageItemAllAlbum = priceSheetPackage.items.filter(
                (packageItem) =>
                    packageItem.allAlbumPhotosDownload &&
                    packageItem.allAlbumPhotosDownload.quantity > 0
            );

            if (digitalPackageItemAllAlbum.length) {
                let digitalAllAlbum = [];

                for (let i = 0; i < digitalPackageItemAllAlbum.length; i++) {
                    for (let j = 0; j < items.length; j++) {
                        if (digitalPackageItemAllAlbum[i].id === items[j].priceSheetItemId) {
                            digitalAllAlbum.push(items[j]);
                            break;
                        }
                    }
                }

                if (digitalAllAlbum.length) {
                    for (let i = 0; i < digitalAllAlbum.length; i++) {
                        selectPackageAllAlbumItem(
                            digitalAllAlbum[i].priceSheetItemId,
                            0,
                            digitalAllAlbum[i].boundItems[0].albumId,
                            digitalAllAlbum[i].photo
                        );
                    }
                }
            }
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#setSelectedPackage
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Sets the currently selected package.
         *
         * @param {object} package Selected package data
         */
        function setSelectedPackage(packageData = null) {
            selectedPackage = packageData;

            if (packageData === null) {
                $window.sessionStorage.removeItem(PACKAGE_STORAGE_KEY);
                $window.sessionStorage.removeItem(PACKAGE_SELECTION_STORAGE_KEY);
                $window.sessionStorage.removeItem(PACKAGE_SMALL_SCREEN_STATE_KEY);
                $window.sessionStorage.removeItem(PACKAGE_SELECTED_ITEM);
            } else {
                $window.sessionStorage.setItem(PACKAGE_STORAGE_KEY, JSON.stringify(packageData));
                $window.sessionStorage.setItem(
                    PACKAGE_SMALL_SCREEN_STATE_KEY,
                    JSON.stringify(smallScreenState)
                );
                $window.sessionStorage.setItem(
                    PACKAGE_SELECTED_ITEM,
                    JSON.stringify(selectedPackageItem)
                );

                if (packageItemSelection) {
                    $window.sessionStorage.setItem(
                        PACKAGE_SELECTION_STORAGE_KEY,
                        JSON.stringify(packageItemSelection)
                    );
                }
            }

            packagePhotos = [];

            deselectPackageItem();

            if (packageData && typeof packageItemSelection !== undefined) {
                mapPackageItemsToSelectionState();
            }

            $rootScope.$emit(PACKAGE_EVENTS.SELECT);
        }

        function setSmallScreenState(screenState) {
            smallScreenState = screenState;

            // Force the package builder to render, so it displays the current view on small screens (either Packages or Photos)
            $rootScope.$evalAsync(() => {
                $rootScope.updateCount = ($rootScope.updateCount ?? 0) + 1;
            });
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#sumItemQuantities
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Calculates the sum of quantities on a collection of items on a package.
         *
         * @param {object[]} items Collection of price sheet items
         */
        function sumItemQuantities(items) {
            return items.reduce((sum, item) => {
                return sum + spPackage.getPackageItemQuantity(item);
            }, 0);
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spPackages#togglePackagePhoto
         * @methodOf sp.client.gallery.service:spPackages
         *
         * @description
         * Toggles selection of a package photo, currently supporting only a
         * single photo selected at once.
         *
         * @param {object} targetPhoto Target photo to toggle
         * @param {object|null} album The current photo the album is in
         */
        function togglePackagePhoto(targetPhoto, album) {
            const metadata = getAlbumMetadata(album);

            if (packagePhotos.length) {
                const [firstPackagePhoto] = packagePhotos;

                if (firstPackagePhoto.id === targetPhoto.id) {
                    packagePhotos = [];

                    return;
                }
            } else if (isSinglePackageItemSelected()) {
                assignPhotoToAllPackageItems(targetPhoto);
                selectPackageSingleItem();
                setSmallScreenState(PACKAGE_SMALL_SCREEN_STATES.PACKAGE_VIEW);

                packagePhotos = [];

                return;
            } else if (getSelectedPackageItem()) {
                const { itemId, itemIndex } = getSelectedPackageItem();

                if (itemId && itemIndex >= 0) {
                    assignPhotoToPackageItemSelection(targetPhoto, itemId, itemIndex, metadata);
                    deselectPackageItem();
                    setSmallScreenState(PACKAGE_SMALL_SCREEN_STATES.PACKAGE_VIEW);

                    return;
                }
            }

            packagePhotos = [targetPhoto];
        }
    }
];
