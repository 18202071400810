import React, { ComponentProps, FC, useCallback } from 'react';
import { Flex, Grid, Text, useBreakpointValue, useTheme } from '@chakra-ui/react';
import type { IPhoto, IPhotoActions, IPhotoWithUrl, IRootScope } from 'ts/client/types';
import LoadingIndicator from 'ts/common/components/LoadingIndicator';
import BackNav from 'ts/common/components/gallery/BackNav';
import PhotoComparePanel from 'client_react/gallery/components/PhotoComparePanel';
import SwipeCarousel from 'client_react/gallery/components/SwipeCarousel';

interface Props {
    /** The href for the back nav link */
    backNavHref: string;
    /** True only if the download button should be visible */
    isDownloadPossible: boolean;
    /** True only if the left photo has black & white enabled */
    isLeftPhotoBlackAndWhite: boolean;
    /** True only if the left photo is favorited */
    isLeftPhotoFavorite: boolean;
    /** True only if the left photo is hidden */
    isLeftPhotoHidden: boolean;
    /** True only if the left photo has at least one label applied */
    isLeftPhotoLabeled: boolean;
    /** True only if the right photo has black & white enabled */
    isRightPhotoBlackAndWhite: boolean;
    /** True only if the right photo is favorited */
    isRightPhotoFavorite: boolean;
    /** True only if the right photo is hidden */
    isRightPhotoHidden: boolean;
    /** True only if the right photo has at least one label applied */
    isRightPhotoLabeled: boolean;
    /** The first photo to compare */
    leftPhoto?: IPhotoWithUrl;
    /** An object with callbacks to perform actions on photos */
    photoActions: IPhotoActions;
    /** The id of a photo that is part of a pending download that requires the user to select a download destination */
    requiredDestinationSelectionPhotoId?: IPhoto['id'];
    /** The second photo to compare */
    rightPhoto?: IPhotoWithUrl;
    /** Angular free digitals service */
    spFreeDigitals: SpAngularJs.ISpFreeDigitals;
    /** Angular translation service */
    translateFilter: SpAngularJs.ITranslateFilter;
    /** Angular root scope */
    $rootScope: IRootScope;
}

const ComparePage: FC<Props> = ({
    backNavHref,
    isDownloadPossible,
    isLeftPhotoBlackAndWhite,
    isLeftPhotoFavorite,
    isLeftPhotoHidden,
    isLeftPhotoLabeled,
    isRightPhotoBlackAndWhite,
    isRightPhotoFavorite,
    isRightPhotoHidden,
    isRightPhotoLabeled,
    leftPhoto,
    photoActions,
    requiredDestinationSelectionPhotoId,
    rightPhoto,
    spFreeDigitals,
    translateFilter: translate,
    $rootScope
}) => {
    const mapItemToProps = useCallback(
        (photo: IPhotoWithUrl): ComponentProps<typeof PhotoComparePanel> => {
            const isLeftPhoto = photo === leftPhoto;

            return {
                isBlackAndWhite: isLeftPhoto ? isLeftPhotoBlackAndWhite : isRightPhotoBlackAndWhite,
                isDownloadPossible,
                isFavorite: isLeftPhoto ? isLeftPhotoFavorite : isRightPhotoFavorite,
                isHidden: isLeftPhoto ? isLeftPhotoHidden : isRightPhotoHidden,
                isLabeled: isLeftPhoto ? isLeftPhotoLabeled : isRightPhotoLabeled,
                photo,
                photoActions,
                requiresDownloadDestination: requiredDestinationSelectionPhotoId === photo.id,
                spFreeDigitals,
                translateFilter: translate,
                $rootScope
            };
        },
        [
            leftPhoto,
            isLeftPhotoBlackAndWhite,
            isRightPhotoBlackAndWhite,
            isDownloadPossible,
            isLeftPhotoFavorite,
            isRightPhotoFavorite,
            isLeftPhotoHidden,
            isRightPhotoHidden,
            isLeftPhotoLabeled,
            isRightPhotoLabeled,
            photoActions,
            requiredDestinationSelectionPhotoId,
            spFreeDigitals,
            translate,
            $rootScope
        ]
    );

    const theme = useTheme();
    const isLargeScreen = useBreakpointValue({ base: false, lg: true });

    if (!leftPhoto || !rightPhoto) {
        return <LoadingIndicator />;
    }

    const photos = [leftPhoto, rightPhoto];

    return (
        <Flex
            flexDirection="column"
            gap={isLargeScreen ? 0 : '16px'}
            height="100%"
            left={0}
            position="fixed"
            sx={{ touchAction: 'pan-x pinch-zoom' }} // Needed for WebKit on touch devices to prevent pulling down to refresh, which interferes with swiping
            top={0}
            width="100%"
        >
            <BackNav parentUri={backNavHref} translateFilter={translate} />
            {isLargeScreen ? (
                <Grid
                    background={theme.isLightColorScheme ? 'gray.50' : 'background'}
                    flex="1 1 auto"
                    gap="20px"
                    gridTemplateColumns="repeat(2, 1fr)"
                    padding="32px"
                >
                    {photos.map((photo) => (
                        <Flex
                            key={photo.id}
                            background={theme.isLightColorScheme ? 'background' : 'gray.50'}
                            borderRadius="xl"
                            filter={theme.filters?.dropShadow}
                            padding="20px 40px 0"
                        >
                            <PhotoComparePanel {...mapItemToProps(photo)} />
                        </Flex>
                    ))}
                </Grid>
            ) : (
                <Flex alignItems="center" flexDirection="column" flexGrow={1} gap="40px">
                    <Text fontSize="md" paddingX="16px">
                        {translate('comparePhotosMobileDirections')}
                    </Text>
                    <SwipeCarousel
                        Component={PhotoComparePanel}
                        items={photos}
                        mapItemToProps={mapItemToProps}
                    />
                </Flex>
            )}
        </Flex>
    );
};

export default ComparePage;
