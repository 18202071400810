import type { ICrop } from 'ts/common/types';

export enum SizeVariant {
    Medium = 'medium',
    Small = 'small',
    Square = 'square',
    Custom = 'custom'
}

export interface IProductPhoto {
    width: number;
    height: number;
}

export interface IProductItem {
    boundsName?: string;
    crop: ICrop;
    productMasks: SpApi.ProductMaskUrls & {
        rotation?: number;
    };
    rotation?: number;
}

export interface FrameProps {
    frameColor: Nullable<string>;
    frameImageUrl?: string;
    frameOverlap: Nullable<number>;
    frameWidth: Nullable<number>;
    matColor?: Nullable<string>;
    matHeight?: Nullable<number>;
    matOverlap?: Nullable<number>;
    matWidth?: Nullable<number>;
}
