import React, { useEffect } from 'react';
import { Button as ChakraButton, Flex, FormLabel, Input, useNumberInput } from '@chakra-ui/react';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { styled } from 'sp-ui';
import { useTranslate } from 'ts/common/hooks';
import { IFormValues } from '../types';

const StepperButton = styled(ChakraButton)(
    ({ theme }) => `
        background: ${theme.isLightColorScheme ? 'white' : theme.colors.background};
        border: 1px solid ${theme.isLightColorScheme ? theme.colors.gray[100] : 'white'};
        color: currentColor;
        height: 35px;
        margin: 0;
    `
);

const DecrementButton = styled(StepperButton)(
    () => `
        border-bottom-right-radius: 0px;
        border-right-style: none;
        border-top-right-radius: 0px;
    `
);

const IncrementButton = styled(StepperButton)(
    () => `
        border-left-style: none;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    `
);

const NumberInput = styled(Input)(
    ({ theme }) => `
        margin: 0;
        height: 35px;
        border: 1px solid ${theme.isLightColorScheme ? theme.colors.gray[100] : 'white'};
        border-radius: 0;
        background: ${theme.isLightColorScheme ? 'white' : theme.colors.background};
        color: currentColor;
    `
);

interface IQuantityStepperProps {
    initialValue?: number;
    setValue: UseFormSetValue<IFormValues>;
    watch: UseFormWatch<IFormValues>;
}

const QuantityStepper: React.FC<IQuantityStepperProps> = ({
    initialValue = 1,
    setValue,
    watch
}) => {
    const { getDecrementButtonProps, getIncrementButtonProps, getInputProps, valueAsNumber } =
        useNumberInput({
            defaultValue: initialValue,
            min: 1
        });
    const t = useTranslate('client.gallery.addToCartSidePanel.selectProductOptionsSlide');

    useEffect(() => {
        if (Number.isNaN(valueAsNumber) || valueAsNumber <= 0) {
            setValue('quantity', 1);
        } else {
            setValue('quantity', valueAsNumber);
        }
    }, [setValue, valueAsNumber]);

    const { quantity } = watch();

    return (
        <>
            <FormLabel
                color="text"
                data-testid="quantity-input-label"
                fontFamily="open-sans, sans-serif"
                fontSize="md"
                fontWeight="semibold"
                htmlFor="quantity-input"
                marginBottom="2px"
                marginTop="24px"
            >
                {t('quantity')}
            </FormLabel>
            <Flex marginTop="9px">
                <DecrementButton
                    data-testid="quantity-decrement-button"
                    {...getDecrementButtonProps()}
                >
                    -
                </DecrementButton>
                <NumberInput
                    {...getInputProps()}
                    id="quantity-input"
                    name="quantity"
                    textAlign="center"
                    width="60px"
                    value={quantity}
                />
                <IncrementButton
                    data-testid="quantity-increment-button"
                    {...getIncrementButtonProps()}
                >
                    +
                </IncrementButton>
            </Flex>
        </>
    );
};

export default QuantityStepper;
