export default [
    '$rootScope',
    '$route',
    function SPClientRouteService($rootScope, $route) {
        function SPClientRoute() {
            const self = this;
            let currentDataContext;
            let routeData;

            this.getControllerName = function getControllerName() {
                return $route.current?.$$route?.controller;
            };

            this.getPath = function getPath() {
                return $route.current?.originalPath;
            };

            this.getCurrentUri = function getCurrentUri() {
                return (
                    $route.current &&
                    assembleRoute($route.current.originalPath, $route.current.params)
                );
            };

            this.getDataContext = function getDataContext() {
                if (!this.hasRouteData()) {
                    return false;
                }

                return routeData.dataContext;
            };

            this.getNavigationRoot = function getNavigationRoot() {
                if (!this.hasRouteData()) {
                    return;
                }

                return routeData.navigationRoot;
            };

            this.getNavigationSecondary = function getNavigationSecondary() {
                if (!this.hasRouteData()) {
                    return;
                }

                return routeData.navigationSecondary;
            };

            this.getParentId = function getParentId() {
                if (!this.hasRouteData()) {
                    return false;
                }

                return routeData.parentId;
            };

            this.getParentUri = function getParentUri(albumParentId) {
                if (!this.hasRouteData()) {
                    return;
                }

                if (!this.hasParent()) {
                    return;
                }

                if (routeData.parentId === 'HomeOrAlbum') {
                    if (typeof albumParentId === 'number') {
                        return `album/${albumParentId}`;
                    }

                    return 'home';
                }

                const route = getRouteById(routeData.parentId);

                if (route) {
                    return assembleRoute(route.originalPath, $route.current.params);
                }
            };

            this.hasParent = function hasParent() {
                if (!this.hasRouteData()) {
                    return false;
                }

                if (
                    routeData.parentId === 'HomeOrAlbum' &&
                    $rootScope.eventData.settings.hideAllAlbums
                ) {
                    return false;
                }

                return typeof routeData.parentId === 'string';
            };

            this.hasRouteData = function hasRouteData() {
                return typeof routeData === 'object';
            };

            this.isControllerName = function isControllerName(controllerName) {
                return this.getControllerName() === controllerName;
            };

            this.isDataContext = function isDataContext(context) {
                return this.getDataContext() === context;
            };

            this.isMusicDisabledForRoute = function isMusicDisabledForRoute() {
                if (!this.hasRouteData()) {
                    return true;
                }

                return routeData.musicDisabled === true;
            };

            this.isNavigationDisabled = function isNavigationDisabled() {
                if (!this.hasRouteData()) {
                    return false;
                }

                return routeData.navigationDisabled === true;
            };

            this.isNavigationRoot = function isNavigationRoot(context) {
                return this.getNavigationRoot() === context;
            };

            this.isNavigationSecondary = function isNavigationSecondary(context) {
                return self.getNavigationSecondary() === context;
            };

            this.reload = function reload() {
                currentDataContext = undefined;

                $route.reload();
            };

            $rootScope.$on('$routeChangeSuccess', () => {
                routeData = $route.current.spRoute;

                let newDataContext = self.getDataContext();

                // @@STUB: This violates single responsibility, move into routing
                // This way we only refresh album data when navigating between
                // albums, not for the same album
                if (newDataContext === 'Album') {
                    newDataContext += `/${$route.current.params.albumId}`;
                }

                if (
                    typeof currentDataContext === undefined ||
                    currentDataContext !== newDataContext
                ) {
                    currentDataContext = newDataContext;

                    $rootScope.$broadcast('SPClientRoute:RouteDataContextChange');
                } else {
                    $rootScope.$broadcast('SPClientRoute:RouteDataContextRefresh');
                }
            });

            function assembleRoute(routePath, values) {
                if (!routePath) {
                    return routePath;
                }

                const params = routePath.match(/:[A-Za-z-_]+/g);

                angular.forEach(params, (param) => {
                    const key = param.substring(1);

                    routePath = routePath.replace(param, values[key]);
                });

                if (routePath[0] === '/') {
                    routePath = routePath.substring(1);
                }

                if (routePath === 'search' && values.q) {
                    routePath += `?q=${values.q}`;
                }

                return routePath;
            }

            function getRouteById(id) {
                for (const key in $route.routes) {
                    if ($route.routes[key].spRouteId === id) {
                        return $route.routes[key];
                    }
                }

                return false;
            }
        }

        return new SPClientRoute();
    }
];
