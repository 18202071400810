const NOTIFICATION_EVENTS = {
    FAVORITES: {
        SEND: 'client-notification-send',
        SENT: 'client-notification-sent'
    }
};

export default {
    NOTIFICATION_EVENTS
};

export { NOTIFICATION_EVENTS };
