import { useMemo } from 'react';
import type { INavTab } from '../types';

interface IUseNavTabsInput {
    isAboutContactVisible?: boolean;
    isAboutMessageVisible?: boolean;
    isAboutPricingVisible?: boolean;
    isAdminModeEnabled?: boolean;
    isHiddenPhotosVisible?: boolean;
    isHomeSecondaryNavVisible?: boolean;
    isLabeledPhotosVisible?: boolean;
    selectedRoute: 'Home' | 'Album' | 'Special' | 'Search' | 'Info' | 'Cart' | 'Store';
    selectedUri?: Nullable<string>;
    translate: SpAngularJs.ITranslateFilter;
}

interface IUseNavTabsOutput {
    navTabs: INavTab[] | undefined;
    navTabsKey: 'Home' | 'Special' | 'Search' | 'Info' | 'Cart' | 'Store';
    selectedNavTabIndex: number | undefined;
}

export const useNavTabs = ({
    isAboutContactVisible,
    isAboutMessageVisible,
    isAboutPricingVisible,
    isAdminModeEnabled,
    isHiddenPhotosVisible,
    isHomeSecondaryNavVisible,
    isLabeledPhotosVisible,
    selectedRoute,
    selectedUri,
    translate
}: IUseNavTabsInput): IUseNavTabsOutput => {
    const navTabs = useMemo(() => {
        if ((selectedRoute === 'Home' || selectedRoute === 'Album') && isHomeSecondaryNavVisible) {
            const items: INavTab[] = [
                {
                    href: 'home',
                    label: translate('albums')
                },
                {
                    href: 'album/all',
                    label: translate('allPhotos')
                }
            ];
            return items.some((item) => item.href === selectedUri) ? items : undefined;
        }

        if (selectedRoute === 'Special' && isAdminModeEnabled) {
            const items: INavTab[] = [
                {
                    href: 'favorites',
                    label: translate('favorites')
                },
                isHiddenPhotosVisible && {
                    href: 'hidden',
                    label: translate('hidden')
                },
                isLabeledPhotosVisible && {
                    href: 'labels',
                    label: translate('labeled')
                }
            ].filter((item): item is INavTab => Boolean(item));
            return items.some((item) => item.href === selectedUri) && items.length > 1
                ? items
                : undefined;
        }

        if (selectedRoute === 'Info') {
            const items: INavTab[] = [
                isAboutContactVisible && {
                    href: 'info/contact',
                    label: translate('contact')
                },
                isAboutMessageVisible && {
                    href: 'info/message',
                    label: translate('message')
                },
                isAboutPricingVisible && {
                    href: 'info/pricing',
                    label: translate('pricing')
                }
            ].filter((item): item is INavTab => Boolean(item));
            return items.length > 1 ? items : undefined;
        }

        return undefined;
    }, [
        isAboutContactVisible,
        isAboutMessageVisible,
        isAboutPricingVisible,
        isAdminModeEnabled,
        isHiddenPhotosVisible,
        isHomeSecondaryNavVisible,
        isLabeledPhotosVisible,
        selectedRoute,
        selectedUri,
        translate
    ]);

    const navTabIndex = navTabs?.findIndex((tab) => tab.href === selectedUri);
    const selectedNavTabIndex = navTabIndex === -1 ? 0 : navTabIndex;

    const navTabsKey = selectedRoute === 'Album' ? 'Home' : selectedRoute;

    return { navTabs, navTabsKey, selectedNavTabIndex };
};

export default useNavTabs;
