/* eslint-disable func-names */
import { PRICE_SHEET_ITEM_EVENTS } from 'Sp/Angular/Events/Client/Gallery';

export default [
    '$rootScope',
    '$scope',
    'SPClientData',
    'spAppData',
    'spEventBus',
    'spPackageItemDetailFilter',
    'spPackages',
    function InfoPricingController(
        $rootScope,
        $scope,
        SPClientData,
        spAppData,
        spEventBus,
        spPackageItemDetailFilter,
        spPackages
    ) {
        $scope.currentItemGroup = {};

        $scope.getDigitalBoundsItemName = getDigitalBoundsItemName;
        $scope.isSelfFulfilledDigital = isSelfFulfilledDigital;
        $scope.isPackagesOnlyEnabled = isPackagesOnlyEnabled;
        $scope.selectItemGroup = selectItemGroup;
        $scope.isCardItem = isCardItem;

        const { hasPackages } = $rootScope.eventData.settings;

        if (hasPackages) {
            spEventBus.once(PRICE_SHEET_ITEM_EVENTS.LISTED, packagesListed);
            spPackages.retrievePackages();
        } else {
            packagesListed([]);
        }

        function packagesListed(packages) {
            let packagesGroup = {
                name: 'Packages',
                isPackagesGroup: true,
                packages
            };

            SPClientData.getPriceSheetAllItems((response) => {
                $scope.priceSheetGroups = response.groups;
                $scope.discountDescriptions = response.discountDescriptions;

                if (packages.length > 0) {
                    $scope.priceSheetGroups.push(packagesGroup);

                    if (isPackagesOnlyEnabled()) {
                        $scope.priceSheetGroups = $scope.priceSheetGroups.filter(
                            (group) => group.isPackagesGroup
                        );
                    }
                }

                formatDigitalsGroup();

                $scope.currentItemGroup = $scope.priceSheetGroups[0];
            });
        }

        function isPackagesOnlyEnabled() {
            const { settings } = spAppData.get('eventData');

            return settings.priceSheetShowPackagesOnly;
        }

        function formatDigitalsGroup() {
            let digitalsGroup = $scope.priceSheetGroups.find((group) => {
                return group.isDigitalDownload;
            });

            if (digitalsGroup) {
                let formattedDigitalItems = digitalsGroup.bounds.reduce((itemsArray, item) => {
                    let boundItem = item.boundItems[0];
                    let parentItem;

                    if (!boundItem.parentPriceSheetItemId) {
                        itemsArray.push(item);

                        return itemsArray;
                    }

                    parentItem = itemsArray.find((arrayItem) => {
                        return (
                            arrayItem.boundItems[0].priceSheetItemId ===
                            boundItem.parentPriceSheetItemId
                        );
                    });

                    if (parentItem) {
                        parentItem.boundItems.push(boundItem);
                    }

                    return itemsArray;
                }, []);

                digitalsGroup.bounds = formattedDigitalItems;
            }
        }

        function getDigitalBoundsItemName(boundItem) {
            if ($scope.boundItemUsedForAlbum(boundItem)) {
                return 'allAlbumPhotos';
            }

            if ($scope.boundItemUsedForAll(boundItem)) {
                return 'allGalleryPhotos';
            }

            return 'individualPhotos';
        }

        function isCardItem(bound) {
            return bound.boundItems.some((item) => item.templateId);
        }

        function isSelfFulfilledDigital(boundsGroup) {
            return (
                $scope.currentItemGroup.isDigitalDownload &&
                boundsGroup.digitalType === 'self' &&
                boundsGroup.boundItems.length === 1
            );
        }

        function selectItemGroup(group) {
            $scope.currentItemGroup = group;
        }
    }
];
