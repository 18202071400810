import { useCallback } from 'react';
import { useGalleryApiFetch } from 'ts/client/common';
import type { IAddItemToCartResponse, IImage } from 'ts/client/gallery/components';
import { FetchMethod } from 'ts/common/hooks';

const useAddToCart = () => {
    const {
        loading: isPostToCartLoading,
        response: postToCartResponse,
        performFetch: postToCart
    } = useGalleryApiFetch<IAddItemToCartResponse>('cart/additem', {
        defer: true,
        method: FetchMethod.POST
    });

    const postDigitalItemToCart = useCallback(
        (
            image: IImage,
            priceSheetItemId: number,
            quantity: number,
            selectedAlbumId?: number,
            debugData?: object
        ) => {
            if (image && priceSheetItemId && quantity) {
                postToCart({
                    body: new URLSearchParams({
                        album_id: selectedAlbumId ? String(selectedAlbumId) : '',
                        crop_data: '',
                        photo_filter_id: '1',
                        photo_id: String(image.id),
                        price_sheet_item_id: String(priceSheetItemId),
                        quantity: String(quantity),
                        ...(selectedAlbumId && debugData
                            ? { debug_data: JSON.stringify(debugData) }
                            : {})
                    })
                });
            }
        },
        [postToCart]
    );

    return {
        isPostToCartLoading,
        postDigitalItemToCart,
        postToCart,
        postToCartResponse
    };
};

export default useAddToCart;
