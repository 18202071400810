export default [
    '$rootScope',
    'SPClientRoute',
    function SPPhotoUriService($rootScope, SPClientRoute) {
        const service = new (function SPPhotoUri() {
            this.build = function build(photo) {
                if (typeof photo !== 'object') {
                    return '';
                }

                let contextUri = SPClientRoute.isControllerName('Photo')
                    ? SPClientRoute.getParentUri()
                    : SPClientRoute.getCurrentUri();

                // strip off any search params, since we're going to build on the path
                contextUri = contextUri ? contextUri.split('?')[0] : '';

                if (contextUri === 'home') {
                    contextUri = '';
                } else {
                    contextUri = contextUri + '/';
                }

                return contextUri + 'photo/' + photo.id;
            };
        })();

        $rootScope.SPPhotoUri = service;

        return service;
    }
];
