import React, { FC } from 'react';
import { Image } from '@chakra-ui/react';

interface IStudioLogoProps {
    logo: SpApi.IBrandLogoImage;
    logoAltText: string;
    logoSize?: 'sm' | 'md' | 'lg';
}

const StudioLogo: FC<IStudioLogoProps> = ({ logo, logoAltText, logoSize = 'md' }) => {
    const isPortrait = logo.height > logo.width;
    const maxSize = logoSize === 'sm' ? 4 : logoSize === 'md' ? 13.75 : 18.75;

    return (
        <Image
            alt={logoAltText}
            height={`${isPortrait ? maxSize : (maxSize / logo.width) * logo.height}rem`}
            src={logo.displayUrl.original}
            width={`${isPortrait ? (maxSize / logo.height) * logo.width : maxSize}rem`}
        />
    );
};

export default StudioLogo;
