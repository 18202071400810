import COMMON_MODALS from 'Sp/Angular/Modals/Common';
import { PRICE_SHEET_ITEM_PACKAGE_TYPE } from 'Sp/Api';
import {
    CANVAS_EXHIBITION_TYPES,
    CANVAS_RENDERING_TYPES,
    CANVAS_THUMBNAILS,
    CANVAS_THUMBNAIL_EXHIBITION_RENDERING_MAP
} from 'Sp/Canvas';
import { PACKAGE_SMALL_SCREEN_STATES } from 'Sp/Gallery';
import { EMAIL_REGEX } from 'Sp/Regex';

export default [
    '$location',
    '$routeParams',
    '$scope',
    '$timeout',
    '$window',
    'SPModal',
    'SPPhoto',
    'SPClientRoute',
    'SPClientData',
    'SPCartData',
    'spAppData',
    'SPMusicData',
    'spPackage',
    'spPackages',
    'translateFilter',
    function AddToCartController(
        $location,
        $routeParams,
        $scope,
        $timeout,
        $window,
        SPModal,
        SPPhoto,
        SPClientRoute,
        SPClientData,
        SPCartData,
        spAppData,
        SPMusicData,
        spPackage,
        spPackages,
        translateFilter
    ) {
        const SMALL_WINDOW_WIDTH = 767;
        const PRODUCT_VIEW_STAGE_ID = 'product-edit';
        const PRODUCT_PREVIEW_STAGE = 'product-preview';
        let currentCropValues = {};
        let currentlySelectedPackage;
        let currentlySelectedPhoto;

        $scope.CANVAS_RENDERING_TYPES = CANVAS_RENDERING_TYPES;
        $scope.ITEM_TYPES = {
            PACKAGES: 'packages',
            ITEMS: 'items'
        };
        $scope.canvasThumbnails = Object.values(CANVAS_THUMBNAILS);
        $scope.canvasThumbnailExhibitionMap = CANVAS_THUMBNAIL_EXHIBITION_RENDERING_MAP;
        $scope.cartPhotoState = false;
        $scope.chooseCardTemplateDescription = translateFilter('cardTemplateChooseDescription');
        $scope.credentials = {};
        $scope.currentGroupType = { value: $scope.ITEM_TYPES.PACKAGES };
        $scope.emailPattern = EMAIL_REGEX.VALID_EMAIL;
        $scope.isPackageSinglePose = spPackage.getUseSingleImageIndicator;
        $scope.photos = $scope.photos instanceof Array ? $scope.photos : [$scope.photos];
        $scope.priceSheet = false;
        $scope.selectedCanvasExhibition = CANVAS_EXHIBITION_TYPES.SOLO;
        $scope.selectedPerspectiveRendering = CANVAS_RENDERING_TYPES.PERSPECTIVE;
        $scope.selectedCanvasThumbnail = CANVAS_THUMBNAILS.PERSPECTIVE;
        $scope.selections = {
            group: false,
            bound: false,
            boundItem: false,
            productImage: false,
            productImageIndex: null,
            email: ''
        };
        $scope.singlePhoto = $scope.photos.length === 1;
        $scope.spPackages = spPackages;
        $scope.stage = 'choose-bound';
        $scope.tryingToAddPackageToCart = false;

        Object.assign($scope, {
            PRODUCT_PREVIEW_STAGE,
            PRODUCT_VIEW_STAGE_ID,
            addEmail,
            addToCart,
            boundItemUsedForAlbum,
            boundItemUsedForAll,
            boundItemUsedForIndividual,
            boundUsedForAlbum,
            boundUsedForAll,
            boundUsedForIndividual,
            canClose,
            canShowBound,
            canShowBoundItem,
            canShowGroup,
            copyDeepLink,
            doesMatchType,
            enterConfirmStage,
            enterStage,
            getBoundItemName,
            getCardTemplateName,
            getEditorPhoto,
            getEditorItem,
            getHeading,
            getPackageItemCount: spPackage.getPackageItemCount,
            getPreviousStage,
            getPreviousStageName,
            handleCropUpdate,
            hasPackages,
            isCanvasItem,
            isCardItem,
            isCroppableCanvas,
            isPackagesOnly,
            isPackageSelected,
            isPageComparePhoto,
            isProductImageSelected,
            isCanvasThumbnailSelected,
            launchEditor,
            removeFromCart,
            resetPhotoCrop,
            resetState,
            selectBound,
            selectBoundItem,
            selectCanvasThumbnail,
            selectCanvasThumbnailLegacy,
            selectPackage,
            selectProductThumbnail,
            selectTemplatePreview,
            setEditorBlock,
            showCanvasPreview,
            isAcrylicBlock,
            showCroppedImagePreview,
            showPreviewComponent,
            showEditProductButton,
            submitEdit,
            viewPackage
        });

        $scope.resetState();

        function submitEdit() {
            const originalCrop = $scope.selections.crop;

            $scope.selections.crop = { ...originalCrop, ...currentCropValues };
            $scope.selections.boundItem.crop = { ...originalCrop, ...currentCropValues };
            enterConfirmStage();
        }

        function addEmail() {
            SPClientData.setEmail(
                $scope.selections.email,
                $scope.credentials.doesAcceptTerms,
                launchEditor,
                function onError(response) {
                    if (response.status === 'form_errors') {
                        $scope.errors = response.errors;
                    }
                }
            );
        }

        function addToCart(boundItem, photos) {
            if ($scope.tryingToAddPackageToCart) {
                return addPackageToCart();
            }

            const fromFavorites = SPClientRoute.getDataContext() === 'Favorites';

            SPCartData.addToCart(
                boundItem,
                photos,
                $scope.album ? $scope.album.id : false,
                fromFavorites,
                $scope.selections.email,
                $scope.credentials.doesAcceptTerms,
                function withSuccessResponse(response) {
                    $scope.stage = 'success';

                    if (typeof response.cartPhotoState === 'object') {
                        $scope.cartPhotoState = response.cartPhotoState;
                    } else {
                        $scope.cartPhotoState = false;
                    }
                },
                function withErrorResponse(response) {
                    // Standard form error handling, but also handle
                    // if a value exists indicating that email address
                    // needs to be collected.
                    if (response.status === 'form_errors') {
                        // If the response is an error indicating that email
                        // is needed, collect it from the end user.  They can
                        // then add to Cart again, but with email sent along.
                        if (typeof response.need_email === 'boolean' && response.need_email) {
                            $scope.stage = 'collect-email';

                            return;
                        }

                        $scope.errors = response.errors;

                        return;
                    }

                    $scope.$parent.errorMessage = response.msg;

                    SPModal.navigate('../error-general');
                }
            );
        }

        function addPackageToCart() {
            $scope.tryingToAddPackageToCart = true;

            spPackages.addToCart({
                doesAcceptTerms: $scope.credentials.doesAcceptTerms,
                email: $scope.selections.email,
                onError: ({ errors }) => {
                    $scope.errors = errors;
                    $scope.stage = 'collect-email';
                },
                onSuccess: () => {
                    $scope.stage = 'success';
                },
                packageToAdd: currentlySelectedPackage,
                photoForPackage: currentlySelectedPhoto
            });
        }

        function boundItemUsedForAlbum(boundItem) {
            return boundItem.usage.album();
        }

        function boundItemUsedForAll(boundItem) {
            return boundItem.usage.all();
        }

        function boundItemUsedForIndividual(boundItem) {
            return boundItem.usage.individual();
        }

        function boundUsedForAlbum(bound) {
            return $scope.boundItemUsedForAlbum(bound.boundItems[0]);
        }

        function boundUsedForAll(bound) {
            return $scope.boundItemUsedForAll(bound.boundItems[0]);
        }

        function boundUsedForIndividual(bound) {
            return $scope.boundItemUsedForIndividual(bound.boundItems[0]);
        }

        function canClose() {
            return !['crop', PRODUCT_PREVIEW_STAGE, PRODUCT_VIEW_STAGE_ID].includes($scope.stage);
        }

        function canShowBound() {
            return true;
        }

        function canShowBoundItem() {
            return true;
        }

        function canShowGroup(group) {
            for (var index in group.bounds) {
                if (group.bounds[index]) {
                    var bound = group.bounds[index];

                    if ($scope.canShowBound(bound)) {
                        return true;
                    }
                }
            }

            return false;
        }

        function doesMatchType(groupType) {
            return $scope.currentGroupType.value === groupType;
        }

        function enterConfirmStage() {
            if (!$scope.isCroppableCanvas()) {
                $scope.selections.productImageIndex = -1;
            } else {
                $scope.selectCanvasThumbnailLegacy(CANVAS_THUMBNAILS.PERSPECTIVE);

                $scope.selections.productImageIndex = null;
            }

            SPModal.shrink();

            $scope.stage = 'confirm';
        }

        function selectTemplatePreview(item) {
            $scope.selections.boundItem = item;
            setEditorBlock(false);

            // Default quantity to one if value is invalid
            if (
                typeof $scope.selections.boundItem.quantity !== 'number' ||
                $scope.selections.boundItem.quantity < 1
            ) {
                $scope.selections.boundItem.quantity = 1;
            }

            $scope.stage = 'template-preview';
        }

        function enterStage(stage) {
            if (isExpandableStage(stage)) {
                SPModal.expand();
            } else {
                SPModal.shrink();
            }

            $scope.lastStage = $scope.stage;
            $scope.stage = stage;
        }

        function getBoundItemName({ name: boundName }, { name: boundItemName }) {
            if (!boundName) {
                return boundItemName;
            }

            if (boundName !== boundItemName) {
                return `${boundName} - ${boundItemName}`;
            }

            return boundName;
        }

        function getCardTemplateName() {
            const occasion = $scope.selections.bound.name;
            const template = $scope.selections.boundItem.name;

            if (template.includes(occasion)) {
                return `${template} cards`;
            }

            return `${template} - ${occasion} cards`;
        }

        function getEditorPhoto() {
            return $scope.photos[0];
        }

        function getEditorItem() {
            return $scope.selections.boundItem;
        }

        function getHeading() {
            if (
                $scope.currentGroupType.value === $scope.ITEM_TYPES.ITEMS ||
                !$scope.hasPackages()
            ) {
                return 'chooseItem';
            }

            return 'choosePackage';
        }

        /**
         * This returns the state previous stage from the current stage, if it
         * returns undefined you have no back button
         */
        function getPreviousStage() {
            switch ($scope.stage) {
                case 'choose-bound-item':
                case 'choose-card-template':
                case 'warn-mobile-users':
                    return 'choose-bound';
                case 'confirm':
                    return selectedGroupHasMultipleOptions() ? 'choose-bound-item' : 'choose-bound';
                case 'template-preview':
                    return 'choose-card-template';
                case PRODUCT_PREVIEW_STAGE:
                case PRODUCT_VIEW_STAGE_ID:
                case 'crop':
                    return 'confirm';
                case 'collect-email':
                    return 'confirm';
                case 'collect-email-predesign':
                    return 'template-preview';
            }
        }

        /**
         * This returns the text name of the previous stage for user display
         */
        function getPreviousStageName() {
            switch ($scope.stage) {
                case 'choose-bound-item':
                case 'choose-card-template':
                case 'warn-mobile-users':
                    return $scope.selections.group.name;
                case 'confirm':
                    return selectedGroupHasMultipleOptions()
                        ? 'options'
                        : $scope.selections.group.name;
                case 'crop':
                    return 'set crop';
                case 'collect-email':
                    return $scope.eventData.settings.allowCropping ? 'set crop' : 'confirm';
                case PRODUCT_PREVIEW_STAGE:
                case PRODUCT_VIEW_STAGE_ID:
                    return 'Back';
                case 'template-preview':
                    return 'cardTemplatesGoBack';
                case 'collect-email-predesign':
                    return 'cardTemplatesPreviewDesign';
            }
        }

        function handleCropUpdate(crop) {
            currentCropValues = { ...crop, isChanged: true };
        }

        function hasPackages() {
            return spPackages.getPackages().length > 0;
        }

        function isCanvasItem() {
            return $scope.selections.group.type === 2;
        }

        function isAcrylicBlock() {
            return $scope.selections.group.type === 9;
        }

        function isCardItem(bound) {
            return (
                $scope.selections.group.type === 8 &&
                bound.boundItems.some((item) => item.templateId)
            );
        }

        function isCanvasThumbnailSelected(thumbnail) {
            return $scope.selectedCanvasThumbnail === thumbnail;
        }

        function isCroppableCanvas() {
            return (
                $scope.eventData.settings.allowCropping &&
                !$scope.isPackageSelected() &&
                $scope.singlePhoto &&
                $scope.isCanvasItem()
            );
        }

        function isExpandableStage(stage) {
            return [PRODUCT_PREVIEW_STAGE, PRODUCT_VIEW_STAGE_ID].includes(stage);
        }

        function shouldBlockWHCCEditor() {
            // This is really ugly, but the requirment is to change behavior for clicks based on moble vs not.
            const windowWidth = angular.element($window).outerWidth();

            return windowWidth <= SMALL_WINDOW_WIDTH;
        }

        function isPackagesOnly() {
            const { settings } = spAppData.get('eventData');
            const { priceSheetShowPackagesOnly } = settings;

            return priceSheetShowPackagesOnly;
        }

        function isPackageSelected() {
            return $scope.selections.boundItem.type === PRICE_SHEET_ITEM_PACKAGE_TYPE;
        }

        function isPageComparePhoto() {
            return $scope.getPageId() === 'pageComparePhotos';
        }

        function isProductImageSelected() {
            return $scope.selections.productImageIndex !== null;
        }

        function getPathContext() {
            let context = SPClientRoute.getDataContext()?.toLowerCase();
            let contextId = undefined;
            const { boundsName, name: boundItemName } = $scope.selections.boundItem;
            const { id: groupId } = $scope.selections.group;
            const photoId = $routeParams.photoId ?? $scope.photos[0]?.id;

            switch (context) {
                case 'compare':
                    // Try and pull off the album or tag if it exists
                    const pathSplit = $routeParams.from.split('/');
                    // Check for search param
                    const querySplit = pathSplit[0].split('?q=');

                    context = querySplit[0];
                    contextId = pathSplit[1] ?? querySplit[1];
                    break;
                case 'tag':
                    context = 'label';
                    contextId = $routeParams.tagName;
                    break;
                case 'album':
                    contextId = $routeParams.albumId;
                    break;
                case 'search':
                    contextId = $routeParams.q;
                case 'favorites':
                case 'hidden':
                case 'home':
                    break;
            }

            return {
                boundItemName,
                boundsName,
                context,
                contextId,
                groupId,
                photoId
            };
        }

        function setEditorBlock(block) {
            $scope.blockEditor = block;
        }

        function launchEditor() {
            if (shouldBlockWHCCEditor()) {
                setEditorBlock(true);
                return;
            }

            $scope.editorLoading = true;
            const { context, contextId } = getPathContext();

            SPCartData.launchEditor(
                $scope.selections.boundItem,
                $scope.photos[0].id,
                context,
                contextId,
                function withSuccessResponse(response) {
                    window.location.href = response.data.url;
                },
                function withErrorResponse(response) {
                    $scope.editorLoading = false;

                    if (response.needEmail) {
                        $scope.stage = 'collect-email-predesign';
                        return;
                    }

                    $scope.$parent.errorMessage = response.msg;

                    SPModal.navigate('../error-general');
                }
            );
        }

        function removeFromCart(boundItem, photos) {
            if (!$scope.singlePhoto) {
                return;
            }

            SPCartData.removeFromCart(
                boundItem,
                photos,
                function onSuccess() {
                    // boundItem.wasRemoved = true;
                    $scope.stage = 'success';
                },
                function withErrorResponse(response) {
                    $scope.$parent.errorMessage = response.msg;

                    SPModal.navigate('../error-general');
                }
            );
        }

        function resetPhotoCrop() {
            $scope.selections.crop = $scope.selections.boundItem.resetCrop(SPPhoto);
        }

        function resetState() {
            $scope.selections = {
                group: false,
                bound: false,
                boundItem: false,
                productImage: false,
                productImageIndex: null,
                email: ''
            };

            $scope.stage = 'choose-bound';
            $scope.priceSheet = false;
            $scope.cartPhotoState = false;

            function selectInitialState({ groupId, boundsName, boundItemName }) {
                const foundGroup =
                    groupId !== undefined &&
                    $scope.priceSheet.find((group) => encodeURIComponent(group.id) === groupId);

                if (!foundGroup) {
                    $scope.selections.group = $scope.priceSheet[0];
                    return;
                }

                $scope.selections.group = foundGroup;
                const foundBound =
                    boundsName !== undefined &&
                    foundGroup.bounds.find(
                        (bound) => encodeURIComponent(bound.name) === boundsName
                    );

                if (!foundBound) {
                    return;
                }

                const foundBoundItem =
                    boundItemName !== undefined &&
                    foundBound.boundItems.find(
                        (boundItem) =>
                            encodeURIComponent(boundItem.name) === boundItemName ||
                            boundItem.name === boundItemName
                    );

                if (!foundBoundItem) {
                    selectBound(foundBound);
                    return;
                }

                $scope.selections.bound = foundBound;

                if (isCardItem) {
                    selectTemplatePreview(foundBoundItem);
                } else {
                    selectBoundItem(foundBoundItem);
                }
            }

            function priceSheetSuccessCallback(response) {
                $scope.discountDescriptions = response.discountDescriptions;
                $scope.cartPhotoState = response.cartPhotoState;
                $scope.priceSheet = response.groups;

                const passedProductSelectionState = $scope.passedProductSelectionState;

                if (passedProductSelectionState?.groupId) {
                    selectInitialState(passedProductSelectionState);
                    $scope.setPassedProductSelectionState(undefined);
                } else {
                    $scope.selections.group = $scope.priceSheet[0];
                }
            }

            function priceSheetErrorCallback(response) {
                $scope.errorMessage = response.msg;

                SPModal.navigate('../error-general');
            }

            const controllerName = SPClientRoute.getDataContext();

            if (controllerName === 'Favorites') {
                SPClientData.getPriceSheetForFavorites(
                    $scope.photos,
                    priceSheetSuccessCallback,
                    priceSheetErrorCallback
                );
            } else if (controllerName === 'Hidden') {
                SPClientData.getPriceSheetForHidden(
                    $scope.photos,
                    priceSheetSuccessCallback,
                    priceSheetErrorCallback
                );
            } else if (controllerName === 'Tag') {
                SPClientData.getPriceSheetForTag(
                    $scope.photos,
                    $scope.tag,
                    priceSheetSuccessCallback,
                    priceSheetErrorCallback
                );
            } else {
                SPClientData.getPriceSheetForPhotos(
                    $scope.photos,
                    $scope.album,
                    priceSheetSuccessCallback,
                    priceSheetErrorCallback
                );

                spPackages.retrievePackages();
            }
        }

        function selectedGroupHasMultipleOptions() {
            return (
                $scope.selections.group.isDigitalDownload !== true &&
                $scope.selections.bound.boundItems &&
                $scope.selections.bound.boundItems.length > 1
            );
        }

        function selectBound(bound) {
            $scope.selections.bound = bound;

            if (
                $scope.selections.bound.boundItems &&
                $scope.selections.bound.boundItems.length === 1
            ) {
                if (isCardItem(bound)) {
                    $scope.selectTemplatePreview($scope.selections.bound.boundItems[0]);
                } else {
                    $scope.selectBoundItem($scope.selections.bound.boundItems[0]);
                }

                return;
            }

            $scope.stage = isCardItem(bound)
                ? shouldBlockWHCCEditor()
                    ? 'warn-mobile-users'
                    : 'choose-card-template'
                : 'choose-bound-item';
        }

        function selectBoundItem(item) {
            $scope.selections.boundItem = item;

            // Default quantity to one is value is invalid
            if (
                typeof $scope.selections.boundItem.quantity !== 'number' ||
                $scope.selections.boundItem.quantity < 1
            ) {
                $scope.selections.boundItem.quantity = 1;
            }

            $scope.resetPhotoCrop();

            enterConfirmStage();
        }

        function selectCanvasThumbnailLegacy(thumbnail) {
            const { exhibition, rendering } = CANVAS_THUMBNAIL_EXHIBITION_RENDERING_MAP[thumbnail];

            $scope.selectedCanvasThumbnail = thumbnail;
            $scope.selectedCanvasExhibition = exhibition;
            $scope.selectedPerspectiveRendering = rendering;

            $scope.selections.productImageIndex = null;
        }

        function selectCanvasThumbnail({ exhibition, rendering }, thumbnail) {
            $timeout(() => {
                $scope.selectedCanvasThumbnail = thumbnail;
                $scope.selectedCanvasExhibition = exhibition;
                $scope.selectedPerspectiveRendering = rendering;

                $scope.selections.productImageIndex = null;
            });
        }

        function selectPackage(selectedPackage, selectedPhoto) {
            if (
                selectedPackage.items.length === 1 &&
                spPackage.isShootProofFulfilledAllGalleryDownloadsDigitalItem(
                    selectedPackage.items[0]
                )
            ) {
                currentlySelectedPackage = selectedPackage;
                currentlySelectedPhoto = selectedPhoto;

                addPackageToCart();
            } else {
                spPackages.setSelectedPackage(selectedPackage);
                spPackages.setSmallScreenState(PACKAGE_SMALL_SCREEN_STATES.PACKAGE_VIEW);
                spPackages.togglePackagePhoto(selectedPhoto, $scope.album);
                SPModal.close();
            }
        }

        function selectProductThumbnail($index) {
            $scope.selections.productImageIndex = $index;

            $scope.selectedCanvasThumbnail = null;
            $scope.selectedCanvasExhibition = null;
            $scope.selectedPerspectiveRendering = null;
        }

        function showCanvasPreview() {
            return $scope.isCroppableCanvas() && !$scope.isProductImageSelected();
        }

        function showCroppedImagePreview() {
            return (
                $scope.isProductImageSelected() &&
                !$scope.isPackageSelected() &&
                $scope.singlePhoto &&
                $scope.selections.productImageIndex === -1 &&
                $scope.selections.crop &&
                $scope.eventData.settings.allowCropping
            );
        }

        function isItemEditable() {
            const { eventData, selections, selectedPerspectiveRendering, singlePhoto } = $scope;
            const { crop, productImageIndex } = selections;

            return (
                !$scope.isPackageSelected() &&
                singlePhoto &&
                (productImageIndex === -1 || selectedPerspectiveRendering !== null) &&
                crop &&
                eventData.settings.allowCropping
            );
        }

        function showEditProductButton() {
            return isItemEditable();
        }

        function showPreviewComponent() {
            return isItemEditable();
        }

        function viewPackage(selectedPackage) {
            $scope.selections.bound = {};
            $scope.selections.boundItem = {
                ...selectedPackage,
                descriptionImages: selectedPackage.images.map(({ displayUrl }) => ({
                    large: displayUrl.large,
                    thumbnail: displayUrl.thumb
                })),
                descriptionText: selectedPackage.description
            };
            $scope.selections.group = { name: 'packages' };

            enterConfirmStage();
        }

        function copyDeepLink() {
            const selfUrl = generatePath(getPathContext());

            navigator.clipboard.writeText(selfUrl);

            $scope.$emit(COMMON_MODALS.TOAST.OPEN_TOAST, {
                subText: translateFilter('copiedLink')
            });

            setEditorBlock(false);
        }

        function generatePath({ boundItemName, boundsName, groupId, photoId, context, contextId }) {
            const url = new URL($location.absUrl());
            const params = new URLSearchParams(url.search);

            if (groupId) {
                params.set('groupId', groupId);
            }

            if (boundsName) {
                params.set('boundsName', boundsName);
            }

            if (boundItemName) {
                params.set('boundItemName', boundItemName);
            }

            if (photoId) {
                params.set('photoId', photoId);
            }

            let pathname = url.pathname;

            // compare paths are incomplete, won't load, so rebuild the original url
            if (pathname.endsWith('/compare')) {
                pathname = pathname.replace('compare', context);

                if (context === 'search') {
                    params.set('q', contextId);
                } else if (contextId) {
                    pathname += `/${contextId}`;
                }

                pathname += `/photo/${photoId}`;
            }

            return `${url.origin}${pathname}?${params}`;
        }
    }
];
