import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { createPresenter } from '@shootproof/rendering';
import { RenderSpecification } from '@shootproof/rendering/lib/Presentation/product/RenderSpecification';
import { useFrameFill } from '../product/hooks';
import { FrameProps } from '../product/types';
import { createCache } from './AssetCache';
import { PageSpecification, ProjectSpecification } from './Editor.types';
import { calculateLinearGradientCoords } from './helpers';

export interface IPreviewBackground {
    /** The url of the background image, if supplied */
    backgroundUrl?: string;
    /** Boolean determining if the preview should be rendered in the background with a drop shadow effect */
    dropShadow?: boolean;
    /** The amount to rotate the preview in the background, in degrees. */
    rotation?: number;
    /** The scale factor of the product render in the scene capped at 1, if supplied, */
    scaleInBackground?: number;
    /** The amount of the canvas width to shift the origin (center) of the preview left (negative) or right (positive) from -1 to 1. */
    translateX?: number;
    /** The amount of the canvas width to shift the origin (center) of the preview up (negative) or down (positive) from -1 to 1. */
    translateY?: number;
}

export interface IPreviewEffects {
    /** Boolean for if the product imagery should be rendered in black and white. */
    blackAndWhite?: boolean;
    /** Boolean for if the product imagery should be rendered with a glossy effect overlay. */
    glossy?: boolean;
    /** Boolean for if the product imagery should be rendered with a lustre effect overlay. */
    lustre?: boolean;
    /** Boolean for if the product imagery should be rendered with a matte filter. */
    matte?: boolean;
    /** Boolean for if the product imagery should be rendered with a transparent depth effect overlay. */
    transparent?: boolean;
}

export interface IPreviewProps {
    /** Optional object describing the properties of the frame for rendering. */
    frame?: FrameProps;
    /** Optional object describing the properties of the background for rendering. */
    previewBackground?: IPreviewBackground;
    /** Optional object defining what effect(s) to apply to the Preview render */
    previewEffects?: IPreviewEffects;
    /** The width of the canvas in pixels. */
    width: number;
    /** The height of the canvas in pixels. */
    height: number;
    /** Project specification. */
    project: ProjectSpecification;
    /** Rendering product specification. */
    pageSpecification: PageSpecification;
}

const Preview: React.FC<IPreviewProps> = ({
    frame,
    previewBackground,
    previewEffects,
    width,
    height,
    project,
    pageSpecification
}) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [assetCache] = useState(createCache());
    const [presenter] = useState(() => createPresenter(assetCache));
    const scale = window.devicePixelRatio;
    const renderSpecification = useMemo(
        () => ({
            x: 0,
            y: 0,
            width: project.width,
            height: project.height,
            scale: 1,
            contents: [
                {
                    id: '0',
                    type: 'image' as const,
                    x: 0,
                    y: 0,
                    width: project.width,
                    height: project.height,
                    image: {
                        url: project.image.url,
                        width: project.image.width,
                        height: project.image.height,
                        rotation: project.rotation,
                        clippingMask: {
                            x: project.image.clippingMask.left * project.image.width,
                            y: project.image.clippingMask.top * project.image.height,
                            width:
                                (1 -
                                    project.image.clippingMask.left -
                                    project.image.clippingMask.right) *
                                project.image.width,
                            height:
                                (1 -
                                    project.image.clippingMask.top -
                                    project.image.clippingMask.bottom) *
                                project.image.height
                        }
                    }
                }
            ],
            product: pageSpecification.product,
            productMasks: {
                rotation: 4 - (project.rotation % 4),
                ...pageSpecification.productMasks
            }
        }),
        [project, pageSpecification]
    );

    const {
        frameColor,
        frameImageUrl,
        frameOverlap,
        frameWidth,
        matColor,
        matHeight,
        matOverlap,
        matWidth
    } = frame || {};

    const frameFill = useFrameFill(frameColor, frameImageUrl);

    useLayoutEffect(() => {
        const canvas = canvasRef.current;

        if (!canvas) {
            return;
        }

        const context = canvas.getContext('2d');

        if (!context) {
            return;
        }

        let animationFrameHandle = 0;

        const render = () => {
            context.save();

            context.clearRect(0, 0, canvas.width, canvas.height);

            context.translate(0.5 * canvas.width, 0.5 * canvas.height);

            if (previewBackground) {
                const {
                    rotation = 0,
                    scaleInBackground = 1,
                    translateX = 0,
                    translateY = 0
                } = previewBackground;

                context.translate(
                    translateX * (canvas.width / 2),
                    translateY * (canvas.height / 2)
                );
                context.rotate((rotation * Math.PI) / 180);
                context.scale(scaleInBackground, scaleInBackground);
            }

            const productWidth =
                renderSpecification.productMasks.rotation % 2 === 0
                    ? renderSpecification.product.width
                    : renderSpecification.product.height;
            const productHeight =
                renderSpecification.productMasks.rotation % 2 === 0
                    ? renderSpecification.product.height
                    : renderSpecification.product.width;

            const isMatted = typeof matWidth === 'number' && !isNaN(matWidth) && matWidth > 0;
            const isFramed = typeof frameWidth === 'number' && !isNaN(frameWidth) && frameWidth > 0;

            let framedProductWidth = productWidth;
            let framedProductHeight = productHeight;

            if (isMatted && isFramed) {
                framedProductWidth = productWidth + (frameWidth + matWidth - (matOverlap || 0)) * 2;
                framedProductHeight =
                    productHeight + (frameWidth + (matHeight ?? matWidth) - (matOverlap || 0)) * 2;
            } else if (isFramed) {
                framedProductWidth = productWidth + ((frameWidth || 0) - (frameOverlap || 0)) * 2;
                framedProductHeight = productHeight + ((frameWidth || 0) - (frameOverlap || 0)) * 2;
            }

            /**
             * Adding extra space to this computation so that there's room for shadows and other possible effects
             */
            const scale = Math.min(
                canvas.width / (framedProductWidth * (isFramed || isMatted ? 1.15 : 1)),
                canvas.height / (framedProductHeight * (isFramed || isMatted ? 1.15 : 1))
            );

            context.scale(scale, scale);

            if (renderSpecification.productMasks.rotation) {
                context.rotate((-renderSpecification.productMasks.rotation * Math.PI) / 2);
            }

            context.translate(
                -0.5 * renderSpecification.product.width - renderSpecification.product.x,
                -0.5 * renderSpecification.product.height - renderSpecification.product.y
            );

            if (previewEffects?.blackAndWhite || previewEffects?.matte) {
                context.filter = `${previewEffects.blackAndWhite ? 'grayscale(1)' : ''} ${
                    previewEffects.matte ? 'brightness(1.05) contrast(0.85)' : ''
                }`;
            }

            const { loaded } = presenter.renderProductSync(canvas, {
                ...renderSpecification,
                scale
            });

            if (frameWidth && frameFill) {
                drawFrame(
                    context,
                    frameFill,
                    frameOverlap || 0,
                    frameWidth,
                    renderSpecification,
                    scale,
                    matHeight || 0,
                    matOverlap || 0,
                    matWidth || 0,
                    matColor
                );
            }

            if (previewBackground?.dropShadow) {
                context.save();

                context.globalCompositeOperation = 'destination-over';

                const maskImage = renderSpecification.productMasks.clipImageUrl
                    ? assetCache.getImage(renderSpecification.productMasks.clipImageUrl)
                    : null;

                drawDropShadow(
                    context,
                    renderSpecification.product.width,
                    renderSpecification.product.height,
                    {
                        shadowOffsetX: 0,
                        shadowOffsetY: 3,
                        shadowBlur: 20,
                        shadowColor: 'rgba(0, 0, 0, 0.20)'
                    },
                    maskImage
                );

                drawDropShadow(
                    context,
                    renderSpecification.product.width,
                    renderSpecification.product.height,
                    {
                        shadowOffsetX: 0,
                        shadowOffsetY: 4,
                        shadowBlur: 4,
                        shadowColor: 'rgba(0, 0, 0, 0.36)'
                    },
                    maskImage
                );

                context.restore();
            }

            if (previewEffects?.transparent) {
                context.save();

                const insetBorderDepthX = renderSpecification.product.height / 30;
                const insetBorderDepthY = renderSpecification.product.width / 30;

                // Top
                context.fillStyle = 'rgba(0, 0, 0, 0.20)';
                context.beginPath();
                context.moveTo(0, 0);
                context.lineTo(renderSpecification.product.width, 0);
                context.lineTo(
                    renderSpecification.product.width - insetBorderDepthY,
                    insetBorderDepthX
                );
                context.lineTo(insetBorderDepthY, insetBorderDepthX);
                context.closePath();
                context.fill();

                // Bottom
                context.beginPath();
                context.moveTo(0, renderSpecification.product.height);
                context.lineTo(
                    renderSpecification.product.width,
                    renderSpecification.product.height
                );
                context.lineTo(
                    renderSpecification.product.width - insetBorderDepthY,
                    renderSpecification.product.height - insetBorderDepthX
                );
                context.lineTo(
                    insetBorderDepthY,
                    renderSpecification.product.height - insetBorderDepthX
                );
                context.closePath();
                context.fill();

                // Right
                context.fillStyle = 'rgba(255, 255, 255, 0.50)';
                context.beginPath();
                context.moveTo(renderSpecification.product.width, 0);
                context.lineTo(
                    renderSpecification.product.width - insetBorderDepthY,
                    insetBorderDepthX
                );
                context.lineTo(
                    renderSpecification.product.width - insetBorderDepthY,
                    renderSpecification.product.height - insetBorderDepthX
                );
                context.lineTo(
                    renderSpecification.product.width,
                    renderSpecification.product.height
                );
                context.closePath();
                context.fill();

                // Left
                context.beginPath();
                context.moveTo(0, 0);
                context.lineTo(insetBorderDepthY, insetBorderDepthX);
                context.lineTo(
                    insetBorderDepthY,
                    renderSpecification.product.height - insetBorderDepthX
                );
                context.lineTo(0, renderSpecification.product.height);
                context.closePath();
                context.fill();

                context.restore();
            }

            if (previewEffects?.glossy || previewEffects?.lustre) {
                context.save();

                const { startX, startY, endX, endY } = calculateLinearGradientCoords(
                    renderSpecification.product.width,
                    renderSpecification.product.height,
                    renderSpecification.productMasks.rotation
                );

                context.globalCompositeOperation = 'source-atop';

                if (previewEffects.glossy) {
                    const gradient = context.createLinearGradient(startX, startY, endX, endY);

                    gradient.addColorStop(0.5118, 'rgba(255, 255, 255, 0)');
                    gradient.addColorStop(0.6521, 'rgba(255, 255, 255, 0.4)');
                    gradient.addColorStop(0.7853, 'rgba(255, 255, 255, 0)');

                    context.fillStyle = gradient;
                    context.fillRect(
                        0,
                        0,
                        renderSpecification.product.width,
                        renderSpecification.product.height
                    );
                }

                if (previewEffects.lustre) {
                    const gradient = context.createLinearGradient(startX, startY, endX, endY);

                    gradient.addColorStop(0.313, 'rgba(255, 255, 255, 0)');
                    gradient.addColorStop(0.6521, 'rgba(255, 255, 255, 0.3)');
                    gradient.addColorStop(0.986, 'rgba(255, 255, 255, 0)');

                    context.fillStyle = gradient;
                    context.fillRect(
                        0,
                        0,
                        renderSpecification.product.width,
                        renderSpecification.product.height
                    );
                }

                context.restore();
            }

            context.restore();

            if (!loaded) {
                animationFrameHandle = requestAnimationFrame(render);
            }
        };

        render();

        return () => {
            cancelAnimationFrame(animationFrameHandle);
        };
    }, [
        width,
        height,
        assetCache,
        canvasRef,
        renderSpecification,
        presenter,
        previewBackground,
        previewEffects,
        pageSpecification.width,
        frameFill,
        frameOverlap,
        frameWidth,
        matHeight,
        matOverlap,
        matWidth,
        matColor
    ]);

    return (
        <canvas
            ref={canvasRef}
            width={width * scale}
            height={height * scale}
            style={{
                ...(previewBackground?.backgroundUrl && {
                    backgroundImage: `url("${previewBackground.backgroundUrl}")`,
                    backgroundSize: '100%'
                }),
                width: `${width}px`,
                height: `${height}px`
            }}
        />
    );
};

const drawDropShadow = (
    context: CanvasRenderingContext2D,
    width: number,
    height: number,
    canvasDropShadow: {
        shadowOffsetX: number;
        shadowOffsetY: number;
        shadowBlur: number;
        shadowColor: string;
    },
    maskImage: Nullable<HTMLImageElement>
) => {
    context.save();

    const { shadowOffsetX, shadowOffsetY, shadowBlur, shadowColor } = canvasDropShadow;

    context.shadowOffsetX = shadowOffsetX;
    context.shadowOffsetY = shadowOffsetY;
    context.shadowBlur = shadowBlur;
    context.shadowColor = shadowColor;

    if (maskImage) {
        // Must handle rotating in landscape; as our mask images are always in portrait.
        if (width > height) {
            // move to where the center of the image should be
            context.translate(width / 2, height / 2);

            // rotate 90 degrees
            context.rotate(Math.PI / 2);

            // move back
            context.translate(-height / 2, -width / 2);
            context.drawImage(maskImage, 0, 0, height, width);
        } else {
            context.drawImage(maskImage, 0, 0, width, height);
        }
    } else {
        context.fillRect(0, 0, width, height);
    }

    context.restore();
};

const fillFrame = (
    context: CanvasRenderingContext2D,
    srcX: number,
    srcY: number,
    srcWidth: number,
    srcHeight: number,
    destX: number,
    destY: number,
    destWidth: number,
    destHeight: number,
    frameFill: HTMLImageElement | string | null
) => {
    if (typeof frameFill === 'string' || frameFill instanceof String) {
        context.fillStyle = frameFill as string;
        context.fillRect(destX, destY, destWidth, destHeight);
    } else if (frameFill instanceof HTMLImageElement) {
        context.drawImage(
            frameFill,
            srcX,
            srcY,
            srcWidth,
            srcHeight,
            destX,
            destY,
            destWidth,
            destHeight
        );
    }
};

export const drawFrame = (
    context: CanvasRenderingContext2D,
    frameFill: HTMLImageElement | string | null,
    frameOverlap: number,
    frameWidth: number,
    renderSpecification: RenderSpecification,
    scale: number,
    matHeight = 0,
    matOverlap = 0,
    matWidth = 0,
    matColor?: Nullable<string>
) => {
    context.save();

    const isMatted = !isNaN(matWidth) && matWidth > 0 && !!matColor;

    // How much the frame and mat add to the overall product size
    const frameAddedWidth = frameWidth - frameOverlap;
    const matAddedWidth = matWidth - matOverlap;
    const matAddedHeight = matHeight - matOverlap;

    // Total product dimensions with frame and mat
    const framedPrintWidth = isMatted
        ? renderSpecification.product.width + (frameWidth + matAddedWidth) * 2
        : renderSpecification.product.width + frameAddedWidth * 2;
    const framedPrintHeight = isMatted
        ? renderSpecification.product.height + (frameWidth + matAddedHeight) * 2
        : renderSpecification.product.height + frameAddedWidth * 2;

    // The longest side of the frame
    const MAX_FRAME_LENGTH = Math.max(framedPrintWidth, framedPrintHeight);

    // The size of the actual image
    const textureSrcWidth = frameFill instanceof HTMLImageElement ? frameFill?.naturalWidth : 1;
    const textureSrcHeight = frameFill instanceof HTMLImageElement ? frameFill?.naturalHeight : 1;
    const textureAspectRatio = textureSrcWidth / textureSrcHeight;

    // The "real world" measurements of the image that should be rendered
    const textureDstWidth = Math.max(MAX_FRAME_LENGTH, frameWidth * textureAspectRatio);
    const textureDstHeight = Math.max(frameWidth, MAX_FRAME_LENGTH / textureAspectRatio);

    // Image initial bounds, before frame overlap
    const imageLeftBound = 0;
    const imageRightBound = imageLeftBound + renderSpecification.product.width;
    const imageTopBound = 0;
    const imageBottomBound = imageTopBound + renderSpecification.product.height;

    let outerTopLeftPoint: readonly [number, number];
    let outerTopRightPoint: readonly [number, number];
    let outerBottomLeftPoint: readonly [number, number];
    let outerBottomRightPoint: readonly [number, number];

    let innerTopLeftPoint: readonly [number, number];
    let innerTopRightPoint: readonly [number, number];
    let innerBottomLeftPoint: readonly [number, number];
    let innerBottomRightPoint: readonly [number, number];

    // draw mat
    if (isMatted) {
        // Frame inner and outer points
        outerTopLeftPoint = [
            imageLeftBound - frameWidth - matAddedWidth,
            imageTopBound - frameWidth - matAddedHeight
        ] as const;
        outerTopRightPoint = [
            imageRightBound + frameWidth + matAddedWidth,
            imageTopBound - frameWidth - matAddedHeight
        ] as const;
        outerBottomLeftPoint = [
            imageLeftBound - frameWidth - matAddedWidth,
            imageBottomBound + frameWidth + matAddedHeight
        ] as const;
        outerBottomRightPoint = [
            imageRightBound + frameWidth + matAddedWidth,
            imageBottomBound + frameWidth + matAddedHeight
        ] as const;

        innerTopLeftPoint = [
            imageLeftBound - matAddedWidth,
            imageTopBound - matAddedHeight
        ] as const;
        innerTopRightPoint = [
            imageRightBound + matAddedWidth,
            imageTopBound - matAddedHeight
        ] as const;
        innerBottomLeftPoint = [
            imageLeftBound - matAddedWidth,
            imageBottomBound + matAddedHeight
        ] as const;
        innerBottomRightPoint = [
            imageRightBound + matAddedWidth,
            imageBottomBound + matAddedHeight
        ] as const;

        // drawing mat
        context.save();
        context.globalCompositeOperation = 'destination-over';
        context.fillStyle = matColor;
        context.fillRect(
            ...innerTopLeftPoint,
            renderSpecification.product.width + 2 * matAddedWidth,
            renderSpecification.product.height + 2 * matAddedHeight
        );
        context.restore();
    } else {
        // Frame inner and outer points
        outerTopLeftPoint = [
            imageLeftBound - frameAddedWidth,
            imageTopBound - frameAddedWidth
        ] as const;
        outerTopRightPoint = [
            imageRightBound + frameAddedWidth,
            imageTopBound - frameAddedWidth
        ] as const;
        outerBottomLeftPoint = [
            imageLeftBound - frameAddedWidth,
            imageBottomBound + frameAddedWidth
        ] as const;
        outerBottomRightPoint = [
            imageRightBound + frameAddedWidth,
            imageBottomBound + frameAddedWidth
        ] as const;

        innerTopLeftPoint = [imageLeftBound + frameOverlap, imageTopBound + frameOverlap] as const;
        innerTopRightPoint = [
            imageRightBound - frameOverlap,
            imageTopBound + frameOverlap
        ] as const;
        innerBottomLeftPoint = [
            imageLeftBound + frameOverlap,
            imageBottomBound - frameOverlap
        ] as const;
        innerBottomRightPoint = [
            imageRightBound - frameOverlap,
            imageBottomBound - frameOverlap
        ] as const;
    }

    // draw top frame
    context.beginPath();
    context.moveTo(...outerTopLeftPoint);
    context.lineTo(...outerTopRightPoint);
    context.lineTo(...innerTopRightPoint);
    context.lineTo(...innerTopLeftPoint);
    context.closePath();

    context.clip();
    fillFrame(
        context,
        0,
        0,
        textureSrcWidth,
        textureSrcHeight,
        ...outerTopLeftPoint,
        textureDstWidth,
        textureDstHeight,
        frameFill
    );

    context.restore();
    context.save();

    // draw bottom frame
    context.beginPath();
    context.moveTo(...outerBottomLeftPoint);
    context.lineTo(...outerBottomRightPoint);
    context.lineTo(...innerBottomRightPoint);
    context.lineTo(...innerBottomLeftPoint);
    context.closePath();

    context.clip();
    fillFrame(
        context,
        0,
        0,
        textureSrcWidth,
        textureSrcHeight,
        outerBottomLeftPoint[0],
        innerBottomLeftPoint[1],
        textureDstWidth,
        textureDstHeight,
        frameFill
    );

    context.restore();
    context.save();

    // draw left frame
    context.beginPath();
    context.moveTo(...outerTopLeftPoint);
    context.lineTo(...innerTopLeftPoint);
    context.lineTo(...innerBottomLeftPoint);
    context.lineTo(...outerBottomLeftPoint);
    context.closePath();

    context.clip();

    context.translate(...outerBottomLeftPoint);
    context.rotate((-90 * Math.PI) / 180);

    fillFrame(
        context,
        0,
        0,
        textureSrcWidth,
        textureSrcHeight,
        0,
        0,
        textureDstWidth,
        textureDstHeight,
        frameFill
    );

    context.restore();
    context.save();

    // draw right frame
    context.beginPath();
    context.moveTo(...innerTopRightPoint);
    context.lineTo(...outerTopRightPoint);
    context.lineTo(...outerBottomRightPoint);
    context.lineTo(...innerBottomRightPoint);
    context.closePath();

    context.clip();

    context.translate(...outerTopRightPoint);
    context.rotate((90 * Math.PI) / 180);

    fillFrame(
        context,
        0,
        0,
        textureSrcWidth,
        textureSrcHeight,
        0,
        0,
        textureDstWidth,
        textureDstHeight,
        frameFill
    );

    context.restore();

    // frame shadows and highlights

    const shadowsColor = '0, 0, 0';
    const bottomShadowsTransparency = '0.36';
    const secondBottomShadowsTransparency = '0.2';
    const innerShadowsTransparency = '0.3';
    const topHighlightColor = '255, 255, 255';
    const topHighlightTransparency = '0.25';

    // top highlight
    context.save();
    context.translate(...outerTopLeftPoint);
    context.fillStyle = `rgba(${topHighlightColor}, ${topHighlightTransparency})`;
    drawDropShadow(
        context,
        framedPrintWidth,
        1 / scale,
        {
            shadowBlur: 0.05 * frameWidth * scale,
            shadowColor: `rgba(${topHighlightColor}, 1)`,
            shadowOffsetX: 0,
            shadowOffsetY: 0.05 * frameWidth * scale
        },
        null
    );
    context.restore();

    // bottom shadows
    context.save();
    context.translate(...outerTopLeftPoint);
    context.globalCompositeOperation = 'destination-over';
    context.fillStyle = `rgba(${shadowsColor}, ${bottomShadowsTransparency})`;
    drawDropShadow(
        context,
        framedPrintWidth,
        framedPrintHeight,
        {
            shadowBlur: 0.05 * frameWidth * scale,
            shadowColor: `rgba(${shadowsColor}, 1)`,
            shadowOffsetX: 0,
            shadowOffsetY: 0.05 * frameWidth * scale
        },
        null
    );
    context.restore();

    context.save();
    context.translate(...outerTopLeftPoint);
    context.globalCompositeOperation = 'destination-over';
    context.fillStyle = `rgba(${shadowsColor}, ${secondBottomShadowsTransparency})`;
    drawDropShadow(
        context,
        framedPrintWidth,
        framedPrintHeight,
        {
            shadowBlur: 0.25 * frameWidth * scale,
            shadowColor: `rgba(${shadowsColor}, 1)`,
            shadowOffsetX: 0,
            shadowOffsetY: 0.035 * frameWidth * scale
        },
        null
    );
    context.restore();

    // top inner shadow
    context.save();
    context.translate(...innerTopLeftPoint);
    context.fillStyle = `rgba(${shadowsColor}, ${innerShadowsTransparency})`;
    const shadowWidth = isMatted
        ? renderSpecification.product.width + 2 * matAddedWidth
        : renderSpecification.product.width - 2 * frameOverlap;
    drawDropShadow(
        context,
        shadowWidth,
        1 / scale,
        {
            shadowBlur: 0.04 * frameWidth * scale,
            shadowColor: `rgba(${shadowsColor}, 1)`,
            shadowOffsetX: 0,
            shadowOffsetY: 0.04 * frameWidth * scale
        },
        null
    );
    context.restore();

    const shadowHeight = isMatted
        ? renderSpecification.product.height + 2 * matAddedHeight
        : renderSpecification.product.height - 2 * frameOverlap;

    // left inner shadow
    context.save();
    context.translate(...innerTopLeftPoint);
    context.fillStyle = `rgba(${shadowsColor}, ${innerShadowsTransparency})`;
    drawDropShadow(
        context,
        1 / scale,
        shadowHeight,
        {
            shadowBlur: 0.04 * frameWidth * scale,
            shadowColor: `rgba(${shadowsColor}, 1)`,
            shadowOffsetX: 0.04 * frameWidth * scale,
            shadowOffsetY: 0
        },
        null
    );
    context.restore();

    // right inner shadow
    context.save();
    context.translate(...innerTopRightPoint);
    context.fillStyle = `rgba(${shadowsColor}, ${innerShadowsTransparency})`;
    drawDropShadow(
        context,
        1 / scale,
        shadowHeight,
        {
            shadowBlur: 0.04 * frameWidth * scale,
            shadowColor: `rgba(${shadowsColor}, 1)`,
            shadowOffsetX: -0.04 * frameWidth * scale,
            shadowOffsetY: 0
        },
        null
    );
    context.restore();
};

Preview.displayName = 'Preview';

export default Preview;
