import React from 'react';
import { Flex, useBreakpointValue, useTheme } from '@chakra-ui/react';
import Button from 'ts/common/components/gallery/Button';

type ViewPhotoOverlayProps = {
    /** Callback for when the View Photo button is clicked */
    onClick: React.MouseEventHandler;
    /** The Angular translation service */
    translate: SpAngularJs.ITranslateFilter;
};

const ViewPhotoOverlay = ({ onClick, translate }: ViewPhotoOverlayProps) => {
    const theme = useTheme();

    const opacity = useBreakpointValue({ lg: 0 }) ?? 1;
    const backgroundColor = theme.isLightColorScheme ? 'whiteAlpha.700' : 'blackAlpha.700';

    return (
        <Flex
            justifyContent="center"
            opacity={opacity}
            transition="0.25s opacity"
            _groupHover={useBreakpointValue({ lg: { opacity: 1 } })}
        >
            <Button
                backgroundColor={backgroundColor}
                border="none"
                color="text"
                onClick={onClick}
                size="sm"
                text={translate('viewPhoto')}
                _active={{
                    backgroundColor,
                    color: 'brand.primary'
                }}
                _focus={{
                    boxShadow: 'none',
                    color: 'text'
                }}
                _focusVisible={{
                    boxShadow: 'outline',
                    color: 'brand.primary'
                }}
                _hover={{
                    color: 'brand.primary',
                    backgroundColor
                }}
            />
        </Flex>
    );
};

export default ViewPhotoOverlay;
