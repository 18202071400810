/**
 * @ngdoc filter
 * @name sp.studio.priceSheet.filter:spPriceSheetItemSubgroup
 *
 * @description
 * Filters to the Price Sheet Subgroup for the given Price Sheet Item.
 *
 * @param {object} item A Price Sheet Item
 * @param {object} item.priceSheetSubgroupId A Price Sheet Subgroup id
 * @param {object} priceSheet A Price Sheet containing the Price Sheet Item
 *
 * @returns {object|null} The Price Sheet Item's Price Sheet Subgroup
 */
export default [
    function spPriceSheetItemSubgroupFilter() {
        return function filter({ priceSheetSubgroupId }, priceSheet) {
            if (!priceSheetSubgroupId) {
                return null;
            }

            return priceSheet.priceSheetGroups
                .reduce(
                    (subgroups, { priceSheetSubgroups }) => subgroups.concat(priceSheetSubgroups),
                    []
                )
                .find(({ id }) => id === priceSheetSubgroupId);
        };
    }
];
