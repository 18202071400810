import React, { FC } from 'react';
import { Flex, Image, Text, useBreakpointValue } from '@chakra-ui/react';
import { IBoundItem } from 'ts/client/gallery/components/types';
import { GalleryHeading } from 'ts/common/components/gallery';
import { useTranslate } from 'ts/common/hooks';
import { useStore } from 'client_react/gallery/hooks/StoreContext';

const CardTemplatePreviewSlide: FC = () => {
    const { selectedCardProduct, selectedCardCategory } = useStore();
    const categoryName = selectedCardCategory?.bounds_name;

    const t = useTranslate('client.store.cardTemplatePreviewSlide');
    const isMediumScreenOrLarger = useBreakpointValue({ base: false, md: true });

    if (!selectedCardProduct || !categoryName) {
        return null;
    }

    return (
        <>
            <Flex justifyContent="center" marginTop="35px">
                <Flex gap="24px" direction="column" alignItems="center">
                    <GalleryHeading
                        data-testid="card-template-preview-heading"
                        size="sm"
                        text={getCardTemplateName(selectedCardProduct, categoryName)}
                        variant="primary"
                        paddingBottom="24px"
                    />
                    <Flex gap="23px" flexDirection={isMediumScreenOrLarger ? 'row' : 'column'}>
                        <Flex flexDirection="column" alignItems="center" gap="20px">
                            <Image
                                data-testid="card-template-preview-image-front"
                                borderStyle="none"
                                boxShadow="3px 3px 7px 4px rgba(0, 0, 0, 0.1)"
                                height="auto"
                                maxWidth="635px"
                                width="100%"
                                src={selectedCardProduct.description_images[0].l}
                            />
                            <Text data-testid="card-template-front-text">
                                {t('cardTemplateFront')}
                            </Text>
                        </Flex>
                        <Flex flexDirection="column" alignItems="center" gap="20px">
                            <Image
                                data-testid="card-template-preview-image-back"
                                borderStyle="none"
                                boxShadow="3px 3px 7px 4px rgba(0, 0, 0, 0.1)"
                                height="auto"
                                maxWidth="635px"
                                width="100%"
                                src={selectedCardProduct.description_images[1].l}
                            />
                            <Text data-testid="card-template-back-text">
                                {t('cardTemplateBack')}
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
};

const getCardTemplateName = (cardProduct: IBoundItem, categoryName: string) => {
    const occasion = categoryName;
    const template = cardProduct.name;

    if (template.includes(occasion)) {
        return `${template} cards`;
    }

    return `${template} - ${occasion} cards`;
};

export default CardTemplatePreviewSlide;
