export const NOTIFICATION_GROUP_TYPES = {
    CONTRACT: 'contract',
    EVENT: 'event',
    INVOICE: 'invoice',
    ORDER: 'order',
    STUDIO: 'studio',
    BOOKING: 'booking'
};

export const NOTIFICATION_TYPES = {
    GRANTED_ARCHIVING_SPACE: 'granted-archiving-space',
    CONTRACT: {
        CANCELLED: 'contract-canceled',
        SIGNED_BY_CLIENT: 'contract-signed-by-client'
    },
    EVENT: {
        DOWNLOAD_EMAIL_UNSENT: 'event-digital-download-email-unsent',
        FAVORITES_FINALIZED: 'event-favorites-list-finalized-by-event-contact',
        PHOTO_DOWNLOADED: 'event-photo-downloaded-by-event-contact',
        PHOTO_FAVORITED: 'event-photo-favorited-by-event-contact',
        PHOTO_HIDDEN: 'event-photo-hidden-by-event-contact',
        PHOTO_TAGGED: 'event-photo-tagged-by-event-contact'
    },
    FAVORITES: 'favorites',
    INVOICE: {
        PAYMENT_RECEIVED: 'invoice-payment-received',
        PAST_DUE: 'invoice-past-due'
    },
    ORDER: {
        APPROVAL_PROLONGED: 'order-approval-prolonged',
        NEEDS_APPROVAL: 'order-needs-approval',
        PLACED: 'order-placed',
        SHIPPED_FROM_LAB: 'order-shipped-from-lab'
    },
    STUDIO: {
        PROFIT_RELEASED: 'studio-profit-released',
        PHOTO_PLAN_NEXT_BILL_DATE_RELEASED: 'studio-photo-plan-next-bill-date-released',
        MONEY_BALANCE_INCREASED: 'studio-money-balance-increased'
    },
    BOOKING: {
        SESSION_CONFIRMED: 'booking-session-confirmed',
        SESSION_NEEDS_CONFIRMATION: 'booking-session-needs-confirmation'
    }
};

export default {
    NOTIFICATION_GROUP_TYPES,
    NOTIFICATION_TYPES
};
