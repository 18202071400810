import { DIGITAL_RULE_EVENTS } from 'Sp/Angular/Events/Client/Gallery';
import { SP_API_HTTP_EVENTS } from 'Sp/Angular/Events/Common';

export default [
    '$scope',
    'spAuthorizationToken',
    'spFreeDigitals',
    'SPModal',
    function CaptureCredentialsController($scope, spAuthorizationToken, spFreeDigitals, SPModal) {
        $scope.credentials = {
            email: spFreeDigitals.getEmail() || null,
            pin: null,
            doesAcceptTerms: false
        };
        $scope.shouldCaptureEmail = !$scope.credentials.email;

        $scope.captureCredentials = captureCredentials;

        $scope.capturePinCredentials = capturePinCredentials;

        $scope.closeModal = closeModal;

        $scope.$on(DIGITAL_RULE_EVENTS.LIST_ERROR, updateFormWithErrors);
        $scope.$on(SP_API_HTTP_EVENTS.TOO_MANY_REQUESTS, unsubmitFormAndShowRateLimitError);

        function captureCredentials(email, doesAcceptTerms) {
            $scope.credentials.email = email;
            $scope.credentials.doesAcceptTerms = doesAcceptTerms;

            spFreeDigitals.setCredentials($scope.credentials);
        }

        function capturePinCredentials(pin) {
            if ($scope.onCaptureCredentials) {
                $scope.onCaptureCredentials();
            }

            if (pin) {
                $scope.credentials.pin = pin;
            }

            spFreeDigitals.setCredentials($scope.credentials);
        }

        function updateFormWithErrors(_, errors) {
            $scope.clientCredentialsForm.$submitted = false;
            $scope.errors = errors;
        }

        function unsubmitFormAndShowRateLimitError() {
            $scope.clientCredentialsForm.$submitted = false;
            $scope.showRateLimitError = true;
        }

        function closeModal() {
            SPModal.close();
        }
    }
];
