import React from 'react';
import { useEvent } from 'ts/client/common';
import AddedToCart from 'client_react/gallery/components/AddedToCart';
import { useStore } from 'client_react/gallery/hooks/StoreContext';
import { PageIndex } from 'client_react/gallery/pages/StorePage';

interface IAddedToCartProps {
    onClose: () => void;
}

const AddedToCartSlide: React.FC<IAddedToCartProps> = ({ onClose }) => {
    const { id: eventId } = useEvent();
    const { userSelectedProducts, returnToPage, spFulfilledDigitalItems } = useStore();

    const onKeepShopping = () => {
        returnToPage(PageIndex.LandingPage);
        onClose();
    };

    return (
        <AddedToCart
            eventId={eventId}
            onKeepShopping={onKeepShopping}
            userSelectedProducts={userSelectedProducts}
            spFulfilledDigitalItems={spFulfilledDigitalItems}
        />
    );
};

export default AddedToCartSlide;
