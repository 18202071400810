import React, { FC } from 'react';
import { Link, LinkProps } from '@chakra-ui/react';

interface Props extends LinkProps {
    /** True only if the button should show a isSelected state */
    isSelected?: boolean;
}

const HeaderNavLink: FC<Props> = ({ children, isSelected, ...rest }) => {
    return (
        <Link
            aria-current={isSelected ? 'page' : undefined}
            alignItems="center"
            borderBottomColor={isSelected ? 'brand.primary' : 'transparent'}
            borderBottomStyle="solid"
            borderBottomWidth="2px"
            color={isSelected ? 'brand.primary' : 'text'}
            display="flex"
            fontFamily="open-sans"
            fontSize="16px"
            fontWeight={isSelected ? 'semibold' : 'normal'}
            height="full"
            lineHeight={1.5}
            marginX="20px"
            padding={0}
            _focus={{
                color: isSelected ? 'brand.primary' : 'text'
            }}
            _focusVisible={{
                boxShadow: 'outline',
                color: 'brand.primary'
            }}
            _hover={{
                borderBottomColor: isSelected ? 'brand.primary' : 'transparent',
                borderBottomStyle: 'solid',
                borderBottomWidth: '2px',
                color: 'brand.primary',
                textDecoration: 'none'
            }}
            _visited={{
                color: isSelected ? 'brand.primary' : 'text',
                '&:focus': {
                    color: isSelected ? 'brand.primary' : 'text'
                },
                '&:hover': {
                    color: 'brand.primary'
                }
            }}
            {...rest}
        >
            {children}
        </Link>
    );
};

export default HeaderNavLink;
