import React, {
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    createContext,
    useContext,
    useState
} from 'react';
import type { IImage, IPhotoConfig } from 'ts/client/gallery/components';
import { IBound, IBoundItem, IProductGroup } from 'ts/client/gallery/components/types';
import type { IPathContext } from 'ts/client/gallery/components/types';
import { noop } from 'ts/common/utils';

interface IStoreContext {
    albumsData: SpApi.Client.IAlbum[];
    authenticatedAlbums: number[];
    brandCurrencyCode: string;
    cardProducts: Nullable<IProductGroup>;
    goToPage: (index: number, name: string) => void;
    imageOnSelectedGroup: Nullable<IImage>;
    isAdminModeEnabled: boolean;
    isLabeledPhotosVisible: boolean;
    isSubjectToGdpr: boolean;
    pathContext: IPathContext;
    pathContextBeforeRouteChange: IPathContext;
    photos: SpApi.Client.IEventPhoto[];
    priceSheet: Nullable<SpApi.Client.IPriceSheet>;
    priceSheetGroups: SpApi.Client.IPriceSheetGroup[];
    returnToPage: (index: number) => void;
    selectedCardCategory: Nullable<IBound>;
    selectedCardProduct: Nullable<IBoundItem>;
    selectedPriceSheetGroup: Nullable<SpApi.Client.IPriceSheetGroup>;
    selectedPriceSheetItem: Nullable<SpApi.Client.IPriceSheetItem>;
    setAuthenticatedAlbums: Dispatch<SetStateAction<number[]>>;
    setGoToPage: React.Dispatch<React.SetStateAction<(index: number, name: string) => void>>;
    setImageOnSelectedGroup: Dispatch<SetStateAction<Nullable<IImage>>>;
    setPhotos: Dispatch<SetStateAction<SpApi.Client.IEventPhoto[]>>;
    setReturnToPage: React.Dispatch<React.SetStateAction<(index: number) => void>>;
    setSelectedCardCategory: Dispatch<SetStateAction<Nullable<IBound>>>;
    setSelectedCardProduct: Dispatch<SetStateAction<Nullable<IBoundItem>>>;
    setSelectedPriceSheetGroup: Dispatch<SetStateAction<Nullable<SpApi.Client.IPriceSheetGroup>>>;
    setSelectedPriceSheetItem: Dispatch<SetStateAction<Nullable<SpApi.Client.IPriceSheetItem>>>;
    // TODO: Consider if the Select photos modal should exist with its own context or not.
    setUserSelectedPhotos: Dispatch<SetStateAction<IImage[]>>;
    setUserSelectedProducts: Dispatch<SetStateAction<IPhotoConfig[]>>;
    setPathContext: Dispatch<SetStateAction<IPathContext>>;
    spFulfilledDigitalItems: IBoundItem[];
    tags: SpApi.Client.ITag[];
    userSelectedPhotos: IImage[];
    userSelectedProducts: IPhotoConfig[];
    updateUserCartState: (numCartItems: number) => void;
}

const StoreContext = createContext<IStoreContext>({
    albumsData: [],
    authenticatedAlbums: [],
    brandCurrencyCode: '',
    cardProducts: null,
    goToPage: noop,
    imageOnSelectedGroup: null,
    isAdminModeEnabled: false,
    isLabeledPhotosVisible: false,
    isSubjectToGdpr: false,
    pathContext: { context: '', contextId: '' },
    pathContextBeforeRouteChange: { context: '', contextId: '' },
    photos: [],
    priceSheet: null,
    priceSheetGroups: [],
    returnToPage: noop,
    selectedCardCategory: null,
    selectedCardProduct: null,
    selectedPriceSheetGroup: null,
    selectedPriceSheetItem: null,
    setAuthenticatedAlbums: () => noop,
    setPhotos: () => noop,
    setGoToPage: () => noop,
    setImageOnSelectedGroup: noop,
    setReturnToPage: () => noop,
    setSelectedCardCategory: () => noop,
    setSelectedCardProduct: () => noop,
    setSelectedPriceSheetGroup: () => noop,
    setSelectedPriceSheetItem: () => noop,
    setUserSelectedPhotos: () => noop,
    setUserSelectedProducts: () => noop,
    setPathContext: () => noop,
    spFulfilledDigitalItems: [],
    tags: [],
    userSelectedPhotos: [],
    userSelectedProducts: [],
    updateUserCartState: () => noop
});

type IStoreProviderProps = PropsWithChildren<
    Pick<
        IStoreContext,
        | 'albumsData'
        | 'brandCurrencyCode'
        | 'isAdminModeEnabled'
        | 'isLabeledPhotosVisible'
        | 'isSubjectToGdpr'
        | 'cardProducts'
        | 'priceSheet'
        | 'priceSheetGroups'
        | 'spFulfilledDigitalItems'
        | 'tags'
        | 'updateUserCartState'
    > & {
        pathContextBeforeRouteChange: IPathContext;
        initialAuthenticatedAlbums: number[];
        initialPhotos: SpApi.Client.IEventPhoto[];
    }
>;

export const StoreProvider: React.FC<IStoreProviderProps> = ({
    albumsData,
    brandCurrencyCode,
    cardProducts,
    children,
    initialAuthenticatedAlbums,
    isAdminModeEnabled,
    isLabeledPhotosVisible,
    isSubjectToGdpr,
    pathContextBeforeRouteChange,
    initialPhotos,
    priceSheet,
    priceSheetGroups,
    spFulfilledDigitalItems,
    tags,
    updateUserCartState
}) => {
    const [authenticatedAlbums, setAuthenticatedAlbums] = useState<number[]>(
        initialAuthenticatedAlbums
    );
    const [goToPage, setGoToPage] = useState<(index: number, name: string) => void>(() => {
        return noop;
    });
    const [imageOnSelectedGroup, setImageOnSelectedGroup] = useState<Nullable<IImage>>(null);
    const [pathContext, setPathContext] = useState<IPathContext>(pathContextBeforeRouteChange);
    const [photos, setPhotos] = useState<SpApi.Client.IEventPhoto[]>(initialPhotos);
    const [returnToPage, setReturnToPage] = useState<(index: number) => void>(() => {
        return noop;
    });
    const [selectedCardCategory, setSelectedCardCategory] = useState<Nullable<IBound>>(null);
    const [selectedCardProduct, setSelectedCardProduct] = useState<Nullable<IBoundItem>>(null);
    const [selectedPriceSheetGroup, setSelectedPriceSheetGroup] =
        useState<Nullable<SpApi.Client.IPriceSheetGroup>>(null);
    const [selectedPriceSheetItem, setSelectedPriceSheetItem] =
        useState<Nullable<SpApi.Client.IPriceSheetItem>>(null);
    const [userSelectedPhotos, setUserSelectedPhotos] = useState<IImage[]>([]);
    const [userSelectedProducts, setUserSelectedProducts] = useState<IPhotoConfig[]>([]);

    return (
        <StoreContext.Provider
            value={{
                albumsData,
                authenticatedAlbums,
                brandCurrencyCode,
                cardProducts,
                goToPage,
                imageOnSelectedGroup,
                isAdminModeEnabled,
                isLabeledPhotosVisible,
                isSubjectToGdpr,
                pathContext,
                pathContextBeforeRouteChange,
                photos,
                priceSheet,
                priceSheetGroups,
                returnToPage,
                selectedCardCategory,
                selectedCardProduct,
                selectedPriceSheetGroup,
                selectedPriceSheetItem,
                setAuthenticatedAlbums,
                setPhotos,
                setGoToPage,
                setImageOnSelectedGroup,
                setReturnToPage,
                setSelectedCardCategory,
                setSelectedCardProduct,
                setSelectedPriceSheetGroup,
                setSelectedPriceSheetItem,
                setUserSelectedPhotos,
                setUserSelectedProducts,
                setPathContext,
                spFulfilledDigitalItems,
                tags,
                userSelectedPhotos,
                userSelectedProducts,
                updateUserCartState
            }}
        >
            {children}
        </StoreContext.Provider>
    );
};

export const useStore = () => {
    return useContext(StoreContext);
};
