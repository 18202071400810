import type { IFetchHookOptions } from 'ts/common/hooks';
import useClientApiFetch from './useClientApiFetch';
import useEvent from './useEvent';

const useClientEventApiFetch = <TResponse>(path: string, fetchOptions?: IFetchHookOptions) => {
    const { brandId, id } = useEvent();

    return useClientApiFetch<TResponse>(`brand/${brandId}/event/${id}/${path}`, fetchOptions);
};

export default useClientEventApiFetch;
