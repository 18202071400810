import React, { ComponentType, FC, SVGProps } from 'react';
import { Flex, Icon, IconProps } from '@chakra-ui/react';

interface ICircleIconProps extends IconProps {
    /** The component to use to render the icon */
    icon: ComponentType<SVGProps<SVGSVGElement>>;
    /** The preset size of the icon */
    size?: 'md' | 'lg' | 'xl';
}

const CircleIcon: FC<ICircleIconProps> = ({ icon: IconComponent, size = 'md', ...props }) => {
    const outerPadding = size === 'xl' ? '20px' : size === 'lg' ? '10px' : '6px';
    const innerPadding = size === 'xl' ? '42px' : size === 'lg' ? '15px' : '8px';
    const iconSize = size === 'xl' ? '55px' : size === 'lg' ? '40px' : '24px';

    return (
        <Flex
            alignItems="center"
            backgroundColor="brand.primaryLight"
            borderRadius="50%"
            justifyContent="center"
            padding={outerPadding}
        >
            <Flex
                alignItems="center"
                backgroundColor="brand.primaryMedium"
                borderRadius="50%"
                justifyContent="center"
                padding={innerPadding}
            >
                <Icon
                    width={iconSize}
                    height={iconSize}
                    color="brand.primary"
                    display="block"
                    as={IconComponent}
                    {...props}
                />
            </Flex>
        </Flex>
    );
};

export default CircleIcon;
