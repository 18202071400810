import React, { ReactNode, useState } from 'react';
import { CloseButton, Flex, Text, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import { GalleryHeading } from 'ts/common/components/gallery';
import { AlbumPasswordModal } from 'client_react/gallery/components/Store';
import { ContextSelect } from 'client_react/gallery/components/Store/select-photos-modal';

interface IHeaderProps {
    headingText?: string;
    infoTextLeft?: string;
    infoTextRight?: string;
    onClose: () => void;
    primaryButton?: ReactNode;
    secondaryButton?: ReactNode;
    showCloseButton?: boolean;
    showContextSelect?: boolean;
}

const Header: React.FC<IHeaderProps> = ({
    headingText,
    infoTextLeft,
    infoTextRight,
    onClose,
    primaryButton,
    secondaryButton,
    showCloseButton = false,
    showContextSelect = false
}) => {
    const isSmallScreen = useBreakpointValue({ base: true, lg: false });
    const [selectedAlbumId, setSelectedAlbumId] = useState<string>();
    const { isOpen, onClose: closePasswordModal, onOpen: openPasswordModal } = useDisclosure();

    const onPasswordedAlbumSelected = (albumId: string) => {
        setSelectedAlbumId(albumId);

        openPasswordModal();
    };

    return (
        <>
            {selectedAlbumId && isOpen && (
                <AlbumPasswordModal
                    albumId={selectedAlbumId}
                    isOpen={isOpen}
                    onClose={closePasswordModal}
                />
            )}

            <Flex
                alignItems="center"
                data-testid="select-photos-modal-header"
                justifyContent="space-between"
                width="100%"
                height="40px"
            >
                <Flex alignItems="center" gap="20px">
                    {showCloseButton && (
                        <CloseButton
                            color="currentColor"
                            data-testid="select-photos-modal-close-button"
                            onClick={onClose}
                            _hover={{
                                color: 'brand.primary'
                            }}
                        />
                    )}
                    {(headingText || infoTextLeft) && (
                        <Flex
                            flexDirection={isSmallScreen ? 'column' : 'row'}
                            justifyContent={isSmallScreen ? 'center' : 'flex-start'}
                            height={isSmallScreen ? '40px' : 'auto'}
                            gap={isSmallScreen ? '0' : '12px'}
                            marginLeft={isSmallScreen ? '10px' : '0'}
                        >
                            {headingText && (
                                <GalleryHeading
                                    data-testid="modal-heading"
                                    size="sm"
                                    text={headingText}
                                    variant="primary"
                                    textTransform="none"
                                    lineHeight={isSmallScreen ? '12px' : '30px'}
                                    paddingTop="7px"
                                />
                            )}
                            {infoTextLeft && (
                                <Text paddingTop={isSmallScreen ? '0' : '11px'} fontWeight="normal">
                                    {infoTextLeft}
                                </Text>
                            )}
                        </Flex>
                    )}
                </Flex>
                {showContextSelect && (
                    <ContextSelect onPasswordedAlbumSelected={onPasswordedAlbumSelected} />
                )}
                <Flex alignItems="center" gap="20px">
                    {infoTextRight && <Text fontWeight="normal">{infoTextRight}</Text>}
                    {secondaryButton}
                    {primaryButton}
                </Flex>
            </Flex>
        </>
    );
};

export default Header;
