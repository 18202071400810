import React, { useCallback, useEffect } from 'react';
import { CloseButton, Flex, Heading, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import {
    trackClientEventFromPriceSheetItems,
    useEvent,
    useGalleryApiFetch
} from 'ts/client/common';
import { Button } from 'ts/common/components/gallery';
import { FetchMethod, useTranslate } from 'ts/common/hooks';
import { EmailCaptureModal } from 'client_react/gallery/components/Store';
import { useStore } from 'client_react/gallery/hooks/StoreContext';

interface ILaunchEditorResponse {
    data?: {
        url?: string;
    };
    needEmail?: boolean;
    statusText?: string;
}

interface IHeaderProps {
    onClose: () => void;
    showCloseButton: boolean;
}

const Header: React.FC<IHeaderProps> = ({ onClose, showCloseButton }) => {
    const t = useTranslate('client.store.cardTemplatePreviewSlide');
    const { id: eventId } = useEvent();
    const isMediumScreenOrLarger = useBreakpointValue({ base: false, md: true });
    const {
        brandCurrencyCode,
        pathContext,
        selectedCardProduct,
        selectedPriceSheetGroup,
        imageOnSelectedGroup
    } = useStore();

    const cardPricesheetItem = selectedPriceSheetGroup?.items.find(
        (item) => item.id === selectedCardProduct?.priceSheetItemId
    );

    const {
        isOpen: isEmailCaptureModalOpen,
        onClose: onEmailCaptureModalClose,
        onOpen: openEmailCaptureModal
    } = useDisclosure();

    const {
        response: launchEditorResponse,
        performFetch: launchEditor,
        loading: editorLoading
    } = useGalleryApiFetch<ILaunchEditorResponse>('cart/launcheditor', {
        defer: true,
        method: FetchMethod.POST
    });

    let context = pathContext.context;
    const validContexts = ['label', 'favorites', 'album'];

    if (!validContexts.includes(context)) {
        context = 'no-context';
    }

    const pushGoogleAnalyticsDataCallback = useCallback(() => {
        trackClientEventFromPriceSheetItems({
            eventSlug: 'add_to_cart',
            eventId,
            priceSheetItems: cardPricesheetItem ? [cardPricesheetItem] : undefined,
            orderPrice: selectedCardProduct?.whcc_id
                ? parseFloat(selectedCardProduct.price) * 25
                : selectedCardProduct?.price
                ? parseFloat(selectedCardProduct.price)
                : undefined,
            quantity: selectedCardProduct?.whcc_id ? 25 : 1,
            currencyCode: brandCurrencyCode
        });
    }, [brandCurrencyCode, cardPricesheetItem, eventId, selectedCardProduct]);

    const launchEditorCallBack = useCallback(() => {
        pushGoogleAnalyticsDataCallback();
        launchEditor({
            body: new URLSearchParams({
                context: context,
                context_id: String(pathContext.contextId),
                photo_id: String(imageOnSelectedGroup?.id),
                template_id: String(selectedCardProduct?.whcc_id),
                whcc_product_id: String(selectedCardProduct?.whcc_product_id)
            })
        });
    }, [
        context,
        pathContext.contextId,
        imageOnSelectedGroup,
        selectedCardProduct,
        launchEditor,
        pushGoogleAnalyticsDataCallback
    ]);

    useEffect(() => {
        if (launchEditorResponse) {
            if (launchEditorResponse.data?.url) {
                window.location.href = launchEditorResponse.data.url;
            }

            if (launchEditorResponse.statusText === 'error' && launchEditorResponse.needEmail) {
                openEmailCaptureModal();
            }
        }
    }, [launchEditorResponse, openEmailCaptureModal]);

    return (
        <>
            <EmailCaptureModal
                actionFunction={launchEditorCallBack}
                isActionLoading={editorLoading}
                isOpen={isEmailCaptureModalOpen}
                onClose={onEmailCaptureModalClose}
                variant="launch-card-editor"
            />
            <Flex
                alignItems="center"
                data-testid="select-card-modal-header"
                justifyContent="space-between"
                width="100%"
                height="40px"
            >
                <Flex alignItems="center" gap="20px">
                    {showCloseButton && (
                        <CloseButton
                            color="currentColor"
                            data-testid="select-card-close-button"
                            onClick={onClose}
                            _hover={{
                                color: 'brand.primary'
                            }}
                        />
                    )}
                    {isMediumScreenOrLarger && (
                        <Heading color="brand.primary" size="md" fontFamily="open-sans, sans-serif">
                            {t('heading')}
                        </Heading>
                    )}
                </Flex>
                {selectedCardProduct && isMediumScreenOrLarger && (
                    <Flex alignItems="center">
                        <Button
                            data-testid="start-designing-button"
                            type="submit"
                            onClick={() => launchEditorCallBack()}
                            fullWidth
                            isLoading={editorLoading}
                            marginBottom="7px"
                            text={t('cardTemplateStart')}
                            variant="primary"
                        />
                    </Flex>
                )}
            </Flex>
        </>
    );
};

export default Header;
