export default [
    '$scope',
    '$location',
    '$routeParams',
    'SPModal',
    'SPPhoto',
    'spComparePhotos',
    'spPhotoActions',
    function ComparePhotosController(
        $scope,
        $location,
        $routeParams,
        SPModal,
        SPPhoto,
        spComparePhotos,
        spPhotoActions
    ) {
        const EMPTY_COMPARE_REDIRECT_PATH = '/home';
        const IMAGE_SIZE = 'l';
        const { userState, eventData } = $scope;
        const selectedPhotos = spComparePhotos.getSelected();
        const [leftPhoto, rightPhoto] = selectedPhotos;

        initialize();

        $scope.closeLabelModal = closeLabelModal;
        $scope.closeAndGoToLabels = closeAndGoToLabels;

        function initialize() {
            if (selectedPhotos.length === 0) {
                return $location.url(EMPTY_COMPARE_REDIRECT_PATH);
            }

            leftPhoto.url = SPPhoto.getUrl(leftPhoto, IMAGE_SIZE);
            rightPhoto.url = SPPhoto.getUrl(rightPhoto, IMAGE_SIZE);

            Object.assign($scope, {
                leftPhoto,
                rightPhoto,
                photoActions: spPhotoActions({
                    userState,
                    eventData
                }),
                getExitHref
            });
        }

        function getExitHref() {
            return $routeParams.from;
        }

        function closeLabelModal() {
            SPModal.close();
        }

        function closeAndGoToLabels() {
            SPModal.close();
            $location.path('labels');
        }
    }
];
