import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import PRICESHEET_GROUP_TYPES from 'Sp/PriceSheet/Group';
import type { IPreviewBackground } from 'ts/common/components/editor/Preview';
import ProductRender from 'ts/common/components/product/ProductRender';
import {
    cardsBackdrop,
    grayBackdrop,
    ornamentBackdrop
} from 'ts/common/components/product/assets/backdrops';
import type { ICrop } from 'ts/common/types';
import type { IImage } from './types';

interface IProductProps {
    perspectiveRenderAllowed?: boolean;
    forceDefaultBackdrop?: boolean;
    crop?: Nullable<ICrop>;
    image: IImage;
    onClick?: () => void;
    priceSheetItem: SpApi.Client.IPriceSheetItem;
    productScene?: IPreviewBackground;
    renderCustomItem?: boolean;
    width?: number | string;
}

const Product: React.FC<IProductProps & BoxProps> = ({
    perspectiveRenderAllowed = true,
    forceDefaultBackdrop = false,
    crop,
    image,
    onClick,
    priceSheetItem,
    productScene,
    renderCustomItem,
    width = '100%',
    ...props
}) => {
    const backdrop =
        priceSheetItem.group.groupType === PRICESHEET_GROUP_TYPES.TYPE.CARDS
            ? cardsBackdrop
            : priceSheetItem.group.groupType === PRICESHEET_GROUP_TYPES.TYPE.ORNAMENTS &&
              !forceDefaultBackdrop
            ? ornamentBackdrop
            : grayBackdrop;

    const useOverlay = backdrop === ornamentBackdrop;

    return (
        <Box
            data-testid="product-group-image"
            background={`url(${backdrop})`}
            backgroundPosition="center"
            backgroundSize="cover"
            borderRadius="8px"
            maxWidth="100%"
            onClick={onClick}
            overflow="hidden"
            padding={0}
            position="relative"
            sx={{ aspectRatio: 1 }}
            width={width}
            {...props}
        >
            <ProductRender
                perspectiveRenderAllowed={perspectiveRenderAllowed}
                useOverlay={useOverlay}
                crop={crop}
                priceSheetItem={priceSheetItem}
                productScene={productScene}
                image={image}
                productPreviewProps={{ position: 'relative' }}
                renderCustomItem={renderCustomItem}
            />
        </Box>
    );
};

export default Product;
