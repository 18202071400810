import React, { useCallback, useEffect, useMemo } from 'react';
import { FormControl, FormLabel } from '@chakra-ui/react';
import type { UseFormSetValue } from 'react-hook-form';
import { useTranslate } from 'ts/common/hooks';
import type { IFormValues, ProductOption } from '../types';
import FrameSelect from './FrameSelect';
import { getFrameOptions } from './logic';

interface Props {
    priceSheetGroup: SpApi.Client.IPriceSheetGroup;
    priceSheetSubgroupId?: number;
    priceSheetItemId: number;
    setValue: UseFormSetValue<IFormValues>;
}

/**
 * Manages the set of frame options available passed to FrameSelect,
 * and synchronizes the selected frame option with the form state.
 */
const FrameOptionsSelect: React.FC<Props> = ({
    priceSheetGroup,
    priceSheetSubgroupId,
    priceSheetItemId,
    setValue
}) => {
    const t = useTranslate('client.gallery.optionSelect');

    const handleFrameOptionClick = useCallback(
        (option: ProductOption) => {
            setValue('priceSheetItemId', option.priceSheetItemId);
        },
        [setValue]
    );

    const availableOptions = useMemo(
        () => getFrameOptions(priceSheetGroup, priceSheetSubgroupId),
        [priceSheetGroup, priceSheetSubgroupId]
    );

    // Ensure that one of the available options is always selected:
    useEffect(() => {
        const matchingName = priceSheetGroup.items.find(
            (item) => item.id === priceSheetItemId
        )?.name;

        const matchingOption = availableOptions.find((option) => option.name === matchingName);

        if (matchingOption !== undefined) {
            setValue('priceSheetItemId', matchingOption.priceSheetItemId);
            setValue('priceSheetSubgroupId', matchingOption.priceSheetSubgroupId);
        }
    }, [availableOptions, priceSheetGroup, priceSheetItemId, setValue]);

    if (availableOptions.length <= 1) {
        return null;
    }

    return (
        <FormControl paddingBottom={0}>
            <FormLabel
                color="text"
                data-testid="price-sheet-item-id-select-label"
                fontFamily="open-sans, sans-serif"
                fontSize="md"
                fontWeight="semibold"
                htmlFor="price-sheet-item-id-select"
                marginBottom="2px"
                marginTop="24px"
            >
                {t('frameOptions')}
            </FormLabel>
            <FrameSelect
                onClickOption={handleFrameOptionClick}
                options={availableOptions}
                selectedPriceSheetItemId={priceSheetItemId}
            />
        </FormControl>
    );
};

export default FrameOptionsSelect;
