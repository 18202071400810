import React, { FC, useState } from 'react';
import {
    BoxProps,
    Button,
    Container,
    Flex,
    Grid,
    GridItem,
    IconButton,
    Input,
    Select,
    Text,
    useBreakpointValue,
    useNumberInput,
    useTheme
} from '@chakra-ui/react';
import { Copy, Crop, X } from 'react-feather';
import { styled } from 'sp-ui';
import { Product } from 'ts/client/gallery/components';
import type { IImage } from 'ts/client/gallery/components';
import { useCurrency } from 'ts/client/gallery/hooks';
import { useTranslate } from 'ts/common';
import type { ICrop } from 'ts/common/types';

const StepperButton = styled(Button)(
    ({ theme }) => `
        background: ${theme.isLightColorScheme ? 'white' : theme.colors.background};
        border: 1px solid ${theme.isLightColorScheme ? theme.colors.gray[100] : 'white'};
        color: currentColor;
        height: 40px;
        width: 42px;
        margin: 0;
    `
);

const DecrementButton = styled(StepperButton)(
    () => `
        border-bottom-right-radius: 0px;
        border-right-style: none;
        border-top-right-radius: 0px;
    `
);

const IncrementButton = styled(StepperButton)(
    () => `
        border-left-style: none;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    `
);

const NumberInput = styled(Input)(
    ({ theme }) => `
        margin: 0;
        height: 40px;
        width: 42px;
        border: 1px solid ${theme.isLightColorScheme ? theme.colors.gray[100] : 'white'};
        border-radius: 0;
        background: ${theme.isLightColorScheme ? 'white' : theme.colors.background};
        color: currentColor;
    `
);

interface IActionButtonProps {
    icon: React.ReactElement;
    onClick: () => void;
    text: string;
}

const ActionButton: FC<IActionButtonProps> = ({ icon, onClick, text, ...props }) => {
    return (
        <Button
            variant="ghost"
            color={'currentColor'}
            _hover={{ color: 'currentColor' }}
            fontSize="md"
            height="24px"
            justifyContent="left"
            leftIcon={icon}
            onClick={onClick}
            {...props}
        >
            {text}
        </Button>
    );
};

interface IProps {
    /** The crop values for the product on the tile. */
    crop: Nullable<ICrop>;
    /** The selected photo image to be displayed on the tile */
    image: IImage;
    /** The initial quantity to initialize the quantity input */
    initialQuantity: number;
    /** The initial pricesheet item id to initialize the select */
    initialPricesheetItemId: number;
    /** Boolean for if cropping is allowed */
    isCroppingAllowed: boolean;
    /** Function to be called when Delete button is clicked */
    onDelete: () => void;
    /** Function to be called when Duplicate button is clicked */
    onDuplicate: () => void;
    /** Function to be called when the Edit Crop button is clicked */
    onEditCrop: () => void;
    /** Function to be called when quantity changes */
    onQuantityChange: (quantity: number) => void;
    /** Function to be called when selected Size changes */
    onSizeChange: (priceSheetItemId: number) => void;
    /** The pricesheet group of the selected photo - TBD if it will be used so removing it for now*/
    priceSheetGroup: Nullable<SpApi.Client.IPriceSheetGroup>;
    /** The pricesheet item of the selected photo */
    priceSheetItem: SpApi.Client.IPriceSheetItem;
}

const PhotoOptionsTile: React.FC<IProps & BoxProps> = ({
    crop,
    image,
    initialQuantity,
    initialPricesheetItemId,
    isCroppingAllowed,
    onDelete,
    onDuplicate,
    onEditCrop,
    onQuantityChange,
    onSizeChange,
    priceSheetGroup,
    priceSheetItem,
    ...props
}) => {
    const $ = useCurrency();
    const theme = useTheme();
    const t = useTranslate('client.store.selectPhotosModal');
    const isSmallScreen = useBreakpointValue({ base: true, lg: false });
    const {
        getDecrementButtonProps,
        getIncrementButtonProps,
        getInputProps,
        value: quantity
    } = useNumberInput({
        defaultValue: initialQuantity,
        min: 1,
        onChange(_, valueAsNumber) {
            onQuantityChange(valueAsNumber);
        }
    });
    const isSelfFulfilledUnboundedItem = priceSheetItem.subgroup?.name === null;
    const shouldHideTheSizeSelect = isSelfFulfilledUnboundedItem;

    const [selectedPricesheetItemId, setSelectedPricesheetItemId] =
        useState<number>(initialPricesheetItemId);
    const selectedPricesheetItem = priceSheetGroup?.items.find(
        (item) => item.id === selectedPricesheetItemId
    );

    const shouldShowEditCropButton = () => {
        const isSelfFulfilledDigital =
            priceSheetItem.type === 'price-sheet-item-digital' &&
            !priceSheetItem?.isDigitalDownload;

        return isCroppingAllowed && !isSelfFulfilledDigital && crop;
    };

    const orderPrice =
        (selectedPricesheetItem?.price ?? priceSheetItem?.price ?? 0) * parseInt(quantity);

    const options = priceSheetGroup?.items.reduce((options, item) => {
        if (item.name === priceSheetItem?.name && item.subgroup?.name) {
            options.push({ priceSheetItemId: item.id, option: item.subgroup?.name });
        }

        return options;
    }, [] as { priceSheetItemId: number; option: string }[]);

    return (
        <Container
            width="100%"
            maxWidth="592px"
            padding={isSmallScreen ? '20px' : '40px'}
            boxShadow={theme.isLightColorScheme ? '0px 8px 24px 0px rgba(0, 0, 0, 0.1)' : ''}
            borderRadius="20px"
            background={theme.isLightColorScheme ? 'background' : 'gray.50'}
            data-testid="photo-options-container"
            height="min-content"
            maxHeight="730px"
            {...props}
        >
            <Product
                forceDefaultBackdrop
                crop={crop}
                image={image}
                priceSheetItem={selectedPricesheetItem ?? priceSheetItem}
                borderRadius="0"
                background={theme.isLightColorScheme ? 'background' : 'rgba(155, 155, 155, 0)'}
            />
            <Flex margin="40px 0" justifyContent="space-evenly">
                <ActionButton
                    data-testid="photo-options-duplicate-button"
                    icon={<Copy strokeWidth="1px" style={{ marginRight: '2px' }} />}
                    onClick={onDuplicate}
                    text={t('duplicate')}
                />
                {shouldShowEditCropButton() && (
                    <ActionButton
                        data-testid="photo-options-edit-crop-button"
                        icon={<Crop strokeWidth="1px" style={{ marginRight: '2px' }} />}
                        onClick={onEditCrop}
                        text={t('editCrop')}
                    />
                )}
                {!isSmallScreen ? (
                    <ActionButton
                        data-testid="photo-options-remove-button"
                        icon={<X strokeWidth="1px" style={{ marginRight: '2px' }} />}
                        onClick={onDelete}
                        text={t('remove')}
                    />
                ) : (
                    <IconButton
                        data-testid="photo-options-remove-button"
                        icon={<X strokeWidth="1px" />}
                        height="24px"
                        color="text"
                        backgroundColor="transparent"
                        border="none"
                        _hover={{ color: 'brand.primary' }}
                        _focus={{ color: 'text' }}
                        aria-label={'more options'}
                        onClick={onDelete}
                    />
                )}
            </Flex>
            <Grid
                templateColumns={
                    isSmallScreen || shouldHideTheSizeSelect ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'
                }
                templateRows={
                    isSmallScreen && !shouldHideTheSizeSelect ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)'
                }
                alignItems="center"
                justifyContent="center"
                rowGap={isSmallScreen ? '22px' : '0'}
            >
                {!shouldHideTheSizeSelect && (
                    <GridItem
                        display="flex"
                        justifyContent={isSmallScreen ? 'flex-start' : 'center'}
                    >
                        {options && (
                            <Select
                                data-testid="photo-options-size-select"
                                height="40px"
                                width="125px"
                                background={theme.isLightColorScheme ? 'white' : 'background'}
                                value={selectedPricesheetItemId}
                                onChange={(event) => {
                                    setSelectedPricesheetItemId(parseInt(event.target.value));
                                    onSizeChange(parseInt(event.target.value));
                                }}
                            >
                                {options.map(({ priceSheetItemId, option }, index) => (
                                    <option
                                        key={index}
                                        value={priceSheetItemId}
                                        style={{ background: 'inherit' }}
                                    >
                                        {option}
                                    </option>
                                ))}
                            </Select>
                        )}
                    </GridItem>
                )}
                <GridItem
                    data-testid="photo-options-price"
                    gridRow={isSmallScreen && !shouldHideTheSizeSelect ? '2' : 'auto'}
                    gridColumn={isSmallScreen && !shouldHideTheSizeSelect ? '1 / 3' : 'auto'}
                    justifySelf="center"
                >
                    <Text fontSize="md" margin="0">
                        {`${t('subtotal')}: ${$(orderPrice)}`}
                    </Text>
                </GridItem>
                <GridItem
                    justifySelf={isSmallScreen && !shouldHideTheSizeSelect ? 'flex-end' : 'center'}
                >
                    <Flex>
                        <DecrementButton
                            data-testid="quantity-decrement-button"
                            {...getDecrementButtonProps()}
                        >
                            -
                        </DecrementButton>
                        <NumberInput
                            {...getInputProps()}
                            data-testid="photo-options-quantity-input"
                            name="quantity"
                            textAlign="center"
                            value={quantity}
                            width="60px"
                        />
                        <IncrementButton
                            data-testid="quantity-increment-button"
                            {...getIncrementButtonProps()}
                        >
                            +
                        </IncrementButton>
                    </Flex>
                </GridItem>
            </Grid>
        </Container>
    );
};

export default PhotoOptionsTile;
