import { Flex } from '@chakra-ui/react';
import { styled } from 'sp-ui';

const Footer = styled(Flex)(
    ({ theme }) => `
        border-top: 1px solid ${theme.isLightColorScheme ? theme.colors.gray[200] : 'black'};
        bottom: 0;
        height: 80px;
        left: 0;
        padding: 0 30px;
        position: fixed;
        width: 100%;
        z-index: 1;
    `
);

export default Footer;
