import React, { FC, useMemo } from 'react';
import type { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { getProductSizes } from 'ts/client/gallery/components/common';
import FrameOptionsSelect from 'ts/client/gallery/components/frames/FrameOptionsSelect';
import { FramedPrintMeasuresBanner } from 'ts/client/gallery/components/frames/FramedPrintMeasuresBanner';
import { useTranslate } from 'ts/common';
import RadioGroupGrid from 'ts/common/components/RadioGroupGrid';
import type { IFormValues } from '../types';

interface Props {
    priceSheetGroup: SpApi.Client.IPriceSheetGroup;
    setValue: UseFormSetValue<IFormValues>;
    watch: UseFormWatch<IFormValues>;
}

/**
 * Renders the Frame and Size form fields, for framed product shopping.
 */
const FrameOptionsGroup: FC<Props> = ({ priceSheetGroup, setValue, watch }) => {
    const t = useTranslate('client.store.selectProductOptionsPage');
    const { priceSheetSubgroupId, priceSheetItemId } = watch();

    const productSizes = useMemo(
        () =>
            getProductSizes(
                priceSheetGroup,
                priceSheetGroup.items.find((item) => item.id === priceSheetItemId)?.name
            ),
        [priceSheetGroup, priceSheetItemId]
    );
    const sizeOptionsForRadioGrid =
        productSizes?.map((productSize) => {
            return { name: productSize.size, value: productSize.priceSheetSubgroupId };
        }) ?? [];

    const selectedItem = priceSheetGroup.items.find((item) => item.id === priceSheetItemId);

    return (
        <>
            <FrameOptionsSelect
                priceSheetGroup={priceSheetGroup}
                priceSheetSubgroupId={priceSheetSubgroupId}
                priceSheetItemId={priceSheetItemId}
                setValue={setValue}
            />
            {productSizes && (
                <RadioGroupGrid
                    defaultValue={priceSheetSubgroupId}
                    fieldName="priceSheetSubgroupId"
                    label={t('size')}
                    onChange={(fieldName, value) =>
                        setValue(fieldName as 'priceSheetSubgroupId' | 'priceSheetItemId', value)
                    }
                    options={sizeOptionsForRadioGrid}
                    parentFormValue={priceSheetSubgroupId}
                />
            )}
            <FramedPrintMeasuresBanner item={selectedItem as SpApi.Client.IPriceSheetItemSingle} />
        </>
    );
};

export default FrameOptionsGroup;
