import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Flex, Link } from '@chakra-ui/react';
import { ResizeObserver } from '@juggle/resize-observer';
import { ArrowLeft } from 'react-feather';

interface Props {
    children?: React.ReactNode;
    /** Optionally called when the link is clicked */
    onClickBack?: () => unknown;
    /** The name of the parent route, if any */
    parentRouteName?: string;
    /** The path from the URL of the parent, if any */
    parentUri?: string;
    /** An optional node to render on the right side of this component */
    rightActions?: ReactNode;
    /** The Angular translation service */
    translateFilter: SpAngularJs.ITranslateFilter;
}

const BackNav: FC<Props> = ({
    children,
    onClickBack,
    parentRouteName,
    parentUri,
    rightActions,
    translateFilter: translate
}) => {
    const leftActionsContainerRef = useRef<HTMLDivElement>(null);
    const rightActionsContainerRef = useRef<HTMLDivElement>(null);
    const [actionsContainerWidth, setActionsContainerWidth] = useState<number>();

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            setActionsContainerWidth(
                Math.max(
                    leftActionsContainerRef.current?.offsetWidth ?? 0,
                    rightActionsContainerRef.current?.offsetWidth ?? 0
                )
            );
        });

        if (leftActionsContainerRef.current) {
            resizeObserver.observe(leftActionsContainerRef.current);
        }

        if (rightActionsContainerRef.current) {
            resizeObserver.observe(rightActionsContainerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <Flex
            alignItems="center"
            backgroundColor="background"
            borderBottomColor="gray.100"
            borderBottomStyle="solid"
            borderBottomWidth="1px"
            flexFlow="row nowrap"
            height="60px"
            justifyContent="space-between"
            padding="16px"
            position="relative"
        >
            <Flex
                ref={leftActionsContainerRef}
                flex={`0 0 ${
                    actionsContainerWidth === undefined ? 'auto' : `${actionsContainerWidth}px`
                }`}
                flexFlow="row nowrap"
                justifyContent="flex-start"
                alignItems="center"
            >
                <Link
                    aria-label={translate(parentRouteName ? `Back to ${parentRouteName}` : 'back')}
                    href={parentUri}
                    onClick={onClickBack}
                    color="text"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="40px"
                    height="40px"
                    _focus={{
                        color: 'text',
                        boxShadow: 'none'
                    }}
                    _focusVisible={{
                        boxShadow: 'outline'
                    }}
                    _hover={{
                        color: 'brand.primary',
                        textDecoration: 'none'
                    }}
                    _visited={{
                        color: 'text',
                        '&:focus': {
                            color: 'text'
                        },
                        '&:hover': {
                            color: 'brand.primary'
                        }
                    }}
                >
                    <ArrowLeft />
                </Link>
            </Flex>
            <Flex
                flex="0 1 auto"
                flexFlow="row nowrap"
                justifyContent={rightActions ? 'center' : 'flex-end'}
                alignItems="center"
                overflow="hidden"
            >
                {children}
            </Flex>
            {rightActions && (
                <Flex
                    ref={rightActionsContainerRef}
                    flex={`0 0 ${
                        actionsContainerWidth === undefined ? 'auto' : `${actionsContainerWidth}px`
                    }`}
                    flexFlow="row nowrap"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    {rightActions}
                </Flex>
            )}
        </Flex>
    );
};

export default BackNav;
