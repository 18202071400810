import React, { FC } from 'react';
import { FormErrorMessage as BaseFormErrorMessage } from '@chakra-ui/react';
import { AlertCircle } from 'react-feather';

interface IFormErrorMessageProps {
    children?: React.ReactNode;
}

const FormErrorMessage: FC<IFormErrorMessageProps> = ({ children }) => {
    return (
        <BaseFormErrorMessage>
            <AlertCircle size="1rem" style={{ marginRight: '0.25rem' }} />
            {children}
        </BaseFormErrorMessage>
    );
};

export default FormErrorMessage;
