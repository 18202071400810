import { GroupType } from './components/types';
import type { IBoundItem, IProductGroup } from './components/types';

export function getCardProductsFromProductGroups(productGroups: IProductGroup[]) {
    return productGroups.find((group) => group.type === GroupType.CARDS);
}

function getDigitalsGroupFromItemsAndBoundItems(
    digitalsGroup: SpApi.Client.IPriceSheetGroup,
    spFulfilledDigitalBoundItems: IBoundItem[]
) {
    if (digitalsGroup.groupType !== 'digitals') {
        return digitalsGroup;
    }

    const digitalItems = digitalsGroup.items.filter(
        (digitalItem) =>
            digitalItem.type === 'price-sheet-item-digital' &&
            (!digitalItem.isDigitalDownload ||
                (digitalItem.isDigitalDownload &&
                    spFulfilledDigitalBoundItems.some(
                        (boundItem) =>
                            boundItem.id === digitalItem.id.toString() ||
                            boundItem.parent_price_sheet_item_id === digitalItem.id.toString()
                    )))
    );

    if (digitalItems.length === 0) {
        return;
    }

    return {
        ...digitalsGroup,
        items: digitalItems
    };
}

export function getPriceSheetGroupsFromPriceSheetItems(
    priceSheetItems: SpApi.Client.IPriceSheetItem[],
    priceSheetShowPackagesOnly: boolean
) {
    const priceSheetGroupsByGroupType = priceSheetItems.reduce(
        (priceSheetGroups, priceSheetItem) => {
            const { group } = priceSheetItem;

            if (!group) {
                return priceSheetGroups;
            }

            const { groupType, id } = group;

            if (groupType === 'digitals') {
                const {
                    allAlbumPhotosDownload,
                    allGalleryPhotosDownload,
                    individualPhotoDownload,
                    price
                } = priceSheetItem;
                const hasDigitalsForSale = [
                    allAlbumPhotosDownload?.isDigitalDownloadForSale,
                    allGalleryPhotosDownload?.isDigitalDownloadForSale,
                    individualPhotoDownload?.isDigitalDownloadForSale,
                    typeof price === 'number'
                ].some((isDigitalDownloadForSale) => isDigitalDownloadForSale);

                if (!hasDigitalsForSale) {
                    return priceSheetGroups;
                }
            }

            if (priceSheetShowPackagesOnly && groupType !== 'packages') {
                return priceSheetGroups;
            }

            if (!priceSheetGroups[id]) {
                priceSheetGroups[id] = {
                    ...group,
                    items: []
                };
            }

            priceSheetGroups[id].items.push(priceSheetItem);

            return priceSheetGroups;
        },
        {} as { [id: number]: SpApi.Client.IPriceSheetGroup }
    );

    const priceSheetGroups = Object.values(priceSheetGroupsByGroupType).sort(
        ({ displayOrder }, { displayOrder: nextDisplayOrder }) => displayOrder - nextDisplayOrder
    );

    return priceSheetGroups;
}

export function getPriceSheetGroupsFromPriceSheetItemsAndBoundItems(
    priceSheetItems: SpApi.Client.IPriceSheetItem[],
    spFulfilledDigitalBoundItems: IBoundItem[],
    priceSheetShowPackagesOnly: boolean
) {
    const productGroups = getPriceSheetGroupsFromPriceSheetItems(
        priceSheetItems,
        priceSheetShowPackagesOnly
    );

    return productGroups.reduce((priceSheetGroups, priceSheetGroup) => {
        if (priceSheetGroup.groupType === 'digitals') {
            const digitalsGroup = getDigitalsGroupFromItemsAndBoundItems(
                priceSheetGroup,
                spFulfilledDigitalBoundItems
            );

            if (digitalsGroup) {
                priceSheetGroups.push(digitalsGroup);
            }
        } else {
            priceSheetGroups.push(priceSheetGroup);
        }

        return priceSheetGroups;
    }, [] as SpApi.Client.IPriceSheetGroup[]);
}

export function getSpFulfilledDigitalBoundItemsFromProductGroups(productGroups: IProductGroup[]) {
    const digitalsGroup = productGroups.find((group) => group.type === GroupType.DIGITALS);
    const spFulfilledBoundItems = digitalsGroup?.bounds.find(
        (bound) => bound.digital_type === 'sp'
    )?.items;

    return spFulfilledBoundItems ? spFulfilledBoundItems : [];
}
