import React, { FC, useCallback, useState } from 'react';
import { Flex, IconButton, useTheme } from '@chakra-ui/react';
import { MoreVertical } from 'react-feather';
import { getDownloadDestinationsProps } from 'ts/client/gallery/components/common';
import DrawerWithActions from 'ts/common/components/gallery/DrawerWithActions';
import usePhotoActions, { IUsePhotoActionsProps } from 'ts/common/hooks/usePhotoActions';

/**
 * The PhotoKebab renders a "kebab" button which opens a drawer of buttons.
 * The buttons are used to control a single photo's actions.
 * This component is used for small screens only.
 */
const PhotoKebab: FC<IUsePhotoActionsProps> = (props) => {
    const { spFreeDigitals, translateFilter: translate, photoActions } = props;

    const theme = useTheme();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const {
        hideShareLinks,
        isShareLinksVisible,
        hideDownloadDestinations,
        isDownloadDestinationsVisible,
        photoActionButtons,
        shareUrls
    } = usePhotoActions(props);

    const handleKebabClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setIsDrawerOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setIsDrawerOpen(false);
        hideShareLinks();
        hideDownloadDestinations();
    }, [hideDownloadDestinations, hideShareLinks]);

    const handleCancel = useCallback(() => {
        handleClose();
        photoActions.cancelDownload();
    }, [handleClose, photoActions]);

    // We hide the Label button, because it doesn't work correctly within the home/album page where PhotoKebab is used.
    // We'll enable it again as part of https://shootproof.atlassian.net/browse/FOR-9013
    const filteredPhotoActionButtons = photoActionButtons.filter(
        (button) => button.key !== 'label'
    );

    if (filteredPhotoActionButtons.length === 0) {
        return null;
    }

    return (
        <Flex justifyContent="flex-end" padding="4px">
            <IconButton
                aria-label={translate('showPhotoActions')}
                icon={<MoreVertical />}
                isRound
                height="36px"
                width="36px"
                border="none"
                color="text"
                backgroundColor={theme.isLightColorScheme ? 'whiteAlpha.700' : 'blackAlpha.700'}
                _hover={{
                    color: 'brand.primary'
                }}
                _focus={{
                    boxShadow: 'none',
                    color: 'text'
                }}
                _focusVisible={{
                    boxShadow: 'outline',
                    color: 'brand.primary'
                }}
                onClick={handleKebabClick}
            />
            <DrawerWithActions
                actions={filteredPhotoActionButtons}
                isOpen={isDrawerOpen || isShareLinksVisible || isDownloadDestinationsVisible}
                isShareLinksVisible={isShareLinksVisible}
                onCancel={handleCancel}
                onClose={handleClose}
                shareCopyLinkUrl={shareUrls?.photoShareUrl}
                shareHeadingText={translate('sharePhoto')}
                shareUrls={shareUrls}
                isDownloadDestinationsVisible={isDownloadDestinationsVisible}
                isDownloadPhoto
                downloadDestinationsProps={getDownloadDestinationsProps(photoActions, handleClose)}
                spFreeDigitals={spFreeDigitals}
                translateFilter={translate}
            />
        </Flex>
    );
};

export default PhotoKebab;
