import React, { FC } from 'react';
import { Flex, IconButton, useTheme } from '@chakra-ui/react';
import DownloadDestinations from 'ts/client/gallery/components/DownloadDestinations';
import { getDownloadDestinationsProps } from 'ts/client/gallery/components/common';
import { LoadingIndicator } from 'ts/common/components';
import Modal from 'ts/common/components/gallery/Modal';
import ShareLinks from 'ts/common/components/gallery/ShareLinks';
import usePhotoActions, { IUsePhotoActionsProps } from 'ts/common/hooks/usePhotoActions';

/**
 * The PhotoActionsList renders a row of icon buttons within a photo grid item's overlay.
 * The buttons are used to control a single photo's actions.
 * This component is used for large screens only.
 */
const PhotoActionList: FC<IUsePhotoActionsProps> = (props) => {
    const { spFreeDigitals, translateFilter: translate, photoActions } = props;

    const theme = useTheme();

    const {
        hideShareLinks,
        isShareLinksVisible,
        hideDownloadDestinations,
        isDownloadDestinationsVisible,
        photoActionButtons,
        shareUrls
    } = usePhotoActions(props);

    // We hide the Label button, because it doesn't work correctly within the home/album page where PhotoActionList is used.
    // We'll enable it again as part of https://shootproof.atlassian.net/browse/FOR-9013
    const filteredPhotoActionButtons = photoActionButtons.filter(
        (button) => button.key !== 'label'
    );

    const handleCancelDownload = () => {
        hideDownloadDestinations();
        photoActions.cancelDownload();
    };

    return (
        <Flex
            justifyContent="flex-end"
            gap="8px"
            padding="4px"
            opacity={0}
            _groupHover={{ opacity: 1 }}
            transition="0.25s opacity"
        >
            {filteredPhotoActionButtons.map((button) => {
                return (
                    <IconButton
                        key={button.key}
                        role={button.isToggledOn === undefined ? undefined : 'switch'}
                        aria-checked={button.isToggledOn}
                        aria-label={button.text}
                        isDisabled={button.isDisabled}
                        isRound
                        icon={button.icon}
                        height="28px"
                        width="28px"
                        minWidth="28px"
                        border="none"
                        paddingInlineStart="0px"
                        paddingInlineEnd="0px"
                        color="text"
                        backgroundColor={
                            theme.isLightColorScheme ? 'whiteAlpha.700' : 'blackAlpha.700'
                        }
                        _hover={{
                            color: 'brand.primary'
                        }}
                        _focus={{
                            boxShadow: 'none',
                            color: 'text'
                        }}
                        _focusVisible={{
                            boxShadow: 'outline',
                            color: 'brand.primary'
                        }}
                        onClick={(event) => {
                            event.stopPropagation();
                            button.onClick();
                        }}
                    />
                );
            })}
            <Modal isOpen={isShareLinksVisible} onClose={hideShareLinks}>
                {shareUrls ? (
                    <ShareLinks
                        copyLinkHref={shareUrls?.photoShareUrl}
                        headingText={translate('sharePhoto')}
                        linkHrefs={shareUrls}
                        onClickLink={hideShareLinks}
                        translateFilter={translate}
                    />
                ) : (
                    <LoadingIndicator />
                )}
            </Modal>
            <Modal isOpen={isDownloadDestinationsVisible} onClose={handleCancelDownload} size="sm">
                <DownloadDestinations
                    {...getDownloadDestinationsProps(photoActions, hideDownloadDestinations)}
                    isDownloadPhoto
                    spFreeDigitals={spFreeDigitals}
                    translateFilter={translate}
                />
            </Modal>
        </Flex>
    );
};

export default PhotoActionList;
