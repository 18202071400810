import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Divider, Flex, Text, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useGalleryApiFetch } from 'ts/client/common';
import {
    trackClientEventFromPriceSheetItems,
    trackViewItemEvent,
    useEvent,
    useFetchGalleryPhotosByContext
} from 'ts/client/common';
import {
    OptionSelect,
    ProductDescriptionAccordion,
    ProductImages
} from 'ts/client/gallery/components';
import QuantityStepper from 'ts/client/gallery/components/add-to-cart-side-panel/QuantityStepper';
import {
    areEqualSize,
    convertIBoundItemToIPhotoConfig,
    convertIBoundItemToIPriceSheetItem,
    convertPhotoToImage,
    getFirstItemByDisplayOrder,
    getItemDisplayName,
    getProductSizes,
    isSingleItem
} from 'ts/client/gallery/components/common';
import FrameOptionsGroup from 'ts/client/gallery/components/frames/FrameOptionsGroup';
import type { IBoundItem, IFormValues } from 'ts/client/gallery/components/types';
import { useAddToCart, useCurrency } from 'ts/client/gallery/hooks';
import { getMaxCrop, getSize, noop, useTranslate } from 'ts/common';
import RadioGroupGrid from 'ts/common/components/RadioGroupGrid';
import { Button, GalleryHeading } from 'ts/common/components/gallery';
import { isFramedCategory } from 'ts/common/logic';
import { ICrop } from 'ts/common/types';
import {
    AddToCartErrorModal,
    AlbumSelect,
    EmailCaptureModal,
    SelectPhotosModal
} from 'client_react/gallery/components/Store';
import { useStore } from 'client_react/gallery/hooks/StoreContext';
import { PageIndex } from '../StorePage';

const SelectProductOptionsPage: FC = () => {
    const $ = useCurrency();
    const { id: eventId } = useEvent();
    const t = useTranslate('client.store.selectProductOptionsPage');
    const {
        brandCurrencyCode,
        imageOnSelectedGroup,
        goToPage,
        pathContext,
        photos,
        selectedPriceSheetGroup,
        selectedPriceSheetItem,
        setImageOnSelectedGroup,
        setSelectedPriceSheetItem,
        setUserSelectedProducts,
        setUserSelectedPhotos,
        spFulfilledDigitalItems,
        userSelectedProducts,
        updateUserCartState
    } = useStore();

    const { isPostToCartLoading, postDigitalItemToCart, postToCartResponse } = useAddToCart();

    const [selectedPriceSheetItemCrop, setSelectedPriceSheetItemCrop] =
        useState<Nullable<ICrop>>(null);
    const [photosForAlbumSelect, setPhotosForAlbumSelect] =
        useState<SpApi.Client.IEventPhoto[]>(photos);
    const [hadInitialPriceSheetItem] = useState(!!selectedPriceSheetItem);
    const [digitalItemSelected, setDigitalItemSelected] = useState<IBoundItem>();

    const isLargeScreen = useBreakpointValue({ base: false, lg: true });
    const isDigitalItem =
        selectedPriceSheetItem && selectedPriceSheetItem.group.groupType === 'digitals';
    const isDigitalDownload =
        !!selectedPriceSheetItem &&
        selectedPriceSheetItem.group.groupType === 'digitals' &&
        selectedPriceSheetItem.type === 'price-sheet-item-digital' &&
        selectedPriceSheetItem.isDigitalDownload;
    const isNonDownloadableDigitalItem = !isDigitalDownload && isDigitalItem;
    const isCanvasItem = selectedPriceSheetItem?.group.groupType === 'canvas';

    const firstItemInGroupByDisplayOrder = useMemo(
        () => selectedPriceSheetGroup && getFirstItemByDisplayOrder(selectedPriceSheetGroup),
        [selectedPriceSheetGroup]
    );
    const productSizes = useMemo(
        () => selectedPriceSheetGroup && getProductSizes(selectedPriceSheetGroup),
        [selectedPriceSheetGroup]
    );

    const initialPriceSheetItem = selectedPriceSheetItem ?? firstItemInGroupByDisplayOrder;
    const initialPriceSheetItemRef = useRef(initialPriceSheetItem);

    const {
        formState: { defaultValues },
        handleSubmit,
        setValue,
        watch
    } = useForm<IFormValues>({
        defaultValues: {
            priceSheetItemId: initialPriceSheetItem?.id,
            priceSheetSubgroupId: initialPriceSheetItem?.subgroup?.id,
            quantity: 1
        }
    });

    const { priceSheetItemId, quantity } = watch();

    // TODO: Remove after debugging data is nolonger needed:
    const [initialImageOnSelectedGroupId] = useState(imageOnSelectedGroup?.id);

    useEffect(() => {
        const digitalItemSelected = spFulfilledDigitalItems?.find(
            (item) => item.id === String(priceSheetItemId)
        );
        setDigitalItemSelected(digitalItemSelected);
    }, [priceSheetItemId, spFulfilledDigitalItems]);

    const {
        loading: areAlbumsLoading,
        response: albumsDataResponse,
        performFetch: getAlbums
    } = useGalleryApiFetch<IApiListResponse<SpApi.Client.IAlbum>>(`getalbums`, {
        defer: true,
        search: {
            filterUnAuthenticatedAlbums: '1'
        }
    });

    const {
        fetchPhotos,
        loading: arePhotosLoading,
        fetchPhotosResponse
    } = useFetchGalleryPhotosByContext();

    const [selectedAlbumId, setSelectedAlbumId] = useState<number>();

    const shouldShowAlbumSelect = !!digitalItemSelected?.is_album;

    useEffect(() => {
        if (shouldShowAlbumSelect && !albumsDataResponse) {
            getAlbums();
        }
    }, [albumsDataResponse, getAlbums, shouldShowAlbumSelect]);

    useEffect(() => {
        if (shouldShowAlbumSelect && selectedAlbumId) {
            fetchPhotos('album', selectedAlbumId);
        }
    }, [fetchPhotos, selectedAlbumId, shouldShowAlbumSelect]);

    useEffect(() => {
        if (albumsDataResponse) {
            setSelectedAlbumId(
                Number(
                    pathContext.context === 'album'
                        ? pathContext.contextId
                        : albumsDataResponse?.items?.[0]?.id
                )
            );
        }
    }, [albumsDataResponse, pathContext.context, pathContext.contextId]);

    useEffect(() => {
        if (fetchPhotosResponse) {
            setPhotosForAlbumSelect(fetchPhotosResponse.items);
        }
    }, [fetchPhotosResponse]);

    useEffect(() => {
        if (shouldShowAlbumSelect && selectedAlbumId) {
            const randomPhotoInAlbum = photosForAlbumSelect.find((photo) =>
                photo.belongsToAlbumIds.some((albumId) => albumId === selectedAlbumId)
            );

            if (randomPhotoInAlbum) {
                setImageOnSelectedGroup(convertPhotoToImage(randomPhotoInAlbum));
            }
        }
    }, [photosForAlbumSelect, selectedAlbumId, shouldShowAlbumSelect, setImageOnSelectedGroup]);

    let orderPrice;

    if (isDigitalDownload) {
        if (spFulfilledDigitalItems && priceSheetItemId) {
            const digitalItem = spFulfilledDigitalItems.find(
                (digitalItem) => digitalItem.id === priceSheetItemId.toString()
            );

            if (digitalItem) {
                orderPrice = parseFloat(digitalItem.price) * quantity;
            }
        }
    } else {
        orderPrice = (selectedPriceSheetItem?.price ?? 0) * quantity;
    }

    useEffect(() => {
        const newPriceSheetItem = selectedPriceSheetGroup?.items.find(
            ({ id }) => id === priceSheetItemId
        );

        let shouldRecalculateCrop = false;

        if (newPriceSheetItem && imageOnSelectedGroup) {
            setSelectedPriceSheetItem((currentItem) => {
                if (currentItem?.id !== newPriceSheetItem.id) {
                    if (
                        isSingleItem(newPriceSheetItem) &&
                        newPriceSheetItem.size &&
                        (!currentItem ||
                            !isSingleItem(currentItem) ||
                            !currentItem?.size ||
                            !areEqualSize(newPriceSheetItem.size, currentItem.size))
                    ) {
                        shouldRecalculateCrop = true;
                    }

                    return newPriceSheetItem;
                }

                return currentItem;
            });

            if (shouldRecalculateCrop && isSingleItem(newPriceSheetItem)) {
                const maxCrop = getMaxCrop(
                    imageOnSelectedGroup,
                    getSize(newPriceSheetItem.size),
                    newPriceSheetItem.group.groupType === 'canvas',
                    newPriceSheetItem.group.groupType === 'ornaments'
                );
                setSelectedPriceSheetItemCrop(maxCrop);
            }
        }
    }, [
        imageOnSelectedGroup,
        priceSheetItemId,
        selectedPriceSheetGroup,
        setSelectedPriceSheetItem
    ]);

    const getHeadingText = () => {
        if (hadInitialPriceSheetItem && selectedPriceSheetItem) {
            return getItemDisplayName(selectedPriceSheetItem);
        }

        if (selectedPriceSheetGroup && selectedPriceSheetGroup.items.length > 1) {
            return selectedPriceSheetGroup.name;
        }

        return '';
    };

    const { isOpen, onClose, onOpen: openSelectPhotosModal } = useDisclosure();
    const {
        isOpen: isEmailCaptureModalOpen,
        onClose: onEmailCaptureModalClose,
        onOpen: openEmailCaptureModal
    } = useDisclosure();
    const {
        isOpen: isAddToCartErrorModalOpen,
        onClose: onAddToCartErrorModalClose,
        onOpen: openAddToCartErrorModal
    } = useDisclosure();

    const pushGoogleAnalyticsDataCallback = useCallback(
        (eventSlug: string) => {
            trackClientEventFromPriceSheetItems({
                eventSlug: eventSlug,
                eventId,
                priceSheetItems: selectedPriceSheetItem ? [selectedPriceSheetItem] : undefined,
                orderPrice,
                quantity,
                currencyCode: brandCurrencyCode,
                digitalItem: digitalItemSelected
            });
        },
        [
            brandCurrencyCode,
            digitalItemSelected,
            eventId,
            selectedPriceSheetItem,
            orderPrice,
            quantity
        ]
    );

    useEffect(() => {
        trackViewItemEvent(
            quantity,
            () => pushGoogleAnalyticsDataCallback('view_item'),
            priceSheetItemId,
            initialPriceSheetItemRef.current?.id,
            selectedPriceSheetItem?.id,
            digitalItemSelected ? parseInt(digitalItemSelected?.id) : undefined,
            isDigitalDownload
        );
    }, [
        selectedPriceSheetItem,
        quantity,
        pushGoogleAnalyticsDataCallback,
        priceSheetItemId,
        digitalItemSelected,
        isDigitalDownload
    ]);

    // TODO: Remove after diagnosing/resolving digital albums checkout issue.
    // We want to see data around the events where this occurs.
    const getDebugDataForDigitalAlbumCheckout = useCallback(() => {
        return {
            albumsDataResponseStatus: albumsDataResponse?.status,
            albumsList: albumsDataResponse?.items
                ? albumsDataResponse.items.map((item) => item.id)
                : [],
            initialImageOnSelectedGroupId,
            pathContext,
            photosInAlbumList: photosForAlbumSelect.map((photo) => photo.id)
        };
    }, [albumsDataResponse, initialImageOnSelectedGroupId, pathContext, photosForAlbumSelect]);

    const addAllGalleryPhotosDigitalItemToCart = () => {
        if (imageOnSelectedGroup && digitalItemSelected && selectedPriceSheetItem) {
            postDigitalItemToCart(imageOnSelectedGroup, priceSheetItemId, quantity);

            const userSelectedProduct = convertIBoundItemToIPhotoConfig(
                digitalItemSelected,
                selectedPriceSheetItem,
                imageOnSelectedGroup,
                quantity
            );

            if (userSelectedProduct) {
                const userSelectedProducts = [userSelectedProduct];
                setUserSelectedProducts(userSelectedProducts);
            }
        }
    };

    const addAllAlbumPhotosDigitalItemToCart = () => {
        if (imageOnSelectedGroup && digitalItemSelected && selectedAlbumId) {
            postDigitalItemToCart(
                imageOnSelectedGroup,
                priceSheetItemId,
                quantity,
                selectedAlbumId,
                getDebugDataForDigitalAlbumCheckout()
            );

            const userSelectedProduct = convertIBoundItemToIPhotoConfig(
                digitalItemSelected,
                selectedPriceSheetItem,
                imageOnSelectedGroup,
                quantity
            );

            if (userSelectedProduct) {
                const userSelectedProducts = [userSelectedProduct];
                setUserSelectedProducts(userSelectedProducts);
            }
        }
    };

    const handleSingleDigitalItem = () => {
        if (digitalItemSelected && selectedPriceSheetItem) {
            const singleDigitalPriceSheetItem = convertIBoundItemToIPriceSheetItem(
                digitalItemSelected,
                selectedPriceSheetItem
            );
            setSelectedPriceSheetItem(singleDigitalPriceSheetItem);
        }
    };

    useEffect(() => {
        if (postToCartResponse) {
            if (postToCartResponse.statusText === 'success') {
                const numCartItems = userSelectedProducts.reduce(
                    (accumulatedQuantity, userSelectedProduct) => {
                        return accumulatedQuantity + userSelectedProduct.quantity;
                    },
                    0
                );
                updateUserCartState(numCartItems);

                pushGoogleAnalyticsDataCallback('add_to_cart');
                goToPage(PageIndex.AddedToCart, 'Added to Cart');
                onEmailCaptureModalClose();
            } else if (
                postToCartResponse.statusText === 'form_errors' &&
                postToCartResponse.need_email
            ) {
                openEmailCaptureModal();
            } else {
                openAddToCartErrorModal();
            }
        }
    }, [
        goToPage,
        onEmailCaptureModalClose,
        openAddToCartErrorModal,
        openEmailCaptureModal,
        postToCartResponse,
        pushGoogleAnalyticsDataCallback,
        userSelectedProducts,
        updateUserCartState
    ]);

    const productOptionsButtonSubmitHandler = () => {
        if (isDigitalItem && digitalItemSelected?.is_event) {
            addAllGalleryPhotosDigitalItemToCart();
            return;
        }

        if (isDigitalItem && digitalItemSelected?.is_album) {
            addAllAlbumPhotosDigitalItemToCart();
            return;
        }

        if (isDigitalItem && digitalItemSelected?.is_individual) {
            handleSingleDigitalItem();
            openSelectPhotosModal();
            return;
        }

        openSelectPhotosModal();
    };

    const handleClose = () => {
        setUserSelectedPhotos([]);
        setUserSelectedProducts([]);
        onClose();
    };

    const emailCaptureActionFunction = useCallback(() => {
        if (imageOnSelectedGroup) {
            postDigitalItemToCart(
                imageOnSelectedGroup,
                priceSheetItemId,
                quantity,
                selectedAlbumId,
                selectedAlbumId ? getDebugDataForDigitalAlbumCheckout() : undefined
            );
        }
    }, [
        getDebugDataForDigitalAlbumCheckout,
        imageOnSelectedGroup,
        postDigitalItemToCart,
        priceSheetItemId,
        quantity,
        selectedAlbumId
    ]);

    if (!imageOnSelectedGroup || !initialPriceSheetItem) {
        return null;
    }

    const isFramedPriceSheetGroup =
        selectedPriceSheetGroup && isFramedCategory(selectedPriceSheetGroup);

    const sizeOptionsForRadioGrid =
        productSizes?.map((productSize) => {
            return { name: productSize.size, value: productSize.priceSheetSubgroupId };
        }) ?? [];

    return (
        <Flex
            alignItems={isLargeScreen ? 'normal' : 'center'}
            flexDirection={isLargeScreen ? 'row' : 'column'}
            gap="30px"
        >
            <SelectPhotosModal isOpen={isOpen} onClose={handleClose} startingQuantity={quantity} />
            <EmailCaptureModal
                actionFunction={emailCaptureActionFunction}
                isActionLoading={isPostToCartLoading}
                isOpen={isEmailCaptureModalOpen}
                onClose={onEmailCaptureModalClose}
            />
            <AddToCartErrorModal
                isOpen={isAddToCartErrorModalOpen}
                onClose={onAddToCartErrorModalClose}
            />
            <ProductImages
                allowCropping={false}
                crop={selectedPriceSheetItemCrop}
                image={imageOnSelectedGroup}
                maxWidth="572px"
                onCropEdit={noop}
                priceSheetItem={selectedPriceSheetItem ?? initialPriceSheetItem}
                width="100%"
            />
            <Flex
                as="form"
                onSubmit={handleSubmit(() => {
                    productOptionsButtonSubmitHandler();
                })}
                direction="column"
                width={isLargeScreen ? '572px' : '100%'}
            >
                {hadInitialPriceSheetItem && (
                    <Text
                        as="sup"
                        color="brand.primary"
                        display="block"
                        fontFamily="open-sans, sans-serif"
                        fontSize="xs"
                        marginBottom="-0.5em"
                        position="relative"
                        top="-0.5em"
                    >
                        {initialPriceSheetItem.group.name}
                    </Text>
                )}
                <GalleryHeading
                    data-testid="gallery-heading"
                    size="md"
                    text={getHeadingText()}
                    variant="primary"
                />
                <Text
                    data-testid="select-product-options-price"
                    fontFamily="open-sans, sans-serif"
                    fontSize="md"
                    marginBottom="8px"
                >
                    {$(orderPrice)}
                </Text>
                {selectedPriceSheetItem?.group.description && (
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: selectedPriceSheetItem.group.description
                        }}
                        data-testid="select-product-options-description"
                        fontFamily="open-sans, sans-serif"
                        fontSize="md"
                    />
                )}
                {isFramedPriceSheetGroup ? (
                    <FrameOptionsGroup
                        priceSheetGroup={selectedPriceSheetGroup}
                        setValue={setValue}
                        watch={watch}
                    />
                ) : (
                    <>
                        {sizeOptionsForRadioGrid.length > 0 && !hadInitialPriceSheetItem && (
                            <RadioGroupGrid
                                defaultValue={defaultValues?.priceSheetSubgroupId}
                                fieldName="priceSheetSubgroupId"
                                label={t('size')}
                                onChange={(fieldName, value) =>
                                    setValue(
                                        fieldName as 'priceSheetSubgroupId' | 'priceSheetItemId',
                                        value
                                    )
                                }
                                options={sizeOptionsForRadioGrid}
                            />
                        )}
                        <OptionSelect
                            defaultPriceSheetItemId={defaultValues?.priceSheetItemId}
                            isDigitalDownload={isDigitalDownload}
                            priceSheetGroup={selectedPriceSheetGroup}
                            priceSheetItem={selectedPriceSheetItem}
                            selectedPriceSheetItem={selectedPriceSheetItem ?? undefined}
                            setValue={setValue}
                            spFulfilledDigitalItems={spFulfilledDigitalItems}
                            watch={watch}
                        />
                    </>
                )}
                {shouldShowAlbumSelect && albumsDataResponse && (
                    <AlbumSelect
                        albums={albumsDataResponse.items}
                        isDataLoading={areAlbumsLoading || arePhotosLoading}
                        selectedAlbumId={selectedAlbumId}
                        updateSelectedAlbumId={setSelectedAlbumId}
                    />
                )}
                {(isNonDownloadableDigitalItem || isCanvasItem) && (
                    <QuantityStepper setValue={setValue} watch={watch} />
                )}
                <Button
                    data-testid="select-product-options-submit-button"
                    type="submit"
                    fullWidth
                    isLoading={areAlbumsLoading || arePhotosLoading}
                    text={
                        isDigitalItem
                            ? t('buyDigitals')
                            : selectedPriceSheetGroup?.groupType === 'canvas'
                            ? t('buyCanvas')
                            : t('selectPhotos')
                    }
                    variant="primary"
                    marginTop="20px"
                    maxWidth={isLargeScreen ? '286px' : '100%'}
                />
                <Divider />
                <ProductDescriptionAccordion
                    productDescription={selectedPriceSheetItem?.description}
                />
            </Flex>
        </Flex>
    );
};

export default SelectProductOptionsPage;
