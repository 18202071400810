import React, { FC, useCallback } from 'react';
import { IconButton, useBreakpointValue, useTheme } from '@chakra-ui/react';
import { ArrowUp } from 'react-feather';
import { scrollTo } from 'Sp/SmoothScroll';

interface Props {
    /** True only if this button should be hidden */
    isHidden?: boolean;
    /** Angular translation service */
    translateFilter: SpAngularJs.ITranslateFilter;
}

const buttonSize = '56px';

const BackToTopButton: FC<Props> = ({ isHidden, translateFilter: translate }) => {
    const theme = useTheme();
    const spacing = useBreakpointValue({ lg: '32px' }) ?? '16px';

    const handleClick = useCallback(() => {
        scrollTo(0);
    }, []);

    return (
        <IconButton
            aria-label={translate('backToTop')}
            background="background"
            border="none"
            bottom={`calc(-${buttonSize} - ${spacing} - ${spacing})`}
            boxShadow={`0 2px 2px 2px var(--chakra-colors-boxShadow)`}
            color="brand.primary"
            filter={theme.filters?.dropShadow}
            height={buttonSize}
            icon={<ArrowUp />}
            isRound
            marginY={spacing}
            onClick={handleClick}
            opacity={isHidden ? 0 : 1}
            position="absolute"
            right={spacing}
            transition="opacity 0.5s"
            width={buttonSize}
            _focus={{
                boxShadow: 'none',
                color: 'brand.primary'
            }}
            _focusVisible={{
                boxShadow: 'outline'
            }}
            _hover={{
                color: 'brand.primary'
            }}
        />
    );
};

export default BackToTopButton;
