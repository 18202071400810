import React, { FC } from 'react';
import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
import Button from 'ts/common/components/gallery/Button';
import CoverPhoto from 'ts/common/components/gallery/CoverPhoto';
import GalleryHeading from 'ts/common/components/gallery/GalleryHeading';
import StudioHeading from 'ts/common/components/gallery/StudioHeading';
import { ILayoutProps, contentAnimation, coverAnimation } from './BaseLayout';

const MarqueeLayout: FC<ILayoutProps> = ({
    galleryTitle,
    brandName,
    coverPhoto,
    coverPhotoUrl,
    buttonText,
    buttonTextLarge,
    colorScheme,
    onClick
}) => {
    return (
        <Box position="absolute" width="full" height="full" onClick={onClick}>
            <Box
                position="absolute"
                width="full"
                height="full"
                background={colorScheme === 'dark' ? 'whiteAlpha.500' : 'blackAlpha.500'}
                opacity={0}
                animation={contentAnimation}
            >
                <Flex
                    alignItems="center"
                    flexDirection="column"
                    gap={6}
                    height="full"
                    paddingX={4}
                    paddingY={6}
                    textAlign="center"
                    width="full"
                >
                    <Flex alignItems="flex-start" flex={1}>
                        <StudioHeading text={brandName} variant={colorScheme} />
                    </Flex>
                    {useBreakpointValue({
                        base: (
                            <GalleryHeading size="sm" text={galleryTitle} variant={colorScheme} />
                        ),
                        lg: (
                            <Flex alignItems="center" flexDirection="column" gap={7}>
                                <GalleryHeading
                                    size="lg"
                                    text={galleryTitle}
                                    variant={colorScheme}
                                />
                                <Button
                                    text={buttonText}
                                    textLarge={buttonTextLarge}
                                    variant={`secondary ${colorScheme}` as const}
                                />
                            </Flex>
                        )
                    })}
                    <Flex alignItems="flex-end" flex={1} width="full">
                        {useBreakpointValue({
                            base: (
                                <Button
                                    fullWidth
                                    text={buttonText}
                                    textLarge={buttonTextLarge}
                                    variant={`secondary ${colorScheme}` as const}
                                />
                            ),
                            lg: <Box />
                        })}
                    </Flex>
                </Flex>
            </Box>

            <Box width="full" height="full" animation={coverAnimation}>
                <CoverPhoto coverPhoto={coverPhoto} coverPhotoUrl={coverPhotoUrl} />
            </Box>
        </Box>
    );
};

export default MarqueeLayout;
