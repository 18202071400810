import React, { useCallback, useState } from 'react';
import { IBoundItem } from 'ts/client/gallery/components/types';
import { SlidesSidePanel } from 'ts/common/components';
import { noop } from 'ts/common/utils';
import { CardTemplatePreviewSlide, Header } from './select-card-modal';
import SelectCardTemplateSlide from './select-card-modal/SelectCardTemplateSlide';

interface ISelectCardModal {
    isOpen: boolean;
    onClose: () => void;
}

const SelectCardModal: React.FC<ISelectCardModal> = ({ isOpen, onClose }) => {
    const [goToSlide, setGoToSlide] = useState<(index: number) => void>(noop);
    const [previouslySelectedCardProduct, setPreviouslySelectedCardProduct] =
        useState<IBoundItem>();

    const controlSubscribe = useCallback((goToSlide) => {
        setGoToSlide(() => goToSlide);
    }, []);
    return (
        <SlidesSidePanel
            controlSubscribe={controlSubscribe}
            onClose={onClose}
            isOpen={isOpen}
            slides={[
                {
                    content: (
                        <SelectCardTemplateSlide
                            goToCardTemplatePreviewSlide={goToSlide}
                            previouslySelectedCardProduct={previouslySelectedCardProduct}
                            setPreviouslySelectedCardProduct={setPreviouslySelectedCardProduct}
                        />
                    ),
                    headingContent: <Header onClose={onClose} showCloseButton={true} />,
                    hideCloseButton: true
                },
                {
                    content: <CardTemplatePreviewSlide />,
                    headingContent: <Header onClose={onClose} showCloseButton={false} />,
                    hideCloseButton: true
                }
            ]}
            size="full"
        />
    );
};

export enum SlideIndex {
    SelectCardTemplate = 0,
    PreviewCardTemplate = 1
}

export default SelectCardModal;
