import React, {
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    createContext,
    useContext,
    useState
} from 'react';
import { noop } from 'ts/common/utils';

interface IStoreModalContext {
    arePhotosLoading: boolean;
    goToPreviousSlide: (index: number) => void;
    goToSlide: (index: number) => void;
    setArePhotosLoading: Dispatch<SetStateAction<boolean>>;
}

const StoreModal = createContext<IStoreModalContext>({
    arePhotosLoading: false,
    goToPreviousSlide: noop,
    goToSlide: noop,
    setArePhotosLoading: () => noop
});

type IStoreModalProviderProps = PropsWithChildren<
    Pick<IStoreModalContext, 'goToSlide' | 'goToPreviousSlide'>
>;

export const StoreModalProvider: React.FC<IStoreModalProviderProps> = ({
    children,
    goToPreviousSlide,
    goToSlide
}) => {
    const [arePhotosLoading, setArePhotosLoading] = useState<boolean>(false);

    return (
        <StoreModal.Provider
            value={{
                arePhotosLoading,
                goToPreviousSlide,
                goToSlide,
                setArePhotosLoading
            }}
        >
            {children}
        </StoreModal.Provider>
    );
};

export const useStoreModal = () => {
    return useContext(StoreModal);
};
