import React, { FC, useCallback, useState } from 'react';
import {
    Flex,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    useBreakpointValue
} from '@chakra-ui/react';
import { Mail } from 'react-feather';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import Button from 'ts/common/components/gallery/Button';
import FormErrorMessage from 'ts/common/components/gallery/FormErrorMessage';
import Modal from 'ts/common/components/gallery/Modal';
import ModalHeader from 'ts/common/components/gallery/ModalHeader';
import { modalButtonWidth } from 'ts/common/constants/theme/common';
import { VALID_EMAIL_REGEX } from 'ts/common/hooks/useClientCredentials';
import useToast from 'ts/common/hooks/useToast';
import FormSubmitErrors from './FormSubmitErrors';

export type EmailFavoritesSubmit = (
    fromName: string,
    toEmail: string,
    message: string,
    onSuccess: () => unknown,
    onError: (errors: Record<string, Record<string, string>>) => unknown
) => void;

type EmailFavoritesFormProps = {
    /** Callback for the Email Favorites Modal submit button */
    onSubmit: EmailFavoritesSubmit;
    /** The Angular translation service */
    translate: SpAngularJs.ITranslateFilter;
    /** Callback for the modal's closing event */
    onClose: () => void;
};

type EmailFavoritesModalProps = EmailFavoritesFormProps & {
    /** True if the modal open */
    isOpen: boolean;
};

const EmailFavoritesForm: FC<EmailFavoritesFormProps> = ({ onSubmit, onClose, translate }) => {
    const [submitErrors, setSubmitErrors] = useState<Record<string, Record<string, string>>>({});

    const favoritesSentToast = useToast({ type: 'favorites_sent', translateFilter: translate });

    const isButtonFullWidth = useBreakpointValue({ base: true, lg: false });
    const textareaHeight = useBreakpointValue({ base: undefined, lg: '300px' });

    const {
        formState: { errors, isSubmitting, isValid },
        handleSubmit,
        register
    } = useForm({ mode: 'onTouched' });

    const handleFormSubmission = useCallback(
        ({ fromName, toEmail, message }) => {
            return new Promise<void>((resolve) => {
                onSubmit(
                    fromName,
                    toEmail,
                    message,
                    () => {
                        resolve();
                        onClose();
                        favoritesSentToast();
                    },
                    (errors) => {
                        resolve();
                        setSubmitErrors(errors);
                    }
                );
            });
        },
        [favoritesSentToast, onClose, onSubmit]
    );

    return (
        <Flex height="full" flexDirection="column">
            <ModalHeader
                modalIcon={Mail}
                headerText={translate('shareFavoritesLabel')}
                headerSubText={translate('shareFavoritesSubtext')}
            />
            <Flex
                as="form"
                onSubmit={handleSubmit(handleFormSubmission as SubmitHandler<FieldValues>)}
                flexDirection="column"
                justifyContent="space-between"
                flexGrow={1}
                paddingTop="20px"
            >
                <Flex flexDirection="column" flexGrow={1}>
                    <FormControl isInvalid={Boolean(errors.fromName)} isRequired>
                        <FormLabel>{translate('favoritesFromNameLabel')}</FormLabel>
                        <Input
                            {...register('fromName', {
                                required: translate('favoritesFromNameRequired')
                            })}
                        />
                        {errors.fromName && (
                            <FormErrorMessage>{errors.fromName.message as string}</FormErrorMessage>
                        )}
                        <FormSubmitErrors errors={submitErrors['from_name']} />
                    </FormControl>

                    <FormControl isInvalid={Boolean(errors.toEmail)} isRequired>
                        <FormLabel>{translate('favoritesToEmailLabel')}</FormLabel>
                        <Input
                            {...register('toEmail', {
                                required: translate('emailAddressInvalid'),
                                pattern: {
                                    value: VALID_EMAIL_REGEX,
                                    message: translate('emailAddressInvalid')
                                }
                            })}
                        />
                        {errors.toEmail && (
                            <FormErrorMessage>{errors.toEmail.message as string}</FormErrorMessage>
                        )}
                        <FormSubmitErrors errors={submitErrors['to_email']} />
                    </FormControl>

                    <FormControl
                        isInvalid={Boolean(errors.message)}
                        flexGrow={1}
                        display="flex"
                        flexDirection="column"
                    >
                        <FormLabel>{translate('favoritesMessageLabel')}</FormLabel>
                        <Textarea
                            {...register('message', { required: false })}
                            flexGrow={1}
                            height={textareaHeight}
                            maxHeight="300px"
                        />
                        {errors.message && (
                            <FormErrorMessage>{errors.message.message as string}</FormErrorMessage>
                        )}
                        <FormSubmitErrors errors={submitErrors.message} />
                    </FormControl>
                </Flex>

                <Button
                    isDisabled={!isValid}
                    fullWidth={isButtonFullWidth}
                    minWidth={modalButtonWidth}
                    alignSelf="center"
                    isLoading={isSubmitting}
                    text={translate('send')}
                    textLarge={translate('send')}
                    variant="primary"
                    type="submit"
                />
            </Flex>
        </Flex>
    );
};

const EmailFavoritesModal = ({
    onSubmit,
    isOpen,
    onClose,
    translate
}: EmailFavoritesModalProps) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <EmailFavoritesForm onSubmit={onSubmit} onClose={onClose} translate={translate} />
        </Modal>
    );
};

export default EmailFavoritesModal;
