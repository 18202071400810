export default [
    '$scope',
    'spFreeDigitals',
    function FreeDigitalsHeading($scope, spFreeDigitals) {
        var pendingDownloadData = spFreeDigitals.getPendingDownloadData();

        $scope.isTryingToDownloadAll = spFreeDigitals.isTryingToDownloadAll();
        $scope.isTryingToDownloadAlbum = spFreeDigitals.isTryingToDownloadAlbum();
        $scope.isTryingToDownloadPhoto = spFreeDigitals.isTryingToDownloadPhoto();

        if (pendingDownloadData) {
            $scope.albumName = pendingDownloadData.albumName;
        }
    }
];
