import React, { FC } from 'react';
import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
import Button from 'ts/common/components/gallery/Button';
import CoverPhoto from 'ts/common/components/gallery/CoverPhoto';
import GalleryHeading from 'ts/common/components/gallery/GalleryHeading';
import StudioHeading from 'ts/common/components/gallery/StudioHeading';
import StudioLogo from '../components/StudioLogo';
import { ILayoutProps, contentAnimation, coverAnimation } from './BaseLayout';

const FoundationLayout: FC<ILayoutProps> = ({
    galleryTitle,
    brandName,
    coverPhoto,
    coverPhotoUrl,
    buttonText,
    eventBrandLogo,
    buttonTextLarge,
    logoAltText,
    colorScheme,
    onClick
}) => {
    const galleryHeadingSize =
        useBreakpointValue({ base: 'sm' as const, lg: 'md' as const }) ?? 'sm';

    const isFullWidthButton = useBreakpointValue({ base: true, lg: false });
    const containerDirection = useBreakpointValue({ base: 'column' as const, lg: 'row' as const });
    const galleryHeadingContainerPaddingBottom = useBreakpointValue({ base: 6, lg: 0 });
    const containerPaddingX = useBreakpointValue({ base: 4, lg: 6 });
    const galleryHeadingContainerAlign = useBreakpointValue({ base: 'flex-start', lg: 'center' });

    return (
        <Box position="absolute" width="full" height="full" onClick={onClick}>
            <Flex
                paddingX={containerPaddingX}
                paddingY="6"
                position="absolute"
                width="full"
                bottom="0"
                justifyContent="space-between"
                alignItems={galleryHeadingContainerAlign}
                flexDirection={containerDirection}
                background={colorScheme === 'dark' ? 'gray.700' : 'white'}
                animation={contentAnimation}
                opacity={0}
            >
                <Flex alignItems="center" paddingBottom={galleryHeadingContainerPaddingBottom}>
                    {eventBrandLogo && (
                        <Box paddingRight={6}>
                            <StudioLogo
                                logo={eventBrandLogo}
                                logoAltText={logoAltText}
                                logoSize="sm"
                            />
                        </Box>
                    )}
                    <Flex flexDirection="column" justifyContent="center">
                        <GalleryHeading
                            size={galleryHeadingSize}
                            text={galleryTitle}
                            variant="primary"
                        />
                        <StudioHeading text={brandName} variant="primary" />
                    </Flex>
                </Flex>
                <Button
                    fullWidth={isFullWidthButton}
                    text={buttonText}
                    textLarge={buttonTextLarge}
                    variant="primary"
                />
            </Flex>

            <Box width="full" height="full" animation={coverAnimation}>
                <CoverPhoto coverPhoto={coverPhoto} coverPhotoUrl={coverPhotoUrl} />
            </Box>
        </Box>
    );
};

export default FoundationLayout;
