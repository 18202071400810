/**
 * This function returns an array of the allowed digital options
 * during the Buy All flow for a given digital price sheet item.
 * It takes into consideration the current context.
 *
 * @param priceSheetItem The price sheet item containing the digital options.
 * @param context If the visitor is in an album, marked photo context, or none at all.
 * @returns Array of allowed digital options.
 */
export function getAllowedDigitalOptionsInBuyAll(
    priceSheetItem: SpApi.Client.IPriceSheetItem,
    context: 'album' | 'marked' | 'none'
): Array<'is_individual' | 'is_album' | 'is_event' | 'none'> | null {
    const hasIndividualDownload =
        priceSheetItem.individualPhotoDownload?.isDigitalDownloadForSale ?? false;
    const hasAllPhotosInAlbumDownload =
        priceSheetItem.allAlbumPhotosDownload?.isDigitalDownloadForSale ?? false;
    const hasAllPhotosInGalleryDownload =
        priceSheetItem.allGalleryPhotosDownload?.isDigitalDownloadForSale ?? false;

    let conditions: {
        condition: boolean;
        allowed: ('is_individual' | 'is_album' | 'is_event' | 'none')[];
    }[];

    if (context === 'album') {
        conditions = [
            {
                condition:
                    hasIndividualDownload &&
                    !hasAllPhotosInAlbumDownload &&
                    !hasAllPhotosInGalleryDownload,
                allowed: ['is_individual']
            },
            {
                condition:
                    !hasIndividualDownload &&
                    hasAllPhotosInAlbumDownload &&
                    !hasAllPhotosInGalleryDownload,
                allowed: ['is_album']
            },
            {
                condition:
                    !hasIndividualDownload &&
                    !hasAllPhotosInAlbumDownload &&
                    hasAllPhotosInGalleryDownload,
                allowed: ['is_event']
            },
            {
                condition:
                    hasIndividualDownload &&
                    hasAllPhotosInAlbumDownload &&
                    !hasAllPhotosInGalleryDownload,
                allowed: ['is_album']
            },
            {
                condition:
                    !hasIndividualDownload &&
                    hasAllPhotosInAlbumDownload &&
                    hasAllPhotosInGalleryDownload,
                allowed: ['is_album', 'is_event']
            },
            {
                condition:
                    hasIndividualDownload &&
                    !hasAllPhotosInAlbumDownload &&
                    hasAllPhotosInGalleryDownload,
                allowed: ['is_event']
            },
            {
                condition:
                    hasIndividualDownload &&
                    hasAllPhotosInAlbumDownload &&
                    hasAllPhotosInGalleryDownload,
                allowed: ['is_album', 'is_event']
            }
        ];
    } else if (context === 'marked') {
        conditions = [
            {
                condition: hasIndividualDownload && !hasAllPhotosInGalleryDownload,
                allowed: ['is_individual']
            },
            {
                condition: !hasIndividualDownload && hasAllPhotosInGalleryDownload,
                allowed: ['is_event']
            },
            {
                condition: hasIndividualDownload && hasAllPhotosInGalleryDownload,
                allowed: ['is_individual', 'is_event']
            },
            {
                condition: !hasIndividualDownload && !hasAllPhotosInGalleryDownload,
                allowed: ['none']
            }
        ];
    } else {
        conditions = [
            {
                condition:
                    hasIndividualDownload &&
                    !hasAllPhotosInAlbumDownload &&
                    !hasAllPhotosInGalleryDownload,
                allowed: ['is_individual']
            },
            {
                condition:
                    !hasIndividualDownload &&
                    hasAllPhotosInAlbumDownload &&
                    !hasAllPhotosInGalleryDownload,
                allowed: ['none']
            },
            {
                condition:
                    !hasIndividualDownload &&
                    !hasAllPhotosInAlbumDownload &&
                    hasAllPhotosInGalleryDownload,
                allowed: ['is_event']
            },
            {
                condition:
                    hasIndividualDownload &&
                    hasAllPhotosInAlbumDownload &&
                    !hasAllPhotosInGalleryDownload,
                allowed: ['none']
            },
            {
                condition:
                    !hasIndividualDownload &&
                    hasAllPhotosInAlbumDownload &&
                    hasAllPhotosInGalleryDownload,
                allowed: ['is_event']
            },
            {
                condition:
                    hasIndividualDownload &&
                    !hasAllPhotosInAlbumDownload &&
                    hasAllPhotosInGalleryDownload,
                allowed: ['is_event']
            },
            {
                condition:
                    hasIndividualDownload &&
                    hasAllPhotosInAlbumDownload &&
                    hasAllPhotosInGalleryDownload,
                allowed: ['is_event']
            }
        ];
    }

    const allowedCondition = conditions.find((condition) => condition.condition);

    if (allowedCondition?.allowed.includes('none')) {
        return null;
    }

    return allowedCondition?.allowed ?? null;
}
