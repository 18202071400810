import type { IImage } from 'ts/client/gallery/components';
import type { IPhoto, IPhotoDimensions } from 'ts/client/types';

interface IMasonryColumn {
    height: number;
    left: number;
}

interface IMasonryRow {
    width: number;
    photoDimensionsArray: IPhotoDimensions[];
}

interface IGetPhotoDimensionsProps {
    commonDimension: number;
    containerWidth: number;
    layoutType: 'hmason' | 'vmason';
    padding: number;
    photos: IImage[] | IPhoto[];
}

/** Generates a Map of photo ids to photo dimensions, to be rendered in a photo grid */
const getPhotoDimensionsMap = ({
    commonDimension,
    containerWidth,
    layoutType,
    padding,
    photos
}: IGetPhotoDimensionsProps) => {
    const photoDimensionsMap = new Map<IPhoto['id'], IPhotoDimensions>();

    if (layoutType === 'hmason') {
        const rows: IMasonryRow[] = [];

        let row: IMasonryRow | undefined;

        photos.forEach((photo: IImage | IPhoto) => {
            if (!row || row.width >= containerWidth) {
                rows[rows.length] = row = {
                    width: 0,
                    photoDimensionsArray: []
                };
            }

            const photoDimensions: IPhotoDimensions = {
                top: 0,
                left: 0,
                width: commonDimension * (photo.width / photo.height),
                height: commonDimension
            };

            row.width += photoDimensions.width;
            row.photoDimensionsArray.push(photoDimensions);
            photoDimensionsMap.set(photo.id, photoDimensions);
        });

        let top = 0;

        // Rescale row to fit
        rows.forEach((row) => {
            const overflowPixels =
                row.width - containerWidth + (row.photoDimensionsArray.length - 1) * padding;

            const overflowPercentage = Math.min(1, 1 - overflowPixels / row.width);
            const height = commonDimension * overflowPercentage;

            let left = 0;

            // rescale each photo, subtracting its share of overflow pixels
            row.photoDimensionsArray.forEach((photoDimensions) => {
                photoDimensions.top = top;
                photoDimensions.left = left;
                photoDimensions.height = height;
                photoDimensions.width = photoDimensions.width * overflowPercentage;

                left += photoDimensions.width + padding;
            });

            top += height + padding;
        });
    }

    if (layoutType === 'vmason') {
        const maxColumnWidth = commonDimension + padding;
        const proposedColumnCount = Math.ceil(containerWidth / maxColumnWidth);
        const columnCount = Math.max(2, proposedColumnCount);

        const containerImageArea = containerWidth - padding * (columnCount - 1);
        const columnWidth = Math.round(containerImageArea / columnCount);
        const columns: IMasonryColumn[] = [];

        const findShortestColumn = () => {
            return columns.reduce((previousIndex, column, index) => {
                if (columns[previousIndex].height <= column.height) {
                    return previousIndex;
                }

                return index;
            }, 0);
        };

        let columnLeft = 0;

        // Manufacture columns
        for (let index = 0; index < columnCount; index++) {
            columns.push({
                height: 0,
                left: columnLeft
            });

            columnLeft += columnWidth + padding;
        }

        // Sort photos into columns
        photos.forEach((photo) => {
            const targetColumnIndex = findShortestColumn();
            const targetColumn = columns[targetColumnIndex];

            const photoDimensions: IPhotoDimensions = {
                top: targetColumn.height,
                left: targetColumn.left,
                width: columnWidth,
                height: Math.round((columnWidth / photo.width) * photo.height)
            };

            photoDimensionsMap.set(photo.id, photoDimensions);
            targetColumn.height += photoDimensions.height + padding;
        });
    }

    return photoDimensionsMap;
};

export default getPhotoDimensionsMap;
