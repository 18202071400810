import marketingBannerTemplate from '../../templates/components/sp-marketing-banner.nghtml';

/**
 * @ngdoc component
 * @name sp.client.gallery.component:spMarketingBanner
 * @requires $window
 *
 * @description
 * Displays a banner with descriptive text that can have a link associated with
 * it that can be internal or external.
 *
 * @param {object} eventBannerSettings The event settings object to check if it has marketing banner data
 */
export default {
    bindings: {
        eventBannerSettings: '<'
    },
    template: marketingBannerTemplate,
    controller: [
        '$window',
        function spMarketingBanner($window) {
            const $ctrl = this;

            $ctrl.goToBannerLink = goToBannerLink;

            function goToBannerLink() {
                const { bannerUrl } = $ctrl.eventBannerSettings;

                if (bannerUrl) {
                    $window.open(bannerUrl);
                }
            }
        }
    ]
};
