import React, { Attributes, FC } from 'react';
import { Box, Checkbox, Flex, FlexProps, Text, useBreakpointValue } from '@chakra-ui/react';
import { useTranslate } from 'ts/common/hooks';

interface Props extends Attributes {
    isSelected: boolean;
    onClick?: FlexProps['onClick'];
}

const SelectPhotoOverlay: FC<Props> = ({ isSelected, onClick }) => {
    const t = useTranslate('client.store.selectPhotosModal');
    const isLargeScreen = useBreakpointValue({ base: false, lg: true });

    return (
        <Flex
            data-testid="select-photo-overlay"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
            position="relative"
            role="group"
            borderStyle="solid"
            borderWidth="4px"
            borderColor={isSelected ? 'brand.primaryOverlay' : 'transparent'}
            onClick={onClick}
        >
            <Flex justifyContent="space-between" zIndex={1}>
                <Box padding="4px">
                    <Checkbox isChecked={isSelected} variant="round" size="xl" readOnly />
                </Box>
            </Flex>
            {isLargeScreen && !isSelected && (
                <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    position="absolute"
                    height="100%"
                    width="100%"
                    top={0}
                    left={0}
                    background="blackAlpha.500"
                    opacity={0}
                    _groupHover={{ opacity: 1 }}
                    transition="0.25s opacity"
                >
                    <Text color="white">{t('select')}</Text>
                </Flex>
            )}
        </Flex>
    );
};

export default SelectPhotoOverlay;
