import React, { ComponentProps, FC, useMemo } from 'react';
import { Flex, Heading, useBreakpointValue } from '@chakra-ui/react';
import { Heart, Search } from 'react-feather';
import CircleIcon from 'ts/common/components/gallery/CircleIcon';
import {
    CartEnabledIcon,
    EyeOffEnabledIcon,
    ImageEnabledIcon,
    StoreIcon,
    TagEnabledIcon
} from 'ts/common/components/icons';

export const pages = [
    'Home',
    'Album',
    'Favorites',
    'Hidden',
    'Tag',
    'Search',
    'Store',
    'Cart'
] as const;

interface Props {
    /** The unique name associated with the current page */
    page: typeof pages[number];
    /** The Angular translation service */
    translateFilter: SpAngularJs.ITranslateFilter;
}

const EmptyState: FC<Props> = ({ page, translateFilter: translate }) => {
    const icon = useMemo(
        () =>
            ({
                Home: ImageEnabledIcon,
                Album: ImageEnabledIcon,
                // eslint-disable-next-line react/no-unstable-nested-components
                Favorites: function FavoritesIcon(props: ComponentProps<typeof Heart>) {
                    return <Heart fill="currentColor" {...props} />;
                },
                Hidden: EyeOffEnabledIcon,
                Tag: TagEnabledIcon,
                // eslint-disable-next-line react/no-unstable-nested-components
                Search: function SearchIcon(props: ComponentProps<typeof Search>) {
                    return <Search fill="currentColor" {...props} />;
                },
                Store: StoreIcon,
                Cart: CartEnabledIcon
            }[page]),
        [page]
    );

    const title = useMemo(
        () =>
            translate(
                {
                    Home: 'noPhotosInGallery',
                    Album: 'noPhotos',
                    Favorites: 'noFavorites',
                    Hidden: 'noHiddenPhotos',
                    Tag: 'noTagPhotos',
                    Search: 'searchNoResults',
                    Store: 'noStore',
                    Cart: 'noItemsInCart'
                }[page]
            ),
        [page, translate]
    );

    return (
        <Flex
            alignItems="center"
            data-testid="empty-state"
            flexDirection="column"
            gap="30px"
            paddingY={useBreakpointValue({ base: '60px', lg: '120px' })}
        >
            <CircleIcon icon={icon} size="xl" />
            <Heading as="h3" size="lg" variant="primary">
                {title}
            </Heading>
        </Flex>
    );
};

export default EmptyState;
