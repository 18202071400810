import {
    CANVAS_EXHIBITION_TYPES,
    CANVAS_EXHIBITION_FOCAL_POINT_MAP,
    CANVAS_EXHIBITION_PHYSICAL_DIMENSIONS_MAP
} from 'Sp/Canvas';

/**
 * @ngdoc service
 * @name sp.client.gallery.service:spCanvasView
 *
 * @description
 * Provides helper functions for rendering perspective and shadow canvas views
 */
export default [
    function spCanvasViewService() {
        const service = this;

        Object.assign(service, {
            divideByPercent,
            getBoundsInches,
            getBoundsPixels,
            getExhibitionDimensions,
            getPerspectiveRenderingDetails,
            getScaleFactors,
            getShadowRenderingDetails,
            getTotalCropPercentages,
            getTranslateFactors,
            multiplyByPercent
        });

        return service;

        /**
         * @name divideByPercent
         * @private
         *
         * @description Helper function to divide a number by a percentage
         *
         * @param {number} number Target number to divide
         * @param {number} percent Target percentage to divide by
         *
         * @returns {number} Calculated number
         */
        function divideByPercent(number, percent) {
            return number * (100 / percent);
        }

        /**
         * @name multiplyByPercent
         * @private
         *
         * @description Helper function to multiply a number by a percentage
         *
         * @param {number} number Target number to multiply
         * @param {number} percent Target percentage to multiply by
         *
         * @returns {number} Calculated number
         */
        function multiplyByPercent(number, percent) {
            return number * (percent / 100);
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spCanvasView#getExhibitionDimensions
         * @methodOf sp.client.gallery.service:spCanvasView
         *
         * @description Get Physical Dimensions of the Exhibition Space
         *
         * @param {string} exhibition Which exhibition environment will the canvas be scaled to fit
         *     into? (CANVAS_EXHIBITION_TYPES)
         * @param {object} containerDimensions Represents the rendered image container dimensions
         * @param {number} containerDimensions.width Container width in pixels
         * @param {number} containerDimensions.height Container width in pixels
         * @param {number} containerDimensions.aspectRatio Ratio of container's width to its height
         *
         * @returns {object} exhibitionDimensions
         * @returns {object} exhibitionDimensions.totalExhibitionInches Uncropped Physical
         *     Exhibition Dimensions
         * @returns {number} exhibitionDimensions.totalExhibitionInches.width Uncropped Physical
         *     Exhibition Width in inches
         * @returns {number} exhibitionDimensions.totalExhibitionInches.height Uncropped Physical
         *     Exhibition Height in inches
         * @returns {number} exhibitionDimensions.totalExhibitionAspectRatio Aspect Ratio of the
         *     uncropped backdrop
         * @returns {boolean} exhibitionDimensions.fitBackdropToContainerWidth Are we fitting the
         *     backdrop to the container's width or height?
         * @returns {object} exhibitionDimensions.croppedExhibitionInches Cropped Physical
         *     Exhibition Dimensions
         * @returns {number} exhibitionDimensions.croppedExhibitionInches.width Cropped Physical
         *     Exhibition Width in Inches
         * @returns {number} exhibitionDimensions.croppedExhibitionInches.height Cropped Physical
         *     Exhibition Height in Inches
         * @returns {object} exhibitionDimensions.uncroppedBackdropPixels Dimensions of the
         *     uncropped backdrop as though it extended beyond the container
         * @returns {number} exhibitionDimensions.uncroppedBackdropPixels.width Uncropped backdrop
         *     width in pixels
         * @returns {number} exhibitionDimensions.uncroppedBackdropPixels.height Uncropped backdrop
         *     height in pixels
         */
        function getExhibitionDimensions(
            exhibition,
            { width: containerWidth, height: containerHeight, aspectRatio: containerAspectRatio }
        ) {
            const totalExhibitionInches = CANVAS_EXHIBITION_PHYSICAL_DIMENSIONS_MAP[exhibition];

            if (!totalExhibitionInches) {
                return null;
            }

            const {
                width: totalExhibitionWidthInches,
                height: totalExhibitionHeightInches
            } = totalExhibitionInches;
            const totalExhibitionAspectRatio =
                totalExhibitionWidthInches / totalExhibitionHeightInches;
            const fitBackdropToContainerWidth = totalExhibitionAspectRatio < containerAspectRatio;
            const croppedExhibitionWidthInches = fitBackdropToContainerWidth
                ? totalExhibitionWidthInches
                : totalExhibitionHeightInches * containerAspectRatio;
            const croppedExhibitionHeightInches = fitBackdropToContainerWidth
                ? totalExhibitionWidthInches / containerAspectRatio
                : totalExhibitionHeightInches;
            const croppedExhibitionInches = {
                width: croppedExhibitionWidthInches,
                height: croppedExhibitionHeightInches
            };
            const uncroppedBackdropWidth = fitBackdropToContainerWidth
                ? containerWidth
                : containerHeight * totalExhibitionAspectRatio;
            const uncroppedBackdropHeight = fitBackdropToContainerWidth
                ? containerWidth / totalExhibitionAspectRatio
                : containerHeight;
            const uncroppedBackdropPixels = {
                width: uncroppedBackdropWidth,
                height: uncroppedBackdropHeight
            };

            return {
                totalExhibitionInches,
                totalExhibitionAspectRatio,
                fitBackdropToContainerWidth,
                croppedExhibitionInches,
                uncroppedBackdropPixels
            };
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spCanvasView#getTotalCropPercentages
         * @methodOf sp.client.gallery.service:spCanvasView
         *
         * @description
         * Calculates the total crop percentages relative to the start and end percentages
         *
         * @param {object} crop Provided crop object where value coordinates are represented as
         *     percentages ex.
         * ```js
         *  {
         *      start: { x: 10, y: 10 },
         *      end: { x: 100, y: 100 }
         *  }
         * ```
         *
         * @returns {object} totalCropPercentages Crop data represented as percentages
         * @returns {number} totalCropPercentages.totalCropLeftPercent Percentage of distance from
         *     the left bounds to the start of the crop
         * @returns {number} totalCropPercentages.totalCropTopPercent Percentage of distance from
         *     the bounds top to the start of the crop
         * @returns {number} totalCropPercentages.totalCropWidthPercent Width percentage of the crop
         *     relative to the bounds
         * @returns {number} totalCropPercentages.totalCropHeightPercent Height percentage of the
         *     crop relative to the bounds
         */
        function getTotalCropPercentages(crop) {
            const {
                start: { x: startX, y: startY },
                end: { x: endX, y: endY }
            } = crop;

            return {
                totalCropLeftPercent: startX,
                totalCropTopPercent: startY,
                totalCropWidthPercent: endX - startX,
                totalCropHeightPercent: endY - startY
            };
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spCanvasView#getBoundsInches
         * @methodOf sp.client.gallery.service:spCanvasView
         *
         * @description Performs calculations in order to convert the crop percentages to real
         *     units (inches) using the target product bounds
         *
         * @param {boolean} isRotated Determine if the crop is portrait or landscape (portrait when
         *     true)
         * @param {object} bounds Bounds of the product
         * @param {number} bounds.depth Depth of the product in inches
         * @param {number} bounds.height Height of the product in inches
         * @param {number} bounds.width Width of the product in inches
         * @param {object} totalCropPercentages Crop data represented as percentages as calculated
         *     by spCanvasView#getTotalCropPercentages
         *
         * @returns {object} boundsInches Bounds calculations in inches
         * @returns {number} boundsInches.totalCropLeftInches Left crop distance in inches
         * @returns {number} boundsInches.totalCropTopInches Top crop distance in inches
         * @returns {number} boundsInches.bleedInches Bleed distance in inches
         * @returns {number} boundsInches.depthInches Depth distance in inches
         * @returns {number} boundsInches.cropWidthInches Cropped area width in inches
         * @returns {number} boundsInches.cropHeightInches Cropped area height in inches
         * @returns {number} boundsInches.fullImageWidthInches Image width in inches
         * @returns {number} boundsInches.fullImageHeightInches Image height in inches
         */
        function getBoundsInches(isRotated, bounds, totalCropPercentages) {
            // For now, the bleed is hardcoded to 0.625 inches, but this may be dynamic in the future
            const bleedInches = 0.625;
            let {
                width: totalCropWidthInches,
                height: totalCropHeightInches,
                depth: totalDepthInches
            } = bounds;
            const {
                totalCropLeftPercent,
                totalCropTopPercent,
                totalCropWidthPercent,
                totalCropHeightPercent
            } = totalCropPercentages;

            // If the isRotated flag is set, we need to swap the axes
            if (isRotated) {
                [totalCropWidthInches, totalCropHeightInches] = [
                    totalCropHeightInches,
                    totalCropWidthInches
                ];
            }

            // The total depth also includes the bleed
            const depthInches = totalDepthInches - bleedInches;
            // The total width and total height include depth+bleed on both sides
            const cropWidthInches = totalCropWidthInches - 2 * totalDepthInches;
            const cropHeightInches = totalCropHeightInches - 2 * totalDepthInches;
            const cropAspectRatio = cropWidthInches / cropHeightInches;
            // We use full image bounds to determine DPI
            const fullImageWidthInches = divideByPercent(
                totalCropWidthInches,
                totalCropWidthPercent
            );
            const fullImageHeightInches = divideByPercent(
                totalCropHeightInches,
                totalCropHeightPercent
            );
            // Now we can actually determine the crop position
            const totalCropLeftInches = multiplyByPercent(
                fullImageWidthInches,
                totalCropLeftPercent
            );
            const totalCropTopInches = multiplyByPercent(
                fullImageHeightInches,
                totalCropTopPercent
            );

            return {
                totalCropLeftInches,
                totalCropTopInches,
                bleedInches,
                depthInches,
                cropWidthInches,
                cropHeightInches,
                cropAspectRatio,
                fullImageWidthInches,
                fullImageHeightInches
            };
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spCanvasView#getBoundsPixels
         * @methodOf sp.client.gallery.service:spCanvasView
         *
         * @description Performs calculations in order to convert the crop percentages to from real
         *     units relative to the product into pixel values which can be rendered
         *
         * @param {object} photoDimensions Photo dimensions as calculated by
         *     SPPhoto#getPhotoDimensions
         * @param {object} boundsInches Bounds calculations in inches as calculated by
         *     spCanvasView#getBoundsInches
         *
         * @returns {object} boundsPixels Dimensions calculations in pixels
         * @returns {number} boundsPixels.totalCropLeftPixels Left crop distance in pixels
         * @returns {number} boundsPixels.totalCropTopPixels Top crop distance in pixels
         * @returns {number} boundsPixels.bleedPixels Bleedwrap crop distance in pixels
         * @returns {number} boundsPixels.depthPixels Depth of image in pixels
         * @returns {number} boundsPixels.cropWidthPixels Crop width in pixels
         * @returns {number} boundsPixels.cropHeightPixels Crop height in pixels
         */
        function getBoundsPixels(photoDimensions, boundsInches) {
            const { width: photoWidthPixels } = photoDimensions;
            const {
                totalCropLeftInches,
                totalCropTopInches,
                bleedInches,
                depthInches,
                cropWidthInches,
                cropHeightInches,
                fullImageWidthInches
            } = boundsInches;
            const photoDpi = photoWidthPixels / fullImageWidthInches;

            return {
                totalCropLeftPixels: totalCropLeftInches * photoDpi,
                totalCropTopPixels: totalCropTopInches * photoDpi,
                bleedPixels: bleedInches * photoDpi,
                depthPixels: depthInches * photoDpi,
                cropWidthPixels: cropWidthInches * photoDpi,
                cropHeightPixels: cropHeightInches * photoDpi
            };
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spCanvasView#getScaleFactors
         * @methodOf sp.client.gallery.service:spCanvasView
         *
         * @description Determines the scale factor based on the crop and target container
         *
         * @param {string} exhibition Which exhibition environment will the canvas be scaled to fit
         *     into? (CANVAS_EXHIBITION_TYPES)
         * @param {object} exhibitionDimensions Physical Dimensions of the Exhibition Space as
         *     calculated by spCanvasView#getExhibitionDimensions
         * @param {object} boundsInches Bounds calculations in inches as calculated by
         *     spCanvasView#getBoundsInches
         * @param {object} containerDimensions Represents the rendered image container dimensions
         * @param {number} containerDimensions.width Container width in pixels
         * @param {number} containerDimensions.height Container width in pixels
         * @param {number} containerDimensions.aspectRatio Ratio of container width to container
         *     height
         * @param {object} boundsPixels Dimensions calculations in pixels
         * @param {number} boundsPixels.cropWidthPixels Crop width in pixels
         * @param {number} boundsPixels.cropHeightPixels Crop height in pixels
         *
         * @returns {object} scaleFactors Collection of scaling factors > 0 used to stretch or
         *     shrink visual elements
         * @returns {number} scaleFactors.baseScaleFactor Scale factor to completely fit to
         *     container
         * @returns {number} scaleFactors.perspectiveScaleFactor Scale factor used to render the
         *     perspective rendering
         * @returns {number} scaleFactors.shadowScaleFactor Scale factor used to render the shadow
         *     rendering
         * @returns {boolean} scaleFactors.fitPhotoToContainerWidth Are we fitting the photo to the
         *     container's width or height?
         */
        function getScaleFactors(
            exhibition,
            exhibitionDimensions,
            boundsInches,
            {
                width: containerWidthPixels,
                height: containerHeightPixels,
                aspectRatio: containerAspectRatio
            },
            { cropWidthPixels, cropHeightPixels }
        ) {
            // Look at aspect ratios and determine whether we will fit width or height
            const cropAspectRatio = cropWidthPixels / cropHeightPixels;
            const fitPhotoToContainerWidth = containerAspectRatio < cropAspectRatio;
            // Determine scale factor betweeen source image and container
            const baseScaleFactor = fitPhotoToContainerWidth
                ? containerWidthPixels / cropWidthPixels
                : containerHeightPixels / cropHeightPixels;
            // Coverage ratios is the percentage of the mat we cover (fitting width or height)
            const perspectiveCoverageRatio = 0.75;
            const shadowRenderingCoverageRatio = getShadowRenderingCoverageRatio(
                exhibition,
                exhibitionDimensions,
                boundsInches,
                fitPhotoToContainerWidth
            );
            // Determine the scaling factors we will use to size renderings
            const perspectiveScaleFactor = baseScaleFactor * perspectiveCoverageRatio;
            const shadowScaleFactor = baseScaleFactor * shadowRenderingCoverageRatio;

            return {
                baseScaleFactor,
                perspectiveScaleFactor,
                shadowScaleFactor,
                fitPhotoToContainerWidth
            };
        }

        /**
         * @name getShadowRenderingCoverageRatio
         * @private
         *
         * @description Get Ratios and Dimensions to overlay canvas over backdrop
         *
         * @param {string} exhibition Which exhibition environment will the canvas be scaled to fit
         *     into? (CANVAS_EXHIBITION_TYPES)
         * @param {object} exhibitionDimensions Physical Dimensions of the Exhibition Space as
         *     calculated by spCanvasView#getExhibitionDimensions
         * @param {object} boundsInches Bounds calculations in inches as calculated by
         *     spCanvasView#getBoundsInches
         * @param {number} boundsInches.cropWidthInches Cropped area width from
         *     spCanvasView#getBoundsInches in inches
         * @param {number} boundsInches.cropHeightInches Cropped area height from
         *     spCanvasView#getBoundsInches in inches
         * @param {boolean} fitPhotoToContainerWidth Are we fitting the photo to the container's
         *     width or height?
         *
         * @returns {number} shadowRenderingCoverageRatio Scaled box shadow offset
         */
        function getShadowRenderingCoverageRatio(
            exhibition,
            exhibitionDimensions,
            { cropWidthInches, cropHeightInches },
            fitPhotoToContainerWidth
        ) {
            if (exhibition === CANVAS_EXHIBITION_TYPES.SOLO) {
                return 0.85;
            }

            const { croppedExhibitionInches } = exhibitionDimensions;
            const {
                width: croppedExhibitionWidthInches,
                height: croppedExhibitionHeightInches
            } = croppedExhibitionInches;
            const shadowRenderingCoverageRatio = fitPhotoToContainerWidth
                ? cropWidthInches / croppedExhibitionWidthInches
                : cropHeightInches / croppedExhibitionHeightInches;

            return shadowRenderingCoverageRatio;
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spCanvasView#getPerspectiveRenderingDetails
         * @methodOf sp.client.gallery.service:spCanvasView
         *
         * @description Computes some additional details to control perspective rendering after scaling
         *
         * @param {object} scaleFactors Scaling factors as calculated by spCanvasView#getScaleFactors
         * @param {number} scaleFactors.perspectiveScaleFactor Scaling factor for the perspective rendering
         *
         * @returns {object} perspectiveRenderingDetails
         * @returns {number} perspectiveRenderingDetails.boxShadowOffset Scaled box shadow offset
         */
        function getPerspectiveRenderingDetails({ perspectiveScaleFactor }) {
            // Because we scale our entire renderings, we reverse the scaling to get constant
            // box-shadow
            const boxShadowOffset = 4 / perspectiveScaleFactor;

            return { boxShadowOffset };
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spCanvasView#getShadowRenderingDetails
         * @methodOf sp.client.gallery.service:spCanvasView
         *
         * @description Computes some additional details to control shadow rendering after scaling
         *
         * @param {object} scaleFactors Scaling factors as calculated by
         *     spCanvasView#getScaleFactors
         * @param {number} scaleFactors.shadowScaleFactor Scaling factor for the shadow rendering
         *
         * @returns {object} perspectiveRenderingDetails
         * @returns {number} perspectiveRenderingDetails.boxShadowVerticalOffset Scaled box shadow
         *     vertical offset
         * @returns {number} perspectiveRenderingDetails.boxShadowHorizontalOffset Scaled box shadow
         *     horizontal offset
         * @returns {number} perspectiveRenderingDetails.borderRadius Scaled border radius
         */
        function getShadowRenderingDetails({ shadowScaleFactor }) {
            // Because we scale our entire renderings, we reverse the scaling to get constant
            // box-shadow and border-radius
            const boxShadowVerticalOffset = 4 / shadowScaleFactor;
            const boxShadowHorizontalOffset = 1 / shadowScaleFactor;
            const borderRadius = 2 / shadowScaleFactor;

            return { boxShadowVerticalOffset, boxShadowHorizontalOffset, borderRadius };
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spCanvasView#getTranslateFactors
         * @methodOf sp.client.gallery.service:spCanvasView
         *
         * @description Returns the translate factors according to the focal point of the target
         *     exhibition type
         *
         * @param {string} exhibition Canvas exhibition type (CANVAS_EXHIBITION_TYPES)
         * @param {object} exhibitionDimensions Physical Dimensions of the Exhibition Space as
         *     calculated by spCanvasView#getExhibitionDimensions
         *
         * @returns {object} translateFactors
         * @returns {number} translateFactors.translateX Horizontal pixel offset of the focal point
         * @returns {number} translateFactors.translateY Vertical pixel offset of the focal point
         */
        function getTranslateFactors(exhibition, exhibitionDimensions) {
            const translateFactorPercentages = CANVAS_EXHIBITION_FOCAL_POINT_MAP[exhibition];

            if (!translateFactorPercentages || !exhibitionDimensions) {
                return { translateX: 0, translateY: 0 };
            }

            const { uncroppedBackdropPixels } = exhibitionDimensions;
            const {
                width: uncroppedBackdropWidth,
                height: uncroppedBackdropHeight
            } = uncroppedBackdropPixels;
            const {
                translateX: translateXPercentage,
                translateY: translateYPercentage
            } = translateFactorPercentages;
            const translateXPixels = Math.round(
                multiplyByPercent(uncroppedBackdropWidth, translateXPercentage)
            );
            const translateYPixels = Math.round(
                multiplyByPercent(uncroppedBackdropHeight, translateYPercentage)
            );
            const translateFactorPixels = {
                translateX: `${translateXPixels}px`,
                translateY: `${translateYPixels}px`
            };

            return translateFactorPixels;
        }
    }
];
