import { createStandaloneToast } from '@chakra-ui/react';

/**
 * @ngdoc service
 * @name  sp.client.gallery.service:spToast
 *
 * @description
 * Helps show React toasts from Angular
 */
export default [
    function spToast() {
        const service = this;
        const { toast } = createStandaloneToast();

        Object.assign(service, {
            toast
        });

        return service;
    }
];
