import React, { useEffect, useState } from 'react';
import { useClientEventApiFetch } from 'ts/client/common';
import ProductImages from 'ts/client/gallery/components/ProductImages';
import { IImage } from 'ts/client/gallery/components/types';
import { LoadingIndicator } from 'ts/common/components';
import { ICrop } from 'ts/common/types';

export interface ICartPreview {
    crop: Nullable<ICrop>;
    image: IImage;
    priceSheetItemId: number;
}

const CartPreview: React.FC<ICartPreview> = ({ crop, image, priceSheetItemId }) => {
    const [priceSheetItem, setPriceSheetItem] = useState<SpApi.Client.IPriceSheetItem>();
    const { response } =
        useClientEventApiFetch<IApiListResponse<SpApi.Client.IPriceSheetItem>>('price-sheet/item');

    useEffect(() => {
        if (response) {
            const item = response.items?.find((x) => x.id === priceSheetItemId);
            setPriceSheetItem(item);
        }
    }, [response, priceSheetItemId]);

    return priceSheetItem ? (
        <ProductImages
            allowCropping={false}
            buyAllImages={[]}
            crop={crop}
            image={image}
            priceSheetItem={priceSheetItem}
        />
    ) : (
        <LoadingIndicator squareClassName="theme-background-color-primary" />
    );
};

export default CartPreview;
