import React, { FC } from 'react';
import { Box, Flex, Icon, Text, useTheme } from '@chakra-ui/react';
import { X as Close, Icon as IconType } from 'react-feather';

interface IToastProps {
    title: string;
    description?: string;
    icon?: IconType;
    iconFill?: string;
    onClose: () => unknown;
}

const Toast: FC<IToastProps> = ({ title, description, icon, iconFill, onClose }) => {
    const theme = useTheme();

    return (
        <Flex
            background={theme.isLightColorScheme ? 'background' : 'gray.600'}
            borderRadius="lg"
            filter={theme.filters?.dropShadow}
            fontFamily="open-sans, sans-serif"
            padding="12px"
            position="relative"
        >
            {icon && (
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    height="24px"
                    paddingX="4px"
                    marginRight="12px"
                    color="brand.primary"
                    borderRadius="50%"
                    backgroundColor="brand.primaryLight"
                >
                    <Icon as={icon} boxSize="16px" fill={iconFill} />
                </Flex>
            )}
            <Close
                size="16px"
                onClick={onClose}
                style={{
                    position: 'absolute',
                    right: '16px',
                    top: '16px',
                    cursor: 'pointer',
                    color: theme.isLightColorScheme ? 'gray.500' : 'white'
                }}
            />
            <Box>
                {title && <Text fontWeight="semibold">{title}</Text>}
                {description && <Text>{description}</Text>}
            </Box>
        </Flex>
    );
};

export default Toast;
