export const EMAIL_REGEX = {
    VALID_EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
};

export const HTML_TAG_REGEX = /(<([^>]+)>)/gi;

export default {
    EMAIL_REGEX,
    HTML_TAG_REGEX
};
