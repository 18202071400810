import React, { useCallback, useEffect, useState } from 'react';
import { Download, EyeOff, Heart, ShoppingBag, Tag } from 'react-feather';
import type { IPhoto, IPhotoActions, IRootScope } from 'ts/client/types';
import {
    BAndWEnabledIcon,
    BAndWIcon,
    EyeOffEnabledIcon,
    ShareIcon,
    TagEnabledIcon
} from 'ts/common/components/icons';
import useCountSessionAction from 'ts/common/hooks/useCountSessionAction';
import useToast from 'ts/common/hooks/useToast';
import type { IActionButton } from 'ts/common/types';

interface IPhotoActionButton extends IActionButton {
    key: 'favorite' | 'download' | 'share' | 'bAndW' | 'hide' | 'label' | 'buyPhoto';
}

const SESSION_STORAGE_KEY_FAVORITED_TOAST_SHOWN = 'favorited';
const SESSION_STORAGE_KEY_HIDDEN_TOAST_SHOWN = 'hidden';
const SESSION_STORAGE_KEY_LABEL_ASSIGNED_TOAST_SHOWN = 'label-assigned';

export interface IUsePhotoActionsProps {
    /** True only if the photo has black & white enabled */
    isBlackAndWhite: boolean;
    /** True only if the download button should be visible */
    isDownloadPossible: boolean;
    /** True only if the photo is favorited */
    isFavorite: boolean;
    /** True only if the photo is hidden */
    isHidden: boolean;
    /** True only if the photo has at least one label applied */
    isLabeled: boolean;
    /** True only if the package builder is open */
    isPackageBuilderOpen?: boolean;
    /** The photo associated with this toolbar */
    photo: IPhoto;
    /** An object with callbacks to perform actions on photos */
    photoActions: IPhotoActions;
    /** True if the user needs to select a download destination for a pending download that includes the photo */
    requiresDownloadDestination?: boolean;
    /** Angular free digitals service */
    spFreeDigitals: SpAngularJs.ISpFreeDigitals;
    /** Angular translation service */
    translateFilter: SpAngularJs.ITranslateFilter;
    /** Angular root scope */
    $rootScope: IRootScope;
}

const usePhotoActions = ({
    isBlackAndWhite,
    isDownloadPossible,
    isFavorite,
    isHidden,
    isLabeled,
    isPackageBuilderOpen,
    photo,
    photoActions: {
        addPhotoToCart,
        canAddPhotoToCart,
        canBandWPhoto,
        canFavoritePhoto,
        canHidePhoto,
        canSelectForPackage,
        canSharePhoto,
        canTagPhoto,
        downloadPhoto,
        getPhotoShareUrls,
        selectForPackage,
        showTagModal,
        togglePhotoBandW,
        togglePhotoFavorite,
        togglePhotoHidden
    },
    requiresDownloadDestination,
    translateFilter: translate,
    $rootScope
}: IUsePhotoActionsProps) => {
    const [isShareLinksVisible, setIsShareLinksVisible] = useState(false);
    const [isDownloadDestinationsVisible, setIsDownloadDestinationsVisible] = useState(false);
    const [shareUrls, setShareUrls] = useState<Record<string, string>>();

    const [getSessionCount, increaseSessionCount] = useCountSessionAction();
    const favoritedToast = useToast({ type: 'favorite', translateFilter: translate });
    const hiddenToast = useToast({ type: 'hide', translateFilter: translate });
    const labelAssignedToast = useToast({ type: 'label', translateFilter: translate });

    useEffect(() => {
        if (requiresDownloadDestination) {
            setIsDownloadDestinationsVisible(true);
        }
    }, [requiresDownloadDestination]);

    const hideDownloadDestinations = useCallback(() => {
        setIsDownloadDestinationsVisible(false);
    }, []);

    const hideShareLinks = useCallback(() => {
        setIsShareLinksVisible(false);
    }, []);

    const incrementUpdateCount = useCallback(() => {
        $rootScope.updateCount = ($rootScope.updateCount ?? 0) + 1;
    }, [$rootScope]);

    const handleClickBlackAndWhite = useCallback(() => {
        togglePhotoBandW(photo);
        incrementUpdateCount();
        $rootScope.$digest();
    }, [incrementUpdateCount, photo, togglePhotoBandW, $rootScope]);

    const handleClickBuy = useCallback(() => {
        addPhotoToCart(photo);
    }, [addPhotoToCart, photo]);

    const handleClickDownload = useCallback(() => {
        downloadPhoto(photo);

        if (requiresDownloadDestination) {
            setIsDownloadDestinationsVisible(true);
        }
    }, [downloadPhoto, photo, requiresDownloadDestination]);

    const handleClickFavorite = useCallback(() => {
        togglePhotoFavorite(photo, () => {
            incrementUpdateCount();

            if (photo.isFavorite && !getSessionCount(SESSION_STORAGE_KEY_FAVORITED_TOAST_SHOWN)) {
                favoritedToast();
                increaseSessionCount(SESSION_STORAGE_KEY_FAVORITED_TOAST_SHOWN);
            }
        });
    }, [
        favoritedToast,
        getSessionCount,
        increaseSessionCount,
        incrementUpdateCount,
        photo,
        togglePhotoFavorite
    ]);

    const handleClickHide = useCallback(() => {
        togglePhotoHidden(photo, () => {
            incrementUpdateCount();

            if (photo.isHidden && !getSessionCount(SESSION_STORAGE_KEY_HIDDEN_TOAST_SHOWN)) {
                hiddenToast();
                increaseSessionCount(SESSION_STORAGE_KEY_HIDDEN_TOAST_SHOWN);
            }
        });
    }, [
        getSessionCount,
        hiddenToast,
        increaseSessionCount,
        incrementUpdateCount,
        photo,
        togglePhotoHidden
    ]);

    const handlePhotoLabelAssigned = useCallback(() => {
        if (!getSessionCount(SESSION_STORAGE_KEY_LABEL_ASSIGNED_TOAST_SHOWN)) {
            labelAssignedToast();
            increaseSessionCount(SESSION_STORAGE_KEY_LABEL_ASSIGNED_TOAST_SHOWN);
        }
    }, [getSessionCount, increaseSessionCount, labelAssignedToast]);

    const handleClickLabel = useCallback(() => {
        showTagModal(photo, handlePhotoLabelAssigned);
    }, [handlePhotoLabelAssigned, photo, showTagModal]);

    const handleClickPackageSelect = useCallback(() => {
        selectForPackage?.(photo);
    }, [photo, selectForPackage]);

    const handleClickShare = useCallback(async () => {
        setIsShareLinksVisible(true);
        const response = await getPhotoShareUrls(photo);
        setShareUrls(response.shareUrls);
    }, [getPhotoShareUrls, photo]);

    const onClickBlackAndWhite = canBandWPhoto() ? handleClickBlackAndWhite : undefined;
    const onClickBuy = canAddPhotoToCart() ? handleClickBuy : undefined;
    const onClickDownload = isDownloadPossible ? handleClickDownload : undefined;
    const onClickFavorite = canFavoritePhoto() ? handleClickFavorite : undefined;
    const onClickHide = canHidePhoto() ? handleClickHide : undefined;
    const onClickLabel = canTagPhoto() ? handleClickLabel : undefined;
    const onClickPackageSelect = canSelectForPackage?.() ? handleClickPackageSelect : undefined;
    const onClickShare = canSharePhoto() ? handleClickShare : undefined;

    const photoActionButtons: IPhotoActionButton[] = [];

    if (onClickFavorite) {
        photoActionButtons.push({
            key: 'favorite',
            icon: isFavorite ? (
                <Heart
                    fill="var(--chakra-colors-brand-primary)"
                    stroke="var(--chakra-colors-brand-primary)"
                    width="16px"
                />
            ) : (
                <Heart fill="none" width="16px" />
            ),
            isToggledOn: isFavorite,
            onClick: onClickFavorite,
            text: translate('favorite')
        });
    }

    if (onClickDownload) {
        photoActionButtons.push({
            key: 'download',
            icon: <Download width="16px" />,
            onClick: onClickDownload,
            text: translate('download'),
            keepOpen: requiresDownloadDestination
        });
    }

    if (onClickShare) {
        photoActionButtons.push({
            key: 'share',
            icon: <ShareIcon width="16px" />,
            isDisabled: isHidden,
            onClick: onClickShare,
            text: translate('share'),
            keepOpen: true
        });
    }

    if (onClickBlackAndWhite) {
        photoActionButtons.push({
            key: 'bAndW',
            icon: isBlackAndWhite ? (
                <BAndWEnabledIcon width="16px" color="var(--chakra-colors-brand-primary)" />
            ) : (
                <BAndWIcon width="16px" viewBox="0 0 24 24" />
            ),
            isToggledOn: isBlackAndWhite,
            onClick: onClickBlackAndWhite,
            text: translate('bAndW')
        });
    }

    if (onClickHide) {
        photoActionButtons.push({
            key: 'hide',
            icon: isHidden ? (
                <EyeOffEnabledIcon
                    width="16px"
                    viewBox="0 0 16 16"
                    color="var(--chakra-colors-brand-primary)"
                />
            ) : (
                <EyeOff width="16px" />
            ),
            isToggledOn: isHidden,
            onClick: onClickHide,
            text: translate(isHidden ? 'unhide' : 'hide')
        });
    }

    if (onClickLabel) {
        photoActionButtons.push({
            key: 'label',
            icon: isLabeled ? (
                <TagEnabledIcon width="16px" color="var(--chakra-colors-brand-primary)" />
            ) : (
                <Tag width="16px" />
            ),
            isToggledOn: isLabeled,
            onClick: onClickLabel,
            text: translate('label')
        });
    }

    const onClickBuyOrPackageSelect = isPackageBuilderOpen ? onClickPackageSelect : onClickBuy;

    if (onClickBuyOrPackageSelect) {
        photoActionButtons.push({
            key: 'buyPhoto',
            icon: <ShoppingBag width="16px" />,
            onClick: onClickBuyOrPackageSelect,
            text: translate(onClickBuy ? 'buyPhoto' : 'selectPhoto')
        });
    }

    return {
        hideShareLinks,
        isShareLinksVisible,
        hideDownloadDestinations,
        isDownloadDestinationsVisible,
        photoActionButtons,
        shareUrls
    };
};

export default usePhotoActions;
