import React, { FC, useCallback, useState } from 'react';
import {
    Box,
    BoxProps,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input
} from '@chakra-ui/react';
import debounce from 'lodash.debounce';
import { Image } from 'react-feather';
import ModalHeader from 'ts/common/components/gallery/ModalHeader';
import ModalButtonFooter from './ModalButtonFooter';

interface IAlbumPasswordFormProps extends BoxProps {
    onClose: () => void;
    onSubmit: () => void;
    isInvalid: boolean;
    errorMessage: string;
    onChangePassword: (password: string) => void;
    translateFilter: SpAngularJs.ITranslateFilter;
}

const AlbumPasswordForm: FC<IAlbumPasswordFormProps> = ({
    onClose,
    onSubmit,
    isInvalid,
    errorMessage,
    onChangePassword,
    translateFilter: t,
    ...props
}) => {
    const [password, setPassword] = useState('');
    const isPasswordEmpty = password.length < 1;

    const debouncedOnChangePassword = debounce(onChangePassword, 250);

    const handleChangePassword = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
            debouncedOnChangePassword(event.target.value);
        },
        [debouncedOnChangePassword]
    );

    const handleSubmit = useCallback(
        (event: React.FormEvent<HTMLElement>) => {
            event.preventDefault();
            onSubmit();
            setPassword('');
        },
        [onSubmit]
    );

    return (
        <Box height="375px" {...props}>
            <ModalHeader
                modalIcon={Image}
                headerText={t('viewAlbum')}
                headerSubText={t('enterAlbumPassword')}
            />
            <Flex as="form" flexDirection="column" paddingTop="32px" onSubmit={handleSubmit}>
                <FormControl isRequired isInvalid={isInvalid}>
                    <FormLabel>{t('accessCodeLabel')}</FormLabel>
                    <Input
                        data-testid="password-input"
                        type="password"
                        value={password}
                        onChange={handleChangePassword}
                    />
                    <FormErrorMessage>{errorMessage}</FormErrorMessage>
                </FormControl>

                <ModalButtonFooter
                    borderRadius="20px"
                    isPrimaryDisabled={isPasswordEmpty}
                    secondaryButtonAction={onClose}
                    position="absolute"
                    primaryButtonText={t('submit')}
                    primaryLoadingText={t('submit')}
                    secondaryButtonText={t('cancel')}
                />
            </Flex>
        </Box>
    );
};

export default AlbumPasswordForm;
