import isSafeInteger from 'lodash.issafeinteger';
import lodashFind from 'lodash.find';
import lodashGet from 'lodash.get';

export {
    findBy,
    findByPath,
    findDeepBy,
    findIndexBy,
    getAtPath,
    getLinkedChanges,
    keyBy,
    match,
    pluck,
    replace
};

export default {
    findBy,
    findByPath,
    findDeepBy,
    findIndexBy,
    getAtPath,
    getLinkedChanges,
    keyBy,
    match,
    pluck,
    replace
};

/**
 * @name findBy
 * @description
 * Find the first item that matches on the property like the needle, using identity (===)
 *
 * @example
 ```js
 var someObject = { id: 1 };
 var someCollection = [{ id: 1 }];

 findBy('id', someObject.id, someCollection); // returns someCollection[0]
 findBy('id', 2, someCollection); // returns undefined
 ```
 *
 * @param {string} property Property to look up on the object
 * @param {any} needleLike Value to compare. Checked with identity (===)
 * @param {array<object>} collection Array to iterate over
 *
 * @returns {object|undefined} The first object to match on the property, or undefined
 */
function findBy(property, needleLike, collection) {
    var lookup = {};

    lookup[property] = needleLike;

    return match(lookup, collection);
}

/**
 * @name findIndexBy
 * @description
 * Find the index of the first item that matches on the property like the needle, using
 * identity (===). If not found, returns `-1` like `Array.prototype#indexOf`.
 *
 * @example
 ```js
 var someObject = { id: 1 };
 var someCollection = [{ id: 1 }];

 findIndexBy('id', someObject.id, someCollection); // returns 0
 findIndexBy('id', 2, someCollection); // returns -1
 ```
 *
 * @param {string} property Property to look up on the object
 * @param {any} needleLike Value to compare. Checked with identity (===)
 * @param {array<object>} collection Array to iterate over
 *
 * @returns {number} The index of the first object to match on the property, or -1
 */
function findIndexBy(property, needleLike, collection) {
    return collection.indexOf(findBy(property, needleLike, collection));
}

/**
 * @name findDeepBy
 * @description
 * Find the first item that matches on the property at the path specified, or recurses
 * looking up the children based on the childPath. Uses identity to match (===).
 *
 * @example
 ```js
 var someObject = { id: 1 };
 var someCollection = [{ children: [{ nest: { id: 1 } }] }];

 // Recursive nested tree:
 findDeepBy(['nest', 'id'], ['children'], someObject.id, someCollection);
 // returns someCollection[0]
 findDeepBy(['nest', 'id'], ['children'], 2, someCollection);
 // returns undefined

 // Array of Arrays:
 var anotherCollection = [[0, { id: 1 }], [null, { key: 'value' }]];
 findDeepBy([1, 'id'], [], 1, anotherCollection);
 // returns [0, { id: 1 }]
 findDeepBy([0], [], null, anotherCollection);
 // returns [null, { key: 'value' }]
 ```
 *
 * @param {array<string>} propertyPath Path to comparison proprety. Use number for array index
 * @param {array<string>} childPath Path to children to recurse into. Use number for array index
 * @param {any} needleLike Value to compare. Checked with identity (===)
 * @param {array<object>} collection Array to iterate over
 *
 * @returns {object|undefined} The first object to match on the property, or undefined
 */
function findDeepBy(propertyPath, childPath, needleLike, collection) {
    return collection.reduce(function findIn(found, item) {
        if (!found && item) {
            var property = getAtPath(propertyPath, item);
            var children = getAtPath(childPath, item);

            if (property === needleLike) {
                return item;
            }

            return children && children.reduce(findIn, found);
        }

        return found;
    }, undefined);
}

/**
 * @name getAtPath
 * @description
 * Safely access a nested value.
 *
 * @example
 ```js
 var user = {
     name: {
         firstName: 'Ian'
     },
     email: {
         main: 'ian@joydivision.com'
     },
     phone: [
        '1231312',
        '123'
     ]
 };

 var userFirstName = getAtPath(['name', 'firstName'], user); // 'Ian'
 var userLastName = getAtPath(['name', 'lastName'], user); // undefined
 var userZipcode = getAtPath(['address', 'zipcode'], user); // undefined
 var userEmail = getAtPath(['email'], user); // { main: 'ian@joydivision.com' }
 var userThirdPhone = getAtPath(['phone', 2], user); // undefined
 ```
 *
 * @param {string} path Path to property. Use number for array index
 * @param {object|array} object Object to access
 *
 * @returns {any|undefined} The value at the path, or undefined
 */
function getAtPath(path, object) {
    return path.reduce(function hasPath(soFar, pathPart) {
        return soFar && soFar[pathPart] ? soFar[pathPart] : undefined;
    }, object);
}

/**
 * @name keyBy
 * @description
 * Take an array and create an object where the keys are the value at the
 * provided property.
 *
 * @param {string} property Property whose value will be the key
 * @param {array<object>} collection Collection to match on.
 * @return {object} Keyed object
 */
function keyBy(property, collection) {
    return collection.reduce(function indexTo(indexed, item) {
        indexed[item[property]] = item;

        return indexed;
    }, {});
}

/**
 * @name match
 * @description
 * Finds the first object in an array that matches the lookup object
 *
 * @param {object} lookup Keys/values to match. Compared by identity.
 * @param {array<object>} collection Collection to match on.
 * @return {object|undefined} Found object or undefined for no match.
 */
function match(lookup, collection) {
    return collection.reduce(function findMatch(found, item) {
        var matchesAllKeys = Object.keys(lookup).reduce(function checkForKeyMatch(doesMatch, key) {
            if (doesMatch === undefined) {
                return item && item[key] === lookup[key];
            }

            return doesMatch && item && item[key] === lookup[key];
        }, undefined);

        if (!found && matchesAllKeys) {
            return item;
        }

        return found;
    }, undefined);
}

/**
 * @name pluck
 * @description
 * Transforms an array of objects into an array of a given property of the objects
 *
 * @param {string} property Property to pull from objects in array.
 * @param {array<object>} collection Collection to pluck from.
 */
function pluck(property, collection) {
    return collection.map(function withItem(item) {
        return item && item[property];
    });
}

/**
 * @name replace
 * @description
 * Replaces an item in an array, and returns an array with a new reference. This
 * is primarily useful in the context of AngularJS watchers which depend on
 * reference equality
 *
 * @param {array} collection Target item collection
 * @param {object} item Item data to replace at index
 * @param {number} index Index of the item
 *
 * @returns {array} New array containing the replaced item
 */
function replace(collection, item, index) {
    if (!collection || !collection.length || !isSafeInteger(index) || index < 0) {
        return collection;
    }

    return [...collection.slice(0, index), item, ...collection.slice(index + 1)];
}

/**
 * @name getLinkedChanges
 * @description
 * Takes an array of strings or numbers, and an equal length array of strings and
 * numbers (containing the same set of elements), and creates an array of difference
 * objects.
 *
 * @example
 * <pre>
       getLinkedChanges([0, 1], [1, 0], { value: 'id', previous: 'previousId' });
       // => [{ id: 0, previousId: 1 }, { id: 1, previousId: null }];
   </pre>
 *
 * @param {array<string|number>} current Current state of the array
 * @param {array<string|number>} desired Desired state of the array
 * @param {object=} properties Settings object to configure returned difference object
 * @param {string} properties.value Property that will contain the elements of the source arrays
 * @param {string} properties.previous Property that will point to the previous element in the list
 * @return {array<object>} [{ properties.value, properties.previous }]
 */
function getLinkedChanges(current, desired, properties) {
    if (current.length !== desired.length) {
        throw new Error('Current and desired arrays must have the same length');
    }

    var currentLinked = toLinked(current);
    var desiredLinked = toLinked(desired);

    properties = properties || {};
    properties.value = properties.value || 'id';
    properties.previous = properties.previous || 'previous';

    return Object.keys(desiredLinked)
        .filter(function getChanged(id) {
            return desiredLinked[id] !== currentLinked[id];
        })
        .map(function getDesiredChangeItem(id) {
            var change = {};

            change[properties.value] = parseInt(id);
            change[properties.previous] = desiredLinked[id];

            return change;
        });
}

function toLinked(inputArray) {
    return inputArray.reduce(function toLinkedMap(linkedMap, item, index) {
        var previousItem = inputArray[index - 1];

        linkedMap[item] = previousItem === undefined ? null : previousItem;

        return linkedMap;
    }, {});
}

/**
 * @name findByPath
 *
 * @description
 * Searches through a collection of objects for the given value at the provided path
 * Mainly just wrappers around `lodash.get` and `lodash.find` (https://lodash.com/docs/4.17.10)
 *
 * @example
<pre>
    const collection = [
        { one: { nested: 'this' }},
        { one: { nested: 'that' }}
    ];

    findByPath(collection, 'one.nested', 'this')

    // => { one: { nested: 'this' } }
</pre>
 *
 * @param {array<object>} collection Collection of object to search through
 * @param {string} path Path string to access property on items in the provided collection
 * @param {string|number} findValue Value to compare against property found at path
 * @return {any|undefined} Value found at the matched path
 */
function findByPath(collection, path, findValue) {
    return lodashFind(collection, (item) => {
        return lodashGet(item, path) === findValue;
    });
}
