import React, { memo } from 'react';
import { Flex, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

const Container = styled(Flex)(({ theme }) => {
    const { mediumScreenUp } = theme.responsive;

    return `
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 88px;
        align-items: flex-start;
        padding: ${theme.spacing.md};
        margin: 0;
        background-color: white;

        ${mediumScreenUp(`
            height: 80px;
            padding: ${theme.gutters.sm};
        `)}
    `;
});

const InnerContainer = styled(Flex)(({ theme }) => {
    const { mediumScreenUp } = theme.responsive;

    return `
        width: 100%;
        max-width: 350px;
        height: 56px;
        justify-content: space-between;
        align-items: center;
        padding: ${theme.spacing.md};
        margin-left: auto;
        margin-right: auto;

        ${mediumScreenUp(`
          padding: ${theme.gutters.sm};
        `)}
    `;
});

const StyledText = styled(Text)(({ theme }) => {
    return `
        text-align: right;
        font-weight: ${theme.fontWeights.semibold};
        color: ${theme.colors.gray[700]};
        margin: auto;
    `;
});

interface IEditorEditbarProps {
    /** Size scale value. */
    size: number;
    onSizeChangeEnd?: (value: number) => void;
    /** Callback when size scale changes. */
    onSizeChange?: (value: number) => void;
}

const EditorEditbar: React.FC<IEditorEditbarProps> = ({ size, onSizeChangeEnd, onSizeChange }) => {
    return (
        <Container data-testid="sp-ui-editor-editbar">
            <InnerContainer>
                <Slider
                    aria-label="Scale Image"
                    onChangeEnd={onSizeChangeEnd}
                    onChange={onSizeChange}
                    value={size}
                    maxWidth={269}
                >
                    <SliderTrack>
                        <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                </Slider>
                <StyledText aria-label="Scale Value" size="sm">
                    {size}%
                </StyledText>
            </InnerContainer>
        </Container>
    );
};

EditorEditbar.displayName = 'EditorEditbar';

export default memo(EditorEditbar);
