import { PointerEvent } from 'react';
import { PageSpecification, ProjectSpecification } from './Editor.types';
import { Position } from './EditorCanvas';
import { zoomBounds } from './constants';
import { convertPercentageToCrops } from './helpers';

export function getZoomCenter(
    pageSpecification: PageSpecification,
    additionalWidth: number,
    additionalHeight: number,
    zoomScale: number,
    zoomCenter: { x: number; y: number },
    offset = { x: 0, y: 0 }
) {
    const minimumCentre = {
        x: pageSpecification.product.x + (pageSpecification.product.width + additionalWidth) / 2,
        y: pageSpecification.product.y + (pageSpecification.product.height + additionalHeight) / 2
    };

    const maximumWidth = (pageSpecification.product.width + additionalWidth) / 1.2 ** zoomBounds[0];
    const maximumHeight =
        (pageSpecification.product.height + additionalHeight) / 1.2 ** zoomBounds[0];

    const width = pageSpecification.product.width / 1.2 ** zoomScale;
    const height = pageSpecification.product.height / 1.2 ** zoomScale;

    const minimumX = minimumCentre.x - 0.5 * maximumWidth + 0.5 * width;
    const minimumY = minimumCentre.y - 0.5 * maximumHeight + 0.5 * height;

    const maximumX = minimumCentre.x + 0.5 * maximumWidth - width + 0.5 * width;
    const maximumY = minimumCentre.y + 0.5 * maximumHeight - height + 0.5 * height;

    const x = zoomCenter.x - offset.x;
    const y = zoomCenter.y - offset.y;

    return {
        x: Math.min(maximumX, Math.max(minimumX, x)),
        y: Math.min(maximumY, Math.max(minimumY, y))
    };
}

interface PointerState {
    /** The pointer id from the pointer event. */
    id: number;
    /** The position the pointer started on in the pointerDown event. */
    startPosition: Position;
    /** The latest position from the pointerMove event. */
    currentPosition: Position;
}

interface StandbyState {
    type: 'standby';
    /** The set of active pointers. */
    activePointers: Set<number>;
}

interface StartedState {
    type: 'started';
    /** The set of active pointers. */
    activePointers: Set<number>;
    /** The first pointer we received from the pointerDown event. */
    firstPointer: PointerState;
    project: ProjectSpecification;
    zoomCenter: Position;
    projectPosition: Position;
    selected: boolean;
}

interface EndedState {
    type: 'ended';
    /** The set of active pointers. */
    activePointers: Set<number>;
}

interface PanState {
    type: 'pan';
    /** The set of active pointers. */
    activePointers: Set<number>;
    /** The first pointer we received from the pointerDown event. */
    firstPointer: PointerState;
    zoomCenter: Position;
    projectPosition: Position;
    zoomCenterOffset: Position;
}

interface ZoomState {
    type: 'zoom';
    /** The set of active pointers. */
    activePointers: Set<number>;
    /** The first pointer we received from the pointerDown event. */
    firstPointer: PointerState;
    /** The second pointer we received from the pointerDown event. */
    secondPointer: PointerState;
    /** How far the center of the two pointers has drift since the beginning of the action. */
    adjustZoomCenter: Position;
    /** The initial distance between the two pointers. */
    startDistance: number;
}

interface DragState {
    type: 'drag';
    /** The set of active pointers. */
    activePointers: Set<number>;
    /** The first pointer we received from the pointerDown event. */
    firstPointer: PointerState;
    project: ProjectSpecification;
    zoomCenter: Position;
    projectPosition: Position;
    cropRectangle: { x: number; y: number; width: number; height: number };
}

interface ResizeState {
    type: 'resize';
    /** The set of active pointers. */
    activePointers: Set<number>;
    /** The first pointer we received from the pointerDown event. */
    firstPointer: PointerState;
    /** The second pointer we received from the pointerDown event. */
    secondPointer: PointerState;
    /** How far the center of the two pointers has drift since the beginning of the action. */
    adjustZoomCenter: Position;
    /** The initial distance between the two pointers. */
    startDistance: number;
}

export type PointerActionState =
    | StandbyState
    | StartedState
    | EndedState
    | PanState
    | ZoomState
    | DragState
    | ResizeState;

export function createPointerActionState() {
    return {
        type: 'standby' as const,
        activePointers: new Set<number>()
    };
}

interface PointerDownEvent {
    type: 'pointerDown';
    id: number;
    position: Position;
    projectPosition: Position;
    project: ProjectSpecification;
    zoomCenter: Position;
    selected: boolean;
}

interface PointerMoveEvent {
    type: 'pointerMove';
    id: number;
    position: Position;
}

interface PointerUpEvent {
    type: 'pointerUp';
    id: number;
    position: Position;
}

interface PointerCancelEvent {
    type: 'pointerCancel';
    id: number;
}

export type PointerActionEvent =
    | PointerDownEvent
    | PointerMoveEvent
    | PointerUpEvent
    | PointerCancelEvent;

export function createPointerDownEvent(
    event: PointerEvent,
    position: Position,
    projectPosition: Position,
    project: ProjectSpecification,
    zoomCenter: Position,
    selected: boolean
): PointerDownEvent {
    return {
        type: 'pointerDown' as const,
        id: event.pointerId,
        position,
        projectPosition,
        project,
        zoomCenter,
        selected
    };
}

export function createPointerMoveEvent(event: PointerEvent, position: Position): PointerMoveEvent {
    return {
        type: 'pointerMove' as const,
        id: event.pointerId,
        position
    };
}

export function createPointerUpEvent(event: PointerEvent, position: Position): PointerUpEvent {
    return {
        type: 'pointerUp' as const,
        id: event.pointerId,
        position
    };
}

export function createPointerCancelEvent(event: PointerEvent): PointerCancelEvent {
    return {
        type: 'pointerCancel' as const,
        id: event.pointerId
    };
}

function handlePointerDownEvent(
    state: PointerActionState,
    event: PointerDownEvent
): PointerActionState {
    const activePointers = new Set(state.activePointers);
    activePointers.add(event.id);

    switch (state.type) {
        case 'standby': {
            return {
                type: 'started' as const,
                firstPointer: {
                    id: event.id,
                    startPosition: event.position,
                    currentPosition: event.position
                },
                project: event.project,
                zoomCenter: event.zoomCenter,
                projectPosition: event.projectPosition,
                selected: event.selected,
                activePointers
            };
        }

        case 'started': {
            return state.selected
                ? {
                      type: 'resize' as const,
                      firstPointer: state.firstPointer,
                      secondPointer: {
                          id: event.id,
                          startPosition: event.position,
                          currentPosition: event.position
                      },
                      adjustZoomCenter: {
                          x: state.firstPointer.startPosition.x,
                          y: state.firstPointer.startPosition.y
                      },
                      startDistance: distance(state.firstPointer.currentPosition, event.position),
                      activePointers
                  }
                : {
                      type: 'zoom' as const,
                      firstPointer: {
                          id: event.id,
                          startPosition: event.position,
                          currentPosition: event.position
                      },
                      secondPointer: {
                          id: event.id,
                          startPosition: event.position,
                          currentPosition: event.position
                      },
                      adjustZoomCenter: {
                          x: state.firstPointer.startPosition.x,
                          y: state.firstPointer.startPosition.y
                      },
                      startDistance: distance(state.firstPointer.currentPosition, event.position),
                      activePointers
                  };
        }
    }

    return state;
}

function distance(position1: Position, position2: Position): number {
    return Math.hypot(position1.x - position2.x, position1.y - position2.y);
}

function handlePointerMoveEvent(
    state: PointerActionState,
    event: PointerMoveEvent
): PointerActionState {
    // Ignore non active pointers.
    if (!state.activePointers.has(event.id)) {
        return state;
    }

    switch (state.type) {
        case 'started':
        case 'pan':

        case 'drag': {
            const offset = {
                x: event.position.x - state.firstPointer.startPosition.x,
                y: event.position.y - state.firstPointer.startPosition.y
            };

            const type =
                state.type === 'started'
                    ? state.selected
                        ? ('drag' as const)
                        : ('pan' as const)
                    : state.type;

            switch (type) {
                case 'pan': {
                    return {
                        ...state,
                        type: 'pan' as const,
                        firstPointer: {
                            ...state.firstPointer,
                            currentPosition: event.position
                        },
                        zoomCenterOffset: offset
                    };
                }

                case 'drag': {
                    // Override type inference.
                    const initialState = state as StartedState | DragState;
                    const project = initialState.project;
                    const cropRectangle = convertPercentageToCrops(
                        project.image.width,
                        project.image.height,
                        project.image.clippingMask
                    );

                    if (project.rotation % 2 === 1) {
                        [offset.x, offset.y] = [
                            offset.y,
                            project.disableRotating ? offset.x : -offset.x
                        ];
                    }

                    const x = cropRectangle.x - (offset.x * cropRectangle.width) / project.width;
                    const y = cropRectangle.y - (offset.y * cropRectangle.height) / project.height;

                    const adjustedCropRectangle = {
                        ...cropRectangle,
                        x: Math.min(project.image.width - cropRectangle.width, Math.max(0, x)),
                        y: Math.min(project.image.height - cropRectangle.height, Math.max(0, y))
                    };

                    return {
                        ...initialState,
                        type: 'drag' as const,
                        firstPointer: {
                            ...state.firstPointer,
                            currentPosition: event.position
                        },
                        cropRectangle: adjustedCropRectangle
                    };
                }
            }
        }

        case 'zoom': {
            return state;
        }

        case 'resize': {
            return state;
        }
    }

    return state;
}

function handlePointerUpEvent(
    state: PointerActionState,
    event: PointerUpEvent
): PointerActionState {
    // Ignore non active pointers.
    if (!state.activePointers.has(event.id)) {
        return state;
    }

    const activePointers = new Set(state.activePointers);
    activePointers.delete(event.id);

    if (activePointers.size === 0) {
        return createPointerActionState();
    }

    switch (state.type) {
        case 'standby':

        case 'started': {
            // eslint-disable-next-line no-console
            console.warn('DEBUG: This should not happen.');
            return state;
        }

        case 'ended': {
            return {
                ...state,
                activePointers
            };
        }

        case 'pan':

        case 'drag': {
            return activePointers.has(state.firstPointer.id)
                ? {
                      ...state,
                      activePointers
                  }
                : {
                      type: 'ended' as const,
                      activePointers
                  };
        }

        case 'zoom':

        case 'resize': {
            return activePointers.has(state.firstPointer.id) &&
                activePointers.has(state.secondPointer.id)
                ? {
                      ...state,
                      activePointers
                  }
                : {
                      type: 'ended' as const,
                      activePointers
                  };
        }
    }
}

function handlePointerCancelEvent(
    state: PointerActionState,
    event: PointerCancelEvent
): PointerActionState {
    // Ignore non active pointers.
    if (!state.activePointers.has(event.id)) {
        return state;
    }

    const activePointers = new Set(state.activePointers);
    activePointers.delete(event.id);

    if (activePointers.size === 0) {
        return createPointerActionState();
    }

    switch (state.type) {
        case 'standby':

        case 'started': {
            // eslint-disable-next-line no-console
            console.warn('DEBUG: This should not happen.');
            return state;
        }

        case 'ended': {
            return {
                ...state,
                activePointers
            };
        }

        case 'pan':

        case 'drag': {
            return activePointers.has(state.firstPointer.id)
                ? {
                      ...state,
                      activePointers
                  }
                : {
                      type: 'ended' as const,
                      activePointers
                  };
        }

        case 'zoom':

        case 'resize': {
            return activePointers.has(state.firstPointer.id) &&
                activePointers.has(state.secondPointer.id)
                ? {
                      ...state,
                      activePointers
                  }
                : {
                      type: 'ended' as const,
                      activePointers
                  };
        }
    }
}

export function handlePointerActionEvent(
    state: PointerActionState,
    event: PointerActionEvent
): PointerActionState {
    switch (event.type) {
        case 'pointerDown':
            return handlePointerDownEvent(state, event);
        case 'pointerMove':
            return handlePointerMoveEvent(state, event);
        case 'pointerUp':
            return handlePointerUpEvent(state, event);
        case 'pointerCancel':
            return handlePointerCancelEvent(state, event);
    }
}
