import React, { FC } from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { useScenes } from 'ts/client/common';
import Product from 'ts/client/gallery/components/Product';
import { isPackageItem } from 'ts/client/gallery/components/common';
import { useCurrency } from 'ts/client/gallery/hooks';
import { ImageCarousel } from 'ts/common/components';
import { Button } from 'ts/common/components/gallery';
import { useTranslate } from 'ts/common/hooks';
import { IImage } from './types';

interface IPackageDetail {
    image: IImage;
    isSmallView: boolean;
    onPackageSelected: (
        selectedPackage: SpApi.Client.IPriceSheetItem,
        selectedPhoto: IImage
    ) => void;
    pkg: SpApi.Client.IPriceSheetItem;
}

const PackageDetail: FC<IPackageDetail> = ({ image, isSmallView, onPackageSelected, pkg }) => {
    const { getDefaultSceneForProduct } = useScenes();

    if (!isPackageItem(pkg)) {
        return null;
    }

    return (
        <Flex data-testid="package-detail" flexDirection="column" gap="20px">
            {isSmallView && <PackageHeadingContent pkg={pkg} />}
            <Flex>
                <ImageCarousel
                    flex="0 1 40%"
                    images={[
                        <Product
                            key={image.id}
                            image={image}
                            priceSheetItem={pkg}
                            productScene={getDefaultSceneForProduct(pkg)}
                        />,
                        ...pkg.images.map((image) => image.displayUrl.large)
                    ]}
                />
                <Flex
                    data-testid="package-text-details"
                    flex={`0 1 ${!isSmallView ? '45%' : '60%'}`}
                    flexDirection="column"
                    gap="15px"
                    margin="0 auto 0 auto"
                >
                    {!isSmallView && <PackageHeadingContent pkg={pkg} />}
                    <Flex flexDirection="column" gap="10px" width={!isSmallView ? '60%' : '100%'}>
                        {pkg.items?.map((packageItem) => (
                            <PackageItemDetail key={packageItem.id} packageItem={packageItem} />
                        ))}
                    </Flex>
                    {!isSmallView && (
                        <SelectPackageButton onClick={() => onPackageSelected(pkg, image)} />
                    )}
                </Flex>
            </Flex>
            {isSmallView && <SelectPackageButton onClick={() => onPackageSelected(pkg, image)} />}
        </Flex>
    );
};

interface IPackageHeadingContent {
    pkg: SpApi.Client.IPriceSheetItem;
}

const PackageHeadingContent: React.FC<IPackageHeadingContent> = ({ pkg }) => {
    const $ = useCurrency();

    return (
        <>
            <Box>
                <Heading as="h4" color="brand.primary" size="lg">
                    {pkg.name}
                </Heading>
                <Text>{$(pkg.price ?? 0)}</Text>
            </Box>
            {pkg.description && <Text dangerouslySetInnerHTML={{ __html: pkg.description }} />}
        </>
    );
};

interface IPackageItemDetail {
    packageItem: SpApi.Client.IPriceSheetItemInPackage;
}

const PackageItemDetail: React.FC<IPackageItemDetail> = ({ packageItem }) => {
    const t = useTranslate('client.gallery.packageDetail');

    const getSubgroupContent = (packageItem: SpApi.Client.IPriceSheetItemInPackage) => {
        if (packageItem.type === 'price-sheet-item-digital') {
            if (packageItem.allGalleryPhotosDownload?.quantity ?? 0 > 0) {
                return t('allPhotosInGallery');
            }

            if (packageItem.allAlbumPhotosDownload?.quantity ?? 0 > 0) {
                return t('allPhotosInAlbum');
            }

            return t('individualPhotos');
        }

        if (packageItem.subgroup?.name === 'No Size Specified') {
            return null;
        }

        return packageItem.subgroup?.name;
    };

    const subgroupContent = getSubgroupContent(packageItem);

    return (
        <Flex data-testid="package-item-detail" gap="12px">
            <Text
                data-testid="package-item-quantity"
                flex="0 1 20%"
                fontWeight="600"
                textAlign="center"
            >
                {packageItem.quantity || packageItem.individualPhotoDownload?.quantity || '-'}
            </Text>
            <Flex flex="0 1 80%" flexDirection="column">
                <Text data-testid="package-item-subgroup" fontWeight="600">
                    {`${subgroupContent ? `${subgroupContent} | ` : ''}${packageItem.group.name}`}
                </Text>
                <Text as="i">{packageItem.name}</Text>
            </Flex>
        </Flex>
    );
};

interface ISelectPackageButton {
    onClick: () => void;
}

const SelectPackageButton: React.FC<ISelectPackageButton> = ({ onClick }) => {
    const t = useTranslate('client.gallery.packageDetail');

    return (
        <Button
            data-testid="select-package-cta"
            fullWidth
            onClick={onClick}
            text={t('selectPackage')}
            variant="primary"
        />
    );
};

export default PackageDetail;
