import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useScenes } from 'ts/client/common';
import { useCurrency } from 'ts/client/gallery/hooks';
import { useTranslate } from 'ts/common/hooks';
import type { ICrop } from 'ts/common/types';
import Product from './Product';
import ProductRenderGrid from './ProductRenderGrid';
import type { IBoundItem, IImage } from './types';

interface IProductDetailsProps {
    buyAllImages?: IImage[];
    crop: Nullable<ICrop>;
    digitalItem?: IBoundItem;
    digitalOrderPrice?: number;
    image: IImage;
    item: SpApi.Client.IPriceSheetItem;
    quantity: number;
    renderWidth?: number | string;
}

const ProductDetails: React.FC<IProductDetailsProps> = ({
    buyAllImages,
    crop,
    digitalItem,
    digitalOrderPrice,
    image,
    item,
    quantity,
    renderWidth
}) => {
    const $ = useCurrency();
    const t = useTranslate('client.gallery.productDetails');
    const { getDefaultSceneForProduct } = useScenes();
    const isBuyingAllPhotos = !!buyAllImages && !!buyAllImages.length;
    const isAllPhotosDigitalItem = digitalItem?.is_event || digitalItem?.is_album;
    const allPhotosDigitalName = `${item.name} (All ${
        digitalItem?.is_album ? 'Album' : digitalItem?.is_event ? 'Gallery' : ''
    } Photos)`;
    const nameToDisplay = isAllPhotosDigitalItem ? allPhotosDigitalName : item.name;

    return (
        <Flex data-testid="product-details" paddingTop="26px">
            {isBuyingAllPhotos ? (
                <ProductRenderGrid
                    selectedPriceSheetItem={item}
                    images={buyAllImages}
                    width={renderWidth ? renderWidth : '100%'}
                    padding="15px"
                    fontSize="16px"
                />
            ) : (
                <Product
                    crop={crop}
                    image={image}
                    productScene={getDefaultSceneForProduct(item)}
                    priceSheetItem={item}
                    width={renderWidth ? renderWidth : 'auto'}
                />
            )}

            <Flex direction="column" paddingLeft="20px" width="100%">
                <Flex justifyContent="space-between" alignItems="center">
                    <Text
                        fontSize="md"
                        fontFamily="open-sans, sans-serif"
                        fontWeight="semibold"
                        padding="2px"
                        data-testid="added-to-cart-product-subgroup"
                    >
                        {item.type === 'price-sheet-item-package'
                            ? t('package')
                            : !item.subgroup || item.subgroup?.name === null
                            ? item.group.name
                            : item.subgroup?.name}
                    </Text>
                    <Text
                        fontSize="md"
                        fontFamily="open-sans, sans-serif"
                        fontWeight="semibold"
                        data-testid="added-to-cart-product-price"
                    >
                        {$(
                            item.type === 'price-sheet-item-digital' && item.isDigitalDownload
                                ? digitalOrderPrice ?? 0
                                : (item.price ?? 0) * quantity
                        )}
                    </Text>
                </Flex>
                <Text
                    fontSize="md"
                    fontFamily="open-sans, sans-serif"
                    padding="2px"
                    data-testid="added-to-cart-product-name"
                >
                    {nameToDisplay}
                </Text>
                <Text
                    fontSize="md"
                    fontFamily="open-sans, sans-serif"
                    padding="2px"
                    data-testid="added-to-cart-product-qty"
                >
                    {`${t('qty')} ${quantity}`}
                </Text>
            </Flex>
        </Flex>
    );
};

export default ProductDetails;
