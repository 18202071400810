export default [
    '$scope',
    'SPModal',
    'spFreeDigitals',
    function HomeController($scope, SPModal) {
        /**
         * If the visitor is the Event Contact, and they've not enabled Admin Mode, and they
         * haven't already declined to enter Admin Mode, present them a modal where they can
         * either enter the Admin Mode PIN, or remain in public mode.
         */
        if (
            $scope.userState.isVisitorEventContact &&
            !$scope.userState.isAdminModeEnabled &&
            !$scope.userState.hasDeclinedAdminMode
        ) {
            SPModal.open('main', {
                partialDirectory: 'admin-auth',
                queue: true
            });
        }

        $scope.getDownloadTextHeaderKey = function getDownloadTextHeaderKey() {
            return 'downloadAll';
        };

        $scope.getDownloadTextProcessingKey = function getDownloadTextProcessingKey() {
            return 'galleryDownloadProcessing';
        };

        $scope.closeShareModal = function closeShareModal() {
            SPModal.close();
        };
    }
];
