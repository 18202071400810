import React, { FC } from 'react';
import { Box, Flex, IconButton, IconButtonProps, Link } from '@chakra-ui/react';
import {
    SocialNetworkIconFacebook,
    SocialNetworkIconInstagram,
    SocialNetworkIconPinterest,
    SocialNetworkIconTwitter
} from 'ts/common/components/icons';

interface ISocialLinks {
    facebookLink?: string;
    instagramLink?: string;
    pinterestLink?: string;
    twitterLink?: string;
    translateFilter: SpAngularJs.ITranslateFilter;
}

const SocialLinks: FC<ISocialLinks> = ({
    facebookLink,
    instagramLink,
    pinterestLink,
    twitterLink,
    translateFilter
}) => {
    return (
        <Flex justifyContent="center">
            {facebookLink && (
                <SocialLink
                    href={facebookLink}
                    icon={<SocialNetworkIconFacebook width="18px" height="18px" />}
                    label={translateFilter('linkLabelFacebook')}
                />
            )}
            {twitterLink && (
                <SocialLink
                    href={twitterLink}
                    icon={<SocialNetworkIconTwitter width="18px" height="18px" />}
                    label={translateFilter('linkLabelInstagram')}
                />
            )}
            {instagramLink && (
                <SocialLink
                    href={instagramLink}
                    icon={<SocialNetworkIconInstagram width="18px" height="18px" />}
                    label={translateFilter('linkLabelPinterest')}
                />
            )}
            {pinterestLink && (
                <SocialLink
                    href={pinterestLink}
                    icon={<SocialNetworkIconPinterest width="18px" height="18px" />}
                    label={translateFilter('linkLabelTwitter')}
                />
            )}
        </Flex>
    );
};

type SocialLinkProps = {
    href: string;
    label: string;
    icon: IconButtonProps['icon'];
};

const SocialLink = ({ href, label, icon }: SocialLinkProps) => {
    return (
        <Box paddingRight="12px" paddingLeft="12px">
            <IconButton
                as={Link}
                href={href}
                target="_blank"
                color="brand.primaryMedium"
                backgroundColor="brand.primaryMedium"
                fill="brand.primary"
                colorScheme="primary"
                border="none"
                icon={icon}
                size="md"
                aria-label={label}
                width="48px"
                height="48px"
                _focus={{
                    boxShadow: 'none'
                }}
                _focusVisible={{
                    boxShadow: 'outline'
                }}
            />
        </Box>
    );
};

export default SocialLinks;
