import React, { FC } from 'react';
import { Heading } from '@chakra-ui/react';

interface IStudioHeadingProps {
    text: string;
    variant: 'dark' | 'light' | 'primary';
}

const StudioHeading: FC<IStudioHeadingProps> = ({ text, variant }) => {
    return (
        <Heading as="h2" margin={0} size="sm" variant={variant}>
            {text}
        </Heading>
    );
};

export default StudioHeading;
