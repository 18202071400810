import React, { useState } from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import { useTranslate } from 'ts/common/hooks';

interface ICardImageProps {
    frontImageUrl: string;
    backImageUrl?: string;
    onClick?: () => void;
}

const CardImage: React.FC<ICardImageProps> = ({ frontImageUrl, backImageUrl, onClick }) => {
    const t = useTranslate('client.gallery.addToCartSidePanel.cardImage');

    const [showBacksideImage, setShowBacksideImage] = useState(false);

    return (
        <Flex
            alignItems="center"
            background="gray.25"
            borderRadius="8px"
            cursor="pointer"
            data-testid="card-image-container"
            justifyContent="center"
            onClick={onClick}
            onMouseEnter={() => {
                if (backImageUrl) {
                    setShowBacksideImage(true);
                }
            }}
            onMouseLeave={() => {
                if (backImageUrl) {
                    setShowBacksideImage(false);
                }
            }}
            overflow="hidden"
            padding="28px"
            style={{ aspectRatio: '1 / 1' }}
            width="100%"
        >
            <Image
                alt={t('cardImageAlt')}
                data-testid="card-image"
                maxHeight="100%"
                maxWidth="100%"
                src={backImageUrl && showBacksideImage ? backImageUrl : frontImageUrl}
                fallback={<Box backgroundColor="grey"></Box>}
            ></Image>
        </Flex>
    );
};

export default CardImage;
