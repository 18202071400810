import React, { FC } from 'react';
import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
import Button from 'ts/common/components/gallery/Button';
import CoverPhoto from 'ts/common/components/gallery/CoverPhoto';
import GalleryHeading from 'ts/common/components/gallery/GalleryHeading';
import StudioHeading from 'ts/common/components/gallery/StudioHeading';
import { ILayoutProps, contentAnimation, coverAnimation } from './BaseLayout';

const getBorder = (colorScheme: 'dark' | 'light') =>
    `0.375rem solid ${
        colorScheme === 'dark' ? 'var(--chakra-colors-gray-700)' : 'var(--chakra-colors-white)'
    }`;

const getHexagonDecorationStyle = (hexagonWidth: number) => ({
    height: `${hexagonWidth / 2}rem`,
    marginTop: `-${hexagonWidth / 2}rem`,
    position: 'absolute' as const,
    transform: 'rotate(45deg) skew(-12deg, -12deg) scale(1.1663)',
    width: `${hexagonWidth / 2}rem`
});

const hexagonWidthValues = { base: 17.5, lg: 26.5 };

const SymmetryLayout: FC<ILayoutProps> = ({
    galleryTitle,
    brandName,
    coverPhoto,
    coverPhotoUrl,
    buttonText,
    buttonTextLarge,
    colorScheme,
    onClick
}) => {
    const hexagonWidth = useBreakpointValue(hexagonWidthValues) ?? hexagonWidthValues.base;

    return (
        <Box position="absolute" width="full" height="full" onClick={onClick}>
            <Flex
                alignItems="center"
                background={colorScheme === 'dark' ? 'whiteAlpha.500' : 'blackAlpha.500'}
                flexDirection="column"
                gap={6}
                height="full"
                overflowY="auto"
                paddingX={useBreakpointValue({ base: 4, lg: 6 })}
                paddingY={6}
                position="absolute"
                textAlign="center"
                width="full"
                animation={contentAnimation}
                opacity={0}
            >
                <Flex
                    alignItems="flex-start"
                    alignSelf={useBreakpointValue({ base: 'center', lg: 'flex-start' })}
                    flex={1}
                >
                    <StudioHeading text={brandName} variant={colorScheme} />
                </Flex>
                <Flex
                    alignItems="center"
                    justifyContent="center"
                    minHeight={{ base: '20rem', lg: '32rem' }}
                >
                    <Flex
                        alignItems="center"
                        justifyContent="center"
                        borderLeft={getBorder(colorScheme)}
                        borderRight={getBorder(colorScheme)}
                        padding={4}
                        height={`${hexagonWidth / 2}rem`}
                        width={`${hexagonWidth}rem`}
                    >
                        <Box
                            {...getHexagonDecorationStyle(hexagonWidth)}
                            borderLeft={getBorder(colorScheme)}
                            borderTop={getBorder(colorScheme)}
                            marginTop={`-${hexagonWidth / 2}rem`}
                            role="presentation"
                        />
                        <GalleryHeading
                            size={useBreakpointValue({ base: 'sm', lg: 'md' }) ?? 'sm'}
                            text={galleryTitle}
                            variant={colorScheme}
                        />
                        <Box
                            {...getHexagonDecorationStyle(hexagonWidth)}
                            borderBottom={getBorder(colorScheme)}
                            borderRight={getBorder(colorScheme)}
                            marginTop={`${hexagonWidth / 2}rem`}
                            role="presentation"
                        />
                    </Flex>
                </Flex>
                <Flex
                    alignItems={useBreakpointValue({ base: 'flex-end', lg: 'flex-start' })}
                    alignSelf={useBreakpointValue({ base: 'stretch', lg: 'center' })}
                    flex={1}
                >
                    <Button
                        fullWidth={useBreakpointValue({ base: true, lg: false })}
                        text={buttonText}
                        textLarge={buttonTextLarge}
                        variant={`secondary ${colorScheme}` as const}
                    />
                </Flex>
            </Flex>

            <Box width="full" height="full" animation={coverAnimation}>
                <CoverPhoto coverPhoto={coverPhoto} coverPhotoUrl={coverPhotoUrl} />
            </Box>
        </Box>
    );
};

export default SymmetryLayout;
