import React from 'react';
import { BoxProps } from 'sp-ui';
import PRICESHEET_GROUP_TYPES from 'Sp/PriceSheet/Group';
import { IImage } from 'ts/client/gallery/components';
import { isSingleItem } from 'ts/client/gallery/components/common';
import { ornamentsOverImageUrl } from 'ts/common/assets';
import type { IPreviewBackground } from 'ts/common/components/editor/Preview';
import { ProductOrnamentsOverlay, ProductPreview } from 'ts/common/components/product';
import { RenderTextDisplay } from 'ts/common/components/product/CategoryTextDisplay/RenderTextDisplay';
import { DigitalRender } from 'ts/common/components/product/DigitalRender';
import { PerspectiveRender } from 'ts/common/components/product/PerspectiveRender';
import { FrameProps, SizeVariant } from 'ts/common/components/product/types';
import type { IProductPhoto } from 'ts/common/components/product/types';
import { getImageAspectRatio, getProductItem } from 'ts/common/components/product/utils';
import type { ICrop } from 'ts/common/types';
import PackageAccent from './PackageAccent';
import { grayBackdrop } from './assets/backdrops';

enum Effect {
    BlackAndWhite = 'black-and-white',
    Glossy = 'glossy',
    Lustre = 'lustre',
    Matte = 'deep-matte'
}

const getPackageRenderSize = (photoBounds: IProductPhoto) => {
    return {
        depth: 0,
        height: photoBounds.height,
        width: photoBounds.width
    };
};

interface IProductRenderProps {
    perspectiveRenderAllowed?: boolean;
    useOverlay?: boolean;
    crop?: Nullable<ICrop>;
    variant?: SizeVariant;
    priceSheetItem: SpApi.Client.IPriceSheetItem;
    hideBackdrop?: boolean;
    productScene?: IPreviewBackground;
    image: IImage;
    productPreviewProps: BoxProps;
    renderCustomItem?: boolean;
}

const ProductRender: React.FC<IProductRenderProps> = ({
    perspectiveRenderAllowed = true,
    useOverlay = false,
    variant = SizeVariant.Square,
    hideBackdrop = false,
    crop,
    priceSheetItem,
    productScene,
    image,
    productPreviewProps,
    renderCustomItem
}) => {
    const {
        group: { groupType },
        productMasks
    } = priceSheetItem;

    const perspectiveBackdrop = hideBackdrop ? '' : grayBackdrop;
    const photoBounds = { height: image.height, width: image.width };
    const photoUrl = image.url;

    const productItem = getProductItem({
        boundsName:
            groupType === PRICESHEET_GROUP_TYPES.TYPE.ORNAMENTS &&
            priceSheetItem.subgroup &&
            priceSheetItem.subgroup.bounds
                ? priceSheetItem.subgroup.bounds
                : priceSheetItem.boundsName,
        crop,
        photoBounds,
        priceSheetGroupType: groupType,
        priceSheetItemSize: isSingleItem(priceSheetItem)
            ? priceSheetItem.size
            : getPackageRenderSize(photoBounds),
        productMasks
    });

    const frameProps: FrameProps | undefined =
        groupType === 'framed-prints' || groupType === 'matted-framed-prints'
            ? {
                  frameColor: priceSheetItem.frameColor ?? null,
                  frameImageUrl: priceSheetItem.productMasks?.frameImageUrl,
                  frameOverlap: priceSheetItem.frameOverlap ?? null,
                  frameWidth: priceSheetItem.frameWidth ?? null
              }
            : undefined;

    if (frameProps && groupType === 'matted-framed-prints') {
        frameProps.matColor = priceSheetItem.matColor ?? null;
        frameProps.matHeight = priceSheetItem.matHeight ?? null;
        frameProps.matOverlap = priceSheetItem.matOverlap ?? null;
        frameProps.matWidth = priceSheetItem.matWidth ?? null;
    }

    return perspectiveRenderAllowed &&
        (groupType === PRICESHEET_GROUP_TYPES.TYPE.CANVAS ||
            groupType === PRICESHEET_GROUP_TYPES.TYPE.ACRYLIC_BLOCKS) ? (
        <PerspectiveRender
            isBlackAndWhite={image.filterBlackAndWhite}
            isAcrylicBlock={groupType === PRICESHEET_GROUP_TYPES.TYPE.ACRYLIC_BLOCKS}
            crop={productItem.crop}
            exhibition="solo"
            perspectiveBackdrop={perspectiveBackdrop}
            photo={photoBounds}
            photoUrl={photoUrl}
        />
    ) : groupType === PRICESHEET_GROUP_TYPES.TYPE.DIGITALS ? (
        <DigitalRender photoBounds={photoBounds} photoUrl={photoUrl} />
    ) : groupType === PRICESHEET_GROUP_TYPES.TYPE.CUSTOM ? (
        <RenderTextDisplay
            text={renderCustomItem ? priceSheetItem.name : priceSheetItem.group.name}
        />
    ) : (
        <>
            {groupType === PRICESHEET_GROUP_TYPES.TYPE.PACKAGES && (
                <PackageAccent aspectRatio={getImageAspectRatio(photoBounds)} />
            )}
            <ProductPreview
                frame={frameProps}
                item={productItem}
                photo={photoBounds}
                photoUrl={photoUrl}
                previewBackground={productScene}
                previewEffects={{
                    blackAndWhite:
                        image.filterBlackAndWhite ||
                        priceSheetItem.effectName === Effect.BlackAndWhite,
                    glossy: priceSheetItem.effectName === Effect.Glossy,
                    lustre: priceSheetItem.effectName === Effect.Lustre,
                    matte: priceSheetItem.effectName === Effect.Matte,
                    transparent: groupType === PRICESHEET_GROUP_TYPES.TYPE.ACRYLIC_BLOCKS
                }}
                variant={variant}
                {...productPreviewProps}
            />
            {groupType === PRICESHEET_GROUP_TYPES.TYPE.ORNAMENTS && useOverlay && (
                <ProductOrnamentsOverlay backgroundImage={ornamentsOverImageUrl} opacity={0.7} />
            )}
        </>
    );
};

export default ProductRender;
