import React, { FC, useMemo } from 'react';
import type { LandingLayoutType } from 'ts/client/types';
import type { ICoverPhoto } from 'ts/common/types';
import AccentLayout from './AccentLayout';
import DetailLayout from './DetailLayout';
import FoundationLayout from './FoundationLayout';
import HeadlineLayout from './HeadlineLayout';
import LimelightLayout from './LimelightLayout';
import MarqueeLayout from './MarqueeLayout';
import SymmetryLayout from './SymmetryLayout';

export const coverAnimation = '2s ease-in-out fadeIn';
export const contentAnimation = '1s ease-in-out fadeIn 2s forwards';

export interface ILayout {
    galleryTitle: string;
    brandName: string;
    eventBrandLogo?: Nullable<SpApi.IBrandLogoImage>;
    logoAltText: string;
    colorScheme: 'dark' | 'light';
    coverPhotoUrl: Nullable<string>;
    buttonText: string;
    buttonTextLarge?: string;
    onClick: () => void;
}

export type IBaseLayoutProps = ILayout & {
    landingLayoutType: LandingLayoutType;
    coverPhoto: ICoverPhoto | false;
};

export type ILayoutProps = ILayout & {
    coverPhoto: ICoverPhoto;
};

const BaseLayout: FC<IBaseLayoutProps> = ({ landingLayoutType, coverPhoto, ...layoutProps }) => {
    const SelectedLayout = useMemo(() => {
        switch (landingLayoutType) {
            case 'full-bottom-alt':
                return FoundationLayout;
            case 'split':
                return DetailLayout;
            case 'full-center':
                return MarqueeLayout;
            case 'full-bottom':
                return LimelightLayout;
            case 'basic':
                return AccentLayout;
            case 'hexagon':
                return SymmetryLayout;
            default:
                return HeadlineLayout;
        }
    }, [landingLayoutType]);

    if (!coverPhoto) {
        return <HeadlineLayout {...layoutProps} />;
    }

    return <SelectedLayout {...layoutProps} coverPhoto={coverPhoto} />;
};

export default BaseLayout;
