import { ZIP_BUNDLE_EVENTS } from 'Sp/Angular/Events/Client/Gallery';

export default [
    '$rootScope',
    '$scope',
    '$interval',
    '$routeParams',
    '$window',
    'spAppData',
    'spClientCredentials',
    'SPClientData',
    function BundleController(
        $rootScope,
        $scope,
        $interval,
        $routeParams,
        $window,
        spAppData,
        spClientCredentials,
        SPClientData
    ) {
        const PAGE_STATE = {
            AUTHORIZE: 'authorize',
            BROWSER_GOOGLE_SEARCH_APP: 'browser-google-search-app',
            PENDING: 'pending',
            READY: 'ready',
            TIMEOUT: 'timeout'
        };
        // If the ZIP Bundle has no email set, there's no reason for us to
        // present the form for authentication.  Instead, just make a
        // separate request to fetch the ZIP Bundle data.
        const clientCredentialsEmail = spClientCredentials.get().readableEmail;
        const clientCredentialsZipBundleKey = spClientCredentials.get().zipBundleKey;
        let pollingTimer = null;

        $scope.PAGE_STATE = PAGE_STATE;
        $scope.pageState = PAGE_STATE.AUTHORIZE;
        $scope.zipBundleAppData = spAppData.get('zipBundleData');
        $scope.zipBundleData = null;
        $scope.showHelp = false;
        $scope.credentials = { email: clientCredentialsEmail };
        $scope.errors = {};

        const isGoogleSearchApp = !!$window.navigator.userAgent.match(/(?:GSA)\/(\d+(\.?_?\d+)+)/i);

        if (isGoogleSearchApp) {
            $scope.pageState = PAGE_STATE.BROWSER_GOOGLE_SEARCH_APP;
            return;
        }

        if (!$scope.zipBundleAppData.settings.hasEmail || clientCredentialsEmail) {
            authenticateBundle();
        }

        $scope.authenticateBundle = authenticateBundle;

        $scope.downloadFile = function downloadFile(zipBundleFile) {
            SPClientData.logBundleDownloaded(
                $routeParams.zipBundleId,
                function doNothing() {
                    // Success
                },
                function doNothing() {
                    // Error
                }
            );

            $window.location.href = zipBundleFile.url;
        };

        function authenticateBundle() {
            SPClientData.bundleAuth(
                $routeParams.zipBundleId,
                $scope.credentials.email,
                $scope.credentials.doesAcceptTerms,
                function withSuccessResponse({ zipBundleData }) {
                    $scope.zipBundleData = zipBundleData;

                    if (zipBundleData.ready) {
                        $scope.pageState = PAGE_STATE.READY;

                        return;
                    }

                    pollForZipBundle();

                    $scope.pageState = PAGE_STATE.PENDING;
                },
                function withErrorResponse(response) {
                    if ($scope.pageState === PAGE_STATE.AUTHORIZE) {
                        $scope.errors = response.errors;
                    } else {
                        if (response.errors.email && response.errors.email.notSame) {
                            $scope.errors = null;
                            $scope.credentials.email = null;
                            $scope.pageState = PAGE_STATE.AUTHORIZE;
                        }
                    }
                }
            );
        }

        function pollForZipBundle() {
            const offZipBundleGot = $rootScope.$on(ZIP_BUNDLE_EVENTS.GOT, (_, response) => {
                isZipBundleReady = response.zipBundleStatusId === 3;

                if (isZipBundleReady) {
                    authenticateBundle();
                }
            });
            let isZipBundleReady = false;

            if (!pollingTimer) {
                const POLLING_INTERVAL = 5000;
                const MAX_RUN = 60000 / POLLING_INTERVAL;

                pollingTimer = $interval(
                    () => {
                        const zipBundleKey =
                            clientCredentialsZipBundleKey || $scope.zipBundleData.bundleKey;

                        if (zipBundleKey && !isZipBundleReady) {
                            $rootScope.$emit(ZIP_BUNDLE_EVENTS.GET, {
                                zipBundleId: $routeParams.zipBundleId,
                                zipBundleKey
                            });
                        } else {
                            $interval.cancel(pollingTimer);

                            pollingTimer = null;

                            offZipBundleGot();
                        }
                    },
                    POLLING_INTERVAL,
                    MAX_RUN
                );

                pollingTimer
                    .then(() => {
                        $scope.pageState = PAGE_STATE.TIMEOUT;
                        pollingTimer = null;

                        offZipBundleGot();
                    })
                    .catch(() => {
                        $scope.pageState = PAGE_STATE.TIMEOUT;
                    });
            }
        }
    }
];
