import React, { FC } from 'react';
import { Box, BoxProps, Flex } from '@chakra-ui/react';

interface IPackageAccentItem extends BoxProps {
    aspectRatio: number;
}

const PackageAccentItem: FC<IPackageAccentItem> = ({ aspectRatio, ...props }) => {
    return (
        <Box
            height={aspectRatio < 1 ? '90%' : 'unset'}
            position="absolute"
            sx={{ aspectRatio: aspectRatio }}
            width={aspectRatio >= 1 ? '90%' : 'unset'}
            {...props}
        />
    );
};

interface IPackageAccent {
    aspectRatio: number;
}

const PackageAccent: FC<IPackageAccent> = ({ aspectRatio }) => {
    return (
        <Flex
            alignItems="center"
            height="100%"
            justifyContent="center"
            position="absolute"
            top={0}
            width="100%"
        >
            <PackageAccentItem
                aspectRatio={aspectRatio}
                background="gray.400"
                filter="drop-shadow(0px 1.5px 1.5px rgba(0, 0, 0, 0.36)) drop-shadow(0px 1.1px 7.5px rgba(0, 0, 0, 0.2))"
                transform="skew(-5.4deg, 4.5deg)"
            />
            <PackageAccentItem
                aspectRatio={aspectRatio}
                background="gray.200"
                filter="drop-shadow(0px 1.5px 1.5px rgba(0, 0, 0, 0.25));"
                transform="skew(4.5deg, -5.4deg)"
            />
        </Flex>
    );
};

export default PackageAccent;
