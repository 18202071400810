import { NOTIFICATION_EVENTS } from 'Sp/Angular/Events/Client/Notification';
import { NOTIFICATION_TYPES } from 'Sp/Notification';
import Api from 'Sp/Api';

export default [
    '$rootScope',
    'spApiHypermedia',
    'spApiHttp',
    'spAuthorizationToken',
    'spEventBus',
    function NotificationDataStore(
        $rootScope,
        spApiHypermedia,
        spApiHttp,
        spAuthorizationToken,
        spEventBus
    ) {
        spEventBus.on(NOTIFICATION_EVENTS.FAVORITES.SEND, (request) => {
            spAuthorizationToken.getAuthorizationTokenForEvent().then((token) => {
                spApiHypermedia
                    .getLink([
                        { type: Api.BRAND_TYPE, id: $rootScope.brandData.id },
                        { type: Api.EVENT_TYPE, id: $rootScope.eventData.id },
                        { type: Api.NOTIFICATION_TYPE }
                    ])
                    .then(({ href }) =>
                        spApiHttp.post({
                            url: href,
                            data: {
                                ...request,
                                notificationType: NOTIFICATION_TYPES.FAVORITES,
                                type: Api.NOTIFICATION_TYPE
                            },
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        })
                    )
                    .then(() => {
                        spEventBus.broadcast(NOTIFICATION_EVENTS.FAVORITES.SENT);
                    });
            });
        });
    }
];
