import spMusicTemplate from '../../templates/components/sp-music.nghtml';

/**
 * @ngdoc component
 * @name sp.client.gallery.component:spMusic
 * @requires sp.client.common.service:SPMusicData
 * @requires $http
 * @requires $httpParamSerializer
 * @requires $element
 *
 * @description
 * Renders the music controls.
 *
 */
export default {
    template: spMusicTemplate,
    controller: [
        'SPMusicData',
        '$http',
        '$httpParamSerializer',
        '$element',
        function spMusicController(SPMusicData, $http, $httpParamSerializer, $element) {
            const $ctrl = this;

            $ctrl.$onDestroy = $onDestroy;
            $ctrl.$onInit = $onInit;
            $ctrl.preventHideControls = false;
            $ctrl.musicIsPlaying = false;
            $ctrl.musicIsMuted = true;
            $ctrl.volumeLevel = 0.5;

            $ctrl.updateVolume = updateVolume;
            $ctrl.startOrPauseMusic = startOrPauseMusic;

            function $onInit() {
                if (SPMusicData.getAsync) {
                    SPMusicData.getAsync().then((data) => {
                        setupMusic(data);
                    });
                } else if (SPMusicData.get) {
                    setupMusic(SPMusicData.get());
                }
            }

            function $onDestroy() {
                $ctrl.musicIsPlaying = false;
                $ctrl.musicIsMuted = true;
                $ctrl.currentAudio.pause();
                $ctrl.currentAudio.removeEventListener('ended', $ctrl.loopMusic);
                $ctrl.currentAudio = null;
            }

            function updateVolume(level) {
                $ctrl.volumeLevel = level;
                $ctrl.currentAudio.volume = level;
            }

            function startOrPauseMusic() {
                if ($ctrl.musicIsPlaying) {
                    $ctrl.currentAudio.pause();
                    $ctrl.musicIsPlaying = false;
                    $ctrl.musicIsMuted = true;
                } else {
                    $ctrl.currentAudio.play();
                    $ctrl.musicIsPlaying = true;
                    $ctrl.musicIsMuted = false;

                    SPMusicData.setCurrentSongId($ctrl.currentSong.id);
                }
            }

            function setupMusic(musicData) {
                if (
                    !musicData.isMusicAllowed ||
                    ((typeof musicData.playlistSongs === undefined &&
                        musicData.playlistSongs.length > 0) ||
                        musicData.playlistSongs === false)
                ) {
                    return;
                }

                const autoPlay = musicData.musicAutoPlay;
                const playlistSongs = musicData.playlistSongs;
                const musicAuthToken = musicData.musicTsmAuthToken;
                let currentSongIndex = 0;

                $ctrl.addEventEnded = () => {
                    $ctrl.currentAudio.addEventListener('ended', $ctrl.loopMusic);
                };

                $ctrl.loopMusic = () => {
                    $ctrl.currentAudio.currentTime = 0;

                    $http.post(
                        `https://api.triplescoopmusic.com/v1/analytics/songs/${
                            playlistSongs[currentSongIndex].id
                        }/play`,
                        $httpParamSerializer({
                            authToken: musicAuthToken
                        }),
                        {
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            }
                        }
                    );

                    const nextSongIndex = currentSongIndex + 1;

                    if (!playlistSongs[nextSongIndex]) {
                        $ctrl.currentSong = playlistSongs[0];
                        SPMusicData.setCurrentSongId($ctrl.currentSong.id);

                        $ctrl.currentAudio = new Audio([$ctrl.currentSong.m]);
                        $ctrl.addEventEnded();
                        $ctrl.currentAudio.play();
                        currentSongIndex = 0;
                    } else {
                        $ctrl.currentSong = playlistSongs[nextSongIndex];
                        SPMusicData.setCurrentSongId($ctrl.currentSong.id);

                        $ctrl.currentAudio = new Audio([$ctrl.currentSong.m]);
                        $ctrl.addEventEnded();
                        $ctrl.currentAudio.play();
                        currentSongIndex++;
                    }
                };

                $ctrl.currentSong = playlistSongs[0];
                $ctrl.currentAudio = new Audio([$ctrl.currentSong.m]);
                $ctrl.addEventEnded();
                SPMusicData.setCurrentSongId($ctrl.currentSong.id);
                $element.addClass('ready');

                if (autoPlay && $ctrl.currentAudio && $ctrl.currentAudio.paused) {
                    const promise = $ctrl.currentAudio.play();

                    if (promise !== undefined) {
                        promise
                            .then(() => {
                                $ctrl.currentAudio.play();
                                $ctrl.musicIsPlaying = true;
                                $ctrl.musicIsMuted = false;
                            })
                            .catch(() => {
                                $ctrl.musicIsPlaying = false;
                                $ctrl.musicIsMuted = true;
                            });
                    }
                }
            }
        }
    ]
};
