import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { ShoppingCart } from 'react-feather';
import ModalHeader from 'ts/common/components/gallery/ModalHeader';
import ModalButtonFooter from './ModalButtonFooter';

interface IEmptyCartModal {
    primaryButtonAction: () => void;
    secondaryButtonAction: () => void;
    translateFilter: SpAngularJs.ITranslateFilter;
}

const EmptyCartModal: FC<IEmptyCartModal> = ({
    primaryButtonAction,
    secondaryButtonAction,
    translateFilter: t
}) => {
    return (
        <Box>
            <Box paddingBottom="120px">
                <ModalHeader
                    modalIcon={ShoppingCart}
                    headerText={t('emptyShoppingCartQuestion')}
                    headerSubText={t('emptyShoppingCartDescription')}
                />
            </Box>
            <ModalButtonFooter
                secondaryButtonAction={secondaryButtonAction}
                primaryButtonAction={primaryButtonAction}
                primaryButtonText={t('no')}
                primaryLoadingText={t('no')}
                secondaryButtonText={t('yes')}
            />
        </Box>
    );
};

export default EmptyCartModal;
