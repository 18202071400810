var serviceName = 'SPPhotoDownload';

export default [
    '$rootScope',
    '$cacheFactory',
    '$window',
    '$timeout',
    'SPClientData',
    'SPModal',
    function SPPhotoDownloadService(
        $rootScope,
        $cacheFactory,
        $window,
        $timeout,
        SPClientData,
        SPModal
    ) {
        var self = this;
        var $cache = $cacheFactory(serviceName);
        var needsEmail;
        var pin;
        var needsPin;
        var options;

        /**
         * Call this method to check on the userState and determine if the user needs Email or PIN for downloading
         */
        this.checkUserState = function checkUserState() {
            needsEmail = !$rootScope.userState.hasUserEmail;

            pin = $cache.get('pin');

            needsPin =
                $rootScope.eventData.settings.freeDigitalsPinRequired &&
                (typeof pin !== 'string' || pin.length === 0);
        };

        this.checkUserState();

        /**
         * Call this method to download an entire album
         *
         * @param downloadOptions   The photo & album data necessary to call SPClientData: album, email, pin
         */
        this.downloadAlbum = function downloadAlbum(downloadOptions, errorCallback) {
            options = downloadOptions;
            options.pin =
                typeof options.pin !== 'string' || options.pin.length === 0 ? pin : options.pin;

            if (
                (!needsEmail && !needsPin) ||
                (needsEmail && typeof options.email === 'string') ||
                (needsPin && typeof options.pin === 'string')
            ) {
                return $timeout(function download() {
                    SPClientData.downloadAlbum(
                        options.album,
                        options.email,
                        options.pin,
                        function withSuccessResponse(response) {
                            self.successCallback(response);
                        },
                        function withErrorResponse(response) {
                            onDownloadError(response, self.downloadAlbum, errorCallback);
                        }
                    );
                }, 50);
            }

            self.openModal(self.downloadAlbum);
        };

        /**
         * Call this method to download all photos
         *
         * @param downloadOptions   The photo & album data necessary to call SPClientData: album, email, pin
         */
        this.downloadAllPhotos = function downloadAllPhotos(downloadOptions, errorCallback) {
            options = downloadOptions;

            // If the downloadOptions.pin doesn't exist, check the global pin for a value.
            options.pin =
                typeof options.pin !== 'string' || options.pin.length === 0 ? pin : options.pin;

            if (
                (!needsEmail && !needsPin) ||
                (needsEmail && typeof options.email === 'string') ||
                (needsPin && typeof options.pin === 'string')
            ) {
                return $timeout(function download() {
                    SPClientData.downloadAllPhotos(
                        options.email,
                        options.pin,
                        function withSuccessResponse(response) {
                            self.successCallback(response);
                        },
                        function withErrorResponse(response) {
                            onDownloadError(response, self.downloadAllPhotos, errorCallback);
                        }
                    );
                }, 50);
            }

            self.openModal(self.downloadAllPhotos);
        };

        /**
         * Call this method to download multiple selected photos
         *
         * @param downloadOptions   The photo & album data necessary to call SPClientData: album, email, pin
         */
        this.downloadPhotos = function downloadPhotos(downloadOptions, errorCallback) {
            options = downloadOptions;
            options.pin =
                typeof options.pin !== 'string' || options.pin.length === 0 ? pin : options.pin;

            if (
                (!needsEmail && !needsPin) ||
                (needsEmail && typeof options.email === 'string') ||
                (needsPin && typeof options.pin === 'string')
            ) {
                return $timeout(function download() {
                    SPClientData.downloadPhotos(
                        options.photos,
                        options.email,
                        options.pin,
                        function withSuccessResponse(response) {
                            self.successCallback(response);
                        },
                        function withErrorResponse(response) {
                            onDownloadError(response, self.downloadPhotos, errorCallback);
                        }
                    );
                }, 50);
            }

            self.openModal(self.downloadPhotos);
        };

        /**
         * Call this method to download an a single photo
         *
         * @param downloadOptions   The photo & album data necessary to call SPClientData: album, email, pin
         */
        this.downloadPhoto = function downloadPhoto(downloadOptions, errorCallback) {
            options = downloadOptions;
            options.pin =
                typeof options.pin !== 'string' || options.pin.length === 0 ? pin : options.pin;

            if (
                (!needsEmail && !needsPin) ||
                (needsEmail && typeof options.email === 'string') ||
                (needsPin && typeof options.pin === 'string')
            ) {
                return $timeout(function download() {
                    SPClientData.downloadPhoto(
                        options.photo,
                        options.email,
                        options.pin,
                        self.successCallback,
                        function withErrorResponse(response) {
                            onDownloadError(response, self.downloadPhoto, errorCallback);
                        }
                    );
                }, 50);
            }

            self.openModal(self.downloadPhoto);
        };

        /**
         * Called on SPClientData.download success
         *
         * @param response  Response value from SPClientData as string, either 'direct' or 'queued'
         */
        this.successCallback = function successCallback(response) {
            self.checkUserState();

            switch (response.action) {
                case 'direct': // Always an individual photo
                    if (typeof response.download_url !== 'string') {
                        break;
                    }

                    // Mobile Download
                    if (window.Modernizr.touchevents) {
                        SPModal.open(
                            'mobile-download',
                            {
                                partialDirectory: serviceName.toLowerCase(),
                                modalData: {
                                    downloadUrl: response.download_url
                                }
                            },
                            true
                        );

                        return;
                    }

                    SPModal.close();

                    $window.location.href = response.download_url;

                    break;
                case 'queued': // Always multiple photos
                    SPModal.open(
                        'download-queued',
                        {
                            partialDirectory: serviceName.toLowerCase(),
                            modalData: {
                                email: response.email
                            }
                        },
                        true
                    );

                    break;
                default:
                    return;
            }
        };

        /**
         * Called on SPClientData.download error
         *
         * @param response      Response value from SPClientData as string, either 'direct' or 'queued'
         *                      Determines which download function to call after resetting cached values.
         *
         * @param returnFunction  Determines which download function to call after the user enters their information.
         *
         * @param errorCallback Optional passed-in callback error function
         */
        function onDownloadError(response, returnFunction, errorCallback) {
            if (typeof errorCallback === 'function') {
                errorCallback(response);
            } else {
                /**
                 * Here, we thought we had a valid pin or email, and got back an error
                 * dump whatever data we think we have and start over
                 */
                $cache.removeAll();
                self.checkUserState();
                returnFunction(options);
            }
        }

        /**
         * Call this method to open a modal to collect email and/or PIN for downloading.  On success calls the
         * appropriate download function automatically.
         *
         * @param downloadType  Determines which download function to call after the user enters their information.
         */
        this.openModal = function openModal(returnFunction) {
            SPModal.open('main', {
                partialDirectory: serviceName.toLowerCase(),
                modalData: {
                    needsEmail: needsEmail,
                    needsPin: needsPin,
                    singlePhotoDownload: options.singlePhotoDownload,
                    submit: function submit(email, pin) {
                        var $scope = this;

                        if ($scope.DownloadInfoForm.$pristine || $scope.DownloadInfoForm.$invalid) {
                            return;
                        }

                        $cache.put('pin', pin);

                        var downloadOptions = {
                            photo: options.photo,
                            photos: options.photos,
                            album: options.album,
                            email: email,
                            pin: pin
                        };

                        return returnFunction(downloadOptions, function withErrorResponse(
                            response
                        ) {
                            if (response.status === 'form_errors') {
                                $scope.errors = response.errors;

                                return;
                            }

                            // @@STUB: Download not available?
                        });
                    }
                }
            });
        };
    }
];
