import React, { FC } from 'react';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import Button from 'ts/common/components/gallery/Button';
import { modalButtonWidth } from 'ts/common/constants/theme/common';

export interface ILabelModalFooter {
    onClose: () => void;
    translateFilter: SpAngularJs.ITranslateFilter;
}

const LabelModalFooter: FC<ILabelModalFooter> = ({ onClose, translateFilter: t }) => {
    const ButtonGroupMarginBottom = useBreakpointValue({ base: '24px', lg: '40px' });

    return (
        <Box
            textAlign="center"
            bottom="0"
            backgroundColor="background"
            position="fixed"
            left="0"
            width="100%"
            boxShadow={`0px -2px 8px var(--chakra-colors-boxShadow)`}
            padding="16px"
            paddingTop="20px"
            paddingBottom={ButtonGroupMarginBottom}
        >
            <Button
                minWidth={modalButtonWidth}
                fullWidth={useBreakpointValue({ base: true, lg: false })}
                isDisabled={false}
                text={t('close')}
                textLarge={t('close')}
                variant="outline primary"
                onClick={onClose}
            />
        </Box>
    );
};

export default LabelModalFooter;
