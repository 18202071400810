import React, { FC, useEffect, useMemo, useState } from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { EVENT_EVENTS } from 'Sp/Angular/Events/Client/Gallery';
import getTheme from 'ts/common/components/gallery/getTheme';

let theme: ReturnType<typeof extendTheme> | undefined;

const useTheme = (spEventBus: SpAngularJs.ISpEventBus, providedBrandTheme?: SpApi.IBrandTheme) => {
    const [brandTheme, setBrandTheme] = useState<SpApi.IBrandTheme | undefined>(providedBrandTheme);

    useEffect(() => {
        const off = spEventBus.on<SpApi.IEvent>(EVENT_EVENTS.GOT, (eventData) => {
            setBrandTheme(eventData.brandTheme);
        });

        if (!providedBrandTheme) {
            spEventBus.emit(EVENT_EVENTS.GET);
        }

        return off;
    }, [providedBrandTheme, spEventBus]);

    theme = useMemo(() => {
        return theme ?? (brandTheme && extendTheme(getTheme(brandTheme)));
    }, [brandTheme]);

    return { brandTheme, theme };
};

export const withTheme = <P extends object>(
    Component: FC<P>,
    providedGalleryTheme?: SpApi.IBrandTheme
): React.FC<P & { spEventBus: SpAngularJs.ISpEventBus }> =>
    function WithTheme({ spEventBus, ...props }) {
        const { theme } = useTheme(spEventBus, providedGalleryTheme);

        return theme ? (
            <ChakraProvider cssVarsRoot="body" resetCSS={false} theme={theme}>
                <Component {...(props as P)} />
            </ChakraProvider>
        ) : null;
    };

export default useTheme;
