import { EVENT_TAG_EVENTS } from 'Sp/Angular/Events/Client/Gallery';
import Route from 'Sp/Route';

export default [
    '$rootScope',
    'spApiHttp',
    function EventTagDataStore($rootScope, spApiHttp) {
        $rootScope.$on(EVENT_TAG_EVENTS.GET, function getEventTags(_, eventId) {
            eventId = eventId || $rootScope.eventData.id;

            spApiHttp
                .get(
                    Route.get('/brand/:brandId/event/:eventId/tag', {
                        brandId: $rootScope.brandData.id,
                        eventId
                    })
                )
                .then(function withResponse(response) {
                    const eventTags = response && response.data && response.data.items;

                    $rootScope.$broadcast(EVENT_TAG_EVENTS.GOT, eventTags, eventId);
                });
        });
    }
];
