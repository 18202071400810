import React, { useEffect, useState } from 'react';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { getSubgroups, sortByDisplayOrder } from 'ts/client/gallery/components/common';
import { getAllowedDigitalOptionsInBuyAll } from 'ts/client/gallery/components/digitals/logic';
import RadioGroupGrid from 'ts/common/components/RadioGroupGrid';
import { useTranslate } from 'ts/common/hooks';
import { IBoundItem, IFormValues, ProductOption } from './types';

interface IOptionSelectProps {
    defaultPriceSheetItemId?: number;
    context?: 'album' | 'marked' | 'none';
    isBuyingAllPhotos?: boolean;
    isDigitalDownload?: boolean;
    priceSheetItem: Nullable<SpApi.Client.IPriceSheetItem>;
    priceSheetGroup: Nullable<SpApi.Client.IPriceSheetGroup>;
    selectedPriceSheetItem?: SpApi.Client.IPriceSheetItem;
    setValue: UseFormSetValue<IFormValues>;
    spFulfilledDigitalItems?: IBoundItem[];
    watch: UseFormWatch<IFormValues>;
}

const OptionSelect: React.FC<IOptionSelectProps> = ({
    defaultPriceSheetItemId,
    context = 'none',
    isBuyingAllPhotos,
    isDigitalDownload,
    priceSheetItem,
    priceSheetGroup,
    selectedPriceSheetItem,
    setValue,
    spFulfilledDigitalItems,
    watch
}) => {
    const [options, setOptions] = useState<Nullable<Array<ProductOption>>>(null);
    const { priceSheetItemId, priceSheetSubgroupId } = watch();
    const t = useTranslate('client.gallery.optionSelect');

    useEffect(() => {
        if (isDigitalDownload) {
            const digitalOptionsForPricesheetItem = spFulfilledDigitalItems?.filter(
                (item) => item.name === priceSheetItem?.name
            );

            const options = digitalOptionsForPricesheetItem?.reduce<Array<ProductOption>>(
                (options, digitalOption) => {
                    const name = digitalOption.is_individual
                        ? t('singlePhoto')
                        : digitalOption.is_event
                        ? t('allPhotosInGallery')
                        : digitalOption.is_album
                        ? t('allPhotosInAlbum')
                        : '';

                    if (isBuyingAllPhotos && priceSheetItem) {
                        const allowed = getAllowedDigitalOptionsInBuyAll(priceSheetItem, context);

                        if (allowed && allowed.some((allow) => digitalOption[allow])) {
                            options.push({ priceSheetItemId: parseInt(digitalOption.id), name });
                        }

                        return options;
                    }

                    options.push({ priceSheetItemId: parseInt(digitalOption.id), name });

                    return options;
                },
                []
            );

            if (options && options.length > 0) {
                setOptions(options);
                setValue('priceSheetItemId', options[0].priceSheetItemId);
            }
        } else if (priceSheetItem && priceSheetGroup?.groupType !== 'ornaments') {
            return;
        }

        if (priceSheetGroup && getSubgroups(priceSheetGroup).length > 0) {
            const defaultSelectedItem = priceSheetGroup.items.find(
                ({ id }) => id === defaultPriceSheetItemId
            );

            if (defaultSelectedItem && defaultSelectedItem.subgroup) {
                const { id } = defaultSelectedItem.subgroup;
                const subgroupItems = priceSheetGroup.items.filter(
                    ({ subgroup }) => subgroup?.id === id
                );

                if (subgroupItems.length > 0) {
                    setOptions(
                        sortByDisplayOrder(subgroupItems).map(({ id, name }) => ({
                            priceSheetItemId: id,
                            name
                        }))
                    );
                }
            }
        }
    }, [
        context,
        defaultPriceSheetItemId,
        isBuyingAllPhotos,
        isDigitalDownload,
        priceSheetGroup,
        priceSheetItem,
        setValue,
        spFulfilledDigitalItems,
        t
    ]);

    useEffect(() => {
        if (
            priceSheetGroup &&
            priceSheetSubgroupId &&
            selectedPriceSheetItem &&
            priceSheetSubgroupId !== selectedPriceSheetItem?.subgroup?.id
        ) {
            const subgroupItems = priceSheetGroup.items.filter(
                ({ subgroup }) => subgroup?.id === priceSheetSubgroupId
            );

            if (subgroupItems.length > 0) {
                setOptions(
                    sortByDisplayOrder(subgroupItems).map(({ id, name }) => ({
                        priceSheetItemId: id,
                        name
                    }))
                );
                setTimeout(() => {
                    setValue('priceSheetItemId', subgroupItems[0].id);
                });
            }
        }
    }, [priceSheetGroup, priceSheetSubgroupId, selectedPriceSheetItem, setValue]);

    if (!options || options.length <= 1) {
        return null;
    }

    const optionsForRadioGrid = options.map((productOption) => {
        return { name: productOption.name, value: productOption.priceSheetItemId };
    });

    return (
        <RadioGroupGrid
            defaultValue={defaultPriceSheetItemId}
            fieldName="priceSheetItemId"
            label={t('options')}
            onChange={(fieldName, value) =>
                setValue(fieldName as 'priceSheetSubgroupId' | 'priceSheetItemId', value)
            }
            options={optionsForRadioGrid}
            parentFormValue={priceSheetItemId}
        />
    );
};

export default OptionSelect;
