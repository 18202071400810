import { EVENT_EVENTS } from 'Sp/Angular/Events/Client/Gallery';
import Route from 'Sp/Route';

export default [
    '$rootScope',
    'spApiHttp',
    function EventDataStore($rootScope, spApiHttp) {
        let eventData;
        let pendingRequest;

        $rootScope.$on(EVENT_EVENTS.GET, function getEvent() {
            if (eventData) {
                $rootScope.$broadcast(EVENT_EVENTS.GOT, eventData);
                return;
            }

            if (pendingRequest) {
                return;
            }

            pendingRequest = spApiHttp
                .get(
                    Route.get('/brand/:brandId/event/:eventId', {
                        brandId: $rootScope.brandData.id,
                        eventId: $rootScope.eventData.id
                    })
                )
                .then(function withResponse({ data: event }) {
                    eventData = event;
                    $rootScope.$broadcast(EVENT_EVENTS.GOT, event);
                })
                .finally(() => {
                    pendingRequest = null;
                });
        });
    }
];
