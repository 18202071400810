import { useCallback } from 'react';

const useCountSessionAction = () => {
    const getCount = useCallback((key: string): number => {
        return parseInt(window.sessionStorage.getItem(key) || '') || 0;
    }, []);

    const increaseCount = useCallback(
        (key: string): number => {
            const count = getCount(key) + 1;
            window.sessionStorage.setItem(key, `${count}`);
            return count;
        },
        [getCount]
    );

    return [getCount, increaseCount];
};

export default useCountSessionAction;
