import React, { FC } from 'react';
import { Link, LinkProps } from '@chakra-ui/react';

interface Props extends LinkProps {
    /** True only if the button should show a isSelected state */
    isSelected?: boolean;
}

const BottomNavLink: FC<Props> = ({ children, isSelected, ...rest }) => {
    return (
        <Link
            aria-current={isSelected ? 'page' : undefined}
            alignItems="center"
            color={isSelected ? 'brand.primary' : 'text'}
            direction="column"
            display="flex"
            flex={1}
            flexDirection="column"
            fontFamily="open-sans"
            fontSize="12px"
            gap="2px"
            lineHeight={1.33}
            paddingBottom="8px"
            paddingTop="12px"
            paddingX="4px"
            _focus={{
                color: isSelected ? 'brand.primary' : 'text'
            }}
            _focusVisible={{
                boxShadow: 'outline',
                color: 'brand.primary'
            }}
            _hover={{
                textDecoration: 'none',
                color: isSelected ? 'brand.primary' : 'text',
                stroke: 'inherit'
            }}
            _visited={{
                color: isSelected ? 'brand.primary' : 'text',
                '&:focus': {
                    color: isSelected ? 'brand.primary' : 'text'
                },
                '&:hover': {
                    color: isSelected ? 'brand.primary' : 'text'
                }
            }}
            {...rest}
        >
            {children}
        </Link>
    );
};

export default BottomNavLink;
