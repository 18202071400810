export default [
    '$scope',
    '$location',
    'SPModal',
    'spPackages',
    function PackageSuccessController($scope, $location, SPModal, spPackages) {
        Object.assign($scope, {
            close,
            onGoToCart
        });

        initialize();

        function initialize() {
            spPackages.setSelectedPackage();
        }

        function onGoToCart() {
            $location.path('cart');
            SPModal.close();
        }

        function close() {
            SPModal.close();
        }
    }
];
