import { PACKAGE_SMALL_SCREEN_STATES } from 'Sp/Gallery';

export default [
    '$scope',
    'SPClientRoute',
    'spComparePhotos',
    'spHiddenAlbum',
    'spPackages',
    function NavigationController(
        $scope,
        SPClientRoute,
        spComparePhotos,
        spHiddenAlbum,
        spPackages
    ) {
        Object.assign($scope, {
            getSelectedPackage: spPackages.getSelectedPackage,
            getSmallScreenState: spPackages.getSmallScreenState,
            isInHiddenAlbumFlow,
            isNavigationRoot,
            isNavigationSecondary,
            isPackageSelected,
            PACKAGE_SMALL_SCREEN_STATES: PACKAGE_SMALL_SCREEN_STATES,
            spComparePhotos
        });

        function isInHiddenAlbumFlow() {
            return spHiddenAlbum.isInFlow();
        }

        function isPackageSelected() {
            return Boolean(spPackages.getSelectedPackage());
        }

        function isNavigationRoot(context) {
            return SPClientRoute.isNavigationRoot(context);
        }

        function isNavigationSecondary(context) {
            return SPClientRoute.isNavigationSecondary(context);
        }
    }
];
