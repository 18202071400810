function createState() {
    const mappedImages = new Map<string, HTMLImageElement>();
    const mappedPromises = new Map<string, Promise<HTMLImageElement>>();

    return {
        mappedImages,
        mappedPromises
    };
}

async function fetchImage(url: string) {
    const image = new Image();

    await new Promise<void>((resolve) => {
        image.addEventListener('load', () => resolve());
        image.addEventListener('error', () => resolve());
        image.src = url;

        // This means the image is cached.
        if (image.complete && image.naturalWidth > 0) {
            resolve();
        }
    });

    return image;
}

export function createCache() {
    const { mappedImages, mappedPromises } = createState();

    const loadImage = (url: string) => {
        const promise = mappedPromises.get(url);

        if (!promise) {
            const promise = fetchImage(url);

            promise.then((image) => {
                mappedImages.set(url, image);
            });

            mappedPromises.set(url, promise);

            return promise;
        }

        return promise;
    };

    const hasImage = (url: string) => {
        return mappedImages.has(url);
    };

    const getImage = (url: string) => {
        return mappedImages.get(url) || null;
    };

    return Object.freeze({
        loadImage,
        hasImage,
        getImage
    });
}

export type AssetCacheType = ReturnType<typeof createCache>;
