import React from 'react';
import { BoxProps, Flex, Text } from '@chakra-ui/react';
import { A, styled } from 'sp-ui';
import { ReactComponent as CloseIcon } from 'svg/common/close-icon.svg';
import { Button, GalleryHeading } from 'ts/common/components/gallery';
import { useTranslate } from 'ts/common/hooks';

const WarningContainer = styled(Flex, {
    shouldForwardProp: (prop) => prop !== 'isVisible'
})<{ isVisible: boolean }>(
    ({ theme, isVisible }) => `
        flex-direction: column;
        height: 390px;
        background: ${theme.colors.white};
        position: fixed;
        left: 0;
        bottom: ${isVisible ? '0' : '-390px'};
        width: 100%;
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3), 0px 1px 2px rgba(0, 0, 0, 0.1);
        padding: 24px 16px;
        transition: all .3s ease-out;
        z-index: 1;
    `
);

interface ISmallScreenWarningProps {
    actionButtonOnClick: () => void;
    actionButtonTranslateKey: string;
    headingTranslateKey: string;
    isVisible: boolean;
    onCloseClick: () => void;
    showCancelButton?: boolean;
    subtextTranslateKey?: string;
}

const SmallScreenWarning: React.FC<ISmallScreenWarningProps & BoxProps> = ({
    actionButtonOnClick,
    actionButtonTranslateKey,
    headingTranslateKey,
    isVisible,
    onCloseClick,
    showCancelButton,
    subtextTranslateKey,
    ...props
}) => {
    const t = useTranslate('client.gallery.smallScreenWarning');

    return (
        <WarningContainer
            alignItems="center"
            justifyContent="space-between"
            isVisible={isVisible}
            {...props}
        >
            <A height="40px" marginLeft="auto" onClick={onCloseClick}>
                <CloseIcon />
            </A>
            <GalleryHeading
                data-testid="small-screen-warning-heading"
                margin="14px 0 8px"
                size="sm"
                text={t(headingTranslateKey)}
                textAlign="center"
                variant="primary"
            />
            {subtextTranslateKey && (
                <Text
                    color="gray.600"
                    data-testid="small-screen-warning-info-text"
                    fontFamily="open-sans, sans-serif"
                    fontSize="md"
                    margin="0 0 26px"
                    textAlign="center"
                >
                    {t(subtextTranslateKey)}
                </Text>
            )}
            <Button
                data-testid="small-screen-warning-action-button"
                fullWidth
                marginBottom="8px"
                onClick={actionButtonOnClick}
                text={t(actionButtonTranslateKey)}
                variant="primary"
            />
            {showCancelButton && (
                <Button
                    borderColor="brand.primary"
                    color="brand.primary"
                    fullWidth
                    onClick={onCloseClick}
                    text={t('cancel')}
                    variant="outline primary"
                />
            )}
        </WarningContainer>
    );
};

export default SmallScreenWarning;
