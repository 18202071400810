/* eslint-disable func-names */

var directiveName = 'spPhotoCropView';

export default [
    'SPPhoto',
    function spPhotoCropViewDirective(SPPhoto) {
        function backgroundPercentageLinearInterpolation(offset, distance) {
            var t = 0;

            if (distance < 100) {
                t = offset / (100 - distance);
            }

            return offset + t * distance;
        }

        return {
            restrict: 'A',
            scope: {
                photo: '=' + directiveName,
                size: '=' + directiveName + 'Size',
                crop: '=' + directiveName + 'Crop'
            },
            transclude: true,
            template:
                '<span class="photo-crop-overlay"></span><span class="photo-crop-view"></span>',
            link: function spPhotoCropViewController(
                $scope,
                element,
                $attributes,
                controller,
                $transclude
            ) {
                $transclude(function(elements) {
                    element.append(elements);
                });

                var cropView = element.children('.photo-crop-view');
                var photoDimensions;
                var setPhoto = function setPhoto() {
                    var photoUrl = 'url(' + SPPhoto.getUrl($scope.photo, $scope.size) + ')';

                    if (typeof $scope.crop !== 'object') {
                        return;
                    }

                    var containerDimensions = {
                        width: element.parent().width(),
                        height: element.parent().height()
                    };

                    photoDimensions = SPPhoto.getPhotoDimensions(
                        $scope.photo,
                        $scope.size,
                        containerDimensions
                    );

                    element.css(photoDimensions).css('background-image', photoUrl);

                    cropView.css('background-image', photoUrl);

                    if (
                        $scope.crop.boundsName === 'Button' ||
                        $scope.crop.boundsName === 'Round Magnet'
                    ) {
                        cropView.css('clip-path', 'circle(45% at 50% 50%)');
                    }
                };

                $scope.$watch(
                    'photo',
                    function() {
                        setPhoto();
                    },
                    true
                );

                var setCropView = function setCropView() {
                    if (typeof $scope.crop !== 'object' || typeof photoDimensions !== 'object') {
                        return;
                    }

                    var bounds = $scope.crop.bounds;
                    var top = $scope.crop.getTop();
                    var left = $scope.crop.getLeft();
                    var width = $scope.crop.getWidth();
                    var height = $scope.crop.getHeight();

                    if (bounds.depth > 0) {
                        top = top + (bounds.depth / bounds.height) * height;
                        left = left + (bounds.depth / bounds.width) * width;
                        width = width * (1 - (2 * bounds.depth) / bounds.width);
                        height = height * (1 - (2 * bounds.depth) / bounds.height);
                    }

                    cropView.css({
                        top: top + '%',
                        left: left + '%',
                        width: width + '%',
                        height: height + '%',
                        'background-position':
                            backgroundPercentageLinearInterpolation(left, width) +
                            '% ' +
                            backgroundPercentageLinearInterpolation(top, height) +
                            '%',
                        'background-size':
                            Math.pow(width, -1) * 10000 + '% ' + Math.pow(height, -1) * 10000 + '%'
                    });
                };

                $scope.$watch(
                    'crop',
                    function() {
                        setCropView();
                    },
                    true
                );

                $scope.$parent.$watch(
                    'clientWidth',
                    function() {
                        setCropView();
                    },
                    true
                );
            }
        };
    }
];
