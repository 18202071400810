import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { Package } from 'react-feather';
import ModalHeader from 'ts/common/components/gallery/ModalHeader';
import ModalButtonFooter from './ModalButtonFooter';

interface IPackageAddToCartModal {
    isPackageBeingEdited: boolean;
    primaryButtonAction: () => void;
    secondaryButtonAction: () => void;
    translateFilter: SpAngularJs.ITranslateFilter;
}

const PackageAddToCartModal: FC<IPackageAddToCartModal> = ({
    isPackageBeingEdited = false,
    primaryButtonAction,
    secondaryButtonAction,
    translateFilter: t
}) => {
    return (
        <Box>
            <Box paddingBottom="120px">
                <ModalHeader
                    modalIcon={Package}
                    headerText={
                        isPackageBeingEdited
                            ? t('packagesModeSuccessHeaderEdit')
                            : t('packagesModeSuccessHeader')
                    }
                />
            </Box>
            <ModalButtonFooter
                secondaryButtonAction={secondaryButtonAction}
                primaryButtonAction={primaryButtonAction}
                primaryButtonText={t('viewGallery')}
                primaryLoadingText={t('viewGallery')}
                secondaryButtonText={t('viewCart')}
            />
        </Box>
    );
};

export default PackageAddToCartModal;
