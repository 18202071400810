import React, { useEffect } from 'react';
import { Box, Flex, Image, Text, useBreakpointValue } from '@chakra-ui/react';
import {
    trackClientEventFromPriceSheetItems,
    useEvent,
    useGalleryApiFetch
} from 'ts/client/common';
import { Button, GalleryHeading } from 'ts/common/components/gallery';
import { FetchMethod, useTranslate } from 'ts/common/hooks';
import { IBoundItem, IImage, IPathContext } from '../types';
import Footer from './Footer';

interface ILaunchEditorResponse {
    data?: {
        url?: string;
    };
    needEmail?: boolean;
    statusText?: string;
}

interface ICardTemplatePreviewSlideProps {
    brandCurrencyCode: string;
    categoryName?: string;
    cardPricesheetItems?: SpApi.Client.IPriceSheetItem[];
    cardProduct?: IBoundItem;
    getPathContext: () => IPathContext;
    goToEmailCaptureSlide: () => void;
    image: IImage;
}

const CardTemplatePreviewSlide: React.FC<ICardTemplatePreviewSlideProps> = ({
    brandCurrencyCode,
    categoryName,
    cardPricesheetItems,
    cardProduct,
    getPathContext,
    goToEmailCaptureSlide,
    image
}) => {
    const t = useTranslate('client.gallery.addToCartSidePanel.cardTemplatePreviewSlide');
    const { id: eventId } = useEvent();

    const isMediumScreenOrLarger = useBreakpointValue({ base: false, md: true });

    const { context, contextId } = getPathContext();

    const cardPricesheetItem = cardPricesheetItems?.find(
        (item) => item.id === cardProduct?.priceSheetItemId
    );

    const {
        response: launchEditorResponse,
        performFetch: launchEditor,
        loading: editorLoading
    } = useGalleryApiFetch<ILaunchEditorResponse>('cart/launcheditor', {
        defer: true,
        method: FetchMethod.POST
    });

    useEffect(() => {
        if (launchEditorResponse) {
            if (launchEditorResponse.data?.url) {
                window.location.href = launchEditorResponse.data.url;
            }

            if (launchEditorResponse.statusText === 'error' && launchEditorResponse.needEmail) {
                goToEmailCaptureSlide();
            }
        }
    }, [launchEditorResponse, goToEmailCaptureSlide]);

    if (!cardProduct || !categoryName) {
        return null;
    }

    return (
        <Box padding="48px 20px">
            <Flex gap="24px" direction="column" alignItems="center" marginBottom="50px">
                <GalleryHeading
                    data-testid="card-template-preview-heading"
                    size="sm"
                    text={getCardTemplateName(cardProduct, categoryName)}
                    variant="primary"
                    paddingBottom="24px"
                />
                <Image
                    data-testid="card-template-preview-image-front"
                    borderStyle="none"
                    boxShadow="3px 3px 7px 4px rgba(0, 0, 0, 0.1)"
                    height="auto"
                    width="100%"
                    src={cardProduct.description_images[0].l}
                />
                <Text data-testid="card-template-front-text">{t('cardTemplateFront')}</Text>
                <Image
                    data-testid="card-template-preview-image-back"
                    borderStyle="none"
                    boxShadow="3px 3px 7px 4px rgba(0, 0, 0, 0.1)"
                    height="auto"
                    width="100%"
                    src={cardProduct.description_images[1].l}
                />
                <Text data-testid="card-template-back-text">{t('cardTemplateBack')}</Text>
            </Flex>
            {isMediumScreenOrLarger && (
                <Footer alignItems="center" background="modalBackground">
                    <Button
                        data-testid="start-designing-button"
                        type="submit"
                        onClick={() => {
                            trackClientEventFromPriceSheetItems({
                                eventSlug: 'add_to_cart',
                                eventId,
                                priceSheetItems: cardPricesheetItem
                                    ? [cardPricesheetItem]
                                    : undefined,
                                orderPrice: cardProduct?.whcc_id
                                    ? parseFloat(cardProduct?.price) * 25
                                    : parseFloat(cardProduct?.price),
                                quantity: cardProduct?.whcc_id ? 25 : 1,
                                currencyCode: brandCurrencyCode
                            });
                            launchEditor({
                                body: new URLSearchParams({
                                    context: String(context),
                                    context_id: String(contextId),
                                    photo_id: String(image.id),
                                    template_id: String(cardProduct.whcc_id),
                                    whcc_product_id: String(cardProduct.whcc_product_id)
                                })
                            });
                        }}
                        fullWidth
                        isLoading={editorLoading}
                        text={t('cardTemplateStart')}
                        variant="primary"
                    />
                </Footer>
            )}
        </Box>
    );
};

const getCardTemplateName = (cardProduct: IBoundItem, categoryName: string) => {
    const occasion = categoryName;
    const template = cardProduct.name;

    if (template.includes(occasion)) {
        return `${template} cards`;
    }

    return `${template} - ${occasion} cards`;
};

export default CardTemplatePreviewSlide;
