import { PHOTO_DOWNLOAD_EVENTS } from 'Sp/Angular/Events/Client/Gallery';
import Route from 'Sp/Route';

export default [
    '$rootScope',
    'spApiHttp',
    function PhotoDownloadDataStore($rootScope, spApiHttp) {
        $rootScope.$on(PHOTO_DOWNLOAD_EVENTS.CREATE, createPhotoDownload);

        function createPhotoDownload(_, request) {
            const { albumId } = request;
            const apiRouteParams = {
                brandId: $rootScope.brandData.id,
                eventId: $rootScope.eventData.id,
                photoId: request.photoId
            };
            let apiRoute;

            if (albumId) {
                apiRouteParams.albumId = albumId;
                apiRoute = '/brand/:brandId/event/:eventId/album/:albumId/photo/:photoId/download';
            } else {
                apiRoute = '/brand/:brandId/event/:eventId/photo/:photoId/download';
            }

            spApiHttp
                .post({
                    url: Route.get(apiRoute, apiRouteParams),
                    data: {
                        type: 'download-request',
                        digitalRuleId: request.digitalRuleId,
                        email: request.email,
                        pins: request.pins
                    },
                    headers: {
                        Authorization: `Bearer ${request.authorizationToken}`
                    }
                })
                .then(({ data: photoDownload }) => {
                    $rootScope.$broadcast(PHOTO_DOWNLOAD_EVENTS.CREATED, photoDownload);
                })
                .catch(({ data, status }) => {
                    if (status === 403 && data.detail === 'The provided token is expired.') {
                        return $rootScope.$broadcast(
                            PHOTO_DOWNLOAD_EVENTS.ERRORS.TOKEN_INVALID,
                            albumId
                        );
                    }

                    const { errors = {} } = data.info;

                    if (errors.digitalRuleId && errors.digitalRuleId.notInEventAlbum) {
                        $rootScope.$broadcast(PHOTO_DOWNLOAD_EVENTS.ERRORS.ALBUM);
                    }

                    if (errors.pin && errors.pin.notInDigitalRule) {
                        $rootScope.$broadcast(PHOTO_DOWNLOAD_EVENTS.ERRORS.PIN);
                    }
                });
        }
    }
];
