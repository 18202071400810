export default [
    '$scope',
    '$location',
    'SPClientRoute',
    function SearchController($scope, $location, SPClientRoute) {
        $scope.query = $location.search().q;
        $scope.resultsQuery = $scope.query;

        $scope.search = function search() {
            if ($scope.query === undefined || $scope.query.length === 0) {
                return;
            }

            $location.search('q', $scope.query);
            $scope.resultsQuery = $scope.query;

            SPClientRoute.reload();
        };

        $scope.clearSearch = function clearSearch() {
            // Remove the "q" GET parameter.
            $location.url('search');

            $scope.query = undefined;
            $scope.resultsQuery = $scope.query;

            SPClientRoute.reload();
        };
    }
];
