import React from 'react';
import { Box, Grid, Text, useBreakpointValue } from '@chakra-ui/react';
import { styled } from 'sp-ui';
import { getItemDisplayName, sortByDisplayOrder } from 'ts/client/gallery/components/common';
import { ExpandableHtml } from 'ts/common/components';
import { GalleryHeading } from 'ts/common/components/gallery';
import ProductGroup from '../ProductGroup';
import { IImage, IPathContext } from '../types';

const GroupDescription = styled(Text)`
    p {
        color: inherit;
        font-size: inherit;
        line-height: inherit;
    }
`;

interface ISelectProductSlideProps {
    getPathContext: () => IPathContext;
    image: IImage;
    isBuyingAllPhotos: boolean;
    onProductGroupSelected: (priceSheetGroup: SpApi.Client.IPriceSheetGroup) => void;
    onProductSelected: (priceSheetItem: SpApi.Client.IPriceSheetItem) => void;
    priceSheetGroup?: SpApi.Client.IPriceSheetGroup;
}

const SelectProductSlide: React.FC<ISelectProductSlideProps> = ({
    getPathContext,
    image,
    isBuyingAllPhotos,
    onProductGroupSelected,
    onProductSelected,
    priceSheetGroup
}) => {
    const gap = 20;
    const responsiveColumnGap = useBreakpointValue({ base: `${gap / 2}px`, lg: `${gap}px` });

    if (!priceSheetGroup) {
        return null;
    }

    const { context, contextId } = getPathContext();
    const isInAlbum = context === 'album' && contextId !== 'all';
    const isInMarkedContext = ['favorites', 'hidden', 'label'].includes(context);
    const { description, groupType, items, name } = priceSheetGroup;
    const sortedItems = sortByDisplayOrder(Object.values(items));
    const isDigitalsGroupType = groupType === 'digitals';
    const isOrnamentsGroupType = groupType === 'ornaments';

    const isDigitalGroupAllowed = (item: SpApi.Client.IPriceSheetItem) => {
        // Hide the item if:
        // - Buying All Photos
        // - Not in an album
        // - Not in Marked context (fav, hidden, label)
        // - Individual and Album are enabled but Gallery is not
        if (
            isBuyingAllPhotos &&
            !isInAlbum &&
            !isInMarkedContext &&
            item.individualPhotoDownload?.isDigitalDownloadForSale &&
            item.allAlbumPhotosDownload?.isDigitalDownloadForSale &&
            !item.allGalleryPhotosDownload?.isDigitalDownloadForSale
        ) {
            return false;
        }

        return true;
    };

    const { boundedItems, customSubgroups, unboundedItems } = sortedItems.reduce(
        ({ boundedItems, customSubgroups, unboundedItems }, item) => {
            const subgroupName = item.subgroup?.name;

            if (
                ['packages', 'products'].includes(groupType) ||
                subgroupName === null ||
                subgroupName === 'No Size Specified'
            ) {
                unboundedItems.push(item);
            } else if (isOrnamentsGroupType && subgroupName) {
                if (!customSubgroups['ornaments']) {
                    customSubgroups['ornaments'] = [];
                }

                boundedItems.push(item);

                if (!customSubgroups['ornaments'].find((x) => x.subgroup?.name === subgroupName)) {
                    customSubgroups['ornaments'].push(item);
                }
            } else if (isDigitalsGroupType && isDigitalGroupAllowed(item)) {
                unboundedItems.push(item);
            } else if (item.bounds && groupType !== 'ornaments') {
                boundedItems.push(item);
            } else if (subgroupName) {
                if (!customSubgroups[subgroupName]) {
                    customSubgroups[subgroupName] = [];
                }

                customSubgroups[subgroupName].push(item);
            }

            return { boundedItems, customSubgroups, unboundedItems };
        },
        {
            boundedItems: [] as SpApi.Client.IPriceSheetItem[],
            customSubgroups: {} as { [subgroupName: string]: SpApi.Client.IPriceSheetItem[] },
            unboundedItems: [] as SpApi.Client.IPriceSheetItem[]
        }
    );

    const boundedItemsPriceSheetGroup = { ...priceSheetGroup, items: boundedItems };

    return (
        <Box padding="48px 20px">
            <GalleryHeading
                marginBottom="16px"
                size="md"
                text={name}
                variant="primary"
                data-testid="select-product-heading"
            />
            {description && (
                <GroupDescription
                    as="span"
                    fontSize="md"
                    marginBottom="24px"
                    data-testid="select-product-description"
                >
                    <ExpandableHtml html={description} maxLineCount={4} />
                </GroupDescription>
            )}
            <Grid
                templateColumns="repeat(2, 1fr)"
                columnGap={responsiveColumnGap}
                rowGap={`${gap}px`}
                marginBottom="24px"
            >
                {boundedItems.length > 0 && !isOrnamentsGroupType && (
                    <ProductGroup
                        image={image}
                        onClick={() => onProductGroupSelected(boundedItemsPriceSheetGroup)}
                        priceSheetGroup={boundedItemsPriceSheetGroup}
                    />
                )}
                {!isDigitalsGroupType &&
                    unboundedItems.map((item, index) => (
                        <ProductGroup
                            image={image}
                            key={index}
                            onClick={() => onProductSelected(item)}
                            priceSheetGroup={{
                                ...priceSheetGroup,
                                items: [item],
                                name: getItemDisplayName(item)
                            }}
                        />
                    ))}
                {isDigitalsGroupType &&
                    unboundedItems.map((item, index) => (
                        <ProductGroup
                            image={image}
                            key={index}
                            onClick={() => onProductSelected(item)}
                            priceSheetGroup={{
                                ...priceSheetGroup,
                                items: [item],
                                name: getItemDisplayName(item)
                            }}
                        />
                    ))}
            </Grid>
            {Object.entries(customSubgroups).map(([subgroupName, items], index) => (
                <React.Fragment key={index}>
                    {!isOrnamentsGroupType && (
                        <GalleryHeading
                            marginBottom="28px"
                            size="sm"
                            text={subgroupName}
                            variant="primary"
                        />
                    )}
                    <Grid
                        templateColumns="repeat(2, 1fr)"
                        columnGap={responsiveColumnGap}
                        rowGap={`${gap}px`}
                    >
                        {items.map((item, index) => (
                            <ProductGroup
                                image={image}
                                key={index}
                                onClick={() =>
                                    isOrnamentsGroupType
                                        ? onProductGroupSelected({
                                              ...priceSheetGroup,
                                              items: boundedItems.filter(
                                                  (x) => x.subgroup?.name === item.subgroup?.name
                                              )
                                          })
                                        : onProductSelected(item)
                                }
                                priceSheetGroup={{
                                    ...priceSheetGroup,
                                    items: [item],
                                    name:
                                        isOrnamentsGroupType && item.subgroup && item.subgroup.name
                                            ? item.subgroup.name
                                            : item.name
                                }}
                            />
                        ))}
                    </Grid>
                </React.Fragment>
            ))}
        </Box>
    );
};

export default SelectProductSlide;
