import React, { FC } from 'react';
import {
    Box,
    Button,
    ButtonProps,
    Divider,
    Flex,
    Heading,
    Link,
    LinkProps,
    Text
} from '@chakra-ui/react';
import CircleIcon from 'ts/common/components/gallery/CircleIcon';
import { BundleFileIcon } from 'ts/common/components/icons';

const HelpfulTipsButton: FC<ButtonProps & LinkProps> = ({ children, ...rest }) => (
    <Button
        as={Link}
        target="_blank"
        rel="noopener noreferrer"
        borderColor="gray.400"
        color="text"
        fontWeight="semibold"
        variant="outline"
        width={{ base: 'full', lg: '358px' }}
        _active={{
            backgroundColor: 'initial'
        }}
        _focus={{
            boxShadow: 'none'
        }}
        _focusVisible={{
            boxShadow: 'outline'
        }}
        _hover={{
            backgroundColor: 'initial',
            textDecoration: 'none'
        }}
        _visited={{
            color: 'text'
        }}
        {...rest}
    >
        {children}
    </Button>
);

export interface IBundleFile {
    filename: string;
    size: string;
}

export const getFileLabel = (file: IBundleFile) => `${file.filename} (${file.size})`;

interface Props {
    /** A list of files to be rendered */
    files: IBundleFile[];
    /** A function to be called when a file is clicked */
    onClickFile: (file: IBundleFile) => unknown;
    /** The Angular translation service */
    translateFilter: SpAngularJs.ITranslateFilter;
}

const BundleFileList: FC<Props> = ({ files, onClickFile, translateFilter: translate }) => {
    return (
        <Flex alignItems="center" flexDirection="column">
            <Flex alignItems="center" flexFlow="row wrap" gap="48px 64px" justifyContent="center">
                {files.map((file) => {
                    return (
                        <Button
                            key={file.filename}
                            onClick={() => onClickFile(file)}
                            aria-label={getFileLabel(file)}
                            borderRadius="0px"
                            borderStyle="none"
                            color="brand.primary"
                            display="flex"
                            flexDirection="column"
                            textDecoration="underline"
                            variant="link"
                            _focus={{
                                boxShadow: 'none'
                            }}
                            _focusVisible={{
                                boxShadow: 'outline'
                            }}
                        >
                            <CircleIcon icon={BundleFileIcon} size="lg" />
                            <Text
                                color="brand.primary"
                                fontSize="md"
                                fontWeight="semibold"
                                letterSpacing="0px"
                                marginTop="8px"
                            >
                                {file.filename}
                            </Text>
                            <Text color="brand.primary">{file.size}</Text>
                        </Button>
                    );
                })}
            </Flex>
            <Divider marginY="32px" />
            <Box marginBottom="24px">
                <Heading as="h3" marginY="16px" size="md" variant="primary">
                    {translate('zipBundleHelpfulTips')}
                </Heading>
                <Text fontSize="md" marginY="24px">
                    {translate('zipBundleHelpfulTipsItem1')}
                </Text>
                <Text fontSize="md" marginY="24px">
                    {translate('zipBundleHelpfulTipsItem2')}
                </Text>
            </Box>
            <Flex alignItems="center" alignSelf="stretch" flexDirection="column" gap="16px">
                <HelpfulTipsButton href="https://support.apple.com/kb/PH25411">
                    macOS
                </HelpfulTipsButton>
                <HelpfulTipsButton href="https://support.microsoft.com/help/14200/windows-compress-uncompress-zip-files">
                    Windows
                </HelpfulTipsButton>
            </Flex>
        </Flex>
    );
};

export default BundleFileList;
