import { PACKAGE_SMALL_SCREEN_STATES } from 'Sp/Gallery';
import spPackageCartItemTemplate from '../../templates/components/sp-package-cart-item.nghtml';

/**
 * @ngdoc component
 * @name sp.client.gallery.component:spPackageCartItem
 * @requires $location
 * @requires sp.client.gallery.filter:spPackageCartItemDetailFilter
 * @requires sp.client.gallery.service:spPackages
 *
 * @description
 * Displays an individual item from the cart
 *
 * @param {boolean} allowClientComment Determines if a client comment can be provided on this cart item
 * @param {boolean} allowCropping Determines if an image can be cropped
 * @param {function} editCropForBoundItem Edits the crop for an item
 * @param {function} editProductForCartItem Edits the crop for an item
 * @param {object} packageItem Item to be displayed
 * @param {function} removePackageItem Removes package item from the cart
 * @param {number} totalNumberOfItems Total number of items in the cart
 */
export default {
    template: spPackageCartItemTemplate,
    bindings: {
        allowClientComment: '<',
        allowCropping: '<',
        editCropForBoundItem: '<',
        editProductForCartItem: '<',
        packageItem: '<',
        removePackageItem: '<',
        totalNumberOfItems: '<'
    },
    controller: [
        '$location',
        'spClientComment',
        'spPackageCartItemDetailFilter',
        'spPackages',
        function spPackageCartItemController(
            $location,
            spClientComment,
            spPackageCartItemDetailFilter,
            spPackages
        ) {
            const $ctrl = this;

            $ctrl.hideEdit = false;

            $ctrl.$onInit = $onInit;
            $ctrl.editPackage = editPackage;
            $ctrl.clientCommentChanged = spClientComment.configure();

            function $onInit() {
                const [firstBoundsItem] = $ctrl.packageItem.items[0].boundItems;

                $ctrl.packageItemHeader = spPackageCartItemDetailFilter($ctrl.packageItem);
                $ctrl.hideEdit =
                    $ctrl.packageItem.items.length === 1 &&
                    firstBoundsItem.isDigitalDownload &&
                    firstBoundsItem.name === 'Full Resolution (All Gallery Photos)';
            }

            function editPackage() {
                spPackages.setSmallScreenState(PACKAGE_SMALL_SCREEN_STATES.PACKAGE_VIEW);
                spPackages.editPackageInCart($ctrl.packageItem);
                $location.url('home');
            }
        }
    ]
};
