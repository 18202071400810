import React, { FC, useRef } from 'react';
import { Box, Flex, Link, useBreakpointValue } from '@chakra-ui/react';
import { IToolbarActions } from 'ts/client/types';
import { CartEnabledIcon, CartIcon } from 'ts/common/components/icons';
import useNavTabs from 'ts/common/hooks/useNavTabs';
import BackNav from './BackNav';
import BackToTopButton from './BackToTopButton';
import CountBadge from './CountBadge';
import HeaderNavLink from './HeaderNavLink';
import NavTabs from './NavTabs';
import StudioHeading from './StudioHeading';
import Toolbar from './Toolbar';

export type RouteIdentifier = 'Home' | 'Album' | 'Special' | 'Search' | 'Info' | 'Cart' | 'Store';

interface Props {
    /** The number of items in the cart */
    cartCount?: number;
    /** The number of favorites */
    favoritesCount?: number;
    /** The href for the Home nav link */
    homeLinkHref: string;
    /** The label to be translated for the Home nav link */
    homeLinkLabel: string;
    /* True only if the About Contact link is shown */
    isAboutContactVisible?: boolean;
    /* True only if the About Message link is shown */
    isAboutMessageVisible?: boolean;
    /* True only if the About Pricing link is shown */
    isAboutPricingVisible?: boolean;
    /** True only if the About nav link is shown */
    isAboutVisible?: boolean;
    /* True only if admin mode is enabled (which enables hiding and labeling) */
    isAdminModeEnabled?: boolean;
    /** True only if the back nav section (with the back button) is visible */
    isBackNavVisible?: boolean;
    /** True only if the Cart nav link is shown */
    isCartVisible?: boolean;
    /* True only if hiding photos is allowed */
    isHiddenPhotosVisible?: boolean;
    /** True only if the secondary nav on the Home page should be shown */
    isHomeSecondaryNavVisible?: boolean;
    /** True only if labeling photos is allowed */
    isLabeledPhotosVisible?: boolean;
    /** True only if the large-screen version should be shown. If not specified, then automatically detect based on viewport width. */
    isLargeScreen?: boolean;
    /** True only if the package builder is open */
    isPackageBuilderOpen?: boolean;
    /** True only if the page is scrolled down at least one page length */
    isScrolledDown?: boolean;
    /** True only if the last scroll interaction was downward */
    isScrollingDown?: boolean;
    /** True only if the Search nav link is shown */
    isSearchVisible?: boolean;
    /** True if the Store should be shown */
    isStoreEnabled?: boolean;
    /** True only if the toolbar is hidden */
    isToolbarHidden?: boolean;
    /** Actions that can be performed from the toolbar */
    toolbarActions: IToolbarActions;
    /** The name of the parent route, if any */
    parentRouteName?: string;
    /** The path from the URL of the parent, if any */
    parentUri?: string;
    /** The href of the portfolio website, if the studio name/logo should link to it */
    portfolioWebsiteHref?: string;
    /** Callback for reloading the current route */
    reloadRoute?: () => unknown;
    /** True if the user needs to select a download destination for a pending download */
    requiresDownloadDestination?: boolean;
    /** The identifier of the selected route */
    selectedRoute: RouteIdentifier;
    /** The current path from the URL, excluding the base path of the gallery */
    selectedUri?: Nullable<string>;
    /** The name of the studio that owns this gallery */
    studioName: string;
    /** A url to be copied to the clipboard when the Share Links Copy Link button is clicked */
    shareCopyLinkUrl: string;
    /** A record of urls to be used for share links */
    shareUrls?: Record<string, string>;
    /** Angular free digitals service */
    spFreeDigitals: SpAngularJs.ISpFreeDigitals;
    /** Angular translation service */
    translateFilter: SpAngularJs.ITranslateFilter;
}

const Header: FC<Props> = ({
    cartCount = 0,
    favoritesCount = 0,
    homeLinkHref,
    homeLinkLabel,
    isAboutContactVisible,
    isAboutMessageVisible,
    isAboutPricingVisible,
    isAboutVisible,
    isAdminModeEnabled,
    isBackNavVisible,
    isCartVisible,
    isHiddenPhotosVisible,
    isHomeSecondaryNavVisible,
    isLabeledPhotosVisible,
    isLargeScreen: isLargeScreenControlled,
    isPackageBuilderOpen,
    isScrolledDown,
    isScrollingDown,
    isSearchVisible,
    isStoreEnabled,
    isToolbarHidden,
    toolbarActions,
    parentRouteName,
    parentUri,
    portfolioWebsiteHref,
    reloadRoute,
    requiresDownloadDestination,
    selectedRoute,
    selectedUri,
    studioName,
    shareCopyLinkUrl,
    shareUrls,
    spFreeDigitals,
    translateFilter: translate
}) => {
    const isLargeScreenViewport = useBreakpointValue({ base: false, lg: true }) ?? false;
    const isLargeScreen = isLargeScreenControlled ?? isLargeScreenViewport;

    const placeholderElementRef = useRef<HTMLDivElement>(null);
    const headerElementRef = useRef<HTMLDivElement>(null);

    const isHomeSelected = selectedRoute === 'Home' || selectedRoute === 'Album';
    const isStoreSelected = selectedRoute === 'Store';
    const isFavoritesSelected = selectedRoute === 'Special';
    const isSearchSelected = selectedRoute === 'Search';
    const isAboutSelected = selectedRoute === 'Info';
    const isCartSelected = selectedRoute === 'Cart';

    const { navTabs, navTabsKey, selectedNavTabIndex } = useNavTabs({
        isAboutContactVisible,
        isAboutMessageVisible,
        isAboutPricingVisible,
        isAdminModeEnabled,
        isHiddenPhotosVisible,
        isHomeSecondaryNavVisible,
        isLabeledPhotosVisible,
        selectedRoute,
        selectedUri,
        translate
    });

    const visibleNavTabs = isLargeScreen ? navTabs : undefined;

    const isOnlySingleActionOnSmallScreen =
        !isLargeScreen &&
        selectedUri !== null &&
        selectedUri !== undefined &&
        (Boolean(selectedUri.match(/album\/[0-9]/g)) || selectedUri.includes('label/'));

    const subheaderPaddingLeft = isLargeScreen ? '32px' : '8px';
    const subheaderPaddingRight = isLargeScreen ? '16px' : '8px';

    const toolbar = isToolbarHidden ? null : (
        <Toolbar
            isLargeScreen={isLargeScreen}
            isOnlySingleActionOnSmallScreen={isOnlySingleActionOnSmallScreen}
            actions={toolbarActions}
            requiresDownloadDestination={requiresDownloadDestination}
            selectedUri={selectedUri}
            shareCopyLinkUrl={shareCopyLinkUrl}
            shareUrls={shareUrls}
            spFreeDigitals={spFreeDigitals}
            translate={translate}
        />
    );

    return (
        <>
            <Box ref={placeholderElementRef} role="presentation" />
            <Flex
                flexDirection="column"
                position="sticky"
                top={`${
                    ((isPackageBuilderOpen && placeholderElementRef.current?.offsetTop) || 0) -
                    ((isScrolledDown &&
                        isScrollingDown &&
                        headerElementRef.current?.offsetHeight) ||
                        0)
                }px`}
                transition="0.5s top"
                zIndex={3}
            >
                <Flex
                    ref={headerElementRef}
                    as="header"
                    backgroundColor="background"
                    borderBottomColor="gray.100"
                    borderBottomStyle="solid"
                    borderBottomWidth="1px"
                    flexDirection="row"
                    gap="12px"
                    height={isLargeScreen ? '80px' : '64px'}
                    justifyContent="space-between"
                >
                    <Flex direction="row" gap="12px">
                        <Link
                            href={portfolioWebsiteHref || homeLinkHref}
                            target={portfolioWebsiteHref ? '_blank' : undefined}
                            alignSelf="center"
                            _focus={{ boxShadow: 'none' }}
                            _focusVisible={{ boxShadow: 'outline' }}
                            _hover={{ textDecoration: 'none' }}
                        >
                            <Box
                                maxWidth={isLargeScreen ? '380px' : undefined}
                                paddingX={isLargeScreen ? '32px' : '16px'}
                                paddingY={isLargeScreen ? '32px' : '24px'}
                            >
                                <StudioHeading text={studioName} variant="primary" />
                            </Box>
                        </Link>
                        {isLargeScreen && (
                            <Flex as="nav" direction="row">
                                <HeaderNavLink href={homeLinkHref} isSelected={isHomeSelected}>
                                    {translate(homeLinkLabel)}
                                </HeaderNavLink>
                                {isCartVisible && isStoreEnabled && (
                                    <HeaderNavLink
                                        href={'store'}
                                        isSelected={isStoreSelected}
                                        onClick={reloadRoute}
                                    >
                                        {translate('store')}
                                    </HeaderNavLink>
                                )}
                                <HeaderNavLink
                                    href="favorites"
                                    isSelected={isFavoritesSelected}
                                    aria-label={translate('favorites')}
                                    data-testid="viewFavoritesLink"
                                >
                                    <CountBadge count={favoritesCount} data-testid="favoritesCount">
                                        {translate('favorites')}
                                    </CountBadge>
                                </HeaderNavLink>
                                {isSearchVisible && (
                                    <HeaderNavLink href="search" isSelected={isSearchSelected}>
                                        {translate('search')}
                                    </HeaderNavLink>
                                )}
                                {isAboutVisible && (
                                    <HeaderNavLink href="info" isSelected={isAboutSelected}>
                                        {translate('about')}
                                    </HeaderNavLink>
                                )}
                            </Flex>
                        )}
                    </Flex>
                    {isLargeScreen && isCartVisible && (
                        <HeaderNavLink
                            href="cart"
                            isSelected={isCartSelected}
                            aria-label={translate('cart')}
                            height={undefined}
                            marginRight="32px"
                            padding="8px"
                        >
                            <CountBadge count={cartCount}>
                                {isCartSelected ? (
                                    <CartEnabledIcon display="block" />
                                ) : (
                                    <CartIcon display="block" />
                                )}
                            </CountBadge>
                        </HeaderNavLink>
                    )}
                </Flex>
                {(visibleNavTabs || (!isToolbarHidden && !isBackNavVisible)) && (
                    <Flex
                        alignItems="center"
                        backgroundColor="background"
                        borderBottomColor="gray.100"
                        borderBottomStyle="solid"
                        borderBottomWidth="1px"
                        flexDirection="row"
                        gap="8px"
                        height="60px"
                        overflowX="auto"
                        overflowY="hidden"
                        paddingLeft={subheaderPaddingLeft}
                        paddingRight={subheaderPaddingRight}
                        paddingY="16px"
                    >
                        {visibleNavTabs && (
                            <NavTabs
                                key={navTabsKey}
                                selectedTabIndex={selectedNavTabIndex}
                                tabs={visibleNavTabs}
                            />
                        )}
                        {toolbar}
                    </Flex>
                )}
                {isBackNavVisible && parentUri !== undefined && (
                    <BackNav
                        parentRouteName={parentRouteName}
                        parentUri={parentUri}
                        translateFilter={translate}
                    >
                        {toolbar}
                    </BackNav>
                )}
                <BackToTopButton
                    isHidden={!isScrolledDown || isScrollingDown}
                    translateFilter={translate}
                />
            </Flex>
        </>
    );
};

export default Header;
