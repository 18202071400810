import React, { FC } from 'react';
import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
import Button from 'ts/common/components/gallery/Button';
import CoverPhoto from 'ts/common/components/gallery/CoverPhoto';
import GalleryHeading from 'ts/common/components/gallery/GalleryHeading';
import StudioHeading from 'ts/common/components/gallery/StudioHeading';
import { ILayoutProps, contentAnimation, coverAnimation } from './BaseLayout';

const LimelightLayout: FC<ILayoutProps> = ({
    galleryTitle,
    brandName,
    coverPhoto,
    coverPhotoUrl,
    buttonText,
    buttonTextLarge,
    colorScheme,
    onClick
}) => {
    const galleryHeadingSize =
        useBreakpointValue({ base: 'sm' as const, lg: 'lg' as const }) ?? 'sm';

    return (
        <Box position="absolute" width="full" height="full" onClick={onClick}>
            <Box
                position="absolute"
                width="full"
                height="full"
                bgGradient={`linear(0deg, ${
                    colorScheme === 'dark' ? 'whiteAlpha.500' : 'blackAlpha.500'
                } 16.74%, rgba(0, 0, 0, 0) 99.54%)`}
                opacity={0}
                animation={contentAnimation}
            />
            <Flex
                padding="8"
                position="absolute"
                width="full"
                bottom="0"
                justify="space-between"
                alignItems={useBreakpointValue({ base: 'stretch', lg: 'flex-end' })}
                gap={4}
                flexDirection={useBreakpointValue({ base: 'column', lg: 'row' })}
                flexWrap="wrap"
                animation={contentAnimation}
                opacity={0}
            >
                <Box>
                    <GalleryHeading
                        size={galleryHeadingSize}
                        text={galleryTitle}
                        variant={colorScheme}
                    />
                    <StudioHeading text={brandName} variant={colorScheme} />
                </Box>
                <Box>
                    <Button
                        fullWidth={useBreakpointValue({ base: true, lg: false })}
                        text={buttonText}
                        textLarge={buttonTextLarge}
                        variant={`secondary ${colorScheme}` as const}
                    />
                </Box>
            </Flex>

            <Box width="full" height="full" animation={coverAnimation}>
                <CoverPhoto coverPhoto={coverPhoto} coverPhotoUrl={coverPhotoUrl} />
            </Box>
        </Box>
    );
};

export default LimelightLayout;
