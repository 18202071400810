import spPackageSingleItemTileTemplate from '../../templates/components/sp-package-single-item-tile.nghtml';
import { PACKAGE_SMALL_SCREEN_STATES } from 'Sp/Gallery';
import { PACKAGE_EVENTS } from 'Sp/Angular/Events/Client/Gallery';

/**
 * @ngdoc component
 * @name sp.client.gallery.component:spPackageSingleItemTile
 *
 * @requires sp.client.common.service:SPPhoto
 * @requires sp.newcommon.service:spEventBus
 * @requires sp.client.gallery.service:spPackages
 *
 * @description
 * Renders a Single Pose Package Item selection tile.
 */
export default {
    controller: [
        'SPPhoto',
        'spEventBus',
        'spPackages',
        function spPackageSingleItemTileController(SPPhoto, spEventBus, spPackages) {
            const PHOTO_SIZE =
                window.getDevicePixelRatio() === 1 || window.isSmallDevice() ? 'm' : 'l';
            const $ctrl = this;

            Object.assign($ctrl, {
                deleteSelectedItemPhoto,
                getSinglePhotoFilename,
                getSinglePhotoUrl,
                isSinglePackageItemSelected: spPackages.isSinglePackageItemSelected,
                selectSingleItem
            });

            function deleteSelectedItemPhoto($event) {
                $event.stopPropagation();

                spPackages.assignPhotoToAllPackageItems(null);
            }

            function getSinglePhotoFilename() {
                const photo = spPackages.getAssignedSinglePhoto();

                if (photo) {
                    return photo.filename;
                }

                return false;
            }

            function getSinglePhotoUrl() {
                const photo = spPackages.getAssignedSinglePhoto();

                if (photo) {
                    return SPPhoto.getUrl(photo, PHOTO_SIZE);
                }

                return false;
            }

            function selectSingleItem() {
                const photo = spPackages.getSelectedPackagePhoto();

                if (photo && !window.isSmallDevice()) {
                    spPackages.assignPhotoToAllPackageItems(photo);
                } else {
                    if (!$ctrl.isSinglePackageItemSelected()) {
                        spPackages.selectPackageSingleItem();
                    }

                    spPackages.setSmallScreenState(PACKAGE_SMALL_SCREEN_STATES.PHOTO_VIEW);
                    spEventBus.emit(PACKAGE_EVENTS.SELECT);
                }
            }
        }
    ],
    template: spPackageSingleItemTileTemplate
};
