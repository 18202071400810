import type { ICrop } from 'ts/common/types';

export interface IAddItemToCartResponse {
    cartPhotoState?: ICartPhotoState;
    errors?: {
        doesAcceptTerms?: {
            isEmpty?: string;
        };
    };
    statusText: 'success' | 'error' | 'form_errors';
    userState?: SpApi.Client.IUserState;
    need_email?: boolean;
}

export interface IAlbumAuthResponse {
    errors?: object;
    statusText: 'success' | 'error' | 'form_errors';
}

export interface ISetEmailResponse {
    errors?: {
        doesAcceptTerms?: {
            isEmpty?: string;
        };
    };
    statusText?: string;
}

export interface IBound {
    bounds_name: string;
    digital_type?: string;
    items: IBoundItem[];
}

export interface IBoundItem {
    description_images: { t: string; l: string }[];
    effectName?: Nullable<string>;
    id: string;
    is_album?: boolean;
    is_event?: boolean;
    is_individual?: boolean;
    name: string;
    parent_price_sheet_item_id?: Nullable<string>;
    price: string;
    priceSheetItemId: number;
    productMasks?: SpApi.ProductMaskUrls;
    whcc_id: string;
    whcc_product_id: string;
}

export interface ICartPhotoState {
    numInCart: number;
    preDiscountSubtotal: number;
}

export interface IDataResponse {
    photos?: IDataPhoto[];
}

export interface IDataPhoto {
    c: boolean;
    externalUrl: string;
    f: boolean;
    g: number;
    h?: boolean;
    id: number;
    key: string;
    l: boolean;
    lbw?: boolean;
    n: string;
    t?: string[];
    w: number;
    a?: Nullable<number>;
}

export interface IImage {
    filterBlackAndWhite: boolean;
    height: number;
    id: number;
    url: string;
    editorUrl?: string;
    width: number;
    favFromAlbumId?: Nullable<number>;
}

export interface IPhotoConfig {
    albumContextId?: string;
    cropData: Nullable<ICrop>;
    image: IImage;
    key?: string;
    priceSheetItem: SpApi.Client.IPriceSheetItem;
    quantity: number;
}

export interface IPriceSheetDataResponse {
    cartPhotoState: ICartPhotoState;
    discountDescriptions?: string[];
    groups: IProductGroup[];
}

export interface IProductGroup {
    bounds: IBound[];
    description: Nullable<string>;
    id: number;
    is_digital_download: boolean;
    name: string;
    type: number;
}

export interface IPathContext {
    context: string;
    contextId: string;
}

export enum GroupType {
    PRINTS = 1,
    CANVASES = 2,
    DIGITALS = 3,
    PRODUCTS = 4,
    METAL_PRINTS = 5,
    FINE_ART_PRINTS = 6,
    CARDS = 8,
    ACRYLIC_BLOCKS = 9,
    ORNAMENTS = 10
}

export interface IFormValues {
    priceSheetItemId: number;
    priceSheetSubgroupId?: number;
    quantity: number;
}

/**
 * An available size in the selection of sizes for a product.
 */
export interface ProductSize {
    /** The label for this size which an end user will see */
    size: string;
    /** The id for a price sheet subgroup which corresponds to this size */
    priceSheetSubgroupId: number;
}

/**
 * An available option in the selection of options for a product.
 * For framed products, this represents an option in the set of available frames,
 * and it is presented to end users as the primary selection, above the set of sizes.
 */
export interface ProductOption {
    /** The label for this option which an end user will see */
    name: string;
    /** The id for a price sheet item which corresponds to this option */
    priceSheetItemId: number;
    /** The id for the price sheet subgroup which includes the item related to this option */
    priceSheetSubgroupId?: number;
    /** The color or image URL for framed products */
    frame?: { color: string } | { imageUrl: string };
}
