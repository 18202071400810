import { EVENT_TAG_EVENTS } from 'Sp/Angular/Events/Client/Gallery';

/**
 * @ngdoc service
 * @name sp.client.gallery.service:spEventTags
 *
 * @requires $rootScope
 *
 * @description
 * Handles the retrieval and management of event photo tags for in order to
 * maintain the total and maximum tag count related to an event photo tag
 **/
export default [
    '$rootScope',
    function spEventTagsService($rootScope) {
        var service = this;

        service.eventTags = {};

        service.getPhotoTags = getPhotoTags;
        service.togglePhotoTagCount = togglePhotoTagCount;

        $rootScope.$on(EVENT_TAG_EVENTS.GOT, getEventTagGotEvent);

        function getEventId() {
            return $rootScope.eventData.id;
        }

        function getEventTagGotEvent(_, eventTags) {
            service.eventTags = mapEventTagArray(eventTags);
        }

        function mapEventTagArray(eventTags) {
            return eventTags.reduce(function reduceEventTagArray(eventTagById, tag) {
                eventTagById[tag.id] = tag;

                return eventTagById;
            }, {});
        }

        function mapTagToggleCountById(eventTags) {
            var eventTagToggleKeys = Object.keys(eventTags);

            return eventTagToggleKeys.reduce(function reduceTogglePhotoCounts(countMap, toggleKey) {
                var taggedPhotoCount = Number(eventTags[toggleKey].num_tagged_photos);

                countMap[toggleKey] = { photosCount: taggedPhotoCount };

                return countMap;
            }, {});
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spEventTags#getPhotoTags
         * @methodOf sp.client.gallery.service:spEventTags
         *
         * @description
         * Triggers retrieval of event photo tags with count data
         *
         * @param {number=} eventId Unique event identifier
         */
        function getPhotoTags(eventId) {
            eventId = eventId || getEventId();

            $rootScope.$emit(EVENT_TAG_EVENTS.GET, eventId);
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spEventTags#togglePhotoTagCount
         * @methodOf sp.client.gallery.service:spEventTags
         *
         * @description
         * Updates the event tag photo count utilizing the event tag toggle
         * photo response
         *
         * @param {array<object>} eventTags Array of event tag item counts from
         * the backend
         */
        function togglePhotoTagCount(eventTags) {
            service.eventTags = angular.merge(
                {},
                service.eventTags,
                mapTagToggleCountById(eventTags)
            );
        }

        return service;
    }
];
