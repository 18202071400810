import { useCallback, useMemo, useState } from 'react';
import { FetchMethod, IFetchHookResponse, useFetch } from 'ts/common/hooks';

type IAccessErrors = { email?: Record<string, string>; password?: Record<string, string> };

type IAccessSuccessResponse = {
    msg?: string;
    copiedSharedFavorites?: boolean;
};

type IAccessErrorResponse = {
    errors: IAccessErrors;
};

type IAccessResponse = IAccessSuccessResponse | IAccessErrorResponse;

function isSuccessResponse(
    response: IFetchHookResponse<IAccessResponse>
): response is NonNullable<IFetchHookResponse<IAccessSuccessResponse>> {
    return response !== null && !('errors' in response);
}

export interface ICredentials {
    email?: string;
    password?: string;
    doesAcceptTerms?: boolean;
}

/**
 * Provide access to the client galleries.
 * @param baseUrl absolute URL
 * @param isPreRegister is the gallery pre-released
 * @param isAdminRoute is the user on the `/admin` path
 * @param onSuccess Make sure this callback is wrapped in a `useCallback`
 * @returns `successMessage`, `errors`, `submitCredentials`, and a `clearStatus` function
 */
export function useAccess(
    baseUrl: string,
    isPreRegister: boolean,
    isAdminRoute: boolean,
    onSuccess: (
        response: NonNullable<IFetchHookResponse<IAccessSuccessResponse>>,
        credentials: ICredentials
    ) => void
) {
    const [successMessage, setSuccessMessage] = useState('');
    const [errors, setErrors] = useState<IAccessErrors>({});

    const url = useMemo(() => {
        if (isPreRegister) {
            if (isAdminRoute) {
                return `${baseUrl}/adminmodeentryauth`;
            }

            return `${baseUrl}/preregister`;
        }

        return `${baseUrl}/auth`;
    }, [baseUrl, isPreRegister, isAdminRoute]);

    const { performFetch: sendPost } = useFetch<IAccessResponse>(url, {
        method: FetchMethod.POST,
        defer: true
    });

    const submitCredentials = useCallback(
        async (credentials: ICredentials) => {
            setErrors({});

            const formData = new FormData();
            formData.append('email', credentials.email ?? '');
            formData.append('password', credentials.password ?? '');
            formData.append('doesAcceptTerms', credentials.doesAcceptTerms ? '1' : '0');

            const response = await sendPost({
                body: formData
            });

            if (isSuccessResponse(response)) {
                if (response.msg) {
                    setSuccessMessage(response.msg);
                }

                onSuccess(response, credentials);
                return;
            }

            if (response?.errors) {
                setErrors(response.errors);
            }
        },
        [onSuccess, sendPost]
    );

    const clearStatus = useCallback(() => {
        setSuccessMessage('');
        setErrors({});
    }, []);

    return { successMessage, errors, submitCredentials, clearStatus };
}
