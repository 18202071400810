import React, { FC } from 'react';
import { Heading, HeadingProps } from '@chakra-ui/react';

interface IGalleryHeadingProps {
    size: 'sm' | 'md' | 'lg';
    text: string;
    variant: 'dark' | 'light' | 'primary';
}

const GalleryHeading: FC<IGalleryHeadingProps & HeadingProps> = ({
    size,
    text,
    variant,
    ...props
}) => (
    <Heading
        as="h1"
        margin={0}
        size={size === 'sm' ? 'xl' : size === 'md' ? '2xl' : '3xl'}
        variant={variant}
        {...props}
    >
        {text}
    </Heading>
);

export default GalleryHeading;
