import React, { FC, memo } from 'react';
import { Box, Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import type { IPhotoActions, IPhotoWithUrl, IRootScope } from 'ts/client/types';
import PhotoToolbar from 'client_react/gallery/components/PhotoToolbar';

interface Props {
    /** True only if the photo has black & white enabled */
    isBlackAndWhite: boolean;
    /** True only if the download button should be visible */
    isDownloadPossible: boolean;
    /** True only if the photo is favorited */
    isFavorite: boolean;
    /** True only if the photo is hidden */
    isHidden: boolean;
    /** True only if the photo has at least one label applied */
    isLabeled: boolean;
    /** The photo to render */
    photo: IPhotoWithUrl;
    /** An object with callbacks to perform actions on photos */
    photoActions: IPhotoActions;
    /** True if the user needs to select a download destination for a pending download */
    requiresDownloadDestination: boolean;
    /** Angular free digitals service */
    spFreeDigitals: SpAngularJs.ISpFreeDigitals;
    /** Angular translation service */
    translateFilter: SpAngularJs.ITranslateFilter;
    /** Angular root scope */
    $rootScope: IRootScope;
}

const PhotoComparePanel: FC<Props> = ({
    isBlackAndWhite,
    isDownloadPossible,
    isFavorite,
    isHidden,
    isLabeled,
    photo,
    ...rest
}) => {
    return (
        <Flex alignItems="center" flexDirection="column" gap="24px" width="100%">
            <Flex
                alignItems="stretch"
                alignSelf="stretch"
                flexDirection="column"
                flexGrow={1}
                gap={useBreakpointValue({ base: '16px', lg: '20px' })}
            >
                <Text
                    alignSelf="center"
                    fontSize="xs"
                    maxWidth={useBreakpointValue({ base: '100vw', lg: '40vw' })}
                    overflow="hidden"
                    paddingX="8px"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                >
                    {photo.filename}
                </Text>
                <Box
                    role="img"
                    aria-label={photo.filename}
                    backgroundImage={`url(${photo.url})`}
                    backgroundPosition="top"
                    backgroundRepeat="no-repeat"
                    backgroundSize="contain"
                    filter={isBlackAndWhite ? 'grayscale(100%)' : undefined}
                    flexGrow={1}
                />
            </Flex>
            <PhotoToolbar
                isBlackAndWhite={isBlackAndWhite}
                isDownloadPossible={isDownloadPossible}
                isFavorite={isFavorite}
                isHidden={isHidden}
                isLabeled={isLabeled}
                photo={photo}
                {...rest}
            />
        </Flex>
    );
};

export default memo(PhotoComparePanel);
