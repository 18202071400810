import React, { FC, useCallback, useState } from 'react';
import { Flex, Link, Text } from '@chakra-ui/react';

interface Props {
    /** Requests a new client access token which is not connected to Google Photos */
    disconnectFromGoogle: () => Promise<void>;
    /** The authorized Google email, if any, for the current gallery user */
    googleEmail: string;
    /** Angular translation service */
    translateFilter: SpAngularJs.ITranslateFilter;
}

const GooglePhotosConnection: FC<Props> = ({
    disconnectFromGoogle,
    googleEmail,
    translateFilter: t
}) => {
    const [disconnected, setDisconnected] = useState(false);

    const handleDisconnection = useCallback(async () => {
        await disconnectFromGoogle();
        setDisconnected(true);
    }, [disconnectFromGoogle]);

    return (
        <Flex alignItems="center" flexDirection="column" width="100%">
            <Text fontSize="xs" textAlign="center">
                {disconnected
                    ? t('client.gallery.downloadDestinations.disconnectedFromGooglePhotos')
                    : t('client.gallery.downloadDestinations.connectedToGooglePhotos', {
                          googleEmail
                      })}
            </Text>
            {!disconnected && (
                <Link as="button" onClick={handleDisconnection} variant="googlePhotos">
                    <Text fontSize="sm" fontWeight="bold" textDecoration="underline">
                        {t('client.gallery.downloadDestinations.disconnectFromGooglePhotos')}
                    </Text>
                </Link>
            )}
        </Flex>
    );
};

export default GooglePhotosConnection;
