/* eslint-disable func-names */

export default [
    '$scope',
    '$routeParams',
    '$location',
    '$window',
    'SPClientData',
    function AcceptFavoritesController($scope, $routeParams, $location, $window, SPClientData) {
        $scope.submit = function submit() {
            if (!$scope.email) {
                return;
            }

            SPClientData.acceptFavoritesAuth(
                $routeParams.token,
                $scope.email,
                function() {
                    if (!$scope.eventData.settings.requirePassword) {
                        $location.url('favorites');
                    } else {
                        $window.location.href = $scope.eventData.publicUrl;
                    }
                },
                function(response) {
                    if (typeof response.errors === 'object') {
                        $scope.errors = response.errors;
                    }
                }
            );
        };
    }
];
