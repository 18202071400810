/* eslint-disable func-names, no-unused-vars */

var directiveName = 'spSlideshowPhoto';
var defaultOptions = {
    holdDuration: 5000,
    fadeDuration: 500,
    autoPlay: true
};

/**
 * This animation yeilds a continually growing photo which will
 * crossfade with the next photo before the grow animation completes
 *
 * Size:
 * __/    __/    __/
 *     __/    __/
 *  __/    __/    __
 * /      /      /
 *
 * Opacity:
 *   _____  _____  _
 * \/     \/     \/
 * /\     /\     /\
 *
 **/
export default [
    '$window',
    '$timeout',
    function spSlideshowPhotoDirective($window, $timeout) {
        var windowElement = angular.element($window);

        return {
            restrict: 'A',
            scope: true,
            link: function spSlideshowPhotoController($scope, element) {
                var slideHoldPromise;
                // zoom duration is the total animation time
                var zoomDuration = 2 * defaultOptions.fadeDuration + defaultOptions.holdDuration;

                // this method fires @ zeroTime + fadeDuration
                // when the incoming photo has reached 100% opacity
                function transitionComplete() {
                    // hold for holdDuration
                    // (runs @ zeroTime + fadeDuration + holdDuration)
                    slideHoldPromise = $timeout(function() {
                        if ($scope.$parent.$parent.playSlideshow) {
                            $scope.$parent.$parent.$emit('advanceSlideshow');
                        }
                    }, defaultOptions.fadeDuration + defaultOptions.holdDuration);
                }

                // detect autoplay
                $scope.$parent.$parent.playSlideshow = defaultOptions.autoPlay;

                function pauseSlideshow() {
                    $scope.$parent.$parent.playSlideshow = false;
                }

                function resumeSlideshow() {
                    $scope.$parent.$parent.playSlideshow = true;

                    $scope.$parent.$parent.$emit('advanceSlideshow');
                }

                $scope.$parent.$parent.toggleSlideshow = function toggleSlideshow() {
                    if ($scope.$parent.$parent.playSlideshow) {
                        pauseSlideshow();
                    } else {
                        resumeSlideshow();
                    }
                };

                $scope.$on('$destroy', function() {
                    if (slideHoldPromise) {
                        $timeout.cancel(slideHoldPromise);
                    }
                });

                // this method will fire @ zeroTime + fadeDuration + holdDuration
                // this affects the current photo and will occur in concert with
                // the incomingTransition on the incoming photo
                function performOutgoingTransition() {
                    // fade out current photo
                    element.fadeTo(defaultOptions.fadeDuration, 0);
                }

                // this method will fire @ zeroTime + fadeDuration + holdDuration
                // this affects the incoming photo and will occur in concert with
                // the outgoingTransition on the current photo
                function performIncomingTransition() {
                    // intialize photo for grow transition
                    element
                        .css('-webkit-transform', 'translateZ(-5px)')
                        .css('-moz-transform', 'translateZ(-5px)')
                        .css('transform', 'translateZ(-5px)')
                        .fadeTo(defaultOptions.fadeDuration, 1);

                    // begin grow transition on incoming photo
                    // begin fade on incoming photo
                    element
                        .css(
                            '-webkit-transition',
                            '-webkit-transform ' + zoomDuration + 'ms ease-in-out'
                        )
                        .css('-moz-transition', '-moz-transform ' + zoomDuration + 'ms ease-in-out')
                        .css('transition', 'transform ' + zoomDuration + 'ms ease-in-out');

                    element
                        .css('-webkit-transform', 'translateZ(0)')
                        .css('-moz-transform', 'translateZ(0)')
                        .css('transform', 'translateZ(0)');

                    transitionComplete();
                }

                var first = true;

                $scope.$watchCollection('slideshowPhotos', function() {
                    function checkForPhotoTransitions() {
                        if ($scope.$parent.$index === 0) {
                            performOutgoingTransition();
                        }

                        if ($scope.$parent.$index === 1) {
                            performIncomingTransition();
                        }
                    }

                    checkForPhotoTransitions();
                });
            }
        };
    }
];
