export default [
    '$scope',
    '$routeParams',
    'spHiddenAlbum',
    'SPModal',
    'spPackages',
    function AlbumController($scope, $routeParams, spHiddenAlbum, SPModal) {
        $scope.isHidden = spHiddenAlbum.isInFlow;
        $scope.closeShareModal = closeShareModal;
        $scope.getDownloadTextHeaderKey = getDownloadTextHeaderKey;
        $scope.getDownloadTextProcessingKey = getDownloadTextProcessingKey;

        initialize();

        function initialize() {
            if (spHiddenAlbum.isInitialViewAHiddenAlbum) {
                spHiddenAlbum.persistFlow();
            }
        }

        function closeShareModal() {
            SPModal.close();
        }

        function getDownloadTextHeaderKey() {
            return $routeParams.albumId === 'all' ? 'downloadAll' : 'download';
        }

        function getDownloadTextProcessingKey() {
            return $routeParams.albumId === 'all' ? 'galleryDownloadProcessing' : 'downloadPhotos';
        }
    }
];
