import React, { FC, useCallback } from 'react';
import { Box, Checkbox, Flex, Text } from '@chakra-ui/react';
import type { IPhoto } from 'ts/client/types';
import {
    PhotoActionList,
    PhotoIndicatorList,
    PhotoKebab,
    ViewPhotoOverlay
} from 'ts/common/components/gallery';
import { useBreakpoint } from 'ts/common/hooks';
import type { IUsePhotoActionsProps } from 'ts/common/hooks/usePhotoActions';

interface Props extends IUsePhotoActionsProps {
    /** True if the user is in Compare mode */
    isComparing: boolean;
    /** True if the photo is in the cart */
    isInCart: boolean;
    /** True if the Package Builder is open */
    isPackageBuilderOpen?: boolean;
    /** True if the user selected enough photos for comparison */
    isReadyToCompare?: boolean;
    /** True if the photo is selected in Compare mode or the Package Builder */
    isSelected: boolean;
    /** Callback when the photo overlay is clicked */
    onClick: (photo: IPhoto, goTo?: boolean) => void;
}

const PhotoOverlay: FC<Props> = ({
    isBlackAndWhite,
    isComparing,
    isDownloadPossible,
    isFavorite,
    isHidden,
    isInCart,
    isLabeled,
    isPackageBuilderOpen,
    isReadyToCompare,
    isSelected,
    onClick,
    photo,
    photoActions,
    requiresDownloadDestination,
    spFreeDigitals,
    translateFilter,
    $rootScope
}) => {
    const isLargeScreen = useBreakpoint();

    const handleClick = useCallback(
        (event: React.MouseEvent) => {
            event.stopPropagation();
            event.preventDefault();
            onClick(photo);
        },
        [onClick, photo]
    );

    const handleViewPhotoClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        onClick(photo, true);
    };

    const isSelecting = isComparing || isPackageBuilderOpen;

    return (
        <Flex
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
            position="relative"
            role="group"
            borderStyle="solid"
            borderWidth="4px"
            borderColor={isSelected && isSelecting ? 'brand.primaryOverlay' : 'transparent'}
            onClick={handleClick}
        >
            <Flex justifyContent="space-between" zIndex={1}>
                {isSelecting && (
                    <Box padding="4px">
                        <Checkbox isChecked={isSelected} variant="round" size="xl" readOnly />
                    </Box>
                )}
                <PhotoIndicatorList
                    isBlackAndWhite={isBlackAndWhite}
                    isFavorite={isFavorite}
                    isHidden={isHidden}
                    isInCart={isInCart}
                    isLabeled={isLabeled}
                />
            </Flex>
            {isLargeScreen &&
                ((isComparing && !isSelected && !isReadyToCompare) || isPackageBuilderOpen) && (
                    <Flex
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        position="absolute"
                        height="100%"
                        width="100%"
                        top={0}
                        left={0}
                        background="blackAlpha.500"
                        opacity={0}
                        _groupHover={{ opacity: 1 }}
                        transition="0.25s opacity"
                    >
                        <Text color="white">
                            {isComparing && translateFilter('selectToCompare')}
                            {isPackageBuilderOpen &&
                                translateFilter(isSelected ? 'deselectPhoto' : 'selectPhoto')}
                        </Text>
                    </Flex>
                )}
            {isComparing && !isSelected && isReadyToCompare && (
                <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    position="absolute"
                    height="calc(100% + 8px)"
                    width="calc(100% + 8px)"
                    top="-4px"
                    left="-4px"
                    background="whiteAlpha.500"
                    opacity={1}
                    transition="0.25s opacity"
                />
            )}
            {isPackageBuilderOpen && (
                <ViewPhotoOverlay onClick={handleViewPhotoClick} translate={translateFilter} />
            )}
            {!isSelecting && (
                <>
                    {isLargeScreen ? (
                        <PhotoActionList
                            isBlackAndWhite={isBlackAndWhite}
                            isDownloadPossible={isDownloadPossible}
                            isFavorite={isFavorite}
                            isHidden={isHidden}
                            isLabeled={isLabeled}
                            photo={photo}
                            photoActions={photoActions}
                            requiresDownloadDestination={requiresDownloadDestination}
                            spFreeDigitals={spFreeDigitals}
                            translateFilter={translateFilter}
                            $rootScope={$rootScope}
                        />
                    ) : (
                        <PhotoKebab
                            isBlackAndWhite={isBlackAndWhite}
                            isDownloadPossible={isDownloadPossible}
                            isFavorite={isFavorite}
                            isHidden={isHidden}
                            isLabeled={isLabeled}
                            photo={photo}
                            photoActions={photoActions}
                            requiresDownloadDestination={requiresDownloadDestination}
                            spFreeDigitals={spFreeDigitals}
                            translateFilter={translateFilter}
                            $rootScope={$rootScope}
                        />
                    )}
                </>
            )}
        </Flex>
    );
};

export default PhotoOverlay;
