import { PACKAGE_SMALL_SCREEN_STATES } from 'Sp/Gallery';

export default [
    '$scope',
    'SPClientData',
    'SPModal',
    'SPPhoto',
    'spAppData',
    'spPackage',
    'spPackages',
    function SelectPackageController(
        $scope,
        SPClientData,
        SPModal,
        SPPhoto,
        spAppData,
        spPackage,
        spPackages
    ) {
        const $ctrl = this;
        const MAX_RECOMMENDED_PACKAGES = 3;
        let previousPosition;
        let currentlySelectedPackage;

        Object.assign($ctrl, {
            carouselItems: {},
            doDisplayCollectEmail: null,
            errors: null,
            packageToView: null,
            priceSheetGroups: [],
            selectedPriceSheetGroup: {},
            showLearnMore: false,

            $onInit,
            captureEmail,
            close,
            doShowBackNavigation,
            doShowSecondaryHeader,
            getBackgroundImage,
            getExampleBackgroundImage,
            getFirstHeaderPreviewImage,
            getHeadingText,
            getPartialURL: $scope.getPartialURL,
            getRecommendedPackages,
            getRemainingPackages,
            getSecondHeaderPreviewImage,
            getTotalItems,
            seeAllPackages,
            selectPackage,
            selectViewedPackage,
            selectPriceSheetGroup,
            showRemainingPackages,
            toggleLearnMore,
            viewPackage,

            isPackageSinglePose: spPackage.getUseSingleImageIndicator
        });

        function $onInit() {
            SPClientData.getPriceSheetAllItems(({ groups }) => {
                $ctrl.priceSheetGroups = groups;
                $ctrl.selectedPriceSheetGroup = $ctrl.priceSheetGroups[0];
            });
        }

        function addToCart(doesAcceptTerms = null, email = '') {
            spPackages.addToCart({
                doesAcceptTerms,
                email,
                onError: ({ errors, needsEmail }) => {
                    $ctrl.doDisplayCollectEmail = needsEmail;
                    $ctrl.errors = errors;
                },
                onSuccess: () => {
                    close().then(() => {
                        SPModal.open('main', {
                            partialDirectory: 'package-success',
                            type: 'dialog'
                        });
                    });
                },
                packageToAdd: currentlySelectedPackage,
                photoForPackage: $scope.photos[0]
            });
        }

        function captureEmail(email, doesAcceptTerms) {
            addToCart(doesAcceptTerms, email);
        }

        function doShowBackNavigation() {
            return !$ctrl.doDisplayCollectEmail && ($ctrl.packageToView || $ctrl.showLearnMore);
        }

        function doShowSecondaryHeader() {
            const { priceSheetShowPackagesOnly } = spAppData.get('eventData').settings;

            return !priceSheetShowPackagesOnly && !$ctrl.showLearnMore && !$ctrl.packageToView;
        }

        function close() {
            return SPModal.close();
        }

        function getHeadingText() {
            if ($ctrl.showLearnMore) {
                return 'buyingPhotos';
            }

            return 'buyPackage';
        }

        function getBackgroundImage() {
            return SPPhoto.getUrl($scope.photos[0], 'm');
        }

        function getExampleBackgroundImage() {
            return `url(${SPPhoto.getUrl($scope.photos[0], 'm')})`;
        }

        function getFirstHeaderPreviewImage() {
            return SPPhoto.getUrl($scope.photos[0], 'm');
        }

        function getSecondHeaderPreviewImage() {
            return $scope.photos.length > 1 ? SPPhoto.getUrl($scope.photos[1], 'm') : null;
        }

        function getRecommendedPackages() {
            return spPackages.getPackages().slice(0, MAX_RECOMMENDED_PACKAGES);
        }

        function getRemainingPackages() {
            return showRemainingPackages()
                ? spPackages.getPackages().slice(MAX_RECOMMENDED_PACKAGES)
                : [];
        }

        function getTotalItems(packageToSumItems) {
            return spPackages.sumItemQuantities(packageToSumItems.items);
        }

        function seeAllPackages() {
            const scrollElement = document.querySelector('.dialog-content');

            $ctrl.packageToView = null;
            $ctrl.showLearnMore = false;

            setTimeout(() => {
                scrollControl(scrollElement);
            }, 10);
        }

        function selectPackage(selectedPackage) {
            if (
                selectedPackage.items.length === 1 &&
                spPackage.isShootProofFulfilledAllGalleryDownloadsDigitalItem(
                    selectedPackage.items[0]
                )
            ) {
                currentlySelectedPackage = selectedPackage;

                addToCart();
            } else {
                spPackages.setSelectedPackage(selectedPackage);
                spPackages.setSmallScreenState(PACKAGE_SMALL_SCREEN_STATES.PACKAGE_VIEW);
                close();
            }
        }

        function selectViewedPackage() {
            $ctrl.selectPackage($ctrl.packageToView);
        }

        function selectPriceSheetGroup(groupId) {
            $ctrl.selectedPriceSheetGroup = $ctrl.priceSheetGroups.find(
                (group) => group.id === groupId
            );
        }

        function showRemainingPackages() {
            return spPackages.getPackages().length > MAX_RECOMMENDED_PACKAGES;
        }

        function toggleLearnMore() {
            $ctrl.showLearnMore = !$ctrl.showLearnMore;

            $scope.$digest();
        }

        function scrollControl(scrollElement) {
            return $ctrl.packageToView
                ? (scrollElement.scrollTop = 0)
                : scrollElement.scrollTo(0, previousPosition);
        }

        function viewPackage(packageToView) {
            const scrollElement = document.querySelector('.dialog-content');

            $ctrl.packageToView = packageToView;
            $ctrl.carouselItems = $ctrl.packageToView.images.reduce((carouselObject, image) => {
                const { displayUrl, name } = image;

                return Object.assign({}, carouselObject, {
                    [name]: {
                        imgUrl: displayUrl.large,
                        name
                    }
                });
            }, {});
            previousPosition = scrollElement.scrollTop;
            scrollControl(scrollElement);

            $scope.$digest();
        }
    }
];
