import spPackageBuilderItemTemplate from '../../templates/components/sp-package-builder-item.nghtml';

/**
 * @ngdoc component
 * @name sp.client.gallery.component:spPackageBuilderItem
 *
 * @requires $rootScope
 * @requires sp.newcommon.service:spPackage
 * @requires sp.client.gallery.service:spPackages
 * @requires sp.client.gallery.filter:spPackageItemDetailFilter
 *
 * @description
 * Renders package entries for a given package item
 *
 * @param {object=} album Current album selected
 * @param {array<object>=} albums current array of all albums
 * @param {object} item Package item
 * @param {integer=} itemIndex Index in the Selection State of the Price Sheet Item in a Package
 * @param {array<object>} photos Photos available in the current view
 */
export default {
    bindings: {
        album: '<?',
        albums: '<?',
        item: '<',
        itemIndex: '<?',
        photos: '<?'
    },
    template: spPackageBuilderItemTemplate,
    controller: [
        '$rootScope',
        'spPackage',
        'spPackages',
        'spPackageItemDetailFilter',
        function spPackageBuilderItemController(
            $rootScope,
            spPackage,
            spPackages,
            spPackageItemDetailFilter
        ) {
            const $ctrl = this;

            $ctrl.getEventPhotoCount = getEventPhotoCount;
            $ctrl.getItemDisplayContent = getItemDisplayContent;
            $ctrl.getPackageItemSelectionState = getPackageItemSelectionState;
            $ctrl.isShootProofFulfilledDigitalItem = spPackage.isShootProofFulfilledDigitalItem;
            $ctrl.isShootProofFulfilledAllAlbumDownloadsDigitalItem = isShootProofFulfilledAllAlbumDownloadsDigitalItem;
            $ctrl.isShootProofFulfilledAllGalleryDownloadsDigitalItem = isShootProofFulfilledAllGalleryDownloadsDigitalItem;

            function getEventPhotoCount() {
                return $rootScope.eventData.settings.totalCount;
            }

            function getItemDisplayContent() {
                const { item } = $ctrl;

                return spPackageItemDetailFilter(item, false);
            }

            function getPackageItemSelectionState() {
                const { id } = $ctrl.item;
                const selectionState = spPackages.getPackageItemsSelectionState();

                if (selectionState) {
                    return selectionState[id];
                }
            }

            function isShootProofFulfilledAllAlbumDownloadsDigitalItem() {
                return spPackage.isShootProofFulfilledAllAlbumDownloadsDigitalItem($ctrl.item);
            }

            function isShootProofFulfilledAllGalleryDownloadsDigitalItem() {
                return spPackage.isShootProofFulfilledAllGalleryDownloadsDigitalItem($ctrl.item);
            }
        }
    ]
};
