import React, { FC, memo, useEffect, useState } from 'react';
import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Box,
    Text
} from '@chakra-ui/react';
import { Minus, Plus } from 'react-feather';
import { useTranslate } from 'ts/common';

interface IProductDescriptionAccordion {
    productDescription?: Nullable<string>;
}

const ProductDescriptionAccordion: FC<IProductDescriptionAccordion> = ({ productDescription }) => {
    const t = useTranslate('client.gallery.productDescriptionAccordion');

    const [productDescriptionOpenIndex, setProductDescriptionOpenIndex] = useState(-1);

    useEffect(() => {
        if (productDescription) {
            setProductDescriptionOpenIndex(0);
        }
    }, [productDescription]);

    return (
        <Accordion
            allowToggle
            index={productDescriptionOpenIndex}
            onChange={(expandedIndex: number) => setProductDescriptionOpenIndex(expandedIndex)}
        >
            <AccordionItem>
                {({ isExpanded }) => (
                    <>
                        <h5>
                            <AccordionButton
                                color="brand.primary"
                                data-testid="product-description-accordion-button"
                                _focus={{
                                    boxShadow: '0 0 0 3px var(--chakra-colors-brand-primary)',
                                    color: 'brand.primary'
                                }}
                                _hover={{ color: 'brand.primary' }}
                            >
                                <Box as="span" flex="1" textAlign="left">
                                    {t('productDescription')}
                                </Box>
                                {isExpanded ? <Minus /> : <Plus />}
                            </AccordionButton>
                        </h5>
                        <AccordionPanel>
                            <Text
                                dangerouslySetInnerHTML={{
                                    __html: productDescription ?? t('productDescriptionPlaceholder')
                                }}
                                data-testid="product-description-text"
                                fontFamily="open-sans, sans-serif"
                                fontSize="md"
                            />
                        </AccordionPanel>
                    </>
                )}
            </AccordionItem>
        </Accordion>
    );
};

export default memo(ProductDescriptionAccordion);
