/* eslint-disable func-names */

export default [
    '$scope',
    'SPClientData',
    'spEventTags',
    'translateFilter',
    function TagsController($scope, SPClientData, spEventTags, translateFilter) {
        $scope.shouldShowTagsContent = shouldShowTagsContent;
        $scope.getTagsCaption = getTagsCaption;

        spEventTags.getPhotoTags();

        SPClientData.getTaggedPhotos(undefined, function getTaggedPhotoHandlerSuccess(response) {
            // Sort the photos by the tags they are tagged in
            // Also, Create a copy of the tags object in our scope with photos attached
            var tags = angular.copy($scope.getTags());

            // Handles a case where no Photos are tagged.
            if (response.photos.length > 0) {
                tags.forEach(function iterateTags(tag) {
                    tag.photos = response.photos.filter(function filterPhotoTags(photo) {
                        if (photo.tags.indexOf(tag.id) !== -1) {
                            return true;
                        }

                        return false;
                    });
                });
            }

            $scope.tags = tags;
        });

        function shouldShowTagsContent() {
            return $scope.tags && $scope.tags.length === Object.keys(spEventTags.eventTags).length;
        }

        function getTagsCaption() {
            return translateFilter($scope.tags.length === 1 ? 'oneLabel' : 'multipleLabels', {
                labelCount: $scope.tags.length
            });
        }
    }
];
