import React, { FC, useMemo } from 'react';
import { Flex, useBreakpointValue } from '@chakra-ui/react';
import type { IImage } from 'ts/client/gallery/components';
import { PackageDetail } from 'ts/client/gallery/components';
import { sortByDisplayOrder } from 'ts/client/gallery/components/common';
import { GalleryHeading } from 'ts/common/components/gallery';
import { useTranslate } from 'ts/common/hooks';
import { useStore } from 'client_react/gallery/hooks/StoreContext';

interface ISelectPackagePage {
    navigate: (path: string, needsDigest?: boolean) => void;
    /** Callback to open the package builder tool */
    selectPackage: (
        selectedPackage: SpApi.Client.IPriceSheetItem,
        selectedPhoto?: IImage,
        isSmallScreen?: boolean
    ) => void;
}

const SelectPackagePage: FC<ISelectPackagePage> = ({ navigate, selectPackage }) => {
    const t = useTranslate('client.store.selectPackagesPage');
    const { imageOnSelectedGroup, pathContextBeforeRouteChange, selectedPriceSheetGroup } =
        useStore();

    const packagesSortedByDisplayOrder = useMemo(
        () => (selectedPriceSheetGroup ? sortByDisplayOrder(selectedPriceSheetGroup.items) : []),
        [selectedPriceSheetGroup]
    );

    const onPackageSelected = (pkg: SpApi.Client.IPriceSheetItem) => {
        const { context, contextId } = pathContextBeforeRouteChange;
        navigate(contextId ? `${context}/${contextId}` : context || 'home');
        selectPackage(pkg);
    };

    const isMediumScreenOrLarger = useBreakpointValue({ base: false, md: true });

    return (
        <>
            <GalleryHeading
                data-testid="packages-heading"
                margin="30px 0 10px 0"
                size="lg"
                text={t('heading')}
                variant="primary"
            />
            <Flex flexDirection="column" gap="40px">
                {imageOnSelectedGroup &&
                    packagesSortedByDisplayOrder.map((pkg) => (
                        <PackageDetail
                            key={pkg.id}
                            image={imageOnSelectedGroup}
                            isSmallView={!isMediumScreenOrLarger}
                            onPackageSelected={onPackageSelected}
                            pkg={pkg}
                        />
                    ))}
            </Flex>
        </>
    );
};

export default SelectPackagePage;
