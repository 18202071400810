import React, { FC, useCallback, useState } from 'react';
import { Flex, FormControl, FormLabel, Textarea, useBreakpointValue } from '@chakra-ui/react';
import { Send } from 'react-feather';
import Button from 'ts/common/components/gallery/Button';
import Modal from 'ts/common/components/gallery/Modal';
import { modalButtonWidth } from 'ts/common/constants/theme/common';
import useToast from 'ts/common/hooks/useToast';
import ModalHeader from './ModalHeader';

type ModalState = 'input' | 'submitting';

interface ISendToPhotographerModalProps {
    /** True if the modal open */
    isOpen: boolean;
    /** Callback for the Send To Photographer Modal submit button */
    onSubmit: (message: string, onSuccess: () => unknown) => unknown;
    /** Callback for the modal's closing event */
    onClose: () => unknown;
    /** The Angular translation service */
    translate: SpAngularJs.ITranslateFilter;
}

const SendToPhotographerModal: FC<ISendToPhotographerModalProps> = ({
    isOpen,
    onSubmit,
    onClose,
    translate
}) => {
    const [modalState, setModalState] = useState<ModalState>('input');
    const [message, setMessage] = useState('');

    const favoritesSentToast = useToast({ type: 'favorites_sent', translateFilter: translate });

    const isButtonFullWidth = useBreakpointValue({ base: true, lg: false });

    const handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(event.target.value);
    };

    const handleClose = useCallback(() => {
        setModalState('input');
        onClose();
    }, [onClose, setModalState]);

    const handleSubmit = useCallback(() => {
        setModalState('submitting');
        onSubmit(message, () => {
            handleClose();
            setMessage('');
            favoritesSentToast();
        });
    }, [favoritesSentToast, handleClose, message, onSubmit]);

    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
            <Flex flexDirection="column" height="full" gap="24px">
                <ModalHeader
                    modalIcon={Send}
                    headerText={translate('sendFavoritesToPhotographer')}
                    headerSubText={translate('sendFavoritesToPhotographerSubtext')}
                />

                <FormControl flexGrow={1}>
                    <FormLabel>{translate('favoritesMessageLabel')}</FormLabel>
                    <Textarea height="300px" value={message} onChange={handleMessageChange} />
                </FormControl>

                <Button
                    fullWidth={isButtonFullWidth}
                    minWidth={modalButtonWidth}
                    alignSelf="center"
                    isLoading={modalState === 'submitting'}
                    text={translate('send')}
                    textLarge={translate('send')}
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                />
            </Flex>
        </Modal>
    );
};

export default SendToPhotographerModal;
