import React, { FC, useCallback, useState } from 'react';
import { Box, Flex, FormControl, Heading, Input, Text } from '@chakra-ui/react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import Button from 'ts/common/components/gallery/Button';

interface IDigitalDownloadPinForm {
    hasPin: boolean;
    onSubmit: (pin: number) => void;
    translateFilter: SpAngularJs.ITranslateFilter;
}

const DigitalDownloadPinForm: FC<IDigitalDownloadPinForm> = ({
    hasPin = true,
    onSubmit,
    translateFilter: t
}) => {
    const [showPinInput, setShowPinInput] = useState(false);
    const {
        formState: { errors, isSubmitting, isValid },
        handleSubmit,
        register,
        reset
    } = useForm({ mode: 'onTouched' });

    const handleSuccessfulAccess = useCallback(
        ({ pin }) => {
            onSubmit(pin);
            reset();
        },
        [onSubmit, reset]
    );

    return (
        <Flex
            as="form"
            flexDirection="column"
            onSubmit={handleSubmit(handleSuccessfulAccess as SubmitHandler<FieldValues>)}
        >
            <Heading as="h2" size={'md'} variant="primary" paddingTop="8px" paddingBottom="8px">
                {t('freeDigitalsChoose')}
            </Heading>
            {hasPin && (
                <FormControl paddingBottom="5px" isRequired isInvalid={Boolean(errors.pin)}>
                    {showPinInput ? (
                        <Box display="flex" alignItems="center">
                            <Input
                                width="256px"
                                placeholder={t('enterPIN')}
                                {...register('pin', { required: true })}
                            />
                            <Button
                                type="submit"
                                width="114px"
                                marginLeft="8px"
                                isDisabled={!isValid}
                                text={t('freeDigitalsCaptureCredentialsApplyPinButton')}
                                isLoading={isSubmitting}
                                loadingText={t('freeDigitalsCaptureCredentialsApplyingButton')}
                                variant="outline primary"
                            />
                        </Box>
                    ) : (
                        <Box>
                            <Text>
                                {t('freeDigitalsSelectDigitalRuleShowPinCalloutPin')}
                                <Button
                                    type="button"
                                    variant="text"
                                    text={t('freeDigitalsSelectDigitalRuleShowPinCalloutLink')}
                                    onClick={() => setShowPinInput(true)}
                                />
                                {t('freeDigitalsSelectDigitalRuleShowPinCalloutAdditional')}
                            </Text>
                        </Box>
                    )}
                </FormControl>
            )}
        </Flex>
    );
};

export default DigitalDownloadPinForm;
