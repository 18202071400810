import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

interface FramedPrintMeasuresBannerProps {
    item: SpApi.Client.IPriceSheetItemSingle;
}

export const FramedPrintMeasuresBanner: React.FC<FramedPrintMeasuresBannerProps> = ({ item }) => {
    if (!item || !item.frameWidth) {
        return null;
    }

    const printSize = `${item.size.width} x ${item.size.height} in`;
    const frameWidth = `${item.frameWidth} in`;
    const finishedSize =
        (item.size.width || 0) +
        (item.matWidth ? item.matWidth * 2 : 0) +
        (item.frameWidth ? item.frameWidth * 2 : 0) +
        ' x ' +
        ((item.size.height || 0) +
            (item.matHeight ? item.matHeight * 2 : 0) +
            (item.frameWidth ? item.frameWidth * 2 : 0)) +
        ' in';

    let matWidth: string | undefined;

    if (item.matWidth) {
        matWidth = `${item.matWidth} in`;
    }

    return (
        <Flex
            alignItems="center"
            background="#F6F8F8" // TODO: Fix to gray.100 when that color matches Figma
            borderColor="gray.200"
            borderRadius="8px"
            borderStyle="solid"
            borderWidth="1px"
            color="gray.400"
            justifyContent="space-evenly"
            marginY="16px"
            paddingY="8px"
        >
            <Box textAlign="center">
                <strong>Print Size</strong>
                <br />
                {printSize}
            </Box>
            {matWidth && (
                <Box textAlign="center">
                    <strong>Mat Width</strong>
                    <br />
                    {matWidth}
                </Box>
            )}
            <Box textAlign="center">
                <strong>Frame Width</strong>
                <br />
                {frameWidth}
            </Box>
            <Box textAlign="center">
                <strong>Finished Size</strong>
                <br />
                {finishedSize}
            </Box>
        </Flex>
    );
};
