import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import {
    Box,
    ButtonGroup,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    useBreakpointValue
} from '@chakra-ui/react';
import { useGalleryApiFetch } from 'ts/client/common';
import { IAlbumAuthResponse } from 'ts/client/gallery/components/types';
import { Button, ModalHeader } from 'ts/common/components/gallery';
import { StoreIcon } from 'ts/common/components/icons';
import { modalButtonWidth } from 'ts/common/constants/theme/common';
import { FetchMethod, useTranslate } from 'ts/common/hooks';
import { AlbumSelect } from 'client_react/gallery/components/Store';

interface IAlbumSelectModalProps {
    albums: SpApi.Client.IAlbum[];
    onSuccess: (albumId: number) => void;
    isOpen: boolean;
    onCancel: () => void;
    onClose: () => void;
}

const AlbumSelectModal: FC<IAlbumSelectModalProps> = ({
    albums,
    onSuccess,
    isOpen,
    onCancel,
    onClose
}) => {
    const t = useTranslate('client.store.albumSelectModal');
    const [password, setPassword] = useState('');
    const [isInvalid, setIsInvalid] = useState(false);
    const [selectedAlbumId, setSelectedAlbumId] = useState<number>(Number(albums[0]?.id));
    const isPasswordEmpty = password.length < 1;

    const ButtonGroupMarginBottom = useBreakpointValue({ base: '24px' });
    const ButtonGroupPadding = useBreakpointValue({ base: '16px' });
    const ButtonPosition = useBreakpointValue({ base: 'fixed', lg: 'static' } as const);

    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const albumHasPassword = useMemo(() => {
        return albums?.find((album) => Number(album.id) === selectedAlbumId)?.requiresAccessCode;
    }, [albums, selectedAlbumId]);

    const { loading, performFetch, response } = useGalleryApiFetch<IAlbumAuthResponse>(
        'albumauth',
        {
            defer: true,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            method: FetchMethod.POST
        }
    );

    const submitPassword = () => {
        if (selectedAlbumId) {
            performFetch({
                body: new URLSearchParams({
                    album_id: selectedAlbumId.toString(),
                    cr: '1',
                    password
                })
            });
        }
    };

    useEffect(() => {
        if (response?.errors) {
            setIsInvalid(true);
        }

        if (response?.statusText === 'success') {
            setIsInvalid(false);
            onSuccess(selectedAlbumId);
            onClose();
        }
    }, [response, onSuccess, onClose, selectedAlbumId]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalBody>
                    <Box>
                        <ModalHeader
                            modalIcon={StoreIcon}
                            headerText={t('viewStore')}
                            headerSubText={t('selectAlbumStore')}
                        />
                        <Flex
                            as="form"
                            flexDirection="column"
                            paddingTop="32px"
                            onSubmit={(event: React.FormEvent<HTMLElement>) => {
                                event.preventDefault();
                                submitPassword();
                            }}
                        >
                            <Box>
                                <FormControl isRequired>
                                    <AlbumSelect
                                        albums={albums}
                                        isDataLoading={false}
                                        selectedAlbumId={selectedAlbumId}
                                        updateSelectedAlbumId={setSelectedAlbumId}
                                    />
                                </FormControl>
                            </Box>
                            <Box>
                                {albumHasPassword && (
                                    <FormControl isRequired isInvalid={isInvalid}>
                                        <FormLabel>{t('accessCodeLabel')}</FormLabel>
                                        <Input
                                            data-testid="album-password-input"
                                            type="password"
                                            name="password"
                                            value={password}
                                            onChange={handleChangePassword}
                                        />
                                        <FormErrorMessage>{t('errorMessage')}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Box>

                            <Box
                                paddingTop="32px"
                                role="region"
                                bottom="0"
                                backgroundColor="background"
                                left="0"
                                width="100%"
                                position={ButtonPosition}
                                padding={ButtonGroupPadding}
                                paddingBottom={ButtonGroupMarginBottom}
                            >
                                <ButtonGroup spacing="16px" justifyContent="center" width="100%">
                                    <Button
                                        type="button"
                                        onClick={onCancel}
                                        width={modalButtonWidth}
                                        text={t('cancel')}
                                        variant="outline primary"
                                    />
                                    <Button
                                        type="submit"
                                        width={modalButtonWidth}
                                        isDisabled={albumHasPassword && isPasswordEmpty}
                                        text={t('view')}
                                        isLoading={loading}
                                        loadingText={t('view')}
                                        variant="primary"
                                    />
                                </ButtonGroup>
                            </Box>
                        </Flex>
                    </Box>
                </ModalBody>
                <ModalCloseButton onClick={onCancel} />
            </ModalContent>
        </Modal>
    );
};

export default memo(AlbumSelectModal);
