import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDisclosure } from '@chakra-ui/hooks';
import {
    useClientEventApiFetch,
    useEvent,
    useFetchGalleryPhotosByContext,
    useGalleryApiFetch
} from 'ts/client/common';
import {
    getCardProductsFromProductGroups,
    getPriceSheetGroupsFromPriceSheetItemsAndBoundItems,
    getSpFulfilledDigitalBoundItemsFromProductGroups
} from 'ts/client/gallery';
import type { IImage } from 'ts/client/gallery/components';
import type { IPathContext, IPriceSheetDataResponse } from 'ts/client/gallery/components/types';
import { LoadingIndicator } from 'ts/common/components';
import { EmptyState } from 'ts/common/components/gallery';
import { FetchMethod, useTranslate } from 'ts/common/hooks';
import { AlbumSelectModal, StorePages } from 'client_react/gallery/components/Store';
import { StoreProvider } from 'client_react/gallery/hooks/StoreContext';
import {
    LandingPage,
    SelectCardPage,
    SelectPackagePage,
    SelectProductOptionsPage,
    SelectProductPage
} from 'client_react/gallery/pages/store';
import AddedToCartPage from './store/AddedToCartPage';

interface Props {
    /** The path context the user had before entering store **/
    pathContextBeforeRouteChange: { context?: string; contextId?: string };
    /* True only if admin mode is enabled (which enables hiding and labeling) */
    isAdminModeEnabled: boolean;
    /** True only if the Cart nav link is shown */
    isCartVisible: boolean;
    /** Callback to open the package builder tool */
    selectPackage: (selectedPackage: SpApi.Client.IPriceSheetItem, selectedPhoto?: IImage) => void;
    /** Updates the Angular cart state */
    updateUserCartState: (numCartItems: number) => void;
    /** True only if labeling photos is allowed */
    isLabeledPhotosVisible: boolean;
    /** True if the Store should be shown */
    isStoreEnabled?: boolean;
    /** Does the user need to consent to their data being collected */
    isSubjectToGdpr: boolean;
    /** The brand currency code */
    brandCurrencyCode: string;
    /** Angular root scope */
    $rootScope: {
        navigate: (path: string, needsDigest?: boolean) => void;
    };
}

const StorePage: FC<Props> = ({
    isAdminModeEnabled,
    isCartVisible,
    isLabeledPhotosVisible,
    isStoreEnabled,
    isSubjectToGdpr,
    brandCurrencyCode,
    pathContextBeforeRouteChange: {
        context: contextBeforeRouteChange = '',
        contextId: contextIdBeforeRouteChange = ''
    },
    selectPackage,
    updateUserCartState,
    $rootScope: { navigate }
}) => {
    const t = useTranslate();
    const [authenticatedAlbums, setAuthenticatedAlbums] = useState<number[]>([]);
    const [countPhotoFetchCallsMade, setCountPhotoFetchCallsMade] = useState<number>(0);
    const [foundPhotos, setFoundPhotos] = useState<boolean>(false);
    const [loadEmptyState, setLoadEmptyState] = useState<boolean>(false);
    const [previousPageOnHistory, setPreviousPageOnHistory] = useState<Nullable<number>>();
    const didInitialPhotoFetch = useRef(false);

    const [context, setContext] = useState<IPathContext>({
        context: contextBeforeRouteChange,
        contextId: contextIdBeforeRouteChange
    });

    const isStoreAvailable = isCartVisible && isStoreEnabled;

    if (!isStoreAvailable) {
        navigate('home', false);
    }

    const { isOpen, onClose, onOpen: openAlbumSelectModal } = useDisclosure();

    const {
        fetchPhotos,
        loading: arePhotosLoading,
        fetchPhotosResponse,
        usedFilter: fetchedPhotosWithFilter
    } = useFetchGalleryPhotosByContext();

    const {
        settings: { exceedsBigEventThreshold, priceSheetShowPackagesOnly }
    } = useEvent();

    const { loading: areAlbumsLoading, response: albumsDataResponse } = useGalleryApiFetch<
        IApiListResponse<SpApi.Client.IAlbum>
    >(`getalbums`, {
        search: {
            filterUnAuthenticatableAlbums: '1'
        }
    });

    const { loading: areTagsLoading, response: tagsResponse } =
        useClientEventApiFetch<IApiListResponse<SpApi.Client.ITag>>('tag');

    const { loading: areItemsLoading, response: priceSheetItemResponse } =
        useClientEventApiFetch<IApiListResponse<SpApi.Client.IPriceSheetItem>>('price-sheet/item');

    const { loading: isPriceSheetLoading, response: priceSheetResponse } =
        useClientEventApiFetch<SpApi.Client.IPriceSheet>('price-sheet');

    const { loading: isPriceSheetDataLoading, response: pricesheetDataResponse } =
        useGalleryApiFetch<IPriceSheetDataResponse>('cart/pricesheetdata', {
            method: FetchMethod.POST
        });

    const hasAlbums = albumsDataResponse && albumsDataResponse.items.length > 0;

    const validSpFulfilledDigitalItems = getSpFulfilledDigitalBoundItemsFromProductGroups(
        pricesheetDataResponse?.groups ?? []
    ).filter((boundItem) => (hasAlbums ? true : boundItem.is_album === false));

    useEffect(() => {
        if (
            (!didInitialPhotoFetch.current && !exceedsBigEventThreshold) ||
            (!didInitialPhotoFetch.current &&
                exceedsBigEventThreshold &&
                (context.context === 'album' ||
                    context.context === 'label' ||
                    context.context === 'favorites'))
        ) {
            didInitialPhotoFetch.current = true;
            fetchPhotos(context.context, context.contextId);
        }
    }, [context, exceedsBigEventThreshold, fetchPhotos]);

    useEffect(() => {
        if (
            fetchedPhotosWithFilter &&
            fetchPhotosResponse &&
            fetchPhotosResponse.items.length === 0
        ) {
            fetchPhotos();
            setContext({ context: '', contextId: '' });
        } else if (
            //if came from a parent album that has public photos under it
            //set store context as All Available Photos and dont fetch photos again
            fetchedPhotosWithFilter &&
            fetchPhotosResponse &&
            fetchPhotosResponse.items.length > 0 &&
            context.context === 'album' &&
            albumsDataResponse &&
            !albumsDataResponse?.items.some((album) => album.id.toString() === context.contextId)
        ) {
            setContext({ context: '', contextId: '' });
        }
    }, [
        albumsDataResponse,
        context.context,
        context.contextId,
        fetchPhotos,
        fetchPhotosResponse,
        fetchedPhotosWithFilter
    ]);

    const isDataStillLoading = useMemo(() => {
        return (
            arePhotosLoading ||
            areItemsLoading ||
            areAlbumsLoading ||
            areTagsLoading ||
            isPriceSheetDataLoading ||
            isPriceSheetLoading
        );
    }, [
        arePhotosLoading,
        areItemsLoading,
        areAlbumsLoading,
        areTagsLoading,
        isPriceSheetDataLoading,
        isPriceSheetLoading
    ]);

    useEffect(() => {
        if (
            hasAlbums &&
            exceedsBigEventThreshold &&
            context.context !== 'album' &&
            context.context !== 'label' &&
            context.context !== 'favorites' &&
            !isDataStillLoading
        ) {
            openAlbumSelectModal();
        }
    }, [
        context.context,
        exceedsBigEventThreshold,
        hasAlbums,
        isDataStillLoading,
        openAlbumSelectModal
    ]);

    useEffect(() => {
        if (
            !isDataStillLoading &&
            fetchPhotosResponse?.items.length === 0 &&
            (!fetchedPhotosWithFilter || countPhotoFetchCallsMade > 1)
        ) {
            const passwordProtectedAlbums =
                albumsDataResponse?.items.filter((album) => album.requiresAccessCode) ?? [];

            if (passwordProtectedAlbums?.length > 0) {
                openAlbumSelectModal();
            } else {
                setLoadEmptyState(true);
            }
        }
    }, [
        albumsDataResponse,
        countPhotoFetchCallsMade,
        fetchPhotosResponse,
        fetchedPhotosWithFilter,
        isDataStillLoading,
        openAlbumSelectModal
    ]);

    useEffect(() => {
        if (fetchPhotosResponse) {
            setFoundPhotos(fetchPhotosResponse?.items.length > 0);
            setCountPhotoFetchCallsMade((prev) => prev + 1);
        }
    }, [fetchPhotosResponse]);

    const handleAlbumAuthSuccess = useCallback(
        (albumId: number) => {
            setAuthenticatedAlbums((prev) => [...prev, albumId]);
            setContext({ context: 'album', contextId: albumId.toString() });
            fetchPhotos('album', albumId.toString());
        },
        [fetchPhotos]
    );

    const onAlbumSelectModalCancel = useCallback(() => {
        navigate('home');
    }, [navigate]);

    const priceSheetGroups = getPriceSheetGroupsFromPriceSheetItemsAndBoundItems(
        priceSheetItemResponse?.items ?? [],
        validSpFulfilledDigitalItems,
        priceSheetShowPackagesOnly
    );

    const shouldSkipLandingPage =
        priceSheetGroups.length === 1 && previousPageOnHistory === PageIndex.LandingPage;

    if (isDataStillLoading || (!foundPhotos && !loadEmptyState)) {
        return (
            <>
                {hasAlbums && (
                    <AlbumSelectModal
                        albums={albumsDataResponse.items}
                        isOpen={isOpen}
                        onCancel={onAlbumSelectModalCancel}
                        onClose={onClose}
                        onSuccess={handleAlbumAuthSuccess}
                    />
                )}
                <LoadingIndicator squareClassName="theme-background-color-primary" />
            </>
        );
    }

    if (!foundPhotos && loadEmptyState) {
        return <EmptyState page={'Home'} translateFilter={t} />;
    }

    return (
        <StoreProvider
            albumsData={albumsDataResponse?.items ?? []}
            brandCurrencyCode={brandCurrencyCode}
            initialAuthenticatedAlbums={authenticatedAlbums}
            isAdminModeEnabled={isAdminModeEnabled}
            isLabeledPhotosVisible={isLabeledPhotosVisible}
            isSubjectToGdpr={isSubjectToGdpr}
            cardProducts={
                getCardProductsFromProductGroups(pricesheetDataResponse?.groups ?? []) ?? null
            }
            pathContextBeforeRouteChange={context}
            initialPhotos={fetchPhotosResponse?.items ?? []}
            priceSheet={priceSheetResponse}
            priceSheetGroups={priceSheetGroups}
            spFulfilledDigitalItems={validSpFulfilledDigitalItems}
            tags={tagsResponse?.items ?? []}
            updateUserCartState={updateUserCartState}
        >
            <StorePages
                initialPageName="Home"
                setPreviousPageOnHistory={setPreviousPageOnHistory}
                pages={[
                    {
                        content: <LandingPage navigate={navigate} />
                    },
                    {
                        content: <SelectProductPage />,
                        hideBackButton: shouldSkipLandingPage
                    },
                    {
                        content: <SelectProductOptionsPage />,
                        hideBackButton: shouldSkipLandingPage
                    },
                    {
                        content: (
                            <SelectPackagePage navigate={navigate} selectPackage={selectPackage} />
                        ),
                        hideBackButton: shouldSkipLandingPage
                    },
                    {
                        content: <SelectCardPage />,
                        hideBackButton: shouldSkipLandingPage
                    },
                    {
                        content: <AddedToCartPage key="added-to-cart" />,
                        hideBreadcrumbs: true
                    }
                ]}
            />
        </StoreProvider>
    );
};

export enum PageIndex {
    LandingPage = 0,
    SelectProduct = 1,
    SelectProductOptions = 2,
    SelectPackage = 3,
    SelectCard = 4,
    AddedToCart = 5
}

export default StorePage;
