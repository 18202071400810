import React, {
    PointerEvent,
    memo,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState
} from 'react';
import { Box } from '@chakra-ui/react';
import { ResizeObserver } from '@juggle/resize-observer';
import { ProductRenderSpecification, createPresenter } from '@shootproof/rendering';
import { useFrameFill } from '../product/hooks';
import { FrameProps } from '../product/types';
import { AssetCacheType, createCache } from './AssetCache';
import { drawFrame } from './Preview';
import { viewportPadding } from './constants';

export interface Position {
    x: number;
    y: number;
}

function getCoordinates(
    event: PointerEvent,
    canvas: HTMLCanvasElement,
    rotation: number,
    /** Viewport. */
    viewport: {
        x: number;
        y: number;
        width: number;
        height: number;
    }
) {
    const { left, top } = canvas.getBoundingClientRect();
    const canvasX = (event.pageX - left) * window.devicePixelRatio;
    const canvasY = (event.pageY - top) * window.devicePixelRatio;
    const scale = Math.min(
        (canvas.width - window.devicePixelRatio * (viewportPadding.left + viewportPadding.right)) /
            viewport.width,
        (canvas.height - window.devicePixelRatio * (viewportPadding.top + viewportPadding.bottom)) /
            viewport.height
    );

    const preRotationX = (canvasX - 0.5 * canvas.width) / scale;
    const preRotationY = (canvasY - 0.5 * canvas.height) / scale;

    const postRotationX =
        preRotationX * Math.cos((rotation * Math.PI) / 2) -
        preRotationY * Math.sin((rotation * Math.PI) / 2);
    const postRotationY =
        preRotationX * Math.sin((rotation * Math.PI) / 2) +
        preRotationY * Math.cos((rotation * Math.PI) / 2);

    const projectX = postRotationX + 0.5 * viewport.width;
    const projectY = postRotationY + 0.5 * viewport.height;

    return {
        x: projectX,
        y: projectY
    };
}

export interface RenderSpecification extends ProductRenderSpecification {
    contents: (ProductRenderSpecification['contents'][number] & {
        selected?: boolean;
        ghostImage?: boolean;
    })[];
}

interface IEditorCanvasProps {
    /** Optional object describing the properties of the frame for rendering. */
    frame?: FrameProps;
    /** Rendering product specification. */
    renderSpecification: RenderSpecification;
    /** Viewport. */
    viewport: {
        x: number;
        y: number;
        width: number;
        height: number;
    };
    /** True only if the crop is round */
    isRoundCrop?: boolean;
    /** Optional callback on the pointerdown event. */
    onPointerDown: (event: PointerEvent<HTMLCanvasElement>, position: Position) => void;
    /** Optional callback on the pointermove event. */
    onPointerMove: (event: PointerEvent<HTMLCanvasElement>, position: Position) => void;
    /** Optional callback on the pointerup event. */
    onPointerUp: (event: PointerEvent<HTMLCanvasElement>, position: Position) => void;
    /** Optional callback on the pointercancel event. */
    onPointerCancel: (event: PointerEvent<HTMLCanvasElement>) => void;
}

const EditorCanvas: React.FC<IEditorCanvasProps> = ({
    frame,
    renderSpecification,
    viewport,
    isRoundCrop,
    onPointerDown,
    onPointerMove,
    onPointerUp,
    onPointerCancel
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const assetCacheRef = useRef<AssetCacheType>(createCache());
    const [presenter] = useState(() => createPresenter(assetCacheRef.current));
    const [width, setWidth] = useState(100);
    const [height, setHeight] = useState(100);

    const {
        frameColor,
        frameImageUrl,
        frameOverlap,
        frameWidth,
        matColor,
        matHeight,
        matOverlap,
        matWidth
    } = frame || {};

    const frameFill = useFrameFill(frameColor, frameImageUrl);

    useEffect(() => {
        const onResize = () => {
            const container = containerRef.current;
            const canvas = canvasRef.current;

            if (container && canvas) {
                setWidth(container.clientWidth);
                setHeight(container.clientHeight);
            }
        };

        let resizeObserver: ResizeObserver | undefined;

        if (containerRef.current) {
            resizeObserver = new ResizeObserver(onResize);
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            resizeObserver?.disconnect();
        };
    }, []);

    useLayoutEffect(() => {
        const canvas = canvasRef.current;

        if (!canvas) {
            return;
        }

        const context = canvas.getContext('2d');

        if (!context) {
            return;
        }

        let animationFrameHandle = 0;

        const render = () => {
            context.save();

            context.clearRect(0, 0, canvas.width, canvas.height);

            const scale = Math.min(
                (canvas.width -
                    window.devicePixelRatio * (viewportPadding.left + viewportPadding.right)) /
                    viewport.width,
                (canvas.height -
                    window.devicePixelRatio * (viewportPadding.top + viewportPadding.bottom)) /
                    viewport.height
            );

            context.translate(0.5 * canvas.width, 0.5 * canvas.height);
            context.scale(scale, scale);

            if (renderSpecification.productMasks.rotation) {
                context.rotate((-renderSpecification.productMasks.rotation * Math.PI) / 2);
            }

            context.translate(
                -0.5 * viewport.width - viewport.x,
                -0.5 * viewport.height - viewport.y
            );

            const { loaded } = presenter.renderProductSync(canvas, {
                ...renderSpecification,
                scale
            });

            context.save();

            renderSpecification.contents.forEach((content) => {
                if (content.ghostImage) {
                    const dimensionFlipped = content.image.rotation % 2 === 1;
                    const horizontalScale = dimensionFlipped
                        ? content.height / content.image.clippingMask.width
                        : content.width / content.image.clippingMask.width;
                    const verticalScale = dimensionFlipped
                        ? content.width / content.image.clippingMask.height
                        : content.height / content.image.clippingMask.height;

                    context.save();
                    context.translate(content.x, content.y);

                    if (content.image.rotation) {
                        const centerOffsetWidth = content.width / 2;
                        const centerOffsetHeight = content.height / 2;
                        context.translate(centerOffsetWidth, centerOffsetHeight);
                        context.rotate((-content.image.rotation * Math.PI) / 2);
                        context.translate(
                            dimensionFlipped ? -centerOffsetHeight : -centerOffsetWidth,
                            dimensionFlipped ? -centerOffsetWidth : -centerOffsetHeight
                        );
                    }

                    const ghostImage = assetCacheRef.current.getImage(content.image.url);

                    // Image is loaded.
                    if (!!ghostImage && ghostImage.width > 0 && ghostImage.height > 0) {
                        context.globalAlpha = 0.45;
                        context.drawImage(
                            ghostImage,
                            0,
                            0,
                            ghostImage.width,
                            ghostImage.height,
                            -content.image.clippingMask.x * horizontalScale,
                            -content.image.clippingMask.y * verticalScale,
                            content.image.width * horizontalScale,
                            content.image.height * verticalScale
                        );
                    }

                    context.restore();
                }

                if (content.selected && (!frameWidth || content.ghostImage)) {
                    const clientScale = canvas.width / canvas.clientWidth / scale;

                    // Draw white grid over image.
                    context.beginPath();
                    context.lineWidth = clientScale;
                    context.strokeStyle = 'white';
                    context.moveTo(content.x + content.width / 3, content.y + clientScale);
                    context.lineTo(
                        content.x + content.width / 3,
                        content.y + content.height - clientScale
                    );
                    context.moveTo(content.x + (2 * content.width) / 3, content.y + clientScale);
                    context.lineTo(
                        content.x + (2 * content.width) / 3,
                        content.y + content.height - clientScale
                    );
                    context.moveTo(content.x + clientScale, content.y + content.height / 3);
                    context.lineTo(
                        content.x + content.width - clientScale,
                        content.y + content.height / 3
                    );
                    context.moveTo(content.x + clientScale, content.y + (2 * content.height) / 3);
                    context.lineTo(
                        content.x + content.width - clientScale,
                        content.y + (2 * content.height) / 3
                    );
                    context.stroke();

                    // Draw the selection outline...
                    context.lineWidth = 2 * clientScale;
                    context.strokeStyle = '#FF7D73';
                    const selectionX = content.x + clientScale;
                    const selectionY = content.y + clientScale;
                    const selectionWidth = content.width - 2 * clientScale;
                    const selectionHeight = content.height - 2 * clientScale;

                    if (isRoundCrop) {
                        // Draw an elliptical outline.
                        const radiusX = selectionWidth / 2;
                        const radiusY = selectionHeight / 2;
                        context.beginPath();
                        context.ellipse(
                            selectionX + radiusX,
                            selectionY + radiusY,
                            radiusX,
                            radiusY,
                            0,
                            0,
                            2 * Math.PI
                        );
                        context.stroke();
                    } else {
                        // Draw a rectangular outline.
                        context.strokeRect(selectionX, selectionY, selectionWidth, selectionHeight);
                    }
                }
            });

            if (frameWidth && frameFill) {
                drawFrame(
                    context,
                    frameFill,
                    frameOverlap ?? 0,
                    frameWidth,
                    renderSpecification,
                    scale,
                    matHeight || 0,
                    matOverlap || 0,
                    matWidth || 0,
                    matColor
                );
            }

            context.restore();

            context.restore();

            if (!loaded) {
                animationFrameHandle = requestAnimationFrame(render);
            }
        };

        render();

        return () => {
            cancelAnimationFrame(animationFrameHandle);
        };
    }, [
        canvasRef,
        width,
        height,
        renderSpecification,
        viewport,
        isRoundCrop,
        presenter,
        frameWidth,
        frameOverlap,
        frameFill,
        matHeight,
        matOverlap,
        matWidth,
        matColor
    ]);

    const handlePointerDown = useCallback(
        (event: PointerEvent<HTMLCanvasElement>) => {
            // For mice, application does nothing for right-clicks and other non-main, mouse clicks.
            if (event.button !== 0) {
                return;
            }

            const canvas = canvasRef.current;

            if (!canvas) {
                return;
            }

            canvas.setPointerCapture(event.pointerId);

            onPointerDown(
                event,
                getCoordinates(event, canvas, renderSpecification.productMasks.rotation, viewport)
            );
        },
        [onPointerDown, renderSpecification.productMasks.rotation, viewport]
    );

    const handlePointerMove = useCallback(
        (event: PointerEvent<HTMLCanvasElement>) => {
            if (!canvasRef.current) {
                return;
            }

            onPointerMove(
                event,
                getCoordinates(
                    event,
                    canvasRef.current,
                    renderSpecification.productMasks.rotation,
                    viewport
                )
            );
        },
        [onPointerMove, renderSpecification.productMasks.rotation, viewport]
    );

    const handlePointerUp = useCallback(
        (event: PointerEvent<HTMLCanvasElement>) => {
            // For mice, application does nothing for right-clicks and other non-main, mouse clicks.
            if (event.button !== 0 || !canvasRef.current) {
                return;
            }

            onPointerUp(
                event,
                getCoordinates(
                    event,
                    canvasRef.current,
                    renderSpecification.productMasks.rotation,
                    viewport
                )
            );
        },
        [onPointerUp, renderSpecification.productMasks.rotation, viewport]
    );

    return (
        <Box
            position="absolute"
            left="0"
            right="0"
            top="0"
            bottom="0"
            overflow="hidden"
            ref={containerRef}
        >
            <canvas
                ref={canvasRef}
                data-testid="sp-ui-editor-canvas"
                width={width * window.devicePixelRatio}
                height={height * window.devicePixelRatio}
                style={{
                    width: `${width}px`,
                    height: `${height}px`,
                    touchAction: 'none'
                }}
                onPointerDown={handlePointerDown}
                onPointerMove={handlePointerMove}
                onPointerUp={handlePointerUp}
                onPointerCancel={onPointerCancel}
            />
        </Box>
    );
};

EditorCanvas.displayName = 'EditorCanvas';

export default memo(EditorCanvas);
