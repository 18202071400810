import type { IPoint } from 'ts/common/types';

export const isHorizontalSwipeMovement = (start: IPoint, end: IPoint) => {
    return Math.abs(end.x - start.x) >= 5;
};

export const isVerticalMovement = (start: IPoint, end: IPoint) => {
    const distance = Math.sqrt(Math.pow(start.x - end.x, 2) + Math.pow(start.y - end.y, 2));
    const radians = Math.acos((start.y - end.y) / Math.abs(distance));
    const angle = 180 / (Math.PI / radians);

    return angle <= 45 || angle >= 135;
};
