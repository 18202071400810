import React, { FC } from 'react';
import { Box } from 'sp-ui';
import { IPerspectiveRenderCommonProps } from './PerspectiveRender.types';

export const AcrylicRender: FC<IPerspectiveRenderCommonProps> = ({
    isBlackAndWhite,
    photoUrl,
    frontFaceWidthPixels,
    frontFaceHeightPixels,
    topFaceHeightPixels,
    totalCropLeftPixels,
    totalCropTopPixels,
    depthPixels
}) => {
    const frontFaceLeftPixels = 1 - totalCropLeftPixels;
    const frontFaceTopPixels = 0 - totalCropTopPixels;
    const transparentBoxShadow = `0 6px 6px rgba(0, 0, 0, 1)`;
    const leftFaceWidthPixels = depthPixels * 4;
    const leftFaceBorderWidthPixels = depthPixels;
    const leftFaceHeightPixels = frontFaceHeightPixels;
    const leftFaceLeftPixels = totalCropLeftPixels;
    const leftFaceTopPixels = totalCropTopPixels;
    const leftFaceTranslateX = depthPixels * 2;
    const leftFaceBorderTranslateX = depthPixels / 2;
    const sideFaceScaleZ = 0.25;
    const rightFaceWidthPixels = depthPixels * 4;
    const rightFaceTopPixels = totalCropTopPixels;
    const rightFaceLeftPixels = frontFaceWidthPixels - rightFaceWidthPixels + totalCropLeftPixels;
    const topFaceWidthPixels = frontFaceWidthPixels;
    const topFaceLeftPixels = totalCropLeftPixels;
    const topFaceTopPixels = totalCropTopPixels;
    const bottomFaceLeftPixels = totalCropLeftPixels;
    const bottomFaceTopPixels = frontFaceHeightPixels - topFaceHeightPixels + totalCropTopPixels;

    return (
        <>
            <Box // Back Face
                position={'absolute'}
                backgroundImage={photoUrl}
                width={`${frontFaceWidthPixels}px`}
                height={`${frontFaceHeightPixels}px`}
                backgroundPosition={`${frontFaceLeftPixels}px ${frontFaceTopPixels}px`}
                transform={`translateZ(${-depthPixels / 2}px)`}
                filter={isBlackAndWhite ? ' grayscale(1)' : ''}
            />
            <Box // Right Face
                position={'absolute'}
                backgroundImage={photoUrl}
                width={`${rightFaceWidthPixels}px`}
                height={`${leftFaceHeightPixels}px`}
                backgroundPosition={`-${rightFaceLeftPixels}px -${rightFaceTopPixels}px`}
                boxSizing={'border-box'}
                borderRight={'4px solid rgba(255, 255, 255, 0.5)'}
                transform={`scaleZ(-${sideFaceScaleZ}) rotateY(-90deg) translateZ(-${
                    frontFaceWidthPixels - depthPixels * 2
                }px)`}
                filter={(isBlackAndWhite ? 'brightness(75%) grayscale(1) ' : '') + 'opacity(0.5)'}
            />
            <Box // bottom face
                position={'absolute'}
                backgroundImage={photoUrl}
                width={`${topFaceWidthPixels}px`}
                height={`${topFaceHeightPixels}px`}
                backgroundPosition={`-${bottomFaceLeftPixels}px -${bottomFaceTopPixels}px`}
                border={'2px solid rgba(255, 255, 255, 0.5)'}
                boxSizing={'border-box'}
                transform={`rotateX(270deg) translateZ(${
                    frontFaceHeightPixels - depthPixels / 2
                }px)`}
                filter={(isBlackAndWhite ? 'brightness(75%) grayscale(1) ' : '') + 'opacity(0.6)'}
            />
            <Box // bottom face border
                position={'absolute'}
                backgroundImage={photoUrl}
                width={`${topFaceWidthPixels}px`}
                height={`1px`}
                transform={`translateY(${frontFaceHeightPixels - 5}px) translateZ(${
                    depthPixels / 2
                }px)`}
                boxShadow={transparentBoxShadow}
            />
            <Box // left face border (avoids squeezing)
                position={'absolute'}
                backgroundImage={photoUrl}
                width={`${leftFaceBorderWidthPixels}px`}
                height={`1px`}
                transform={`translateY(${
                    leftFaceHeightPixels - 5
                }px) translateX(-${leftFaceBorderTranslateX}px) rotateY(-90deg)`}
                boxShadow={transparentBoxShadow}
            />
            <Box // left face whitener
                position={'absolute'}
                backgroundImage={photoUrl}
                width={`${leftFaceBorderWidthPixels}px`}
                height={`${leftFaceHeightPixels}px`}
                transform={`translateX(-${leftFaceBorderTranslateX + 0.05}px) rotateY(-90deg)`}
                backgroundColor={'rgba(255, 255, 255, 0.15)'}
            />
            <Box // left face
                position={'absolute'}
                backgroundImage={photoUrl}
                width={`${leftFaceWidthPixels}px`}
                height={`${leftFaceHeightPixels}px`}
                backgroundPosition={`-${leftFaceLeftPixels}px -${leftFaceTopPixels}px`}
                transform={`scaleZ(${sideFaceScaleZ}) translateX(-${leftFaceTranslateX}px) rotateY(-90deg)`}
                filter={(isBlackAndWhite ? 'grayscale(1) ' : '') + 'brightness(90%)'}
                borderRight={'4px solid rgba(255, 255, 255, 0.5)'}
                boxSizing={'border-box'}
            />
            <Box // top face
                position={'absolute'}
                backgroundImage={photoUrl}
                width={`${topFaceWidthPixels}px`}
                height={`${topFaceHeightPixels}px`}
                border={'2px solid rgba(255, 255, 255, 1)'}
                boxSizing={'border-box'}
                backgroundPosition={`-${topFaceLeftPixels}px -${topFaceTopPixels}px`}
                transform={`rotateX(270deg) translateZ(-${depthPixels / 2}px)`}
                filter={(isBlackAndWhite ? 'grayscale(1) ' : '') + 'brightness(150%) opacity(0.2)'}
            />
        </>
    );
};
