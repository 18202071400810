import { useCallback, useState } from 'react';
import { useGalleryApiFetch } from 'ts/client/common';

const useFetchGalleryPhotosByContext = () => {
    const {
        performFetch: getPhotos,
        loading: arePhotosLoading,
        response: getPhotosResponse
    } = useGalleryApiFetch<IApiListResponse<SpApi.Client.IEventPhoto>>(`getphotos`, {
        defer: true
    });

    const [usedFilter, setUsedFilter] = useState<boolean>(false);

    const fetchPhotos = useCallback(
        async (context?, contextId?) => {
            if (context === 'favorites') {
                setUsedFilter(true);
                getPhotos({
                    search: {
                        filterFavorites: '1'
                    }
                });
            } else if (context === 'label' && contextId) {
                setUsedFilter(true);
                getPhotos({
                    search: {
                        filterLabel: contextId
                    }
                });
            } else if (context && contextId && context === 'album' && contextId !== 'all') {
                setUsedFilter(true);
                getPhotos({
                    search: {
                        filterAlbumId: contextId
                    }
                });
            } else {
                setUsedFilter(false);
                getPhotos();
            }
        },
        [getPhotos]
    );

    return {
        fetchPhotos,
        loading: arePhotosLoading,
        fetchPhotosResponse: getPhotosResponse,
        usedFilter
    };
};

export default useFetchGalleryPhotosByContext;
