import { IFetchHookOptions, useFetch } from 'ts/common/hooks';

let authorizationHeader = '';
let baseUrl = 'https://api.shootproof.com';

export function setAuthorizationHeader(header) {
    authorizationHeader = header;
}

export function setBaseUrl(url) {
    baseUrl = url;
}

export function getAuthorizationHeader() {
    return authorizationHeader;
}

export function getBaseUrl() {
    return baseUrl;
}

/**
 * Wraps the useFetch hook; provides the Studio Panel API base URL and Authorization header
 */
export function useStudioApiFetch<TResponse>(path: string, fetchOptions?: IFetchHookOptions) {
    return useFetch<TResponse>(String(new URL(`studio/${path}`, baseUrl)), {
        ...fetchOptions,
        headers: {
            Authorization: authorizationHeader,
            ...(fetchOptions?.headers || {})
        }
    });
}
