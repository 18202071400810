import { EVENT_EVENTS } from 'Sp/Angular/Events/Client/Gallery';

export default [
    '$routeParams',
    '$scope',
    '$location',
    'spHiddenAlbum',
    function InfoController($routeParams, $scope, $location, spHiddenAlbum) {
        let offEventGot;

        $scope.introData = {};
        $scope.sections = [];

        $scope.boundItemUsedForAlbum = boundItemUsedForAlbum;
        $scope.boundItemUsedForAll = boundItemUsedForAll;
        $scope.boundItemUsedForIndividual = boundItemUsedForIndividual;
        $scope.boundUsedForAlbum = boundUsedForAlbum;
        $scope.boundUsedForAll = boundUsedForAll;
        $scope.boundUsedForIndividual = boundUsedForIndividual;
        $scope.hasSection = hasSection;
        $scope.selectSection = selectSection;

        $scope.$on('SPLocalNavigationUpdate', selectInitialSection);

        offEventGot = $scope.$on(EVENT_EVENTS.GOT, handleGotEvent);

        initialize();

        function initialize() {
            $scope.$emit(EVENT_EVENTS.GET);

            spHiddenAlbum.persistFlow();
        }

        function addContactSection() {
            if (
                $scope.brandData.contact.address ||
                $scope.brandData.contact.phone ||
                $scope.brandData.contact.email ||
                $scope.brandData.contact.website ||
                $scope.brandData.contact.facebookUrl ||
                $scope.brandData.contact.twitterUrl ||
                $scope.brandData.contact.instagramUrl ||
                $scope.brandData.contact.pinterestUrl
            ) {
                sectionFactory('contact', 'contact');
            }
        }

        function addMessageSection() {
            sectionFactory('message', 'message');
        }

        function addPricingSection() {
            if ($scope.eventData.settings.hasPriceSheet || $scope.eventData.settings.hasDiscounts) {
                sectionFactory('pricing', 'pricing');
            }
        }

        function boundItemUsedForAlbum(boundItem) {
            return boundItem.usage.album();
        }

        function boundItemUsedForAll(boundItem) {
            return boundItem.usage.all();
        }

        function boundItemUsedForIndividual(boundItem) {
            return boundItem.usage.individual();
        }

        function boundUsedForAlbum(bound) {
            return $scope.boundItemUsedForAlbum(bound.boundItems[0]);
        }

        function boundUsedForAll(bound) {
            return $scope.boundItemUsedForAll(bound.boundItems[0]);
        }

        function boundUsedForIndividual(bound) {
            return $scope.boundItemUsedForIndividual(bound.boundItems[0]);
        }

        function getSection(sectionKey) {
            const matchingSections = $scope.sections.filter(function withSection(section) {
                return section.key === sectionKey;
            });
            const section = matchingSections.shift();

            if (section === undefined) {
                return false;
            }

            return section;
        }

        function handleGotEvent(_, { headline, information, videoUrl, videoEmbedCode }) {
            addContactSection();

            if (information || videoUrl) {
                addMessageSection();
            }

            $scope.introData = {
                headline: headline,
                text: information,
                videoUrl: videoUrl,
                videoEmbedCode: videoEmbedCode
            };

            addPricingSection();
            selectInitialSection();
            offEventGot();
        }

        function hasSection(sectionKey) {
            return typeof getSection(sectionKey) === 'object';
        }

        function sectionFactory(sectionKey, sectionDescriptor) {
            $scope.sections.push(new Section(sectionKey, sectionDescriptor));

            function Section(sectionKey, sectionDescriptor) {
                this.key = sectionKey;
                this.descriptor = sectionDescriptor;
            }
        }

        function selectInitialSection() {
            let targetSectionKey = $routeParams.sectionKey;

            if (!$scope.hasSection(targetSectionKey)) {
                targetSectionKey = undefined;
            }

            if (targetSectionKey === undefined) {
                if ($scope.sections.length === 0) {
                    $location.url('');

                    return;
                }

                $scope.selectSection($scope.sections[0].key);
            } else {
                $scope.selectSection(targetSectionKey);
            }
        }

        function selectSection(sectionKey) {
            const section = getSection(sectionKey);

            if (typeof section !== 'object') {
                return;
            }

            $scope.selectedSection = section;

            $scope.spLocalNavigation(`/info/${section.key}`);
        }
    }
];
