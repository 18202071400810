import React, { FC } from 'react';
import AlbumPageTitle from 'ts/common/components/gallery/AlbumPageTitle';
import useCoverPhotoUrl from 'ts/common/hooks/useCoverPhotoUrl';
import type { ICoverPhoto } from 'ts/common/types';

interface Props {
    /** An optional number of albums to include in the caption */
    albumCount?: number;
    /** An optional cover photo with 3 possible URLs, to render in the background of the title and caption */
    coverPhoto?: ICoverPhoto;
    /** Text for a larger heading to show just above the small caption text */
    headingText: string;
    /** True only if the large-screen version should be shown. If not specified, then automatically detect based on viewport width. */
    isLargeScreen?: boolean;
    /** The number of photos to include in the caption */
    photoCount: number;
    /** The Angular translation service */
    translateFilter: SpAngularJs.ITranslateFilter;
}

/** A component that renders an AlbumPageTitle, for the Home Page which has a cover photo with 3 possible URLs based on screen size */
const HomePageTitle: FC<Props> = ({
    albumCount,
    coverPhoto,
    headingText,
    isLargeScreen,
    photoCount,
    translateFilter
}) => {
    const coverPhotoUrl = useCoverPhotoUrl(coverPhoto);

    return (
        <AlbumPageTitle
            albumCount={albumCount}
            coverPhoto={
                coverPhoto && coverPhotoUrl
                    ? {
                          ...coverPhoto,
                          url: coverPhotoUrl
                      }
                    : undefined
            }
            headingText={headingText}
            isLargeScreen={isLargeScreen}
            photoCount={photoCount}
            translateFilter={translateFilter}
        />
    );
};

export default HomePageTitle;
