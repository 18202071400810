import React, { useMemo } from 'react';
import { BoxProps, Text } from '@chakra-ui/react';
import PRICESHEET_GROUP_TYPES from 'Sp/PriceSheet/Group';
import { useClientEventApiFetch } from 'ts/client/common';
import { useScenes } from 'ts/client/common/hooks';
import { getSize } from 'ts/common';
import { ImageCarousel } from 'ts/common/components';
import { useTranslate } from 'ts/common/hooks';
import type { ICrop } from 'ts/common/types';
import Product from './Product';
import ProductRenderGrid from './ProductRenderGrid';
import { isSingleItem } from './common';
import { IImage } from './types';

interface IProductImages extends BoxProps {
    allowCropping: boolean;
    buyAllImages?: IImage[];
    crop: Nullable<ICrop>;
    image: IImage;
    onCropEdit?: () => void;
    priceSheetItem: SpApi.Client.IPriceSheetItem;
}

const ProductImages: React.FC<IProductImages> = ({
    allowCropping,
    buyAllImages,
    crop,
    image,
    onCropEdit,
    priceSheetItem,
    width = '100%',
    ...props
}) => {
    const t = useTranslate('client.gallery.addToCartSidePanel.selectProductOptionsSlide');

    const { response: priceSheet } =
        useClientEventApiFetch<SpApi.Client.IPriceSheet>('price-sheet');

    const { getAllScenesForProduct } = useScenes();

    const selectedPriceSheetItemImages = useMemo(
        () => priceSheetItem.images.map(({ displayUrl: { large } }) => large) ?? [],
        [priceSheetItem]
    );

    const images = useMemo(() => {
        const allScenes = getAllScenesForProduct(priceSheetItem, priceSheet ?? undefined);

        return [
            ...(priceSheetItem.group.groupType === PRICESHEET_GROUP_TYPES.TYPE.CANVAS ||
            priceSheetItem.group.groupType === PRICESHEET_GROUP_TYPES.TYPE.ACRYLIC_BLOCKS
                ? [
                      <Product
                          crop={crop}
                          image={image}
                          key="canvas"
                          priceSheetItem={priceSheetItem}
                      />
                  ]
                : []), // This is temporary to not show the scenes for acrylic blocks until designs are ready
            ...(priceSheetItem.group.groupType === PRICESHEET_GROUP_TYPES.TYPE.ORNAMENTS
                ? [
                      <Product
                          perspectiveRenderAllowed={false}
                          crop={crop}
                          image={image}
                          key={allScenes[0]?.backgroundUrl}
                          priceSheetItem={priceSheetItem}
                          productScene={allScenes[0]}
                      />
                  ]
                : allScenes.map((productScene) => {
                      return (
                          <Product
                              perspectiveRenderAllowed={false}
                              crop={crop}
                              image={image}
                              key={productScene.backgroundUrl}
                              priceSheetItem={priceSheetItem}
                              productScene={productScene}
                              renderCustomItem
                          />
                      );
                  })),
            ...selectedPriceSheetItemImages
        ];
    }, [
        image,
        crop,
        priceSheet,
        priceSheetItem,
        selectedPriceSheetItemImages,
        getAllScenesForProduct
    ]);

    return buyAllImages && buyAllImages.length > 0 ? (
        <ProductRenderGrid
            selectedPriceSheetItem={priceSheetItem}
            images={buyAllImages}
            position="relative"
            width={width}
            padding="30px"
            fontSize="24px"
            {...props}
        />
    ) : (
        <ImageCarousel
            belowActiveImageContent={
                allowCropping &&
                isSingleItem(priceSheetItem) &&
                getSize(priceSheetItem.size) &&
                onCropEdit ? (
                    <Text cursor="pointer" onClick={onCropEdit}>
                        {t('editCropAction')}
                    </Text>
                ) : null
            }
            images={images}
            position="relative"
            width={width}
            {...props}
        />
    );
};

export default ProductImages;
