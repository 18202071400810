import { useMemo } from 'react';
import type { ICoverPhoto } from 'ts/common/types';

/** Attempt to return the most relevant url available based on screen size */
function useCoverPhotoUrl(coverPhoto?: ICoverPhoto | false) {
    return useMemo(() => {
        if (!coverPhoto) {
            return null;
        }

        // Prefer the largest cover size for all screen sizes/densities to improve cover quality
        // For more details check issue FOR-9211
        return coverPhoto.urlLarge || coverPhoto.urlMedium || coverPhoto.urlSmall;
    }, [coverPhoto]);
}

export default useCoverPhotoUrl;
