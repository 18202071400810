import React, { FC, useMemo } from 'react';
import { Flex, useBreakpointValue } from '@chakra-ui/react';
import { AddedToCartBanner, IPhotoConfig, ProductDetails } from 'ts/client/gallery/components';
import { IBoundItem } from 'ts/client/gallery/components/types';
import { Button } from 'ts/common/components/gallery';
import { useTranslate } from 'ts/common/hooks';

interface IAddedToCartProps {
    eventId: string;
    onKeepShopping: () => void;
    userSelectedProducts: IPhotoConfig[];
    spFulfilledDigitalItems: IBoundItem[];
}

const AddedToCart: FC<IAddedToCartProps> = ({
    eventId,
    onKeepShopping,
    spFulfilledDigitalItems,
    userSelectedProducts
}) => {
    const isMediumScreenOrLarger = useBreakpointValue({ base: false, md: true });
    const t = useTranslate('client.store.selectPhotosModal');

    const quantity = useMemo(() => {
        return userSelectedProducts.reduce((accumulatedQuantity, userSelectedProduct) => {
            return accumulatedQuantity + userSelectedProduct.quantity;
        }, 0);
    }, [userSelectedProducts]);

    return (
        <Flex
            flexDirection="column"
            gap="10px"
            marginLeft="auto"
            marginRight="auto"
            width={isMediumScreenOrLarger ? '518px' : '100%'}
        >
            <AddedToCartBanner quantity={quantity} />
            {userSelectedProducts.map((userSelectedProduct, index) => {
                const digitalItem = spFulfilledDigitalItems?.find(
                    (digitalItem) =>
                        digitalItem.id === String(userSelectedProduct.priceSheetItem.id)
                );
                return (
                    <ProductDetails
                        digitalOrderPrice={Number(digitalItem?.price)}
                        key={`${userSelectedProduct.image.id}-${userSelectedProduct.priceSheetItem.id}-${index}`}
                        crop={userSelectedProduct.cropData}
                        image={userSelectedProduct.image}
                        item={userSelectedProduct.priceSheetItem}
                        quantity={userSelectedProduct.quantity}
                        renderWidth="108px"
                    />
                );
            })}
            <Flex
                flexDirection={isMediumScreenOrLarger ? 'row' : 'column'}
                gap="20px"
                marginTop="25px"
            >
                <Button
                    data-testid="keep-shopping-button"
                    fullWidth
                    borderColor="brand.primary"
                    color="brand.primary"
                    onClick={onKeepShopping}
                    order={isMediumScreenOrLarger ? 1 : 2}
                    text={t('keepShopping')}
                    variant="outline primary"
                />
                <Button
                    data-testid="view-cart-button"
                    fullWidth
                    onClick={() => {
                        window.location.replace(
                            new URL(`/gallery/${eventId}/cart`, window.location.origin).toString()
                        );
                    }}
                    order={isMediumScreenOrLarger ? 2 : 1}
                    text={t('viewCart')}
                    variant="primary"
                />
            </Flex>
        </Flex>
    );
};

export default AddedToCart;
