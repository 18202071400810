import React, { FC, useEffect, useState } from 'react';
import { Flex, Heading, Text, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { SmallScreenWarning } from 'ts/client/gallery/components';
import CardImage from 'ts/client/gallery/components/add-to-cart-side-panel/CardImage';
import { IBound, IBoundItem } from 'ts/client/gallery/components/types';
import { useCurrency } from 'ts/client/gallery/hooks';
import { ExpandableHtml } from 'ts/common/components';
import { GalleryHeading } from 'ts/common/components/gallery';
import { useTranslate } from 'ts/common/hooks';
import SelectCardModal from 'client_react/gallery/components/Store/SelectCardModal';
import { useStore } from 'client_react/gallery/hooks/StoreContext';

const CardDescription = styled(Text)`
    p {
        color: inherit;
        font-size: inherit;
        line-height: inherit;
    }
`;

const SelectCardPage: FC = () => {
    const $ = useCurrency();
    const t = useTranslate('client.store.selectCardPage');
    const isMediumScreenOrLarger = useBreakpointValue({ base: false, md: true });
    const { cardProducts, selectedCardCategory, setSelectedCardCategory } = useStore();
    const [boundsRandomImages, setBoundsRandomImages] = useState<string[]>();
    const [isSmallScreenWarningOpen, setIsSmallScreenWarningOpen] = useState(false);

    useEffect(() => {
        if (cardProducts) {
            const boundsRandomImages = cardProducts?.bounds.reduce<string[]>(
                (boundsRandomImages, bound) => {
                    boundsRandomImages.push(
                        bound.items[Math.floor(Math.random() * bound.items.length)]
                            .description_images[0].t
                    );
                    return boundsRandomImages;
                },
                []
            );
            setBoundsRandomImages(boundsRandomImages);
        }
    }, [cardProducts]);

    const getLowestCardCategoryPrice = (cardItems: IBoundItem[]) => {
        const cardItemWithLowestPrice = cardItems.reduce((cardItem, nextCardItem) =>
            parseFloat(cardItem.price) < parseFloat(nextCardItem.price) ? cardItem : nextCardItem
        );
        return parseFloat(cardItemWithLowestPrice.price);
    };

    const { isOpen, onClose, onOpen: openCardCategoryModal } = useDisclosure();

    const gap = 20;
    const responsiveColumnGap = useBreakpointValue({ base: gap / 2, lg: gap }) ?? 20;
    const cardTileMaxWidth = useBreakpointValue({
        base: `calc(50% - ${responsiveColumnGap / 2}px)`,
        lg: `calc(33% - ${gap / 2 + 1}px)`
    });

    const onCardCategorySelected = (cardCategory: IBound) => {
        setSelectedCardCategory(cardCategory);
        openCardCategoryModal();
    };

    return (
        <>
            <SelectCardModal isOpen={isOpen} onClose={onClose} />
            <GalleryHeading
                marginBottom={cardProducts?.description ? '16px' : '24px'}
                size="md"
                text={cardProducts?.name ? cardProducts?.name : ''}
                variant="primary"
                data-testid="select-card-heading"
            />
            {cardProducts?.description && cardProducts?.description !== '' && (
                <CardDescription as="span" fontSize="md" data-testid="select-card-description">
                    <ExpandableHtml html={cardProducts.description} maxLineCount={4} />
                </CardDescription>
            )}
            <Flex
                flexWrap="wrap"
                columnGap={`${responsiveColumnGap}px`}
                rowGap="20px"
                marginBottom="24px"
            >
                {cardProducts?.bounds.map((bound, index) => (
                    <Flex
                        key={index}
                        flexDirection="column"
                        alignItems="flex-start"
                        width={'100%'}
                        maxWidth={cardTileMaxWidth}
                    >
                        <CardImage
                            frontImageUrl={boundsRandomImages ? boundsRandomImages[index] : ''}
                            onClick={() => {
                                setSelectedCardCategory(bound);
                                isMediumScreenOrLarger
                                    ? onCardCategorySelected(bound)
                                    : setIsSmallScreenWarningOpen(true);
                            }}
                        />
                        <Heading
                            color="brand.primary"
                            data-testid="card-category-heading"
                            fontFamily="open-sans, sans-serif"
                            marginBottom="3px"
                            marginTop="12px"
                            size="lg"
                            textTransform="capitalize"
                        >
                            {bound.bounds_name}
                        </Heading>
                        <Text
                            color="text"
                            fontFamily="open-sans, sans-serif"
                            data-testid="card-category-price"
                        >
                            {t('from', {
                                lowestCardItemPrice: `${$(
                                    getLowestCardCategoryPrice(bound.items) * 25
                                )}`
                            })}
                        </Text>
                    </Flex>
                ))}
            </Flex>
            {!isMediumScreenOrLarger && (
                <SmallScreenWarning
                    actionButtonOnClick={() => {
                        setIsSmallScreenWarningOpen(false);

                        if (selectedCardCategory) {
                            onCardCategorySelected(selectedCardCategory);
                        }
                    }}
                    onCloseClick={() => {
                        setIsSmallScreenWarningOpen(false);
                    }}
                    actionButtonTranslateKey="previewDesign"
                    headingTranslateKey="designOnLaptop"
                    showCancelButton
                    subtextTranslateKey="cardEditorNotAvailable"
                    isVisible={isSmallScreenWarningOpen}
                    marginBottom="62px"
                />
            )}
        </>
    );
};

export default SelectCardPage;
