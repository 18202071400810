import React, { FC } from 'react';
import { Box, Heading, Text, useBreakpointValue } from '@chakra-ui/react';
import ReactPlayer from 'react-player';

export interface IStudioMessageProps {
    /** Message header text */
    headerText: string;
    /** URL to video */
    videoUrl?: string;
    /** Message body text */
    bodyText?: string;
    /** Is the message mounted in a modal */
    isInModal?: boolean;
}

const StudioMessage: FC<IStudioMessageProps> = ({
    headerText,
    videoUrl,
    bodyText,
    isInModal = false
}) => {
    const maxHeight = useBreakpointValue({ base: 'none', md: '390px' });

    return (
        <Box>
            <Heading as="h2" textAlign="center" size="lg" variant="primary" paddingBottom="32px">
                {headerText}
            </Heading>
            {videoUrl && (
                <Box role="presentation" display="flex" justifyContent="center" alignItems="center">
                    <ReactPlayer url={videoUrl} height="308px" width="480px" controls />
                </Box>
            )}
            {!videoUrl && bodyText && (
                <Text
                    maxHeight={isInModal ? maxHeight : 'none'}
                    overflow="auto"
                    css={{ '&::-webkit-scrollbar': { display: 'none' } }}
                >
                    <span dangerouslySetInnerHTML={{ __html: bodyText }} />
                </Text>
            )}
        </Box>
    );
};

export default StudioMessage;
