import React, { FC } from 'react';
import { Box, Divider, Flex, useBreakpointValue } from '@chakra-ui/react';
import Button from 'ts/common/components/gallery/Button';
import CoverPhoto from 'ts/common/components/gallery/CoverPhoto';
import GalleryHeading from 'ts/common/components/gallery/GalleryHeading';
import StudioHeading from 'ts/common/components/gallery/StudioHeading';
import StudioLogo from '../components/StudioLogo';
import { ILayoutProps, contentAnimation, coverAnimation } from './BaseLayout';

const DetailLayout: FC<ILayoutProps> = ({
    galleryTitle,
    brandName,
    coverPhoto,
    coverPhotoUrl,
    eventBrandLogo,
    logoAltText,
    buttonText,
    buttonTextLarge,
    colorScheme,
    onClick
}) => {
    const galleryHeadingSize =
        useBreakpointValue({ base: 'sm' as const, lg: 'md' as const }) ?? 'sm';
    const showLogo = useBreakpointValue({ base: false, lg: true }, 'lg') && eventBrandLogo;

    return (
        <Box position="absolute" width="full" height="full" onClick={onClick}>
            <Flex
                position="absolute"
                height={useBreakpointValue({ base: 'auto', lg: 'full' })}
                width={useBreakpointValue({ base: 'full', lg: '24.5rem' })}
                top={useBreakpointValue({ base: 'initial', lg: 0 })}
                bottom={useBreakpointValue({ base: 0, lg: 'initial' })}
                left={0}
                flexDirection="column"
                justifyContent="center"
                paddingX={8}
                paddingY={6}
                background={colorScheme === 'dark' ? 'blackAlpha.800' : 'whiteAlpha.800'}
                animation={contentAnimation}
                opacity={0}
            >
                <Flex position="absolute" top={8} left={0} width="full" justifyContent="center">
                    {showLogo ? (
                        <StudioLogo logo={eventBrandLogo} logoAltText={logoAltText} logoSize="md" />
                    ) : null}
                </Flex>
                <Flex flexDirection="column" alignItems="stretch" gap={8} zIndex={1}>
                    <Flex flexDirection="column" alignItems="center" textAlign="center" gap={4}>
                        <GalleryHeading
                            size={galleryHeadingSize}
                            text={galleryTitle}
                            variant={colorScheme === 'dark' ? 'light' : 'dark'}
                        />
                        {showLogo ? null : (
                            <>
                                <Divider
                                    variant={colorScheme === 'dark' ? 'light' : 'dark'}
                                    margin={0}
                                />
                                <StudioHeading
                                    text={brandName}
                                    variant={colorScheme === 'dark' ? 'light' : 'dark'}
                                />
                            </>
                        )}
                    </Flex>
                    <Box alignSelf={useBreakpointValue({ base: 'stretch', lg: 'center' })}>
                        <Button
                            paddingX={12}
                            text={buttonText}
                            textLarge={buttonTextLarge}
                            variant={
                                `secondary ${colorScheme === 'dark' ? 'light' : 'dark'}` as const
                            }
                            fullWidth={useBreakpointValue({ base: true, lg: false })}
                        />
                    </Box>
                </Flex>
            </Flex>

            <Box width="full" height="full" animation={coverAnimation}>
                <CoverPhoto coverPhoto={coverPhoto} coverPhotoUrl={coverPhotoUrl} />
            </Box>
        </Box>
    );
};

export default DetailLayout;
