import React, { useEffect, useState } from 'react';
import { Box, Flex, Heading, Text, useBreakpointValue } from '@chakra-ui/react';
import { styled } from 'sp-ui';
import { useGalleryApiFetch } from 'ts/client/common';
import { ExpandableHtml } from 'ts/common/components';
import { LoadingIndicator } from 'ts/common/components';
import { GalleryHeading } from 'ts/common/components/gallery';
import { FetchMethod, useTranslate } from 'ts/common/hooks';
import { useCurrency } from '../../hooks';
import SmallScreenWarning from '../SmallScreenWarning';
import { GroupType, IBound, IBoundItem, IPriceSheetDataResponse, IProductGroup } from '../types';
import CardImage from './CardImage';

const CardDescription = styled(Text)`
    p {
        color: inherit;
        font-size: inherit;
        line-height: inherit;
    }
`;
interface ISelectCardSlideProps {
    onCardCategorySelected: (cardCategory: IBound) => void;
}

const SelectCardSlide: React.FC<ISelectCardSlideProps> = ({ onCardCategorySelected }) => {
    const $ = useCurrency();
    const t = useTranslate('client.gallery.addToCartSidePanel.selectCardSlide');
    const isMediumScreenOrLarger = useBreakpointValue({ base: false, md: true });
    const [selectedCardCategory, setSelectedCardCategory] = useState<IBound>();
    const [isSmallScreenWarningOpen, setIsSmallScreenWarningOpen] = useState(false);
    const [cardProducts, setCardProducts] = useState<IProductGroup>();
    const [boundsRandomImages, setBoundsRandomImages] = useState<string[]>();

    const { loading, response: pricesheetDataResponse } =
        useGalleryApiFetch<IPriceSheetDataResponse>('cart/pricesheetdata', {
            method: FetchMethod.POST
        });

    useEffect(() => {
        if (pricesheetDataResponse && (!cardProducts || !boundsRandomImages)) {
            const cardProducts = pricesheetDataResponse?.groups.find(
                (group) => group.type === GroupType.CARDS
            );
            const boundsRandomImages = cardProducts?.bounds.reduce<string[]>(
                (boundsRandomImages, bound) => {
                    boundsRandomImages.push(
                        bound.items[Math.floor(Math.random() * bound.items.length)]
                            .description_images[0].t
                    );
                    return boundsRandomImages;
                },
                []
            );
            setBoundsRandomImages(boundsRandomImages);
            setCardProducts(cardProducts);
        }
    }, [pricesheetDataResponse, cardProducts, boundsRandomImages]);

    const getLowestCardCategoryPrice = (cardItems: IBoundItem[]) => {
        const cardItemWithLowestPrice = cardItems.reduce((cardItem, nextCardItem) =>
            parseFloat(cardItem.price) < parseFloat(nextCardItem.price) ? cardItem : nextCardItem
        );
        return parseFloat(cardItemWithLowestPrice.price);
    };

    const gap = 16;

    return (
        <Box padding="48px 20px">
            <GalleryHeading
                marginBottom={cardProducts?.description ? '16px' : ''}
                size="md"
                text={cardProducts?.name ? cardProducts?.name : ''}
                variant="primary"
                data-testid="select-card-heading"
            />
            {loading ? (
                <LoadingIndicator squareClassName="theme-background-color-primary" />
            ) : (
                <>
                    {cardProducts?.description && cardProducts?.description !== '' && (
                        <CardDescription
                            as="span"
                            fontSize="md"
                            data-testid="select-card-description"
                        >
                            <ExpandableHtml html={cardProducts.description} maxLineCount={4} />
                        </CardDescription>
                    )}
                    <Flex flexWrap="wrap" gap={`${gap}px`} margin="24px 0">
                        {cardProducts?.bounds.map((bound, index) => (
                            <Flex
                                key={index}
                                flexDirection="column"
                                alignItems="flex-start"
                                width={'100%'}
                                maxWidth={`calc(50% - ${gap / 2}px)`}
                            >
                                <CardImage
                                    frontImageUrl={
                                        boundsRandomImages ? boundsRandomImages[index] : ''
                                    }
                                    onClick={() => {
                                        setSelectedCardCategory(bound);
                                        isMediumScreenOrLarger
                                            ? onCardCategorySelected(bound)
                                            : setIsSmallScreenWarningOpen(true);
                                    }}
                                />
                                <Heading
                                    color="brand.primary"
                                    data-testid="card-category-heading"
                                    fontFamily="open-sans, sans-serif"
                                    marginBottom="3px"
                                    marginTop="12px"
                                    size="lg"
                                    textTransform="capitalize"
                                >
                                    {bound.bounds_name}
                                </Heading>
                                <Text
                                    color="text"
                                    fontFamily="open-sans, sans-serif"
                                    data-testid="card-category-price"
                                >
                                    {t('from', {
                                        lowestCardItemPrice: `${$(
                                            getLowestCardCategoryPrice(bound.items) * 25
                                        )}`
                                    })}
                                </Text>
                            </Flex>
                        ))}
                    </Flex>
                </>
            )}

            <SmallScreenWarning
                actionButtonOnClick={() => {
                    setIsSmallScreenWarningOpen(false);

                    if (selectedCardCategory) {
                        onCardCategorySelected(selectedCardCategory);
                    }
                }}
                onCloseClick={() => setIsSmallScreenWarningOpen(false)}
                actionButtonTranslateKey="previewDesign"
                headingTranslateKey="designOnLaptop"
                showCancelButton
                subtextTranslateKey="cardEditorNotAvailable"
                isVisible={isSmallScreenWarningOpen}
            />
        </Box>
    );
};

export default SelectCardSlide;
