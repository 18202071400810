import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { Tag } from 'react-feather';
import Button from 'ts/common/components/gallery/Button';
import ModalHeader from 'ts/common/components/gallery/ModalHeader';

export interface ILabelModalHeader {
    onNavigate: () => void;
    translateFilter: SpAngularJs.ITranslateFilter;
}

const LabelModalHeader: FC<ILabelModalHeader> = ({ onNavigate, translateFilter: t }) => {
    return (
        <Box>
            <ModalHeader
                modalIcon={Tag}
                headerText={t('labelPhoto')}
                headerSubText={t('labelsDescription')}
            />
            <Button
                type="button"
                variant="text"
                text={t('viewLabeledPhotos')}
                onClick={onNavigate}
            />
        </Box>
    );
};

export default LabelModalHeader;
