import React, { FC, useEffect, useLayoutEffect, useRef } from 'react';
import { Flex, Heading, Text, useBreakpointValue } from '@chakra-ui/react';
import { trackClientEventFromPriceSheetItems, useEvent } from 'ts/client/common';
import CardImage from 'ts/client/gallery/components/add-to-cart-side-panel/CardImage';
import { IBoundItem } from 'ts/client/gallery/components/types';
import { GalleryHeading } from 'ts/common/components/gallery';
import { useTranslate } from 'ts/common/hooks';
import { useStore } from 'client_react/gallery/hooks/StoreContext';
import { SlideIndex } from '../SelectCardModal';

interface SelectCardTemplateSlideProps {
    goToCardTemplatePreviewSlide: (index: number) => void;
    previouslySelectedCardProduct?: IBoundItem;
    setPreviouslySelectedCardProduct: (cardProduct: IBoundItem) => void;
}

const SelectCardTemplateSlide: FC<SelectCardTemplateSlideProps> = ({
    goToCardTemplatePreviewSlide,
    previouslySelectedCardProduct,
    setPreviouslySelectedCardProduct
}) => {
    const {
        brandCurrencyCode,
        selectedCardCategory,
        selectedPriceSheetGroup,
        setSelectedCardProduct
    } = useStore();
    const previousSelectedProductRef = useRef<HTMLDivElement>(null);
    const isMediumScreenOrLarger = useBreakpointValue({ base: false, md: true });

    useLayoutEffect(() => {
        setTimeout(() => {
            if (previousSelectedProductRef.current) {
                previousSelectedProductRef.current.scrollIntoView();
            }
        }, 50);
    }, [previousSelectedProductRef]);

    useEffect(() => {
        setSelectedCardProduct(null);
    }, [setSelectedCardProduct]);

    const t = useTranslate('client.store.selectCardTemplateSlide');
    const { id: eventId } = useEvent();

    const onCardProductSelected = (cardProduct: IBoundItem) => {
        const cardPricesheetItems = selectedPriceSheetGroup?.items ?? [];
        const cardPricesheetItem = cardPricesheetItems?.find(
            (item) => item.id === cardProduct?.priceSheetItemId
        );

        trackClientEventFromPriceSheetItems({
            eventSlug: 'view_item',
            eventId,
            priceSheetItems: cardPricesheetItem ? [cardPricesheetItem] : undefined,
            orderPrice: cardProduct?.whcc_id
                ? parseFloat(cardProduct?.price) * 25
                : parseFloat(cardProduct?.price),
            quantity: cardProduct?.whcc_id ? 25 : 1,
            currencyCode: brandCurrencyCode
        });
        setSelectedCardProduct(cardProduct);
        setPreviouslySelectedCardProduct(cardProduct);
        goToCardTemplatePreviewSlide(SlideIndex.PreviewCardTemplate);
    };

    const gap = 16;

    return (
        <Flex flexDirection="column" gap="24px" textAlign="center" marginTop="35px">
            <GalleryHeading
                size="sm"
                text={t('heading')}
                variant="primary"
                data-testid="select-card-template-heading"
            />
            <Text data-testid="select-card-template-description">{t('description')}</Text>
            <Flex flexWrap="wrap" gap={`${gap}px`} justifyContent="center">
                {selectedCardCategory?.items.map((cardItem) => (
                    <Flex
                        alignItems="center"
                        flexDirection="column"
                        gap="5px"
                        key={cardItem.whcc_id}
                        width={isMediumScreenOrLarger ? `calc(50% - ${gap / 2}px)` : '100%'}
                        maxWidth={isMediumScreenOrLarger ? '285px' : '100%'}
                        ref={
                            previouslySelectedCardProduct &&
                            previouslySelectedCardProduct.whcc_id === cardItem.whcc_id
                                ? previousSelectedProductRef
                                : undefined
                        }
                    >
                        <CardImage
                            frontImageUrl={cardItem.description_images[0].t}
                            backImageUrl={cardItem.description_images[1].t}
                            onClick={() => onCardProductSelected(cardItem)}
                        />

                        <Heading
                            color="brand.primary"
                            data-testid="card-template-heading"
                            size="md"
                            textTransform="capitalize"
                        >
                            {cardItem.name}
                        </Heading>
                    </Flex>
                ))}
            </Flex>
        </Flex>
    );
};

export default SelectCardTemplateSlide;
