import { IFetchHookOptions, useFetch } from 'ts/common/hooks';
import useEvent from './useEvent';

const useGalleryApiFetch = <TResponse>(path: string, fetchOptions?: IFetchHookOptions) => {
    const { id: eventId } = useEvent();
    return useFetch<TResponse>(
        new URL(`/gallery/${eventId}/${path}`, window.location.origin).toString(),
        fetchOptions
    );
};

export default useGalleryApiFetch;
