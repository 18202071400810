import React, { FC } from 'react';
import { DrawerProps, Flex } from '@chakra-ui/react';
import Drawer from 'ts/common/components/gallery/Drawer';
import type { IContextOptionButton } from 'ts/common/types';
import { ContextSelectButton } from 'client_react/gallery/components/Store/select-photos-modal';

interface Props extends Omit<DrawerProps, 'children'> {
    /** Actions to show in this drawer */
    actions: IContextOptionButton[];
    /** Returns whether the action button corresponds to the selected context or not */
    getIsSelectedContext: (action: IContextOptionButton) => boolean;
}

const ContextSelectDrawer: FC<Props> = ({ actions, getIsSelectedContext, ...rest }) => {
    return (
        <Drawer {...rest}>
            <Flex flexFlow="column nowrap">
                {actions.map((action) => (
                    <ContextSelectButton
                        key={action.key}
                        action={action}
                        color={getIsSelectedContext(action) ? 'brand.primary' : 'currentcolor'}
                        onClick={() => {
                            // Perform the action.
                            action.onClick();
                            // Close the modal
                            rest.onClose();
                        }}
                    />
                ))}
            </Flex>
        </Drawer>
    );
};

export default ContextSelectDrawer;
