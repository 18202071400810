import React, { useMemo } from 'react';
import { useToast as useChakraToast } from '@chakra-ui/toast';
import { EyeOff, Heart, Link as LinkIcon, Send } from 'react-feather';
import Toast from 'ts/common/components/Toast';
import { TagEnabledIcon } from 'ts/common/components/icons';

export const ALL_TOAST_TYPES = ['favorite', 'favorites_sent', 'label', 'hide', 'copyLink'] as const;

export interface UseToastInput {
    type: typeof ALL_TOAST_TYPES[number];
    translateFilter: SpAngularJs.ITranslateFilter;
}

const useToast = ({ type, translateFilter: translate }: UseToastInput) => {
    const chakraToast = useChakraToast();

    const toastProps = useMemo(() => {
        switch (type) {
            case 'favorite':
                return {
                    icon: Heart,
                    iconFill: 'brand.primary',
                    title: translate('photoAddedToFavorites'),
                    description: translate('photoAddedToFavoritesDescription')
                };
            case 'favorites_sent':
                return {
                    icon: Send,
                    iconFill: 'brand.primary',
                    title: translate('favoritePhotosSent'),
                    description: translate('favoritePhotosSentDescription')
                };
            case 'label':
                return {
                    icon: TagEnabledIcon,
                    title: translate('photoLabeled'),
                    description: translate('photoLabeledDescription')
                };
            case 'hide':
                return {
                    icon: EyeOff,
                    title: translate('photoHidden'),
                    description: translate('photoHiddenDescription')
                };
            case 'copyLink':
                return {
                    icon: LinkIcon,
                    title: translate('copiedLink')
                };
            default:
                throw new Error(`Invalid ToastType: ${type}`);
        }
    }, [translate, type]);

    const toast = () => {
        const toastId = chakraToast({
            position: 'bottom',
            duration: 5000,
            render: () => (
                <Toast
                    onClose={() => {
                        if (toastId) {
                            chakraToast.close(toastId);
                        }
                    }}
                    {...toastProps}
                />
            )
        });

        return toastId;
    };

    return toast;
};

export default useToast;
