import React, { FC, useMemo } from 'react';
import { Box, styled } from 'sp-ui';
import { useElementDimensions } from '../hooks';
import { AcrylicRender } from './AcrylicRender';
import { CanvasRender } from './CanvasRender';
import { Exhibition, IPerspectiveViewConfig } from './PerspectiveRender.types';
import {
    calculateSurfaceValues,
    getPhotoDimensions,
    isCropRotated
} from './PerspectiveRender.utils';

interface IRenderSurfaceBoardProps {
    scaleFactor?: string | number | undefined;
    width?: string | number | undefined;
}

const doNotForward = (notForwarded: string[]) => ({
    shouldForwardProp: (prop: string) => !notForwarded.includes(prop)
});
const Container = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
`;
const RenderSurfaceBoard = styled(
    Box,
    doNotForward(['scaleFactor', 'width'])
)(({ scaleFactor, width }: IRenderSurfaceBoardProps) => {
    return `
        position: relative;
        transform-style: preserve-3d;
        transform: scale(${scaleFactor}) translateZ(-${width}) rotate3d(-3, 6, -1, 35deg);
        flex: 0 0 ${width};
    `;
});

export interface IPerspectiveRenderProps extends IPerspectiveViewConfig {
    exhibition: Exhibition;
    perspectiveBackdrop?: string;
}

export const PerspectiveRender: FC<IPerspectiveRenderProps> = ({
    isBlackAndWhite = false,
    isAcrylicBlock = false,
    perspectiveBackdrop,
    photo,
    photoUrl,
    crop,
    exhibition
}) => {
    // this is needed while this component is called
    // from old angular code
    crop.bounds.useCanvasCrop = !isAcrylicBlock;
    const { dimensions, ref } = useElementDimensions();

    const surfaceValues = useMemo(() => {
        const photoDimensions = getPhotoDimensions(photo, 'l', true);
        const isRotated = isCropRotated(photo, crop);

        const width = dimensions?.width ?? 0;
        const height = dimensions?.height ?? 0;

        return calculateSurfaceValues({
            crop: {
                ...crop,
                isRotated
            },
            exhibition,
            containerDimensions: {
                width,
                height,
                aspectRatio: height === 0 ? 0 : width / height
            },
            photoDimensions
        });
    }, [dimensions, crop, exhibition, photo]);

    if (!surfaceValues) {
        return null;
    }

    const { boundsPixels, perspectiveRenderingDetails, scaleFactors } = surfaceValues;
    const frontFaceWidthPixels = boundsPixels.cropWidthPixels;
    const frontFaceHeightPixels = boundsPixels.cropHeightPixels;
    const topFaceHeightPixels = boundsPixels.depthPixels;

    return (
        <Container
            {...(perspectiveBackdrop && {
                backgroundImage: perspectiveBackdrop,
                backgroundPosition: 'center',
                backgroundSize: 'cover'
            })}
            className="perspective-render-container"
            ref={ref}
            height="100%"
            width="100%"
        >
            <RenderSurfaceBoard
                width={`${frontFaceWidthPixels}px`}
                height={`${frontFaceHeightPixels}px`}
                scaleFactor={scaleFactors.perspectiveScaleFactor}
                className="photo-perspective-rendering photo-canvas-perspective-rendering"
            >
                {isAcrylicBlock ? (
                    <AcrylicRender
                        isBlackAndWhite={isBlackAndWhite}
                        photoUrl={photoUrl}
                        frontFaceWidthPixels={frontFaceWidthPixels}
                        frontFaceHeightPixels={frontFaceHeightPixels}
                        topFaceHeightPixels={topFaceHeightPixels}
                        totalCropLeftPixels={boundsPixels.totalCropLeftPixels}
                        totalCropTopPixels={boundsPixels.totalCropTopPixels}
                        depthPixels={boundsPixels.depthPixels}
                    ></AcrylicRender>
                ) : (
                    <CanvasRender
                        isBlackAndWhite={isBlackAndWhite}
                        photoUrl={photoUrl}
                        frontFaceWidthPixels={frontFaceWidthPixels}
                        frontFaceHeightPixels={frontFaceHeightPixels}
                        topFaceHeightPixels={topFaceHeightPixels}
                        boxShadowOffset={perspectiveRenderingDetails.boxShadowOffset}
                        totalCropLeftPixels={boundsPixels.totalCropLeftPixels}
                        totalCropTopPixels={boundsPixels.totalCropTopPixels}
                        depthPixels={boundsPixels.depthPixels}
                        bleedPixels={boundsPixels.bleedPixels}
                        cropWidthPixels={boundsPixels.cropWidthPixels}
                        cropHeightPixels={boundsPixels.cropHeightPixels}
                    ></CanvasRender>
                )}
            </RenderSurfaceBoard>
        </Container>
    );
};
