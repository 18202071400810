import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { styled } from 'sp-ui';
import type { IContextOptionButton } from 'ts/common/types';
import { ContextSelectButton } from 'client_react/gallery/components/Store/select-photos-modal';

const ContextSelectDropdownWrapper = styled(Flex, {
    shouldForwardProp: (prop) => prop !== 'isOpen'
})<{ isOpen: boolean }>(
    ({ theme, isOpen }) => `
        display: grid;
        position: absolute;
        grid-template-rows: ${isOpen ? '1fr' : '0fr'};
        transition: grid-template-rows 0.25s;
        background: ${theme.colors.modalBackground};
        border-radius: 0 0 4px 4px;
        box-shadow: 0px 4px 8px 0px var(--chakra-colors-boxShadow);
        z-index: 10;
        min-width: 260px;
    `
);

interface Props {
    /** Actions to show in this dropdown */
    actions: IContextOptionButton[];
    /** Returns whether the action button corresponds to the selected context or not */
    getIsSelectedContext: (action: IContextOptionButton) => boolean;
    /** Indicates whether the dropdown is open or not */
    isOpen: boolean;
    /** Function to be called on dropdown close */
    onClose: () => void;
}

const ContextSelectDropdown: FC<Props> = ({ actions, getIsSelectedContext, isOpen, onClose }) => {
    return (
        <ContextSelectDropdownWrapper isOpen={isOpen} data-testid="header-context-select-dropdown">
            <Box maxHeight="65vh" overflowY="auto">
                {actions.map((action) => (
                    <ContextSelectButton
                        key={action.key}
                        action={action}
                        color={getIsSelectedContext(action) ? 'brand.primary' : 'currentcolor'}
                        onClick={() => {
                            // Perform the action.
                            action.onClick();
                            // Close the modal
                            onClose();
                        }}
                    />
                ))}
            </Box>
        </ContextSelectDropdownWrapper>
    );
};

export default ContextSelectDropdown;
