/**
 * @ngdoc service
 * @name sp.client.gallery.service:spComparePhotos
 *
 * @requires $rootScope
 * @requires $location
 * @requires sp.client.gallery.service:SPClientRoute
 *
 * @description
 * Manages functionality of photo comparison
 **/
export default [
    '$rootScope',
    '$location',
    'SPClientRoute',
    function spComparePhoto($rootScope, $location, SPClientRoute) {
        const SELECTED_REQUIRED = 2;
        let selectedPhotos = [];
        let isComparingStarted = false;
        let compareStartedFrom = null;

        $rootScope.$on('$routeChangeStart', (_, next) => {
            if (next.controller !== 'ComparePhotos') {
                isComparingStarted = false;
                selectedPhotos = [];
            }
        });

        return {
            getSelected,
            isComparing,
            isSelected,
            startComparison,
            stopComparing,
            toggleSelection,
            isDoneSelecting,
            goToCompare
        };

        function goToCompare() {
            const compareStartedFrom = encodeURIComponent(SPClientRoute.getCurrentUri());

            $location.url(`/compare?from=${compareStartedFrom}`);
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spComparePhotos#startComparison
         * @methodOf sp.client.gallery.service:spComparePhotos
         *
         * @description
         * Starts photo comparison process
         */
        function startComparison() {
            isComparingStarted = true;
            selectedPhotos = [];
            compareStartedFrom = null;
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spComparePhotos#toggleSelection
         * @methodOf sp.client.gallery.service:spComparePhotos
         *
         * @description
         * Toggles photo selection for comparison
         *
         * @param {object} $event Click event for toggle
         * @param {object} photo Photo selected to toggle for comparison
         */
        function toggleSelection(photo) {
            // determine if the photo is being toggled "off"
            const { id: photoId } = photo;
            const foundIndex = selectedPhotos.findIndex(({ id }) => id === photoId);

            if (foundIndex !== -1) {
                selectedPhotos.splice(foundIndex, 1);
                return;
            }

            // cannot toggle "on" if the max has already been selected
            if (isDoneSelecting()) {
                return;
            }

            selectedPhotos.push(photo);
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spComparePhotos#isSelected
         * @methodOf sp.client.gallery.service:spComparePhotos
         *
         * @description
         * Checks if photoId is already selected
         *
         * @param {number} photoId Id of photo to check for selected state
         *
         * @returns {boolean} If photo with given ID is already selected
         */
        function isSelected(photoId) {
            return selectedPhotos.find(({ id }) => id === photoId) !== undefined;
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spComparePhotos#isComparing
         * @methodOf sp.client.gallery.service:spComparePhotos
         *
         * @description
         * Has comparison process started
         *
         * @returns {boolean} If comparing started
         */
        function isComparing() {
            return isComparingStarted;
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spComparePhotos#getSelected
         * @methodOf sp.client.gallery.service:spComparePhotos
         *
         * @description
         * Get selected photos
         *
         * @returns {array<object>} Collection of selected photos
         */
        function getSelected() {
            return selectedPhotos;
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spComparePhotos#isDone
         * @methodOf sp.client.gallery.service:spComparePhotos
         *
         * @description
         * Determines if selection is done for comparison
         *
         * @returns {boolean} Has selected required photos
         */
        function isDoneSelecting() {
            return selectedPhotos.length === SELECTED_REQUIRED;
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spComparePhotos#stopComparing
         * @methodOf sp.client.gallery.service:spComparePhotos
         *
         * @description
         * Stop comparison process
         */
        function stopComparing() {
            isComparingStarted = false;
        }
    }
];
