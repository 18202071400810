import React, { PointerEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import type { FrameProps } from 'ts/common/components/product/types';
import { PageSpecification, ProjectSpecification } from './Editor.types';
import EditorCanvas, { Position } from './EditorCanvas';
import EditorEditbar from './EditorEditbar';
import EditorToolbar from './EditorToolbar';
import {
    PointerActionState,
    createPointerActionState,
    createPointerCancelEvent,
    createPointerDownEvent,
    createPointerMoveEvent,
    createPointerUpEvent,
    getZoomCenter,
    handlePointerActionEvent
} from './actions';
import { zoomBounds } from './constants';
import {
    convertCropsToPercentage,
    convertPercentageToCrops,
    cropImage,
    getSizeScale,
    resizeImage
} from './helpers';

const Container = styled(Box)(
    ({ theme }) => `
background-color: ${theme.colors.gray[100]};
position: relative;
height: 100%;
`
);

interface IEditorProps {
    /** Whether editing is allowed. */
    disableEditing?: boolean;
    /** Whether rotating is allowed. */
    disableRotating?: boolean;
    /** Optional object describing the properties of the frame for rendering. */
    frame?: FrameProps;
    /** True only if the crop is round */
    isRoundCrop?: boolean;
    /** Project specification. */
    project: ProjectSpecification;
    /** Rendering product specification. */
    pageSpecification: PageSpecification;
    /** Callback for when the project is updated. */
    onUpdate?: (project: ProjectSpecification) => void;
}

const Editor: React.FC<IEditorProps> = ({
    frame,
    disableEditing,
    disableRotating,
    isRoundCrop,
    pageSpecification,
    project: initialProject,
    onUpdate
}) => {
    initialProject.disableRotating = disableRotating;

    const [undoStack, setUndoStack] = useState<ProjectSpecification[]>(() => []);
    const [redoStack, setRedoStack] = useState<ProjectSpecification[]>(() => []);
    const [project, setProject] = useState(initialProject);
    const [resizing, setResizing] = useState(false);
    const [sizeScale, setSizeScale] = useState(() => getSizeScale(project, pageSpecification));
    const [zoomScale, setZoomScale] = useState(-1);
    const [zoomCenter, setZoomCenter] = useState(() => ({
        x: pageSpecification.product.x + pageSpecification.product.width / 2,
        y: pageSpecification.product.y + pageSpecification.product.height / 2
    }));
    const [pointerAction, setPointerAction] =
        useState<PointerActionState>(createPointerActionState);

    const selected = true;

    const frameAdditionalWidth = ((frame?.frameWidth ?? 0) - (frame?.frameOverlap ?? 0)) * 2;
    const matAdditionalWidth = ((frame?.matWidth ?? 0) - (frame?.matOverlap ?? 0)) * 2;
    const matAdditionalHeight = ((frame?.matHeight ?? 0) - (frame?.matOverlap ?? 0)) * 2;

    const resetEditorState = useCallback(() => {
        setZoomScale(-1);
        setZoomCenter({
            x: pageSpecification.product.x + pageSpecification.product.width / 2,
            y: pageSpecification.product.y + pageSpecification.product.height / 2
        });
    }, [
        pageSpecification.product.height,
        pageSpecification.product.width,
        pageSpecification.product.x,
        pageSpecification.product.y
    ]);

    const pushUndoStack = useCallback(
        (project: ProjectSpecification) => {
            setUndoStack((undoStack) => undoStack.concat(project));
            setRedoStack([]);
        },
        [setUndoStack, setRedoStack]
    );

    const handleUndo = useCallback(() => {
        setUndoStack((undoStack) => {
            if (undoStack.length === 0) {
                return undoStack;
            }

            const newProject = undoStack[undoStack.length - 1];

            setProject((project) => {
                setRedoStack((redoStack) => redoStack.concat(project));

                return newProject;
            });

            return undoStack.slice(0, -1);
        });

        resetEditorState();
    }, [resetEditorState]);

    const handleRedo = useCallback(() => {
        setRedoStack((redoStack) => {
            if (redoStack.length === 0) {
                return redoStack;
            }

            const newProject = redoStack[redoStack.length - 1];

            setProject((project) => {
                setUndoStack((undoStack) => undoStack.concat(project));

                return newProject;
            });

            return redoStack.slice(0, -1);
        });

        resetEditorState();
    }, [resetEditorState]);

    const handleSizeChange = useCallback(
        (value: number) => {
            setSizeScale(value);
            setResizing(true);

            setProject((project) => {
                if (!resizing) {
                    pushUndoStack(project);
                }

                return resizeImage(project, pageSpecification, value);
            });
        },
        [resizing, pageSpecification, pushUndoStack]
    );

    const handleSizeChangeEnd = useCallback(
        (value: number) => {
            setResizing(false);
            setSizeScale(value);

            setProject((project) => resizeImage(project, pageSpecification, value));
        },
        [setSizeScale, setResizing, setProject, pageSpecification]
    );

    const handleRotate = useCallback(() => {
        setProject((project) => {
            pushUndoStack(project);

            const cropRectangle = cropImage(
                project.image.width,
                project.image.height,
                project.rotation % 2 === 1 ? pageSpecification.width : pageSpecification.height,
                project.rotation % 2 === 1 ? pageSpecification.height : pageSpecification.width
            );

            return {
                ...project,
                image: {
                    ...project.image,
                    // Reset image crops.
                    clippingMask: convertCropsToPercentage(
                        project.image.width,
                        project.image.height,
                        cropRectangle
                    )
                },
                rotation: project.rotation % 2 === 0 ? 3 : 0
            };
        });

        setSizeScale(0);
        resetEditorState();
    }, [resetEditorState, pushUndoStack, pageSpecification.width, pageSpecification.height]);

    const handleZoomIn = useCallback(() => {
        setZoomScale((scale) => {
            return scale < zoomBounds[1] ? scale + 1 : scale;
        });
    }, [setZoomScale]);

    const handleZoomOut = useCallback(() => {
        setZoomScale((scale) => {
            return scale > zoomBounds[0] ? scale - 1 : scale;
        });
    }, [setZoomScale]);

    const renderSpecification = useMemo(
        () => ({
            x: 0,
            y: 0,
            width: project.width,
            height: project.height,
            scale: 1,
            contents: [
                {
                    id: '0',
                    type: 'image' as const,
                    x: 0,
                    y: 0,
                    width: project.width,
                    height: project.height,
                    image: {
                        url: project.image.url,
                        width: project.image.width,
                        height: project.image.height,
                        rotation: project.rotation,
                        clippingMask: convertPercentageToCrops(
                            project.image.width,
                            project.image.height,
                            project.image.clippingMask
                        )
                    },
                    selected,
                    ghostImage:
                        resizing || pointerAction.type === 'drag' || pointerAction.type === 'resize'
                }
            ],
            product: pageSpecification.product,
            productMasks: {
                rotation: 4 - (project.rotation % 4),
                ...pageSpecification.productMasks
            }
        }),
        [
            project.width,
            project.height,
            project.image.url,
            project.image.width,
            project.image.height,
            project.image.clippingMask,
            project.rotation,
            selected,
            resizing,
            pointerAction.type,
            pageSpecification.product,
            pageSpecification.productMasks
        ]
    );

    const viewport = useMemo(() => {
        const dimensions = {
            width:
                (pageSpecification.product.width + frameAdditionalWidth + matAdditionalWidth) /
                1.2 ** zoomScale,
            height:
                (pageSpecification.product.height + frameAdditionalWidth + matAdditionalHeight) /
                1.2 ** zoomScale
        };

        // eslint-disable-next-line eqeqeq
        if (project.rotation % 2 == 1) {
            [dimensions.width, dimensions.height] = [dimensions.height, dimensions.width];
        }

        const clampZoomCenter = getZoomCenter(
            pageSpecification,
            frameAdditionalWidth + matAdditionalWidth,
            frameAdditionalWidth + matAdditionalHeight,
            zoomScale,
            zoomCenter
        );

        return {
            ...dimensions,
            x: clampZoomCenter.x - 0.5 * dimensions.width,
            y: clampZoomCenter.y - 0.5 * dimensions.height
        };
    }, [
        pageSpecification,
        frameAdditionalWidth,
        matAdditionalWidth,
        zoomScale,
        matAdditionalHeight,
        project.rotation,
        zoomCenter
    ]);

    const handlePointerDown = useCallback(
        (event: PointerEvent<HTMLCanvasElement>, position: Position) => {
            const pointerDownEvent = createPointerDownEvent(
                event,
                position,
                {
                    x: position.x + viewport.x,
                    y: position.y + viewport.y
                },
                project,
                zoomCenter,
                selected
            );

            setPointerAction((pointerAction) =>
                handlePointerActionEvent(pointerAction, pointerDownEvent)
            );
        },
        [project, zoomCenter, viewport, selected]
    );

    const handlePointerMove = useCallback(
        (event: PointerEvent<HTMLCanvasElement>, position: Position) => {
            const pointerMoveEvent = createPointerMoveEvent(event, position);

            setPointerAction((pointerAction) => {
                const newPointerAction = handlePointerActionEvent(pointerAction, pointerMoveEvent);

                if (pointerAction !== newPointerAction) {
                    switch (newPointerAction.type) {
                        case 'pan': {
                            setZoomCenter(
                                getZoomCenter(
                                    pageSpecification,
                                    frameAdditionalWidth + matAdditionalWidth,
                                    frameAdditionalWidth + matAdditionalHeight,
                                    zoomScale,
                                    newPointerAction.zoomCenter,
                                    newPointerAction.zoomCenterOffset
                                )
                            );
                            break;
                        }

                        case 'drag': {
                            setProject((project) => {
                                if (pointerAction.type === 'started') {
                                    pushUndoStack(project);
                                }

                                return {
                                    ...project,
                                    image: {
                                        ...project.image,
                                        clippingMask: convertCropsToPercentage(
                                            project.image.width,
                                            project.image.height,
                                            newPointerAction.cropRectangle
                                        )
                                    }
                                };
                            });
                            break;
                        }
                    }
                }

                return newPointerAction;
            });
        },
        [
            pageSpecification,
            frameAdditionalWidth,
            matAdditionalWidth,
            matAdditionalHeight,
            zoomScale,
            pushUndoStack
        ]
    );

    const handlePointerUp = useCallback(
        (event: PointerEvent<HTMLCanvasElement>, position: Position) => {
            const pointerUpEvent = createPointerUpEvent(event, position);

            setPointerAction((pointerAction) => {
                return handlePointerActionEvent(pointerAction, pointerUpEvent);
            });
        },
        []
    );

    const handlePointerCancel = useCallback((event: PointerEvent<HTMLCanvasElement>) => {
        const pointerCancelEvent = createPointerCancelEvent(event);

        setPointerAction((pointerAction) => {
            return handlePointerActionEvent(pointerAction, pointerCancelEvent);
        });
    }, []);

    useEffect(() => {
        if (onUpdate) {
            onUpdate(project);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project]);

    const rotateDisabled = project.width === project.height || disableRotating;

    return (
        <Container>
            <EditorCanvas
                frame={frame}
                renderSpecification={renderSpecification}
                viewport={viewport}
                isRoundCrop={isRoundCrop}
                onPointerDown={handlePointerDown}
                onPointerMove={handlePointerMove}
                onPointerUp={handlePointerUp}
                onPointerCancel={handlePointerCancel}
            />
            <EditorToolbar
                disableEditing={disableEditing}
                undoDisabled={undoStack.length === 0}
                onUndo={handleUndo}
                redoDisabled={redoStack.length === 0}
                onRedo={handleRedo}
                rotateDisabled={rotateDisabled}
                showAlternativeRotateIcon={project.rotation % 2 === 1}
                onRotate={handleRotate}
                zoomInDisabled={zoomScale === zoomBounds[1]}
                onZoomIn={handleZoomIn}
                zoomOutDisabled={zoomScale === zoomBounds[0]}
                onZoomOut={handleZoomOut}
            />
            {!disableEditing && (
                <EditorEditbar
                    size={sizeScale}
                    onSizeChangeEnd={handleSizeChangeEnd}
                    onSizeChange={handleSizeChange}
                />
            )}
        </Container>
    );
};

Editor.displayName = 'Editor';

export default memo(Editor);
