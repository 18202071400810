/**
 * @ngdoc filter
 * @name sp.client.gallery.filter:spPackageCartItemDetail
 * @function
 *
 * @description
 * Parses the item detail summary for a package item
 *
 * @param {object} item Package item
 *
 * @returns {string} HTML content for a package items details
 */
export default [
    'translateFilter',
    function spPackageCartItemDetailFilter(translateFilter) {
        return function withItemDetail(item) {
            const quantity = item.items.length;
            const itemDetail = quantity > 1 ? 'ITEMS' : 'ITEM';

            return translateFilter(`THIS PACKAGE CONTAINS ${quantity} ${itemDetail}`);
        };
    }
];
