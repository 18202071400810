import React, { FC, useCallback } from 'react';
import { Button, Text, useBreakpointValue } from '@chakra-ui/react';
import type { IPhotoActions, IPhotoWithUrl } from 'ts/client/types';
import BackNav from 'ts/common/components/gallery/BackNav';

interface Props {
    /** The name of the parent route, if any */
    parentRouteName?: string;
    /** The path from the URL of the parent */
    parentUri: string;
    /** The photo associated with this header */
    photo: IPhotoWithUrl;
    /** An object with callbacks to perform actions on photos */
    photoActions: IPhotoActions;
    /** The Angular translation service */
    translateFilter: SpAngularJs.ITranslateFilter;
}

const handleClickBack = () => history?.back();

const PhotoHeader: FC<Props> = ({
    parentRouteName,
    parentUri,
    photo,
    photoActions: { addPhotoToCart, canAddPhotoToCart },
    translateFilter: translate
}) => {
    const handleClickBuy = useCallback(() => {
        addPhotoToCart(photo);
    }, [addPhotoToCart, photo]);

    const onClickBuy = canAddPhotoToCart() ? handleClickBuy : undefined;

    return (
        <BackNav
            onClickBack={parentUri ? undefined : handleClickBack}
            parentRouteName={parentRouteName}
            parentUri={parentUri}
            translateFilter={translate}
            rightActions={useBreakpointValue({
                base: <></>,
                lg: onClickBuy && (
                    <Button onClick={onClickBuy} variant="primary">
                        {translate('buyPhoto')}
                    </Button>
                )
            })}
        >
            <Text
                margin="8px"
                overflow="hidden"
                size="xs"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
            >
                {photo.filename}
            </Text>
        </BackNav>
    );
};

export default PhotoHeader;
