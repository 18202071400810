import { EVENT_ALBUM_EVENTS } from 'Sp/Angular/Events/Client/Gallery';
import Route from 'Sp/Route';

export default [
    '$rootScope',
    'spApiHttp',
    function EventAlbumDataStore($rootScope, spApiHttp) {
        $rootScope.$on(EVENT_ALBUM_EVENTS.GET, function withId(_, id) {
            spApiHttp
                .get(
                    Route.get('/brand/:brandId/event/:eventId/album/:id', {
                        brandId: $rootScope.brandData.id,
                        eventId: $rootScope.eventData.id,
                        id
                    })
                )
                .then(function withResponse({ data: eventAlbum }) {
                    $rootScope.$broadcast(EVENT_ALBUM_EVENTS.GOT, eventAlbum);
                });
        });
    }
];
