import React, { FC } from 'react';
import { getAlbumCaption } from 'ts/common/components/gallery/helpers';
import type { IAlbum } from 'ts/common/types';
import CoverPhotoList from './CoverPhotoList';

interface Props {
    albums: IAlbum[];
    hideCount?: boolean;
    translateFilter: SpAngularJs.ITranslateFilter;
}

const AlbumList: FC<Props> = ({ albums, hideCount, translateFilter: translate }) => (
    <CoverPhotoList
        items={albums.map((album) => ({
            caption: hideCount
                ? undefined
                : getAlbumCaption({
                      albumCount: album.albumCount,
                      photoCount: album.photoCount || 0,
                      translate
                  }),
            coverPhoto: album.thumbnail,
            href: `album/${album.id}`,
            id: album.id,
            name: album.name,
            requiresPassword: album.requiresPassword
        }))}
    />
);

export default AlbumList;
