export function getItemsAndSubgroupBySubgroupId(priceSheetGroup: SpApi.Client.IPriceSheetGroup) {
    return priceSheetGroup.items.reduce(
        (map, item) => {
            if (item.subgroup) {
                if (!map[item.subgroup.id]) {
                    map[item.subgroup.id] = {
                        items: [],
                        subgroup: item.subgroup
                    };
                }

                map[item.subgroup.id].items.push(item);
            }

            return map;
        },
        {} as {
            [priceSheetSubgroupId: number]: {
                items: SpApi.Client.IPriceSheetItem[];
                subgroup: SpApi.IPriceSheetSubgroup;
            };
        }
    );
}

type QueryPrefix = '&' | '?';

export function getParamsForPriceSheetDataFromContext(
    context: string,
    contextId: string,
    queryPrefix: QueryPrefix
) {
    if (context === 'favorites') {
        return `${queryPrefix}is_favorites=1`;
    }

    if (context === 'hidden') {
        return `${queryPrefix}is_hidden=1`;
    }

    if (context === 'label') {
        return `${queryPrefix}is_tag=1`;
    }

    if (contextId === 'all' || context === 'home') {
        return `${queryPrefix}album_id=all`;
    }

    return '';
}

export function getSubgroups(priceSheetGroup: SpApi.Client.IPriceSheetGroup) {
    return Object.values(getItemsAndSubgroupBySubgroupId(priceSheetGroup)).map(
        ({ subgroup }) => subgroup
    );
}
