import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Flex, IconButton } from '@chakra-ui/react';
import { ReactComponent as BackArrow } from 'svg/common/back_arrow.svg';
import { noop } from 'ts/common/utils';
import SidePanel, { SidePanelSize } from './SidePanel';

interface ISlide {
    bodyStyles?: React.CSSProperties;
    content: ReactNode;
    footer?: ReactNode;
    headingContent?: ReactNode;
    hideCloseButton?: boolean;
    hidePreviousButton?: boolean;
    size?: SidePanelSize;
    scrollToTopOnRender?: boolean;
}

interface ISlidesSidePanelProps {
    controlSubscribe?: (goToSlide: (index: number) => void, goToPreviousSlide: () => void) => void;
    isDarkTheme?: boolean;
    isOpen: boolean;
    onClose: () => void;
    setPreviousSlideOnHistory?: (slideIndex: number) => void;
    size?: SidePanelSize;
    slides: ISlide[];
}

const SlidesSidePanel: React.FC<ISlidesSidePanelProps> = ({
    controlSubscribe = noop,
    isDarkTheme,
    isOpen,
    onClose,
    setPreviousSlideOnHistory,
    size = 'lg',
    slides
}) => {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const [slideHistory, setSlideHistory] = useState<number[]>([]);
    const hasSlides = slides.length > 0;
    const hasSlideHistory = slideHistory.length > 0;

    const activeSlide = slides[activeSlideIndex];

    const goToPreviousSlide = useCallback(() => {
        if (hasSlideHistory) {
            const previousSlide = slideHistory[slideHistory.length - 1];
            setSlideHistory((slideHistory) => slideHistory.slice(0, slideHistory.length - 1));
            setActiveSlideIndex(previousSlide);
        }
    }, [hasSlideHistory, slideHistory]);

    const goToSlide = useCallback(
        (index: number) => {
            if (index >= 0 && index < slides.length) {
                setActiveSlideIndex((currentSlideIndex) => {
                    setSlideHistory((slideHistory) => [...slideHistory, currentSlideIndex]);
                    return index;
                });
            }
        },
        [slides.length]
    );

    useEffect(() => {
        if (!isOpen) {
            setActiveSlideIndex(0);
            setSlideHistory([]);
        }
    }, [isOpen]);

    useEffect(() => {
        controlSubscribe(goToSlide, goToPreviousSlide);
    }, [controlSubscribe, goToSlide, goToPreviousSlide]);

    useEffect(() => {
        if (hasSlideHistory && setPreviousSlideOnHistory) {
            const previousSlide = slideHistory[slideHistory.length - 1];
            setPreviousSlideOnHistory(previousSlide);
        }
    }, [hasSlideHistory, slideHistory, setPreviousSlideOnHistory]);

    const HeadingContent = (
        <Flex alignItems="center" zIndex="2" width="100%">
            {hasSlideHistory && !activeSlide?.hidePreviousButton && (
                <IconButton
                    aria-label="Back Button"
                    background="transparent"
                    color="currentColor"
                    data-testid="previous-slide-button"
                    height="40px"
                    icon={<BackArrow />}
                    marginRight="auto"
                    onClick={goToPreviousSlide}
                    variant="ghost"
                />
            )}
            {hasSlides && activeSlide.headingContent}
        </Flex>
    );

    useEffect(() => {
        const panelBody = document.getElementById('side-panel-body');

        if (panelBody && activeSlide.scrollToTopOnRender) {
            panelBody.scrollTop = 0;
        }
    }, [activeSlide]);

    return (
        <SidePanel
            footerContent={hasSlides && activeSlide.footer}
            headingContent={HeadingContent}
            isDarkTheme={isDarkTheme}
            isOpen={isOpen}
            onClose={onClose}
            hideCloseButton={hasSlides && activeSlide.hideCloseButton}
            size={activeSlide?.size ?? size}
            bodyStyles={activeSlide?.bodyStyles}
        >
            {hasSlides && activeSlide.content}
        </SidePanel>
    );
};

export default SlidesSidePanel;
