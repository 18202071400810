import React, { FC, useCallback } from 'react';
import { Flex, Grid, useBreakpointValue, useTheme } from '@chakra-ui/react';
import { isSingleItem } from 'ts/client/gallery/components/common';
import { getMaxCrop, getSize } from 'ts/common';
import { PhotoOptionsTile } from 'client_react/gallery/components/Store';
import { useStore } from 'client_react/gallery/hooks/StoreContext';

interface IPhotoConfigSlide {
    isCroppingAllowed: boolean;
    onEditCrop: (selectedProductIndexToCrop: number) => void;
}

const PhotosConfigSlide: FC<IPhotoConfigSlide> = ({ isCroppingAllowed, onEditCrop }) => {
    const theme = useTheme();
    const {
        selectedPriceSheetGroup,
        selectedPriceSheetItem,
        setUserSelectedPhotos,
        setUserSelectedProducts,
        userSelectedProducts
    } = useStore();
    const isLargeScreen = useBreakpointValue({ base: false, lg: true });
    const generateUniqueKey = () => `${new Date().getTime()}-${Math.random()}`;

    const handleDuplicate = useCallback(
        (index: number) => {
            const productToDuplicate = { ...userSelectedProducts[index] };
            productToDuplicate.key = generateUniqueKey();
            const updatedSelectedProducts = [...userSelectedProducts];
            updatedSelectedProducts.splice(index + 1, 0, productToDuplicate);
            setUserSelectedProducts(updatedSelectedProducts);
        },
        [setUserSelectedProducts, userSelectedProducts]
    );

    const handleDelete = useCallback(
        (itemIndex: number) => {
            const photoToRemove = userSelectedProducts[itemIndex].image;

            const selectedProductsWithPhoto = userSelectedProducts.filter(
                (userSelectedProduct) => userSelectedProduct.image.id === photoToRemove.id
            );

            setUserSelectedProducts((prevProducts) =>
                prevProducts.filter((_, index) => index !== itemIndex)
            );

            if (selectedProductsWithPhoto.length <= 1) {
                setUserSelectedPhotos((userSelectedPhotos) =>
                    userSelectedPhotos.filter(
                        (userSelectedPhoto) => userSelectedPhoto.id !== photoToRemove.id
                    )
                );
            }
        },
        [setUserSelectedPhotos, setUserSelectedProducts, userSelectedProducts]
    );

    const handleSizeChange = useCallback(
        (itemIndex: number, priceSheetItemId: number) => {
            const selectedItem = selectedPriceSheetGroup?.items.find(
                (item) => item.id === priceSheetItemId
            );

            if (selectedItem) {
                setUserSelectedProducts((prevProducts) => {
                    const updatedProducts = [...prevProducts];

                    updatedProducts[itemIndex] = {
                        ...updatedProducts[itemIndex],
                        cropData: getMaxCrop(
                            updatedProducts[itemIndex].image,
                            isSingleItem(selectedItem) ? getSize(selectedItem.size) : null,
                            selectedItem.group.groupType === 'canvas',
                            selectedItem.group.groupType === 'ornaments'
                        ),
                        priceSheetItem: selectedItem
                    };
                    return updatedProducts;
                });
            }
        },
        [selectedPriceSheetGroup, setUserSelectedProducts]
    );

    const handleQuantityChange = useCallback(
        (itemIndex: number, quantity: number) => {
            setUserSelectedProducts((prevProducts) => {
                const updatedProducts = [...prevProducts];
                updatedProducts[itemIndex] = {
                    ...updatedProducts[itemIndex],
                    quantity: quantity
                };
                return updatedProducts;
            });
        },
        [setUserSelectedProducts]
    );

    if (!selectedPriceSheetItem) {
        return null;
    }

    return (
        <Flex
            background={theme.isLightColorScheme ? 'gray.25' : 'background'}
            className="custom-flex"
            justifyContent="center"
            padding={isLargeScreen ? '40px' : '16px'}
            minHeight="100%"
            height="min-content"
        >
            <Grid
                data-testid="photos-config-grid"
                gap="20px"
                templateColumns={isLargeScreen ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)'}
                width="100%"
                maxWidth="1204px"
            >
                {userSelectedProducts.map((userSelectedProduct, index) => {
                    return (
                        <PhotoOptionsTile
                            key={userSelectedProduct.key}
                            crop={userSelectedProduct.cropData}
                            image={userSelectedProduct.image}
                            initialQuantity={userSelectedProduct.quantity}
                            initialPricesheetItemId={userSelectedProduct.priceSheetItem.id}
                            isCroppingAllowed={isCroppingAllowed}
                            onDelete={() => handleDelete(index)}
                            onDuplicate={() => handleDuplicate(index)}
                            onEditCrop={() => onEditCrop(index)}
                            onQuantityChange={(quantity) => handleQuantityChange(index, quantity)}
                            onSizeChange={(priceSheetItemId) =>
                                handleSizeChange(index, priceSheetItemId)
                            }
                            priceSheetGroup={selectedPriceSheetGroup}
                            priceSheetItem={selectedPriceSheetItem}
                        />
                    );
                })}
            </Grid>
        </Flex>
    );
};

export default PhotosConfigSlide;
