import React, { FC } from 'react';
import { Box, BoxProps, ButtonGroup, useBreakpointValue } from '@chakra-ui/react';
import Button from 'ts/common/components/gallery/Button';
import { modalButtonWidth } from 'ts/common/constants/theme/common';

interface IModalButtonFooterProps extends BoxProps {
    applyBoxShadow?: boolean;
    isLoading?: boolean;
    isPrimaryDisabled?: boolean;
    isSecondaryDisabled?: boolean;
    secondaryButtonAction: () => void;
    primaryButtonAction?: () => void;
    primaryButtonText: string;
    primaryLoadingText: string;
    secondaryButtonText: string;
}

const ModalButtonFooter: FC<IModalButtonFooterProps> = ({
    applyBoxShadow = false,
    isPrimaryDisabled,
    isSecondaryDisabled,
    isLoading,
    secondaryButtonAction,
    primaryButtonAction,
    primaryButtonText,
    primaryLoadingText,
    secondaryButtonText,
    ...props
}) => {
    const ButtonGroupMarginBottom = useBreakpointValue({ base: '24px', lg: '30px' });
    const ButtonGroupPadding = useBreakpointValue({ base: '16px', lg: '60px' });

    return (
        <Box
            role="region"
            bottom="0"
            backgroundColor="background"
            position="fixed"
            left="0"
            width="100%"
            boxShadow={applyBoxShadow ? `0px -2px 8px var(--chakra-colors-boxShadow)` : 'none'}
            padding={ButtonGroupPadding}
            paddingTop="30px"
            paddingBottom={ButtonGroupMarginBottom}
            {...props}
        >
            <ButtonGroup spacing="16px" justifyContent="center" width="100%">
                <Button
                    type="button"
                    width={modalButtonWidth}
                    text={secondaryButtonText}
                    isDisabled={isSecondaryDisabled}
                    onClick={secondaryButtonAction}
                    variant="outline primary"
                />
                <Button
                    type="submit"
                    width={modalButtonWidth}
                    isDisabled={isPrimaryDisabled}
                    text={primaryButtonText}
                    isLoading={isLoading}
                    loadingText={primaryLoadingText}
                    onClick={primaryButtonAction}
                    variant="primary"
                />
            </ButtonGroup>
        </Box>
    );
};

export default ModalButtonFooter;
