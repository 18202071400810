export default [
    '$scope',
    'SPModal',
    'spHiddenAlbum',
    function FavoritesController($scope, SPModal, spHiddenAlbum) {
        $scope.downloadPhotoTextHeaderKey = 'favoritesDownload';

        $scope.getDownloadTextHeaderKey = function getDownloadTextHeaderKey() {
            return 'favoritesDownload';
        };

        $scope.getDownloadTextProcessingKey = function getDownloadTextProcessingKey() {
            return 'favoritesDownloadProcessing';
        };

        $scope.showCollectEmail = function showCollectEmail() {
            SPModal.open('collect-email');
        };

        spHiddenAlbum.persistFlow();
    }
];
