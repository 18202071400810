import React from 'react';
import { Box, Text } from '@chakra-ui/react';

type FormSubmitErrorsProps = {
    errors: Record<string, string> | undefined;
};

const FormSubmitErrors = ({ errors }: FormSubmitErrorsProps) => {
    if (!errors) {
        return null;
    }

    return (
        <Box>
            {Object.values(errors).map((error) => (
                <Text key={error} variant="error">
                    {error}
                </Text>
            ))}
        </Box>
    );
};

export default FormSubmitErrors;
