/**
 * @ngdoc service
 * @name sp.client.gallery.service:spPhotoActions
 *
 * @requires sp.client.gallery.service:SPClientData
 * @requires sp.client.common.service:SPPhoto
 * @requires sp.client.common.service:spAuthorizationToken
 * @requires sp.client.gallery.service:spClientCredentials
 * @requires sp.client.common.service:SPModal
 * @requires sp.client.gallery.service:SPFavoritePhoto
 * @requires sp.client.gallery.service:spFreeDigitals
 * @requires sp.client.gallery.service:SPPhotoDownload
 * @requires sp.client.gallery.service:spEventTags
 * @requires $rootScope
 * @requires $routeParams
 *
 * @description
 * Handles checking of permissions and performing photo actions
 **/
export default [
    'SPClientData',
    'SPPhoto',
    'spClientCredentials',
    'SPModal',
    'SPFavoritePhoto',
    'spFreeDigitals',
    'SPPhotoDownload',
    'spEventTags',
    '$rootScope',
    function spPhotoActions(
        SPClientData,
        SPPhoto,
        spClientCredentials,
        SPModal,
        SPFavoritePhoto,
        spFreeDigitals,
        SPPhotoDownload,
        spEventTags,
        $rootScope
    ) {
        /**
         * @description Function to create instance of spPhotoActions service
         *
         * @param {object} userState Settings of user browsing gallery
         * @param {object} eventData Event data for gallery
         *
         * @returns {object} Instance of spPhotoActions
         */
        return ({ userState, eventData }) => {
            const svc = {
                errors: [],
                errorMessage: '',
                tags: [],

                canFavoritePhoto,
                togglePhotoFavorite,
                canSharePhoto,
                getPhotoShareUrls: SPClientData.getPhotoShareUrls,
                downloadPhoto,
                canDownloadPhoto,
                canAddPhotoToCart,
                addPhotoToCart,
                canHidePhoto,
                togglePhotoHidden,
                canBandWPhoto,
                togglePhotoBandW,
                canTagPhoto,
                showTagModal,
                setDownloadDestination,
                getRemainingDownloads,
                cancelDownload
            };

            spEventTags.getPhotoTags();

            return svc;

            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spPhotoActions#canFavoritePhoto
             * @methodOf sp.client.gallery.service:spPhotoActions
             *
             * @description Check if photo can be favorited
             *
             * @param {object} photo Photo to check for favorite action
             *
             * @returns {boolean} Can photo be favorited
             */
            function canFavoritePhoto(photo) {
                return SPPhoto.canFavoritePhoto(photo);
            }

            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spPhotoActions#togglePhotoFavorite
             * @methodOf sp.client.gallery.service:spPhotoActions
             *
             * @description Toggles favorite status of photo
             *
             * @param {object} photo Photo to favorite
             * @param {object} album Album containing target photo
             * @param {function} onPhotoFavorited Album containing target photo
             */
            function togglePhotoFavorite(photo, album = false, onPhotoFavorited = undefined) {
                const email = spClientCredentials.get().email;

                if (shouldCollectEmail(email)) {
                    return showEmailModal(photo, album);
                }

                const favoriteOptions = {
                    photo: photo,
                    album,
                    email: email,
                    partialDirectory: 'photo'
                };

                SPFavoritePhoto.favoritePhoto(
                    favoriteOptions,
                    onPhotoFavorited,
                    function favoritePhotoErrorCallback(errors) {
                        svc.errors = errors;
                    }
                );
            }

            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spPhotoActions#canSharePhoto
             * @methodOf sp.client.gallery.service:spPhotoActions
             *
             * @description Checks if photo can be shared
             *
             * @param {object} photo Photo to check for sharing action
             *
             * @returns {boolean} Can photo be shared
             */
            function canSharePhoto() {
                return eventData.settings.allowSocialSharing;
            }

            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spPhotoActions#canDownloadPhoto
             * @methodOf sp.client.gallery.service:spPhotoActions
             *
             * @description Check if event allows downloads
             *
             * @returns {boolean} Can download photo
             */
            function canDownloadPhoto() {
                if (eventData.settings.hasV3Events) {
                    return spFreeDigitals.canDownloadPhoto();
                }

                return eventData.settings.allowFreeDigitals;
            }

            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spPhotoActions#downloadPhoto
             * @methodOf sp.client.gallery.service:spPhotoActions
             *
             * @description Downloads photo
             *
             * @param {object} photo Photo to download
             * @param {object} credentials "Credentials" for downloading photo
             */
            function downloadPhoto(photo, { email, pin, album } = {}) {
                if (!canDownloadPhoto()) {
                    return;
                }

                if (typeof photo !== 'object') {
                    return;
                }

                if (eventData.settings.hasV3Events) {
                    return spFreeDigitals.downloadPhoto(photo.id);
                }

                var downloadOptions = {
                    photo: photo,
                    album: album,
                    email: email,
                    pin: pin,
                    singlePhotoDownload: true
                };

                SPPhotoDownload.downloadPhoto(downloadOptions, true);
            }

            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spPhotoActions#canAddPhotoToCart
             * @methodOf sp.client.gallery.service:spPhotoActions
             *
             * @description Check if event allows purchase of photos
             *
             * @returns {boolean} Can photo be added to cart
             */
            function canAddPhotoToCart() {
                return eventData.settings.hasPriceSheet;
            }

            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spPhotoActions#addPhotoToCart
             * @methodOf sp.client.gallery.service:spPhotoActions
             *
             * @description Add photo to cart
             *
             * @param {object} photo Photo to add to cart
             */
            function addPhotoToCart(photo) {
                if (!canAddPhotoToCart()) {
                    return;
                }

                $rootScope.openAddToCartSidePanel({
                    ...photo,
                    url: $rootScope.SPPhoto.getUrl(photo, 'l')
                });
            }

            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spPhotoActions#canHidePhoto
             * @methodOf sp.client.gallery.service:spPhotoActions
             *
             * @description Check if photo can be hidden
             *
             * @returns {boolean} Can photo be hidden
             */
            function canHidePhoto() {
                return userState.isAdminModeEnabled && eventData.settings.allowHiddenPhotos;
            }

            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spPhotoActions#togglePhotoHide
             * @methodOf sp.client.gallery.service:spPhotoActions
             *
             * @description Toggles hidden status of photo
             *
             * @param {object} photo Photo to hide
             */
            function togglePhotoHidden(photo) {
                if (!canHidePhoto()) {
                    return;
                }

                SPClientData.togglePhotoHidden(photo);
            }

            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spPhotoActions#canBandWPhoto
             * @methodOf sp.client.gallery.service:spPhotoActions
             *
             * @description Check if photo can add black and white filter
             *
             * @returns {boolean} Can add black and white filter to photo
             */
            function canBandWPhoto() {
                return eventData.settings.allowFiltering;
            }

            function togglePhotoBandW(photo) {
                if (!canBandWPhoto()) {
                    return;
                }

                photo.filterBlackAndWhite = !photo.filterBlackAndWhite;
            }

            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spPhotoActions#canTagPhoto
             * @methodOf sp.client.gallery.service:spPhotoActions
             *
             * @description Check if photo can be tagged
             *
             * @returns {boolean} Can photo be tagged
             */
            function canTagPhoto() {
                return (
                    userState.isAdminModeEnabled && eventData.settings.allowTagPhotos && hasTags()
                );
            }

            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spPhotoActions#showTagModal
             * @methodOf sp.client.gallery.service:spPhotoActions
             *
             * @description Shows modal with label options
             *
             * @param {object} photo Photo to tag
             * @param {function} onTagSelected Tag selected callback
             */
            function showTagModal(photo = {}, onTagSelected = undefined) {
                if (!canTagPhoto()) {
                    return;
                }

                SPModal.open('../photo/tags', {
                    modalData: getTagModalData(photo, onTagSelected),
                    partialDirectory: 'modals'
                });
            }

            function shouldCollectEmail(email) {
                return !userState.hasUserEmail && !email;
            }

            function showEmailModal(photo, album) {
                return SPModal.open('favorite-get-email', {
                    partialDirectory: 'photo',
                    modalData: {
                        clientDataObject: SPClientData,
                        detailPhoto: photo,
                        album,
                        successCallback: function onSuccess() {
                            SPModal.close();
                        },
                        errorCallback: function onError(response) {
                            SPModal.getScope().errors = response.errors;
                        }
                    }
                });
            }

            function hasTags() {
                return Object.keys(spEventTags.eventTags).length > 0;
            }

            function getLabelWithTagCount(tagName, currentEventTag) {
                return `${tagName} (${currentEventTag.photosCount}/${currentEventTag.maximumPhotoCount})`;
            }

            function getTagModalData(photo, onTagSelected) {
                return {
                    tags: SPClientData.getTags(),
                    detailPhoto: photo,
                    getPhotoTagLabel: (tag) => {
                        const tagName = tag.name;
                        const currentEventTag = spEventTags.eventTags[tag.id];
                        const shouldDisplayTagCount =
                            currentEventTag && currentEventTag.maximumPhotoCount;

                        return shouldDisplayTagCount
                            ? getLabelWithTagCount(tagName, currentEventTag)
                            : tagName;
                    },
                    canTogglePhotoTag: (photo, tag) => {
                        const tagId = tag.id;
                        const photoHasTagEnabled = photo.tags.indexOf(tagId) !== -1;
                        const currentEventTag = spEventTags.eventTags[tagId];
                        const photoAtMaximumCount =
                            currentEventTag &&
                            currentEventTag.maximumPhotoCount &&
                            currentEventTag.photosCount >= currentEventTag.maximumPhotoCount;

                        if (!photoHasTagEnabled && photoAtMaximumCount) {
                            return false;
                        }

                        return true;
                    },
                    togglePhotoTag: (photo, tag) => {
                        if (!canTagPhoto() || typeof tag !== 'object') {
                            return;
                        }

                        SPClientData.togglePhotoTag(photo, tag, (data) => {
                            if (typeof onTagSelected === 'function' && photo.hasTag(tag)) {
                                onTagSelected();
                            }

                            var eventTags = data.event_photo_tags;

                            spEventTags.togglePhotoTagCount(eventTags);
                        });
                    }
                };
            }

            function setDownloadDestination(destination) {
                spFreeDigitals.setDestination(destination);
            }

            function getRemainingDownloads() {
                return spFreeDigitals.getRemainingDownloadsForCurrentRule();
            }

            function cancelDownload() {
                spFreeDigitals.cancelDownload();
            }
        };
    }
];
