export default [
    '$scope',
    '$location',
    'SPModal',
    'SPClientRoute',
    'SPClientData',
    function AlbumAuthController($scope, $location, SPModal, SPClientRoute, SPClientData) {
        $scope.handleChangePassword = function handleChangePassword(password) {
            $scope.albumAuth.password = password;
        };

        $scope.albumAuth = {
            password: ''
        };

        $scope.album = $scope.response.album;

        $scope.closeAndGoHome = function closeAndGoHome() {
            SPModal.close();
            $location.path('home');
        };

        if ($scope.requiresPassword === undefined) {
            $scope.requiresPassword = true;
        }

        $scope.authenticate = function authenticate() {
            authenticateAlbum();
        };

        $scope.decline = function decline() {
            if ($scope.isHidden) {
                $location.url('/');
            } else {
                var parentUri = SPClientRoute.getParentUri($scope.album.parentAlbumId);

                $location.url(parentUri);
            }
        };

        function authenticateAlbum() {
            function errorMessage(response) {
                return response.errors.password.passwordNotMatch;
            }

            if ($scope.requiresPassword) {
                SPClientData.authenticateAlbum(
                    $scope.album,
                    $scope.albumAuth.password,
                    function onSuccess() {
                        if ($scope.onAuthentication) {
                            $scope.onAuthentication($scope.albumAuth);
                            SPModal.close();
                        } else {
                            closeAndReload();
                        }
                    },
                    function withResponse(response) {
                        $scope.errors = response.errors;
                        $scope.errorMessage = errorMessage(response);
                        $scope.isInvalid = true;
                    }
                );
            } else {
                closeAndReload();
            }

            function closeAndReload() {
                SPModal.close();
                SPClientRoute.reload();
            }
        }
    }
];
