/* global Darkroom */
import '../../../../common/js/darkroomjs/fabric';
import '../../../../common/js/darkroomjs/darkroom';
import '../../../../common/js/darkroomjs/plugins/darkroom.crop';

const directiveName = 'spPhotoCrop';

export default [
    '$rootScope',
    '$window',
    'SPPhoto',
    function spPhotoCropDirective($rootScope, $window, SPPhoto) {
        const windowElement = angular.element($window);

        return {
            restrict: 'A',
            scope: {
                photo: `=${directiveName}`,
                size: `@${directiveName}Size`,
                crop: `=${directiveName}Crop`
            },
            transclude: 'element',
            link: function spPhotoCropController($scope, $element) {
                const $parent = $element.parent();
                const dimensions = SPPhoto.getPhotoDimensions($scope.photo, $scope.size);
                const url = SPPhoto.getUrl($scope.photo, $scope.size);
                let darkroom;

                $scope.$parent.rotate = function rotate() {
                    $scope.crop.rotate();
                    setDarkroomCrop();
                };

                $scope.$watch('crop', setDarkroomCrop);

                windowElement.on('resize', setDarkroomCrop);

                $scope.$on('$destroy', function offWindowResize() {
                    windowElement.off('resize', setDarkroomCrop);
                });

                function setDarkroomCrop() {
                    const containerDimensions = {
                        width: $parent.width(),
                        height: $parent.height()
                    };
                    const image = new Image();
                    const ratio = $scope.crop.getRatio();

                    image.src = url;
                    image.width = dimensions.width;
                    image.height = dimensions.height;

                    image.addEventListener('load', function initDarkroom() {
                        createDarkroom(image, ratio, containerDimensions);
                    });
                }

                function createDarkroom(image, ratio, containerDimensions) {
                    const parentElement = document.createElement('div');

                    parentElement.appendChild(image);

                    new Darkroom(image, {
                        minWidth: containerDimensions.width * 0.1,
                        minHeight: containerDimensions.height * 0.1,
                        maxWidth: containerDimensions.width,
                        maxHeight: containerDimensions.height,
                        plugins: {
                            save: false,
                            crop: {
                                minWidth: containerDimensions.width * 0.1,
                                minHeight: containerDimensions.width * 0.1,
                                ratio: ratio,
                                bounds: $scope.crop.getBoundsAsArray(),
                                canvasDepth: $scope.crop.bounds.depth,
                                useCanvasCrop: $scope.crop.bounds.useCanvasCrop ? 1 : 0,
                                isLightTheme: $rootScope.themeData.backgroundOptionId === 1,
                                circleCrop:
                                    $scope.crop.boundsName === 'Button' ||
                                    $scope.crop.boundsName === 'Round Magnet'
                            }
                        },
                        init: function init() {
                            const self = this;

                            if (
                                typeof darkroom === 'object' &&
                                typeof darkroom.container === 'object'
                            ) {
                                darkroom.container.remove();
                            }

                            $element.after(self.container);

                            darkroom = self;

                            self.getPlugin('crop').selectZone(
                                Math.floor((self.image.currentWidth * $scope.crop.getLeft()) / 100),
                                Math.floor((self.image.currentHeight * $scope.crop.getTop()) / 100),
                                Math.floor(
                                    (self.image.currentWidth * $scope.crop.getWidth()) / 100
                                ),
                                Math.floor(
                                    (self.image.currentHeight * $scope.crop.getHeight()) / 100
                                )
                            );

                            self.addEventListener('crop:update', function adaptDarkroomCrop() {
                                const currentCrop = self.getPlugin('crop').getCurrentCrop();

                                $scope.$apply(function setCrop() {
                                    $scope.crop.setCrop(
                                        (currentCrop.getLeft() / self.image.currentWidth) * 100,
                                        (currentCrop.getTop() / self.image.currentHeight) * 100,
                                        (currentCrop.getWidth() / self.image.currentWidth) * 100,
                                        (currentCrop.getHeight() / self.image.currentHeight) * 100
                                    );
                                });
                            });
                        }
                    });
                }
            }
        };
    }
];
