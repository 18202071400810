import { ZIP_BUNDLE_EVENTS } from 'Sp/Angular/Events/Client/Gallery';
import Route from 'Sp/Route';

export default [
    '$rootScope',
    'spApiHttp',
    function ZipBundleDataStore($rootScope, spApiHttp) {
        $rootScope.$on(ZIP_BUNDLE_EVENTS.GET, function withRequest(
            _,
            { zipBundleId, zipBundleKey }
        ) {
            spApiHttp
                .get({
                    url: Route.get('/brand/:brandId/event/:eventId/zip-bundle/:id', {
                        brandId: $rootScope.brandData.id,
                        eventId: $rootScope.eventData.id,
                        id: zipBundleId,
                        zipBundleKey
                    })
                })
                .then(({ data }) => {
                    $rootScope.$broadcast(ZIP_BUNDLE_EVENTS.GOT, data);
                });
        });

        $rootScope.$on(ZIP_BUNDLE_EVENTS.CREATE, function withRequest(_, request) {
            const eventId = $rootScope.eventData.id;
            const albumId = request.albumId;
            const apiRouteParams = {
                brandId: $rootScope.brandData.id,
                eventId
            };
            let apiRoute;

            if (albumId) {
                apiRouteParams.albumId = albumId;
                apiRoute = '/brand/:brandId/event/:eventId/album/:albumId/zip-bundle';
            } else {
                apiRoute = '/brand/:brandId/event/:eventId/zip-bundle';
            }

            spApiHttp
                .post({
                    url: Route.get(apiRoute, apiRouteParams),
                    data: {
                        type: 'zip-bundle',
                        digitalRuleId: request.digitalRuleId,
                        email: request.email,
                        pins: request.pins,
                        photoIds: request.photoIds
                    },
                    headers: {
                        Authorization: `Bearer ${request.authorizationToken}`
                    }
                })
                .then(function withResponse({ data: zipBundle }) {
                    $rootScope.$broadcast(ZIP_BUNDLE_EVENTS.CREATED, zipBundle);
                })
                .catch(function withResponse(response) {
                    if (
                        response.status === 403 &&
                        response.data.detail === 'The provided token is expired.'
                    ) {
                        return $rootScope.$broadcast(
                            ZIP_BUNDLE_EVENTS.ERRORS.TOKEN_INVALID,
                            albumId
                        );
                    }

                    const errors = response.data.info.errors;

                    if (errors.digitalRuleId && errors.digitalRuleId.notInEventAlbum) {
                        $rootScope.$broadcast(ZIP_BUNDLE_EVENTS.ERRORS.ALBUM);
                    }

                    if (errors.pin && errors.pin.notInDigitalRule) {
                        $rootScope.$broadcast(ZIP_BUNDLE_EVENTS.ERRORS.PIN);
                    }
                });
        });
    }
];
