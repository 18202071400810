import React, { FC } from 'react';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import Button from 'ts/common/components/gallery/Button';
import { modalButtonWidth } from 'ts/common/constants/theme/common';
import StudioMessage from './StudioMessage';

export interface IWelcomeModal {
    bodyText?: string;
    buttonAction: () => void;
    headerText: string;
    videoUrl?: string;
    translateFilter: SpAngularJs.ITranslateFilter;
}

const WelcomeModal: FC<IWelcomeModal> = ({
    bodyText,
    buttonAction,
    headerText,
    videoUrl,
    translateFilter: t
}) => {
    const ButtonGroupMarginBottom = useBreakpointValue({ base: '24px', lg: '40px' });
    const shouldShowText = Boolean(!videoUrl && bodyText);
    const applyBoxShadow = shouldShowText ? true : false;

    return (
        <Box paddingTop="5px">
            <Box paddingBottom="110px">
                <StudioMessage
                    headerText={headerText}
                    videoUrl={videoUrl}
                    bodyText={bodyText}
                    isInModal
                />
                <Box
                    role="main"
                    textAlign="center"
                    bottom="0"
                    backgroundColor="background"
                    position="fixed"
                    left="0"
                    width="100%"
                    boxShadow={applyBoxShadow ? `0px -2px 8px var(--chakra-colors-boxShadow)` : 0}
                    padding="16px"
                    paddingTop="20px"
                    paddingBottom={ButtonGroupMarginBottom}
                >
                    <Button
                        minWidth={modalButtonWidth}
                        fullWidth={useBreakpointValue({ base: true, lg: false })}
                        isDisabled={false}
                        text={t('continue')}
                        textLarge={t('continue')}
                        variant="primary"
                        onClick={buttonAction}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default WelcomeModal;
