import { DOWNLOAD_DESTINATION } from 'Sp/Gallery';
import { Props as DownloadDestinationsProps } from 'ts/client/gallery/components/DownloadDestinations';
import { IPhoto, IPhotoActions } from 'ts/client/types';
import { IBoundItem, IDataPhoto, IImage, IPhotoConfig, ProductSize } from './types';

export function areEqualSize(
    sizeOne: SpApi.IPriceSheetItemSize,
    sizeTwo: SpApi.IPriceSheetItemSize
) {
    return (
        sizeOne.width === sizeTwo.width &&
        sizeOne.height === sizeTwo.height &&
        sizeOne.depth === sizeTwo.depth
    );
}

export function groupHasLabFulfilledCards(priceSheetGroup: SpApi.Client.IPriceSheetGroup): boolean {
    return priceSheetGroup.items.some((priceSheetItem) => priceSheetItem.isSelfFulfilled === false);
}

export const isPackageItem = (
    item: SpApi.Client.IPriceSheetItem
): item is SpApi.Client.IPriceSheetPackage => {
    return item.type === 'price-sheet-item-package';
};

export const isSingleItem = (
    item: SpApi.Client.IPriceSheetItem
): item is SpApi.Client.IPriceSheetItemSingle => {
    return item.type !== 'price-sheet-item-package';
};

/**
 * Convert an IBoundItem to an IPhotoConfig.
 * The main use for this is to create an IPhotoConfig based on both
 * a digital item and the overarching digital price sheet item.
 *
 * @param selectedDigitalItem The IBoundItem to convert.
 * @param selectedPriceSheetItem The digital price sheet item.
 * @param imageOnSelectedGroup The image to use.
 * @param quantity The quantity to use.
 * @returns An IPhotoConfig.
 */
export const convertIBoundItemToIPhotoConfig = (
    selectedDigitalItem: IBoundItem,
    selectedPriceSheetItem: Nullable<SpApi.Client.IPriceSheetItem>,
    imageOnSelectedGroup: Nullable<IImage>,
    quantity: number
) => {
    if (!selectedPriceSheetItem || !imageOnSelectedGroup) {
        return null;
    }

    const priceSheetItem = convertIBoundItemToIPriceSheetItem(
        selectedDigitalItem,
        selectedPriceSheetItem
    );

    const converted: IPhotoConfig = {
        cropData: null,
        image: imageOnSelectedGroup,
        priceSheetItem: priceSheetItem,
        quantity: quantity
    };

    return converted;
};

export const convertIBoundItemToIPriceSheetItem = (
    selectedDigitalItem: IBoundItem,
    selectedPriceSheetItem: SpApi.Client.IPriceSheetItem
) => {
    const priceSheetItem: SpApi.Client.IPriceSheetItem = {
        type: 'price-sheet-item-digital',
        id: Number(selectedDigitalItem.id),
        isDigitalDownload: true,
        name: selectedDigitalItem.name,
        displayOrder: selectedPriceSheetItem.displayOrder,
        price: Number(selectedDigitalItem.price),
        size: {
            width: null,
            height: null,
            depth: null
        },
        shippingCharge: selectedPriceSheetItem.shippingCharge,
        isSelfFulfilled: false,
        images: selectedPriceSheetItem.images,
        group: {
            ...selectedPriceSheetItem.group,
            items: []
        },
        canHaveScenes: true,
        pixels: selectedPriceSheetItem.pixels,
        created: selectedPriceSheetItem.created,
        links: {
            ...selectedPriceSheetItem.links
        }
    };

    return priceSheetItem;
};

/**
 * Converts an array of IEventPhotos to an array of IImages
 *
 * @param photos Array of IEventPhotos.
 * @param randomize If true, will randomize the order of the photos.
 * @param numberToConvert If provided, will only convert this many photos.
 *
 * @returns Array of IImages
 */
export function convertIEventPhotosToIImages(
    photos: SpApi.Client.IEventPhoto[],
    randomize?: boolean,
    numberToConvert?: number
): IImage[] {
    const images: IImage[] = [];

    const numberOfPhotosToConvert = numberToConvert || photos.length;

    for (let i = 0; i < numberOfPhotosToConvert; i++) {
        let index = i;

        if (randomize) {
            index = randomIntBetween(0, photos.length - 1);
        }

        const photo = photos[index];
        images.push(convertPhotoToImage(photo));
    }

    return images;
}

export function convertPhotoToImage(photo: SpApi.Client.IEventPhoto): IImage {
    return {
        filterBlackAndWhite: false,
        height: Number(photo.height),
        width: Number(photo.width),
        id: Number(photo.id),
        url: photo.displayUrl.large
    };
}

function randomIntBetween(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export function hasMultipleSubgroupsOfProducts(priceSheetGroup: SpApi.Client.IPriceSheetGroup) {
    if (priceSheetGroup.groupType === 'products' || priceSheetGroup.groupType === 'ornaments') {
        return true;
    }

    if (priceSheetGroup.items.length === 1) {
        return false;
    }

    const hasAllBoundedItems = !priceSheetGroup.items.some(({ bounds }) => !bounds);

    if (hasAllBoundedItems) {
        return false;
    }

    return true;
}

export function getItemDisplayName(priceSheetItem: SpApi.Client.IPriceSheetItem) {
    const { name, subgroup } = priceSheetItem;
    const subgroupName =
        subgroup?.name && subgroup.name !== 'No Size Specified' ? subgroup.name : '';

    return `${subgroupName} ${name}`.trim();
}

export function sortByDisplayOrder<T extends { displayOrder: number }>(items: T[]) {
    return items.sort(({ displayOrder }, { displayOrder: nextDisplayOrder }) =>
        displayOrder > nextDisplayOrder ? 1 : -1
    );
}

export function getFirstItemByDisplayOrder(priceSheetGroup: SpApi.Client.IPriceSheetGroup) {
    const itemsWithSubgroup = Object.values(getItemsAndSubgroupBySubgroupId(priceSheetGroup));

    if (itemsWithSubgroup.length === 0) {
        return;
    }

    return sortByDisplayOrder(
        itemsWithSubgroup.sort(({ subgroup }, { subgroup: nextSubgroup }) =>
            subgroup.displayOrder > nextSubgroup.displayOrder ? 1 : -1
        )[0].items
    )[0];
}

/**
 * Returns a Record of priceSheetSubgroupId to items and subgroup.
 * Optionally filters its results to include only items with the given name.
 */
export function getItemsAndSubgroupBySubgroupId(
    priceSheetGroup: SpApi.Client.IPriceSheetGroup,
    filterByItemName?: string
) {
    return priceSheetGroup.items
        .filter((item) => !filterByItemName || item.name === filterByItemName)
        .reduce(
            (map, item) => {
                if (item.subgroup) {
                    if (!map[item.subgroup.id]) {
                        map[item.subgroup.id] = {
                            items: [],
                            subgroup: item.subgroup
                        };
                    }

                    map[item.subgroup.id].items.push(item);
                }

                return map;
            },
            {} as {
                [priceSheetSubgroupId: number]: {
                    items: SpApi.Client.IPriceSheetItem[];
                    subgroup: SpApi.IPriceSheetSubgroup;
                };
            }
        );
}

export function getSubgroups(
    priceSheetGroup: SpApi.Client.IPriceSheetGroup,
    filterByItemName?: string
) {
    return Object.values(getItemsAndSubgroupBySubgroupId(priceSheetGroup, filterByItemName)).map(
        ({ subgroup }) => subgroup
    );
}

export function getProductSizes(
    priceSheetGroup: SpApi.Client.IPriceSheetGroup,
    filterByItemName?: string
): Array<ProductSize> {
    if (priceSheetGroup.groupType === 'ornaments') {
        return [];
    }

    const subgroups = getSubgroups(priceSheetGroup, filterByItemName);
    const productSizes = sortByDisplayOrder(subgroups).map(({ id, name }) => ({
        priceSheetSubgroupId: id ?? 0,
        size: name ?? ''
    }));

    return productSizes;
}

export function convertIDataPhotoToIPhoto(dataPhoto: IDataPhoto): IPhoto {
    return {
        id: dataPhoto.id,
        width: dataPhoto.w,
        height: dataPhoto.g,
        key: dataPhoto.key,
        legacy: dataPhoto.l,
        externalUrl: dataPhoto.externalUrl,
        filename: dataPhoto.n,
        filterBlackAndWhite: Boolean(dataPhoto.lbw),
        isHidden: Boolean(dataPhoto.h),
        isFavorite: Boolean(dataPhoto.f),
        isInCart: Boolean(dataPhoto.c),
        tags: dataPhoto.t ? dataPhoto.t : [],
        favFromAlbumId: dataPhoto.a
    } as IPhoto;
}

export function getDownloadDestinationsProps(
    {
        setDownloadDestination,
        getRemainingDownloads
    }: Pick<IPhotoActions, 'setDownloadDestination' | 'getRemainingDownloads'>,
    handleClose: () => void
): Omit<DownloadDestinationsProps, 'spFreeDigitals' | 'translateFilter'> {
    return {
        remainingDownloads: getRemainingDownloads(),
        onDeviceDownloadClick: () => {
            setDownloadDestination(DOWNLOAD_DESTINATION.DEVICE);
            handleClose();
        },
        onGoogleDownloadClick: () => {
            setDownloadDestination(DOWNLOAD_DESTINATION.GOOGLE_PHOTOS);
            handleClose();
        }
    };
}
