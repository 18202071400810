import React from 'react';
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    useBreakpointValue
} from '@chakra-ui/react';
import { AlertCircle } from 'react-feather';
import { useTranslate } from 'ts/common';
import { ModalHeader } from 'ts/common/components/gallery';

interface IAddToCartErrorModal {
    isOpen: boolean;
    onClose: () => void;
}

const AddToCartErrorModal: React.FC<IAddToCartErrorModal> = ({ isOpen, onClose }) => {
    const t = useTranslate('client.store.addToCartErrorModal');
    const isSmallScreen = useBreakpointValue({ base: true, lg: false });

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="md">
            <ModalOverlay data-testid="add-to-cart-error-modal-overlay" />
            <ModalContent
                borderRadius={isSmallScreen ? '0' : '20px'}
                data-testid="add-to-cart-error-modal"
            >
                <ModalCloseButton
                    _hover={{
                        color: 'brand.primary'
                    }}
                />
                <ModalBody data-testid="add-to-cart-modal-body">
                    <ModalHeader
                        headerText={t('heading')}
                        headerSubText={t('errorMessage')}
                        modalIcon={AlertCircle}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default AddToCartErrorModal;
