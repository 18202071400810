import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { styled } from 'sp-ui';
import { useEvent } from 'ts/client/common';
import { AddedToCartBanner, ProductDetails } from 'ts/client/gallery/components';
import { Button } from 'ts/common/components/gallery';
import { useTranslate } from 'ts/common/hooks';
import type { ICrop } from 'ts/common/types';
import type { IImage } from '../types';
import { GroupType, IPriceSheetDataResponse } from '../types';

const Container = styled(Flex)`
    flex-direction: column;
    margin: 32px 19px 0 19px;
`;

interface IAddedToCartSlideProps {
    buyAllImages: IImage[];
    crop: Nullable<ICrop>;
    digitalOrderPrice?: number;
    image: IImage;
    item?: SpApi.Client.IPriceSheetItem;
    priceSheetData: Nullable<IPriceSheetDataResponse>;
    priceSheetGroup?: SpApi.Client.IPriceSheetGroup;
    priceSheetItemId?: number;
    quantity: number;
    onKeepShoppingClick: () => void;
}

const AddedToCartSlide: React.FC<IAddedToCartSlideProps> = ({
    buyAllImages,
    crop,
    digitalOrderPrice,
    image,
    item,
    priceSheetData,
    priceSheetGroup,
    priceSheetItemId,
    quantity,
    onKeepShoppingClick
}) => {
    const { id: eventId } = useEvent();
    const t = useTranslate('client.gallery.addToCartSidePanel.addedToCartSlide');
    const isBuyingAllPhotos = !!buyAllImages.length;
    const digitalsGroup = priceSheetData?.groups.find((group) => group.type === GroupType.DIGITALS);
    const spFulfilledDigitalItems = digitalsGroup?.bounds.find(
        (bound) => bound.digital_type === 'sp'
    )?.items;
    const digitalItem = spFulfilledDigitalItems?.find(
        (digitalItem) => digitalItem.id === priceSheetItemId?.toString()
    );
    const isAllPhotosDigitalItem = digitalItem?.is_event || digitalItem?.is_album;
    const calculatedQuantity =
        isBuyingAllPhotos && !isAllPhotosDigitalItem ? quantity * buyAllImages.length : quantity;

    if (!priceSheetGroup || !item) {
        return null;
    }

    return (
        <Container>
            <AddedToCartBanner quantity={calculatedQuantity} />
            <ProductDetails
                buyAllImages={buyAllImages}
                crop={crop}
                digitalItem={digitalItem}
                image={image}
                item={item}
                quantity={calculatedQuantity}
                digitalOrderPrice={digitalOrderPrice}
            />
            <Box paddingTop="28px" paddingBottom="10px">
                <Button
                    fullWidth
                    text={t('viewCart')}
                    variant="primary"
                    onClick={() => {
                        window.location.replace(
                            new URL(`/gallery/${eventId}/cart`, window.location.origin).toString()
                        );
                    }}
                />
            </Box>
            <Box paddingTop="10px">
                <Button
                    fullWidth
                    text={t('keepShopping')}
                    variant="outline primary"
                    onClick={onKeepShoppingClick}
                    borderColor="brand.primary"
                    color="brand.primary"
                />
            </Box>
        </Container>
    );
};

export default AddedToCartSlide;
