/* eslint-disable func-names */

export default [
    '$rootScope',
    '$scope',
    '$timeout',
    'SPModal',
    'SPClientData',
    'SPClientRoute',
    function AdminAuthController(
        $rootScope,
        $scope,
        $timeout,
        SPModal,
        SPClientData,
        SPClientRoute
    ) {
        $scope.authenticateAdmin = function authenticateAdmin() {
            $scope.pinMissing = false;
            $scope.errors = null;

            if (!$scope.pin) {
                $scope.pinMissing = true;
                $scope.errors = { pin: true };

                return;
            }

            SPClientData.authenticateAdminMode(
                $scope.pin,
                function() {
                    SPModal.close();
                    SPClientRoute.reload();
                },
                function(response) {
                    if (response.status === 'form_errors') {
                        $scope.errorMessage = errorMessage(response);
                        $scope.errors = response.errors;
                        $scope.isInvalid = true;
                    }
                }
            );
        };

        $scope.handleChangePin = function handleChangePin(pin) {
            $scope.pin = pin;
        };

        $scope.adminModeDecline = function adminModeDecline() {
            $rootScope.userState.hasDeclinedAdminMode = true;

            SPModal.close();
        };

        function errorMessage(response) {
            return response.errors.pin.adminModePinNotMatch;
        }
    }
];
