import React, { useEffect } from 'react';
import { Flex, useBreakpointValue } from '@chakra-ui/react';
import { Info } from 'react-feather';
import { styled } from 'sp-ui';
import { useClientEventApiFetch, useEvent } from 'ts/client/common';
import { LoadingIndicator } from 'ts/common/components';
import { Button, GalleryHeading } from 'ts/common/components/gallery';
import { useTranslate } from 'ts/common/hooks';
import CartInfo from '../CartInfo';
import ProductGroup from '../ProductGroup';
import { IImage } from '../types';
import type { IPathContext, IPriceSheetDataResponse } from '../types';

const Container = styled(Flex)`
    h1 {
        text-align: center;
    }
`;

interface ISelectProductGroupSlideProps {
    priceSheetDataByImage?: Nullable<IPriceSheetDataResponse>;
    getPathContext: () => IPathContext;
    goToPricingAndDiscountsSlide: () => void;
    image: IImage;
    images: IImage[];
    isFetchingPriceSheetDataByImage: boolean;
    onProductGroupSelected: (priceSheetGroup: SpApi.Client.IPriceSheetGroup) => void;
    priceSheetGroups: SpApi.Client.IPriceSheetGroup[];
}

const SelectProductGroupSlide: React.FC<ISelectProductGroupSlideProps> = ({
    priceSheetDataByImage,
    goToPricingAndDiscountsSlide,
    image,
    images,
    isFetchingPriceSheetDataByImage,
    onProductGroupSelected,
    priceSheetGroups
}) => {
    const t = useTranslate('client.gallery.addToCartSidePanel.selectProductGroupSlide');
    const isBuyingAllPhotos = !!images.length;
    const {
        settings: { priceSheetShowPackagesOnly }
    } = useEvent();

    const { response: priceSheetResponse } =
        useClientEventApiFetch<SpApi.Client.IPriceSheet>('price-sheet');
    const cartPhotoState = priceSheetDataByImage ? priceSheetDataByImage.cartPhotoState : null;

    const isLargeScreen = useBreakpointValue({ base: false, lg: true });
    const gap = isLargeScreen ? 20 : 10;

    const productGroupMaxWidth = useBreakpointValue({
        base: `calc(50% - ${gap}px)`,
        lg: `calc(50% - ${gap / 2}px)`
    });

    const hasOnlyOneProductGroup =
        !isFetchingPriceSheetDataByImage && priceSheetDataByImage && priceSheetGroups.length === 1;

    useEffect(() => {
        if (hasOnlyOneProductGroup || priceSheetShowPackagesOnly) {
            onProductGroupSelected(priceSheetGroups[0]);
        }
    }, [
        hasOnlyOneProductGroup,
        onProductGroupSelected,
        priceSheetGroups,
        priceSheetShowPackagesOnly
    ]);

    return !priceSheetDataByImage || isFetchingPriceSheetDataByImage ? (
        <LoadingIndicator squareClassName="theme-background-color-primary" />
    ) : hasOnlyOneProductGroup || priceSheetShowPackagesOnly ? null : (
        <Container alignItems="center" flexDirection="column" padding="48px 20px 80px">
            <GalleryHeading size="md" text={t('heading')} variant="primary" />
            <Button
                borderRadius="24px"
                color="currentColor"
                data-testid="pricing-and-discounts-button"
                fontFamily="open-sans"
                leftIcon={<Info height="14px" style={{ marginRight: '8px' }} width="14px" />}
                marginBottom="48px"
                marginTop="24px"
                onClick={goToPricingAndDiscountsSlide}
                padding="12px 20px"
                text={`${t('pricingInfoLink')}${
                    priceSheetResponse?.discountDescriptions.length ? ` ${t('andDiscounts')}` : ''
                }`}
                variant="outline primary"
            />
            <Flex
                flexWrap="wrap"
                gap={`${gap}px`}
                rowGap="20px"
                justifyContent="space-evenly"
                marginBottom={cartPhotoState ? '48px' : '0'}
                width="100%"
            >
                {priceSheetGroups.map((priceSheetGroup) => (
                    <ProductGroup
                        forceDefaultBackdrop
                        priceSheetGroup={priceSheetGroup}
                        image={isBuyingAllPhotos ? images[0] : image}
                        key={priceSheetGroup.id}
                        width="280px"
                        maxWidth={productGroupMaxWidth}
                        onClick={() => {
                            onProductGroupSelected(priceSheetGroup);
                        }}
                    />
                ))}
            </Flex>
            <CartInfo
                numInCart={cartPhotoState?.numInCart}
                preDiscountSubtotal={cartPhotoState?.preDiscountSubtotal}
                visible={!!cartPhotoState}
            />
        </Container>
    );
};

export default SelectProductGroupSlide;
