import spPackageTileTemplate from '../../templates/components/sp-package-tile.nghtml';

/**
 * @ngdoc component
 * @name sp.client.gallery.component:spPackageTile
 *
 * @requires sp.newcommon.service:spPackage
 * @requires sp.client.gallery.service:spPackages
 * @requires sp.client.gallery.filter:spPackageItemDetailFilter
 * @requires translateFilter
 *
 * @description
 * Renders the package details within a tile
 *
 * @param {object} package Package data to be displayed
 * @param {function} onLearnMore Invoked when learn more or additional summary text is clicked
 * @param {function} onSelect Click handler for the Select button
 */
export default {
    bindings: {
        package: '<',
        onLearnMore: '&',
        onSelect: '<'
    },
    template: spPackageTileTemplate,
    controller: [
        'spPackage',
        'spPackages',
        'spPackageItemDetailFilter',
        'translateFilter',
        function spPackageTileController(
            spPackage,
            spPackages,
            spPackageItemDetailFilter,
            translateFilter
        ) {
            const $ctrl = this;
            const SHOW_MAX_ITEM_DETAIL_COUNT = 4;

            $ctrl.getAdditionalSummaryText = getAdditionalSummaryText;
            $ctrl.getItemSummaries = getItemSummaries;
            $ctrl.isSinglePosePackage = isSinglePosePackage;

            $ctrl.select = function select() {
                $ctrl.onSelect($ctrl.package);
            };

            function getAdditionalSummaryText() {
                const { items } = $ctrl.package;

                if (items.length <= SHOW_MAX_ITEM_DETAIL_COUNT) {
                    return false;
                }

                const remainingItems = items.slice(SHOW_MAX_ITEM_DETAIL_COUNT);
                const itemCount = spPackages.sumItemQuantities(remainingItems);

                return translateFilter('moreItems', { itemCount });
            }

            function getItemSummaries() {
                const { items } = $ctrl.package;
                const maxItemDetails = items.slice(0, SHOW_MAX_ITEM_DETAIL_COUNT);

                return maxItemDetails.map((item) => spPackageItemDetailFilter(item));
            }

            function isSinglePosePackage() {
                return spPackage.getUseSingleImageIndicator($ctrl.package);
            }
        }
    ]
};
