import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, ChakraProvider } from '@chakra-ui/react';
import { useTheme } from 'ts/client/gallery';
import { IBrand, IEvent } from 'ts/client/types';
import Modal from 'ts/common/components/gallery/Modal';
import { AUTHORIZATION_TOKEN_EVENTS } from 'ts/common/constants/auth';
import AccessForm from '../components/AccessForm';
import Intro from '../components/Intro';
import { ICredentials, useAccess } from '../hooks/useAccess';

interface ILandingPageProps {
    spEventBus: SpAngularJs.ISpEventBus;
    spAppData: SpAngularJs.ISpAppData;
    $rootScope: {
        preAuthTargetUrl?: string;
        clearAuthTargetUrl: () => void;
        saveEmail: (email: string) => void;
        navigate: (path: string) => void;
        populateUserState: (response: { userState?: unknown }) => void;
    };
    isAdminRoute?: boolean;
    translateFilter: SpAngularJs.ITranslateFilter;
}

const LandingPage: FC<ILandingPageProps> = ({
    spEventBus,
    spAppData,
    $rootScope: { preAuthTargetUrl, clearAuthTargetUrl, saveEmail, navigate, populateUserState },
    isAdminRoute = false,
    translateFilter
}) => {
    const { brandTheme, theme } = useTheme(spEventBus);

    const postAuthTarget = useRef('home');

    const eventData = spAppData.get<IEvent>('eventData');
    const { coverPhoto, settings } = eventData;
    const {
        preRegister: isPreRegister,
        hasAdminModeEntry,
        landingLayoutType,
        requireEmail,
        requirePassword
    } = settings;

    const brandData = spAppData.get<IBrand>('brandData');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRegistrationComplete, setIsRegistrationComplete] = useState(false);

    const handleSuccessfulAccess = useCallback(
        (response, { email, password }: ICredentials) => {
            if (email !== undefined) {
                saveEmail(email);
            }

            if (typeof response.userState === 'object') {
                populateUserState(response);
            }

            if (isPreRegister && !isAdminRoute) {
                setIsRegistrationComplete(true);
            } else {
                if (!isAdminRoute) {
                    // If favorites were copied during authentication,
                    // take the end user to the favorites page.
                    if (response.copiedSharedFavorites) {
                        postAuthTarget.current = 'favorites';
                    } else if (preAuthTargetUrl) {
                        // If the user was attempting to reach an album or some other
                        // sub-route of this gallery, redirect them. See SPClientAuthCheck.js
                        postAuthTarget.current = preAuthTargetUrl;

                        clearAuthTargetUrl();
                    }
                }

                spEventBus.once(AUTHORIZATION_TOKEN_EVENTS.CREATED, function enterGallery() {
                    if (isAdminRoute && isPreRegister) {
                        navigate('home');
                    } else {
                        navigate(postAuthTarget.current);
                    }
                });

                spEventBus.emit(AUTHORIZATION_TOKEN_EVENTS.CREATE, {
                    email,
                    password
                });
            }
        },
        [
            clearAuthTargetUrl,
            isAdminRoute,
            isPreRegister,
            saveEmail,
            navigate,
            populateUserState,
            preAuthTargetUrl,
            spEventBus
        ]
    );

    const { successMessage, errors, submitCredentials, clearStatus } = useAccess(
        `${window.location.origin}/gallery/${eventData.id}`,
        isPreRegister,
        isAdminRoute,
        handleSuccessfulAccess
    );

    useEffect(() => {
        // Checks for when this controller is used for /admin.
        if (isAdminRoute) {
            // If we're rendering for the Admin page, but pre-registration is not
            // enabled, just deliver the end user to the landing page.  Also
            // bounce to landing if Admin Mode isn't available.  For Admin
            // Mode to be available, the Event has to 1) have a Contact
            // setup, and 2) have an Admin Mode PIN set.
            if (!isPreRegister || !hasAdminModeEntry) {
                navigate('');
            }
        }
    }, [isAdminRoute, navigate, hasAdminModeEntry, isPreRegister]);

    const handleClick = () => {
        if (!isPreRegister && !requireEmail && !requirePassword) {
            navigate('home');
        } else {
            setIsModalOpen(true);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);

        setIsRegistrationComplete(false);
        clearStatus();
    };

    return (
        <ChakraProvider theme={theme} resetCSS={false}>
            <Box height="full" left={0} position="fixed" top={0} width="full">
                <Intro
                    brandName={brandData.name}
                    brandTheme={brandTheme}
                    isAdminRoute={isAdminRoute}
                    translateFilter={translateFilter}
                    onClick={handleClick}
                    landingLayoutType={landingLayoutType}
                    galleryTitle={eventData.name}
                    coverPhoto={coverPhoto}
                    isPreRegister={isPreRegister}
                />
            </Box>
            {brandTheme ? (
                <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                    <AccessForm
                        brandName={brandData.name}
                        eventBrandLogo={brandTheme.eventBrandLogo}
                        logoAltText={brandTheme.logoAltText}
                        isPreRegister={isPreRegister}
                        isAdminRoute={isAdminRoute}
                        isRegistrationComplete={isRegistrationComplete}
                        successMessage={successMessage}
                        eventDataSettings={settings}
                        galleryTitle={eventData.name}
                        isSubjectToGdpr={spAppData.get<boolean>('isSubjectToGdpr')}
                        submitErrors={errors}
                        onSubmit={submitCredentials}
                        translateFilter={translateFilter}
                    />
                </Modal>
            ) : null}
        </ChakraProvider>
    );
};

export default LandingPage;
