import { PageSpecification, ProjectSpecification } from './Editor.types';

const SCALE_CONSTANT = 0.977;

export function calculateLinearGradientCoords(width: number, height: number, rotation: number) {
    // Adjust for any set turns, in degrees.
    const rotationInDegrees = rotation * 90;
    // Desired gradient angle
    const angleInDegrees = 125.57 + rotationInDegrees;
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180;

    // Calculate the diagonal length of the product
    const diagonalLength = Math.sqrt(width * width + height * height);

    // Calculate the start and end points of the gradient
    const startX = width / 2 - (Math.cos(angleInRadians) * diagonalLength) / 2;
    const startY = height / 2 - (Math.sin(angleInRadians) * diagonalLength) / 2;
    const endX = width / 2 + (Math.cos(angleInRadians) * diagonalLength) / 2;
    const endY = height / 2 + (Math.sin(angleInRadians) * diagonalLength) / 2;

    return {
        startX,
        startY,
        endX,
        endY
    };
}

export function convertCropsToPercentage(
    imageWidth: number,
    imageHeight: number,
    cropRectangle: {
        x: number;
        y: number;
        width: number;
        height: number;
    }
) {
    return {
        left: cropRectangle.x / imageWidth,
        top: cropRectangle.y / imageHeight,
        right: (imageWidth - (cropRectangle.x + cropRectangle.width)) / imageWidth,
        bottom: (imageHeight - (cropRectangle.y + cropRectangle.height)) / imageHeight
    };
}

export function convertPercentageToCrops(
    imageWidth: number,
    imageHeight: number,
    clippingMask: {
        left: number;
        top: number;
        right: number;
        bottom: number;
    }
) {
    return {
        x: clippingMask.left * imageWidth,
        y: clippingMask.top * imageHeight,
        width: imageWidth - (clippingMask.left + clippingMask.right) * imageWidth,
        height: imageHeight - (clippingMask.top + clippingMask.bottom) * imageHeight
    };
}

export function cropImage(
    imageWidth: number,
    imageHeight: number,
    canvasWidth: number,
    canvasHeight: number
) {
    const imageAspectRatio = imageWidth / imageHeight;
    const canvasAspectRatio = canvasWidth / canvasHeight;

    // Size the image to cover the canvas completely.
    const width =
        imageAspectRatio > canvasAspectRatio ? imageHeight * canvasAspectRatio : imageWidth;
    const height =
        imageAspectRatio > canvasAspectRatio ? imageHeight : imageWidth / canvasAspectRatio;

    // Center the image on the canvas.
    const x = (imageWidth - width) / 2;
    const y = (imageHeight - height) / 2;

    // Final image crop.
    return { x, y, width, height };
}

export function getSizeScale(project: ProjectSpecification, pageSpecification: PageSpecification) {
    const { image, rotation } = project;
    const { width, height, clippingMask } = image;

    const rectangleCrops = convertPercentageToCrops(width, height, clippingMask);

    const rectangle = cropImage(
        width,
        height,
        rotation % 2 === 0 ? pageSpecification.width : pageSpecification.height,
        rotation % 2 === 0 ? pageSpecification.height : pageSpecification.width
    );

    const scaleModifier = rectangleCrops.width / rectangle.width;
    const sizeScale = Math.log(scaleModifier) / Math.log(SCALE_CONSTANT);

    return Math.round(sizeScale);
}

export function resizeImage(
    project: ProjectSpecification,
    pageSpecification: PageSpecification,
    value: number
) {
    const rectangle = cropImage(
        project.image.width,
        project.image.height,
        project.rotation % 2 === 0 ? pageSpecification.width : pageSpecification.height,
        project.rotation % 2 === 0 ? pageSpecification.height : pageSpecification.width
    );

    const imageWidth = rectangle.width * SCALE_CONSTANT ** value;
    const imageHeight = rectangle.height * SCALE_CONSTANT ** value;

    const rectangleCrops = convertPercentageToCrops(
        project.image.width,
        project.image.height,
        project.image.clippingMask
    );

    const resizedRectangleCrops = {
        x: Math.min(
            project.image.width - imageWidth,
            Math.max(0, rectangleCrops.x + rectangleCrops.width / 2 - imageWidth / 2)
        ),
        y: Math.min(
            project.image.height - imageHeight,
            Math.max(0, rectangleCrops.y + rectangleCrops.height / 2 - imageHeight / 2)
        ),
        width: imageWidth,
        height: imageHeight
    };

    return {
        ...project,
        image: {
            ...project.image,
            clippingMask: convertCropsToPercentage(
                project.image.width,
                project.image.height,
                resizedRectangleCrops
            )
        }
    };
}
