import React, { FC, useEffect } from 'react';
import { Flex, Spinner, Text } from '@chakra-ui/react';

const GoogleOAuthRedirectPage: FC = () => {
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const authKey = urlParams.get('authKey');
        const cacheKey = urlParams.get('cacheKey');

        if (!authKey) {
            throw new Error('An authKey parameter is required in the URL query string.');
        }

        if (cacheKey) {
            localStorage.setItem(authKey, cacheKey);
            window.close();
        } else {
            localStorage.removeItem(authKey);
        }
    }, []);

    const urlParams = new URLSearchParams(location.search);
    const error = urlParams.get('error');

    return (
        <Flex
            alignItems="center"
            justifyContent="center"
            position="fixed"
            height="100%"
            width="100%"
            top={0}
            left={0}
        >
            {error ? <Text>{error}</Text> : <Spinner size="xl" />}
        </Flex>
    );
};

export default GoogleOAuthRedirectPage;
