import React from 'react';
import { Flex, Text, useTheme } from '@chakra-ui/react';
import { Smartphone } from 'react-feather';
import { ReactComponent as GooglePhotosIcon } from 'svg/common/gallery/google-photos-icon.svg';
import GooglePhotosConnection from 'ts/client/gallery/components/GooglePhotosConnection';
import { Button } from 'ts/common/components/gallery';

export type Props = {
    /** True only if this is a single-photo download */
    isDownloadPhoto?: boolean;
    /** Called when the "My Device" button is clicked */
    onDeviceDownloadClick: () => unknown;
    /** Called when the "Save to Google Photos" button is clicked */
    onGoogleDownloadClick: () => unknown;
    /** How many remaining downloads the user has for the currently selected digital rule */
    remainingDownloads?: number | null;
    /** Angular free digitals service */
    spFreeDigitals: SpAngularJs.ISpFreeDigitals;
    /** Angular translation service */
    translateFilter: SpAngularJs.ITranslateFilter;
};

const DownloadDestinations = ({
    isDownloadPhoto,
    onDeviceDownloadClick,
    onGoogleDownloadClick,
    remainingDownloads,
    spFreeDigitals,
    translateFilter
}: Props) => {
    const { isLightColorScheme } = useTheme();

    function t(id: string, interpolateParams?: Record<string, string> | undefined) {
        return translateFilter(`client.gallery.downloadDestinations.${id}`, interpolateParams);
    }

    const googleEmail = spFreeDigitals.getGoogleEmail();

    return (
        <Flex direction="column" alignItems="center" gap="16px">
            <Flex direction="column" alignItems="center" marginBottom="8px">
                <Text
                    fontSize="lg"
                    fontWeight={600}
                    color={isLightColorScheme ? 'gray.600' : 'white'}
                >
                    {t(isDownloadPhoto ? 'downloadPhoto' : 'downloadAll')}
                </Text>
                <Text fontSize="sm">
                    {typeof remainingDownloads === 'number'
                        ? t(remainingDownloads === 1 ? 'oneDownload' : 'downloads', {
                              count: `${remainingDownloads}`
                          })
                        : t('unlimitedDownloads')}
                </Text>
            </Flex>
            {googleEmail && (
                <GooglePhotosConnection
                    disconnectFromGoogle={spFreeDigitals.disconnectFromGoogle}
                    googleEmail={googleEmail}
                    translateFilter={translateFilter}
                />
            )}
            <Flex direction="column" alignItems="center" gap="8px" width="100%">
                <Button
                    text={t('saveToGooglePhotos')}
                    variant="googlePhotos"
                    fullWidth
                    iconSpacing="4"
                    leftIcon={<GooglePhotosIcon />}
                    onClick={onGoogleDownloadClick}
                />
                <Button
                    text={t('myDevice')}
                    variant="primary"
                    fullWidth
                    iconSpacing="4"
                    leftIcon={<Smartphone />}
                    onClick={onDeviceDownloadClick}
                />
            </Flex>
        </Flex>
    );
};

export default DownloadDestinations;
