/* eslint-disable func-names, no-unused-vars  */
export default [
    '$rootScope',
    'SPClientData',
    'SPModal',
    function SPFavoritePhotoService($rootScope, SPClientData, SPModal) {
        var service = new function SPFavoritePhoto() {
            var self = this;

            /**
             * Call this method to toggle the favorite-status of a photo
             *
             * @param options           Options for creating the Modal & making the data call:
             *                              photo: Photo to be favorite-toggled
             *                              album: Album in which photo resides
             *                              email: User email, if it exists
             *                              partialDirectory: If required for modal display.
             *
             * @param {function} onSuccessCallback   called on a successful request to favorite a photo
             * @param {function} onErrorCallback     returns any errors back to the calling controller
             */
            this.favoritePhoto = function (
                options,
                onSuccessCallback = undefined,
                onErrorCallback = undefined
            ) {
                var needsEmail = !$rootScope.userState.hasUserEmail;

                /**
                 * If user does not have an email set, and one was not passed here, we need to
                 * present the modal to collect email address before favoriting.
                 */
                if (needsEmail && typeof options.email !== 'string') {
                    return SPModal.open('favorite-get-email', {
                        partialDirectory: options.partialDirectory,
                        modalData: {
                            clientDataObject: SPClientData,
                            detailPhoto: options.photo,
                            email: options.email,
                            album: options.album,
                            successCallback: function () {
                                SPModal.close();

                                if (typeof onSuccessCallback === 'function') {
                                    onSuccessCallback();
                                }
                            },
                            errorCallback: function (response) {
                                if (typeof onErrorCallback === 'function') {
                                    onErrorCallback(response.errors);
                                }
                            }
                        }
                    });
                }

                SPClientData.togglePhotoFavorite(
                    options.photo,
                    options.email,
                    options.album,
                    true,
                    function () {
                        if (typeof onSuccessCallback === 'function') {
                            onSuccessCallback();
                        }

                        return;
                    },
                    function (response) {
                        if (typeof onErrorCallback === 'function') {
                            onErrorCallback(response.errors);
                        }
                    }
                );
            };
        }();

        return service;
    }
];
