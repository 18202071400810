import { PACKAGE_EVENTS } from 'Sp/Angular/Events/Client/Gallery';

export default [
    '$rootScope',
    '$scope',
    '$anchorScroll',
    '$timeout',
    '$location',
    'SPClientRoute',
    'SPClientData',
    'SPModal',
    'SPPhoto',
    'SPPhotoDownload',
    'SPFavoritePhoto',
    'spClientCredentials',
    'spComparePhotos',
    'spFreeDigitals',
    'spPackages',
    'spPhotoActions',
    function GalleryController(
        $rootScope,
        $scope,
        $anchorScroll,
        $timeout,
        $location,
        SPClientRoute,
        SPClientData,
        SPModal,
        SPPhoto,
        SPPhotoDownload,
        SPFavoritePhoto,
        spClientCredentials,
        spComparePhotos,
        spFreeDigitals,
        spPackages,
        spPhotoActions
    ) {
        let hasScrolledToAnchor = false;
        let anchorYOffset = 106;

        $scope.photoSelected = {};
        $scope.spComparePhotos = spComparePhotos;
        $scope.spPackages = spPackages;

        $scope.$watch('photos', () => {
            if (
                $scope.passedProductSelectionState?.groupId &&
                $scope.photoActions.canAddPhotoToCart()
            ) {
                $scope.photoActions.addPhotoToCart($scope.passedProductSelectionState.foundPhoto);
            }
        });

        $scope.openPackageSelectModal = spPackages.openPackageSelectModal;
        $scope.togglePhotoSelect = togglePhotoSelect;
        $scope.showAlbumPhotoCount = showAlbumPhotoCount;

        $scope.gotoPhoto = function gotoPhoto(photo) {
            $location.path($scope.getPhotoUri(photo));
        };

        $scope.handlePhotoClicked = function handlePhotoClicked(photo, goTo) {
            if (spComparePhotos.isComparing() && !goTo) {
                spComparePhotos.toggleSelection(photo);
                $rootScope.updateCount = ($rootScope.updateCount ?? 0) + 1;
            } else if (spPackages.getSelectedPackage() && !goTo) {
                $scope.togglePhotoSelect(photo);
                $rootScope.updateCount = ($rootScope.updateCount ?? 0) + 1;
            } else {
                $scope.gotoPhoto(photo);
            }

            $scope.$apply();
        };

        $scope.isPhotoSelected = function isPhotoSelected(photo) {
            if (spComparePhotos.isComparing()) {
                return spComparePhotos.isSelected(photo.id);
            }

            if (spPackages.getSelectedPackage()) {
                return spPackages.isPackagePhotoSelected(photo.id);
            }
        };

        if (typeof $scope.getDownloadTextHeaderKey !== 'function') {
            $scope.getDownloadTextHeaderKey = function getDownloadTextHeaderKey() {
                return 'downloadPhotos';
            };
        }

        if (typeof $scope.getDownloadTextProcessingKey !== 'function') {
            $scope.getDownloadTextProcessingKey = function getDownloadTextProcessingKey() {
                return 'downloadProcessing';
            };
        }

        // @@STUB: Implement downloadInProgress
        $scope.downloadInProgress = function downloadInProgress() {
            return false;
        };

        $scope.photoActions = {};

        $scope.photoActions.canFavoritePhoto = function canFavoritePhoto(photo) {
            return SPPhoto.canFavoritePhoto(photo);
        };

        $scope.photoActions.togglePhotoFavorite = function togglePhotoFavorite(
            photo,
            onPhotoFavorited
        ) {
            var email = spClientCredentials.get().email;

            /**
             * If user does not have an email set, and one was not passed here, we need to
             * present the modal to collect email address before favoriting.
             */
            if (!$scope.userState.hasUserEmail && !email) {
                return SPModal.open('favorite-get-email', {
                    partialDirectory: 'photo',
                    modalData: {
                        toggleFavorite: function toggleFavorite(email, doesAcceptTerms) {
                            SPClientData.togglePhotoFavorite(
                                photo,
                                email,
                                $scope.album,
                                doesAcceptTerms,
                                function onSuccess() {
                                    SPModal.close();

                                    if (typeof onPhotoFavorited === 'function') {
                                        onPhotoFavorited();
                                    }
                                },
                                function onError(response) {
                                    SPModal.getScope().errors = response.errors;
                                }
                            );
                        },
                        closeModal: function closeModal() {
                            SPModal.close();
                        }
                    }
                });
            }

            var favoriteOptions = {
                photo: photo,
                album: $scope.album,
                email: email,
                partialDirectory: 'photo'
            };

            SPFavoritePhoto.favoritePhoto(
                favoriteOptions,
                onPhotoFavorited,
                function favoritePhotoErrorCallback(errors) {
                    $scope.errors = errors;
                }
            );
        };

        $scope.photoActions.canSharePhoto = function canSharePhoto() {
            return $scope.eventData.settings.allowSocialSharing;
        };

        $scope.photoActions.getPhotoShareUrls = SPClientData.getPhotoShareUrls;

        $scope.photoActions.canBandWPhoto = function canBandWPhoto() {
            return $scope.eventData.settings.allowFiltering;
        };

        $scope.photoActions.togglePhotoBandW = function togglePhotoBandW(photo) {
            if (!$scope.photoActions.canBandWPhoto()) {
                return;
            }

            photo.filterBlackAndWhite = !photo.filterBlackAndWhite;
            $scope.$apply();
        };

        $scope.showGalleryActions = function showGalleryActions() {
            return !spComparePhotos.isComparing() && !spPackages.getSelectedPackage();
        };

        $scope.showPackageActions = showPackageActions;

        $scope.showPhotoActions = function showPhotoActions() {
            return (
                $scope.getViewControllerName() !== 'Photo' &&
                !$scope.deviceHasTouchEvents() &&
                !spComparePhotos.isComparing() &&
                !spPackages.getSelectedPackage()
            );
        };

        $scope.photoActions.canDownloadPhoto = function canDownloadPhoto() {
            if ($scope.eventData.settings.hasV3Events) {
                return spFreeDigitals.canDownloadPhoto();
            }

            return $scope.eventData.settings.allowFreeDigitals;
        };

        $scope.photoActions.downloadPhoto = function downloadPhoto(photo, email, pin) {
            if (!$scope.photoActions.canDownloadPhoto()) {
                return;
            }

            if (typeof photo !== 'object') {
                return;
            }

            if ($scope.eventData.settings.hasV3Events) {
                return spFreeDigitals.downloadPhoto(photo.id);
            }

            var downloadOptions = {
                photo: photo,
                album: $scope.album,
                email: email,
                pin: pin,
                singlePhotoDownload: true
            };

            SPPhotoDownload.downloadPhoto(downloadOptions, true);
        };

        $scope.photoActions.canAddPhotoToCart = function canAddPhotoToCart() {
            return $scope.eventData.settings.hasPriceSheet;
        };

        $scope.photoActions.addPhotoToCart = function addPhotoToCart(photo) {
            if (!$scope.photoActions.canAddPhotoToCart()) {
                return;
            }

            $rootScope.openAddToCartSidePanel({
                ...photo,
                url: $rootScope.SPPhoto.getUrl(photo, 'l')
            });
        };

        $scope.photoActions.canHidePhoto = function canHidePhoto() {
            return (
                $scope.userState.isAdminModeEnabled && $scope.eventData.settings.allowHiddenPhotos
            );
        };

        $scope.photoActions.togglePhotoHidden = function togglePhotoHidden(photo, onPhotoHidden) {
            if (!$scope.photoActions.canHidePhoto()) {
                return;
            }

            SPClientData.togglePhotoHidden(photo, onPhotoHidden);
        };

        $scope.photoActions.canTagPhoto = function canTagPhoto() {
            return (
                !$scope.isPackageSelected() &&
                $scope.userState.isAdminModeEnabled &&
                $scope.eventData.settings.allowTagPhotos &&
                $scope.hasTags()
            );
        };

        const spPhotoActionsHydrated = spPhotoActions($scope);

        $scope.photoActions.showTagModal = function showTagModal(photo, onTagSelected) {
            spPhotoActionsHydrated.showTagModal(photo, onTagSelected);
        };

        $scope.photoActions.setDownloadDestination = spPhotoActionsHydrated.setDownloadDestination;
        $scope.photoActions.getRemainingDownloads = spPhotoActionsHydrated.getRemainingDownloads;
        $scope.photoActions.cancelDownload = spPhotoActionsHydrated.cancelDownload;

        $scope.$on(PACKAGE_EVENTS.PHOTO_LOCATION_RESET, () => {
            hasScrolledToAnchor = false;
        });

        $scope.scrollToTargetPhoto = function scrollToTargetPhoto() {
            // If this controller has already scrolled to a specific
            // Photo, we don't want to scroll to it again when the layout
            // is performed again.
            if (hasScrolledToAnchor) {
                return;
            }

            var photo = $scope.getTargetPhoto();

            if (typeof photo !== 'object') {
                return;
            }

            $timeout(
                function scrollToPhoto() {
                    if (
                        SPClientRoute.getControllerName() !== 'Home' &&
                        SPClientRoute.getControllerName() !== 'all'
                    ) {
                        anchorYOffset = 156;
                    }

                    $anchorScroll.yOffset = anchorYOffset;
                    $anchorScroll('photoId-' + photo.id);

                    hasScrolledToAnchor = true;
                },
                0,
                false
            );
        };

        $scope.$emit('fix-main-navigation');

        function showPackageActions() {
            return (
                $scope.getViewControllerName() !== 'Photo' &&
                Boolean(spPackages.getSelectedPackage())
            );
        }

        function togglePhotoSelect(photo) {
            if ($scope.photoSelected[photo.id]) {
                $scope.photoSelected[photo.id] = !$timeout.cancel($scope.photoSelected[photo.id]);

                $timeout(() => togglePhotoSelect(photo));

                return;
            }

            if (spPackages.getSelectedPackageItem().itemId) {
                $scope.photoSelected[photo.id] = $timeout(
                    () => ($scope.photoSelected[photo.id] = false),
                    1000
                );
            }

            spPackages.togglePackagePhoto(photo, $scope.album);
            $scope.setTargetPhoto(photo);
        }

        function showAlbumPhotoCount() {
            return !$scope.hidePhotoCountAlbums;
        }
    }
];
