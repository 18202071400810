import bowser from 'bowser';
import { EVENT_EVENTS } from 'Sp/Angular/Events/Client/Gallery';
import { AUTHORIZATION_TOKEN_EVENTS } from 'Sp/Angular/Events/Client/Gallery';
import { NOTIFICATION_EVENTS } from 'Sp/Angular/Events/Client/Notification';
import { findBy } from 'Sp/Array';
import { DOWNLOAD_TYPE, PACKAGE_SMALL_SCREEN_STATES } from 'Sp/Gallery';
import Route from 'Sp/Route';
import { trackClientEventFromPriceSheetItems } from 'ts/client/common';

// The number of pixels a user must scroll in the opposite direction for the Header's hidden state to change:
const scrollDirectionChangeThreshold = 16;

export default [
    '$rootScope',
    '$scope',
    '$routeParams',
    '$window',
    '$location',
    'spAppData',
    'spHiddenAlbum',
    'SPClientData',
    'SPClientRoute',
    'SPPhotoUri',
    'SPModal',
    'spClientCredentials',
    'spEventTags',
    'spFreeDigitals',
    'spPackages',
    'spComparePhotos',
    'SPPhotoDownload',
    'SPAddToCart',
    'spEventBus',
    'spPhotoActions',
    'translateFilter',
    function ClientRootController(
        $rootScope,
        $scope,
        $routeParams,
        $window,
        $location,
        spAppData,
        spHiddenAlbum,
        SPClientData,
        SPClientRoute,
        SPPhotoUri,
        SPModal,
        spClientCredentials,
        spEventTags,
        spFreeDigitals,
        spPackages,
        spComparePhotos,
        SPPhotoDownload,
        SPAddToCart,
        spEventBus,
        spPhotoActions,
        translateFilter
    ) {
        const NAVIGATE_AWAY_EVENT = bowser.ios ? 'pagehide' : 'beforeunload';
        let isAuthenticatedForSecretAlbum = false;

        initialize();

        if ($routeParams.spdebugmode === 'true') {
            $scope.debugMode = true;
        }

        $scope.spComparePhotos = spComparePhotos;
        $scope.debugOptions = {
            centerLine: false
        };
        $scope.isProduction = spAppData.get('isProduction');
        $scope.state = {
            isNavigationSuppressed: false
        };
        $scope.tags = SPClientData.getTags();
        $scope.PACKAGE_SMALL_SCREEN_STATE = PACKAGE_SMALL_SCREEN_STATES.PACKAGE_VIEW;
        $scope.DOWNLOAD_TYPE = DOWNLOAD_TYPE;

        $scope.deselectPackage = deselectPackage;
        $scope.isPackageSelected = isPackageSelected;
        $scope.getSelectedPackage = spPackages.getSelectedPackage;
        $scope.getSmallScreenState = spPackages.getSmallScreenState;
        $scope.selectPackage = selectPackage;
        $scope.updateUserCartState = updateUserCartState;

        $scope.isPage = function isPage(pageControllerName) {
            return SPClientRoute.isControllerName(pageControllerName);
        };

        $scope.hasMusic = function hasMusic() {
            return (
                $scope.eventData.settings.isMusicAllowed && !SPClientRoute.isMusicDisabledForRoute()
            );
        };

        $scope.closeIntroModal = function closeIntroModal() {
            SPModal.close();
        };

        $scope.setPassedProductSelectionState = function setPassedProductSelectionState(newState) {
            $scope.passedProductSelectionState = newState;
        };

        $scope.reloadRouteData = function reloadRouteData() {
            const albumId = $routeParams.albumId;
            let album = null;

            if ($scope.albums && albumId) {
                album = findBy('id', parseInt(albumId), $scope.albums);
            }

            $scope.contentType = undefined;
            $scope.album = undefined;
            $scope.albums = undefined;
            $scope.photos = undefined;
            $scope.tag = undefined;
            $scope.anchorTargetPhoto = undefined;

            switch (SPClientRoute.getDataContext()) {
                case 'Home':
                    SPClientData.getEventData({}, dataSuccessCallback, dataErrorCallback);
                    $scope.$emit(AUTHORIZATION_TOKEN_EVENTS.CREATE, spClientCredentials.get());

                    break;
                case 'Album':
                    if (!$scope.isInHiddenAlbumFlow()) {
                        $scope.$emit(AUTHORIZATION_TOKEN_EVENTS.CREATE, spClientCredentials.get());
                    }

                    const albumData = album || spAppData.get('albumData');
                    let albumIdForAuthorizationToken = albumId;

                    if (albumIdForAuthorizationToken === 'all') {
                        albumIdForAuthorizationToken = null;
                    }

                    if (requiresAuthenticationForSecretAlbum(albumData)) {
                        SPModal.open('main', {
                            partialDirectory: 'album-auth',
                            modalData: {
                                response: {
                                    album: { id: albumId }
                                },
                                isHidden: albumData.isHidden,
                                requiresPassword: albumData.requiresPassword,
                                onAuthentication: function setAuthenticatedAndGetAlbum(
                                    credentials
                                ) {
                                    isAuthenticatedForSecretAlbum = true;

                                    getAlbum();

                                    $scope.$emit(AUTHORIZATION_TOKEN_EVENTS.CREATE, {
                                        albumId: albumIdForAuthorizationToken,
                                        email: credentials.email,
                                        password: credentials.password
                                    });
                                }
                            }
                        });
                    } else {
                        getAlbum();

                        $scope.$emit(AUTHORIZATION_TOKEN_EVENTS.CREATE, {
                            albumId: albumIdForAuthorizationToken
                        });
                    }

                    break;
                case 'Favorites':
                    SPClientData.getFavoritePhotos(dataSuccessCallback, dataErrorCallback);

                    // If they reload the favorites page, fetch a fresh token
                    // with the correct scope
                    if (spHiddenAlbum.isInFlow()) {
                        $scope.$emit(AUTHORIZATION_TOKEN_EVENTS.CREATE, {
                            albumId: spHiddenAlbum.id
                        });
                    } else {
                        $scope.$emit(AUTHORIZATION_TOKEN_EVENTS.CREATE);
                    }

                    break;
                case 'Hidden':
                    SPClientData.getHiddenPhotos(dataSuccessCallback, dataErrorCallback);

                    break;
                case 'Tags':
                    // This is handled in the tags controller
                    break;
                case 'Tag':
                    $scope.tag = $scope.getTagByTagName($routeParams.tagName);

                    SPClientData.getTaggedPhotos(
                        $routeParams.tagName,
                        dataSuccessCallback,
                        dataErrorCallback,
                        getPhotoDataFilterForContext()
                    );

                    break;
                case 'Search':
                    SPClientData.getPhotosForSearch(
                        $location.search().q,
                        dataSuccessCallback,
                        dataErrorCallback
                    );

                    break;
            }

            function requiresAuthenticationForSecretAlbum(albumData) {
                if (
                    isAuthenticatedForSecretAlbum ||
                    !albumData ||
                    (albumData.isHidden && !albumData.requiresPassword)
                ) {
                    return false;
                }

                return albumData.requiresPassword || requiresEmail();

                function requiresEmail() {
                    return $scope.eventData.settings.requireEmail && !$scope.userState.hasUserEmail;
                }
            }

            function getAlbum() {
                SPClientData.getEventData(
                    {
                        album_id: $routeParams.albumId
                    },
                    dataSuccessCallback,
                    dataErrorCallback
                );
            }
        };

        $scope.hasAlbums = function hasAlbums() {
            return typeof $scope.albums === 'object';
        };

        $scope.isAlbumsEmpty = function isAlbumsEmpty() {
            return $scope.hasAlbums() && $scope.albums.length === 0;
        };

        $scope.getAlbums = function getAlbums() {
            return $scope.albums;
        };

        $scope.getPhotoUri = function getPhotoUri(photo) {
            return SPPhotoUri.build(photo);
        };

        $scope.hasPhotos = function hasPhotos() {
            return typeof $scope.photos === 'object' && $scope.photos.length > 0;
        };

        $scope.emptyPhotos = function emptyPhotos() {
            return typeof $scope.photos === 'object' && $scope.photos.length === 0;
        };

        $scope.getPhotoById = function getPhotoById(photoId) {
            if (!$scope.photos) {
                return false;
            }

            const photo = $scope.photos.filter(function withPhoto(photo) {
                // eslint-disable-next-line eqeqeq
                return photo.id == photoId;
            })[0];

            if (typeof photo === 'object') {
                return photo;
            }

            return false;
        };

        $scope.getPhotoIndex = function getPhotoIndex(photo) {
            const index = $scope.photos.indexOf(photo);

            if (index === -1) {
                return false;
            }

            return index;
        };

        $scope.getPhotoByIndex = function getPhotoByIndex(index) {
            if ($scope.photos[index]) {
                return $scope.photos[index];
            }

            return false;
        };

        $scope.getTargetPhoto = function getTargetPhoto() {
            if (
                $scope.anchorTargetPhoto &&
                $scope.photos.indexOf($scope.anchorTargetPhoto) === -1
            ) {
                return false;
            }

            return $scope.anchorTargetPhoto;
        };

        $scope.setTargetPhoto = function setTargetPhoto(photo) {
            $scope.anchorTargetPhoto = photo;
        };

        $scope.hasTags = function hasTags() {
            return typeof $scope.tags === 'object' && $scope.tags.length !== 0;
        };

        $scope.getTags = function getTags() {
            if (typeof $scope.tags !== 'object') {
                return [];
            }

            return $scope.tags;
        };

        $scope.getTagCaption = function getTagCaption(id, photos) {
            const { maximumPhotoCount } = spEventTags.eventTags[id] ?? {};

            if (maximumPhotoCount) {
                return translateFilter(
                    maximumPhotoCount === 1 ? 'outOfOnePhoto' : 'outOfMultiplePhotos',
                    {
                        photoCount: photos?.length ?? 0,
                        maximumPhotoCount
                    }
                );
            }

            if (!photos || photos.length === 0) {
                return translateFilter('zeroPhotos');
            }

            return translateFilter(photos.length === 1 ? 'onePhoto' : 'multiplePhotos', {
                photoCount: photos.length
            });
        };

        $scope.getTagById = function getTagById(id) {
            let tags = $scope.getTags();

            tags = tags.filter(function withTag(tag) {
                // eslint-disable-next-line eqeqeq
                return tag.id == id;
            });

            if (tags.length === 0) {
                return false;
            }

            return tags.shift();
        };

        $scope.getTagByTagName = function getTagByTagName(tagName) {
            let tags = $scope.getTags();

            tags = tags.filter(function withTag(tag) {
                return tag.name === tagName;
            });

            if (tags.length === 0) {
                return false;
            }

            return tags.shift();
        };

        $scope.isContentLoading = function isContentLoading() {
            // the Tags page does not load photos into this scope
            if ($scope.contentType === undefined && SPClientRoute.getDataContext() !== 'Tags') {
                return true;
            }

            return false;
        };

        $scope.isNavTabsVisible = function isNavTabsVisible() {
            const selectedRoute = $scope.getNavigationRoot();
            const selectedUri = $scope.getCurrentUri();

            const isNavTabsVisible =
                (['home', 'album/all'].includes(selectedUri) &&
                    $scope.isHomeSecondaryNavVisible()) ||
                (selectedRoute === 'Special' &&
                    $scope.userState.isAdminModeEnabled &&
                    ($scope.eventData.settings.allowHiddenPhotos ||
                        $scope.isLabeledPhotosVisible())) ||
                (selectedRoute === 'Info' &&
                    [
                        $scope.isAboutContactVisible(),
                        $scope.isAboutMessageVisible(),
                        $scope.isAboutPricingVisible()
                    ].filter(Boolean).length > 1);

            return isNavTabsVisible;
        };

        $scope.isBackNavVisible = function isBackNavVisible() {
            const isBackNavVisible =
                !$scope.isComparing() &&
                $scope.hasParent() &&
                $scope.getCurrentUri() !== 'album/all' &&
                !($scope.getNavigationRoot() === 'Album' && $scope.isInHiddenAlbumFlow());

            return isBackNavVisible;
        };

        $scope.isToolbarHidden = function isToolbarHidden() {
            const selectedRoute = $scope.getNavigationRoot();
            const isHomeSelected = selectedRoute === 'Home' || selectedRoute === 'Album';
            const isFavoritesSelected = selectedRoute === 'Special';

            const isToolbarHidden =
                $scope.isContentLoading() ||
                $scope.isComparing() ||
                spPackages.getSelectedPackage() ||
                !(isHomeSelected || isFavoritesSelected) ||
                !(
                    $scope.canAddToCart() ||
                    $scope.canAddPackagesToCart() ||
                    $scope.canComparePhotos() ||
                    $scope.canDownload() ||
                    $scope.canSlideshow() ||
                    $scope.canShare()
                );

            return isToolbarHidden;
        };

        $scope.canComparePhotos = function canComparePhotos() {
            return $scope.hasPhotos() && $scope.photos.length >= 2;
        };

        $scope.showCompareMode = function showCompareMode() {
            spComparePhotos.startComparison();
            $scope.$apply(); // for React
        };

        $scope.isReadyToCompare = function isReadyToCompare() {
            return spComparePhotos.isDoneSelecting();
        };

        $scope.goToComparePage = function goToComparePage() {
            spComparePhotos.goToCompare();
            spComparePhotos.stopComparing();
            $scope.$apply();
        };

        $scope.exitCompareMode = function exitCompareMode() {
            spComparePhotos.stopComparing();
            $scope.$apply();
        };

        $scope.canSendFavorites = function canSendFavorites() {
            return (
                $scope.userState.isEventContact &&
                $scope.eventData.settings.allowFavoritesNotification
            );
        };

        $scope.canShare = function canShare() {
            const controllerName = SPClientRoute.getControllerName();

            if (controllerName === 'Home') {
                return $scope.eventData.settings.allowSocialSharing;
            } else if (controllerName === 'Favorites') {
                return $scope.hasPhotos();
            }

            return false;
        };

        $scope.sendFavorites = function sendFavorites() {
            SPModal.open('share');
        };

        $scope.shareFavorites = function shareFavorites(
            fromName,
            toEmail,
            message,
            onSuccess,
            onError
        ) {
            SPClientData.shareFavorites(
                fromName,
                toEmail,
                message,
                () => {
                    onSuccess();
                },
                (response) => {
                    if (response.status === 'form_errors') {
                        onError(response.errors);
                    }
                }
            );
        };

        $scope.sendFavoritesToPhotographer = function sendFavoritesToPhotographer(
            message,
            onSuccess
        ) {
            spEventBus.once(NOTIFICATION_EVENTS.FAVORITES.SENT, onSuccess);

            SPClientData.sendFavoritesToPhotographer(spClientCredentials.get().email, message);
        };

        $rootScope.canDownload = function canDownload() {
            const controllerName = SPClientRoute.getControllerName();

            if (controllerName === 'Favorites') {
                if ($scope.eventData.settings.hasV3Events) {
                    if (!$scope.hasPhotos()) {
                        return false;
                    }

                    return spFreeDigitals.canDownloadAll($scope.photos.length);
                }

                // Only allow Favorites downloads if free digitals are enabled.
                return $scope.eventData.settings.allowFreeDigitals;
            }

            if (controllerName === 'Album') {
                const hasSubAlbums = $scope.albums && $scope.albums.length > 0;

                if (hasSubAlbums) {
                    return false;
                }

                if ($scope.eventData.settings.hasV3Events) {
                    if (!$scope.hasPhotos()) {
                        return false;
                    }

                    return spFreeDigitals.canDownloadAlbum($scope.photos.length);
                }

                const downloadAll = $scope.eventData.settings.allowFreeDigitalsDownloadAll;
                const freeAndBig =
                    $scope.eventData.settings.allowFreeDigitals &&
                    $scope.eventData.settings.exceedsBigEventThreshold;

                return downloadAll || freeAndBig;
            }

            if (controllerName === 'Home') {
                if ($scope.eventData.settings.hasV3Events) {
                    const photoCount = $scope.eventData.settings.totalCount;

                    return spFreeDigitals.canDownloadAll(photoCount);
                }

                return $scope.eventData.settings.allowFreeDigitalsDownloadAll;
            }

            return false;
        };

        $scope.download = function download() {
            if (!$scope.canDownload()) {
                return;
            }

            let downloadOptions;
            const controllerName = SPClientRoute.getControllerName();

            switch (SPClientRoute.getControllerName()) {
                case 'Home':
                case 'Album':
                    if (controllerName === 'Home' || $routeParams.albumId === 'all') {
                        if ($scope.eventData.settings.hasV3Events) {
                            return spFreeDigitals.downloadAll();
                        }

                        downloadOptions = {
                            album: $scope.album,
                            singlePhotoDownload: false
                        };

                        SPPhotoDownload.downloadAllPhotos(downloadOptions);
                    } else {
                        const album = $scope.album;

                        if (typeof album === undefined) {
                            return;
                        }

                        if ($scope.eventData.settings.hasV3Events) {
                            return spFreeDigitals.downloadAlbum(album);
                        }

                        downloadOptions = {
                            album: $scope.album,
                            singlePhotoDownload: false
                        };

                        SPPhotoDownload.downloadAlbum(downloadOptions);
                    }

                    break;
                default:
                    if (!$scope.hasPhotos()) {
                        return;
                    }

                    const photos = $scope.photos;

                    if ($scope.eventData.settings.hasV3Events) {
                        return spFreeDigitals.downloadAll(photos);
                    }

                    downloadOptions = {
                        photos: photos,
                        album: $scope.album,
                        singlePhotoDownload: false
                    };

                    SPPhotoDownload.downloadPhotos(downloadOptions);

                    break;
            }
        };

        $scope.canSlideshow = function canSlideshow() {
            const controllerName = SPClientRoute.getControllerName();

            if (
                ['Home', 'Album', 'Favorites', 'Hidden', 'Tag'].some(
                    (name) => name === controllerName
                )
            ) {
                if (!$scope.hasPhotos() || $scope.photos.length === 1) {
                    return false;
                }

                return true;
            }

            return false;
        };

        $scope.enterSlideshow = function enterSlideshow() {
            if (!$scope.hasPhotos() || $scope.photos.length === 1) {
                return;
            }

            $scope.slideshowModeOn = true;
            $scope.$apply(); // for React
        };

        $scope.exitSlideshow = function exitSlideshow() {
            if (!$scope.canSlideshow()) {
                return;
            }

            $scope.slideshowModeOn = false;
        };

        $scope.canAddPackagesToCart = function canAddPackagesToCart() {
            return Boolean($scope.hasPhotos()) && spPackages.canAddPackagesToCart();
        };

        $scope.openPackageSelectModal = spPackages.openPackageSelectModal;

        $scope.canAddToCart = function canAddToCart() {
            const controllerName = SPClientRoute.getControllerName();

            if (
                ['Home', 'Album', 'Favorites', 'Hidden', 'Tag'].some(
                    (name) => name === controllerName
                )
            ) {
                return (
                    $scope.eventData.settings.allowAddAllToCart &&
                    $scope.eventData.settings.hasPriceSheet &&
                    $scope.hasPhotos() &&
                    $scope.photos.length <= 1000
                );
            }

            return false;
        };

        $scope.buyAll = function buyAll() {
            if (!$scope.canAddToCart() || !$scope.photos) {
                return;
            }

            const photosWithUrl = $scope.photos.map(function withPhoto(photo) {
                return {
                    ...photo,
                    url: $rootScope.SPPhoto.getUrl(photo, 'l')
                };
            });

            $rootScope.openAddToCartSidePanelBuyingAllPhotos(photosWithUrl);
        };

        $scope.toolbarActions = {
            canBuyAll: $scope.canAddToCart,
            buyAll: $scope.buyAll,
            canBuyPackage: $scope.canAddPackagesToCart,
            buyPackage: $scope.openPackageSelectModal,
            canComparePhotos: $scope.canComparePhotos,
            comparePhotos: $scope.showCompareMode,
            canDownloadAll: $scope.canDownload,
            downloadAll: $scope.download,
            canShare: $scope.canShare,
            emailFavorites: $scope.shareFavorites,
            canSendFavoritesToPhotographer: $scope.canSendFavorites,
            sendFavoritesToPhotographer: $scope.sendFavoritesToPhotographer,
            canStartSlideshow: $scope.canSlideshow,
            startSlideshow: $scope.enterSlideshow
        };

        $scope.reloadRoute = function reloadRoute() {
            $scope.$emit('reloadRoute');
        };

        $scope.setIsNavigationSuppressed = setIsNavigationSuppressed;

        $scope.$on('$routeChangeStart', (_, nextRoute, previousRoute) => {
            if (
                previousRoute?.$$route &&
                previousRoute.$$route.componentName !== 'Landing' &&
                previousRoute.$$route.controller !== 'Photo' &&
                nextRoute?.$$route?.controller === 'Photo'
            ) {
                $scope.isNavigationFromParentRoute = true;
            }

            if (previousRoute && previousRoute.$$route) {
                const { originalPath } = previousRoute.$$route;

                if (originalPath === '/' || originalPath === '/admin') {
                    $scope.$emit(EVENT_EVENTS.GET);

                    const offEventGot = $scope.$on(
                        EVENT_EVENTS.GOT,
                        function displayIntroMessageModalIfPresent(_, event) {
                            if (event.headline && (event.information || event.videoEmbedCode)) {
                                SPModal.open('main', {
                                    partialDirectory: 'info',
                                    modalData: {
                                        infoData: {
                                            headline: event.headline,
                                            text: event.information,
                                            videoUrl: event.videoUrl,
                                            videoEmbedCode: event.videoEmbedCode
                                        }
                                    },
                                    queue: true
                                });
                            }

                            offEventGot();
                        }
                    );
                }
            }
        });

        $scope.$on('SPClientRoute:RouteDataContextChange', $scope.reloadRouteData);

        $scope.$on('SPClientRoute:RouteDataContextRefresh', function onRouteDataContextRefresh() {
            if (typeof $scope.photos !== 'object') {
                return;
            }

            const filter = getPhotoDataFilterForContext();

            if (typeof filter !== 'function') {
                return;
            }

            /**
             * When the data context refreshes, but does not change, refilter the data
             * incase properties of photos involved have changed
             */
            $scope.photos = $scope.photos.filter(filter);
        });

        $scope.$on('SPClientData:error', function withEventAndResponse(event, response) {
            const path = $location.path();

            switch (response.status) {
                case 'auth_error':
                    if (path === '/' || path === '/admin') {
                        break;
                    }

                    $location.url('');

                    break;
            }
        });

        $scope.$on(AUTHORIZATION_TOKEN_EVENTS.CREATED, (_, authorizationToken) => {
            spFreeDigitals.setAuthorizationToken(authorizationToken);
        });

        $scope.$on(AUTHORIZATION_TOKEN_EVENTS.CREATED_EVENT_TOKEN, (_, eventAuthorizationToken) => {
            spFreeDigitals.setEventAuthorizationToken(eventAuthorizationToken);
        });

        $scope.$on(AUTHORIZATION_TOKEN_EVENTS.ERRORS.UNAUTHORIZED, () => {
            reloadGalleryPage();
        });

        angular.element($window).on(NAVIGATE_AWAY_EVENT, () => {
            if (isPackageSelected()) {
                spPackages.setSelectedPackage(spPackages.getSelectedPackage());
            }
        });

        let lastScrollDiff = 1;
        let lastScrollY = $window.scrollY;

        function handleWindowScroll() {
            const scrollDiff = $window.scrollY - lastScrollY;

            if (
                Math.sign(scrollDiff) === Math.sign(lastScrollDiff) ||
                Math.abs(scrollDiff) > scrollDirectionChangeThreshold
            ) {
                lastScrollDiff = scrollDiff;
                lastScrollY = $window.scrollY;
            }

            $scope.isScrolledDown = $window.scrollY > $window.innerHeight;
            $scope.isScrollingDown = lastScrollDiff > 0;
            $scope.$digest();
        }

        function initialize() {
            spPackages.retrievePackages();

            $window.addEventListener('scroll', handleWindowScroll);

            const offEventGot = $scope.$on(EVENT_EVENTS.GOT, function handleGotEvent(_, event) {
                $scope.eventAccessLevel = event.eventAccessLevel;
                $scope.numClientTopLevelAlbumsVisible = event.numClientTopLevelAlbumsVisible;
                $scope.hidePhotoCountAlbums = event.hidePhotoCountAlbums;
                $scope.shouldShowAllPhotos = event.shouldShowAllPhotos;
                offEventGot();
            });

            $scope.$emit(EVENT_EVENTS.GET);

            SPClientData.getShareUrls((response) => {
                $scope.shareUrls = response.shareUrls;
            });
        }

        function navigateToParentOfPhoto() {
            const context = getPathContext();

            switch (context.context) {
                case 'album':
                    $rootScope.navigate(`album/${context.contextId}`);
                    break;
                case 'home':
                    $rootScope.navigate('home');
                    break;
                case 'favorites':
                    $rootScope.navigate('favorites');
                    break;
                case 'label':
                    $rootScope.navigate(`label/${context.contextId}`);
                    break;
            }
        }

        function selectPackage(selectedPackage, selectedPhoto, isSmallScreen) {
            trackClientEventFromPriceSheetItems({
                eventSlug: 'view_item',
                eventId: $rootScope.eventData.id,
                priceSheetItems: selectedPackage.items,
                currencyCode: $rootScope.brandData.currencyCode,
                orderPrice: selectedPackage.price,
                isPackageItem: true
            });

            const controllerName = SPClientRoute.getControllerName().toLowerCase();

            if (isSmallScreen && controllerName === 'photo') {
                navigateToParentOfPhoto();
            }

            spPackages.setSelectedPackage(selectedPackage);
            spPackages.setSmallScreenState(PACKAGE_SMALL_SCREEN_STATES.PACKAGE_VIEW);

            if (selectedPhoto) {
                spPackages.togglePackagePhoto(selectedPhoto, $scope.album);
            }

            $scope.$apply(); // for React
        }

        function updateUserCartState(numCartItems) {
            $rootScope.userState.numCartItems = $rootScope.userState.numCartItems + numCartItems;
            $scope.$apply(); // for React
        }

        function deselectPackage() {
            SPModal.open('main', {
                partialDirectory: 'package-warning',
                type: 'dialog package-warning'
            });
        }

        function isPackageSelected() {
            return Boolean(spPackages.getSelectedPackage());
        }

        function dataSuccessCallback(response) {
            $scope.contentType = typeof response.type === 'string' ? response.type : undefined;
            $scope.album = typeof response.album === 'object' ? response.album : undefined;
            $scope.albums = typeof response.albums === 'object' ? response.albums : undefined;
            $scope.photos = typeof response.photos === 'object' ? response.photos : undefined;

            const { groupId, boundsName, boundItemName, photoId } = $routeParams;
            const foundPhoto = $scope.getPhotoById(parseInt(photoId));

            if (groupId && foundPhoto) {
                $scope.setPassedProductSelectionState({
                    groupId,
                    boundsName,
                    boundItemName,
                    foundPhoto
                });
            }
        }

        function dataErrorCallback(response) {
            switch (response.status) {
                case 'album_auth_error':
                    if (!$scope.isPage('Album')) {
                        // If we're not on an album page, redirect to landing page
                        $location.url('');

                        break;
                    }

                    // If we're on an album page, present password dialog
                    SPModal.open('main', {
                        partialDirectory: 'album-auth',
                        modalData: { response }
                    });

                    break;
                // User is not allowed to access this data, redirect
                case 'auth_error':
                    // @@STUB: Set an error message for the user?
                    $location.url('');

                    break;

                case 'album_not_found':
                    // @@STUB: Set an error message for the user?
                    $location.url('');

                    break;
                default:
                    // @@STUB: Set an error message for the user?
                    $location.url('');

                    break;
            }
        }

        function getPhotoDataFilterForContext() {
            switch (SPClientRoute.getDataContext()) {
                case 'Favorites':
                    return function withPhoto(photo) {
                        return photo.isFavorite;
                    };

                case 'Hidden':
                    return function withPhoto(photo) {
                        return photo.isHidden;
                    };

                case 'Tag':
                    const tag = $scope.getTagByTagName($routeParams.tagName);

                    return function withPhoto(photo) {
                        return photo.tags.indexOf(tag.id) !== -1;
                    };

                default:
                    return function allPhotos() {
                        return true;
                    };
            }
        }

        function reloadGalleryPage() {
            if ($routeParams.albumId) {
                return $window.location.reload();
            }

            $window.location.replace(Route.get('/gallery/:id', { id: $scope.eventData.id }));
        }

        function setIsNavigationSuppressed(isNavigationSuppressed) {
            $scope.state.isNavigationSuppressed = isNavigationSuppressed;
        }

        $scope.isInHiddenAlbumFlow = function isInHiddenAlbumFlow() {
            return spHiddenAlbum.isInFlow();
        };

        $scope.getHomeLinkHref = function getHomeLinkHref() {
            if ($scope.isInHiddenAlbumFlow()) {
                return 'album/' + spHiddenAlbum.id;
            }

            return 'home';
        };

        $scope.getHomeLinkLabel = function getHomeLinkText() {
            if ($scope.isInHiddenAlbumFlow()) {
                return 'album';
            }

            return 'photos';
        };

        $scope.getCurrentUri = function getNavigationPath() {
            const currentUri = SPClientRoute.getCurrentUri();

            return currentUri;
        };

        $scope.getNavigationRoot = function getNavigationRoot(context) {
            const navigationRoot = SPClientRoute.getNavigationRoot(context);

            return navigationRoot;
        };

        $scope.getParentName = function getParentName() {
            const parentId = SPClientRoute.getParentId();

            switch (parentId) {
                case 'HomeOrAlbum':
                case 'Album':
                case 'Home':
                    return 'gallery';
                case false:
                case undefined:
                    return;
                default:
                    return parentId.toLowerCase();
            }
        };

        $scope.getParentUri = function getParentUri() {
            if (SPClientRoute.isDataContext('Album')) {
                if (typeof $scope.album !== 'object') {
                    return;
                }

                return SPClientRoute.getParentUri($scope.album.parentAlbumId);
            }

            return SPClientRoute.getParentUri();
        };

        $scope.hasParent = function hasParent() {
            return SPClientRoute.hasParent();
        };

        $scope.hasMarketingBanner = function hasMarketingBanner() {
            const { bannerText } = $scope.eventData.settings;

            return Boolean(bannerText);
        };

        $rootScope.isAboutContactVisible = function isAboutContactVisible() {
            const isAboutContactVisible =
                $scope.brandData.contact.address ||
                $scope.brandData.contact.phone ||
                $scope.brandData.contact.email ||
                $scope.brandData.contact.website ||
                $scope.brandData.contact.facebookUrl ||
                $scope.brandData.contact.twitterUrl ||
                $scope.brandData.contact.instagramUrl ||
                $scope.brandData.contact.pinterestUrl;

            return isAboutContactVisible;
        };

        $rootScope.isAboutMessageVisible = function isAboutMessageVisible() {
            const isAboutMessageVisible =
                $scope.eventData.settings.introduction || $scope.eventData.settings.videoUrl;

            return isAboutMessageVisible;
        };

        $rootScope.isAboutPricingVisible = function isAboutPricingVisible() {
            const isAboutPricingVisible =
                $scope.eventData.settings.hasPriceSheet || $scope.eventData.settings.hasDiscounts;

            return isAboutPricingVisible;
        };

        $rootScope.isAboutVisible = function isAboutVisible() {
            const isAboutVisible =
                ($rootScope.isAboutContactVisible() ||
                    $rootScope.isAboutMessageVisible() ||
                    $rootScope.isAboutPricingVisible()) &&
                !$scope.isPackageSelected();

            return isAboutVisible;
        };

        $scope.isCartVisible = function isCartVisible() {
            const isCartVisible =
                $scope.eventData.settings.hasPriceSheet && !$scope.isPackageSelected();

            return isCartVisible;
        };

        $scope.isStoreEnabled = function isStoreEnabled() {
            return $scope.eventData.settings.enableStore;
        };

        $scope.isExpiredMarketingBanner = function isExpiredMarketingBanner() {
            const { bannerExpirationDate } = $scope.eventData.settings;

            return Boolean(bannerExpirationDate) && new Date(bannerExpirationDate) < new Date();
        };

        $scope.isHomeSecondaryNavVisible = function isHomeSecondaryNavVisible() {
            const isHomeSecondaryNavVisible =
                $scope.numClientTopLevelAlbumsVisible > 0 &&
                $scope.shouldShowAllPhotos &&
                !$scope.isComparing();

            return isHomeSecondaryNavVisible;
        };

        $scope.isLabeledPhotosVisible = function isLabeledPhotosVisible() {
            const isLabeledPhotosVisible =
                $scope.eventData.settings.allowTagPhotos && $scope.hasTags();

            return isLabeledPhotosVisible;
        };

        $scope.pageHasNavigation = function pageHasNavigation() {
            const pageHasNavigation =
                typeof SPClientRoute.getNavigationRoot() === 'string' &&
                !SPClientRoute.isNavigationDisabled() &&
                !$scope.isComparing();

            return pageHasNavigation;
        };

        $scope.showPackageMode = function showPackageMode() {
            const path = SPClientRoute.getPath();
            const isOnLandingPage = path === '/' || path === '/admin';
            const showPackageMode = !isOnLandingPage && isPackageSelected();

            return showPackageMode;
        };

        $scope.isComparing = function isComparing() {
            return spComparePhotos.isComparing();
        };

        $scope.getBrandCurrencyCode = function getBrandCurrencyCode() {
            return $rootScope.brandData.currencyCode;
        };

        $scope.setOpenSinglePhotoAddToCartSidePanel = setOpenSinglePhotoAddToCartSidePanel;

        function setOpenSinglePhotoAddToCartSidePanel(open) {
            $rootScope.openAddToCartSidePanel = open;
        }

        const spPhotoActionsHydrated = spPhotoActions($scope);

        $scope.canAddPhotoToCart = canAddPhotoToCart;

        function canAddPhotoToCart() {
            return spPhotoActionsHydrated.canAddPhotoToCart();
        }

        $scope.toolbarActions.setDownloadDestination =
            spPhotoActionsHydrated.setDownloadDestination;
        $scope.toolbarActions.getRemainingDownloads = spPhotoActionsHydrated.getRemainingDownloads;
        $scope.toolbarActions.cancelDownload = spPhotoActionsHydrated.cancelDownload;

        $scope.setOpenAddToCartSidePanelBuyingAllPhotos = setOpenAddToCartSidePanelBuyingAllPhotos;

        function setOpenAddToCartSidePanelBuyingAllPhotos(openBuyingAllPhotos) {
            $rootScope.openAddToCartSidePanelBuyingAllPhotos = openBuyingAllPhotos;
        }

        $scope.isSubjectToGdpr = isSubjectToGdpr;

        function isSubjectToGdpr() {
            const hasAcceptedTerms = Boolean(spAppData.get('userData').doesAcceptTerms);

            if (hasAcceptedTerms) {
                return false;
            }

            const isSubjectToGdpr = Boolean(spAppData.get('isSubjectToGdpr'));

            if (!isSubjectToGdpr) {
                return false;
            }

            return true;
        }

        $scope.saveEmail = saveEmail;

        function saveEmail(email) {
            spClientCredentials.set({ email });
        }

        $scope.getPathContext = getPathContext;

        function getPathContext() {
            let context = SPClientRoute.getDataContext()?.toLowerCase();
            let contextId = '';

            switch (context) {
                case 'compare':
                    // Try and pull off the album or tag if it exists
                    const pathSplit = $routeParams.from.split('/');
                    // Check for search param
                    const querySplit = pathSplit[0].split('?q=');

                    context = querySplit[0];
                    contextId = pathSplit[1] ?? querySplit[1];
                    break;
                case 'tag':
                    context = 'label';
                    contextId = $routeParams.tagName;
                    break;
                case 'album':
                    contextId = $routeParams.albumId;
                    break;
                case 'search':
                    contextId = $routeParams.q;
                case 'favorites':
                case 'hidden':
                case 'home':
                    break;
            }

            return {
                context,
                contextId
            };
        }

        $rootScope.$on('$routeChangeStart', (_, next, last) => {
            $scope.pathContextBeforeRouteChange = { context: undefined, contextId: undefined };

            if (last && next && typeof SPClientRoute.getDataContext() === 'string') {
                $scope.pathContextBeforeRouteChange = getPathContext();
            }
        });
    }
];
