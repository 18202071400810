import React from 'react';
import { Box, Flex, Heading, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { useForm, useWatch } from 'react-hook-form';
import InfoTooltip from './InfoTooltip';

export interface IDigitalRule {
    name: string;
    downloadLimit: number | null;
    downloadLimitUsed: number | null;
    resolution: number | null;
    id: number;
}

interface IDigitalRules {
    control: ReturnType<typeof useForm>['control'];
    digitalRules: IDigitalRule[];
    register: ReturnType<typeof useForm>['register'];
    translateFilter: SpAngularJs.ITranslateFilter;
}

const DigitalRules: React.FC<IDigitalRules> = ({
    control,
    digitalRules,
    register,
    translateFilter: t
}) => {
    const socialSize = 900;
    const value = useWatch({ control, name: 'selectedDigitalRule' });
    const radioRegister = register('selectedDigitalRule', {
        required: t('selectOption'),
        valueAsNumber: true
    });

    const isDisabled = (downloadLimit: number | null, downloadLimitUsed: number | null) => {
        return Boolean(
            downloadLimit !== null &&
                downloadLimitUsed !== null &&
                downloadLimit - downloadLimitUsed === 0
        );
    };

    return (
        <RadioGroup defaultValue={String(value)}>
            <Stack>
                <React.Fragment>
                    {digitalRules.map(
                        ({ name, downloadLimit, downloadLimitUsed, id, resolution }, index) => {
                            const getPhotoSize = () => {
                                if (resolution === socialSize) {
                                    return t('freeDigitalsSocialSize');
                                } else if (resolution === null) {
                                    return t('freeDigitalsOriginalSize');
                                }

                                return t('error');
                            };

                            const getTipInfo = () => {
                                if (resolution === socialSize) {
                                    return t('freeDigitalsSocialTipInfo');
                                } else if (resolution === null) {
                                    return t('freeDigitalsOriginalTipInfo');
                                }

                                return t('error');
                            };

                            return (
                                <Box
                                    key={id}
                                    as="label"
                                    htmlFor={`digital-rule-radio-${index + 1}`}
                                    borderStyle="solid"
                                    borderRadius="8px"
                                    borderWidth="1px"
                                    borderColor={
                                        value === String(index) ? 'brand.primary' : 'gray.200'
                                    }
                                    cursor="pointer"
                                    width="100%"
                                    padding={4}
                                    margin="0px"
                                    opacity={isDisabled(downloadLimit, downloadLimitUsed) ? 0.5 : 1}
                                    pointerEvents={
                                        isDisabled(downloadLimit, downloadLimitUsed)
                                            ? 'none'
                                            : 'auto'
                                    }
                                >
                                    <Radio
                                        id={`digital-rule-radio-${index + 1}`}
                                        {...radioRegister}
                                        value={String(index)}
                                        colorScheme="brand.primary"
                                        isDisabled={isDisabled(downloadLimit, downloadLimitUsed)}
                                    >
                                        <Heading as="h2" size="md" variant="primary">
                                            {name}
                                        </Heading>
                                    </Radio>
                                    {(resolution === socialSize || resolution === null) && (
                                        <Flex alignItems="center" flexDirection="row">
                                            <Text
                                                marginLeft="20px"
                                                paddingRight="4px"
                                                fontWeight="normal"
                                            >
                                                {getPhotoSize()}
                                            </Text>
                                            <InfoTooltip
                                                infoIconLabel="Info"
                                                tooltipLabel={getTipInfo()}
                                            />
                                        </Flex>
                                    )}
                                    {downloadLimit !== null && downloadLimitUsed !== null && (
                                        <Text marginLeft="20px" fontWeight="normal">
                                            {downloadLimit - downloadLimitUsed} {t('outOf')}{' '}
                                            {downloadLimit} {t('freeDigitalsRemainingDownload')}
                                        </Text>
                                    )}
                                </Box>
                            );
                        }
                    )}
                </React.Fragment>
            </Stack>
        </RadioGroup>
    );
};

export default DigitalRules;
