export const zoomBounds = [-1, 8];

export const viewportPadding = {
    left: 0,
    right: 0,
    top: 90,
    bottom: 90
};

export const pointerActionThreshold = 10;
