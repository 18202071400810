import { useEffect, useRef, useState } from 'react';
import { ResizeObserver } from '@juggle/resize-observer';

/** Provides a bound element's dimensions, updating on resize */
export const useElementDimensions = () => {
    const ref = useRef<HTMLDivElement>(null);
    const [dimensions, setDimensions] = useState<{
        height: number;
        width: number;
    }>();

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            if (ref.current) {
                setDimensions({
                    height: ref.current.offsetHeight,
                    width: ref.current.offsetWidth
                });
            }
        });

        if (ref.current) {
            resizeObserver.observe(ref.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return { dimensions, ref };
};

const TextureImageCache: { [key: string]: HTMLImageElement } = {};

export const useFrameFill = (frameColor?: Nullable<string>, frameImageUrl?: Nullable<string>) => {
    const [frameFill, setFrameFill] = useState<HTMLImageElement | string | null>(null);

    useEffect(() => {
        if (!frameImageUrl) {
            if (frameColor) {
                setFrameFill(frameColor);
            }

            return;
        }

        if (frameImageUrl && frameImageUrl in TextureImageCache) {
            setFrameFill((currentFill) => {
                if (currentFill === TextureImageCache[frameImageUrl]) {
                    return currentFill;
                }

                return TextureImageCache[frameImageUrl];
            });
            return;
        }

        const frameTexture = new Image();
        frameTexture.src = frameImageUrl;

        frameTexture.onload = () => {
            TextureImageCache[frameImageUrl] = frameTexture;
            setFrameFill(frameTexture);
        };
    }, [frameColor, frameImageUrl]);

    return frameFill;
};
