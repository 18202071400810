import debounce from 'lodash.debounce';

/**
 * @ngdoc service
 * @name sp.client.gallery.service:spClientComment
 * @requires $timeout
 * @requires sp.client.common.service:SPCartData

 * @description
 * Handles updating of client comments
 */
export default [
    '$timeout',
    'SPCartData',
    function spClientCredentialsService($timeout, SPCartData) {
        const COMMENT_SAVED_DISPLAY_TIME = 3000;
        const COMMENT_SAVED_TRANSITION_TIME = 300;
        let service = this;
        let debouncedUpdateCartItem;
        let errorCallback;
        let successCallback;
        let commentSavedHideTimeoutPromises = {};

        service.configure = configure;
        service.clientCommentChanged = clientCommentChanged;

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spClientComment#configure
         * @methodOf sp.client.gallery.service:spClientComment
         *
         * @description
         * Sets up handler to call client comment changed function after delay.
         *
         * @param {number} Number of milliseconds to wait before saving comment; optional
         * @param {function=} Callback function to call upon success updating client comment
         * @param {function=} Callback function to call upon error updating client comment
         * @return {function} Change handler function
         */
        function configure(timeout = 500, success = undefined, error = undefined) {
            debouncedUpdateCartItem = debounce(updateCartItem, timeout);
            successCallback = success;
            errorCallback = error;

            return clientCommentChanged;
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spClientComment#clientCommentChanged
         * @methodOf sp.client.gallery.service:spClientComment
         *
         * @description
         * Calls the debounced function after the given timeout value to save client comment.
         *
         * @param {object} BoundItem object for cart item
         * @param {object} Photo object for cart item
         */
        function clientCommentChanged(boundItem, photo) {
            boundItem.clientCommentSaved = false;

            const promise = getCommentSavedHideTimeoutPromise(boundItem);

            if (promise) {
                $timeout.cancel(promise);
            }

            if (debouncedUpdateCartItem) {
                debouncedUpdateCartItem(boundItem, photo);
            }
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:spClientCredentials#clientCommentChanged
         * @methodOf sp.client.gallery.service:spClientComment
         *
         * @description
         * Handles updating the cart item for the given boundItem and photo in order to save the client comment.
         *
         * @param {object} BoundItem object for cart item
         * @param {object} Photo object for cart item
         */
        function updateCartItem(boundItem, photo) {
            const { cartClientComment, clientComment } = boundItem;

            if (clientComment === cartClientComment) {
                if (debouncedUpdateCartItem) {
                    debouncedUpdateCartItem.cancel();
                }

                return;
            }

            SPCartData.addToCart(
                boundItem,
                [photo],
                boundItem.albumId,
                false,
                '',
                true,
                (response) => {
                    boundItem.clientCommentSaved = true;
                    boundItem.cartClientComment = boundItem.clientComment;

                    const hidePromise = $timeout(() => {
                        boundItem.clientCommentSaved = false;

                        unsetCommentSavedHideTimeoutPromise(boundItem);
                    }, COMMENT_SAVED_TRANSITION_TIME + COMMENT_SAVED_DISPLAY_TIME);

                    setCommentSavedHideTimeoutPromise(boundItem, hidePromise);

                    if (successCallback) {
                        successCallback(response);
                    }
                },
                errorCallback
            );
        }

        function getCommentSavedHideTimeoutPromise(boundItem) {
            const { cartItemId } = boundItem;

            return commentSavedHideTimeoutPromises[cartItemId] || undefined;
        }

        function setCommentSavedHideTimeoutPromise(boundItem, promise) {
            const { cartItemId } = boundItem;

            commentSavedHideTimeoutPromises[cartItemId] = promise;
        }

        function unsetCommentSavedHideTimeoutPromise(boundItem) {
            const { cartItemId } = boundItem;

            delete commentSavedHideTimeoutPromises[cartItemId];
        }

        return service;
    }
];
