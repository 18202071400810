import { PACKAGE_EVENTS } from 'Sp/Angular/Events/Client/Gallery';
import { PACKAGE_SMALL_SCREEN_STATES } from 'Sp/Gallery';
import { trackClientEventFromPriceSheetItems } from 'ts/client/common';
import spPackageBuilderTemplate from '../../templates/components/sp-package-builder.nghtml';

/**
 * @ngdoc component
 * @name sp.client.gallery.component:spPackageBuilder
 * @requires $anchorScroll
 * @requires $rootScope
 * @requires sp.client.common.service:SPCartData
 * @requires sp.client.common.provider:SPDataObjects
 * @requires sp.client.common.service:SPModal
 * @requires sp.common.spEventBus
 * @requires sp.newcommon.service:spPackage
 * @requires sp.client.gallery.service:spPackages
 * @requires translateFilter
 *
 * @description
 * Renders a package to be built.
 *
 * @param {object} album Current album selected
 * @param {array<object>} albums current array of all albums
 * @param {array<object>} photos Photos available in the current view
 */
export default {
    bindings: {
        album: '<',
        albums: '<',
        photos: '<'
    },
    template: spPackageBuilderTemplate,
    controller: [
        '$anchorScroll',
        '$rootScope',
        '$timeout',
        'SPCartData',
        'SPDataObjects',
        'SPModal',
        'spEventBus',
        'spPackage',
        'spPackages',
        'translateFilter',
        function spPackageBuilderController(
            $anchorScroll,
            $rootScope,
            $timeout,
            SPCartData,
            SPDataObjects,
            SPModal,
            spEventBus,
            spPackage,
            spPackages,
            translateFilter
        ) {
            const $ctrl = this;
            let selectedPackage;
            let hasItemScrolledToAnchor = false;
            let anchorYOffset = 106;

            $ctrl.PACKAGE_SMALL_SCREEN_STATES = PACKAGE_SMALL_SCREEN_STATES;
            $ctrl.capturedFormData = {
                doesAcceptTerms: null,
                email: null,
                isValid: null
            };
            $ctrl.doDisplayCollectEmail = false;
            $ctrl.footerButtonAction = addToCart;
            $ctrl.footerButtonText = 'addToCart';

            $ctrl.$onChanges = $onChanges;
            $ctrl.$onInit = $onInit;
            $ctrl.addToCart = addToCart;
            $ctrl.buildSmallScreenPackage = buildSmallScreenPackage;
            $ctrl.captureEmailFormData = captureEmailFormData;
            $ctrl.getAllPhotosInAlbumDigital = getAllPhotosInAlbumDigital;
            $ctrl.getItemStatusText = getItemStatusText;
            $ctrl.getPackageItems = getPackageItems;
            $ctrl.getSelectedPackage = spPackages.getSelectedPackage;
            $ctrl.getSelectedPackageItemName = spPackages.getSelectedPackageItemName;
            $ctrl.getSelectedPackageItems = getSelectedPackageItems;
            $ctrl.getSmallScreenStatusText = getSmallScreenStatusText;
            $ctrl.getSmallScreenFooterButtonText = getSmallScreenFooterButtonText;
            $ctrl.getSmallScreenState = spPackages.getSmallScreenState;
            $ctrl.isAPackageItemSelected = isAPackageItemSelected;
            $ctrl.isAPackagePhotoSelected = spPackages.isAPackagePhotoSelected;
            $ctrl.isCropEnabled = isCropEnabled;
            $ctrl.isPackageComplete = isPackageComplete;
            $ctrl.isPackageSingleImage = isPackageSingleImage;
            $ctrl.replacePackageInCart = replacePackageInCart;
            $ctrl.toggleDoDisplayCollectEmail = toggleDoDisplayCollectEmail;

            function $onChanges({ photos }) {
                if (!selectedPackage) {
                    selectedPackage = spPackages.getSelectedPackage();
                }

                if (photos && photos.currentValue) {
                    selectedPackage.items.forEach((item) => {
                        if (spPackage.isShootProofFulfilledAllGalleryDownloadsDigitalItem(item)) {
                            if (spPackages.getAssignedPhoto(item.id, 0)) {
                                return;
                            }

                            assignPhotoToAllGalleryDownloadsDigitalItem(item);
                        }
                    });
                }
            }

            function $onInit() {
                if (selectedPackage.cartItemId) {
                    $ctrl.footerButtonText = 'saveChanges';
                    $ctrl.footerButtonAction = replacePackageInCart;
                }
            }

            function addToCart() {
                spPackages.addToCart({
                    doesAcceptTerms: $ctrl.capturedFormData.doesAcceptTerms,
                    email: $ctrl.capturedFormData.email ? $ctrl.capturedFormData.email : '',
                    onError: ({ errors, needsEmail }) => {
                        $ctrl.doDisplayCollectEmail = needsEmail;
                        $ctrl.errors = errors;
                    },
                    onSuccess: () => {
                        trackClientEventFromPriceSheetItems({
                            eventSlug: 'add_to_cart',
                            eventId: $rootScope.eventData.id,
                            priceSheetItems: selectedPackage.items,
                            currencyCode: $rootScope.brandData.currencyCode,
                            orderPrice: selectedPackage.price,
                            isPackageItem: true
                        });

                        SPModal.open('main', {
                            modalData: {
                                isPackageBeingEdited: Boolean(selectedPackage.cartItemId)
                            },
                            partialDirectory: 'package-success',
                            type: 'dialog'
                        });
                        spPackages.setSmallScreenState(PACKAGE_SMALL_SCREEN_STATES.PHOTO_VIEW);
                    }
                });
            }

            function assignPhotoToAllGalleryDownloadsDigitalItem(item) {
                const { id: itemId } = item;

                if (spPackages.isAPackagePhotoSelected()) {
                    spPackages.selectIndividualPackagePhotoItem(itemId, 0, $ctrl.album);
                } else {
                    spPackages.togglePackagePhoto($ctrl.photos[0], $ctrl.album);
                    spPackages.selectPackageItemWithPhoto(itemId, 0);
                }
            }

            function buildSmallScreenPackage() {
                switch (spPackages.getSmallScreenState()) {
                    case PACKAGE_SMALL_SCREEN_STATES.PHOTO_VIEW:
                        spPackages.setSmallScreenState(PACKAGE_SMALL_SCREEN_STATES.PACKAGE_VIEW);

                        if (hasItemScrolledToAnchor) {
                            return;
                        }

                        const item = spPackages.getTargetPackageItem();

                        if (typeof item !== 'object') {
                            return;
                        }

                        $timeout(
                            function scrollToPhoto() {
                                $anchorScroll.yOffset = anchorYOffset;
                                $anchorScroll(`itemIndex-${item.itemIndex}`);

                                hasItemScrolledToAnchor = true;

                                $rootScope.$digest();
                            },
                            0,
                            false
                        );
                        break;
                    case PACKAGE_SMALL_SCREEN_STATES.PACKAGE_VIEW:
                        setGalleryPhotoSizesAndPosition();

                        hasItemScrolledToAnchor = false;

                        if (!isPackageComplete()) {
                            if (!$ctrl.doDisplayCollectEmail) {
                                spPackages.setSmallScreenState(
                                    PACKAGE_SMALL_SCREEN_STATES.PHOTO_VIEW
                                );
                            }

                            return;
                        }

                        $ctrl.footerButtonAction();

                        break;
                }
            }

            function captureEmailFormData(doesAcceptTerms, email, isValid) {
                $ctrl.capturedFormData = {
                    doesAcceptTerms,
                    email,
                    isValid
                };
            }

            function getAllPhotosInAlbumDigital() {
                const { items } = spPackages.getSelectedPackage();
                const digital = items.find((item) =>
                    spPackage.isShootProofFulfilledAllAlbumDownloadsDigitalItem(item)
                );

                return digital;
            }

            function getItemStatusText() {
                const { items } = selectedPackage;
                const totalCount = spPackage.getPackageItemCount(items, false);
                const allPhotoDigitalCount = items.filter((item) =>
                    spPackage.isShootProofFulfilledAllGalleryDownloadsDigitalItem(item)
                );
                const selectedCount = spPackages.getPackageSelectedItemCount();

                if (
                    !allPhotoDigitalCount.length ||
                    (allPhotoDigitalCount.length && items.length > 1)
                ) {
                    return translateFilter('packageSelectionSummary', {
                        selectedCount,
                        totalCount
                    });
                }
            }

            function getPackageItems() {
                const { items: packageItems = [] } = selectedPackage || {};

                return packageItems;
            }

            function getSelectedPackageItems(isAlbumsArray) {
                let { items } = spPackages.getSelectedPackage();
                let itemsArray = items.filter((item) =>
                    isAlbumsArray
                        ? spPackage.isShootProofFulfilledAllAlbumDownloadsDigitalItem(item)
                        : !spPackage.isShootProofFulfilledAllAlbumDownloadsDigitalItem(item)
                );

                return itemsArray;
            }

            function getSmallScreenFooterButtonText() {
                switch (spPackages.getSmallScreenState()) {
                    case PACKAGE_SMALL_SCREEN_STATES.PHOTO_VIEW:
                        return 'viewPackage';
                    case PACKAGE_SMALL_SCREEN_STATES.PACKAGE_VIEW:
                        if (!isPackageComplete() && !$ctrl.doDisplayCollectEmail) {
                            return 'viewPhotos';
                        }

                        return $ctrl.footerButtonText;
                }
            }

            function getSmallScreenStatusText() {
                switch (spPackages.getSmallScreenState()) {
                    case PACKAGE_SMALL_SCREEN_STATES.PHOTO_VIEW:
                        return getItemStatusText();
                    case PACKAGE_SMALL_SCREEN_STATES.PACKAGE_VIEW:
                        if (spPackages.isAPackagePhotoSelected()) {
                            return 'selectTile';
                        }

                        return getItemStatusText();
                }
            }

            function isAPackageItemSelected() {
                return (
                    Boolean(spPackages.getSelectedPackageItem().itemId) ||
                    spPackages.isSinglePackageItemSelected()
                );
            }

            function isCropEnabled() {
                const { allowCropping = false } = $rootScope.eventData.settings;

                return allowCropping;
            }

            function isPackageComplete() {
                return (
                    spPackages.getPackageSelectedItemCount() ===
                        spPackages.getPackageTotalSelectableQuantity() &&
                    (!$ctrl.doDisplayCollectEmail ||
                        ($ctrl.doDisplayCollectEmail && $ctrl.capturedFormData.isValid))
                );
            }

            function isPackageSingleImage() {
                return spPackage.getUseSingleImageIndicator(selectedPackage);
            }

            function replacePackageInCart() {
                const { packageCartItem } = SPDataObjects.factory('CartItem', {
                    ...selectedPackage,
                    isPackage: true
                });

                return SPCartData.removePackageFromCart(packageCartItem, function onSuccess() {
                    $ctrl.addToCart();
                });
            }

            function setGalleryPhotoSizesAndPosition() {
                spEventBus.broadcast(PACKAGE_EVENTS.PHOTO_LOCATION_RESET);
                spEventBus.emit(PACKAGE_EVENTS.SELECT);
            }

            function toggleDoDisplayCollectEmail() {
                $ctrl.doDisplayCollectEmail = !$ctrl.doDisplayCollectEmail;
            }
        }
    ]
};
