import React, { FC, MouseEventHandler, memo } from 'react';
import { Box, Image } from '@chakra-ui/react';
import type { IPhoto, IPhotoDimensions, ISPPhotoService } from 'ts/client/types';
import type { IUsePhotoActionsProps } from 'ts/common/hooks/usePhotoActions';
import PhotoOverlay from './PhotoOverlay';

interface Props extends IUsePhotoActionsProps {
    /** Returns the URL for a given photo and size */
    getPhotoUrl: ISPPhotoService['getUrl'];
    /** True only if currently viewing the compare page */
    isComparing: boolean;
    /** True only if the photo is in the cart */
    isInCart: boolean;
    /** True only if the contents of this grid item should be rendered */
    isInView: boolean;
    /** True only if two photos have been selected to be compared */
    isReadyToCompare: boolean;
    /** True only if this photo is selected */
    isSelected: boolean;
    /** Called when this photo is clicked */
    onClickPhoto: (photo: IPhoto, goTo?: boolean) => void;
    /** Dimensions for rendering the given photo within the overall grid */
    photoDimensions: IPhotoDimensions;
    /** Angular free digitals service */
    spFreeDigitals: SpAngularJs.ISpFreeDigitals;
    /** Angular translation service */
    translateFilter: SpAngularJs.ITranslateFilter;
}

const handleContextMenu: MouseEventHandler = (event) => event.preventDefault();

const PhotoGridItem: FC<Props> = ({
    getPhotoUrl,
    isBlackAndWhite,
    isComparing,
    isDownloadPossible,
    isFavorite,
    isHidden,
    isInCart,
    isInView,
    isLabeled,
    isPackageBuilderOpen,
    isReadyToCompare,
    isSelected,
    onClickPhoto,
    photo,
    photoActions,
    photoDimensions,
    requiresDownloadDestination,
    spFreeDigitals,
    translateFilter,
    $rootScope
}) => {
    return (
        <Box
            id={`photoId-${photo.id}`}
            data-testid="photoImage"
            cursor="pointer"
            outline="1px solid var(--chakra-colors-gray-100)"
            outlineOffset="-1px"
            position="absolute"
            top={`${photoDimensions.top}px`}
            left={`${photoDimensions.left}px`}
            width={`${photoDimensions.width}px`}
            height={`${photoDimensions.height}px`}
            visibility={isInView ? 'visible' : 'hidden'}
            sx={{ contentVisibility: isInView ? 'visible' : 'hidden' }}
        >
            {isInView && (
                <>
                    <Image
                        alt={photo.filename}
                        src={getPhotoUrl(photo, 'l')}
                        onContextMenu={handleContextMenu}
                        filter={photo.filterBlackAndWhite ? 'grayscale(1)' : undefined}
                        position="absolute"
                        width="100%"
                        height="100%"
                    />
                    <PhotoOverlay
                        isBlackAndWhite={isBlackAndWhite}
                        isComparing={isComparing}
                        isDownloadPossible={isDownloadPossible}
                        isFavorite={isFavorite}
                        isHidden={isHidden}
                        isInCart={isInCart}
                        isLabeled={isLabeled}
                        isPackageBuilderOpen={isPackageBuilderOpen}
                        isReadyToCompare={isReadyToCompare}
                        isSelected={isSelected}
                        onClick={onClickPhoto}
                        photo={photo}
                        photoActions={photoActions}
                        requiresDownloadDestination={requiresDownloadDestination}
                        spFreeDigitals={spFreeDigitals}
                        translateFilter={translateFilter}
                        $rootScope={$rootScope}
                    />
                </>
            )}
        </Box>
    );
};

export default memo(PhotoGridItem);
