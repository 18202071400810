import { PRICE_SHEET_DIGITAL_ITEM_TYPE } from 'Sp/Api';

/**
 * @ngdoc filter
 * @name sp.client.gallery.filter:spPackageItemDetail
 * @function
 * @requires sp.newcommon.service:spPackage
 * @requires translateFilter
 *
 * @description
 * Parses the item detail summary for a package item
 *
 * @param {object} item Package item
 * @param {boolean} displayQuantity Omits quantity when false. Default is true
 * @param {boolean} includeTags Omits HTML when false. Default is true
 *
 * @returns {string} HTML content for an item detail
 */
export default [
    'spPackage',
    'translateFilter',
    function spPackageItemDetailFilter(spPackage, translateFilter) {
        return function withItemDetail(item, displayQuantity = true, includeTags = true) {
            let {
                allGalleryPhotosDownload = {},
                allAlbumPhotosDownload = {},
                group = {},
                individualPhotoDownload = {},
                name,
                subgroup = {},
                type
            } = item;
            let digitalType =
                type === PRICE_SHEET_DIGITAL_ITEM_TYPE ? translateFilter('digitals') : '';
            let quantity = spPackage.getPackageItemQuantity(item);
            const { name: itemGroupName = '' } = group;
            const { name: subgroupName = '' } = subgroup;
            const subgroupNameContent =
                subgroupName && subgroupName !== 'No Size Specified' ? `${subgroupName} | ` : '';
            const itemGroupNameContent = itemGroupName ? `${itemGroupName} ` : '';
            const nameContent = name.length ? `${name} ` : '';

            if (digitalType === itemGroupName) {
                digitalType = '';
            }

            if (allGalleryPhotosDownload.quantity > 0) {
                digitalType = translateFilter('photosInGallery');
                quantity = '-';
            }

            if (allAlbumPhotosDownload.quantity > 0) {
                digitalType = translateFilter('photosInAlbum');
                quantity = '-';
            }

            if (individualPhotoDownload.quantity > 0) {
                digitalType = translateFilter('individualPhotosDigitals');
            }

            const BR_TAG = includeTags ? '<br>' : '';
            const P_CLOSE_TAG = includeTags ? '</p>' : '';
            const P_OPEN_TAG = includeTags ? '<p>' : '';
            const SPAN_CLOSE_TAG = includeTags ? '</span>' : '';
            const SPAN_OPEN_TAG = includeTags ? '<span>' : '';
            const STRONG_CLOSE_TAG = includeTags ? '</strong>' : '';
            const STRONG_OPEN_TAG = includeTags ? '<strong>' : '';
            const itemTextSection = `${SPAN_OPEN_TAG}${digitalType} ${subgroupNameContent} ${itemGroupNameContent}${BR_TAG}${SPAN_OPEN_TAG}${nameContent}${SPAN_CLOSE_TAG}${SPAN_CLOSE_TAG}`;
            const quantityTextSection = `${STRONG_OPEN_TAG}${SPAN_OPEN_TAG}${quantity}${SPAN_CLOSE_TAG}${STRONG_CLOSE_TAG}`;
            const filteredText = displayQuantity
                ? `${P_OPEN_TAG}${quantityTextSection} ${itemTextSection}${P_CLOSE_TAG}`
                : `${P_OPEN_TAG}${itemTextSection}${P_CLOSE_TAG}`;

            return filteredText.trim();
        };
    }
];
