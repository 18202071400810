import React, { FC } from 'react';
import { Download } from 'react-feather';
import ModalHeader from 'ts/common/components/gallery/ModalHeader';

interface IDigitalDownloadRulesHeader {
    hasPin: boolean;
    isDownloadAlbum: boolean;
    isDownloadAll: boolean;
    isDownloadPhoto: boolean;
    translateFilter: SpAngularJs.ITranslateFilter;
}

const DigitalDownloadRulesHeader: FC<IDigitalDownloadRulesHeader> = ({
    hasPin = false,
    isDownloadAlbum = false,
    isDownloadAll = false,
    isDownloadPhoto = false,
    translateFilter: t
}) => {
    const getHeaderText = () => {
        if (isDownloadAlbum) {
            return t('downloadAlbumNoName');
        } else if (isDownloadAll) {
            return t('downloadAll');
        } else if (isDownloadPhoto) {
            return t('downloadPhoto');
        }

        return t('download');
    };

    return (
        <>
            {hasPin ? (
                <ModalHeader
                    modalIcon={Download}
                    headerText={getHeaderText()}
                    headerSubText={t('enterDownloadPin')}
                />
            ) : (
                <ModalHeader modalIcon={Download} headerText={getHeaderText()} />
            )}
        </>
    );
};

export default DigitalDownloadRulesHeader;
