/* eslint-disable func-names, eqeqeq, padding-line-between-statements, guard-for-in */

var directiveName = 'spFilmstrip';
var defaultOptions = {
    transitionDuration: 575
};

if (window.isSmallDevice() || window.isMediumDevice()) {
    defaultOptions.transitionDuration = 275;
}

function hasProp(props) {
    var div = document.createElement('div');
    var hasProp = false;

    angular.forEach(props, function(prop) {
        if (div.style[prop] !== undefined) {
            hasProp = true;
        }
    });

    return hasProp;
}

var support = {
    transform3d: hasProp([
        'perspectiveProperty',
        'WebkitPerspective',
        'MozPerspective',
        'OPerspective',
        'msPerspective'
    ]),
    transform: hasProp([
        'transformProperty',
        'WebkitTransform',
        'MozTransform',
        'OTransform',
        'msTransform'
    ])
};
var DISTANCE_THRESHOLD = 5;
var ANGLE_THREHOLD = 90;

function getEventPositionProperty(event, property) {
    return event.changedTouches ? event.changedTouches[0][property] : event[property];
}

function getEventPosition(event) {
    return {
        x: getEventPositionProperty(event, 'pageX'),
        y: getEventPositionProperty(event, 'pageY')
    };
}

function getPositionDelta(startPosition, endPosition) {
    // sqrt(a^2 + b^2)
    return Math.sqrt(
        Math.pow(startPosition.x - endPosition.x, 2) + Math.pow(startPosition.y - endPosition.y, 2)
    );
}

/**
 * This function was derrived from flipsnap js, due to the fact that getPositionDelta uses
 * Absolute values for the difference between the start and end position, we can never derrive
 * an angle more than 180 degrees
 */
function getPositionDeltaAngle(startPosition, endPosition) {
    var distance = getPositionDelta(startPosition, endPosition);
    var radians = Math.acos((startPosition.y - endPosition.y) / Math.abs(distance));
    var angle = 180 / (Math.PI / radians);

    return angle;
}

function isAngleWithinBounds(startPosition, endPosition) {
    var angle = getPositionDeltaAngle(startPosition, endPosition);

    return angle > 90 - ANGLE_THREHOLD / 2 && angle < 90 + ANGLE_THREHOLD / 2;
}

export default [
    '$window',
    '$timeout',
    '$rootScope',
    'SPPhoto',
    'SPPhotoUri',
    function spFilmstripDirective($window, $timeout, $rootScope, SPPhoto, SPPhotoUri) {
        var windowElement = angular.element($window);

        /**
         * spFilmstripItems: the collection that we're sampling data from
         *
         * spFilmstripFocusItem: the item we're initially focused on, also watches this
         *                          item and when updated refocus's fimstrip
         *
         * spFilmstripNumItems: Number of items to maintain in filmstrip
         *
         * spFilmstripItemWidth: the width of an item in the filmstrip, Used in calculating how many items
         *                          should be maintianed in the filmstrip. Takes priority over NumItems,
         *                          If child nodes are available, we use those instead
         */
        return {
            restrict: 'A',
            scope: {
                photos: '=' + directiveName,
                selectedPhoto: '=' + directiveName + 'SelectedPhoto',
                numberOfPhotos: '@' + directiveName + 'NumberOfPhotos',
                generatePhotoPositions: '=' + directiveName + 'GeneratePhotoPositions',
                onSwipe: '&' + directiveName + 'OnSwipe'
            },
            link: function spFilmstripController($scope, element) {
                var previousPhoto = $scope.selectedPhoto;
                var container = element.find('[sp-filmstrip-photos-container]');
                var photosContainer = container.children().eq(0);
                var pageBackArrow = element.find('.filmstrip-pager-arrow.arrow-left');
                var pageForwardArrow = element.find('.filmstrip-pager-arrow.arrow-right');

                if (pageBackArrow) {
                    pageBackArrow.on('click', bounceLeft);
                }

                if (pageForwardArrow) {
                    pageForwardArrow.on('click', bounceRight);
                }

                // No container element was found
                if (container.length === 0 || photosContainer.length === 0) {
                    return;
                }

                // No number of items in scope
                if (typeof $scope.numberOfPhotos === 'undefined' || isNaN($scope.numberOfPhotos)) {
                    return;
                }

                function buildItemsWindow() {
                    var setOutput = function setOutput(value) {
                        $scope.$parent[directiveName + 'Items'] = value;
                    };

                    if (typeof $scope.photos !== 'object') {
                        return setOutput(false);
                    }

                    // If we have room for more items than we have
                    if (
                        $scope.photos.length === 0 ||
                        $scope.numberOfPhotos >= $scope.photos.length
                    ) {
                        return setOutput($scope.photos);
                    }

                    if (typeof $scope.$index !== 'number') {
                        $scope.$index = 0;
                    }

                    /**
                     * Determine the range of the window
                     */
                    var range = {
                        start: $scope.$index - Math.floor($scope.numberOfPhotos / 2),
                        end: $scope.$index + Math.floor($scope.numberOfPhotos / 2)
                    };

                    if (range.start < 0) {
                        range.start = 0;
                    }

                    if (range.end >= $scope.photos.length) {
                        range = {
                            start: $scope.photos.length - 1 - $scope.numberOfPhotos,
                            end: $scope.photos.length
                        };
                    }

                    // Apply index to range so it can be tracked
                    var newItems = $scope.photos.slice(range.start, range.end);
                    // Compose new array and fake out the indexes so that we align with the original array
                    var newArray = [];

                    newArray.length = $scope.photos.length;
                    newArray.splice.apply(newArray, [range.start, range.end].concat(newItems));

                    return setOutput(newArray);
                }

                function generatePhotoPositions() {
                    if (!$scope.generatePhotoPositions) {
                        return;
                    }

                    var setOutput = function setOutput(value) {
                        $scope.photoPositions = $scope.$parent[
                            directiveName + 'PhotoPositions'
                        ] = value;
                    };

                    if (typeof $scope.photos !== 'object') {
                        return setOutput(false);
                    }

                    var left = 0;

                    setOutput(
                        $scope.photos.map(function(photo, index) {
                            var bounds = {
                                height: 84,
                                padding: 6
                            };
                            var photoDimensions = SPPhoto.getPhotoDimensions(photo, 's', bounds);

                            photoDimensions.left = left;

                            var padding = bounds.padding / 2;

                            if (index > 0) {
                                photoDimensions.left += padding;

                                padding += bounds.padding / 2;
                            }

                            left += photoDimensions.width + padding;

                            return photoDimensions;
                        })
                    );
                }

                $scope.$watch('photos', function(photos) {
                    if (typeof photos !== 'object') {
                        return;
                    }

                    generatePhotoPositions();
                    buildItemsWindow();
                });

                $scope.$watch('selectedPhoto', function(value) {
                    if (value === false) {
                        return;
                    }
                    var newIndex = $scope.photos.indexOf(value);

                    if (newIndex !== -1) {
                        $scope.moveToPhoto(newIndex);
                    }

                    setupBackwardsPager();
                    setupForwardsPager();
                });

                var removeProperty = '';

                function getTransform(position) {
                    return support.transform3d
                        ? 'translate3d(' + position + ', 0, 0)'
                        : 'translate(' + position + ', 0)';
                }

                var photosContainerLastPosition;
                var photosContainerStyles = {};

                function setPhotosContainerStyles(options) {
                    photosContainerStyles = options;
                }

                function applyPhotosContainerStyles() {
                    photosContainer.css(photosContainerStyles);
                }

                photosContainer.on('transitionend', function() {
                    setPosition();
                });

                function setPosition(position, animate) {
                    if (typeof position === 'number') {
                        position = position.toString() + 'px';
                    }

                    if (position === undefined) {
                        position = photosContainerLastPosition;
                    }

                    if (animate) {
                        if (photosContainerStyles.left !== removeProperty) {
                            // Sets proper initial positioning
                            setPhotosContainerStyles({
                                left: removeProperty,
                                transform: getTransform(photosContainerStyles.left),
                                transitionProperty: removeProperty,
                                transitionTimingFunction: removeProperty,
                                transitionDuration: removeProperty
                            });

                            applyPhotosContainerStyles();
                        }

                        // Produces the actual filmstrip slide animation
                        setPhotosContainerStyles({
                            left: removeProperty,
                            transform: getTransform(position),
                            transitionProperty: 'transform',
                            transitionTimingFunction: 'cubic-bezier(0, 0, 0.25, 1)',
                            transitionDuration: defaultOptions.transitionDuration + 'ms'
                        });

                        $timeout(function() {
                            applyPhotosContainerStyles();
                        });
                    } else {
                        setPhotosContainerStyles({
                            left: position,
                            transform: removeProperty,
                            transitionProperty: removeProperty,
                            transitionTimingFunction: removeProperty,
                            transitionDuration: removeProperty
                        });

                        applyPhotosContainerStyles();
                    }

                    photosContainerLastPosition = position;
                }

                function getPhotoPixelPosition(index) {
                    if (typeof $scope.photoPositions === 'object') {
                        var position = -$scope.photoPositions[index].left;

                        // Offset to center
                        position += (container.width() - $scope.photoPositions[index].width) / 2;

                        return position;
                    }

                    return -1 * container.width() * index;
                }

                function getPhotoPosition(index) {
                    if (typeof $scope.photoPositions === 'object') {
                        return getPhotoPixelPosition(index);
                    }

                    return '-' + index * 100 + '%';
                }

                var firstRun = true;

                $scope.moveToPhoto = function moveToPhoto(index, noAnimate) {
                    var animate = true;

                    if (noAnimate === true) {
                        animate = false;
                    }

                    if (firstRun) {
                        firstRun = false;
                        animate = false;
                    }

                    if (element[0].id == 'photoDetailView' && Math.abs($scope.$index - index) > 2) {
                        animate = false;
                    }

                    $scope.$index = index;

                    buildItemsWindow();

                    var position = getPhotoPosition(index);

                    setPosition(position, animate);
                };

                function bounceLeft() {
                    var photoIndex = $scope.photos.indexOf($scope.selectedPhoto);
                    var photoFilmstrip = element.find('#photoFilmstripPhotos');

                    if (photoIndex === 0 && previousPhoto === $scope.selectedPhoto) {
                        photoFilmstrip.addClass('bounce-left');
                    }

                    $timeout(function() {
                        photoFilmstrip.removeClass('bounce-left').removeClass('bounce-right');
                    }, 500);

                    previousPhoto = $scope.selectedPhoto;
                }

                function bounceRight() {
                    var photoIndex = $scope.photos.indexOf($scope.selectedPhoto);
                    var photoFilmstrip = element.find('#photoFilmstripPhotos');

                    if (
                        photoIndex === $scope.photos.length - 1 &&
                        previousPhoto === $scope.selectedPhoto
                    ) {
                        photoFilmstrip.addClass('bounce-right');
                    }

                    $timeout(function() {
                        photoFilmstrip.removeClass('bounce-left').removeClass('bounce-right');
                    }, 500);

                    previousPhoto = $scope.selectedPhoto;
                }

                function setupForwardsPager() {
                    if (pageForwardArrow && $scope.photoPositions) {
                        var startIndex = $scope.photos.indexOf($scope.selectedPhoto);
                        var totalWidth = 0;
                        var canContinuePaging = false;

                        pageForwardArrow.removeAttr('href');

                        for (var i = startIndex; i < $scope.photoPositions.length; i++) {
                            totalWidth += $scope.photoPositions[i].width;

                            if (totalWidth > $window.innerWidth / 2) {
                                pageForwardArrow.attr(
                                    'href',
                                    SPPhotoUri.build($scope.photos[i - 1])
                                );

                                canContinuePaging = true;

                                break;
                            }
                        }

                        if (!canContinuePaging) {
                            pageForwardArrow.attr(
                                'href',
                                SPPhotoUri.build($scope.photos[$scope.photos.length - 1])
                            );
                        }
                    }
                }

                function setupBackwardsPager() {
                    if (pageBackArrow && $scope.photoPositions) {
                        var startIndex = $scope.photos.indexOf($scope.selectedPhoto);
                        var totalWidth = 0;
                        var canContinuePaging = false;

                        pageBackArrow.removeAttr('href');

                        for (var i = startIndex; i >= 0; i--) {
                            totalWidth += $scope.photoPositions[i].width;

                            if (totalWidth > $window.innerWidth / 2) {
                                pageBackArrow.attr('href', SPPhotoUri.build($scope.photos[i + 1]));

                                canContinuePaging = true;

                                break;
                            }
                        }

                        if (!canContinuePaging) {
                            pageBackArrow.attr('href', SPPhotoUri.build($scope.photos[0]));
                        }
                    }
                }

                function onResize() {
                    $scope.$apply(function() {
                        if (typeof $scope.$index === 'number') {
                            $scope.moveToPhoto($scope.$index, true);
                        }
                    });
                }

                windowElement.on('resize', onResize);

                $scope.$on('$destroy', function() {
                    windowElement.off('resize', onResize);
                });

                var state;
                var eventHandles = [];

                function resetState() {
                    /*
                    Reset state.readyToMove to false, until this value is set to true we evaluate
                    if this is a touch+drag event or a click
                    */
                    state = {
                        gesture: false,
                        scrolling: false,
                        startPosition: null,
                        readyToMove: false,
                        startLeftPosition: null
                    };

                    // Remove old events
                    eventHandles.forEach(function(callback) {
                        typeof callback === 'function' && callback();
                    });

                    eventHandles = [];
                }

                function attachEvent(object, eventName, callback, useCapture, delayRemove) {
                    object.addEventListener(eventName, callback, useCapture);

                    var removeEventHandle = function removeEventHandle() {
                        object.removeEventListener(eventName, callback, useCapture);
                    };

                    eventHandles.push(function() {
                        if (delayRemove) {
                            $timeout(removeEventHandle, 200);
                        } else {
                            removeEventHandle();
                        }
                    });
                }

                resetState();

                $window.document.addEventListener('gesturestart', function() {
                    state.gesture = true;
                });

                $window.document.addEventListener('gestureend', function() {
                    state.gesture = false;
                });

                var allowProcessEvent = function allowProcessEvent(shouldBeScrolling) {
                    if (state.gesture) {
                        return false;
                    }

                    return state.scrolling === shouldBeScrolling;
                };

                var getNearestIndex = function getNearestIndex(position, reverseDirectionBias) {
                    var nearestIndex = false;

                    if (typeof $scope.photoPositions === 'object') {
                        // Bias to center
                        position += container.width() / 2;

                        for (var index in $scope.photoPositions) {
                            var leftValue = $scope.photoPositions[index].left;

                            if (leftValue >= position) {
                                nearestIndex = index - (reverseDirectionBias & 1);

                                break;
                            }
                        }
                    } else {
                        // percentage based
                        nearestIndex =
                            Math.ceil(position / container.width()) - (reverseDirectionBias & 1);
                    }

                    if (nearestIndex === false || nearestIndex >= $scope.photos.length) {
                        nearestIndex = $scope.photos.length - 1;
                    }

                    if (nearestIndex < 0) {
                        nearestIndex = 0;
                    }

                    return nearestIndex;
                };

                var touchStart = function touchStart(event) {
                    if (!allowProcessEvent(false)) {
                        return;
                    }

                    resetState();

                    // Establish that we're inside a scroll event
                    state.scrolling = true;
                    state.startPosition = getEventPosition(event);
                    state.startPhotoPosition = getPhotoPixelPosition($scope.$index);

                    // Attach other events to handle movement / end of movement
                    switch (event.type) {
                        case 'mousedown':
                            event.preventDefault();

                            attachEvent($window.document, 'mousemove', touchMove);
                            attachEvent($window.document, 'mouseup', touchEnd);

                            break;
                        case 'touchstart':
                            attachEvent($window.document, 'touchmove', touchMove);
                            attachEvent($window.document, 'touchend', touchEnd);

                            break;
                    }
                };

                // Attach Touch event
                container.get(0).addEventListener('touchstart', touchStart);

                // Attach Mouse event
                container.get(0).addEventListener('mousedown', touchStart);

                // Attach scroll event
                // @@STUB: mouse scrolling

                var touchMove = function touchMove(event) {
                    if (!allowProcessEvent(true)) {
                        return;
                    }

                    event.preventDefault();

                    var currentPosition = getEventPosition(event);
                    var distance = getPositionDelta(state.startPosition, currentPosition);

                    if (!state.readyToMove) {
                        /*
                        We're evaluating here if the user is really intending to drag, or if this is
                        click that should be allowed to bubble down
                        */
                        if (Math.abs(distance) < DISTANCE_THRESHOLD) {
                            return;
                        }

                        /*
                        This event chain is not for us, user is doing something besides
                        scrolling left / right if the user does not stay within the target angle
                        */
                        if (!isAngleWithinBounds(state.startPosition, currentPosition)) {
                            return resetState();
                        }

                        // Woohoo, This event is for me, Its really for me!
                        state.readyToMove = true;

                        // Add click event handle that stops click events from propagating
                        attachEvent(
                            container.get(0),
                            'click',
                            function(event) {
                                event.preventDefault();
                                event.stopPropagation();

                                return false;
                            },
                            true,
                            true
                        );
                    }

                    var xDistance = state.startPosition.x - currentPosition.x;
                    var newPosition = state.startPhotoPosition - xDistance;
                    var firstPosition = getPhotoPixelPosition(0);
                    var lastPosition = getPhotoPixelPosition($scope.photos.length - 1);

                    // Slow down movement by a 3rd at the ends
                    if (newPosition > firstPosition) {
                        newPosition = firstPosition + Math.round((newPosition - firstPosition) / 3);
                    }
                    if (newPosition < lastPosition) {
                        newPosition = lastPosition + Math.round((newPosition - lastPosition) / 3);
                    }

                    setPosition(newPosition, false);

                    var nearestIndex = getNearestIndex(-newPosition, xDistance < 0);

                    $scope.$apply(function() {
                        $scope.$index = nearestIndex;

                        buildItemsWindow();
                    });
                };

                var touchEnd = function touchEnd(event) {
                    if (!allowProcessEvent(true)) {
                        return resetState();
                    }

                    if (state.readyToMove) {
                        event.preventDefault();
                        event.stopPropagation();

                        var currentPosition = getEventPosition(event);
                        var xDistance = state.startPosition.x - currentPosition.x;
                        var newPosition = state.startPhotoPosition - xDistance;
                        var nearestIndex = getNearestIndex(-newPosition, xDistance < 0);

                        $scope.$apply(function() {
                            $scope.moveToPhoto(nearestIndex);

                            var newPhoto = $scope.photos[$scope.$index];

                            if (newPhoto !== $scope.selectedPhoto) {
                                $scope.onSwipe({
                                    photo: newPhoto
                                });
                            }
                        });
                    }

                    resetState();
                };

                var controlStep = 4;

                $scope.$parent[directiveName + 'HasPrevious'] = function() {
                    return $scope.$index !== 0;
                };

                $scope.$parent[directiveName + 'Previous'] = function() {
                    var newIndex = $scope.$index - controlStep;

                    if (newIndex < 0) {
                        newIndex = 0;
                    }

                    $scope.moveToPhoto(newIndex);
                };

                $scope.$parent[directiveName + 'HasNext'] = function() {
                    return $scope.$index < $scope.photos.length - 1;
                };

                $scope.$parent[directiveName + 'Next'] = function() {
                    var newIndex = $scope.$index + controlStep;

                    if (newIndex > $scope.photos.length - 1) {
                        newIndex = $scope.photos.length - 1;
                    }

                    $scope.moveToPhoto(newIndex);
                };
            }
        };
    }
];
