import type { IImage } from 'ts/client/gallery/components';
import { isDigitalCategory, isFramedCategory } from 'ts/common/logic';
import type { GoogleAnalyticsAddToCartData, PushGoogleAnalyticsDataOptions } from './types';

const ALL_ALBUM_PHOTOS = 'all_album_photos';
const ALL_GALLERY_PHOTOS = 'all_gallery_photos';
const INDIVIDUAL_PHOTO = 'individual_photo';
const SELF_FULFILLED_DIGITAL = 'self_fulfilled_digital';

export function getAllBoundItemsInCart(cartItems, packageList) {
    const packageBoundItems = getBoundItemsFromPackages(packageList ?? []);

    const singleBoundItems = (cartItems ?? []).reduce((bounds, cartItem) => {
        return [...bounds, ...cartItem.boundItems];
    }, []);

    return [...singleBoundItems, ...packageBoundItems];
}

export function getBoundItemsFromPackages(packages) {
    const packageCartItems = packages.reduce((cartItems, cartPackage) => {
        return [...cartItems, ...cartPackage.items];
    }, []);

    const packageBoundItems = packageCartItems.reduce((bounds, packageCartItem) => {
        return [...bounds, ...packageCartItem.boundItems];
    }, []);

    return packageBoundItems.map((packageBoundItem) => {
        return { ...packageBoundItem, isInPackage: true };
    });
}

export const trackClientEventFromPriceSheetItems = ({
    eventSlug,
    eventId,
    priceSheetItems,
    orderPrice,
    quantity,
    userSelectedProducts,
    currencyCode = 'USD',
    isPackageItem = false,
    digitalItem
}: PushGoogleAnalyticsDataOptions): void => {
    const isAddingMultipleItemsFromStore =
        userSelectedProducts !== undefined && userSelectedProducts?.length > 0;
    const priceSheetItemsList =
        userSelectedProducts && userSelectedProducts?.length > 0
            ? userSelectedProducts.map((item) => item.priceSheetItem)
            : priceSheetItems && priceSheetItems.length > 0
            ? priceSheetItems
            : [];

    let finalOrderPrice = 0; // used only when isAddingMultipleItemsFromStore
    const ecommerceItems: GoogleAnalyticsAddToCartData[] = priceSheetItemsList.map(
        (priceSheetItem, index) => {
            const itemPrice = priceSheetItem.price ?? 0;
            const itemQuantity =
                userSelectedProducts && userSelectedProducts.length > 0
                    ? userSelectedProducts[index].quantity
                    : 0;
            finalOrderPrice += itemPrice * itemQuantity;

            const newItem = {
                item_id: priceSheetItem.labCatalogProductId,
                item_name: priceSheetItem.group.name,
                affiliation: parseFloat(eventId),
                item_brand: getItemBrand(
                    priceSheetItem.group,
                    priceSheetItem.isDigitalDownload,
                    priceSheetItem.isSelfFulfilled,
                    priceSheetItem.labName
                ),
                item_category: priceSheetItem.group.groupType,
                item_variant: getItemVariant(priceSheetItem),
                product_size: priceSheetItem.subgroup?.name,
                product_option: getProductOptions(
                    priceSheetItem,
                    isPackageItem,
                    digitalItem,
                    isAddingMultipleItemsFromStore
                ),
                product_frame: isFramedCategory(priceSheetItem.group)
                    ? priceSheetItem.name
                    : undefined,
                product_package: isPackageItem ? 'yes' : 'no',
                price: getItemPrice(priceSheetItem, isPackageItem, orderPrice),
                quantity: getItemQuantity(
                    quantity,
                    priceSheetItem,
                    itemQuantity,
                    isPackageItem,
                    isAddingMultipleItemsFromStore
                )
            };
            const filteredItem = Object.fromEntries(
                Object.entries(newItem).filter((pair) => pair[1] !== null && pair[1] !== undefined)
            );

            return filteredItem;
        }
    );

    if (ecommerceItems.length > 0) {
        pushEventToDataLayer(
            currencyCode,
            eventSlug,
            ecommerceItems,
            (isAddingMultipleItemsFromStore ? finalOrderPrice : orderPrice) ?? 0
        );
    }
};

export function trackClientEventFromBoundItems(
    event: string,
    boundItems,
    value: number,
    currencyCode: string,
    eventId: string,
    additionalFields?: object
) {
    const itemsToTrack = boundItems.map((boundItem) => ({
        item_id: boundItem.labCatalogProductId,
        item_name: boundItem.priceSheetItem.group.name,
        affiliation: eventId,
        item_brand: getItemBrand(
            boundItem.priceSheetItem.group,
            boundItem.priceSheetItem.isDigitalDownload,
            boundItem.isSelfFulfilled,
            boundItem.labName
        ),
        item_category: boundItem.priceSheetItem.group.groupType,
        item_variant: getItemVariant(boundItem.priceSheetItem),
        product_size: boundItem.priceSheetItem.boundsName,
        product_option: isDigitalCategory(boundItem.priceSheetItem.group)
            ? getDigitalDownloadProductOptions(boundItem)
            : boundItem.name,
        product_frame: isFramedCategory(boundItem.priceSheetItem.group) ? boundItem.name : null,
        product_package: boundItem.isInPackage ? 'yes' : 'no',
        price: boundItem.unitPrice.toFixed(2),
        quantity: boundItem.quantity
    }));

    pushEventToDataLayer(currencyCode, event, itemsToTrack, value, additionalFields);
}

function pushEventToDataLayer(
    currencyCode: string,
    eventSlug: string,
    items,
    value: number,
    additionalFields?: object
) {
    if (window.dataLayer) {
        window.dataLayer.push({
            event: eventSlug,
            ecommerce: {
                currency: currencyCode,
                value,
                ...(additionalFields && additionalFields),
                items: items
            }
        });
    }
}

function getDigitalDownloadProductOptions(boundItem) {
    if (boundItem.isDigitalDownload && boundItem.usage) {
        const usageFunctions = boundItem.usage;

        if (usageFunctions.album()) {
            return ALL_ALBUM_PHOTOS;
        } else if (usageFunctions.all()) {
            return ALL_GALLERY_PHOTOS;
        } else if (usageFunctions.individual()) {
            return INDIVIDUAL_PHOTO;
        }
    }

    return SELF_FULFILLED_DIGITAL;
}

function getItemBrand(
    group: SpApi.IPriceSheetGroup,
    isDigitalDownload?: boolean,
    isSelfFulfilled?: boolean,
    labName?: Nullable<string>
) {
    if (isDigitalCategory(group)) {
        return isDigitalDownload ? 'ShootProof' : 'Self-fulfilled';
    }

    return isSelfFulfilled ? 'Self-fulfilled' : labName;
}

function getItemVariant(priceSheetItem) {
    if (isDigitalCategory(priceSheetItem.group)) {
        //custom res. have pixels defined and full res. doesnt
        if (priceSheetItem.isDigitalDownload && priceSheetItem.pixels) {
            return priceSheetItem.pixels;
            //if not is full res.
        } else if (priceSheetItem.isDigitalDownload) {
            return 'full_resolution';
        }

        //if is digitals but is not digital download than its self fulfilled digital
        return undefined;
    }

    return undefined;
}

function getProductOptions(
    priceSheetItem,
    isPackageItem: boolean,
    digitalItem,
    isAddingMultipleItemsFromStore: boolean
) {
    if (isDigitalCategory(priceSheetItem.group)) {
        if (priceSheetItem.isDigitalDownload) {
            if (isPackageItem) {
                if (priceSheetItem.allAlbumPhotosDownload.quantity > 0) {
                    return ALL_ALBUM_PHOTOS;
                } else if (priceSheetItem.allGalleryPhotosDownload.quantity > 0) {
                    return ALL_GALLERY_PHOTOS;
                } else if (priceSheetItem.individualPhotoDownload.quantity > 0) {
                    return INDIVIDUAL_PHOTO;
                }
            }

            if (digitalItem) {
                if (digitalItem.is_album) {
                    return ALL_ALBUM_PHOTOS;
                } else if (digitalItem.is_event) {
                    return ALL_GALLERY_PHOTOS;
                } else if (digitalItem.is_individual) {
                    return INDIVIDUAL_PHOTO;
                }
            }

            if (isAddingMultipleItemsFromStore) {
                return INDIVIDUAL_PHOTO;
            }

            return `${priceSheetItem.name}`;
        }

        return SELF_FULFILLED_DIGITAL;
    } else if (priceSheetItem.name) {
        return priceSheetItem.name;
    }

    return undefined;
}

function getItemPrice(priceSheetItem, isPackageItem: boolean, orderPrice) {
    if (isDigitalCategory(priceSheetItem.group)) {
        if (priceSheetItem.isDigitalDownload) {
            if (isPackageItem) {
                if (priceSheetItem.allAlbumPhotosDownload.quantity > 0) {
                    return priceSheetItem.allAlbumPhotosDownload.price;
                } else if (priceSheetItem.allGalleryPhotosDownload.quantity > 0) {
                    return priceSheetItem.allGalleryPhotosDownload.price;
                } else if (priceSheetItem.individualPhotoDownload.quantity > 0) {
                    return priceSheetItem.individualPhotoDownload.price;
                }
            }
        }
    }

    if (priceSheetItem.price) {
        return priceSheetItem.price;
    }

    if (orderPrice) {
        return orderPrice;
    }

    return undefined;
}

function getItemQuantity(
    quantity,
    priceSheetItem,
    itemQuantity,
    isPackageItem: boolean,
    isAddingMultipleItemsFromStore: boolean
) {
    if (quantity) {
        return quantity;
    }

    if (isDigitalCategory(priceSheetItem.group)) {
        if (priceSheetItem.isDigitalDownload) {
            if (isPackageItem) {
                if (priceSheetItem.allAlbumPhotosDownload.quantity > 0) {
                    return priceSheetItem.allAlbumPhotosDownload.quantity;
                } else if (priceSheetItem.allGalleryPhotosDownload.quantity > 0) {
                    return priceSheetItem.allGalleryPhotosDownload.quantity;
                } else if (priceSheetItem.individualPhotoDownload.quantity > 0) {
                    return priceSheetItem.individualPhotoDownload.quantity;
                }
            }
        }
    }

    if (isAddingMultipleItemsFromStore) {
        return itemQuantity;
    }

    if (priceSheetItem.quantity) {
        return priceSheetItem.quantity;
    }

    return undefined;
}

let previousSelectedPriceSheetItemId: number | undefined = undefined;
let previousSelectedQuantity: number | undefined = undefined;
let previousSelectedDigitalItemId: number | undefined = undefined;

export function trackViewItemEvent(
    quantity: number,
    trackingFunctionCallback: () => void,
    priceSheetItemId: number,
    initialPriceSheetItemId?: number,
    selectedPriceSheetItemId?: number,
    digitalItemIdSelected?: number,
    isDigitalDownload?: boolean,
    buyAllImagesState?: IImage[]
) {
    const isFirstItemView =
        selectedPriceSheetItemId === initialPriceSheetItemId &&
        initialPriceSheetItemId === priceSheetItemId &&
        (!previousSelectedPriceSheetItemId ||
            selectedPriceSheetItemId !== previousSelectedPriceSheetItemId);

    const selectedPriceSheetItemChanged =
        selectedPriceSheetItemId &&
        selectedPriceSheetItemId !== previousSelectedPriceSheetItemId &&
        previousSelectedPriceSheetItemId !== undefined;

    if (isFirstItemView) {
        previousSelectedPriceSheetItemId = selectedPriceSheetItemId;
    }

    if (selectedPriceSheetItemChanged) {
        previousSelectedDigitalItemId = undefined;
    }

    const selectedQuantityChanged = quantity !== previousSelectedQuantity;

    if (isDigitalDownload) {
        let isFirstDigitalItemView;

        if (buyAllImagesState?.length && priceSheetItemId === digitalItemIdSelected) {
            isFirstDigitalItemView = true;
            previousSelectedDigitalItemId = undefined;
        } else {
            isFirstDigitalItemView =
                digitalItemIdSelected &&
                priceSheetItemId === digitalItemIdSelected &&
                initialPriceSheetItemId === digitalItemIdSelected &&
                !previousSelectedDigitalItemId;
        }

        const selectedDigitalItemChanged =
            digitalItemIdSelected &&
            digitalItemIdSelected !== previousSelectedDigitalItemId &&
            previousSelectedDigitalItemId !== undefined;

        if (isFirstDigitalItemView) {
            previousSelectedDigitalItemId = digitalItemIdSelected;
        }

        if (isFirstDigitalItemView || selectedDigitalItemChanged) {
            previousSelectedDigitalItemId = digitalItemIdSelected;
            previousSelectedQuantity = quantity;
            trackingFunctionCallback();
        }
    } else if (isFirstItemView || selectedPriceSheetItemChanged || selectedQuantityChanged) {
        previousSelectedPriceSheetItemId = selectedPriceSheetItemId;
        previousSelectedQuantity = quantity;
        trackingFunctionCallback();
    }
}
