import React, { FC, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { useGalleryApiFetch } from 'ts/client/common';
import type { IAlbumAuthResponse } from 'ts/client/gallery/components/types';
import { useTranslate } from 'ts/common/hooks';
import { FetchMethod } from 'ts/common/hooks';
import AlbumPasswordForm from 'client_react/gallery/components/AlbumPasswordForm';
import { useStore } from 'client_react/gallery/hooks/StoreContext';

interface IAlbumPasswordModal {
    albumId: string;
    isOpen: boolean;
    onClose: () => void;
}

const AlbumPasswordModal: FC<IAlbumPasswordModal> = ({ albumId, isOpen, onClose }) => {
    const { setAuthenticatedAlbums, setPathContext } = useStore();
    const t = useTranslate('client.store.albumPasswordModal');

    const [password, setPassword] = useState('');
    const [isInvalid, setIsInvalid] = useState(false);

    const { response, performFetch } = useGalleryApiFetch<IAlbumAuthResponse>('albumauth', {
        defer: true,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: FetchMethod.POST
    });

    const submitPassword = () => {
        if (albumId) {
            performFetch({ body: new URLSearchParams({ album_id: albumId, cr: '1', password }) });
        }
    };

    useEffect(() => {
        if (response?.errors) {
            setIsInvalid(true);
        }

        if (response?.statusText === 'success' && albumId) {
            setIsInvalid(false);
            setPathContext({ context: 'album', contextId: albumId });
            setAuthenticatedAlbums((authenticatedAlbums) => [
                ...authenticatedAlbums,
                Number(albumId)
            ]);
            onClose();
        }
    }, [albumId, onClose, response, setAuthenticatedAlbums, setPathContext]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="md">
            <ModalOverlay />
            <ModalContent data-testid="album-password-modal-content">
                <ModalCloseButton />
                <ModalBody paddingTop="30px">
                    <AlbumPasswordForm
                        errorMessage={t('errorMessage')}
                        height="340px"
                        isInvalid={isInvalid}
                        onChangePassword={setPassword}
                        onClose={onClose}
                        onSubmit={submitPassword}
                        translateFilter={t}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default AlbumPasswordModal;
