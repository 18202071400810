import React, { useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { PackageDetail } from 'ts/client/gallery/components';
import { isPackageItem, sortByDisplayOrder } from 'ts/client/gallery/components/common';
import { GalleryHeading } from 'ts/common/components/gallery';
import { useTranslate } from 'ts/common/hooks';
import { IImage } from '../types';

interface ISelectPackageProps {
    image: IImage;
    onPackageSelected: (
        selectedPackage: SpApi.Client.IPriceSheetItem,
        selectedPhoto: IImage
    ) => void;
    packages: SpApi.Client.IPriceSheetItem[];
}

const SelectPackageSlide: React.FC<ISelectPackageProps> = ({
    image,
    onPackageSelected,
    packages
}) => {
    const t = useTranslate('client.gallery.addToCartSidePanel.selectPackageSlide');

    const packagesSortedByDisplayOrder = useMemo(() => sortByDisplayOrder(packages), [packages]);

    return (
        <Box padding="48px 20px">
            <GalleryHeading
                data-testid="select-package-heading"
                marginBottom="20px"
                size="sm"
                text={t('heading')}
                textAlign="center"
                variant="primary"
            />
            <Flex flexDirection="column" gap="40px">
                {packagesSortedByDisplayOrder.filter(isPackageItem).map((pkg) => (
                    <PackageDetail
                        key={pkg.id}
                        image={image}
                        isSmallView
                        onPackageSelected={onPackageSelected}
                        pkg={pkg}
                    />
                ))}
            </Flex>
        </Box>
    );
};

export default SelectPackageSlide;
