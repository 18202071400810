import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { Package } from 'react-feather';
import ModalHeader from 'ts/common/components/gallery/ModalHeader';
import ModalButtonFooter from './ModalButtonFooter';

interface IExitPackageBuilderModal {
    primaryButtonAction: () => void;
    secondaryButtonAction: () => void;
    translateFilter: SpAngularJs.ITranslateFilter;
}

const ExitPackageBuilderModal: FC<IExitPackageBuilderModal> = ({
    primaryButtonAction,
    secondaryButtonAction,
    translateFilter: t
}) => {
    return (
        <Box>
            <Box paddingBottom="120px">
                <ModalHeader
                    modalIcon={Package}
                    headerText={t('packagesModeWarningHeader')}
                    headerSubText={t('packagesModeWarningText')}
                />
            </Box>
            <ModalButtonFooter
                secondaryButtonAction={secondaryButtonAction}
                primaryButtonAction={primaryButtonAction}
                primaryButtonText={t('exitBuildPackageMode')}
                primaryLoadingText={t('exitBuildPackageMode')}
                secondaryButtonText={t('packagesModeWarningCancel')}
            />
        </Box>
    );
};

export default ExitPackageBuilderModal;
