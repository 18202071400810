import React, { FC, MouseEventHandler } from 'react';
import { Box, BoxProps, Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import type { IContextOptionButton } from 'ts/common/types';

interface Props extends BoxProps {
    /** Holds all the necessery data for the button */
    action: IContextOptionButton;
    /** function to be called on click */
    onClick?: MouseEventHandler<HTMLDivElement>;
}

const ContextSelectButton: FC<Props> = ({ action, onClick, ...rest }) => {
    const isSmallScreen = useBreakpointValue({ base: true, lg: false });

    return (
        <Box
            key={action.key}
            cursor="pointer"
            data-testid="context-select-option-button"
            fontSize="md"
            height="48px"
            letterSpacing={0}
            paddingX="8px"
            onClick={onClick}
            {...rest}
        >
            <Flex
                alignItems="center"
                justifyContent="space-between"
                gap={isSmallScreen ? '0' : '40px'}
            >
                <Flex alignItems="center" maxWidth={isSmallScreen ? '300px' : '500px'}>
                    <Flex height="48px" alignItems="center" minWidth="40px">
                        {action.leftIcon}
                    </Flex>
                    <Text
                        fontSize={isSmallScreen ? '14px' : '16px'}
                        lineHeight="20px"
                        color="inherit"
                    >
                        {action.text}
                    </Text>
                </Flex>
                {action.rightIcon}
            </Flex>
        </Box>
    );
};

export default ContextSelectButton;
