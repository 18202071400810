import { EMAIL_REGEX } from 'Sp/Regex';
import spCollectEmailTemplate from '../../templates/components/sp-collect-email.nghtml';

/**
 * @ngdoc component
 * @name sp.client.gallery.component:spCollectEmail
 *
 * @description
 * Displays an email collection form, including data consent checkbox
 *
 * @param {array} errors List of errors that the email template may have to handle
 * @param {expression=} onFormChange Called with the collected email when the form is valid or null
 *     when the form is invalid as `email`, the GDPR consent (if required) as `doesAcceptTerms`, and
 *     if the form is currently valid as `isValid`
 * @param {function=} submitAction Called when the submit button is clicked
 * @param {string=} submitButtonText Text to display on the submit button
 */
export default {
    template: spCollectEmailTemplate,
    bindings: {
        errors: '<',
        onFormChange: '&?',
        submitAction: '<?',
        submitButtonText: '@?'
    },
    controller: [
        function spCollectEmailController() {
            const $ctrl = this;

            $ctrl.credentials = {};
            $ctrl.email = '';
            $ctrl.emailConfirm = '';
            $ctrl.emailPattern = EMAIL_REGEX.VALID_EMAIL;
            $ctrl.validEmail = false;

            $ctrl.checkForm = checkForm;
            $ctrl.isSubmitDisabled = isSubmitDisabled;
            $ctrl.onSubmit = onSubmit;

            function checkForm(doesAcceptTerms = $ctrl.credentials.doesAcceptTerms) {
                if (!$ctrl.onFormChange) {
                    return;
                }

                const { $valid: isValid } = $ctrl.collectEmail;
                let email = null;

                $ctrl.validEmail = $ctrl.emailPattern.test($ctrl.email);

                if ($ctrl.validEmail && isValid) {
                    email = $ctrl.email;
                }

                $ctrl.onFormChange({
                    doesAcceptTerms,
                    email,
                    isValid:
                        $ctrl.validEmail &&
                        $ctrl.email.toLowerCase() === $ctrl.emailConfirm.toLowerCase()
                });
            }

            function isSubmitDisabled() {
                $ctrl.validEmail = $ctrl.emailPattern.test($ctrl.email);

                return (
                    !$ctrl.validEmail ||
                    !$ctrl.email ||
                    !$ctrl.emailConfirm ||
                    $ctrl.email.toLowerCase() !== $ctrl.emailConfirm.toLowerCase()
                );
            }

            function onSubmit() {
                $ctrl.submitAction($ctrl.email, $ctrl.credentials.doesAcceptTerms);
            }
        }
    ]
};
