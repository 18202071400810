import React, { PropsWithChildren } from 'react';
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    useBreakpointValue
} from '@chakra-ui/react';
import { EmailCaptureForm } from 'ts/client/gallery/components';
import { useTranslate } from 'ts/common';
import { noop } from 'ts/common/utils';
import { useStore } from 'client_react/gallery/hooks/StoreContext';

export type EmailCaptureModalVariants = 'add-to-cart' | 'launch-card-editor';

interface IEmailCaptureModalProps {
    actionFunction: () => void;
    isActionLoading: boolean;
    isOpen: boolean;
    onClose: () => void;
    variant?: EmailCaptureModalVariants;
}

const EmailCaptureModal: React.FC<PropsWithChildren<IEmailCaptureModalProps>> = ({
    actionFunction,
    isActionLoading,
    isOpen,
    onClose,
    variant
}) => {
    const t = useTranslate();
    const { isSubjectToGdpr } = useStore();
    const isSmallScreen = useBreakpointValue({ base: true, lg: false });

    const buttonText =
        variant === 'launch-card-editor'
            ? t('client.gallery.addToCartSidePanel.emailCaptureCardDesignerSlide.startDesigning')
            : t('client.gallery.addToCartSidePanel.emailCaptureAddToCartSlide.addToCart');
    const headerText =
        variant === 'launch-card-editor'
            ? t('client.gallery.addToCartSidePanel.emailCaptureCardDesignerSlide.enterEmail')
            : t('client.gallery.addToCartSidePanel.emailCaptureAddToCartSlide.enterEmail');
    const headerSubText =
        variant === 'launch-card-editor'
            ? t(
                  'client.gallery.addToCartSidePanel.emailCaptureCardDesignerSlide.weWillSaveYourCart'
              )
            : t('client.gallery.addToCartSidePanel.emailCaptureAddToCartSlide.weWillSaveYourCart');

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={isSmallScreen ? 'full' : 'md'}>
            <ModalOverlay data-testid="email-capture-modal-overlay" />
            <ModalContent
                borderRadius={isSmallScreen ? '0' : '20px'}
                data-testid="email-capture-modal"
                padding={isSmallScreen ? '0 20px' : ''}
            >
                <ModalCloseButton
                    _hover={{
                        color: 'brand.primary'
                    }}
                />
                <ModalBody data-testid="email-capture-modal-body">
                    <EmailCaptureForm
                        primaryAction={actionFunction}
                        buttonText={buttonText}
                        headerText={headerText}
                        headerSubText={headerSubText}
                        onClose={onClose}
                        isLoading={isActionLoading}
                        isSubjectToGdpr={isSubjectToGdpr}
                        setEmailSetSuccess={noop}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default EmailCaptureModal;
