import { EMAIL_REGEX } from 'Sp/Regex';
import {
    SESSION_STORAGE_EMAIL_KEY,
    SESSION_STORAGE_ZIP_BUNDLE_KEY
} from 'ts/common/constants/auth';

/**
 * @ngdoc service
 * @name sp.client.gallery.service:spClientCredentials
 * @requires $cookies
 * @requires sp.common.service:spAppData
 *
 * @description
 * Manages storage and retrieval of client credentials
 */
export default [
    '$cookies',
    'spAppData',
    function spClientCredentialsService($cookies, spAppData) {
        const emailCookieName = spAppData.get('emailCookieName');
        const { sessionStorage } = window;
        const storedCredentials = {
            email:
                $cookies.get(emailCookieName) ||
                sessionStorage.getItem(SESSION_STORAGE_EMAIL_KEY) ||
                null,
            readableEmail: sessionStorage.getItem(SESSION_STORAGE_EMAIL_KEY) || null,
            zipBundleKey: sessionStorage.getItem(SESSION_STORAGE_ZIP_BUNDLE_KEY) || null
        };

        return {
            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spClientCredentials#get
             * @methodOf sp.client.gallery.service:spClientCredentials
             *
             * @description
             * Gets the client credentials.
             *
             * @returns {object} Crendentials that have been entered by the client
             */
            get: function get() {
                return angular.copy(storedCredentials);
            },
            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spClientCredentials#set
             * @methodOf sp.client.gallery.service:spClientCredentials
             *
             * @description
             * Sets the client credentials. Stores in memory and in `window.sessionStorage`.
             *
             * @param {object} credentials Credentials entered by client
             * @param {string=} credentials.email Email entered by client
             * @param {string=} credentials.zipBundleKey zipBundle key returend from the created zip bundle
             */
            set: function set({ email, zipBundleKey }) {
                const emailPattern = EMAIL_REGEX.VALID_EMAIL;
                const isValidEmail = emailPattern.test(email);

                if (zipBundleKey) {
                    storedCredentials.zipBundleKey = storedCredentials.zipBundleKey || zipBundleKey;
                    sessionStorage.setItem(SESSION_STORAGE_ZIP_BUNDLE_KEY, zipBundleKey);
                }

                if (email) {
                    storedCredentials.email = storedCredentials.email || email;
                }

                if (isValidEmail) {
                    sessionStorage.setItem(SESSION_STORAGE_EMAIL_KEY, email);
                    storedCredentials.readableEmail = email;
                }
            },
            /**
             * @ngdoc method
             * @name sp.client.gallery.service:spClientCredentials#clear
             * @methodOf sp.client.gallery.service:spClientCredentials
             *
             * @description
             * Clears the stored client credentials.
             */
            clear: function clear() {
                storedCredentials.email = null;
                storedCredentials.readableEmail = null;
                storedCredentials.zipBundleKey = null;

                sessionStorage.removeItem(SESSION_STORAGE_EMAIL_KEY);
            }
        };
    }
];
