/**
 * @ngdoc service
 * @name sp.client.gallery.service:SPMusicData
 *
 * @requires $rootScope
 *
 * @description
 * Handles the music data and current song
 **/
export default [
    '$rootScope',
    function SPMusicDataService($rootScope) {
        const service = this;
        let songId = null;

        Object.assign(service, {
            get,
            getCurrentSongId,
            setCurrentSongId
        });

        return service;

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:SPMusicData#setCurrentSongId
         * @methodOf sp.client.gallery.service:SPMusicData
         *
         * @description
         * Sets the current playing music songs id
         *
         * @param {string} id the current songs id
         */
        function setCurrentSongId(id) {
            songId = id;
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:SPMusicData#getCurrentSongId
         * @methodOf sp.client.gallery.service:SPMusicData
         *
         * @description
         * Gets the current playing music songs id
         */
        function getCurrentSongId() {
            return songId;
        }

        /**
         * @ngdoc method
         * @name sp.client.gallery.service:SPMusicData#get
         * @methodOf sp.client.gallery.service:SPMusicData
         *
         * @description
         * Gets the current eventSettings music data
         */
        function get() {
            const eventSettings = $rootScope.eventData.settings;

            return {
                isMusicAllowed: eventSettings.isMusicAllowed,
                playlistSongs: eventSettings.playlistSongs,
                musicAutoPlay: eventSettings.musicAutoPlay,
                musicTsmAuthToken: eventSettings.musicTsmAuthToken
            };
        }
    }
];
