import React, { useMemo, useState } from 'react';
import { Box, Flex, SystemStyleObject } from '@chakra-ui/react';
import { useResizeObserver } from 'ts/common/hooks/useResizeObserver';

const defaultCategoryStyles: SystemStyleObject = {
    fontFamily: 'Brandon, sans-serif',
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: '5px',

    textAlign: 'center',
    textTransform: 'uppercase',

    // Text inset shadows
    backgroundClip: 'text',
    backgroundColor: 'gray.400',
    color: 'transparent',

    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    wordWrap: 'break-word'
};

interface RenderTextDisplayProps {
    text: string;
}

export const RenderTextDisplay: React.FC<RenderTextDisplayProps> = ({ text }) => {
    const ownRef = React.createRef<HTMLDivElement>();
    const [height, setHeight] = useState(0);
    const { fontSize, shadowBlur, xShadowOffset, yShadowOffset } = useMemo(() => {
        const fontSize = Math.min(Math.max(height * 0.08, 7.5), 46);
        const shadowBlur = Math.max(0.5, fontSize * 0.065);
        const xShadowOffset = 0;
        const yShadowOffset = Math.max(0.25, fontSize * 0.044);

        return {
            fontSize,
            shadowBlur,
            xShadowOffset,
            yShadowOffset
        };
    }, [height]);

    useResizeObserver({
        containerRef: ownRef as React.MutableRefObject<HTMLElement | null> | null,
        callback: (node) => setHeight(node.offsetHeight)
    });

    return (
        <Flex alignItems="center" height="100%" justifyContent="center" ref={ownRef} width={height}>
            <Box
                fontSize={fontSize}
                lineHeight="170%"
                maxWidth="90%"
                sx={defaultCategoryStyles}
                textShadow={`${xShadowOffset}px ${yShadowOffset}px ${shadowBlur}px rgba(255, 255, 255, 0.2)`}
            >
                {text}
            </Box>
        </Flex>
    );
};

RenderTextDisplay.displayName = 'RenderTextDisplay';
