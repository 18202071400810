import React, { FC, useCallback, useState } from 'react';
import { Flex, FormControl, FormErrorMessage, useBreakpointValue } from '@chakra-ui/react';
import { Smartphone } from 'react-feather';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { DOWNLOAD_DESTINATION } from 'Sp/Gallery';
import GooglePhotosConnection from 'ts/client/gallery/components/GooglePhotosConnection';
import { Button } from 'ts/common/components/gallery';
import { ReactComponent as GooglePhotosIcon } from '../../../../public_html/src/svg/common/gallery/google-photos-icon.svg';
import DigitalRules from './DigitalRules';
import { IDigitalRule } from './DigitalRules';

export interface IDigitalDownloadRulesForm {
    canExportToGoogle?: boolean;
    onClose: () => void;
    onSubmit: (digitalRule: IDigitalRule, downloadDestination: string) => void;
    radioOptions: IDigitalRule[];
    spFreeDigitals: SpAngularJs.ISpFreeDigitals;
    translateFilter: SpAngularJs.ITranslateFilter;
}

const DigitalDownloadRulesForm: FC<IDigitalDownloadRulesForm> = ({
    canExportToGoogle = false,
    onClose,
    onSubmit,
    radioOptions,
    spFreeDigitals,
    translateFilter: t
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [destination, setDestination] = useState(DOWNLOAD_DESTINATION.DEVICE);
    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
        register
    } = useForm({ mode: 'onTouched' });

    const handleDeviceSubmission = useCallback(
        ({ selectedDigitalRule }) => {
            const digitalRule = radioOptions[selectedDigitalRule];
            onSubmit(digitalRule, DOWNLOAD_DESTINATION.DEVICE);
            setDestination(DOWNLOAD_DESTINATION.DEVICE);
            setIsLoading(true);
        },
        [onSubmit, radioOptions]
    );

    const handleGooglePhotosSubmission = useCallback(
        ({ selectedDigitalRule }) => {
            const digitalRule = radioOptions[selectedDigitalRule];
            onSubmit(digitalRule, DOWNLOAD_DESTINATION.GOOGLE_PHOTOS);
            setDestination(DOWNLOAD_DESTINATION.GOOGLE_PHOTOS);
            setIsLoading(true);
            onClose();
        },
        [onClose, onSubmit, radioOptions]
    );

    const rulesContainerPaddingBottom = useBreakpointValue({ base: '240px', lg: '16px' });

    const buttonContainerMargin = useBreakpointValue({
        base: '0 -16px',
        md: '0 -64px'
    });

    const buttonContainerPadding = useBreakpointValue({
        base: '30px 16px 24px 16px',
        lg: '30px 40px 30px 40px'
    });

    const buttonContainerPosition = useBreakpointValue({
        base: 'fixed' as const,
        lg: 'sticky' as const
    });

    const buttonContainerWidth = useBreakpointValue({
        base: '100%',
        lg: 'auto'
    });

    const buttonGroupDirection = useBreakpointValue({
        base: 'column' as const,
        lg: 'row' as const
    });

    const buttonGroupGap = useBreakpointValue({ base: '16px', lg: '12px' });

    const googleEmail = spFreeDigitals.getGoogleEmail();

    return (
        <Flex as="form" flexDirection="column">
            <FormControl
                overflow="auto"
                css={{ '&::-webkit-scrollbar': { display: 'none' } }}
                isInvalid={Boolean(errors.selectedDigitalRule)}
                isRequired
                paddingBottom={rulesContainerPaddingBottom}
            >
                <DigitalRules
                    control={control}
                    digitalRules={radioOptions}
                    translateFilter={t}
                    register={register}
                />
                <FormErrorMessage>
                    {errors.selectedDigitalRule && t('selectOption')}
                </FormErrorMessage>
            </FormControl>

            <Flex
                backgroundColor="background"
                bottom={0}
                boxShadow={`0px -2px 8px var(--chakra-colors-boxShadow)`}
                flexDirection="column"
                gap="16px"
                margin={buttonContainerMargin}
                padding={buttonContainerPadding}
                position={buttonContainerPosition}
                role="region"
                width={buttonContainerWidth}
            >
                {googleEmail && (
                    <GooglePhotosConnection
                        disconnectFromGoogle={spFreeDigitals.disconnectFromGoogle}
                        googleEmail={googleEmail}
                        translateFilter={t}
                    />
                )}
                <Flex
                    flexDirection={buttonGroupDirection}
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    gap={buttonGroupGap}
                >
                    {canExportToGoogle ? (
                        <>
                            <Button
                                text={t('client.gallery.downloadDestinations.saveToGooglePhotos')}
                                variant="googlePhotos"
                                fullWidth
                                isDisabled={
                                    !isValid ||
                                    radioOptions.length === 0 ||
                                    (isLoading &&
                                        destination !== DOWNLOAD_DESTINATION.GOOGLE_PHOTOS)
                                }
                                isLoading={isLoading && destination !== DOWNLOAD_DESTINATION.DEVICE}
                                iconSpacing="4"
                                leftIcon={<GooglePhotosIcon />}
                                onClick={handleSubmit(
                                    handleGooglePhotosSubmission as SubmitHandler<FieldValues>
                                )}
                            />
                            <Button
                                text={t('client.gallery.downloadDestinations.myDevice')}
                                variant="primary"
                                isLoading={
                                    isLoading && destination !== DOWNLOAD_DESTINATION.GOOGLE_PHOTOS
                                }
                                isDisabled={
                                    !isValid ||
                                    radioOptions.length === 0 ||
                                    (isLoading && destination !== DOWNLOAD_DESTINATION.DEVICE)
                                }
                                fullWidth
                                iconSpacing="4"
                                loadingText={t('downloading')}
                                leftIcon={<Smartphone />}
                                onClick={handleSubmit(
                                    handleDeviceSubmission as SubmitHandler<FieldValues>
                                )}
                            />
                        </>
                    ) : (
                        <>
                            <Button
                                type="button"
                                fullWidth
                                text={t('cancel')}
                                onClick={onClose}
                                variant="outline primary"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                isDisabled={!isValid || radioOptions.length === 0}
                                text={t('download')}
                                isLoading={isLoading}
                                loadingText={t('downloading')}
                                onClick={handleSubmit(
                                    handleDeviceSubmission as SubmitHandler<FieldValues>
                                )}
                                variant="primary"
                            />
                        </>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default DigitalDownloadRulesForm;
