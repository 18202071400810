let currency: CurrencyFn = (amount, currencySymbol = '$') => `${currencySymbol}${amount}`;

type CurrencyFn = (amount: number, currencySymbol?: string) => string;

export const setCurrencyFn = (currencyFn: CurrencyFn) => {
    currency = currencyFn;
};

const useCurrency = () => {
    return currency;
};

export default useCurrency;
