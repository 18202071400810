import React, { FC, memo, useEffect, useMemo } from 'react';
import { FormLabel, Skeleton, useTheme } from '@chakra-ui/react';
import ReactSelect from 'react-select';
import { useTranslate } from 'ts/common';

interface IAlbumSelect {
    albums: SpApi.Client.IAlbum[];
    isDataLoading: boolean;
    selectedAlbumId?: number;
    updateSelectedAlbumId: (albumId: number) => void;
}

const AlbumSelect: FC<IAlbumSelect> = ({
    albums,
    isDataLoading,
    selectedAlbumId,
    updateSelectedAlbumId
}) => {
    const theme = useTheme();

    const albumsContextOptions = useMemo(() => {
        return albums.map((album) => {
            return {
                value: album.id,
                label: album.albumPath
            };
        });
    }, [albums]);

    useEffect(() => {
        if (selectedAlbumId && albumsContextOptions && albumsContextOptions.length > 0) {
            const optionsListHasSelectedAlbum = albumsContextOptions.some(
                (option) => option.value === selectedAlbumId
            );

            if (!optionsListHasSelectedAlbum) {
                updateSelectedAlbumId(albumsContextOptions[0].value);
            }
        }
    }, [albumsContextOptions, selectedAlbumId, updateSelectedAlbumId]);

    const t = useTranslate('client.store.albumSelect');

    return (
        <>
            <FormLabel
                color="text"
                data-testid="album-select-label"
                fontFamily="open-sans, sans-serif"
                fontSize="md"
                fontWeight="semibold"
                htmlFor="album-select"
                marginBottom="2px"
                marginTop="24px"
            >
                {t('album')}
            </FormLabel>
            <Skeleton isLoaded={!isDataLoading}>
                <ReactSelect
                    inputId="album-select"
                    onChange={(option) => {
                        if (option) {
                            updateSelectedAlbumId(option.value);
                        }
                    }}
                    options={albumsContextOptions}
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            '&:hover': {
                                borderColor: theme.colors.brand.primary,
                                boxShadow: `0 0 0 1px ${theme.colors.brand.primary}`
                            },
                            background: theme.isLightColorScheme
                                ? 'white'
                                : theme.colors.background,
                            borderColor: 'none',
                            boxShadow: 'none'
                        }),
                        menu: (baseStyles) => ({
                            ...baseStyles,
                            background: theme.isLightColorScheme ? 'white' : theme.colors.background
                        }),
                        option: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: state.isSelected
                                ? theme.colors.gray[100]
                                : state.isFocused
                                ? theme.colors.gray[50]
                                : '',
                            color: 'inherit'
                        }),
                        singleValue: (baseStyles) => ({
                            ...baseStyles,
                            color: theme.isLightColorScheme ? '' : 'white'
                        })
                    }}
                    value={albumsContextOptions.find((option) => option.value === selectedAlbumId)}
                />
            </Skeleton>
        </>
    );
};

export default memo(AlbumSelect);
