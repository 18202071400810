import React, { FC } from 'react';
import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
import Button from 'ts/common/components/gallery/Button';
import GalleryHeading from 'ts/common/components/gallery/GalleryHeading';
import StudioHeading from 'ts/common/components/gallery/StudioHeading';
import StudioLogo from '../components/StudioLogo';
import { ILayoutProps, contentAnimation } from './BaseLayout';

const HeadineLayout: FC<Omit<ILayoutProps, 'coverPhoto'>> = ({
    brandName,
    buttonText,
    buttonTextLarge,
    eventBrandLogo,
    galleryTitle,
    logoAltText,
    onClick
}) => {
    const studioLogoSize = useBreakpointValue({ base: 'md' as const, lg: 'lg' as const });

    return (
        <Flex
            alignItems="center"
            flexDirection="column"
            gap={6}
            height="full"
            onClick={onClick}
            paddingX={4}
            paddingY={6}
            textAlign="center"
            width="full"
            animation={contentAnimation}
            opacity={0}
        >
            <Flex alignItems="flex-start" flex={1}>
                {eventBrandLogo ? (
                    <StudioLogo
                        logo={eventBrandLogo}
                        logoAltText={logoAltText}
                        logoSize={studioLogoSize}
                    />
                ) : (
                    <StudioHeading text={brandName} variant="primary" />
                )}
            </Flex>
            {useBreakpointValue({
                base: <GalleryHeading size="sm" text={galleryTitle} variant="primary" />,
                lg: (
                    <Flex alignItems="center" flexDirection="column" gap={7}>
                        <GalleryHeading size="lg" text={galleryTitle} variant="primary" />
                        <Button text={buttonText} textLarge={buttonTextLarge} variant="primary" />
                    </Flex>
                )
            })}
            <Flex alignItems="flex-end" flex={1} width="full">
                {useBreakpointValue({
                    base: (
                        <Button
                            fullWidth
                            text={buttonText}
                            textLarge={buttonTextLarge}
                            variant="primary"
                        />
                    ),
                    lg: <Box />
                })}
            </Flex>
        </Flex>
    );
};

export default HeadineLayout;
