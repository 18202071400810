import spCartItemTemplate from '../../templates/components/sp-cart-item.nghtml';

/**
 * @ngdoc component
 * @name sp.client.gallery.component:spCartItem
 * @requires $window
 * @requires SPCartData
 * @requires SPModal
 * @requires SPPhoto
 * @requires sp.client.gallery.service:spClientComment
 *
 * @description
 * Displays an individual item from the cart
 *
 * @param {boolean} allowClientComment Determines if a client comment can be provided on this cart item
 * @param {boolean} allowCropping Determines if an image can be cropped
 * @param {object} cartItem Item to be displayed
 * @param {function} editCropForBoundItem Edits the crop for an item (legacy)
 * @param {function} editProductForCartItem Edits the crop for an item
 * @param {boolean} hasWatermarks Display or hide a warning that items may be showing a watermark
 * @param {boolean} isPackageItem Display or hide elements if the cart item is a package
 * @param {function} removeBoundItem Removes item from the cart
 * @param {number} totalNumberOfItems Total number of items in the cart
 * @param {function} updateItemQuantity Updates the quantity of the item
 */
export default {
    template: spCartItemTemplate,
    bindings: {
        allowClientComment: '<',
        allowCropping: '<',
        cartItem: '<',
        editCropForBoundItem: '<',
        editProductForCartItem: '<',
        hasWatermarks: '<',
        isPackageItem: '<',
        removeBoundItem: '<',
        totalNumberOfItems: '<',
        updateItemQuantity: '<'
    },
    controller: [
        '$window',
        'spAppData',
        'SPCartData',
        'SPModal',
        'SPPhoto',
        'spClientComment',
        function spCartItemController(
            $window,
            spAppData,
            SPCartData,
            SPModal,
            SPPhoto,
            spClientComment
        ) {
            const $ctrl = this;

            $ctrl.canCrop = canCrop;
            $ctrl.clientCommentChanged = spClientComment.configure();
            $ctrl.getItemPrice = getItemPrice;
            $ctrl.getPhotoUrl = getPhotoUrl;
            $ctrl.isAllGalleryPhotos = isAllGalleryPhotos;
            $ctrl.openWhccEditor = openWhccEditor;
            $ctrl.previewWhccEditorItem = previewWhccEditorItem;
            $ctrl.previewProduct = previewProduct;
            $ctrl.showPerspectiveRender = showPerspectiveRender;
            $ctrl.isAcrylicBlock = isAcrylicBlock;
            $ctrl.getPriceSheetGroupType = getPriceSheetGroupType;

            function canCrop({ crop, whccEditorId }) {
                if (whccEditorId) {
                    return false;
                }

                return $ctrl.allowCropping && typeof crop === 'object';
            }

            function getItemPrice({ quantity, unitPrice }) {
                return unitPrice * quantity;
            }

            function getPhotoUrl(photo, size = 'm') {
                return SPPhoto.getUrl(photo, size);
            }

            function isAllGalleryPhotos() {
                const [boundItem] = $ctrl.cartItem.boundItems;

                return boundItem.usage.all();
            }

            function openWhccEditor() {
                const [{ cartItemId }] = $ctrl.cartItem.boundItems;

                SPCartData.launchEditor(
                    {},
                    null,
                    'cart-item',
                    cartItemId,
                    ({ data: { url } }) => {
                        $window.location.assign(url);
                    },
                    ({ msg: errorMessage }) => {
                        SPModal.open('whcc-editor-open-error', {
                            modalData: { errorMessage }
                        });
                    }
                );
            }

            function previewWhccEditorItem() {
                const [
                    {
                        whccEditorProductImages: { back, front }
                    }
                ] = $ctrl.cartItem.boundItems;

                SPModal.open('whcc-editor-item-preview', {
                    modalData: {
                        hasWatermarks: $ctrl.hasWatermarks,
                        backImageUrl: back,
                        frontImageUrl: front,
                        openWhccEditor
                    },
                    type: 'side-panel'
                });
            }

            function previewProduct(photo, boundItem) {
                SPModal.open('product-preview', {
                    partialDirectory: 'add-to-cart',
                    type: 'side-panel',
                    modalData: {
                        canClose: () => true,
                        openEditor: () =>
                            $ctrl.editProductForCartItem(boundItem, photo, 'no-animation'),
                        photo,
                        boundItem,
                        shouldShowActions: canCrop({ crop: boundItem.crop })
                    }
                });
            }

            function showPerspectiveRender(item) {
                return (
                    item.cartDisplayName.toLowerCase().includes('canvases') || isAcrylicBlock(item)
                );
            }

            function isAcrylicBlock(item) {
                return item.cartDisplayName.toLowerCase().includes('acrylic block');
            }

            function getPriceSheetGroupType(item) {
                if (item.cartDisplayName.toLowerCase().includes('canvases')) {
                    return 'canvas';
                } else if (item.cartDisplayName.toLowerCase().includes('acrylic block')) {
                    return 'acrylic-blocks';
                } else if (item.cartDisplayName.toLowerCase().includes('package')) {
                    return 'packages';
                } else if (item.cartDisplayName.toLowerCase().includes('digital')) {
                    return 'digitals';
                }

                return '';
            }
        }
    ]
};
