import spEventInfoMessageTemplate from '../../templates/components/sp-event-info-message.nghtml';

/**
 * @ngdoc component
 * @name sp.client.gallery.component:spEventInfoMessage
 * @requires $sce
 *
 * @description
 * Renders intro information for an event. Info including headline, intro text or intro video.
 *
 * @param {object} infoData A boolean respresentation of granted consent by form submitter
 * @param {string=} infoData.headline Event intro headline
 * @param {string=} infoData.text Event intro text
 * @param {string=} infoData.videoEmbedCode Embed code for intro video
 * @param {function=} trackAction Event tracking function
 */
export default {
    template: spEventInfoMessageTemplate,
    bindings: {
        infoData: '<',
        trackAction: '<'
    },
    controller: [
        '$sce',
        function spEventInfoMessageController($sce) {
            const ACTION_NAME = 'info-message-view';
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.shouldShowText = shouldShowText;

            function $onInit() {
                $ctrl.trackAction = $ctrl.trackAction || angular.noop;

                $ctrl.trackAction(ACTION_NAME);
                $ctrl.videoEmbedCode = getVideoEmbedCode();
            }

            function getVideoEmbedCode() {
                if (!$ctrl.infoData.videoEmbedCode) {
                    return null;
                }

                const code = angular.element($ctrl.infoData.videoEmbedCode);

                code.addClass('sp-event-info-message-video');
                code.removeAttr('height');
                code.removeAttr('width');

                return $sce.trustAsHtml(
                    angular
                        .element('<div />')
                        .html(code)
                        .html()
                );
            }

            function shouldShowText() {
                return Boolean(!$ctrl.infoData.videoEmbedCode && $ctrl.infoData.text);
            }
        }
    ]
};
