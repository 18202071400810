export default [
    '$rootScope',
    '$scope',
    '$routeParams',
    '$location',
    'SPClientData',
    'SPClientRoute',
    'SPPhoto',
    'SPFavoritePhoto',
    'SPModal',
    'spClientCredentials',
    'spEventTags',
    'spFreeDigitals',
    'spPackages',
    'spPhotoActions',
    function PhotoController(
        $rootScope,
        $scope,
        $routeParams,
        $location,
        SPClientData,
        SPClientRoute,
        SPPhoto,
        SPFavoritePhoto,
        SPModal,
        spClientCredentials,
        spEventTags,
        spFreeDigitals,
        spPackages,
        spPhotoActions
    ) {
        let untaggedPhoto = null;

        $scope.detailPhotoSize = null;

        $scope.closeLabelModal = closeLabelModal;
        $scope.closeAndGoToLabels = closeAndGoToLabels;
        $scope.isPackageItemSelected = isPackageItemSelected;
        $scope.isPackageSelected = isPackageSelected;

        $scope.navigateToPhoto = function navigateToPhoto(photo) {
            if (typeof photo !== 'object' || photo === $scope.detailPhoto) {
                return;
            }

            var newUri = $scope.getPhotoUri(photo);

            $scope.spLocalNavigation(newUri);
        };

        $scope.kioskMode = false;

        $scope.enterKioskMode = function enterKioskMode() {
            if ($scope.kioskMode) {
                return;
            }

            $scope.kioskMode = true;
        };

        $scope.exitKioskMode = function exitKioskMode() {
            if (!$scope.kioskMode) {
                return;
            }

            $scope.kioskMode = false;
        };

        $scope.toggleKioskMode = function toggleKioskMode() {
            if (!$scope.kioskMode) {
                $scope.enterKioskMode();

                return;
            }

            $scope.exitKioskMode();
        };

        $scope.hasFiveOrMoreActions = function hasFiveOrMoreActions() {
            return (
                ($scope.photoActions.canHidePhoto() ? 1 : 0) +
                    ($scope.photoActions.canFavoritePhoto() ? 1 : 0) +
                    ($scope.photoActions.canBandWPhoto() ? 1 : 0) +
                    ($scope.photoActions.canTagPhoto() ? 1 : 0) +
                    ($scope.photoActions.canSharePhoto() ? 1 : 0) +
                    ($scope.photoActions.canDownloadPhoto() ? 1 : 0) >=
                5
            );
        };

        $scope.photoActions = {};

        $scope.photoActions.canHidePhoto = function canHidePhoto() {
            return (
                !isPackageSelected() &&
                !$scope.kioskMode &&
                $scope.userState.isAdminModeEnabled &&
                $scope.eventData.settings.allowHiddenPhotos
            );
        };

        $scope.photoActions.togglePhotoHidden = function togglePhotoHidden(photo, onPhotoHidden) {
            if (!$scope.photoActions.canHidePhoto()) {
                return;
            }

            SPClientData.togglePhotoHidden(photo, onPhotoHidden);
        };

        $scope.photoActions.canFavoritePhoto = function canFavoritePhoto() {
            return !isPackageSelected() && SPPhoto.canFavoritePhoto();
        };

        $scope.photoActions.togglePhotoFavorite = function togglePhotoFavorite(
            photo,
            onPhotoFavorited
        ) {
            if (!$scope.photoActions.canFavoritePhoto()) {
                return;
            }

            if (SPClientRoute.getParentId() === 'Favorites' && photo.isFavorite) {
                removePhotoAndGoToNextPhoto(photo);
            }

            var email = spClientCredentials.get().email;

            /**
             * If user does not have an email set, and one was not passed here, we need to
             * present the modal to collect email address before setting it as a favorite.
             */
            if (!$scope.userState.hasUserEmail && !email) {
                return SPModal.open('favorite-get-email', {
                    partialDirectory: 'photo',
                    modalData: {
                        toggleFavorite: function toggleFavorite(email, doesAcceptTerms) {
                            SPClientData.togglePhotoFavorite(
                                photo,
                                email,
                                $scope.album,
                                doesAcceptTerms,
                                function onSuccess() {
                                    SPModal.close();

                                    if (typeof onPhotoFavorited === 'function') {
                                        onPhotoFavorited();
                                    }
                                },
                                function onError(response) {
                                    SPModal.getScope().errors = response.errors;
                                }
                            );
                        },
                        closeModal: function closeModal() {
                            SPModal.close();
                        }
                    }
                });
            }

            var favoriteOptions = {
                photo: photo,
                album: $scope.album,
                email: email,
                partialDirectory: 'photo'
            };

            SPFavoritePhoto.favoritePhoto(
                favoriteOptions,
                onPhotoFavorited,
                function favoritePhotoErrorCallback(errors) {
                    $scope.errors = errors;
                }
            );
        };

        $scope.photoActions.canBandWPhoto = function canBandWPhoto() {
            return $scope.eventData.settings.allowFiltering;
        };

        $scope.photoActions.togglePhotoBandW = function togglePhotoBandW(photo) {
            if (!$scope.photoActions.canBandWPhoto()) {
                return;
            }

            photo.filterBlackAndWhite = !photo.filterBlackAndWhite;
        };

        $scope.photoActions.canTagPhoto = function canTagPhoto() {
            return (
                !isPackageSelected() &&
                !$scope.kioskMode &&
                $scope.userState.isAdminModeEnabled &&
                $scope.eventData.settings.allowTagPhotos &&
                $scope.hasTags()
            );
        };

        $scope.getEventTag = getEventTag;

        $scope.canTogglePhotoTag = function canTogglePhotoTag(photo, tag) {
            var tagId = tag.id;
            var photoHasTagEnabled = photo.tags.indexOf(tagId) !== -1;
            var currentEventTag = spEventTags.eventTags[tagId];
            var photoAtMaximumCount =
                currentEventTag &&
                currentEventTag.maximumPhotoCount &&
                currentEventTag.photosCount >= currentEventTag.maximumPhotoCount;

            if (!photoHasTagEnabled && photoAtMaximumCount) {
                return false;
            }

            return true;
        };

        const spPhotoActionsHydrated = spPhotoActions($scope);

        $scope.photoActions.showTagModal = function showTagModal(photo, onTagSelected) {
            spPhotoActionsHydrated.showTagModal(photo, onTagSelected);
        };

        $scope.photoActions.setDownloadDestination = spPhotoActionsHydrated.setDownloadDestination;
        $scope.photoActions.getRemainingDownloads = spPhotoActionsHydrated.getRemainingDownloads;
        $scope.photoActions.cancelDownload = spPhotoActionsHydrated.cancelDownload;

        $scope.togglePhotoTag = function togglePhotoTag(photo, tag) {
            if (!$scope.photoActions.canTagPhoto() || typeof tag !== 'object') {
                return;
            }

            if (tag.name === $routeParams.tagName) {
                if (photo.tags.indexOf(tag.id) >= 0) {
                    untaggedPhoto = photo;
                } else {
                    untaggedPhoto = null;
                }
            }

            SPClientData.togglePhotoTag(photo, tag, function onTogglePhotoSuccess(data) {
                var eventTags = data.event_photo_tags;

                spEventTags.togglePhotoTagCount(eventTags);
            });
        };

        $scope.removeUntaggedPhotos = function removeUntaggedPhotos() {
            if (untaggedPhoto) {
                removePhotoAndGoToNextPhoto(untaggedPhoto);
            }
        };

        $scope.photoActions.canSharePhoto = function canSharePhoto() {
            return !isPackageSelected() && $scope.eventData.settings.allowSocialSharing;
        };

        $scope.photoActions.getPhotoShareUrls = SPClientData.getPhotoShareUrls;

        $scope.photoActions.canDownloadPhoto = function canDownloadPhoto() {
            return !isPackageSelected() && spFreeDigitals.canDownloadPhoto();
        };

        $scope.getDownloadTextHeaderKey = function getDownloadTextHeaderKey() {
            return 'downloadPhoto';
        };

        $scope.getDownloadTextProcessingKey = function getDownloadTextProcessingKey() {
            return 'downloadProcessing';
        };

        $scope.photoActions.downloadPhoto = function downloadPhoto(photo) {
            if (!$scope.photoActions.canDownloadPhoto()) {
                return;
            }

            if (typeof photo !== 'object') {
                return;
            }

            return spFreeDigitals.downloadPhoto();
        };

        $scope.photoActions.canAddPhotoToCart = function canAddPhotoToCart() {
            return $scope.eventData.settings.hasPriceSheet && !$scope.isPackageSelected();
        };

        $scope.photoActions.addPhotoToCart = function addPhotoToCart(photo) {
            if (!$scope.photoActions.canAddPhotoToCart()) {
                return;
            }

            $rootScope.openAddToCartSidePanel({
                ...photo,
                url: $rootScope.SPPhoto.getUrl(photo, 'l'),
                editorUrl: $rootScope.SPPhoto.getUrl(photo, '2x')
            });
        };

        $scope.photoActions.canSelectForPackage = function canSelectForPackage() {
            return $scope.isPackageSelected() && $scope.isPackageItemSelected();
        };

        $scope.photoActions.selectForPackage = function selectForPackage(photo) {
            spPackages.resetSelectedPackagePhotos();
            spPackages.togglePackagePhoto(photo, $scope.album);
        };

        $scope.$watch('photos', selectDetailPhoto);

        $scope.$on('SPLocalNavigationUpdate', selectDetailPhoto);

        initialize();

        function initialize() {
            if (window.getDevicePixelRatio() === 1) {
                if (window.isSmallDevice()) {
                    $scope.detailPhotoSize = 'm';
                } else if (window.isMediumDevice()) {
                    $scope.detailPhotoSize = 'l';
                } else {
                    $scope.detailPhotoSize = '2x';
                }
            } else {
                if (window.isSmallDevice()) {
                    $scope.detailPhotoSize = 'l';
                } else if (window.isMediumDevice()) {
                    $scope.detailPhotoSize = '2x';
                } else {
                    $scope.detailPhotoSize = '3x';
                }
            }

            spEventTags.getPhotoTags();
        }

        function getEventTag({ id }) {
            return spEventTags.eventTags[id];
        }

        function isPackageItemSelected() {
            return (
                Boolean(spPackages.getSelectedPackageItem().itemId) ||
                spPackages.isSinglePackageItemSelected()
            );
        }

        function isPackageSelected() {
            return Boolean(spPackages.getSelectedPackage());
        }

        function selectDetailPhoto() {
            if (typeof $scope.photos !== 'object') {
                return;
            }

            $scope.detailPhoto = $scope.getPhotoById($routeParams.photoId);

            // Redirect if detailPhoto cannot be resolved
            if (!$scope.detailPhoto) {
                if (typeof $scope.album === 'object') {
                    var parentUri = SPClientRoute.getParentUri($scope.album.parentAlbumId);

                    $location.url(parentUri);
                } else {
                    $location.url('home');
                }

                return;
            }

            $scope.setTargetPhoto($scope.detailPhoto);

            if (spPackages.getSelectedPackage()) {
                spPackages.selectPackagePhoto($scope.detailPhoto);
            }

            var detailPhotoIndex = $scope.getPhotoIndex($scope.detailPhoto);

            $scope.previousDetailPhoto = $scope.getPhotoByIndex(detailPhotoIndex - 1);
            $scope.nextDetailPhoto = $scope.getPhotoByIndex(detailPhotoIndex + 1);

            if (
                $scope.passedProductSelectionState?.groupId &&
                $scope.photoActions.canAddPhotoToCart()
            ) {
                $scope.photoActions.addPhotoToCart($scope.passedProductSelectionState.foundPhoto);
            }
        }

        function removePhotoAndGoToNextPhoto(photo) {
            $scope.photos.splice($scope.photos.indexOf(photo), 1);

            if ($scope.photos.length >= 1) {
                $scope.navigateToPhoto($scope.nextDetailPhoto || $scope.photos[0]);
            } else {
                $location.url(SPClientRoute.getParentUri());
            }
        }

        function closeLabelModal() {
            SPModal.close();
        }

        function closeAndGoToLabels() {
            SPModal.close();
            $location.path('labels');
        }
    }
];
