import React, { FC } from 'react';
import { Badge, BadgeProps, Box } from '@chakra-ui/react';

interface Props extends BadgeProps {
    /** The number to be shown inside the badge */
    count?: number;
}

const CountBadge: FC<Props> = ({ children, count = 0, ...rest }) => {
    const isLargeCount = count > 99;

    return (
        <Box position="relative">
            {children}
            {count > 0 && (
                <Badge
                    alignItems="center"
                    background="brand.secondary"
                    borderColor="background"
                    borderRadius={isLargeCount ? '40%' : '50%'}
                    borderStyle="solid"
                    borderWidth="1px"
                    color="background"
                    display="flex"
                    fontFamily="open-sans"
                    fontSize="10px"
                    fontWeight="normal"
                    height="16px"
                    justifyContent="center"
                    padding={0}
                    position="absolute"
                    right="-12px"
                    top="-4px"
                    width={isLargeCount ? '22px' : '16px'}
                    {...rest}
                >
                    {isLargeCount ? '99+' : count}
                </Badge>
            )}
        </Box>
    );
};

export default CountBadge;
