import React, { useEffect, useState } from 'react';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { withTheme } from 'sp-ui';
import { useTranslate } from 'ts/common';
import { Button } from 'ts/common/components/gallery';
import { ProductEdit } from 'ts/common/components/product';
import type { FrameProps } from 'ts/common/components/product/types';
import type { ICrop } from 'ts/common/types';
import Footer from './add-to-cart-side-panel/Footer';
import type { IImage } from './types';

interface IEditCropSlideProps {
    crop: Nullable<ICrop>;
    handleCanvasCrop?: (crop: ICrop) => void;
    hideFooter?: boolean;
    image: IImage;
    onCropSet: (crop: ICrop) => void;
    priceSheetItem: Nullable<SpApi.Client.IPriceSheetItem>;
}

const EditCropSlide: React.FC<IEditCropSlideProps> = ({
    crop,
    handleCanvasCrop,
    hideFooter = false,
    image,
    onCropSet,
    priceSheetItem
}) => {
    const [editedCrop, setEditedCrop] = useState<Nullable<ICrop>>(crop);
    const t = useTranslate('client.gallery.editCropSlide');
    const isMediumScreenOrLarger = useBreakpointValue({ base: false, md: true });

    const frameProps: FrameProps | undefined = priceSheetItem
        ? {
              frameColor: priceSheetItem.frameColor ?? null,
              frameOverlap: priceSheetItem.frameOverlap ?? null,
              frameWidth: priceSheetItem.frameWidth ?? null,
              frameImageUrl: priceSheetItem.productMasks?.frameImageUrl,
              matColor: priceSheetItem.matColor ?? null,
              matOverlap: priceSheetItem.matOverlap ?? null,
              matHeight: priceSheetItem.matHeight ?? null,
              matWidth: priceSheetItem.matWidth ?? null
          }
        : undefined;

    useEffect(() => {
        setEditedCrop(crop);
    }, [crop]);

    useEffect(() => {
        if (handleCanvasCrop && editedCrop) {
            handleCanvasCrop(editedCrop);
        }
    }, [editedCrop, handleCanvasCrop]);

    if (!crop || !priceSheetItem) {
        return null;
    }

    const disableRotating = priceSheetItem.group.groupType === 'ornaments';

    return (
        <Box paddingBottom="80px" height="100%">
            <ThemedProductEdit
                disableRotating={disableRotating}
                frame={frameProps}
                item={{
                    ...priceSheetItem,
                    crop: editedCrop,
                    productMasks: priceSheetItem.productMasks
                }}
                photo={image}
                photoUrl={image.editorUrl ?? image.url}
                onUpdate={setEditedCrop}
            />
            {!hideFooter && (
                <Footer alignItems="center" background="modalBackground" justifyContent="right">
                    <Button
                        data-testid="crop-set-button"
                        fullWidth={!isMediumScreenOrLarger}
                        onClick={() => {
                            if (editedCrop) {
                                onCropSet(editedCrop);
                            }
                        }}
                        text={t('setCropButton')}
                        variant="primary"
                    />
                </Footer>
            )}
        </Box>
    );
};

const ThemedProductEdit = withTheme(ProductEdit);

export default EditCropSlide;
