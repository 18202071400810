import { useCallback, useEffect } from 'react';
import PRICESHEET_GROUP_TYPES from 'Sp/PriceSheet/Group';
import { isSingleItem } from 'ts/client/gallery/components/common';
import {
    getCanvasFaceDimensions,
    getLongestSideForProductSize,
    getScaleInBackground,
    getSize
} from 'ts/common';
import { isCustomCategory, isFramedCategory } from 'ts/common/logic';
import useClientApiFetch from './useClientApiFetch';

let scenes: SpApi.Client.IScene[] = [];

const useScenes = () => {
    const { performFetch: getScenes, response: scenesResponse } = useClientApiFetch<
        IApiListResponse<SpApi.Client.IScene>
    >('scene', {
        defer: true
    });

    useEffect(() => {
        if (scenes.length === 0) {
            getScenes();
        }
    }, [getScenes]);

    if (scenesResponse?.status === 200) {
        scenes = scenesResponse.items;
    }

    const getScenesForProduct = useCallback(
        (priceSheetItem: SpApi.Client.IPriceSheetItem, priceSheet?: SpApi.Client.IPriceSheet) => {
            const size = isSingleItem(priceSheetItem) ? getSize(priceSheetItem.size) : null;

            // adding the isCustomCategory condition will disable scene renders for items under "custom" categories
            if (!size || isCustomCategory(priceSheetItem.group) || !priceSheetItem.canHaveScenes) {
                return [];
            }

            const longestSide = getLongestSideForProductSize(
                priceSheetItem.group.groupType === PRICESHEET_GROUP_TYPES.TYPE.CANVAS
                    ? getCanvasFaceDimensions(size)
                    : size
            );

            // if the lab is not GTA (i.e. labCatalogId 23), the height is represented by size.width
            // This is due to the way the labs specify their bounds data to be setup and represented in
            // our system. It is counterintuitive, but this reversal is intentional!
            const height = priceSheet?.labCatalogId === 23 ? size.height : size.width;

            const scenesForSize = scenes.filter((scene) => {
                return (
                    (scene.groupType === null ||
                        scene.groupType === undefined ||
                        scene.groupType === priceSheetItem.group.groupType) &&
                    (scene.labCatalogId === null ||
                        scene.labCatalogId === undefined ||
                        scene.labCatalogId === priceSheet?.labCatalogId) &&
                    (scene.minHeight === null || scene.minHeight === undefined
                        ? longestSide > scene.minSize
                        : height > scene.minHeight && longestSide > scene.minSize) &&
                    (scene.maxHeight === null || scene.maxHeight === undefined
                        ? longestSide <= scene.maxSize
                        : height <= scene.maxHeight && longestSide <= scene.maxSize)
                );
            });

            const filterGroupTypeAndLabCatalog = scenesForSize.some(
                (scene) =>
                    scene.groupType === priceSheetItem.group.groupType &&
                    scene.labCatalogId === priceSheet?.labCatalogId
            );

            const minHeightExists = scenesForSize.some((scene) => scene.minHeight);
            const maxHeightExists = scenesForSize.some((scene) => scene.maxHeight);

            const customScenes = scenesForSize.filter((scene) => {
                return (
                    (filterGroupTypeAndLabCatalog
                        ? scene.groupType === priceSheetItem.group.groupType &&
                          scene.labCatalogId === priceSheet?.labCatalogId
                        : scene.groupType === priceSheetItem.group.groupType ||
                          scene.labCatalogId === priceSheet?.labCatalogId) &&
                    (minHeightExists ? scene.minHeight : !scene.minHeight) &&
                    (maxHeightExists ? scene.maxHeight : !scene.maxHeight)
                );
            });

            // If this is a framed category, and the selected item has a scene override, then don't show any scenes.
            // We use these scene overrides to show tabletop scenes for easel-backed framed items, instead of wall scenes.
            // However, these tabletop scenes were cobbled together by engineers and they look subpar.
            // Once we have real designed tabletop scenes, we can remove this logic.
            if (isFramedCategory(priceSheetItem.group) && customScenes.length > 0) {
                return [];
            }

            const scenesForProduct = (customScenes.length > 0 ? customScenes : scenesForSize).map(
                (scene) => {
                    return {
                        ...scene,
                        backgroundUrl: scene.url,
                        scaleInBackground:
                            (scene.scaleInBackground ?? 1) *
                            getScaleInBackground(
                                size,
                                priceSheetItem.group.groupType ===
                                    PRICESHEET_GROUP_TYPES.TYPE.CANVAS
                            )
                    };
                }
            );

            return scenesForProduct;
        },
        []
    );

    const getDefaultSceneForProduct = useCallback(
        (priceSheetItem: SpApi.Client.IPriceSheetItem) => {
            const size = isSingleItem(priceSheetItem) ? getSize(priceSheetItem.size) : null;

            return {
                backgroundUrl: undefined,
                dropShadow: true,
                scaleInBackground:
                    size === null
                        ? 0.9
                        : getScaleInBackground(
                              size,
                              priceSheetItem.group.groupType === PRICESHEET_GROUP_TYPES.TYPE.CANVAS
                          )
            };
        },
        []
    );

    const getAllScenesForProduct = useCallback(
        (priceSheetItem: SpApi.Client.IPriceSheetItem, priceSheet?: SpApi.Client.IPriceSheet) => {
            const defaultScene = getDefaultSceneForProduct(priceSheetItem);

            const productScenesInBackground = getScenesForProduct(priceSheetItem, priceSheet);

            return [defaultScene, ...productScenesInBackground];
        },
        [getDefaultSceneForProduct, getScenesForProduct]
    );

    return {
        getAllScenesForProduct,
        getDefaultSceneForProduct
    };
};

export default useScenes;
