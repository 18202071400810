import React, { FC } from 'react';
import { Box } from 'sp-ui';
import { IPerspectiveRenderCommonProps } from './PerspectiveRender.types';

export interface ICanvasboxProps extends IPerspectiveRenderCommonProps {
    boxShadowOffset: number;
    bleedPixels: number;
    cropWidthPixels: number;
    cropHeightPixels: number;
}

export const CanvasRender: FC<ICanvasboxProps> = ({
    isBlackAndWhite,
    photoUrl,
    frontFaceWidthPixels,
    frontFaceHeightPixels,
    topFaceHeightPixels,
    boxShadowOffset,
    totalCropLeftPixels,
    totalCropTopPixels,
    depthPixels,
    bleedPixels,
    cropWidthPixels,
    cropHeightPixels
}) => {
    const frontFaceLeftPixels = totalCropLeftPixels + bleedPixels + depthPixels;
    const frontFaceTopPixels = totalCropTopPixels + bleedPixels + depthPixels;
    const perspectiveBoxShadow = `0 ${boxShadowOffset}px ${boxShadowOffset}px rgba(0, 0, 0, 0.25)`;
    const leftFaceWidthPixels = depthPixels;
    const leftFaceHeightPixels = cropHeightPixels;
    const leftFaceLeftPixels = totalCropLeftPixels + bleedPixels;
    const leftFaceTopPixels = totalCropTopPixels + bleedPixels + depthPixels;
    const leftFaceTranslateX = depthPixels / 2;
    const topFaceWidthPixels = cropWidthPixels;
    const topFaceLeftPixels = totalCropLeftPixels + bleedPixels + depthPixels;
    const topFaceTopPixels = totalCropTopPixels + bleedPixels;

    return (
        <>
            <Box
                position={'absolute'}
                backgroundImage={photoUrl}
                width={`${frontFaceWidthPixels}px`}
                height={`${frontFaceHeightPixels}px`}
                backgroundPosition={`-${frontFaceLeftPixels}px -${frontFaceTopPixels}px`}
                boxShadow={perspectiveBoxShadow}
                transform={`translateZ(${depthPixels / 2}px)`}
                filter={isBlackAndWhite ? 'brightness(75%) grayscale(1) ' : ''}
            />
            <Box // Left Face
                position={'absolute'}
                backgroundImage={photoUrl}
                width={`${leftFaceWidthPixels}px`}
                height={`${leftFaceHeightPixels}px`}
                backgroundPosition={`-${leftFaceLeftPixels}px -${leftFaceTopPixels}px`}
                transform={`translateX(-${leftFaceTranslateX}px) rotateY(-90deg)`}
                filter={isBlackAndWhite ? 'brightness(75%) grayscale(1) ' : ''}
                boxShadow={perspectiveBoxShadow}
                boxSizing={'border-box'}
            />
            <Box // top face
                position={'absolute'}
                backgroundImage={photoUrl}
                width={`${topFaceWidthPixels}px`}
                height={`${topFaceHeightPixels}px`}
                boxSizing={'border-box'}
                backgroundPosition={`-${topFaceLeftPixels}px -${topFaceTopPixels}px`}
                transform={`rotateX(90deg) translateZ(${depthPixels / 2}px)`}
                filter={isBlackAndWhite ? 'brightness(75%) grayscale(1) ' : ''}
            />
        </>
    );
};
