import React, { useEffect } from 'react';
import { ResizeObserver } from '@juggle/resize-observer';

interface ResizeObserverProps {
    callback: (arg: HTMLElement) => void;
    containerRef: React.MutableRefObject<HTMLElement | null> | null;
}

export const useResizeObserver = (props: ResizeObserverProps) => {
    const { containerRef, callback } = props;
    useEffect(() => {
        if (containerRef === null) {
            return;
        }

        const resizeObserver = new ResizeObserver(() => {
            // Using requestAnimationFrame prevents "Error: ResizeObserver loop completed with undelivered notifications."
            window.requestAnimationFrame(() => {
                if (containerRef.current) {
                    callback(containerRef.current);
                }
            });
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [callback, containerRef]);
};
