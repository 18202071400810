import React, { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';
import { useEvent } from 'ts/client/common';
import { IImage } from 'ts/client/gallery/components';
import { convertIEventPhotosToIImages } from 'ts/client/gallery/components/common';
import { useTranslate } from 'ts/common';
import { LoadingIndicator } from 'ts/common/components';
import { PhotoGrid } from 'ts/common/components';
import { EmptyState } from 'ts/common/components/gallery';
import { pages } from 'ts/common/components/gallery/EmptyState';
import { useStore, useStoreModal } from 'client_react/gallery/hooks';
import { SelectPhotoOverlay } from '.';

interface ISelectPhotosSlide {
    handleSelectPhotos: (selectedPhotos: IImage[]) => void;
    handleDeselectPhoto: (removedPhoto: IImage) => void;
    page: typeof pages[number];
}

const SelectPhotosSlide: React.FC<ISelectPhotosSlide> = ({
    handleDeselectPhoto,
    handleSelectPhotos,
    page
}) => {
    const { settings } = useEvent();
    const { arePhotosLoading } = useStoreModal();
    const {
        photos,
        selectedPriceSheetItem,
        selectedPriceSheetGroup,
        setUserSelectedPhotos,
        userSelectedPhotos
    } = useStore();

    const t = useTranslate();

    const isBuyingCanvas = selectedPriceSheetGroup?.groupType === 'canvas';
    const isSelfFulfilledDigital =
        selectedPriceSheetItem &&
        selectedPriceSheetItem.type === 'price-sheet-item-digital' &&
        !selectedPriceSheetItem?.isDigitalDownload;
    const [modalBody, setModalBody] = useState<Nullable<HTMLElement>>(null);
    const imagesForSelection = convertIEventPhotosToIImages(photos);

    const isLimitedPhotoSelectionActive = useMemo(() => {
        return (isBuyingCanvas && userSelectedPhotos.length >= 1) || isSelfFulfilledDigital;
    }, [isBuyingCanvas, isSelfFulfilledDigital, userSelectedPhotos]);

    useEffect(() => {
        setModalBody(document.querySelector<HTMLElement>('.chakra-modal__body'));
    }, []);

    const isPhotoSelected = useCallback(
        (photo: IImage) => {
            return userSelectedPhotos.some((selectedPhoto) => selectedPhoto.id === photo.id);
        },
        [userSelectedPhotos]
    );

    const togglePhotoSelected = useCallback(
        (photo: IImage) => {
            if (isLimitedPhotoSelectionActive) {
                setUserSelectedPhotos([photo]);
                handleSelectPhotos([photo]);
            } else {
                if (isPhotoSelected(photo)) {
                    const updatedSelectedPhotos = userSelectedPhotos.filter(
                        (selectedPhoto) => selectedPhoto.id !== photo.id
                    );
                    setUserSelectedPhotos(updatedSelectedPhotos);
                    handleDeselectPhoto(photo);
                } else {
                    setUserSelectedPhotos([...userSelectedPhotos, photo]);
                    handleSelectPhotos([photo]);
                }
            }
        },
        [
            handleDeselectPhoto,
            handleSelectPhotos,
            isLimitedPhotoSelectionActive,
            isPhotoSelected,
            setUserSelectedPhotos,
            userSelectedPhotos
        ]
    );

    const getOverlayProps = useCallback(
        (photo: IImage): ComponentProps<typeof SelectPhotoOverlay> => {
            return {
                isSelected: isPhotoSelected(photo),
                onClick: (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    togglePhotoSelected(photo);
                }
            };
        },
        [isPhotoSelected, togglePhotoSelected]
    );

    if (arePhotosLoading) {
        return <LoadingIndicator squareClassName="theme-background-color-primary" />;
    }

    return imagesForSelection.length ? (
        <PhotoGrid
            PhotoOverlayComponent={SelectPhotoOverlay}
            containerElement={modalBody}
            getPhotoOverlayProps={getOverlayProps}
            layoutType={settings.viewType}
            onPhotoClick={togglePhotoSelected}
            photos={imagesForSelection}
            thumbnailGutterWidth={settings.thumbnailGutterWidth}
            thumbnailSize={settings.thumbnailSize}
        />
    ) : (
        <EmptyState page={page} translateFilter={t} />
    );
};

export default React.memo(SelectPhotosSlide);
