import React, { useMemo } from 'react';
import { Center } from '@chakra-ui/react';
import { Heart, ShoppingBag } from 'react-feather';
import { BAndWEnabledIcon, EyeOffEnabledIcon, TagEnabledIcon } from 'ts/common/components/icons';

type PhotoIndicatorProps = {
    size: 'sm' | 'lg';
    kind: 'bw' | 'cart' | 'favorite' | 'hidden' | 'label';
};

const PhotoIndicator = ({ size = 'lg', kind }: PhotoIndicatorProps) => {
    const isSmall = size === 'sm';

    const icon = useMemo(() => {
        switch (kind) {
            case 'bw':
                return <BAndWEnabledIcon viewBox={isSmall ? '-3 -2 23 23' : undefined} />;
            case 'cart':
                return <ShoppingBag width={isSmall ? 14 : 18} height={isSmall ? 14 : 18} />;
            case 'favorite':
                return <Heart fill="white" width={isSmall ? 14 : 18} height={isSmall ? 14 : 18} />;
            case 'hidden':
                return <EyeOffEnabledIcon viewBox={isSmall ? '-3 -2 22 22' : '-1 0 18 18'} />;
            case 'label':
                return <TagEnabledIcon viewBox={isSmall ? '-3 -3 20 20' : '-1 -1 16 16'} />;
        }
    }, [kind, isSmall]);

    return (
        <Center
            backgroundColor="blackAlpha.700"
            color="white"
            borderRadius="full"
            width={isSmall ? '20px' : '28px'}
            height={isSmall ? '20px' : '28px'}
        >
            {icon}
        </Center>
    );
};

export default PhotoIndicator;
