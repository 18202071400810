import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
    Box,
    Drawer as DrawerBase,
    DrawerBody as DrawerBodyBase,
    DrawerCloseButton,
    DrawerContent,
    DrawerOverlay,
    DrawerProps
} from '@chakra-ui/react';
import { ResizeObserver } from '@juggle/resize-observer';

interface IDrawerBodyProps {
    children?: React.ReactNode;
}

/**
 * A Chakra DrawerBody with a height that smoothly transitions as its content's height changes.
 * It wraps its children with a div and watches that div for size changes to set its own height.
 * This enables using a CSS transition on height, which doesn't work when the height is auto.
 */
const DrawerBody: FC<IDrawerBodyProps> = ({ children }) => {
    const childrenWrapperRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState<number>();

    const updateHeight = useCallback(() => {
        if (childrenWrapperRef.current) {
            setHeight(childrenWrapperRef.current.offsetHeight);
        }
    }, []);

    useEffect(() => {
        updateHeight();

        let resizeObserver: ResizeObserver | undefined;

        if (childrenWrapperRef.current) {
            resizeObserver = new ResizeObserver(updateHeight);
            resizeObserver.observe(childrenWrapperRef.current);
        }

        return () => {
            resizeObserver?.disconnect();
        };
    }, [updateHeight]);

    return (
        <DrawerBodyBase
            flex="initial"
            height={height === undefined ? 'auto' : `${height}px`}
            padding={0}
            transition="height 0.25s"
        >
            <Box padding="16px" ref={childrenWrapperRef}>
                {children}
            </Box>
        </DrawerBodyBase>
    );
};

/**
 * A Chakra Drawer with an overlay, a close button, and a body, that has height transitions.
 */
const Drawer: FC<DrawerProps> = ({ children, ...rest }) => {
    return (
        <DrawerBase placement="bottom" {...rest}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerBody>{children}</DrawerBody>
            </DrawerContent>
        </DrawerBase>
    );
};

export default Drawer;
