import React, { memo } from 'react';
import { Divider, Flex, IconButton, Stack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { ReactComponent as CornerUpLeft } from 'svg/common/corner-up-left.svg';
import { ReactComponent as CornerUpRight } from 'svg/common/corner-up-right.svg';
import { ReactComponent as FlipLeft } from 'svg/common/flip-left.svg';
import { ReactComponent as FlipUp } from 'svg/common/flip-up.svg';
import { ReactComponent as ZoomIn } from 'svg/common/zoom-in.svg';
import { ReactComponent as ZoomOut } from 'svg/common/zoom-out.svg';

const Container = styled(Flex)(({ theme }) => {
    const { mediumScreenUp } = theme.responsive;

    return `
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 56px;
        justify-content: space-between;
        align-items: center;
        padding: ${theme.spacing.sm};
        margin: 16px;
        background-color: white;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

        ${mediumScreenUp`
            width: 396px;
            margin-left: auto;
            margin-right: auto;
        `}
    `;
});

const ToolbarButton = styled(IconButton)(({ theme }) => ({
    // fill: theme.colors.gray['600'],
    // stroke: theme.colors.gray['600'],
    margin: theme.spacing.sm
}));

interface IEditorToolbarProps {
    /** Whether editing is allowed. */
    disableEditing?: boolean;
    /** Whether the undo button is disabled. */
    undoDisabled?: boolean;
    /** Callback when the undo button is pressed. */
    onUndo?: () => unknown;
    /** Whether the redo button is disabled. */
    redoDisabled?: boolean;
    /** Callback when the redo button is pressed. */
    onRedo?: () => unknown;
    /** Whether the rotate button is disabled. */
    rotateDisabled?: boolean;
    showAlternativeRotateIcon?: boolean;
    /** Callback when the rotate button is pressed. */
    onRotate?: () => unknown;
    /** Whether the zoom in button is disabled. */
    zoomInDisabled?: boolean;
    /** Callback when the zoom in button is pressed. */
    onZoomIn?: () => unknown;
    /** Whether the zoom out button is disabled. */
    zoomOutDisabled?: boolean;
    /** Callback when the zoom out button is pressed. */
    onZoomOut?: () => unknown;
}

const EditorToolbar: React.FC<IEditorToolbarProps> = ({
    disableEditing,
    undoDisabled,
    onUndo,
    redoDisabled,
    onRedo,
    rotateDisabled,
    showAlternativeRotateIcon,
    onRotate,
    zoomInDisabled,
    onZoomIn,
    zoomOutDisabled,
    onZoomOut
}) => {
    return (
        <Container data-testid="sp-ui-editor-toolbar">
            {!disableEditing ? (
                <>
                    <Stack spacing={1} direction="row" align="center">
                        <ToolbarButton
                            colorScheme="white"
                            aria-label="Undo"
                            label="Undo"
                            icon={<CornerUpLeft color="gray.600" />}
                            isDisabled={undoDisabled}
                            onClick={onUndo}
                        />
                        <ToolbarButton
                            colorScheme="white"
                            aria-label="Redo"
                            label="Redo"
                            icon={<CornerUpRight color="gray.600" />}
                            isDisabled={redoDisabled}
                            onClick={onRedo}
                        />
                    </Stack>
                    <Divider orientation="vertical" color="gray.200" />
                    <Stack spacing={1} direction="row" align="center">
                        <ToolbarButton
                            colorScheme="white"
                            aria-label="Rotate"
                            icon={
                                showAlternativeRotateIcon ? (
                                    <FlipUp />
                                ) : (
                                    <FlipLeft color="gray.600" />
                                )
                            }
                            isDisabled={rotateDisabled}
                            onClick={onRotate}
                        />
                    </Stack>
                    <Divider orientation="vertical" color="gray.200" />
                </>
            ) : (
                <Stack spacing={1} direction="row" align="center" />
            )}
            <Stack spacing={1} direction="row" align="center">
                <ToolbarButton
                    colorScheme="white"
                    aria-label="Zoom Out"
                    icon={<ZoomOut color="gray.600" />}
                    isDisabled={zoomOutDisabled}
                    onClick={onZoomOut}
                />
                <ToolbarButton
                    colorScheme="white"
                    aria-label="Zoom In"
                    icon={<ZoomIn color="gray.600" />}
                    isDisabled={zoomInDisabled}
                    onClick={onZoomIn}
                />
            </Stack>
        </Container>
    );
};

EditorToolbar.displayName = 'EditorToolbar';

export default memo(EditorToolbar);
