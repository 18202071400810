import React, { FC, useEffect, useRef, useState } from 'react';
import { Flex, Grid, Heading, Link, Text, useBreakpointValue, useTheme } from '@chakra-ui/react';
import { ResizeObserver } from '@juggle/resize-observer';
import { Lock } from 'react-feather';
import CoverPhoto from 'ts/common/components/gallery/CoverPhoto';
import { ImageEnabledIcon } from 'ts/common/components/icons';
import type { ICoverPhotoBase } from 'ts/common/types';

export interface ICoverPhotoItem {
    caption?: string;
    coverPhoto?: ICoverPhotoBase & { url: string };
    href: string;
    id: number;
    name: string;
    requiresPassword?: boolean;
}

interface Props {
    items: ICoverPhotoItem[];
}

const imageAspectRatio = 1.524;

const CoverPhotoList: FC<Props> = ({ items }) => {
    const imageRef = useRef<HTMLDivElement>(null);
    const [imageHeight, setImageHeight] = useState(0);
    const imageMarginBottom = useBreakpointValue({ base: '10px', lg: '14px' });
    const theme = useTheme();

    useEffect(() => {
        let resizeObserver: ResizeObserver | undefined;

        if (imageRef.current) {
            resizeObserver = new ResizeObserver(() => {
                setImageHeight((imageRef.current?.offsetWidth ?? 0) / imageAspectRatio);
            });
            resizeObserver.observe(imageRef.current);
        }

        return () => {
            resizeObserver?.disconnect();
        };
    }, []);

    return (
        <Grid
            gridGap={useBreakpointValue({ base: '30px 20px', lg: '24px 20px' })}
            gridTemplateColumns="repeat(auto-fill, minmax(min(350px, 100vw - 32px), 1fr))"
        >
            {items.map((item, index) => (
                <Link
                    key={item.id}
                    href={item.href}
                    aria-label={`${item.name}${item.caption ? ` (${item.caption})` : ''}`}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    _focus={{
                        boxShadow: 'none'
                    }}
                    _focusVisible={{
                        boxShadow: 'outline'
                    }}
                    _visited={{
                        color: 'brand.primary',
                        '&:hover': {
                            color: 'brand.secondary'
                        }
                    }}
                    _hover={{
                        textDecoration: 'none',
                        color: 'brand.secondary',
                        '&:visited': {
                            color: 'brand.secondary'
                        }
                    }}
                >
                    <Flex
                        ref={index === 0 ? imageRef : undefined}
                        alignItems="center"
                        background="gray.50"
                        height={`${imageHeight}px`}
                        justifyContent="center"
                        marginBottom={imageMarginBottom}
                        outline="1px solid var(--chakra-colors-gray-100)"
                        outlineOffset="-1px"
                        position="relative"
                        width="full"
                    >
                        {item.coverPhoto ? (
                            <CoverPhoto
                                coverPhoto={item.coverPhoto}
                                coverPhotoUrl={item.coverPhoto.url}
                                label={item.name}
                            />
                        ) : (
                            !item.requiresPassword && (
                                <ImageEnabledIcon
                                    aria-label={item.name}
                                    color={
                                        theme.isLightColorScheme
                                            ? 'var(--chakra-colors-gray-400)'
                                            : 'var(--chakra-colors-gray-300)'
                                    }
                                    height="32px"
                                    width="32px"
                                />
                            )
                        )}
                        {item.requiresPassword && (
                            <Flex
                                alignItems="center"
                                background="background"
                                borderRadius="32px"
                                color="text"
                                justifyContent="center"
                                left="50%"
                                opacity={0.85}
                                padding="16px"
                                position="absolute"
                                top="50%"
                                transform="translate(-50%,-50%)"
                            >
                                <Lock size="32px" />
                            </Flex>
                        )}
                    </Flex>
                    <Heading
                        as="h3"
                        maxWidth="100%"
                        overflow="hidden"
                        size="md"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                    >
                        {item.name}
                    </Heading>
                    <Text fontSize="12px" lineHeight={1.33}>
                        {item?.caption || ''}
                    </Text>
                </Link>
            ))}
        </Grid>
    );
};

export default CoverPhotoList;
