import { PRICE_SHEET_ITEM_EVENTS } from 'Sp/Angular/Events/Client/Gallery';
import Route from 'Sp/Route';

export default [
    '$rootScope',
    'spApiHttp',
    'spEventBus',
    function PriceSheetItemDataStore($rootScope, spApiHttp, spEventBus) {
        spEventBus.on(PRICE_SHEET_ITEM_EVENTS.LIST, listPriceSheetItems);

        function listPriceSheetItems(query = {}) {
            const request = {
                ...query,
                brandId: $rootScope.brandData.id,
                eventId: $rootScope.eventData.id
            };

            spApiHttp
                .get(Route.get('/brand/:brandId/event/:eventId/price-sheet/item', request))
                .then(({ data }) => {
                    const { items: priceSheetItems } = data;

                    spEventBus.broadcast(PRICE_SHEET_ITEM_EVENTS.LISTED, priceSheetItems);
                });
        }
    }
];
