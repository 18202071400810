import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { EmailCaptureForm } from 'ts/client/gallery/components';
import { useAddToCart } from 'ts/client/gallery/hooks';
import { useTranslate } from 'ts/common/hooks';
import type { ICrop } from 'ts/common/types';
import type { IBoundItem, IImage, IPathContext } from '../types';

export interface IFormValues {
    email: string;
    confirmEmail: string;
    doesAcceptTerms: boolean;
}

interface IEmailCaptureAddToCartSlideProps {
    addToCart: (
        priceSheetItemId: number,
        quantity: number,
        buyAllImagesState: IImage[],
        orderPrice?: number,
        digitalItem?: Nullable<IBoundItem>
    ) => void;
    buyAllImages: IImage[];
    crop: Nullable<ICrop>;
    digitalOrderPrice?: number;
    getPathContext: () => IPathContext;
    image: IImage;
    isSubjectToGdpr: boolean;
    priceSheetItemId?: number;
    quantity: number;
    trackingData: {
        buyAllImagesState: IImage[];
        digitalItem?: Nullable<IBoundItem>;
    };
}

const EmailCaptureAddToCartSlide: React.FC<IEmailCaptureAddToCartSlideProps> = ({
    addToCart,
    buyAllImages,
    crop,
    digitalOrderPrice,
    getPathContext,
    image,
    isSubjectToGdpr,
    priceSheetItemId,
    quantity,
    trackingData
}) => {
    const t = useTranslate();
    const { context, contextId } = getPathContext();
    const [emailSetSuccess, setEmailSetSuccess] = useState<boolean>(false);
    const isBuyingAllPhotos = !!buyAllImages.length;
    const buyAllImagesIds = buyAllImages.reduce<string>(
        (imagesIdsString, currentImage, currentIndex) => {
            const currentImageId = currentIndex === 0 ? currentImage.id : `,${currentImage.id}`;
            return `${imagesIdsString}${currentImageId}`;
        },
        ''
    );

    const { isPostToCartLoading, postToCart, postToCartResponse } = useAddToCart();

    useEffect(() => {
        if (emailSetSuccess) {
            postToCart({
                body: new URLSearchParams({
                    album_id: context === 'album' ? contextId : '',
                    crop_data: crop?.isChanged
                        ? [crop.start.x, crop.start.y, crop.end.x, crop.end.y].join(',')
                        : '',
                    photo_filter_id: image.filterBlackAndWhite ? '2' : '1',
                    photo_id: isBuyingAllPhotos ? buyAllImagesIds : String(image.id),
                    price_sheet_item_id: String(priceSheetItemId),
                    quantity: String(quantity)
                })
            });
        }
    }, [
        buyAllImagesIds,
        context,
        contextId,
        crop,
        emailSetSuccess,
        image,
        isBuyingAllPhotos,
        postToCart,
        priceSheetItemId,
        quantity
    ]);

    useEffect(() => {
        if (
            postToCartResponse &&
            postToCartResponse.statusText === 'success' &&
            priceSheetItemId &&
            quantity
        ) {
            addToCart(
                priceSheetItemId,
                quantity,
                trackingData.buyAllImagesState,
                digitalOrderPrice,
                trackingData.digitalItem
            );
        }
    }, [
        addToCart,
        digitalOrderPrice,
        postToCartResponse,
        priceSheetItemId,
        quantity,
        trackingData
    ]);

    return (
        <Box padding="48px 20px">
            <EmailCaptureForm
                buttonText={t(
                    'client.gallery.addToCartSidePanel.emailCaptureAddToCartSlide.addToCart'
                )}
                headerText={t(
                    'client.gallery.addToCartSidePanel.emailCaptureAddToCartSlide.enterEmail'
                )}
                headerSubText={t(
                    'client.gallery.addToCartSidePanel.emailCaptureAddToCartSlide.weWillSaveYourCart'
                )}
                isLoading={isPostToCartLoading}
                isSubjectToGdpr={isSubjectToGdpr}
                setEmailSetSuccess={setEmailSetSuccess}
            />
        </Box>
    );
};

export default EmailCaptureAddToCartSlide;
