let clientEvent: IEvent;

interface IEvent {
    id: string;
    brandId: string;
    settings: {
        allowCropping: boolean;
        exceedsBigEventThreshold: boolean;
        priceSheetShowPackagesOnly: boolean;
        thumbnailGutterWidth: 's' | 'm' | 'l';
        thumbnailSize: 's' | 'm' | 'l';
        viewType: 'vmason' | 'hmason';
    };
}

export const setEvent = (event: IEvent) => {
    clientEvent = event;
};

const useEvent = () => clientEvent;

export default useEvent;
