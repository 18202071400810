import { NOTIFICATION_EVENTS } from 'Sp/Angular/Events/Client/Notification';

export default [
    '$rootScope',
    'SPDataSourcePrototype',
    'SPDataObjects',
    'spAppData',
    'spEventBus',
    'SPPhoto',
    'spClientCredentials',
    function SPClientDataService(
        $rootScope,
        SPDataSourcePrototype,
        SPDataObjects,
        spAppData,
        spEventBus,
        SPPhoto,
        spClientCredentials
    ) {
        var self = this;

        SPDataSourcePrototype.attachPrototype(this);

        var eventData = spAppData.get('eventData');

        this._getEndpointUri = function _getEndpointUri(endpoint) {
            return '/gallery/' + eventData.uriId + '/' + endpoint;
        };

        this._hydrateResponse = function _hydrateResponse(response) {
            if (typeof response.photos === 'object') {
                response.photos = response.photos.map(function withPhotoData(photoData) {
                    return SPDataObjects.factory('Photo', photoData);
                });
            } else {
                response.photos = false;
            }

            if (typeof response.albums === 'object') {
                response.albums = response.albums.map(function withAlbumData(albumData) {
                    return SPDataObjects.factory('Album', albumData);
                });
            } else {
                response.albums = false;
            }

            if (typeof response.album === 'object') {
                response.album = SPDataObjects.factory('Album', response.album);
            } else {
                response.album = false;
            }

            if (typeof response.cart === 'object') {
                response.cart.items = [];

                if (typeof response.cart.groupedItems === 'object') {
                    var itemGroups = window.SPObject.convertToArray(response.cart.groupedItems);

                    delete response.cart.groupedItems;
                    delete response.packages;

                    // Flatten out data into cartItems
                    itemGroups.forEach(function withFilterGroups(filterGroups) {
                        filterGroups = window.SPObject.convertToArray(filterGroups);

                        filterGroups.forEach(function withItemData(itemData) {
                            var cartItem = SPDataObjects.factory('CartItem', itemData);

                            response.cart.items.push(cartItem);
                        });
                    });

                    response.cart.items.forEach(function withCartItem(cartItem) {
                        cartItem.__initCrop(SPPhoto);
                    });
                }
            }

            if (typeof response.userState === 'object') {
                $rootScope.populateUserState(response);
            }
        };

        this._commonErrorHandler = function _commonErrorHandler(response) {
            $rootScope.$broadcast('SPClientData:error', response);
        };

        this.getBrandData = function getBrandData() {
            return spAppData.get('brandData');
        };

        this.getInitialEventData = function getInitialEventData() {
            return eventData;
        };

        this.getThemeData = function getThemeData() {
            return spAppData.get('themeData');
        };

        this.getInitialUserState = function getInitialUserState() {
            return spAppData.get('userState');
        };

        this.getTags = function getTags() {
            var tags = eventData.tags;

            if (typeof tags === 'object' && tags instanceof Array) {
                return tags.map(function withTagData(tagData) {
                    return SPDataObjects.factory('Tag', tagData);
                });
            }

            return [];
        };

        this.preRegister = function preRegister(
            emailAddress,
            termsAcceptance,
            successCallback,
            errorCallback
        ) {
            return this.makePostRequest(
                'preregister',
                {
                    email: emailAddress,
                    doesAcceptTerms: termsAcceptance ? 1 : 0
                },
                getCredentialStoringSuccessCallback(successCallback, { email: emailAddress }),
                errorCallback
            );
        };

        this.authenticate = function authenticate(
            emailAddress,
            password,
            termsAcceptance,
            successCallback,
            errorCallback
        ) {
            return this.makePostRequest(
                'auth',
                {
                    email: emailAddress,
                    password: password,
                    doesAcceptTerms: termsAcceptance ? 1 : 0
                },
                getCredentialStoringSuccessCallback(successCallback, { email: emailAddress }),
                errorCallback
            );
        };

        this.authenticateAdminModeEntry = function authenticateAdminModeEntry(
            emailAddress,
            termsAcceptance,
            successCallback,
            errorCallback
        ) {
            return this.makePostRequest(
                'adminmodeentryauth',
                {
                    email: emailAddress,
                    doesAcceptTerms: termsAcceptance ? 1 : 0
                },
                getCredentialStoringSuccessCallback(successCallback, { email: emailAddress }),
                errorCallback
            );
        };

        this.authenticateAlbum = function authenticateAlbum(
            album,
            password,
            successCallback,
            errorCallback
        ) {
            return this.makePostRequest(
                'albumauth',
                {
                    album_id: album.id,
                    password: password
                },
                successCallback,
                errorCallback
            );
        };

        this.authenticateAdminMode = function authenticateAdminMode(
            pin,
            successCallback,
            errorCallback
        ) {
            return this.makePostRequest(
                'adminmodeauth',
                { pin: pin },
                successCallback,
                errorCallback
            );
        };

        this.setEmail = function setEmail(
            emailAddress,
            termsAcceptance,
            successCallback,
            errorCallback
        ) {
            return this.makePostRequest(
                'setemail',
                {
                    email: emailAddress,
                    doesAcceptTerms: termsAcceptance ? 1 : 0
                },
                getCredentialStoringSuccessCallback(successCallback, { email: emailAddress }),
                errorCallback
            );
        };

        this.getEventData = function getEventData(params, successCallback, errorCallback) {
            return this.makeGetRequest('data', params, successCallback, errorCallback);
        };

        this.getFavoritePhotos = function getFavoritePhotos(successCallback, errorCallback) {
            return this.makeGetRequest('favoritephotos', {}, successCallback, errorCallback);
        };

        this.getHiddenPhotos = function getHiddenPhotos(successCallback, errorCallback) {
            return this.makeGetRequest('hiddenphotos', {}, successCallback, errorCallback);
        };

        this.getTaggedPhotos = function getTaggedPhotos(
            tagName,
            successCallback,
            errorCallback,
            filter
        ) {
            var params = {};

            if (typeof tagName === 'string') {
                params.event_photo_tag_name = tagName;
            }

            return this.makeGetRequest(
                'taggedphotos',
                params,
                function withResponse(response) {
                    if (typeof response.photos === 'object' && typeof filter === 'function') {
                        response.photos = response.photos.filter(filter);
                    }

                    successCallback(response);
                },
                errorCallback
            );
        };

        this.getPhotosForSearch = function getPhotosForSearch(
            query,
            successCallback,
            errorCallback
        ) {
            if (typeof query !== 'string' || query.length === 0) {
                return successCallback({});
            }

            return this.makePostRequest(
                'photokeywordsearch',
                {
                    q: query
                },
                successCallback,
                errorCallback
            );
        };

        this.togglePhotoHidden = function togglePhotoHidden(photo, successCallback, errorCallback) {
            return this.makePostRequest(
                'photohiddentoggle',
                {
                    photo_id: photo.id
                },
                function withResponse(response) {
                    if (typeof response.isHidden === 'boolean') {
                        photo.isHidden = response.isHidden;
                    }

                    if (typeof successCallback === 'function') {
                        successCallback(response);
                    }
                },
                errorCallback,
                [photo],
                ['hide']
            );
        };

        this.togglePhotoFavorite = function togglePhotoFavorite(
            photo,
            email,
            album,
            doesAcceptTerms,
            successCallback,
            errorCallback
        ) {
            var args = {
                photo_id: photo.id
            };

            if (typeof email === 'string') {
                args.email = email;
            }

            if (typeof album === 'object') {
                args.album_id = album.id;
                args.album_path = album.name;
            }

            args.doesAcceptTerms = doesAcceptTerms ? 1 : 0;

            return this.makePostRequest(
                'photofavoritetoggle',
                args,
                function withResponse(response) {
                    spClientCredentials.set({ email: email });

                    if (typeof response.isFavorite === 'boolean') {
                        photo.isFavorite = response.isFavorite;
                    }

                    if (typeof successCallback === 'function') {
                        successCallback(response);
                    }
                },
                errorCallback,
                [photo],
                ['favorite']
            );
        };

        this.togglePhotoTag = function togglePhotoTag(photo, tag, successCallback, errorCallback) {
            return this.makePostRequest(
                'phototagtoggle',
                {
                    photo_id: photo.id,
                    event_photo_tag_id: tag.id
                },
                function withResponse(response) {
                    if (typeof response.photo_tag_ids === 'object') {
                        photo.tags = response.photo_tag_ids;
                    }

                    if (typeof successCallback === 'function') {
                        successCallback(response);
                    }
                },
                errorCallback,
                [photo],
                ['tag/' + tag.id]
            );
        };

        this.getPhotoShareUrls = (photo) => {
            return new Promise((resolve, reject) => {
                this.makePostRequest(
                    'photoshareurls',
                    {
                        photo_id: photo.id
                    },
                    resolve,
                    reject,
                    [photo],
                    ['share']
                );
            });
        };

        this.downloadPhoto = function downloadPhoto(
            photo,
            email,
            pin,
            successCallback,
            errorCallback
        ) {
            return this.makePostRequest(
                'photodownload',
                {
                    email: email,
                    pin: pin,
                    photo_id: photo.id
                },
                successCallback,
                errorCallback,
                [photo],
                ['download']
            );
        };

        this.downloadAlbum = function downloadAlbum(
            album,
            email,
            pin,
            successCallback,
            errorCallback
        ) {
            return this.makePostRequest(
                'albumdownload',
                {
                    email: email,
                    pin: pin,
                    album_id: album.id
                },
                successCallback,
                errorCallback,
                [album],
                ['download']
            );
        };

        this.downloadPhotos = function downloadPhotos(
            photos,
            email,
            pin,
            successCallback,
            errorCallback
        ) {
            return this.makePostRequest(
                'photosdownload',
                {
                    email: email,
                    pin: pin,
                    'photo_ids[]': photos.map(function withPhoto(photo) {
                        return photo.id;
                    })
                },
                successCallback,
                errorCallback,
                photos,
                ['download']
            );
        };

        this.downloadAllInProgress = false;

        this.downloadAllPhotos = function downloadAllPhotos(
            email,
            pin,
            successCallback,
            errorCallback
        ) {
            if (self.downloadAllInProgress) {
                return false;
            }

            self.downloadAllInProgress = true;

            return this.makePostRequest(
                'allphotosdownload',
                {
                    email: email,
                    pin: pin,
                    album_id: 'all'
                },
                function withResponse(response) {
                    self.downloadAllInProgress = false;

                    if (typeof successCallback === 'function') {
                        successCallback(response);
                    }
                },
                function withResponse(response) {
                    self.downloadAllInProgress = false;

                    if (typeof errorCallback === 'function') {
                        errorCallback(response);
                    }
                }
            );
        };

        this.getShareUrls = function getShareUrls(successCallback, errorCallback) {
            return this.makePostRequest('shareurls', {}, successCallback, errorCallback);
        };

        this.sendFavoritesToPhotographer = (email, message) => {
            spEventBus.emit(NOTIFICATION_EVENTS.FAVORITES.SEND, {
                email,
                message
            });
        };

        this.shareFavorites = function shareFavorites(
            fromName,
            toEmail,
            message,
            successCallback,
            errorCallback
        ) {
            return this.makePostRequest(
                'sharefavorites',
                {
                    from_name: fromName,
                    to_email: toEmail,
                    message: message
                },
                successCallback,
                errorCallback
            );
        };

        var getPriceSheetData = function getPriceSheetData(
            photos,
            album,
            isFavorites,
            isHidden,
            tag,
            successCallback,
            errorCallback
        ) {
            var photoIds;
            var photo;

            if (typeof photos === 'object' && photos instanceof Array) {
                photoIds = photos
                    .map(function withPhoto(photo) {
                        return photo.id;
                    })
                    .join(',');

                if (photos.length === 1) {
                    photo = photos[0];
                }
            }

            var params = {
                photo_id: photoIds,
                is_favorites: isFavorites ? 1 : 0,
                is_hidden: isHidden ? 1 : 0,
                is_tag: typeof tag === 'object' ? 1 : 0
            };

            // When getting Price Sheet data for an Album, some additional
            // contextual data will be returned on the item, such as a value
            // containing additional description of the number of Photos
            // present in the given Album.
            if (typeof album === 'object' && album.getType('Album')) {
                params.album_id = album.id;
            }

            return self.makePostRequest(
                'cart/pricesheetdata',
                params,
                function withResponse(response) {
                    if (typeof response.groups === 'object') {
                        response.groups = response.groups.map(function withGroup(group) {
                            group.isDigitalDownload = group.is_digital_download;

                            delete group.is_digital_download;

                            var bounds = window.SPObject.convertToArray(group.bounds);

                            group.bounds = [];

                            bounds.forEach(function withBoundData(boundData) {
                                /**
                                 * If we have a name, and we're not working on digital
                                 * downloads, push this data into the bounds object
                                 */
                                if (
                                    typeof boundData.bounds_name === 'string' &&
                                    !group.isDigitalDownload
                                ) {
                                    group.bounds.push(
                                        SPDataObjects.factory('Bound', boundData, photo)
                                    );

                                    return;
                                }

                                /**
                                 * If we have no name, or we're dealing with digital items
                                 * expand individual items into their own bounds
                                 */
                                boundData.items.forEach(function withItem(item) {
                                    var boundDataCopy = angular.copy(boundData);

                                    boundDataCopy.bounds_name = item.name;

                                    if (item.is_album || item.is_event) {
                                        boundDataCopy.description = item.album_or_event_description;
                                    }

                                    boundDataCopy.items = [item];

                                    group.bounds.push(
                                        SPDataObjects.factory('Bound', boundDataCopy, photo)
                                    );
                                });
                            });

                            return group;
                        });
                    }

                    if (typeof successCallback === 'function') {
                        return successCallback(response);
                    }
                },
                errorCallback
            );
        };

        this.getPriceSheetForPhotos = function getPriceSheetForPhotos(
            photos,
            album,
            successCallback,
            errorCallback
        ) {
            return getPriceSheetData(
                photos,
                album,
                false,
                false,
                false,
                successCallback,
                errorCallback
            );
        };

        this.getPriceSheetForFavorites = function getPriceSheetForFavorites(
            photos,
            successCallback,
            errorCallback
        ) {
            return getPriceSheetData(
                photos,
                false,
                true,
                false,
                false,
                successCallback,
                errorCallback
            );
        };

        this.getPriceSheetForHidden = function getPriceSheetForHidden(
            photos,
            successCallback,
            errorCallback
        ) {
            return getPriceSheetData(
                photos,
                false,
                false,
                true,
                false,
                successCallback,
                errorCallback
            );
        };

        this.getPriceSheetForTag = function getPriceSheetForTag(
            photos,
            tag,
            successCallback,
            errorCallback
        ) {
            return getPriceSheetData(
                photos,
                false,
                false,
                false,
                tag,
                successCallback,
                errorCallback
            );
        };

        this.getPriceSheetAllItems = function getPriceSheetAllItems(
            successCallback,
            errorCallback
        ) {
            return getPriceSheetData(
                false,
                false,
                false,
                false,
                false,
                successCallback,
                errorCallback
            );
        };

        this.bundleAuth = function bundleAuth(
            zipBundleId,
            email,
            termsAcceptance,
            successCallback,
            errorCallback
        ) {
            return this.makePostRequest(
                'bundle/' + zipBundleId + '/auth',
                {
                    zip_bundle_id: zipBundleId,
                    email: email,
                    doesAcceptTerms: termsAcceptance ? 1 : 0
                },
                successCallback,
                errorCallback
            );
        };

        this.acceptFavoritesAuth = function acceptFavoritesAuth(
            token,
            email,
            successCallback,
            errorCallback
        ) {
            return this.makePostRequest(
                'acceptfavorites/' + token + '/auth',
                {
                    token: token,
                    email: email
                },
                successCallback,
                errorCallback
            );
        };

        this.logBundleDownloaded = function logBundleDownloaded(
            zipBundleId,
            successCallback,
            errorCallback
        ) {
            return this.makePostRequest(
                'bundledownloaded',
                {
                    zip_bundle_id: zipBundleId
                },
                successCallback,
                errorCallback
            );
        };

        function getCredentialStoringSuccessCallback(callback, credentials) {
            return function credentialStoringSuccessCallback() {
                if (callback) {
                    callback.apply(null, arguments);
                }

                spClientCredentials.set(credentials);
            };
        }
    }
];
