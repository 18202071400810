import React, { FC, useCallback } from 'react';
import { Box, ChakraProvider, Heading, Text } from '@chakra-ui/react';
import { useTheme } from 'ts/client/gallery';
import { LoadingIndicator } from 'ts/common/components';
import Button from 'ts/common/components/gallery/Button';
import StudioHeading from 'ts/common/components/gallery/StudioHeading';
import StudioLogo from 'client_react/gallery/components/StudioLogo';

interface GooglePhotosExport {
    clientEmail: string;
    error?: string;
    exportId: number;
    googlePhotosUrl: string | null;
}

interface Props {
    brandName: string;
    googlePhotosExport?: GooglePhotosExport;
    spEventBus: SpAngularJs.ISpEventBus;
    translateFilter: SpAngularJs.ITranslateFilter;
}

const GooglePhotosExportPage: FC<Props> = ({
    brandName,
    googlePhotosExport,
    spEventBus,
    translateFilter: t
}) => {
    const { brandTheme, theme } = useTheme(spEventBus);

    const handleCtaButtonClick = useCallback(() => {
        location.href = googlePhotosExport?.googlePhotosUrl || 'https://photos.google.com/';
    }, [googlePhotosExport]);

    return (
        <ChakraProvider theme={theme} resetCSS={false}>
            <Box paddingX="24px" textAlign="center">
                <Box marginBottom="32px" marginTop="16px" textAlign="center">
                    {brandTheme?.eventBrandLogo ? (
                        <StudioLogo
                            logo={brandTheme.eventBrandLogo}
                            logoAltText={brandTheme.logoAltText}
                            logoSize="md"
                        />
                    ) : (
                        <StudioHeading text={brandName} variant="primary" />
                    )}
                </Box>
                {googlePhotosExport ? (
                    googlePhotosExport.googlePhotosUrl || googlePhotosExport.error ? (
                        <>
                            <Heading as="h3" fontWeight="semibold" marginBottom="32px" size="lg">
                                {t(
                                    googlePhotosExport.error
                                        ? 'client.gallery.googlePhotosExport.error.header'
                                        : 'client.gallery.googlePhotosExport.ready.header'
                                )}
                            </Heading>
                            <Text
                                fontSize="md"
                                margin="0 auto 32px"
                                maxWidth="100%"
                                textAlign="center"
                                width="600px"
                            >
                                {t(
                                    googlePhotosExport.error
                                        ? `client.gallery.googlePhotosExport.error.subtitle.${googlePhotosExport.error}`
                                        : 'client.gallery.googlePhotosExport.ready.subtitle'
                                )}
                            </Text>
                            <Button
                                maxWidth="100%"
                                onClick={handleCtaButtonClick}
                                text={t('client.gallery.googlePhotosExport.ctaButton')}
                                width="358px"
                            />
                        </>
                    ) : (
                        <>
                            <LoadingIndicator />
                            <Heading as="h3" fontWeight="semibold" marginBottom="32px" size="lg">
                                {t('client.gallery.googlePhotosExport.pending.header')}
                            </Heading>
                            <Text
                                fontSize="md"
                                margin="0 auto 32px"
                                maxWidth="100%"
                                textAlign="center"
                                width="600px"
                            >
                                {t('client.gallery.googlePhotosExport.pending.text', {
                                    clientEmail: googlePhotosExport.clientEmail
                                })}
                            </Text>
                        </>
                    )
                ) : (
                    <LoadingIndicator />
                )}
            </Box>
        </ChakraProvider>
    );
};

export default GooglePhotosExportPage;
