import React, { FC } from 'react';
import {
    Modal as ChakraModal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    ModalProps
} from '@chakra-ui/react';

const Modal: FC<ModalProps> = ({ children, ...props }) => {
    return (
        <ChakraModal {...props}>
            <ModalOverlay />
            <ModalContent>
                <ModalBody>{children}</ModalBody>
                <ModalCloseButton />
            </ModalContent>
        </ChakraModal>
    );
};

export default Modal;
