import React, { useMemo, useState } from 'react';
import { BoxProps, Flex, Heading, Text } from '@chakra-ui/react';
import { useScenes } from 'ts/client/common';
import { useTranslate } from 'ts/common/hooks';
import { useCurrency } from '../hooks';
import Product from './Product';
import { groupHasLabFulfilledCards } from './common';
import { IImage } from './types';

interface IProductGroupProps {
    forceDefaultBackdrop?: boolean;
    image: IImage;
    onClick?: () => void;
    priceSheetGroup: SpApi.Client.IPriceSheetGroup;
    width?: number | string;
}

const ProductGroup: React.FC<IProductGroupProps & BoxProps> = ({
    forceDefaultBackdrop = false,
    image,
    onClick,
    priceSheetGroup,
    width = '100%',
    ...props
}) => {
    const $ = useCurrency();
    const { getDefaultSceneForProduct } = useScenes();
    const { groupType, items, name } = priceSheetGroup;

    const [firstItem] = useState(items[0]);

    const applyCardPriceMultiplier =
        groupType === 'cards' && groupHasLabFulfilledCards(priceSheetGroup);

    const lowestItemPrice = useMemo(
        () =>
            getLowestPriceSheetItemPrice(
                items.sort(
                    (item, nextItem) =>
                        getLowestPriceSheetItemPrice(item) - getLowestPriceSheetItemPrice(nextItem)
                )[0]
            ),
        [items]
    );
    const t = useTranslate('client.gallery.productGroup');

    const displayName = groupType === 'packages' ? t('packages') : name;

    return (
        <Flex
            data-testid="product-group"
            flexDirection="column"
            alignItems="flex-start"
            width={width}
            {...props}
        >
            <Product
                cursor="pointer"
                forceDefaultBackdrop={forceDefaultBackdrop}
                image={image}
                onClick={onClick}
                priceSheetItem={firstItem}
                productScene={getDefaultSceneForProduct(firstItem)}
                width={width}
            />
            <Heading
                color="brand.primary"
                cursor="pointer"
                fontFamily="open-sans, sans-serif"
                marginBottom="3px"
                marginTop="12px"
                onClick={onClick}
                size="lg"
                _hover={{ color: 'brand.secondary' }}
            >
                {displayName}
            </Heading>
            <Text color="text" fontFamily="open-sans, sans-serif">
                {t('subtext', {
                    lowestItemPrice: `${$(
                        applyCardPriceMultiplier ? lowestItemPrice * 25 : lowestItemPrice
                    )}`
                })}
            </Text>
        </Flex>
    );
};

function getLowestPriceSheetItemPrice({
    allAlbumPhotosDownload,
    allGalleryPhotosDownload,
    individualPhotoDownload,
    price
}: SpApi.Client.IPriceSheetItem) {
    if (price !== undefined) {
        return price;
    }

    return (
        [
            allAlbumPhotosDownload?.isDigitalDownloadForSale && allAlbumPhotosDownload?.price,
            allGalleryPhotosDownload?.isDigitalDownloadForSale && allGalleryPhotosDownload?.price,
            individualPhotoDownload?.isDigitalDownloadForSale && individualPhotoDownload?.price
        ].filter((price) => typeof price === 'number') as number[]
    ).sort((price, nextPrice) => price - nextPrice)[0];
}

export default ProductGroup;
