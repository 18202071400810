import {
    AUTHORIZATION_TOKEN_EVENTS,
    GOOGLE_PHOTOS_EXPORT_EVENTS
} from 'Sp/Angular/Events/Client/Gallery';
import { SESSION_STORAGE_EMAIL_KEY } from 'ts/common/constants/auth';

export default [
    '$rootScope',
    '$scope',
    '$routeParams',
    'spAppData',
    'spAuthorizationToken',
    'spToast',
    'translateFilter',
    function GooglePhotosExportController(
        $rootScope,
        $scope,
        $routeParams,
        spAppData,
        spAuthorizationToken,
        spToast,
        translateFilter
    ) {
        const searchParams = new URLSearchParams(location.search);
        const authKey = searchParams.get('authKey');
        const cacheKey = searchParams.get('cacheKey');

        const brandData = spAppData.get('brandData');
        $scope.brandName = brandData.name;

        const exportId = Number($routeParams.exportId);
        const email = sessionStorage.getItem(SESSION_STORAGE_EMAIL_KEY);

        $rootScope.$on(GOOGLE_PHOTOS_EXPORT_EVENTS.GOT, (_, response) => {
            if (exportId === response.exportId) {
                $rootScope.$evalAsync(() => {
                    $scope.googlePhotosExport = response;
                });
            }
        });

        (async () => {
            if (exportId) {
                const authorizationToken = await spAuthorizationToken.getAuthorizationTokenForEvent(
                    false,
                    { email }
                );

                $rootScope.$emit(GOOGLE_PHOTOS_EXPORT_EVENTS.GET, {
                    id: exportId,
                    authorizationToken
                });
            } else {
                if (authKey) {
                    if (cacheKey) {
                        let googlePhotosExportProperties;

                        try {
                            googlePhotosExportProperties = JSON.parse(
                                localStorage.getItem(authKey)
                            );
                        } catch (error) {
                            spToast.toast({
                                title: translateFilter('downloadGoogleAuthKeyInvalid'),
                                status: 'error',
                                isClosable: true
                            });

                            return;
                        }

                        if (googlePhotosExportProperties) {
                            const authorizationToken =
                                await spAuthorizationToken.getAuthorizationTokenForEvent(true, {
                                    email,
                                    google_oauth_refresh_token_cache_key: cacheKey
                                });

                            // Temporarily set the export key in local storage, to authorize the original tab for Google sign-in:
                            localStorage.setItem(authKey, authorizationToken);

                            $rootScope.isSignedIntoGoogle = true;

                            $rootScope.$broadcast(
                                AUTHORIZATION_TOKEN_EVENTS.CREATED_EVENT_TOKEN,
                                authorizationToken
                            );

                            $rootScope.$emit(GOOGLE_PHOTOS_EXPORT_EVENTS.CREATE, {
                                ...googlePhotosExportProperties,
                                authorizationToken
                            });
                        } else {
                            spToast.toast({
                                title: translateFilter('downloadGoogleAuthKeyInvalid'),
                                status: 'error',
                                isClosable: true
                            });
                        }
                    } else {
                        const authorizationToken =
                            await spAuthorizationToken.getAuthorizationTokenForEvent(false, {
                                email
                            });

                        const { apiRoot } = spAppData.get('urls');
                        const url = `${apiRoot}/oauth/google/status`;

                        const options = {
                            headers: {
                                Authorization: `Bearer ${authorizationToken}`
                            }
                        };

                        const response = await fetch(url, options);
                        const responseData = await response.json();

                        if (responseData.isSignedIntoGoogle) {
                            let googlePhotosExportProperties;

                            try {
                                googlePhotosExportProperties = JSON.parse(
                                    localStorage.getItem(authKey)
                                );
                            } catch (error) {
                                spToast.toast({
                                    title: translateFilter('downloadGoogleAuthKeyInvalid'),
                                    status: 'error',
                                    isClosable: true
                                });

                                return;
                            }

                            if (googlePhotosExportProperties) {
                                $rootScope.$emit(GOOGLE_PHOTOS_EXPORT_EVENTS.CREATE, {
                                    ...googlePhotosExportProperties,
                                    authorizationToken
                                });
                            } else {
                                spToast.toast({
                                    title: translateFilter('downloadGoogleAuthKeyInvalid'),
                                    status: 'error',
                                    isClosable: true
                                });
                            }
                        } else {
                            // Construct a URL to help us redirect to the Google Photos OAuth sign-in page:
                            const redirectUrl = location.href.split('?')[0];

                            const stateQueryParam = encodeURIComponent(
                                JSON.stringify({ authKey, redirectUrl })
                            );

                            const { apiRoot } = spAppData.get('urls');
                            const emailQueryPart = email ? `&email=${email}` : '';
                            const authRedirectUrl = `${apiRoot}/oauth/google?state=${stateQueryParam}${emailQueryPart}`;

                            location.href = authRedirectUrl;
                        }
                    }
                } else {
                    spToast.toast({
                        title: translateFilter('downloadGoogleAuthKeyInvalid'),
                        status: 'error',
                        isClosable: true
                    });
                }
            }
        })();
    }
];
