import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Flex, Link, useBreakpointValue } from '@chakra-ui/react';
import { ResizeObserver } from '@juggle/resize-observer';
import type { INavTab } from 'ts/common/types';

interface OffsetRect {
    left: number;
    top: number;
    width: number;
    height: number;
}

interface Props {
    /** The index of the selected tabs within the given array of tabs */
    selectedTabIndex?: number;
    /** An array of tabs to render */
    tabs: INavTab[];
}

const NavTabs: FC<Props> = ({ selectedTabIndex = 0, tabs }) => {
    const selectedTabElementRef = useRef<HTMLAnchorElement>(null);

    const [selectedTabRect, setSelectedTabRect] = useState<OffsetRect>();

    // A function that updates the bounds of the selected tab indicator:
    const updateSelectedTabRect = useCallback(() => {
        if (selectedTabElementRef.current) {
            setSelectedTabRect({
                left: selectedTabElementRef.current.offsetLeft,
                top: selectedTabElementRef.current.offsetTop,
                width: selectedTabElementRef.current.offsetWidth,
                height: selectedTabElementRef.current.offsetHeight
            });
        }
    }, []);

    // When the selected tab's size changes, then update the selected tab indicator's bounds:
    useEffect(() => {
        let resizeObserver: ResizeObserver | undefined;

        if (selectedTabElementRef.current) {
            resizeObserver = new ResizeObserver(updateSelectedTabRect);
            resizeObserver.observe(selectedTabElementRef.current);
        }

        return () => {
            resizeObserver?.disconnect();
        };
    }, [updateSelectedTabRect]);

    // When the selected tab changes, then update the selected tab indicator's bounds:
    useEffect(updateSelectedTabRect, [selectedTabIndex, updateSelectedTabRect]);

    const hoverColor = useBreakpointValue({ base: undefined, lg: 'brand.primary' });

    return (
        <Flex as="nav" gap="4px" position="relative">
            {/* Draw an indicator for the selected tab: */}
            {selectedTabRect && (
                <Box
                    role="presentation"
                    backgroundColor="brand.primaryLight"
                    borderColor="brand.primary"
                    borderRadius="16px"
                    borderStyle="solid"
                    borderWidth="1px"
                    position="absolute"
                    transition="0.25s left"
                    left={`${selectedTabRect.left}px`}
                    top={`${selectedTabRect.top}px`}
                    width={`${selectedTabRect.width}px`}
                    height={`${selectedTabRect.height}px`}
                />
            )}
            {/* Draw the tabs: */}
            {tabs.map((tab, index) => {
                const isSelected = selectedTabIndex === index;

                return (
                    <Link
                        key={index}
                        ref={isSelected ? selectedTabElementRef : undefined}
                        href={tab.href}
                        aria-current={isSelected ? 'page' : undefined}
                        borderColor="transparent"
                        borderRadius="16px"
                        borderStyle="solid"
                        borderWidth="1px"
                        color="text"
                        fontFamily="open-sans"
                        fontSize="16px"
                        fontWeight={isSelected ? 'semibold' : 'normal'}
                        lineHeight={1.5}
                        paddingLeft="12px"
                        paddingRight="12px"
                        _focus={{
                            color: isSelected ? 'brand.primary' : 'text'
                        }}
                        _focusVisible={{
                            boxShadow: 'outline',
                            color: 'brand.primary'
                        }}
                        _hover={{
                            color: hoverColor,
                            textDecoration: 'none'
                        }}
                        _visited={{
                            color: isSelected ? 'brand.primary' : 'text',
                            '&:focus': {
                                color: isSelected ? 'brand.primary' : 'text'
                            },
                            '&:hover': {
                                color: hoverColor
                            }
                        }}
                    >
                        {tab.label}
                    </Link>
                );
            })}
        </Flex>
    );
};

export default NavTabs;
