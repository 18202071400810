import { useCallback, useState } from 'react';
import { SESSION_STORAGE_EMAIL_KEY } from 'ts/common/constants/auth';

export const VALID_EMAIL_REGEX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function useClientCredentials() {
    const { sessionStorage } = window;
    const emailCookieName = '';

    const [email, setEmail] = useState(() => {
        const emailCookie = document.cookie.split('; ').reduce((acc, currentCookie) => {
            const item = currentCookie.split('=');
            const storedKey = item[0];
            const storedValue = item[1];
            return emailCookieName === storedKey ? decodeURIComponent(storedValue) : acc;
        }, '');

        if (emailCookie) {
            return emailCookie;
        }

        const storedEmail = sessionStorage.getItem(SESSION_STORAGE_EMAIL_KEY);

        return storedEmail;
    });

    const saveEmail = useCallback(
        (email: Nullable<string>) => {
            if (email && VALID_EMAIL_REGEX.test(email)) {
                sessionStorage.setItem(SESSION_STORAGE_EMAIL_KEY, email);
            }

            setEmail(email);
        },
        [sessionStorage]
    );

    return { email, saveEmail };
}

export default useClientCredentials;
