import {
    getAllBoundItemsInCart,
    getBoundItemsFromPackages,
    trackClientEventFromBoundItems
} from 'ts/client/common/analytics/analytics';

export default [
    '$rootScope',
    '$scope',
    '$window',
    '$location',
    '$route',
    '$timeout',
    'spAppData',
    'spHiddenAlbum',
    'SPCartData',
    'SPModal',
    function CartController(
        $rootScope,
        $scope,
        $window,
        $location,
        $route,
        $timeout,
        spAppData,
        spHiddenAlbum,
        SPCartData,
        SPModal
    ) {
        $scope.notifications = [];
        $scope.credentials = {};

        refreshCartData(undefined, () => {
            trackClientEventFromBoundItems(
                'view_cart',
                getAllBoundItemsInCart($scope.cartItems, $scope.packageList),
                $scope.userState.cartPostDiscountSubtotal,
                $rootScope.brandData.currencyCode,
                $scope.eventData.id,
                { coupons: $scope.cartPromoCodes }
            );
        });

        $scope.hasCartData = function hasCartData() {
            if ($scope.cartItems || $scope.packageList) {
                return $scope.cartItems.length ? $scope.cartItems : $scope.packageList;
            }
        };

        $scope.leaveCart = function leaveCart() {
            $location.path('home');
        };

        $scope.updateItemQuantity = function updateItemQuantity(boundItem, photo) {
            updateItem(boundItem, photo);
        };

        $scope.canCrop = function canCrop(boundItem) {
            return $scope.eventData.settings.allowCropping && typeof boundItem.crop === 'object';
        };

        $scope.editProductForCartItem = function editProductForCartItem(
            boundItem,
            photo,
            modalType = ''
        ) {
            let currentCropValues = boundItem.crop;

            if (!$scope.canCrop(boundItem)) {
                return;
            }

            if (boundItem.isActionPending('updateCart')) {
                return;
            }

            SPModal.open(
                'product-edit',
                {
                    type: `side-panel cart-item-product-view ${modalType}`.trim(),
                    partialDirectory: 'add-to-cart',
                    fullscreen: true,
                    modalData: {
                        getEditorItem: () => boundItem,
                        getEditorPhoto: () => photo,
                        handleCropUpdate: (crop) =>
                            (currentCropValues = { ...crop, isChanged: true }),
                        canClose: () => true,
                        submitEdit: () => {
                            boundItem.crop = currentCropValues;
                            updateItem(boundItem, photo, true);
                            SPModal.close();
                        }
                    }
                },
                true
            );
        };

        $scope.editCropForBoundItem = function editCropForBoundItem(boundItem, photo) {
            if (!$scope.canCrop(boundItem)) {
                return;
            }

            if (boundItem.isActionPending('updateCart')) {
                return;
            }

            SPModal.open('crop-photo-item-finish', {
                type: 'side-panel',
                modalData: {
                    boundItem: boundItem,
                    photo: photo,
                    save: function withCrop(crop) {
                        boundItem.crop = crop;

                        updateItem(boundItem, photo, true);

                        SPModal.close();
                    }
                }
            });
        };

        $scope.doesCartHaveDiscounts = function doesCartHaveDiscounts() {
            const cartHasDiscountsBeforeRemoval = $scope.userState.cartHasDiscounts;

            if (typeof $scope.userState.cartHasDiscounts === 'boolean') {
                // If the Cart had no Discounts before this
                // update, but now it does, we need to refresh
                // the Cart items.  Similarly, if the Cart did
                // have Discounts, but now does not, we need
                // to refresh the Cart items.
                if (
                    $scope.userState.cartHasDiscounts ||
                    cartHasDiscountsBeforeRemoval !== $scope.userState.cartHasDiscounts
                ) {
                    return refreshCartData();
                }
            }
        };

        $scope.removePackageItem = function removePackageItem(packageItem) {
            if (packageItem.isActionPending('updateCart')) {
                return;
            }

            SPCartData.removePackageFromCart(
                packageItem,
                function onSuccess() {
                    const packageBoundItems = getBoundItemsFromPackages([packageItem]);
                    trackClientEventFromBoundItems(
                        'remove_from_cart',
                        packageBoundItems,
                        packageItem.price,
                        $rootScope.brandData.currencyCode,
                        $scope.eventData.id,
                        { coupons: $scope.cartPromoCodes }
                    );

                    $scope.doesCartHaveDiscounts();

                    var packageItemIndex = $scope.packageList.indexOf(packageItem);

                    if (packageItemIndex !== -1) {
                        $scope.packageList.splice(packageItemIndex, 1);
                    }

                    if ($scope.packageList.length === 0) {
                        $scope.packageList = [];
                    }

                    updateTotalNumberOfItems();
                },
                function withResponse(response) {
                    packageItem.unsetActionPending('removePrompt');

                    $scope.setNotification(response.msg);
                }
            );
        };

        $scope.removeBoundItem = function removeBoundItem(boundItem, cartItem) {
            if (boundItem.isActionPending('updateCart')) {
                return;
            }

            const boundItemToRemove = angular.copy(boundItem);

            SPCartData.removeFromCart(
                boundItem,
                [cartItem.photo],
                function onSuccess() {
                    trackClientEventFromBoundItems(
                        'remove_from_cart',
                        [boundItemToRemove],
                        boundItem.price,
                        $rootScope.brandData.currencyCode,
                        $scope.eventData.id,
                        { coupons: $scope.cartPromoCodes }
                    );

                    $scope.doesCartHaveDiscounts();

                    var index = cartItem.boundItems.indexOf(boundItem);

                    if (index > -1) {
                        cartItem.boundItems.splice(index, 1);
                    }

                    if (cartItem.boundItems.length === 0) {
                        // remove the photoItem too
                        var cartItemIndex = $scope.cartItems.indexOf(cartItem);

                        if (cartItemIndex !== -1) {
                            $scope.cartItems.splice(cartItemIndex, 1);
                        }
                    }

                    if ($scope.cartItems.length === 0) {
                        $scope.cartItems = [];
                    }

                    updateTotalNumberOfItems();
                },
                function withResponse(response) {
                    boundItem.unsetActionPending('removePrompt');

                    $scope.setNotification(response.msg);
                }
            );
        };

        $scope.checkout = function checkout() {
            SPCartData.prepareCheckout(function withResponse(response) {
                $window.location.href = response.redirect_url;

                trackClientEventFromBoundItems(
                    'begin_checkout',
                    getAllBoundItemsInCart($scope.cartItems, $scope.packageList),
                    $scope.userState.cartPostDiscountSubtotal,
                    $rootScope.brandData.currencyCode,
                    $scope.eventData.id,
                    { coupons: $scope.cartPromoCodes }
                );
            });
        };

        $scope.emptyCart = function emptyCart() {
            const cartSubtotal = $scope.userState.cartPostDiscountSubtotal;

            SPCartData.emptyCart(function closeAndReload() {
                trackClientEventFromBoundItems(
                    'remove_from_cart',
                    getAllBoundItemsInCart($scope.cartItems, $scope.packageList),
                    cartSubtotal,
                    $rootScope.brandData.currencyCode,
                    $scope.eventData.id,
                    { coupons: $scope.cartPromoCodes }
                );

                $route.reload();

                SPModal.close();
            });
        };

        $scope.cancel = function cancel() {
            SPModal.close();
        };

        $scope.applyPromoCode = function applyPromoCode(promoCode, $formScope) {
            $formScope.applyingPromoCode = true;

            SPCartData.addPromoCode(
                promoCode,
                function withResponse(response) {
                    refreshCartData();

                    $scope.setNotification(response.msg);
                },
                function withResponse(response) {
                    $formScope.applyingPromoCode = false;

                    $formScope.error = response.msg;
                }
            );
        };

        $scope.removePromoCode = function removePromoCode(promoCode, $fieldScope) {
            $fieldScope.processing = true;

            SPCartData.removePromoCode(
                promoCode,
                function withResponse(response) {
                    refreshCartData();

                    $scope.setNotification(response.msg);
                },
                function withResponse(response) {
                    $fieldScope.processing = false;
                    $fieldScope.error = response.msg;
                }
            );
        };

        $scope.setNotification = function setNotification(message) {
            $scope.notifications.push({
                message: message
            });
        };

        spHiddenAlbum.persistFlow();

        function refreshCartData(_, completeCallback) {
            $scope.cartPromoCodes = undefined;

            SPCartData.getCartItems(
                function withResponse(response) {
                    if (response.cart === false) {
                        $scope.cartItems = [];
                        $scope.packageList = [];
                    } else {
                        $scope.cartItems = response.cart.items;
                        $scope.cartPromoCodes = response.cart.cartPromoCodes;
                        $scope.packageList = response.cart.packageList;
                    }

                    updateTotalNumberOfItems();

                    if (completeCallback) {
                        completeCallback();
                    }
                },
                function withResponse(response) {
                    $scope.cartItems = [];
                    $scope.packageList = [];
                    $scope.cartPromoCodes = [];
                    $scope.collectEmail =
                        typeof response.cartNeedEmail === 'boolean' && response.cartNeedEmail;

                    updateTotalNumberOfItems();

                    if (completeCallback) {
                        completeCallback();
                    }
                }
            );
        }

        function updateItem(boundItem, photo, force) {
            if (boundItem.isActionPending('updateCart')) {
                return;
            }

            if (typeof boundItem.updateTimer === 'object') {
                $timeout.cancel(boundItem.updateTimer);
            }

            var performUpdate = force === true;

            if (boundItem.quantity !== boundItem.cartQuantity) {
                performUpdate = true;
            }

            if (!performUpdate) {
                return;
            }

            boundItem.updateTimer = $timeout(
                function afterTimeout() {
                    boundItem.updateTimer = undefined;

                    // If value entered by user is empty, just return.  They may have emptied the
                    // field in order to type a new value.
                    if (isNaN(boundItem.quantity)) {
                        return;
                    }

                    SPCartData.addToCart(
                        boundItem,
                        [photo],
                        boundItem.albumId,
                        false,
                        '',
                        $scope.credentials.doesAcceptTerms,
                        refreshCartData,
                        function withResponse(response) {
                            $scope.setNotification(response.msg);
                        }
                    );
                },
                force ? 0 : 1000
            );
        }

        function updateTotalNumberOfItems() {
            if (!$scope.cartItems) {
                return 0;
            }

            var total = 0;

            $scope.cartItems.reduce(function sumTotalBoundItemsInCart(
                previousValue,
                currentValue,
                currentIndex
            ) {
                var cartItem = $scope.cartItems[currentIndex];

                return (total += cartItem.boundItems.length);
            },
            $scope.cartItems[0]);

            $scope.totalNumberOfItems = total;
        }
    }
];
