import React from 'react';
import { useEvent } from 'ts/client/common';
import AddedToCart from 'client_react/gallery/components/AddedToCart';
import { useStore } from 'client_react/gallery/hooks/StoreContext';
import { PageIndex } from 'client_react/gallery/pages/StorePage';

const AddedToCartPage: React.FC = () => {
    const { id: eventId } = useEvent();

    const {
        userSelectedProducts,
        returnToPage,
        setUserSelectedPhotos,
        setUserSelectedProducts,
        spFulfilledDigitalItems
    } = useStore();

    const onKeepShopping = () => {
        setUserSelectedPhotos([]);
        setUserSelectedProducts([]);
        returnToPage(PageIndex.LandingPage);
    };

    return (
        <AddedToCart
            eventId={eventId}
            onKeepShopping={onKeepShopping}
            userSelectedProducts={userSelectedProducts}
            spFulfilledDigitalItems={spFulfilledDigitalItems}
        />
    );
};

export default AddedToCartPage;
