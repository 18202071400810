import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Flex, IconButton } from '@chakra-ui/react';
import { Download, MoreVertical, Send, ShoppingBag } from 'react-feather';
import DownloadDestinations from 'ts/client/gallery/components/DownloadDestinations';
import { getDownloadDestinationsProps } from 'ts/client/gallery/components/common';
import { IToolbarActions } from 'ts/client/types';
import DrawerWithActions from 'ts/common/components/gallery/DrawerWithActions';
import Modal from 'ts/common/components/gallery/Modal';
import Popover from 'ts/common/components/gallery/Popover';
import ShareLinks from 'ts/common/components/gallery/ShareLinks';
import { BuyPackageIcon, CompareIcon, ShareIcon, SlideshowIcon } from 'ts/common/components/icons';
import type { IActionButton } from 'ts/common/types';
import EmailFavoritesModal from './EmailFavoritesModal';
import SendToPhotographerModal from './SendToPhotographerModal';

const VIEW_BOX_FOR_FEATHER = '-2 -2 28 28';

type ToolMode = 'actions' | 'share links' | 'send favorites' | 'download destinations';

interface Props {
    /** True only if the large-screen version should be shown */
    isLargeScreen?: boolean;
    /** True only if one action button should be rendered on a small screen */
    isOnlySingleActionOnSmallScreen?: boolean;
    /** Actions that can be performed from the toolbar */
    actions: IToolbarActions;
    /** True if the user needs to select a download destination for a pending download */
    requiresDownloadDestination?: boolean;
    /** The current path from the URL, excluding the base path of the gallery */
    selectedUri?: Nullable<string>;
    /** A url to be copied to the clipboard when the Share Links Copy Link button is clicked */
    shareCopyLinkUrl: string;
    /** A record of urls to be used for share links */
    shareUrls?: Record<string, string>;
    /** Angular free digitals service */
    spFreeDigitals: SpAngularJs.ISpFreeDigitals;
    /** The Angular translation service */
    translate: SpAngularJs.ITranslateFilter;
}

const Toolbar: FC<Props> = ({
    isLargeScreen,
    isOnlySingleActionOnSmallScreen,
    actions: {
        canBuyAll,
        buyAll,
        canBuyPackage,
        buyPackage,
        canComparePhotos,
        comparePhotos,
        canDownloadAll,
        downloadAll,
        setDownloadDestination,
        getRemainingDownloads,
        cancelDownload,
        canShare,
        emailFavorites,
        canSendFavoritesToPhotographer,
        sendFavoritesToPhotographer,
        canStartSlideshow,
        startSlideshow
    },
    requiresDownloadDestination,
    selectedUri,
    shareCopyLinkUrl,
    shareUrls,
    spFreeDigitals,
    translate
}) => {
    const [toolMode, setToolMode] = useState<ToolMode>();
    const [isEmailFavoritesOpen, setIsEmailFavoritesOpen] = useState(false);
    const [isSendToPhotographerOpen, setIsSendToPhotographerOpen] = useState(false);

    useEffect(() => {
        if (requiresDownloadDestination) {
            setToolMode('download destinations');
        }
    }, [requiresDownloadDestination]);

    const handleDownloadAll = useCallback(() => {
        downloadAll();

        if (requiresDownloadDestination) {
            setToolMode('download destinations');
        }
    }, [downloadAll, requiresDownloadDestination]);

    const { toolbarActions, drawerActions } = useMemo(() => {
        const toolbarActions: IActionButton[] = [];
        const drawerActions: IActionButton[] = [];

        const aboveTheFoldCount = isOnlySingleActionOnSmallScreen ? 1 : 2;
        const isFavoritesSelected = selectedUri === 'favorites';

        function pushAction(action: IActionButton) {
            if (isLargeScreen || toolbarActions.length < aboveTheFoldCount) {
                // High priority items are to the right if there is no kebab button, otherwise they are on the left
                !isLargeScreen ? toolbarActions.push(action) : toolbarActions.unshift(action);
            } else {
                // High priority items are at the bottom of the drawer
                drawerActions.unshift(action);
            }
        }

        // Iterate from highest priority to lowest
        if (canBuyAll()) {
            pushAction({
                key: 'buyAllPhotos',
                icon: <ShoppingBag width="24px" viewBox={VIEW_BOX_FOR_FEATHER} />,
                text: translate('buyAllPhotos'),
                onClick: buyAll
            });
        }

        if (canBuyPackage()) {
            pushAction({
                key: 'buyPackage',
                icon: <BuyPackageIcon width="24px" />,
                text: translate('buyPackage'),
                onClick: buyPackage
            });
        }

        if (canDownloadAll()) {
            pushAction({
                key: 'downloadAll',
                icon: <Download width="24px" viewBox={VIEW_BOX_FOR_FEATHER} />,
                text: translate('downloadAll'),
                keepOpen: requiresDownloadDestination,
                onClick: handleDownloadAll
            });
        }

        const onShareClick = isFavoritesSelected
            ? canShare() &&
              (() => {
                  setToolMode('send favorites');
              })
            : shareUrls &&
              (() => {
                  setToolMode('share links');
              });

        if (onShareClick) {
            pushAction({
                key: isFavoritesSelected ? 'sendFavorites' : 'share',
                icon: isFavoritesSelected ? (
                    <Send width="24px" viewBox={VIEW_BOX_FOR_FEATHER} />
                ) : (
                    <ShareIcon width="24px" />
                ),
                text: translate(isFavoritesSelected ? 'sendFavorites' : 'share'),
                keepOpen: true,
                onClick: onShareClick
            });
        }

        if (canComparePhotos()) {
            pushAction({
                key: 'comparePhotos',
                icon: <CompareIcon width="24px" />,
                text: translate('comparePhotos'),
                onClick: comparePhotos
            });
        }

        if (canStartSlideshow()) {
            pushAction({
                key: 'slideshow',
                icon: <SlideshowIcon width="24px" viewBox="2 0 20 24" />,
                text: translate('slideshow'),
                onClick: startSlideshow
            });
        }

        return {
            toolbarActions,
            drawerActions
        };
    }, [
        isOnlySingleActionOnSmallScreen,
        selectedUri,
        canBuyAll,
        canBuyPackage,
        canDownloadAll,
        canShare,
        shareUrls,
        canComparePhotos,
        canStartSlideshow,
        isLargeScreen,
        translate,
        buyAll,
        buyPackage,
        requiresDownloadDestination,
        handleDownloadAll,
        comparePhotos,
        startSlideshow
    ]);

    const handleKebabClick = () => {
        setToolMode('actions');
    };

    const handleClose = () => {
        setToolMode(undefined);
    };

    const handleCancel = () => {
        handleClose();
        cancelDownload();
    };

    const sendFavoritesActions: IActionButton[] = useMemo(() => {
        const actions = [
            {
                key: 'sendFavoritesToOther',
                text: translate('sendFavoritesToOther'),
                onClick: () => setIsEmailFavoritesOpen(true)
            }
        ];

        if (canSendFavoritesToPhotographer()) {
            actions.push({
                key: 'sendFavoritesToPhotographer',
                text: translate('sendFavoritesToPhotographer'),
                onClick: () => setIsSendToPhotographerOpen(true)
            });
        }

        return actions;
    }, [canSendFavoritesToPhotographer, translate]);

    return (
        <Flex
            flex="1 1 auto"
            justifyContent={isLargeScreen ? 'flex-end' : 'space-between'}
            width={isLargeScreen ? undefined : '100%'}
        >
            <Flex>
                {toolbarActions.map((action) => {
                    const button = (
                        <Button
                            key={action.key}
                            fontSize={isLargeScreen ? 'sm' : 'md'}
                            leftIcon={action.icon}
                            onClick={action.onClick}
                            padding="12px"
                            variant="ghost"
                        >
                            {action.text}
                        </Button>
                    );

                    if (action.key === 'share' && isLargeScreen) {
                        return (
                            <Popover
                                key={action.key}
                                isOpen={toolMode === 'share links'}
                                onClose={handleClose}
                                placement="bottom"
                                triggerElement={button}
                            >
                                <ShareLinks
                                    copyLinkHref={shareCopyLinkUrl}
                                    headingText={translate('shareGallery')}
                                    linkHrefs={shareUrls}
                                    onClickLink={handleClose}
                                    translateFilter={translate}
                                />
                            </Popover>
                        );
                    }

                    if (action.key === 'sendFavorites' && isLargeScreen) {
                        return (
                            <Popover
                                key={action.key}
                                isOpen={toolMode === 'send favorites'}
                                onClose={handleClose}
                                placement="bottom"
                                triggerElement={button}
                                variant="small"
                            >
                                <Flex flexFlow="column nowrap">
                                    {sendFavoritesActions.map((sendFavoritesAction) => (
                                        <Button
                                            key={sendFavoritesAction.key}
                                            variant="ghost"
                                            justifyContent="left"
                                            onClick={sendFavoritesAction.onClick}
                                        >
                                            {sendFavoritesAction.text}
                                        </Button>
                                    ))}
                                </Flex>
                            </Popover>
                        );
                    }

                    return button;
                })}
            </Flex>
            {drawerActions.length > 0 && (
                <IconButton
                    icon={<MoreVertical />}
                    aria-label={translate('moreActions')}
                    variant="ghost"
                    onClick={handleKebabClick}
                />
            )}
            {!isLargeScreen && (
                <DrawerWithActions
                    actions={toolMode === 'send favorites' ? sendFavoritesActions : drawerActions}
                    isOpen={toolMode !== undefined}
                    isShareLinksVisible={toolMode === 'share links'}
                    onCancel={handleCancel}
                    onClose={handleClose}
                    shareCopyLinkUrl={shareCopyLinkUrl}
                    shareHeadingText={translate('shareGallery')}
                    shareUrls={shareUrls}
                    isDownloadDestinationsVisible={toolMode === 'download destinations'}
                    downloadDestinationsProps={getDownloadDestinationsProps(
                        { setDownloadDestination, getRemainingDownloads },
                        handleClose
                    )}
                    spFreeDigitals={spFreeDigitals}
                    translateFilter={translate}
                />
            )}
            {isLargeScreen && (
                <Modal
                    isOpen={toolMode === 'download destinations'}
                    onClose={handleCancel}
                    size="sm"
                >
                    <DownloadDestinations
                        {...getDownloadDestinationsProps(
                            { setDownloadDestination, getRemainingDownloads },
                            handleClose
                        )}
                        spFreeDigitals={spFreeDigitals}
                        translateFilter={translate}
                    />
                </Modal>
            )}
            {canShare() && (
                <EmailFavoritesModal
                    isOpen={isEmailFavoritesOpen}
                    onSubmit={emailFavorites}
                    onClose={() => {
                        setToolMode(undefined);
                        setIsEmailFavoritesOpen(false);
                    }}
                    translate={translate}
                />
            )}
            {canShare() && (
                <SendToPhotographerModal
                    isOpen={isSendToPhotographerOpen}
                    onSubmit={sendFavoritesToPhotographer}
                    onClose={() => {
                        setToolMode(undefined);
                        setIsSendToPhotographerOpen(false);
                    }}
                    translate={translate}
                />
            )}
        </Flex>
    );
};

export default Toolbar;
