import React, { FC, ReactElement } from 'react';
import {
    Popover as PopoverBase,
    PopoverBody,
    PopoverContent,
    PopoverProps,
    PopoverTrigger
} from '@chakra-ui/react';

interface Props extends PopoverProps {
    children?: React.ReactNode;
    triggerElement: ReactElement;
}

const Popover: FC<Props> = ({ children, triggerElement, ...rest }) => {
    return (
        <PopoverBase gutter={0} {...rest}>
            <PopoverTrigger>{triggerElement}</PopoverTrigger>
            <PopoverContent>
                <PopoverBody>{children}</PopoverBody>
            </PopoverContent>
        </PopoverBase>
    );
};

export default Popover;
