import {
    getItemsAndSubgroupBySubgroupId,
    sortByDisplayOrder
} from 'ts/client/gallery/components/common';
import type { ProductOption } from '../types';

export const mapItemToFrame = (
    priceSheetItem: SpApi.Client.IPriceSheetItem
): ProductOption['frame'] => {
    return priceSheetItem.productMasks?.frameImageUrl
        ? { imageUrl: priceSheetItem.productMasks?.frameImageUrl }
        : priceSheetItem.frameColor
        ? { color: priceSheetItem.frameColor }
        : undefined;
};

export const getFrameOptions = (
    priceSheetGroup: SpApi.Client.IPriceSheetGroup,
    priceSheetSubgroupId?: number
): Array<ProductOption> => {
    const itemsBySubgroup = Object.values(getItemsAndSubgroupBySubgroupId(priceSheetGroup)).sort(
        (a, b) => a.subgroup.displayOrder - b.subgroup.displayOrder
    );

    const selectedSubgroupIndex = itemsBySubgroup.findIndex(
        ({ subgroup }) => subgroup.id === priceSheetSubgroupId
    );

    if (selectedSubgroupIndex === -1) {
        return [];
    }

    // Assign a display order to each item, based on its order within its ordered subgroup.
    itemsBySubgroup.forEach((value, index, array) => {
        const previousMaxDisplayOrder =
            array[index - 1]?.items.reduce(
                (maxDisplayOrder, { displayOrder }) =>
                    displayOrder > maxDisplayOrder ? displayOrder : maxDisplayOrder,
                0
            ) ?? 0;

        value.items.forEach((item) => {
            item.displayOrder = item.displayOrder + previousMaxDisplayOrder;
        });
    });

    // Maintain a constant display order for frame options, despite the selected size.
    const displayOrderMap = itemsBySubgroup.reduce<Record<string, number>>((result, { items }) => {
        items.forEach((priceSheetItem) => {
            if (!result[priceSheetItem.name]) {
                result[priceSheetItem.name] = priceSheetItem.displayOrder;
            }
        });

        return result;
    }, {});

    // Prioritize the selected size, then the remaining sizes in modulo order.
    const itemsBySubgroupPrioritized = [
        ...itemsBySubgroup.slice(selectedSubgroupIndex),
        ...itemsBySubgroup.slice(0, selectedSubgroupIndex)
    ];

    // Generate a list of frame options bound to the selected size or next available size.
    const productOptions: Array<ProductOption> = sortByDisplayOrder(
        Object.values(
            itemsBySubgroupPrioritized.reduce<Record<string, SpApi.Client.IPriceSheetItem>>(
                (result, { items }) => {
                    items.forEach((priceSheetItem) => {
                        if (!result[priceSheetItem.name]) {
                            result[priceSheetItem.name] = {
                                ...priceSheetItem,
                                displayOrder:
                                    displayOrderMap[priceSheetItem.name] ??
                                    priceSheetItem.displayOrder
                            };
                        }
                    });

                    return result;
                },
                {}
            )
        )
    ).map(
        (priceSheetItem): ProductOption => ({
            name: priceSheetItem.name,
            priceSheetItemId: priceSheetItem.id,
            priceSheetSubgroupId: priceSheetItem.subgroup?.id,
            frame: mapItemToFrame(priceSheetItem)
        })
    );

    return productOptions;
};
