import React, { Attributes, FC, MouseEventHandler, memo, useCallback } from 'react';
import { Box, Image } from '@chakra-ui/react';
import type { IImage } from 'ts/client/gallery/components';
import type { IPhotoDimensions } from 'ts/client/types';

interface Props<OverlayProps> {
    /** An optional React component to supply for the overlay for each item */
    PhotoOverlayComponent?: FC<OverlayProps>;
    /** True only if the contents of this grid item should be rendered */
    isInView: boolean;
    /** Callback when the photo is clicked */
    onClick?: (photo: IImage) => void;
    /** The photo for this Grid Item */
    photo: IImage;
    /** Dimensions for rendering the given photo within the overall grid */
    photoDimensions: IPhotoDimensions;
    /** The optional function to provide the props to the Overlay component, if supplied */
    getPhotoOverlayProps?: (photo: IImage) => OverlayProps;
}

const handleContextMenu: MouseEventHandler = (event) => event.preventDefault();

const PhotoGridItem: FC<Props<Attributes>> = <OverlayProps extends Attributes>({
    PhotoOverlayComponent,
    isInView,
    onClick,
    photo,
    photoDimensions,
    getPhotoOverlayProps
}: Props<OverlayProps>) => {
    const handleClick = useCallback(() => {
        if (onClick) {
            onClick(photo);
        }
    }, [onClick, photo]);

    return (
        <Box
            id={`photoId-${photo.id}`}
            data-testid="photo-grid-item"
            cursor={'pointer'}
            onClick={handleClick}
            outline="1px solid var(--chakra-colors-gray-100)"
            outlineOffset="-1px"
            position="absolute"
            top={`${photoDimensions.top}px`}
            left={`${photoDimensions.left}px`}
            width={`${photoDimensions.width}px`}
            height={`${photoDimensions.height}px`}
            visibility={isInView ? 'visible' : 'hidden'}
            sx={{ contentVisibility: isInView ? 'visible' : 'hidden' }}
        >
            {isInView && (
                <>
                    <Image
                        alt={photo.url}
                        src={photo.url}
                        onContextMenu={handleContextMenu}
                        filter={photo.filterBlackAndWhite ? 'grayscale(1)' : undefined}
                        position="absolute"
                        width="100%"
                        height="100%"
                    />
                    {PhotoOverlayComponent && getPhotoOverlayProps && (
                        <PhotoOverlayComponent {...getPhotoOverlayProps(photo)} />
                    )}
                </>
            )}
        </Box>
    );
};

export default memo(PhotoGridItem);
