import { SP_API_HTTP_EVENTS } from 'Sp/Angular/Events/Common';

export default [
    '$scope',
    'spFreeDigitals',
    function SelectDigitalRuleController($scope, spFreeDigitals) {
        let allDigitalRules;

        $scope.showGenericError = false;
        $scope.showPinForm = false;
        $scope.showRateLimitError = false;

        $scope.canExportToGoogle = canExportToGoogle;
        $scope.captureAnotherPin = captureAnotherPin;
        $scope.doDisplayDownloadLimit = doDisplayDownloadLimit;
        $scope.isDownloadPrevented = isDownloadPrevented;
        $scope.onCaptureCredentials = onCaptureCredentials;
        $scope.hasPins = hasPins;
        $scope.isADigitalRuleSelected = isADigitalRuleSelected;
        $scope.isLoading = isLoading;
        $scope.numberOfDownloadsRemaining = numberOfDownloadsRemaining;
        $scope.selectDigitalRule = selectDigitalRule;

        $scope.$on(SP_API_HTTP_EVENTS.INTERNAL, () => {
            resetFormSubmission();

            $scope.showGenericError = true;
        });

        $scope.$on(SP_API_HTTP_EVENTS.TOO_MANY_REQUESTS, () => {
            resetFormSubmission();

            $scope.showRateLimitError = true;
        });

        init();

        function init() {
            setDigitalRules(spFreeDigitals.getDigitalRules());
        }

        function canExportToGoogle() {
            return spFreeDigitals.canExportToGoogle();
        }

        function captureAnotherPin() {
            $scope.showPinForm = true;
        }

        function doDisplayDownloadLimit(digitalRule) {
            return digitalRule.downloadLimit > 0 && !hasDownloadedPhotoPreviously(digitalRule);
        }

        function isDownloadPrevented(isFormSubmitted, digitalRule) {
            if (isFormSubmitted) {
                return true;
            } else if (hasDownloadedPhotoPreviously(digitalRule)) {
                return false;
            }

            return numberOfDownloadsRemaining(digitalRule) === 0;
        }

        function hasDownloadedPhotoPreviously(digitalRule) {
            return digitalRule.downloadedPhotoIds.includes(
                spFreeDigitals.getPendingDownloadData().photoId
            );
        }

        function hasPins() {
            return spFreeDigitals.hasPins();
        }

        function isADigitalRuleSelected() {
            return spFreeDigitals.hasSelectedADigitalRule();
        }

        function isLoading() {
            const isApiErrorShown = $scope.showGenericError || $scope.showRateLimitError;
            const { $submitted: isFormSubmitted } = $scope.selectDigitalRuleForm;

            return isFormSubmitted || (isADigitalRuleSelected() && !isApiErrorShown);
        }

        function numberOfDownloadsRemaining(digitalRule) {
            if (digitalRule.downloadLimit === null) {
                return null;
            }

            const numRemaining = digitalRule.downloadLimit - digitalRule.downloadLimitUsed;

            if (numRemaining < 0) {
                return 0;
            }

            return numRemaining;
        }

        function onCaptureCredentials() {
            spFreeDigitals.captureAnotherPin(setDigitalRules);
        }

        function resetFormSubmission() {
            $scope.selectDigitalRuleForm.$submitted = false;
        }

        function selectDigitalRule(selectedDigitalRule, downloadDestination) {
            spFreeDigitals.selectDigitalRule(selectedDigitalRule, downloadDestination);
        }

        function setDigitalRules(digitalRules) {
            allDigitalRules = digitalRules;

            $scope.isMoreThanOneDigitalRule = digitalRules.length > 1;

            $scope.digitalRules = allDigitalRules.filter(
                (digitalRule) => spFreeDigitals.isTryingToDownloadPhoto() || digitalRule.downloadAll
            );

            $scope.showPinForm = false;
        }
    }
];
