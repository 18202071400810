import React, { useLayoutEffect, useRef } from 'react';
import { Flex, Heading, Text, useBreakpointValue } from '@chakra-ui/react';
import GalleryHeading from 'ts/common/components/gallery/GalleryHeading';
import { useTranslate } from 'ts/common/hooks';
import { IBound, IBoundItem } from '../types';
import CardImage from './CardImage';

interface ISelectCardTemplateSlideProps {
    cardCategory?: IBound;
    onCardProductSelected: (cardProduct: IBoundItem) => void;
    previouslySelectedCardProduct?: IBoundItem;
}

const SelectCardTemplateSlide: React.FC<ISelectCardTemplateSlideProps> = ({
    cardCategory,
    onCardProductSelected,
    previouslySelectedCardProduct
}) => {
    const previousSelectedProductRef = useRef<HTMLDivElement>(null);
    const isMediumScreenOrLarger = useBreakpointValue({ base: false, md: true });

    useLayoutEffect(() => {
        setTimeout(() => {
            if (previousSelectedProductRef.current) {
                previousSelectedProductRef.current.scrollIntoView();
            }
        }, 50);
    }, [previousSelectedProductRef]);

    const t = useTranslate('client.gallery.addToCartSidePanel.selectCardTemplateSlide');

    const gap = 16;

    return (
        <Flex flexDirection="column" gap="24px" textAlign="center" padding="48px 20px">
            <GalleryHeading
                size="sm"
                text={t('heading')}
                variant="primary"
                data-testid="select-card-template-heading"
            />
            <Text data-testid="select-card-template-description">{t('description')}</Text>
            <Flex flexWrap="wrap" gap={`${gap}px`}>
                {cardCategory?.items.map((cardItem) => (
                    <Flex
                        alignItems="center"
                        flexDirection="column"
                        gap="5px"
                        key={cardItem.whcc_id}
                        width={isMediumScreenOrLarger ? `calc(50% - ${gap / 2}px)` : '100%'}
                        ref={
                            previouslySelectedCardProduct &&
                            previouslySelectedCardProduct.whcc_id === cardItem.whcc_id
                                ? previousSelectedProductRef
                                : undefined
                        }
                    >
                        <CardImage
                            frontImageUrl={cardItem.description_images[0].t}
                            backImageUrl={cardItem.description_images[1].t}
                            onClick={() => onCardProductSelected(cardItem)}
                        />

                        <Heading
                            color="brand.primary"
                            data-testid="card-template-heading"
                            size="md"
                            textTransform="capitalize"
                        >
                            {cardItem.name}
                        </Heading>
                    </Flex>
                ))}
            </Flex>
        </Flex>
    );
};

export default SelectCardTemplateSlide;
