import React from 'react';
import { BoxProps, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import Product from './Product';
import { IImage } from './types';

interface IProductRenderGridProps {
    images: IImage[];
    selectedPriceSheetItem?: SpApi.Client.IPriceSheetItem;
}

const ProductRenderGrid: React.FC<IProductRenderGridProps & BoxProps> = ({
    images,
    selectedPriceSheetItem,
    ...props
}) => {
    return (
        <Flex width={'100%'} background={'gray.25'} backgroundSize={'cover'} {...props}>
            <Grid
                data-testid="product-render-grid"
                gap={4}
                templateColumns="repeat(4, 1fr)"
                width={'100%'}
            >
                {images.slice(0, 11).map((image, index) => {
                    return (
                        <GridItem key={`product-render-item-${index}`}>
                            {selectedPriceSheetItem && (
                                <Product
                                    image={image}
                                    priceSheetItem={
                                        selectedPriceSheetItem.type === 'price-sheet-item-package'
                                            ? {
                                                  ...selectedPriceSheetItem,
                                                  group: {
                                                      ...selectedPriceSheetItem.group,
                                                      groupType: 'fine-art'
                                                  }
                                              }
                                            : {
                                                  ...selectedPriceSheetItem,
                                                  group: {
                                                      ...selectedPriceSheetItem.group,
                                                      groupType: 'fine-art'
                                                  },
                                                  size: {
                                                      width: 1,
                                                      height: 1,
                                                      depth: 0
                                                  }
                                              }
                                    }
                                    borderRadius="0"
                                    boxShadow="0px 3px 3px 0px rgba(0, 0, 0, 0.1)"
                                />
                            )}
                        </GridItem>
                    );
                })}
                {images.length > 11 && (
                    <GridItem border="2px solid">
                        <Flex
                            width="100%"
                            height="100%"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Text fontSize="inherit">{`+ ${images.length - 11}`}</Text>
                        </Flex>
                    </GridItem>
                )}
            </Grid>
        </Flex>
    );
};

export default ProductRenderGrid;
