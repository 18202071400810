import { EMAIL_REGEX } from 'Sp/Regex';

export default [
    '$scope',
    'SPModal',
    'SPClientData',
    'SPClientRoute',
    function CollectEmailController($scope, SPModal, SPClientData, SPClientRoute) {
        $scope.credentials = {};
        $scope.emailPattern = EMAIL_REGEX.VALID_EMAIL;

        $scope.submitEmail = function submitEmail(email, doesAcceptTerms) {
            SPClientData.setEmail(
                email,
                doesAcceptTerms,
                function onSuccess() {
                    SPModal.close();
                    SPClientRoute.reload();
                },
                function onError(response) {
                    if (response.status === 'form_errors') {
                        $scope.errors = response.errors;
                    }
                }
            );
        };

        $scope.closeModal = function closeModal() {
            SPModal.close();
        };
    }
];
