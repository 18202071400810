import React from 'react';
import { Flex } from '@chakra-ui/react';
import PhotoIndicator from './PhotoIndicator';

type PhotoIndicatorListProps = {
    size?: 'sm' | 'lg';
    isBlackAndWhite: boolean;
    isInCart: boolean;
    isFavorite: boolean;
    isHidden: boolean;
    isLabeled: boolean;
};

const PhotoIndicatorList = ({
    size = 'lg',
    isBlackAndWhite,
    isInCart,
    isFavorite,
    isHidden,
    isLabeled
}: PhotoIndicatorListProps) => {
    const gap = size === 'sm' ? '2px' : '4px';
    const padding = size === 'sm' ? '2px' : '4px';
    return (
        <Flex justifyContent="flex-end" gap={gap} padding={padding} flexWrap="wrap" flexGrow={1}>
            {isBlackAndWhite && <PhotoIndicator kind="bw" size={size} />}
            {isInCart && <PhotoIndicator kind="cart" size={size} />}
            {isFavorite && <PhotoIndicator kind="favorite" size={size} />}
            {isHidden && <PhotoIndicator kind="hidden" size={size} />}
            {isLabeled && <PhotoIndicator kind="label" size={size} />}
        </Flex>
    );
};

export default PhotoIndicatorList;
