import React, { FC } from 'react';
import type { IPhoto, ISPPhotoService } from 'ts/client/types';
import CoverPhotoList from './CoverPhotoList';

export interface ILabel {
    id: number;
    name: string;
    photos?: IPhoto[];
}

interface Props {
    getLabelCaption: (labelId: number, photos?: IPhoto[]) => string;
    getPhotoUrl: ISPPhotoService['getUrl'];
    labels: ILabel[];
}

const LabelList: FC<Props> = ({ getLabelCaption, getPhotoUrl, labels }) => (
    <CoverPhotoList
        items={labels.map((label) => ({
            caption: getLabelCaption(label.id, label.photos),
            coverPhoto: label.photos?.[0] && {
                ...label.photos[0],
                url: getPhotoUrl(label.photos[0], 'l'),
                focalPoint: { x: 50, y: 50 }
            },
            href: `label/${label.name}`,
            id: label.id,
            name: label.name
        }))}
    />
);

export default LabelList;
