import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box } from '@chakra-ui/react';
import type { LandingLayoutType } from 'ts/client/types';
import { LoadingIndicator } from 'ts/common/components';
import useCoverPhotoUrl from 'ts/common/hooks/useCoverPhotoUrl';
import type { ICoverPhoto } from 'ts/common/types';
import { noop } from 'ts/common/utils';
import BaseLayout from 'client_react/gallery/layouts/BaseLayout';

export interface IIntroProps {
    landingLayoutType: LandingLayoutType;
    galleryTitle: string;
    brandName: string;
    brandTheme?: SpApi.IBrandTheme;
    coverPhoto: ICoverPhoto | false;
    isAdminRoute: boolean;
    isPreRegister: boolean;
    translateFilter: SpAngularJs.ITranslateFilter;
    onClick: () => void;
}

const Intro: FC<IIntroProps> = ({
    brandTheme,
    isPreRegister,
    isAdminRoute,
    translateFilter: t,
    ...layoutProps
}) => {
    const { coverPhoto } = layoutProps;
    const [isCoverPhotoLoaded, setIsCoverPhotoLoaded] = useState(false);

    const coverPhotoUrl = useCoverPhotoUrl(coverPhoto);

    useEffect(() => {
        let imageLoader: HTMLImageElement | undefined;

        if (coverPhotoUrl) {
            imageLoader = new Image();

            imageLoader.src = coverPhotoUrl;

            imageLoader.onload = () => {
                setIsCoverPhotoLoaded(true);
            };
        } else {
            setIsCoverPhotoLoaded(true);
        }

        return () => {
            if (imageLoader) {
                imageLoader.onload = noop;
            }
        };
    }, [coverPhotoUrl]);

    const buttonTextLarge = useMemo(() => {
        if (isPreRegister && !isAdminRoute) {
            return t('register');
        }

        if (isPreRegister && isAdminRoute) {
            return t('enterAdminMode');
        }

        return t('viewGallery');
    }, [t, isAdminRoute, isPreRegister]);

    const buttonText = useMemo(() => {
        if (isPreRegister && !isAdminRoute) {
            return t('register');
        }

        if (isPreRegister && isAdminRoute) {
            return t('enter');
        }

        return t('viewGallery');
    }, [t, isAdminRoute, isPreRegister]);

    if (!isCoverPhotoLoaded || !brandTheme) {
        return (
            <Box
                position="fixed"
                transform="translate(-50%, -50%)"
                top="50vh"
                left="50vw"
                width="full"
            >
                <LoadingIndicator squareClassName="theme-background-color-primary" />
            </Box>
        );
    }

    return (
        <BaseLayout
            {...layoutProps}
            buttonText={buttonText}
            buttonTextLarge={buttonTextLarge}
            colorScheme={brandTheme.colorScheme}
            coverPhotoUrl={coverPhotoUrl}
            eventBrandLogo={brandTheme.eventBrandLogo}
            logoAltText={brandTheme.logoAltText}
        />
    );
};

export default Intro;
