import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Divider, Skeleton, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useEvent, useGalleryApiFetch } from 'ts/client/common';
import { trackViewItemEvent } from 'ts/client/common';
import {
    OptionSelect,
    ProductDescriptionAccordion,
    ProductImages
} from 'ts/client/gallery/components';
import {
    convertIDataPhotoToIPhoto,
    getFirstItemByDisplayOrder,
    getItemDisplayName,
    getProductSizes
} from 'ts/client/gallery/components/common';
import FrameOptionsGroup from 'ts/client/gallery/components/frames/FrameOptionsGroup';
import { useAddToCart, useCurrency } from 'ts/client/gallery/hooks';
import { ISPPhotoService } from 'ts/client/types';
import { getCropStringFromCrop, getMaxCrop, getSize } from 'ts/common';
import RadioGroupGrid from 'ts/common/components/RadioGroupGrid';
import { Button, GalleryHeading } from 'ts/common/components/gallery';
import { useTranslate } from 'ts/common/hooks';
import { isFramedCategory } from 'ts/common/logic';
import type { ICrop } from 'ts/common/types';
import { areEqualSize, isSingleItem } from '../common';
import {
    GroupType,
    IBoundItem,
    IDataResponse,
    IImage,
    IPathContext,
    IPriceSheetDataResponse
} from '../types';
import { IFormValues } from '../types';
import Footer from './Footer';
import QuantityStepper from './QuantityStepper';

interface IBuyAllPhotosContainer {
    albumPhotos: IImage[];
    eventPhotos: IImage[];
}

interface ISelectProductOptionsSlideProps {
    addToCart: (
        priceSheetItemId: number,
        quantity: number,
        buyAllImagesState: IImage[],
        orderPrice?: number,
        digitalItem?: Nullable<IBoundItem>
    ) => void;
    crop: Nullable<ICrop>;
    setDigitalOrderPrice: (number) => void;
    setTrackingData: ({ buyAllImagesState, digitalItem }) => void;
    getPathContext: () => IPathContext;
    getPhotoUrl: ISPPhotoService['getUrl'];
    goToEmailCaptureAddToCartSlide: (
        priceSheetItemId: number,
        quantity: number,
        crop: Nullable<ICrop>
    ) => void;
    image: IImage;
    buyAllImages: IImage[];
    lastCroppedPriceSheetItem: Nullable<SpApi.Client.IPriceSheetItem>;
    onCropEdit: (
        quantity: number,
        crop: Nullable<ICrop>,
        selectedPriceSheetItemForEditCropSlide: Nullable<SpApi.Client.IPriceSheetItem>
    ) => void;
    priceSheetData: Nullable<IPriceSheetDataResponse>;
    priceSheetGroup: Nullable<SpApi.Client.IPriceSheetGroup>;
    priceSheetItem: Nullable<SpApi.Client.IPriceSheetItem>;
    pushGoogleAnalyticsDataCallback: (
        quantity: number,
        orderPrice?: number,
        priceSheetItem?: SpApi.Client.IPriceSheetItem,
        buyAllImagesState?: IImage[],
        digitalItem?: Nullable<IBoundItem>,
        eventSlug?: string
    ) => void;
}

const SelectProductOptionsSlide: React.FC<ISelectProductOptionsSlideProps> = ({
    addToCart,
    crop,
    setDigitalOrderPrice,
    setTrackingData,
    getPathContext,
    getPhotoUrl,
    goToEmailCaptureAddToCartSlide,
    image,
    buyAllImages,
    lastCroppedPriceSheetItem,
    onCropEdit,
    priceSheetData,
    priceSheetGroup,
    priceSheetItem,
    pushGoogleAnalyticsDataCallback
}) => {
    const $ = useCurrency();
    const t = useTranslate('client.gallery.addToCartSidePanel.selectProductOptionsSlide');
    const [selectedPriceSheetItem, setSelectedPriceSheetItem] =
        useState<SpApi.Client.IPriceSheetItem>();
    const [selectedPriceSheetItemCrop, setSelectedPriceSheetItemCrop] =
        useState<Nullable<ICrop>>(crop);
    const { settings } = useEvent();
    const { context, contextId } = getPathContext();
    const isInAlbum = context === 'album' && contextId !== 'all';
    const isInMarkedContext = ['favorites', 'hidden', 'label'].includes(context);
    const isBuyingAllPhotos = !!buyAllImages.length;
    const isDigitalItem = priceSheetItem && priceSheetItem.group.groupType === 'digitals';
    const isDigitalDownload =
        !!priceSheetItem &&
        priceSheetItem.group.groupType === 'digitals' &&
        priceSheetItem.type === 'price-sheet-item-digital' &&
        priceSheetItem.isDigitalDownload;
    const [digitalItem, setDigitalItem] = useState<Nullable<IBoundItem>>(null);
    const firstItemInGroupByDisplayOrder = useMemo(
        () => priceSheetGroup && getFirstItemByDisplayOrder(priceSheetGroup),
        [priceSheetGroup]
    );
    const [buyAllPhotosContainer, setBuyAllPhotosContainer] = useState<IBuyAllPhotosContainer>({
        albumPhotos: isInAlbum ? buyAllImages : [],
        eventPhotos: isInAlbum || isInMarkedContext ? [] : buyAllImages
    });
    const [buyAllImagesState, setBuyAllImagesState] = useState<IImage[]>(
        isDigitalDownload ? [] : buyAllImages
    );
    const productSizes = useMemo(
        () => priceSheetGroup && getProductSizes(priceSheetGroup),
        [priceSheetGroup]
    );
    const sizeOptionsForRadioGrid =
        productSizes?.map((productSize) => {
            return { name: productSize.size, value: productSize.priceSheetSubgroupId };
        }) ?? [];

    const initialPriceSheetItem =
        lastCroppedPriceSheetItem ?? priceSheetItem ?? firstItemInGroupByDisplayOrder;
    const initialPriceSheetItemRef = useRef(initialPriceSheetItem);

    const digitalsGroup = priceSheetData?.groups.find((group) => group.type === GroupType.DIGITALS);
    const spFulfilledDigitalItems = digitalsGroup?.bounds.find(
        (bound) => bound.digital_type === 'sp'
    )?.items;

    const {
        formState: { defaultValues, isSubmitting, isValid },
        handleSubmit,
        setValue,
        watch
    } = useForm<IFormValues>({
        defaultValues: {
            priceSheetItemId: initialPriceSheetItem?.id,
            priceSheetSubgroupId: initialPriceSheetItem?.subgroup?.id,
            quantity: 1
        },
        mode: 'onTouched'
    });

    const {
        loading: loadingImageData,
        performFetch: fetchImageData,
        response: imageDataResponse
    } = useGalleryApiFetch<IDataResponse>('data', {
        defer: true
    });

    const { postToCart, postToCartResponse } = useAddToCart();

    const { priceSheetItemId, quantity } = watch();

    let orderPrice;

    if (isDigitalDownload) {
        if (spFulfilledDigitalItems) {
            const digitalItem = spFulfilledDigitalItems.find(
                (digitalItem) => digitalItem.id === priceSheetItemId.toString()
            );

            if (digitalItem) {
                if (isBuyingAllPhotos) {
                    orderPrice = digitalItem.is_individual
                        ? parseFloat(digitalItem.price) * buyAllImagesState.length
                        : parseFloat(digitalItem.price);
                } else {
                    orderPrice = parseFloat(digitalItem.price) * quantity;
                }
            }
        }
    } else {
        orderPrice = (selectedPriceSheetItem?.price ?? 0) * quantity;

        if (isBuyingAllPhotos) {
            orderPrice = orderPrice * buyAllImagesState.length;
        }
    }

    const getHeadingText = () => {
        if (isBuyingAllPhotos) {
            return `${t('buyAll')} ${buyAllImagesState.length} ${t('pictures')}`;
        }

        if (priceSheetGroup) {
            return priceSheetGroup.name;
        }

        if (priceSheetItem) {
            return getItemDisplayName(priceSheetItem);
        }

        return '';
    };

    const buyAllImagesIds = useMemo(() => {
        return buyAllImagesState.reduce<string>((imagesIdsString, currentImage, currentIndex) => {
            const currentImageId = currentIndex === 0 ? currentImage.id : `,${currentImage.id}`;
            return `${imagesIdsString}${currentImageId}`;
        }, '');
    }, [buyAllImagesState]);

    useEffect(() => {
        if (priceSheetItemId && spFulfilledDigitalItems) {
            setDigitalItem(
                spFulfilledDigitalItems.find(
                    (digitalItem) => digitalItem.id === priceSheetItemId.toString()
                ) ?? null
            );
        }
    }, [priceSheetItemId, spFulfilledDigitalItems]);

    useEffect(() => {
        if (digitalItem && isDigitalDownload) {
            if (digitalItem.is_album && buyAllPhotosContainer.albumPhotos.length !== 0) {
                setBuyAllImagesState(buyAllPhotosContainer.albumPhotos);
            } else if (digitalItem.is_event && buyAllPhotosContainer.eventPhotos.length !== 0) {
                setBuyAllImagesState(buyAllPhotosContainer.eventPhotos);
            } else if (digitalItem.is_individual) {
                setBuyAllImagesState(buyAllImages);
            }
        }
    }, [
        buyAllImages,
        buyAllPhotosContainer.albumPhotos,
        buyAllPhotosContainer.eventPhotos,
        digitalItem,
        isDigitalDownload
    ]);

    useEffect(() => {
        const selectedPricesheetItem =
            priceSheetItem ?? priceSheetGroup?.items.find(({ id }) => id === priceSheetItemId);

        if (selectedPricesheetItem) {
            setSelectedPriceSheetItem((currentItem) => {
                if (currentItem?.id !== selectedPricesheetItem.id) {
                    if (
                        selectedPricesheetItem.id !== lastCroppedPriceSheetItem?.id &&
                        isSingleItem(selectedPricesheetItem) &&
                        (!currentItem ||
                            !isSingleItem(currentItem) ||
                            !currentItem.size ||
                            !areEqualSize(selectedPricesheetItem.size, currentItem.size))
                    ) {
                        const maxCrop = getMaxCrop(
                            image,
                            getSize(selectedPricesheetItem.size),
                            selectedPricesheetItem.group.groupType === 'canvas',
                            selectedPricesheetItem.group.groupType === 'ornaments'
                        );
                        setSelectedPriceSheetItemCrop(maxCrop);
                    }

                    return selectedPricesheetItem;
                }

                return currentItem;
            });
        }
    }, [image, lastCroppedPriceSheetItem, priceSheetItemId, priceSheetItem, priceSheetGroup]);

    useEffect(() => {
        if (isDigitalDownload && digitalItem && isBuyingAllPhotos) {
            if (digitalItem.is_album && buyAllPhotosContainer.albumPhotos.length === 0) {
                fetchImageData({
                    search: {
                        album_id: contextId
                    }
                });
            } else if (digitalItem.is_event && buyAllPhotosContainer.eventPhotos.length === 0) {
                fetchImageData({
                    search: {
                        album_id: 'all'
                    }
                });
            }
        }
    }, [
        buyAllPhotosContainer.albumPhotos.length,
        buyAllPhotosContainer.eventPhotos.length,
        contextId,
        digitalItem,
        fetchImageData,
        isBuyingAllPhotos,
        isDigitalDownload
    ]);

    useEffect(() => {
        if (imageDataResponse?.photos && digitalItem) {
            const newPhotos = imageDataResponse.photos.map((photo) => {
                const convertedPhoto = convertIDataPhotoToIPhoto(photo);

                return {
                    ...convertedPhoto,
                    url: getPhotoUrl(convertedPhoto, 'm')
                } as IImage;
            });

            if (digitalItem.is_album && buyAllPhotosContainer.albumPhotos.length === 0) {
                setBuyAllPhotosContainer((currentContainer) => ({
                    ...currentContainer,
                    albumPhotos: newPhotos
                }));
            }

            if (digitalItem.is_event && buyAllPhotosContainer.eventPhotos.length === 0) {
                setBuyAllPhotosContainer((currentContainer) => ({
                    ...currentContainer,
                    eventPhotos: newPhotos
                }));
            }
        }
    }, [
        buyAllPhotosContainer.albumPhotos.length,
        buyAllPhotosContainer.eventPhotos.length,
        digitalItem,
        getPhotoUrl,
        imageDataResponse,
        isInAlbum,
        priceSheetItemId
    ]);

    useEffect(() => {
        if (postToCartResponse) {
            if (isDigitalDownload) {
                setDigitalOrderPrice(orderPrice);
            }

            if (postToCartResponse.statusText === 'success') {
                addToCart(priceSheetItemId, quantity, buyAllImagesState, orderPrice, digitalItem);
            } else if (
                postToCartResponse.statusText === 'form_errors' &&
                postToCartResponse.need_email
            ) {
                setTrackingData({ buyAllImagesState, digitalItem });
                goToEmailCaptureAddToCartSlide(
                    priceSheetItemId,
                    quantity,
                    selectedPriceSheetItemCrop
                );
            }
        }
    }, [
        addToCart,
        buyAllImagesState,
        digitalItem,
        goToEmailCaptureAddToCartSlide,
        isDigitalDownload,
        orderPrice,
        postToCartResponse,
        priceSheetItemId,
        quantity,
        selectedPriceSheetItemCrop,
        setDigitalOrderPrice,
        setTrackingData
    ]);

    useEffect(() => {
        trackViewItemEvent(
            quantity,
            () =>
                pushGoogleAnalyticsDataCallback(
                    quantity,
                    orderPrice,
                    selectedPriceSheetItem,
                    buyAllImagesState,
                    digitalItem,
                    'view_item'
                ),
            priceSheetItemId,
            initialPriceSheetItemRef.current?.id,
            selectedPriceSheetItem?.id,
            digitalItem ? parseInt(digitalItem?.id) : undefined,
            isDigitalDownload,
            buyAllImagesState
        );
    }, [
        buyAllImagesState,
        orderPrice,
        selectedPriceSheetItem,
        quantity,
        priceSheetItemId,
        digitalItem,
        isDigitalDownload,
        pushGoogleAnalyticsDataCallback
    ]);

    const isFramedPriceSheetGroup = priceSheetGroup && isFramedCategory(priceSheetGroup);
    const optionsContextString = isInAlbum ? 'album' : isInMarkedContext ? 'marked' : 'none';
    const albumId = image.favFromAlbumId || undefined;

    return (
        <Box
            as="form"
            overflowX="hidden"
            padding="48px 20px"
            onSubmit={handleSubmit(({ priceSheetItemId, quantity }) => {
                postToCart({
                    body: new URLSearchParams({
                        album_id: albumId ? String(albumId) : context === 'album' ? contextId : '',
                        crop_data: selectedPriceSheetItemCrop?.isChanged
                            ? getCropStringFromCrop(selectedPriceSheetItemCrop)
                            : '',
                        photo_filter_id: image.filterBlackAndWhite ? '2' : '1',
                        photo_id: isBuyingAllPhotos ? buyAllImagesIds : String(image.id),
                        price_sheet_item_id: String(priceSheetItemId),
                        quantity: String(quantity)
                    })
                });
            })}
        >
            {selectedPriceSheetItem && (
                <Skeleton isLoaded={!loadingImageData}>
                    <ProductImages
                        allowCropping={settings.allowCropping}
                        buyAllImages={buyAllImagesState}
                        crop={selectedPriceSheetItemCrop}
                        image={image}
                        marginTop="-48px"
                        onCropEdit={() => {
                            onCropEdit(
                                quantity,
                                selectedPriceSheetItemCrop,
                                selectedPriceSheetItem
                            );
                        }}
                        priceSheetItem={selectedPriceSheetItem}
                        right="21px"
                        width="calc(100% + 42px)"
                    />
                </Skeleton>
            )}

            <Box data-testid="product-options-content" margin="33px 0 100px" padding="0 8px">
                {priceSheetItem && (
                    <Text
                        as="sup"
                        color="brand.primary"
                        display="block"
                        fontFamily="open-sans, sans-serif"
                        fontSize="xs"
                        marginBottom="-0.5em"
                        position="relative"
                        top="-0.5em"
                    >
                        {priceSheetItem.group.name}
                    </Text>
                )}
                <GalleryHeading size="md" text={getHeadingText()} variant="primary" />
                <Text
                    data-testid="select-product-options-price"
                    fontFamily="open-sans, sans-serif"
                    fontSize="md"
                    marginBottom="8px"
                >
                    {$(orderPrice)}
                </Text>
                {selectedPriceSheetItem?.group.description && (
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: selectedPriceSheetItem.group.description
                        }}
                        data-testid="select-product-options-description"
                        fontFamily="open-sans, sans-serif"
                        fontSize="md"
                    />
                )}
                {isFramedPriceSheetGroup ? (
                    <FrameOptionsGroup
                        priceSheetGroup={priceSheetGroup}
                        setValue={setValue}
                        watch={watch}
                    />
                ) : (
                    <>
                        {productSizes && (
                            <RadioGroupGrid
                                defaultValue={defaultValues?.priceSheetSubgroupId}
                                fieldName="priceSheetSubgroupId"
                                label={t('size')}
                                onChange={(fieldName, value) =>
                                    setValue(
                                        fieldName as 'priceSheetSubgroupId' | 'priceSheetItemId',
                                        value
                                    )
                                }
                                options={sizeOptionsForRadioGrid}
                            />
                        )}
                        <OptionSelect
                            context={optionsContextString}
                            defaultPriceSheetItemId={defaultValues?.priceSheetItemId}
                            isBuyingAllPhotos={isBuyingAllPhotos}
                            isDigitalDownload={isDigitalDownload}
                            priceSheetGroup={priceSheetGroup}
                            priceSheetItem={priceSheetItem}
                            selectedPriceSheetItem={selectedPriceSheetItem}
                            setValue={setValue}
                            spFulfilledDigitalItems={spFulfilledDigitalItems}
                            watch={watch}
                        />
                    </>
                )}
                {!isDigitalDownload && <QuantityStepper setValue={setValue} watch={watch} />}
                <Divider />
                <ProductDescriptionAccordion
                    productDescription={selectedPriceSheetItem?.description}
                />
            </Box>
            <Footer alignItems="center" background="modalBackground">
                <Button
                    data-testid="select-product-options-submit-button"
                    type="submit"
                    isDisabled={!isValid || loadingImageData}
                    fullWidth
                    isLoading={isSubmitting}
                    text={isDigitalItem ? t('buyDigitals') : t('addToCart')}
                    variant="primary"
                />
            </Footer>
        </Box>
    );
};

export default SelectProductOptionsSlide;
