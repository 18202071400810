/* eslint-disable func-names */

var directiveName = 'spPhotoViewDimensions';

export default [
    '$window',
    'SPPhoto',
    function spPhotoViewDimensionsDirective($window, SPPhoto) {
        var windowElement = angular.element($window);

        return {
            restrict: 'A',
            scope: {
                photo: '=' + directiveName,
                size: '=' + directiveName + 'Size'
            },
            link: function spPhotoViewDimensionsController($scope, element) {
                function setSize() {
                    var container = element.parent();
                    var boundingBox = {
                        width: container.width(),
                        height: container.height()
                    };

                    element.css({
                        width: SPPhoto.getWidth($scope.photo, $scope.size, boundingBox),
                        height: SPPhoto.getHeight($scope.photo, $scope.size, boundingBox)
                    });
                }

                windowElement.on('resize', setSize);

                $scope.$on('$destroy', function() {
                    windowElement.off('resize', setSize);
                });

                $scope.$watch(
                    'photo',
                    function() {
                        setSize();
                    },
                    true
                );
            }
        };
    }
];
