import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { ResizeObserver } from '@juggle/resize-observer';
import Constants from 'Sp/Gallery';
import type {
    IPhoto,
    IPhotoActions,
    IPhotoDimensions,
    IRootScope,
    ISPPhotoService
} from 'ts/client/types';
import PhotoGridItem from './PhotoGridItem';
import getPhotoDimensionsMap from './util/getPhotoDimensionsMap';

const layoutTypes = ['hmason', 'vmason'] as const;

type ThumbnailSize = 's' | 'm' | 'l';

interface Props {
    /** Returns the URL for a given photo and size */
    getPhotoUrl: ISPPhotoService['getUrl'];
    /** True only if currently viewing the compare page */
    isComparing: boolean;
    /** True only if the Download button should be visible */
    isDownloadPossible: boolean;
    /** True only if the package builder is open */
    isPackageBuilderOpen: boolean;
    /** Returns whether or not the given photo is selected */
    isPhotoSelected: (photo: IPhoto) => boolean;
    /** True only if two photos have been selected to be compared */
    isReadyToCompare: boolean;
    /** The type of layout to use for rendering the grid's photos */
    layoutType: typeof layoutTypes[number];
    /** Called when the given photo is clicked */
    onClickPhoto: (photo: IPhoto) => unknown;
    /** The array of photos to render */
    photos: IPhoto[];
    /** Actions to be performed on photos */
    photoActions: IPhotoActions;
    /** The id of a photo that is part of a pending download that requires the user to select a download destination */
    requiredDestinationSelectionPhotoId?: IPhoto['id'];
    /** Called on mount, to scroll to the target photo */
    scrollToTargetPhoto: () => unknown;
    /** Angular free digitals service */
    spFreeDigitals: SpAngularJs.ISpFreeDigitals;
    /** The size to use for spacing between photos on large screens */
    thumbnailGutterWidth: ThumbnailSize;
    /** The size to use for rendering thumbnails */
    thumbnailSize: ThumbnailSize;
    /** Angular translation service */
    translateFilter: SpAngularJs.ITranslateFilter;
    /** Angular root scope */
    $rootScope: IRootScope;
}

const PhotoGrid: FC<Props> = ({
    getPhotoUrl,
    isComparing,
    isDownloadPossible,
    isPackageBuilderOpen,
    isPhotoSelected,
    isReadyToCompare,
    layoutType,
    onClickPhoto,
    photos,
    photoActions,
    requiredDestinationSelectionPhotoId,
    scrollToTargetPhoto,
    spFreeDigitals,
    thumbnailGutterWidth,
    thumbnailSize,
    translateFilter,
    $rootScope
}) => {
    if (!layoutTypes.includes(layoutType)) {
        throw new Error(`PhotoGrid received unexpected layout type: ${layoutType}`);
    }

    const commonHeight: number =
        useBreakpointValue({
            lg: Constants.CLIENT_THUMBNAIL_SIZE_MAP.HMASON[thumbnailSize]
        }) ?? Constants.CLIENT_THUMBNAIL_SIZES.HMASON.SMALL;
    const commonWidth: number =
        useBreakpointValue({
            lg: Constants.CLIENT_THUMBNAIL_SIZE_MAP.VMASON[thumbnailSize]
        }) ?? Constants.CLIENT_THUMBNAIL_SIZES.VMASON.MEDIUM;
    const padding: number =
        useBreakpointValue({
            lg: Constants.CLIENT_THUMBNAIL_GUTTER_MAP[thumbnailGutterWidth]
        }) ?? Constants.CLIENT_GUTTER_SIZES.SMALL;

    const containerElementRef = useRef<HTMLDivElement>(null);

    const [containerTop, setContainerTop] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);
    const [photoDimensionsMap, setPhotoDimensionsMap] = useState<Map<number, IPhotoDimensions>>();

    const windowVirtualTop = useBreakpointValue({ lg: -0.5 * windowHeight }) ?? -windowHeight;
    const windowVirtualBottom = useBreakpointValue({ lg: 1.5 * windowHeight }) ?? 2 * windowHeight;

    const rootHeight = useMemo(() => {
        return photos.reduce((result, photo) => {
            const photoDimensions = photoDimensionsMap?.get(photo.id);

            if (!photoDimensions) {
                return result;
            }

            const height = photoDimensions.top + photoDimensions.height;
            return Math.max(height, result);
        }, 0);
    }, [photos, photoDimensionsMap]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            if (!containerElementRef.current) {
                return;
            }

            const nextPhotoDimensionsMap = getPhotoDimensionsMap({
                commonDimension: layoutType === 'hmason' ? commonHeight : commonWidth,
                containerWidth: containerElementRef.current.offsetWidth,
                layoutType,
                padding,
                photos
            });

            setPhotoDimensionsMap(nextPhotoDimensionsMap);
        });

        if (containerElementRef.current) {
            resizeObserver.observe(containerElementRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [commonHeight, commonWidth, layoutType, padding, photos]);

    useEffect(() => {
        scrollToTargetPhoto();
    }, [scrollToTargetPhoto]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowHeight(window.innerHeight);
        };

        const handleWindowScroll = () => {
            if (!containerElementRef.current) {
                return;
            }

            const nextContainerTop = containerElementRef.current.getBoundingClientRect().top;
            setContainerTop(nextContainerTop);
        };

        handleWindowResize();
        handleWindowScroll();

        window.addEventListener('resize', handleWindowResize);
        window.addEventListener('scroll', handleWindowScroll);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
            window.removeEventListener('scroll', handleWindowScroll);
        };
    }, []);

    return (
        <Box height={`${rootHeight}px`}>
            <Box ref={containerElementRef} position="relative">
                {photos.map((photo) => {
                    const photoDimensions = photoDimensionsMap?.get(photo.id);

                    if (!photoDimensions) {
                        return null;
                    }

                    const photoActualTop = containerTop + photoDimensions.top;
                    const photoActualBottom = photoActualTop + photoDimensions.height;

                    const topAboveView = photoActualTop < windowVirtualTop;
                    const topBelowView = photoActualTop > windowVirtualBottom;
                    const topInView = !topAboveView && !topBelowView;
                    const bottomAboveView = photoActualBottom < windowVirtualTop;
                    const bottomBelowView = photoActualBottom > windowVirtualBottom;
                    const bottomInView = !bottomAboveView && !bottomBelowView;
                    const isInView = topInView || bottomInView || (topAboveView && bottomBelowView);

                    return (
                        <PhotoGridItem
                            key={photo.id}
                            getPhotoUrl={getPhotoUrl}
                            isBlackAndWhite={photo.filterBlackAndWhite}
                            isComparing={isComparing}
                            isDownloadPossible={isDownloadPossible}
                            isFavorite={photo.isFavorite}
                            isHidden={photo.isHidden}
                            isInCart={photo.isInCart}
                            isInView={isInView}
                            isLabeled={photo.tags.length > 0}
                            isPackageBuilderOpen={isPackageBuilderOpen}
                            isReadyToCompare={isReadyToCompare}
                            isSelected={isPhotoSelected(photo)}
                            onClickPhoto={onClickPhoto}
                            photo={photo}
                            photoActions={photoActions}
                            photoDimensions={photoDimensions}
                            requiresDownloadDestination={
                                requiredDestinationSelectionPhotoId === photo.id
                            }
                            spFreeDigitals={spFreeDigitals}
                            translateFilter={translateFilter}
                            $rootScope={$rootScope}
                        />
                    );
                })}
            </Box>
        </Box>
    );
};

export default PhotoGrid;
