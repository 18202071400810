import PACKAGE_SMALL_SCREEN_STATES from 'Sp/Gallery';

export default [
    '$scope',
    'SPModal',
    'spPackages',
    function PackageWarningController($scope, SPModal, spPackages) {
        const $ctrl = this;

        Object.assign($ctrl, {
            close,
            confirmExit
        });

        function close() {
            SPModal.close();
        }

        function confirmExit() {
            spPackages.setSmallScreenState(PACKAGE_SMALL_SCREEN_STATES.PHOTO_VIEW);
            spPackages.setSelectedPackage();
            spPackages.clearBackToCartListener();

            if (spPackages.isSinglePackageItemSelected()) {
                spPackages.selectPackageSingleItem();
            }

            $scope.$apply();
            close();
        }
    }
];
