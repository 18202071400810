import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import { ArrowLeft } from 'react-feather';
import { useTranslate } from 'ts/common';
import { useStore } from 'client_react/gallery/hooks/StoreContext';

interface IPage {
    content: ReactNode;
    hideBreadcrumbs?: boolean;
    hideBackButton?: boolean;
}

interface IStorePages {
    initialPageName: string;
    setPreviousPageOnHistory?: (slideIndex: number) => void;
    pages: IPage[];
}

type PageHistoryEntry = {
    index: number;
    name: string;
};

const StorePages: FC<IStorePages> = ({ initialPageName, setPreviousPageOnHistory, pages }) => {
    const t = useTranslate('client.store');
    const { setGoToPage, setReturnToPage } = useStore();
    const [activePageIndex, setActivePageIndex] = useState(0);
    const [pageHistory, setPageHistory] = useState<PageHistoryEntry[]>([
        { index: 0, name: initialPageName }
    ]);
    const hasPages = pages.length > 0;

    const activePage = pages[activePageIndex];

    const goToPage = useCallback(
        (index: number, name: string) => {
            if (index >= 0 && index < pages.length) {
                setActivePageIndex(index);
                setPageHistory((pageHistory) => [...pageHistory, { index, name }]);
            }
        },
        [pages.length]
    );

    const returnToPage = useCallback(
        (index: number) => {
            if (index >= 0 && index < pages.length) {
                setPageHistory((pageHistory) => [
                    ...pageHistory.slice(
                        0,
                        pageHistory.findIndex((pageHistory) => pageHistory.index === index) + 1
                    )
                ]);
                setActivePageIndex(index);
            }
        },
        [pages.length]
    );

    const goBack = () => {
        const previousPage = pageHistory[pageHistory.length - 2];
        setPageHistory((pageHistory) => pageHistory.slice(0, -1));
        setActivePageIndex(previousPage.index);
    };

    useEffect(() => {
        setGoToPage(() => goToPage);
    }, [goToPage, setGoToPage]);
    useEffect(() => {
        setReturnToPage(() => returnToPage);
    }, [returnToPage, setReturnToPage]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [activePageIndex]);

    useEffect(() => {
        if (pageHistory.length > 1 && setPreviousPageOnHistory) {
            const previousPage = pageHistory[pageHistory.length - 2];
            setPreviousPageOnHistory(previousPage.index);
        }
    }, [pageHistory, setPreviousPageOnHistory]);

    if (!hasPages) {
        return null;
    }

    return (
        <Flex justifyContent="center" position="relative">
            <Box width="100%" maxWidth="1204px">
                {pageHistory.length > 1 && !activePage.hideBreadcrumbs && (
                    <Box alignItems="center" display="inline-flex" marginBottom="40px">
                        {!activePage.hideBackButton && (
                            <IconButton
                                aria-label={t('backButton')}
                                data-testid="back-button"
                                height="20px"
                                icon={<ArrowLeft height="20px" strokeWidth="1.5px" width="20px" />}
                                marginRight="8px"
                                minWidth="20px"
                                onClick={goBack}
                                variant="ghost"
                            />
                        )}
                        {pageHistory.slice(0, -1).map((page) => (
                            <Box key={page.index} display="inline-flex">
                                <Text
                                    as="a"
                                    cursor="pointer"
                                    data-testid="previous-page"
                                    onClick={() => {
                                        returnToPage(page.index);
                                    }}
                                >
                                    {page.name}
                                </Text>
                                <Text margin="0 8px 0 8px">{'/'}</Text>
                            </Box>
                        ))}
                        <Text as="b" cursor="pointer" data-testid="active-page">
                            {pageHistory[pageHistory.length - 1].name}
                        </Text>
                    </Box>
                )}
                <Box data-testid="store-pages" maxWidth="100%">
                    {activePage.content}
                </Box>
            </Box>
        </Flex>
    );
};

export default StorePages;
