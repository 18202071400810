import { useEffect } from 'react';
import { useGalleryApiFetch } from 'ts/client/common';
import type { IImage, IPriceSheetDataResponse } from 'ts/client/gallery/components';
import { getParamsForPriceSheetDataFromContext } from 'ts/client/gallery/components/add-to-cart-side-panel/common';
import { FetchMethod } from 'ts/common/hooks';

const useFetchPriceSheetDataByImage = (
    image: IImage,
    isImageSet: boolean,
    isBuyingAllPhotos: boolean,
    context: string,
    contextId: string
) => {
    const {
        response: priceSheetDataByImage,
        loading: isFetchingPriceSheetDataByImage,
        performFetch: fetchPricesheetDataByImage
    } = useGalleryApiFetch<IPriceSheetDataResponse>(
        `cart/pricesheetdata?photo_id=${
            !isBuyingAllPhotos ? image.id : ''
        }${getParamsForPriceSheetDataFromContext(context, contextId, '&')}`,
        {
            method: FetchMethod.POST,
            defer: true
        }
    );

    useEffect(() => {
        if (isImageSet) {
            fetchPricesheetDataByImage();
        }
    }, [isImageSet, fetchPricesheetDataByImage]);

    return {
        isFetchingPriceSheetDataByImage,
        priceSheetDataByImage
    };
};

export default useFetchPriceSheetDataByImage;
