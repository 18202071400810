import { CANVAS_EXHIBITION_TYPES, CANVAS_RENDERING_TYPES } from 'Sp/Canvas';

/**
 * @ngdoc directive
 * @name sp.client.gallery.directive:spCanvasView
 * @restrict A
 *
 * @description Renders a cropped canvas photo, with either 'perspective' or 'shadow' rendering
 *
 * @param {object} spCanvasViewPhoto The unaltered photo for rendering
 * @param {SPPhoto.Crop} spCanvasViewCrop Dimensions to crop the photo
 * @param {string} spCanvasViewExhibition Exhibition mode:
 *     'solo' | 'bedroom' | 'living-room' | 'office'
 * @param {string} spCanvasViewRendering Rendering method:
 *     'perspective' | 'shadow'
 */
export default [
    '$rootScope',
    'spCanvasView',
    'SPPhoto',
    function spCanvasViewDirective($rootScope, spCanvasView, SPPhoto) {
        return {
            restrict: 'A',
            scope: {
                photo: '<spCanvasViewPhoto',
                crop: '<spCanvasViewCrop',
                exhibition: '<spCanvasViewExhibition',
                rendering: '<spCanvasViewRendering'
            },
            transclude: true,
            templateUrl: $rootScope.getDirectiveTemplateUrl('client/gallery', 'sp-canvas-view'),
            link: function spCanvasViewController(
                $scope,
                element,
                $attributes,
                controller,
                $transclude
            ) {
                $transclude((elements) => {
                    element.append(elements);
                });
                const perspectiveRenderings = element.find('.photo-perspective-rendering');
                const perspectiveRendering = element.find('.photo-canvas-perspective-rendering');
                const perspectiveRenderingFrontFace = element.find(
                    '.photo-canvas-perspective-rendering .photo-canvas-front-face'
                );
                const shadowRendering = element.find('.photo-canvas-shadow-rendering');
                const shadowRenderingFrontFace = element.find(
                    '.photo-canvas-shadow-rendering .photo-canvas-front-face'
                );
                const canvasFaces = element.find('.photo-canvas-face');
                const frontFaces = element.find('.photo-canvas-front-face');
                const leftFace = element.find('.photo-canvas-left-face');
                const topFace = element.find('.photo-canvas-top-face');
                let photoDimensions;
                let containerDimensions;

                Object.assign($scope, {
                    getContainerDimensions,
                    isPerspectiveRendering,
                    isShadowRendering,
                    setPhoto,
                    setupFigures
                });

                initialize();

                function initialize() {
                    getContainerDimensions();
                    setupBackdrop();
                    setupFigures();
                }

                function getContainerDimensions() {
                    const container = element;
                    const width = container.width();
                    const height = container.height();
                    const aspectRatio = width / height;

                    containerDimensions = { width, height, aspectRatio };

                    return containerDimensions;
                }

                function setupBackdrop() {
                    const backdrop = getBackdrop();

                    if (!backdrop) {
                        return;
                    }

                    Object.values(CANVAS_EXHIBITION_TYPES).forEach((exhibition) => {
                        backdrop.removeClass(`${exhibition}-exhibition`);
                    });

                    const { exhibition } = $scope;

                    backdrop.addClass(`${exhibition}-exhibition`);
                }

                function getBackdrop() {
                    const backdrop = element.closest('.canvas-backdrop');

                    if (backdrop.length === 0) {
                        return null;
                    }

                    return backdrop;
                }

                function setPhoto() {
                    const photoSize = 'l';
                    const { photo } = $scope;

                    if (typeof photo !== 'object') {
                        return;
                    }

                    photoDimensions = SPPhoto.getPhotoDimensions(photo, photoSize, true);

                    const photoUrl = `url(${SPPhoto.getUrl(photo, photoSize)})`;

                    canvasFaces.css('background-image', photoUrl);
                }

                function setupFigures() {
                    const { exhibition, crop } = $scope;
                    const { bounds, isRotated } = crop;
                    const {
                        getBoundsInches,
                        getBoundsPixels,
                        getExhibitionDimensions,
                        getPerspectiveRenderingDetails,
                        getScaleFactors,
                        getShadowRenderingDetails,
                        getTotalCropPercentages,
                        getTranslateFactors
                    } = spCanvasView;

                    if (typeof crop !== 'object' || typeof photoDimensions !== 'object') {
                        return;
                    }

                    // First step is to gather dimensions for exhibition space and
                    // backdrop image
                    const exhibitionDimensions = getExhibitionDimensions(
                        exhibition,
                        containerDimensions
                    );
                    // Next step is to normalize the (possibly rotated) totalCrop
                    // (includes depth+bleed) data structure
                    const cropPercentages = getTotalCropPercentages(crop);
                    // Then we compute crop and fullImage bounds in inches
                    const boundsInches = getBoundsInches(isRotated, bounds, cropPercentages);
                    // Then we compute bounds in pixels
                    const boundsPixels = getBoundsPixels(photoDimensions, boundsInches);
                    // Determine scale factor betweeen source image and container
                    const scaleFactors = getScaleFactors(
                        exhibition,
                        exhibitionDimensions,
                        boundsInches,
                        containerDimensions,
                        boundsPixels
                    );
                    const translateFactors = getTranslateFactors(exhibition, exhibitionDimensions);
                    const perspectiveRenderingDetails = getPerspectiveRenderingDetails(
                        scaleFactors
                    );
                    const shadowRenderingDetails = getShadowRenderingDetails(scaleFactors);

                    setupRenderings(boundsPixels, scaleFactors, translateFactors);
                    setupFrontFaces(
                        boundsPixels,
                        perspectiveRenderingDetails,
                        shadowRenderingDetails
                    );
                    setupLeftFace(boundsPixels, perspectiveRenderingDetails);
                    setupTopFace(boundsPixels);
                }

                function setupRenderings(
                    { cropWidthPixels, cropHeightPixels },
                    { perspectiveScaleFactor, shadowScaleFactor },
                    { translateX, translateY }
                ) {
                    const translateFactor =
                        translateX || translateY ? `translate(${translateX}, ${translateY})` : '';

                    perspectiveRenderings.css({
                        width: `${cropWidthPixels}px`,
                        height: `${cropHeightPixels}px`
                    });

                    perspectiveRendering.css({
                        transform: `scale(${perspectiveScaleFactor}) rotate3d(-3, 6, -1, 35deg)`
                    });

                    shadowRendering.css({
                        transform: `${translateFactor} scale(${shadowScaleFactor})`
                    });
                }

                function setupFrontFaces(
                    {
                        totalCropLeftPixels,
                        totalCropTopPixels,
                        bleedPixels,
                        depthPixels,
                        cropWidthPixels,
                        cropHeightPixels
                    },
                    { boxShadowOffset: perspectiveBoxShadowOffset },
                    {
                        boxShadowVerticalOffset: shadowBoxShadowVerticalOffset,
                        boxShadowHorizontalOffset: shadowBoxShadowHorizontalOffset,
                        borderRadius: shadowBorderRadius
                    }
                ) {
                    const frontFaceWidthPixels = cropWidthPixels;
                    const frontFaceHeightPixels = cropHeightPixels;
                    const frontFaceLeftPixels = totalCropLeftPixels + bleedPixels + depthPixels;
                    const frontFaceTopPixels = totalCropTopPixels + bleedPixels + depthPixels;

                    frontFaces.css({
                        width: `${frontFaceWidthPixels}px`,
                        height: `${frontFaceHeightPixels}px`,
                        'background-position-x': `-${frontFaceLeftPixels}px`,
                        'background-position-y': `-${frontFaceTopPixels}px`
                    });

                    perspectiveRenderingFrontFace.css({
                        'box-shadow': `0 ${perspectiveBoxShadowOffset}px ${perspectiveBoxShadowOffset}px rgba(0, 0, 0, 0.25)`,
                        transform: `translateZ(${depthPixels / 2}px)`
                    });

                    shadowRenderingFrontFace.css({
                        'box-shadow': `${shadowBoxShadowHorizontalOffset}px ${shadowBoxShadowVerticalOffset}px ${shadowBoxShadowVerticalOffset}px rgba(0, 0, 0, 0.25)`,
                        'border-radius': `${shadowBorderRadius}px`
                    });
                }

                function setupLeftFace(
                    {
                        totalCropLeftPixels,
                        totalCropTopPixels,
                        bleedPixels,
                        depthPixels,
                        cropHeightPixels
                    },
                    { boxShadowOffset: perspectiveBoxShadowOffset }
                ) {
                    const leftFaceWidthPixels = depthPixels;
                    const leftFaceHeightPixels = cropHeightPixels;
                    const leftFaceLeftPixels = totalCropLeftPixels + bleedPixels;
                    const leftFaceTopPixels = totalCropTopPixels + bleedPixels + depthPixels;

                    leftFace.css({
                        width: `${leftFaceWidthPixels}px`,
                        height: `${leftFaceHeightPixels}px`,
                        'background-position-x': `-${leftFaceLeftPixels}px`,
                        'background-position-y': `-${leftFaceTopPixels}px`,
                        transform: `rotateY(-90deg) translateZ(${depthPixels / 2}px)`,
                        filter: 'brightness(75%)',
                        'box-shadow': `0 ${perspectiveBoxShadowOffset}px ${perspectiveBoxShadowOffset}px rgba(0, 0, 0, 0.25)`
                    });
                }

                function setupTopFace({
                    totalCropLeftPixels,
                    totalCropTopPixels,
                    bleedPixels,
                    depthPixels,
                    cropWidthPixels
                }) {
                    const topFaceWidthPixels = cropWidthPixels;
                    const topFaceHeightPixels = depthPixels;
                    const topFaceLeftPixels = totalCropLeftPixels + bleedPixels + depthPixels;
                    const topFaceTopPixels = totalCropTopPixels + bleedPixels;

                    topFace.css({
                        width: `${topFaceWidthPixels}px`,
                        height: `${topFaceHeightPixels}px`,
                        'background-position-x': `-${topFaceLeftPixels}px`,
                        'background-position-y': `-${topFaceTopPixels}px`,
                        transform: `rotateX(90deg) translateZ(${depthPixels / 2}px)`,
                        filter: 'brightness(75%)'
                    });
                }

                function isPerspectiveRendering() {
                    return $scope.rendering === CANVAS_RENDERING_TYPES.PERSPECTIVE;
                }

                function isShadowRendering() {
                    return $scope.rendering === CANVAS_RENDERING_TYPES.SHADOW;
                }

                $scope.$watch('photo', () => setPhoto(), true);

                $scope.$watch('crop', () => initialize(), true);

                $scope.$watch('exhibition', () => initialize(), true);

                $scope.$parent.$watch('clientWidth', () => initialize(), true);
            }
        };
    }
];
