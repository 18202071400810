import React, { FC, memo } from 'react';
import { Box, Button, Flex, IconButton, useBreakpointValue } from '@chakra-ui/react';
import DownloadDestinations from 'ts/client/gallery/components/DownloadDestinations';
import { getDownloadDestinationsProps } from 'ts/client/gallery/components/common';
import { LoadingIndicator } from 'ts/common/components';
import Drawer from 'ts/common/components/gallery/Drawer';
import Modal from 'ts/common/components/gallery/Modal';
import ShareLinks from 'ts/common/components/gallery/ShareLinks';
import usePhotoActions, { IUsePhotoActionsProps } from 'ts/common/hooks/usePhotoActions';

const PhotoToolbar: FC<IUsePhotoActionsProps> = (props) => {
    const { spFreeDigitals, translateFilter: translate, photoActions } = props;

    const isLargeScreen = useBreakpointValue({ base: false, lg: true }) ?? false;
    const iconButtonSize = useBreakpointValue({ base: '20px', lg: '16px' });
    const buttonStyle = {
        svg: {
            height: iconButtonSize,
            width: iconButtonSize
        }
    };

    const {
        hideShareLinks,
        isShareLinksVisible,
        photoActionButtons,
        hideDownloadDestinations,
        isDownloadDestinationsVisible,
        shareUrls
    } = usePhotoActions(props);

    const handleCancelDownload = () => {
        hideDownloadDestinations();
        photoActions.cancelDownload();
    };

    const buyButton = photoActionButtons.find((button) => button.key === 'buyPhoto');

    const shareLinks = shareUrls ? (
        <ShareLinks
            copyLinkHref={shareUrls?.photoShareUrl}
            headingText={translate('sharePhoto')}
            linkHrefs={shareUrls}
            onClickLink={hideShareLinks}
            translateFilter={translate}
        />
    ) : (
        <LoadingIndicator />
    );

    const downloadDestinations = (
        <DownloadDestinations
            {...getDownloadDestinationsProps(photoActions, hideDownloadDestinations)}
            isDownloadPhoto
            spFreeDigitals={spFreeDigitals}
            translateFilter={translate}
        />
    );

    return (
        <Flex
            alignSelf="stretch"
            flexFlow="column nowrap"
            gap="20px"
            paddingBottom="24px"
            position="relative"
        >
            <Flex
                flexFlow="row wrap"
                gap={useBreakpointValue({ base: '12px', lg: 0 })}
                justifyContent="center"
                position="relative"
            >
                {photoActionButtons.map((button) => {
                    // On small screens, don't render the buy button as part of the row of icon buttons.
                    if (!isLargeScreen && button === buyButton) {
                        return null;
                    }

                    // Don't render the select button.
                    if (button === buyButton && button.text === translate('selectPhoto')) {
                        return null;
                    }

                    const color = button.isToggledOn ? 'brand.primary' : 'text';
                    const variant = button.isToggledOn ? 'ghost primary' : 'ghost';

                    if (isLargeScreen) {
                        return (
                            <Button
                                key={button.key}
                                aria-checked={button.isToggledOn}
                                color={color}
                                isDisabled={button.isDisabled}
                                leftIcon={button.icon}
                                onClick={button.onClick}
                                role={button.isToggledOn === undefined ? undefined : 'switch'}
                                sx={buttonStyle}
                                variant={variant}
                            >
                                {button.text}
                            </Button>
                        );
                    }

                    return (
                        <IconButton
                            key={button.key}
                            aria-checked={button.isToggledOn}
                            aria-label={button.text}
                            color={color}
                            isDisabled={button.isDisabled}
                            icon={button.icon}
                            onClick={button.onClick}
                            role={button.isToggledOn === undefined ? undefined : 'switch'}
                            sx={buttonStyle}
                            variant={variant}
                            _hover={{ color }}
                        />
                    );
                })}
            </Flex>
            {useBreakpointValue({
                // On small screens, render the buy button below the row of icon buttons.
                base: buyButton && (
                    <Button margin="0 16px" onClick={buyButton.onClick} variant="primary">
                        {buyButton.text}
                    </Button>
                ),
                lg: <></>
            })}
            {useBreakpointValue({
                base: (
                    <Drawer isOpen={isShareLinksVisible} onClose={hideShareLinks}>
                        <Box paddingBottom="24px" paddingTop="4px">
                            {shareLinks}
                        </Box>
                    </Drawer>
                ),
                lg: (
                    <Modal isOpen={isShareLinksVisible} onClose={hideShareLinks}>
                        {shareLinks}
                    </Modal>
                )
            })}
            {useBreakpointValue({
                base: (
                    <Drawer isOpen={isDownloadDestinationsVisible} onClose={handleCancelDownload}>
                        {downloadDestinations}
                    </Drawer>
                ),
                lg: (
                    <Modal
                        isOpen={isDownloadDestinationsVisible}
                        onClose={handleCancelDownload}
                        size="sm"
                    >
                        {downloadDestinations}
                    </Modal>
                )
            })}
        </Flex>
    );
};

export default memo(PhotoToolbar);
