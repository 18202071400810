import React from 'react';
import { Box } from '@chakra-ui/react';

interface Props {
    background: { color: string } | { imageUrl: string };
    borderWidth?: number;
    selection?: { borderColor: string };
    size?: number;
}

const ProductSwatch = ({ background, borderWidth = 2, selection, size = 24 }: Props) => {
    const backgroundValue =
        'imageUrl' in background
            ? `url(${background.imageUrl}), lightgray 50% / cover no-repeat`
            : background.color;

    const outerSize = size + 2 * borderWidth;

    return (
        <Box
            borderColor={selection?.borderColor ?? 'transparent'}
            borderRadius="50%"
            borderStyle="solid"
            borderWidth={`${borderWidth}px`}
            boxSizing="border-box"
            height={`${outerSize}px`}
            width={`${outerSize}px`}
        >
            <Box
                background={backgroundValue}
                borderColor="white"
                borderRadius="50%"
                borderStyle="solid"
                borderWidth={`${borderWidth}px`}
                boxShadow="0px 2px 3px 0px rgba(0, 0, 0, 0.20)"
                boxSizing="border-box"
                height={`${size}px`}
                width={`${size}px`}
            />
        </Box>
    );
};

export default ProductSwatch;
