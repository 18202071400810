import React, { FC } from 'react';
import { Box, Divider, Flex, Text, useBreakpointValue, useTheme } from '@chakra-ui/react';
import { CoverPhoto, GalleryHeading } from 'ts/common/components/gallery';
import type { ICoverPhotoBase } from 'ts/common/types';

interface Props {
    /** Small text to show just below the larger title heading */
    caption: string;
    /** An optional cover photo with a URL, to render in the background of the title and caption */
    coverPhoto?: ICoverPhotoBase & { url: string };
    /** Text for a larger heading to show just above the small caption text */
    headingText: string;
    /** True only if the large-screen version should be shown. If not specified, then automatically detect based on viewport width. */
    isLargeScreen?: boolean;
}

/** A component that renders a title, caption, and optional cover photo, to indicate the contents of a client gallery page */
const PageTitle: FC<Props> = ({
    caption,
    coverPhoto,
    headingText,
    isLargeScreen: isLargeScreenControlled
}) => {
    const isLargeScreenViewport = useBreakpointValue({ base: false, lg: true }) ?? false;
    const isLargeScreen = isLargeScreenControlled ?? isLargeScreenViewport;

    const theme = useTheme();

    const coverPhotoHeight = isLargeScreen ? '596px' : '292px';
    const headingSize = isLargeScreen ? 'md' : 'sm';
    const rootMarginBottom = isLargeScreen ? '32px' : '16px';
    const textMarginX = isLargeScreen ? '32px' : '16px';

    const captionText = (
        <Text
            color="inherit"
            fontSize="12px"
            letterSpacing="2px"
            marginX={textMarginX}
            textTransform="uppercase"
        >
            {caption}
        </Text>
    );

    if (!coverPhoto) {
        return (
            <Flex color="brand.primary" flexFlow="column nowrap" marginY="32px">
                <GalleryHeading
                    marginX={textMarginX}
                    size={headingSize}
                    text={headingText}
                    variant="primary"
                />
                {captionText}
            </Flex>
        );
    }

    return (
        <Flex
            alignItems="center"
            color="background"
            flexFlow="column nowrap"
            height={coverPhotoHeight}
            justifyContent="center"
            marginBottom={rootMarginBottom}
            position="relative"
            width="100%"
        >
            <Box height="100%" position="absolute" width="100%" zIndex={-2}>
                <CoverPhoto
                    coverPhoto={coverPhoto}
                    coverPhotoUrl={coverPhoto.url}
                    label={`${headingText} (${caption})`}
                />
            </Box>
            <Box
                background={theme.isLightColorScheme ? 'blackAlpha.500' : 'whiteAlpha.500'}
                height="100%"
                position="absolute"
                role="presentation"
                width="100%"
                zIndex={-1}
            />
            <GalleryHeading
                display="-webkit-box"
                marginX={textMarginX}
                overflow="hidden"
                size={headingSize}
                sx={{
                    WebkitLineClamp: 4,
                    WebkitBoxOrient: 'vertical'
                }}
                text={headingText}
                textAlign="center"
                variant={theme.isLightColorScheme ? 'light' : 'dark'}
            />
            <Divider marginY="12px" width="60px" />
            {captionText}
        </Flex>
    );
};

export default PageTitle;
