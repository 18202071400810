import React, { FC, useCallback, useMemo } from 'react';
import { Box, BoxProps, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import Editor from 'ts/common/components/editor/Editor';
import { ProjectSpecification } from 'ts/common/components/editor/Editor.types';
import Preview, { IPreviewBackground, IPreviewEffects } from 'ts/common/components/editor/Preview';
import type { ICrop } from 'ts/common/types';
import { noop } from '../../utils';
import { useElementDimensions } from './hooks';
import type { FrameProps, IProductItem, IProductPhoto } from './types';
import { SizeVariant } from './types';
import { buildDigitalProps, buildNonDigitalProps } from './utils';

export interface IProductPreview {
    frame?: Nullable<FrameProps>;
    isCentered?: boolean;
    item: IProductItem;
    photo: IProductPhoto;
    photoUrl: string;
    previewBackground?: IPreviewBackground;
    previewEffects?: IPreviewEffects;
    variant?: SizeVariant;
    canvasPadding?: string;
}

export interface IProductEdit {
    disableRotating?: boolean;
    frame?: Nullable<FrameProps>;
    item: IProductItem;
    onUpdate?: (crop: ICrop) => void;
    photo: IProductPhoto;
    photoUrl: string;
}

interface IContainerProps {
    isCentered?: boolean;
}

const Container = styled(Flex, { shouldForwardProp: (prop) => prop !== 'isCentered' })(
    ({ isCentered }: IContainerProps) => `
        align-items: center;
        justify-content: center;
        ${isCentered ? 'margin: 0 auto;' : ''}
        max-width: max-content;

        canvas {
            height: inherit !important;
            max-width: 100% !important;
        }
    `
);
const sizeVariantMap = {
    [SizeVariant.Medium]: {
        height: 423,
        width: 516
    },
    [SizeVariant.Small]: {
        height: 96,
        width: 144
    },
    [SizeVariant.Square]: {
        height: 588,
        width: 588
    }
} as const;
const DEFAULT_SIZE_VARIANT = SizeVariant.Medium;

const ProductPreviewWrapper: FC<IProductPreview & BoxProps> = ({
    frame,
    isCentered = true,
    item,
    photo,
    photoUrl,
    previewBackground,
    previewEffects,
    variant = DEFAULT_SIZE_VARIANT,
    canvasPadding = '0',
    ...props
}) => {
    const { dimensions, ref } = useElementDimensions();
    const size =
        variant === SizeVariant.Custom && dimensions
            ? dimensions
            : sizeVariantMap[variant] ?? sizeVariantMap[DEFAULT_SIZE_VARIANT];
    const { pageSpecification, project } = item.crop
        ? buildNonDigitalProps(item, photo, photoUrl)
        : buildDigitalProps(photo, photoUrl);
    const allProps = {
        ...size,
        frame,
        padding: canvasPadding,
        pageSpecification,
        project
    };

    return (
        <Container ref={ref} isCentered={isCentered} {...props}>
            <Preview
                previewBackground={previewBackground}
                previewEffects={previewEffects}
                {...allProps}
            />
        </Container>
    );
};

/**
 * A legacy version of the above ProductPreviewWrapper component, which doesn't use hooks
 * and so works within our sp.studio_v2.react.bridge framework, which apparently crashes
 * when a component with hooks is rendered on a page with any other bridged components,
 * because the sp.studio_v2.react.bridge registers multiple copies of React at once.
 */
const LegacyProductPreviewWrapper: FC<IProductPreview & BoxProps> = ({
    isCentered = true,
    item,
    photo,
    photoUrl,
    previewBackground,
    previewEffects,
    variant = DEFAULT_SIZE_VARIANT,
    padding = '0px 10px',
    frame,
    ...props
}) => {
    const sizeFromVariant = sizeVariantMap[variant] || sizeVariantMap[DEFAULT_SIZE_VARIANT];
    const { pageSpecification, project } = item.crop
        ? buildNonDigitalProps(item, photo, photoUrl)
        : buildDigitalProps(photo, photoUrl);
    const allProps = { ...sizeFromVariant, pageSpecification, project, frame };

    return (
        <Container isCentered={isCentered} padding={padding} {...props}>
            <Preview
                previewBackground={previewBackground}
                previewEffects={previewEffects}
                {...allProps}
            />
        </Container>
    );
};

const ProductEditWrapper: FC<IProductEdit> = ({
    disableRotating = false,
    frame,
    item,
    photo,
    photoUrl,
    onUpdate = noop
}) => {
    const { isRoundCrop, pageSpecification, project } = useMemo(
        () =>
            item.crop
                ? buildNonDigitalProps(item, photo, photoUrl)
                : buildDigitalProps(photo, photoUrl),
        [item, photo, photoUrl]
    );

    // if rotation is disabled and portrait, (do not) rotate the product mask
    project.rotation = disableRotating && photo.height > photo.width ? 1 : project.rotation;

    const onUpdateProject = useCallback(
        (updatedProject: ProjectSpecification) => {
            const clippingMask = updatedProject.image.clippingMask;
            const crop = {
                ...item.crop,
                start: {
                    x: clippingMask.left * 100,
                    y: clippingMask.top * 100
                },
                end: {
                    x: 100 - clippingMask.right * 100,
                    y: 100 - clippingMask.bottom * 100
                },
                isRotated: updatedProject.rotation % 2 !== 0
            };

            onUpdate(crop);
        },
        [item.crop, onUpdate]
    );

    return (
        <Editor
            frame={frame ?? undefined}
            disableEditing={false}
            disableRotating={disableRotating}
            isRoundCrop={isRoundCrop}
            project={project}
            pageSpecification={pageSpecification}
            onUpdate={onUpdateProject}
        />
    );
};

const ProductOrnamentsOverlay: FC<BoxProps> = (props) => {
    return (
        <Box
            height="100%"
            position="absolute"
            top={0}
            width="100%"
            backgroundSize="cover"
            {...props}
        />
    );
};

export {
    LegacyProductPreviewWrapper,
    ProductPreviewWrapper as ProductPreview,
    ProductEditWrapper as ProductEdit,
    ProductOrnamentsOverlay
};
