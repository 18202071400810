import {
    CANVAS_EXHIBITION_FOCAL_POINT_MAP,
    CANVAS_EXHIBITION_PHYSICAL_DIMENSIONS_MAP,
    CANVAS_EXHIBITION_TYPES
} from 'Sp/Canvas';
import type { ICrop } from 'ts/common/types';
import {
    Exhibition,
    I2DBox,
    I3DBox,
    IBoundInches,
    IContainerDimensions,
    IExhibitionDimensions
} from './PerspectiveRender.types';

export const divideByPercent = (dividend: number, divisorPercent: number): number => {
    return dividend * (100 / divisorPercent);
};

export const multiplyByPercent = (multiplicand: number, multiplierPercent: number): number => {
    return multiplicand * (multiplierPercent / 100);
};

export const getBoundsInches = (
    isRotated: boolean,
    bounds: I3DBox,
    totalCropPercentages: {
        totalCropLeftPercent: number;
        totalCropTopPercent: number;
        totalCropWidthPercent: number;
        totalCropHeightPercent: number;
    }
) => {
    const { useCanvasCrop, depth: totalDepthInches } = bounds;
    // For now, the bleed is hardcoded to 0.625 inches, but this may be dynamic in the future
    const bleedInches = useCanvasCrop ? 0.625 : 0;
    let { width: totalCropWidthInches, height: totalCropHeightInches } = bounds;

    const {
        totalCropLeftPercent,
        totalCropTopPercent,
        totalCropWidthPercent,
        totalCropHeightPercent
    } = totalCropPercentages;

    // If the isRotated flag is set, we need to swap the axes
    if (isRotated) {
        [totalCropWidthInches, totalCropHeightInches] = [
            totalCropHeightInches,
            totalCropWidthInches
        ];
    }

    // The total depth also includes the bleed
    const depthInches = totalDepthInches - bleedInches;

    // The total width and total height include depth+bleed on both sides
    const cropWidthInches = totalCropWidthInches - (useCanvasCrop ? 2 * totalDepthInches : 0);
    const cropHeightInches = totalCropHeightInches - (useCanvasCrop ? 2 * totalDepthInches : 0);
    const cropAspectRatio = cropWidthInches / cropHeightInches;
    // We use full image bounds to determine DPI
    const fullImageWidthInches = divideByPercent(totalCropWidthInches, totalCropWidthPercent);
    const fullImageHeightInches = divideByPercent(totalCropHeightInches, totalCropHeightPercent);
    // Now we can actually determine the crop position
    const totalCropLeftInches = multiplyByPercent(fullImageWidthInches, totalCropLeftPercent);
    const totalCropTopInches = multiplyByPercent(fullImageHeightInches, totalCropTopPercent);

    return {
        totalCropLeftInches,
        totalCropTopInches,
        bleedInches,
        depthInches,
        cropWidthInches,
        cropHeightInches,
        cropAspectRatio,
        fullImageWidthInches,
        fullImageHeightInches
    };
};

export const getBoundsPixels = (photoDimensions: I2DBox, boundsInches: IBoundInches) => {
    const { width: photoWidthPixels } = photoDimensions;
    const {
        totalCropLeftInches,
        totalCropTopInches,
        bleedInches,
        depthInches,
        cropWidthInches,
        cropHeightInches,
        fullImageWidthInches
    } = boundsInches;
    const photoDpi = photoWidthPixels / fullImageWidthInches;

    return {
        totalCropLeftPixels: totalCropLeftInches * photoDpi,
        totalCropTopPixels: totalCropTopInches * photoDpi,
        bleedPixels: bleedInches * photoDpi,
        depthPixels: depthInches * photoDpi,
        cropWidthPixels: cropWidthInches * photoDpi,
        cropHeightPixels: cropHeightInches * photoDpi
    };
};

export const getScaleFactors = (
    exhibition: Exhibition,
    exhibitionDimensions: {
        croppedExhibitionInches: I2DBox;
    } | null,
    boundsInches: IBoundInches,
    {
        width: containerWidthPixels,
        height: containerHeightPixels,
        aspectRatio: containerAspectRatio
    }: IContainerDimensions,
    { cropWidthPixels, cropHeightPixels }
) => {
    // Look at aspect ratios and determine whether we will fit width or height
    const cropAspectRatio = cropWidthPixels / cropHeightPixels;
    const fitPhotoToContainerWidth = containerAspectRatio < cropAspectRatio;
    // Determine scale factor between source image and container
    const baseScaleFactor = fitPhotoToContainerWidth
        ? containerWidthPixels / cropWidthPixels
        : containerHeightPixels / cropHeightPixels;
    // Coverage ratios is the percentage of the mat we cover (fitting width or height)
    const perspectiveCoverageRatio = 0.75;
    const shadowRenderingCoverageRatio = getShadowRenderingCoverageRatio(
        exhibition,
        exhibitionDimensions,
        boundsInches,
        fitPhotoToContainerWidth
    );
    // Determine the scaling factors we will use to size renderings
    const perspectiveScaleFactor = baseScaleFactor * perspectiveCoverageRatio;
    const shadowScaleFactor = baseScaleFactor * shadowRenderingCoverageRatio;

    return {
        baseScaleFactor,
        perspectiveScaleFactor,
        shadowScaleFactor,
        fitPhotoToContainerWidth
    };
};

export const getShadowRenderingCoverageRatio = (
    exhibition: Exhibition,
    exhibitionDimensions: {
        croppedExhibitionInches: I2DBox;
    } | null,
    { cropWidthInches, cropHeightInches }: IBoundInches,
    fitPhotoToContainerWidth: boolean
) => {
    if (exhibition === CANVAS_EXHIBITION_TYPES.SOLO) {
        return 0.85;
    }

    if (!exhibitionDimensions) {
        throw new Error('Exhibition dimensions are needed for any exhibiton other than `solo`');
    }

    const { croppedExhibitionInches } = exhibitionDimensions;
    const { width: croppedExhibitionWidthInches, height: croppedExhibitionHeightInches } =
        croppedExhibitionInches;
    const shadowRenderingCoverageRatio = fitPhotoToContainerWidth
        ? cropWidthInches / croppedExhibitionWidthInches
        : cropHeightInches / croppedExhibitionHeightInches;

    return shadowRenderingCoverageRatio;
};

export const getPerspectiveRenderingDetails = ({
    perspectiveScaleFactor
}: {
    perspectiveScaleFactor: number;
}) => {
    // Because we scale our entire renderings, we reverse the scaling to get constant
    // box-shadow
    const boxShadowOffset = 4 / perspectiveScaleFactor;

    return { boxShadowOffset };
};

export const getShadowRenderingDetails = ({ shadowScaleFactor }: { shadowScaleFactor: number }) => {
    // Because we scale our entire renderings, we reverse the scaling to get constant
    // box-shadow and border-radius
    const boxShadowVerticalOffset = 4 / shadowScaleFactor;
    const boxShadowHorizontalOffset = 1 / shadowScaleFactor;
    const borderRadius = 2 / shadowScaleFactor;

    return { boxShadowVerticalOffset, boxShadowHorizontalOffset, borderRadius };
};

export const getTranslateFactors = (
    exhibition?: Exhibition,
    exhibitionDimensions?: IExhibitionDimensions | null
) => {
    const translateFactorPercentages = CANVAS_EXHIBITION_FOCAL_POINT_MAP[exhibition];

    if (!translateFactorPercentages || !exhibitionDimensions) {
        return { translateX: 0, translateY: 0 };
    }

    const { uncroppedBackdropPixels } = exhibitionDimensions;
    const { width: uncroppedBackdropWidth, height: uncroppedBackdropHeight } =
        uncroppedBackdropPixels;
    const { translateX: translateXPercentage, translateY: translateYPercentage } =
        translateFactorPercentages;
    const translateXPixels = Math.round(
        multiplyByPercent(uncroppedBackdropWidth, translateXPercentage)
    );
    const translateYPixels = Math.round(
        multiplyByPercent(uncroppedBackdropHeight, translateYPercentage)
    );
    const translateFactorPixels = {
        translateX: `${translateXPixels}px`,
        translateY: `${translateYPixels}px`
    };

    return translateFactorPixels;
};

export const getExhibitionDimensions = (
    exhibition: Exhibition,
    {
        width: containerWidth,
        height: containerHeight,
        aspectRatio: containerAspectRatio
    }: IContainerDimensions
) => {
    const totalExhibitionInches = CANVAS_EXHIBITION_PHYSICAL_DIMENSIONS_MAP[exhibition];

    if (!totalExhibitionInches) {
        return null;
    }

    const { width: totalExhibitionWidthInches, height: totalExhibitionHeightInches } =
        totalExhibitionInches;
    const totalExhibitionAspectRatio = totalExhibitionWidthInches / totalExhibitionHeightInches;
    const fitBackdropToContainerWidth = totalExhibitionAspectRatio < containerAspectRatio;
    const croppedExhibitionWidthInches = fitBackdropToContainerWidth
        ? totalExhibitionWidthInches
        : totalExhibitionHeightInches * containerAspectRatio;
    const croppedExhibitionHeightInches = fitBackdropToContainerWidth
        ? totalExhibitionWidthInches / containerAspectRatio
        : totalExhibitionHeightInches;
    const croppedExhibitionInches = {
        width: croppedExhibitionWidthInches,
        height: croppedExhibitionHeightInches
    };
    const uncroppedBackdropWidth = fitBackdropToContainerWidth
        ? containerWidth
        : containerHeight * totalExhibitionAspectRatio;
    const uncroppedBackdropHeight = fitBackdropToContainerWidth
        ? containerWidth / totalExhibitionAspectRatio
        : containerHeight;
    const uncroppedBackdropPixels = {
        width: uncroppedBackdropWidth,
        height: uncroppedBackdropHeight
    };

    return {
        totalExhibitionInches,
        totalExhibitionAspectRatio,
        fitBackdropToContainerWidth,
        croppedExhibitionInches,
        uncroppedBackdropPixels
    };
};

export const getTotalCropPercentages = (crop: Omit<ICrop, 'bounds'> | null) => {
    if (crop === null) {
        return {
            totalCropLeftPercent: 0,
            totalCropTopPercent: 0,
            totalCropWidthPercent: 100,
            totalCropHeightPercent: 100
        };
    }

    const {
        start: { x: startX, y: startY },
        end: { x: endX, y: endY }
    } = crop;

    return {
        totalCropLeftPercent: startX,
        totalCropTopPercent: startY,
        totalCropWidthPercent: endX - startX,
        totalCropHeightPercent: endY - startY
    };
};

export const PHOTO_SIZES = {
    m: {
        key: 'm',
        bounds: {
            width: 500,
            height: 500
        }
    },
    l: {
        key: 'l',
        bounds: {
            width: 1000,
            height: 1000
        }
    },
    xl: {
        key: 'xl',
        bounds: {
            width: 1500,
            height: 1500
        }
    },
    '2x': {
        key: '2x',
        bounds: {
            width: 2000,
            height: 2000
        }
    },
    '3x': {
        key: '3x',
        bounds: {
            width: 3000,
            height: 3000
        }
    }
} as const;

export const getBoundsForSize = (size, customBounds) => {
    // This allows you to pass in your own bounding box
    if (typeof customBounds === 'object') {
        return customBounds;
    }

    const photoSize = PHOTO_SIZES[size];

    if (customBounds === true) {
        return {
            height: photoSize.bounds.height,
            width: photoSize.bounds.width
        };
    }

    const boundsFactor = 1.5; // 1.5x

    return {
        height: photoSize.bounds.height / boundsFactor,
        width: photoSize.bounds.width / boundsFactor
    };
};

export const getPhotoDimensions = (photo, size, customBounds) => {
    const bounds = getBoundsForSize(size, customBounds);
    const photoDimensions = {
        width: photo.width,
        height: photo.height
    };

    if (typeof bounds.width === 'number' && photoDimensions.width > bounds.width) {
        // Photo is wider than container, make it shorter
        photoDimensions.height = (bounds.width / photoDimensions.width) * photoDimensions.height;
        photoDimensions.width = bounds.width;
    }

    if (typeof bounds.height === 'number' && photoDimensions.height > bounds.height) {
        // Photo is too tall, make it more narrow
        photoDimensions.width = (bounds.height / photoDimensions.height) * photoDimensions.width;
        photoDimensions.height = bounds.height;
    }

    return photoDimensions;
};

// TODO Add types:
export const calculateSurfaceValues = ({
    crop,
    exhibition,
    containerDimensions,
    photoDimensions
}) => {
    const { bounds, isRotated } = crop;

    if (typeof crop !== 'object' || typeof photoDimensions !== 'object') {
        return;
    }

    // First step is to gather dimensions for exhibition space and
    // backdrop image
    const exhibitionDimensions = getExhibitionDimensions(exhibition, containerDimensions);
    // Next step is to normalize the (possibly rotated) totalCrop
    // (includes depth+bleed) data structure
    const cropPercentages = getTotalCropPercentages(crop);
    // Then we compute crop and fullImage bounds in inches
    const boundsInches = getBoundsInches(isRotated, bounds, cropPercentages);
    // Then we compute bounds in pixels
    const boundsPixels = getBoundsPixels(photoDimensions, boundsInches);
    // Determine scale factor between source image and container
    const scaleFactors = getScaleFactors(
        exhibition,
        exhibitionDimensions,
        boundsInches,
        containerDimensions,
        boundsPixels
    );
    const translateFactors = getTranslateFactors(exhibition, exhibitionDimensions);
    const perspectiveRenderingDetails = getPerspectiveRenderingDetails(scaleFactors);
    const shadowRenderingDetails = getShadowRenderingDetails(scaleFactors);
    const figureValues = {
        boundsPixels,
        scaleFactors,
        translateFactors,
        perspectiveRenderingDetails,
        shadowRenderingDetails,
        boundsInches,
        cropPercentages,
        exhibitionDimensions
    };

    return figureValues;
};

export const isCropRotated = (photo, crop) => {
    const cropMaskWidth = photo.width * ((crop.end.x - crop.start.x) / 100);
    const cropMaskHeight = photo.height * ((crop.end.y - crop.start.y) / 100);
    const boundsWidth = crop.bounds?.width ?? 0;
    const boundsHeight = crop.bounds?.height ?? 0;

    return cropMaskWidth > cropMaskHeight === boundsWidth > boundsHeight ? false : true;
};
