import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Flex, useBreakpointValue } from '@chakra-ui/react';
import { Info } from 'react-feather';
import { ProductGroup } from 'ts/client/gallery';
import {
    convertIEventPhotosToIImages,
    groupHasLabFulfilledCards,
    hasMultipleSubgroupsOfProducts
} from 'ts/client/gallery/components/common';
import { IImage } from 'ts/client/gallery/components/types';
import { Button } from 'ts/common/components/gallery';
import { EmptyState } from 'ts/common/components/gallery';
import { useTranslate } from 'ts/common/hooks';
import { useStore } from 'client_react/gallery/hooks/StoreContext';
import { PageIndex } from '../StorePage';

interface LandingPageProps {
    navigate: (path: string, needsDigest?: boolean) => void;
}

const LandingPage: FC<LandingPageProps> = ({ navigate }) => {
    const {
        goToPage,
        photos,
        priceSheetGroups,
        priceSheet,
        setImageOnSelectedGroup,
        setSelectedPriceSheetItem,
        setSelectedPriceSheetGroup
    } = useStore();

    useEffect(() => {
        setSelectedPriceSheetItem(null);
    }, [setSelectedPriceSheetItem]);

    const imagesForGroups = useMemo(() => {
        return photos.length > 0
            ? convertIEventPhotosToIImages(photos, true, priceSheetGroups.length)
            : [];
    }, [photos, priceSheetGroups.length]);

    const isLargeScreen = useBreakpointValue({ base: false, lg: true });
    const gap = isLargeScreen ? 20 : 10;

    const productGroupMaxWidth = useBreakpointValue({
        base: `calc(50% - ${gap}px)`,
        lg: `calc(50% - ${gap / 2}px)`
    });

    const t = useTranslate('client.store.landingPage');

    const handleProductGroupSelection = useCallback(
        (priceSheetGroup: SpApi.Client.IPriceSheetGroup, selectedGroupImage: IImage) => {
            setSelectedPriceSheetGroup(priceSheetGroup);

            if (
                priceSheetGroup.groupType === 'cards' &&
                groupHasLabFulfilledCards(priceSheetGroup)
            ) {
                setImageOnSelectedGroup(selectedGroupImage);
                goToPage(PageIndex.SelectCard, t('cards'));
                return;
            }

            if (priceSheetGroup.items.length === 1) {
                setSelectedPriceSheetItem(priceSheetGroup.items[0]);
            }

            setImageOnSelectedGroup(selectedGroupImage);

            if (priceSheetGroup.groupType === 'packages') {
                goToPage(PageIndex.SelectPackage, t('packages'));
            } else if (hasMultipleSubgroupsOfProducts(priceSheetGroup)) {
                goToPage(PageIndex.SelectProduct, priceSheetGroup.name);
            } else {
                goToPage(PageIndex.SelectProductOptions, priceSheetGroup.name);
            }
        },
        [
            goToPage,
            setImageOnSelectedGroup,
            setSelectedPriceSheetItem,
            setSelectedPriceSheetGroup,
            t
        ]
    );

    useEffect(() => {
        if (imagesForGroups.length > 0 && priceSheetGroups.length === 1) {
            handleProductGroupSelection(priceSheetGroups[0], imagesForGroups[0]);
        }
    }, [handleProductGroupSelection, imagesForGroups, priceSheetGroups]);

    return priceSheetGroups.length === 0 ? (
        <EmptyState page="Store" translateFilter={t} />
    ) : priceSheetGroups.length === 1 ? null : (
        <Flex
            data-testid="landing-page"
            flexDirection="column"
            height="100%"
            top={isLargeScreen ? '80px' : '64px'}
            width="100%"
            alignItems="center"
        >
            <Button
                borderRadius="24px"
                color="currentColor"
                data-testid="pricing-and-discounts-button"
                fontFamily="open-sans"
                leftIcon={<Info height="14px" style={{ marginRight: '8px' }} width="14px" />}
                marginBottom="30px"
                marginTop="30px"
                onClick={() => {
                    navigate('info/pricing');
                }}
                padding="12px 20px"
                text={`${t('pricingInfoLink')}${
                    priceSheet?.discountDescriptions.length ? ` ${t('andDiscounts')}` : ''
                }`}
                variant="outline primary"
            />
            <Flex
                flexWrap="wrap"
                gap={`${gap}px`}
                rowGap="20px"
                justifyContent="space-evenly"
                width="100%"
                maxWidth="1440px"
            >
                {imagesForGroups.length > 0 &&
                    priceSheetGroups.map((priceSheetGroup, index) => (
                        <ProductGroup
                            forceDefaultBackdrop
                            data-testid="product-group"
                            priceSheetGroup={priceSheetGroup}
                            image={imagesForGroups[index]}
                            key={index}
                            maxWidth={productGroupMaxWidth}
                            width="388px"
                            onClick={() => {
                                handleProductGroupSelection(
                                    priceSheetGroup,
                                    imagesForGroups[index]
                                );
                            }}
                        />
                    ))}
            </Flex>
        </Flex>
    );
};

export default LandingPage;
